import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import Dropdown from "../../FormElements/Dropdown";
import { updateDataOnRequest } from "../../../redux/actions/CommonActions";
import { useDispatch } from "react-redux";
import Textarea from "../../FormElements/Textarea";
import { redirectUrl } from "../../../services/GlobalUrls";
import Input from "../../FormElements/Input";

const CancelAssignments = (props) => {
  const [myassignments, SetMyAssignments] = useState([]);
  const [cancelAssignments, setAssignment] = useState({});
  const [showModalData, setModalData] = useState({});
  const [assignOneModal, SetAssignOneModal] = useState(false);
  const [assignAllModal, SetAssignAllModal] = useState(false);

  let localStorageValues = JSON.parse(localStorage.getItem("loginUserDetails"));
  let cnd_assignment_cancel_reason, cnd_assignment_cancel_reason_msg;
  let applyError = false;
  useEffect(() => {
    SetMyAssignments(props.myassignments);
  }, [props.myassignments]);

  const dispatch = useDispatch();

  const skipModal = (modalName) => {
    // if(modalName == "hideAssignment"){
    // }
  };

  const onModalOneData = (
    assignId,
    assignUser,
    assignDate,
    assignClient,
    assignClientServiceTown,
    assignBranch,
    assignStartTime,
    assignEndTime,
    assignJobTitle,
    assignJobSkills
  ) => {
    setModalData({
      asssignment_id: assignId,
      user_name: assignUser,
      date_of_assignment: assignDate,
      client_name: assignClient,
      client_service_town: assignClientServiceTown,
      branch_name: assignBranch
        ? assignBranch
        : assignClient +
          (assignClientServiceTown
            ? " - (" + assignClientServiceTown + ")"
            : ""),
      start_time: assignStartTime,
      end_time: assignEndTime,
      job_title: assignJobTitle,
      job_skills: assignJobSkills,
    });

    SetAssignOneModal(true);
  };

  const onModalAllData = () => {
    setModalData({});

    SetAssignAllModal(true);
  };

  // const openModal = (showModalData) => {
  //   console.log("Modal Data Are :=>"+JSON.stringify(showModalData));
  // }

  const onChangeAssignment = (e) => {
    e.preventDefault();

    setAssignment({
      ...cancelAssignments,
      [e.target.name]: e.target.value,
    });

    cnd_assignment_cancel_reason = document.getElementById(
      "cnd_assignment_cancel_reason"
    );
    cnd_assignment_cancel_reason_msg = document.getElementById(
      "cnd_assignment_cancel_reason_msg"
    );

    if (!cnd_assignment_cancel_reason.value) {
      cnd_assignment_cancel_reason.classList.add("border-danger");
      cnd_assignment_cancel_reason_msg.style.display = "block";
      cnd_assignment_cancel_reason_msg.innerText =
        "Please select the reason from above list!";
    } else {
      cnd_assignment_cancel_reason.classList.remove("border-danger");
      cnd_assignment_cancel_reason_msg.style.display = "none";
      cnd_assignment_cancel_reason_msg.innerText = "";
    }
    // console.log("All The Assignment Data :=> " + JSON.stringify(cancelAssignments));
  };
  const onSubmitAssignment = (e) => {
    e.preventDefault();

    cnd_assignment_cancel_reason = document.getElementById(
      "cnd_assignment_cancel_reason"
    );
    cnd_assignment_cancel_reason_msg = document.getElementById(
      "cnd_assignment_cancel_reason_msg"
    );

    if (!cnd_assignment_cancel_reason.value) {
      cnd_assignment_cancel_reason.classList.add("border-danger");
      cnd_assignment_cancel_reason_msg.style.display = "block";
      cnd_assignment_cancel_reason_msg.innerText =
        "Please select the reason from above list!";
      applyError = true;
    } else {
      cnd_assignment_cancel_reason.classList.remove("border-danger");
      cnd_assignment_cancel_reason_msg.style.display = "none";
      cnd_assignment_cancel_reason_msg.innerText = "";
      applyError = false;
    }

    if (applyError == false) {
      cancelAssignments["users_access_token"] = localStorageValues.access_token;
      // console.log("Assignment Id :=>"+showModalData.asssignment_id);
      if (showModalData.asssignment_id) {
        cancelAssignments["cnd_assignment_id"] = showModalData.asssignment_id;
      }
      SetAssignOneModal(false);
      SetAssignAllModal(false);
      document.getElementById("loader").style.display = "block";
      dispatch(
        updateDataOnRequest(
          JSON.stringify(cancelAssignments, null, 2),
          "CancelAssignments",
          ""
        )
      );
    }

    // console.log("All The Assignment Data :=> "+JSON.stringify(cancelAssignments));
  };

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <h6 className="text-danger">
          To Delete the Account, Please cancel all the below assignments.
          <a
            type="button"
            onClick={() => SetAssignAllModal(true)}
            className="pull-right text-white site-button red mb-2"
          >
            <i class="fa fa-ban text-xl-center " aria-hidden="true"></i> Cancel
            All
          </a>
        </h6>
        <table className="table table-bordered table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              {/*<th  className="col-1 text-center" scope="col">Sr. No</th>*/}
              <th className="col-2 text-center" scope="col">
                Date
              </th>
              <th className="col-2 text-center" scope="col">
                Timing
              </th>
              {/* <th  className="col-3 text-center" scope="col"> Name</th> */}
              {/*<th  className="col-2 text-center" scope="col">Email</th>*/}
              <th className="col-2 text-center" scope="col">
                Client Name
              </th>
              <th className="col-2 text-center" scope="col">
                Branch Name
              </th>
              <th className="col-2 text-center" scope="col">
                Address
              </th>
              <th className="col-2 text-center" scope="col">
                Job Title
              </th>
              <th className="col-2 text-center" scope="col">
                Action
              </th>
              {/* <th className="col-2 text-center" scope="col">
                Status
              </th> */}
              {/*<th  className="col-1 text-center" scope="col">Action</th>*/}
            </tr>
          </thead>
          <tbody id="myTable">
            {myassignments &&
              myassignments.length > 0 &&
              myassignments?.map((myassignment, i) => {
                if (myassignment.cnd_assignment_status == "Assigned") {
                  return (
                    <tr key={i}>
                      {/*<td className="col-1 text-center" scope="row">
                    {i + 1}
              </td>*/}
                      <td className="col-2 text-center" scope="row">
                        {format(
                          new Date(
                            myassignment.cnd_assignment_availability_date
                          ),
                          "dd-MM-yyyy"
                        ) +
                          " (" +
                          myassignment.cnd_availability_week_day
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ") +
                          ")"}
                      </td>
                      <td className="col-2 text-center" scope="row">
                        {myassignment.cnd_assignment_start_time +
                          " - " +
                          myassignment.cnd_assignment_end_time}
                      </td>
                      <td className="col-2 text-center" scope="row">
                        {myassignment.clt_company_name}
                      </td>
                      {myassignment.cnd_assignment_client_branch_id &&
                        myassignment.cnd_assignment_client_branch_id ==
                          myassignment.clt_branch_id && (
                          <td className="col-2 text-center" scope="row">
                            {myassignment.clt_branch_location
                              ? myassignment.clt_branch_name +
                                " - ( " +
                                myassignment.clt_branch_location +
                                " )"
                              : myassignment.clt_branch_name}
                          </td>
                        )}
                      {!myassignment.cnd_assignment_client_branch_id && (
                        <td className="col-2 text-center" scope="row">
                          {myassignment.clt_service_town
                            ? myassignment.clt_company_name +
                              " - ( " +
                              myassignment.clt_service_town +
                              " )"
                            : myassignment.clt_company_name}
                        </td>
                      )}
                      <td className="col-2 text-center" scope="row">
                        {myassignment.clt_branch_address1
                          ? myassignment.clt_branch_address1
                          : ""}
                        {myassignment.clt_branch_address2
                          ? ", " + myassignment.clt_branch_address2
                          : ""}
                        {myassignment.clt_branch_town
                          ? ", " + myassignment.clt_branch_town
                          : ""}
                        {myassignment.clt_branch_region
                          ? ", " + myassignment.clt_branch_region
                          : ""}
                        {myassignment.clt_branch_postal
                          ? ", " + myassignment.clt_branch_postal
                          : ""}
                      </td>
                      <td className="col-2 text-center" scope="row">
                        {myassignment.cnd_assignment_job_roles
                          ? myassignment.cnd_assignment_job_roles
                          : " - "}
                      </td>
                      <td className="col-2 text-center" scope="row">
                        <a
                          onClick={() =>
                            onModalOneData(
                              myassignment.cnd_assignment_id,
                              myassignment.users_firstname +
                                " " +
                                myassignment.users_lastname,
                              myassignment.cnd_assignment_availability_date,
                              myassignment.clt_company_name,
                              myassignment.clt_service_town,
                              myassignment.cnd_assignment_client_branch_id
                                ? myassignment.clt_branch_name +
                                    (myassignment.clt_branch_service_town
                                      ? " - (" +
                                        myassignment.clt_branch_service_town +
                                        ")"
                                      : "")
                                : "",
                              myassignment.cnd_assignment_start_time,
                              myassignment.cnd_assignment_end_time,
                              myassignment.cnd_assignment_job_roles,
                              myassignment.cnd_assignment_job_skills
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            class="fa fa-ban text-danger text-xl-center mt-4"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </td>
                    </tr>
                  );
                }
              })}
            {myassignments.length == 0 && (
              <tr>
                <td colSpan="100">No myassignments Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <>
        {/* Single Cancel Modal Start */}
        <div className="row">
          {/* Assignment Modal Started */}
          <Modal
            show={assignOneModal}
            // onHide={() => skipModal("hideAssignment")}
            onHide={() => SetAssignOneModal(false)}
            className="modal fade modal-bx-info editor"
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-titl" id="ResumeheadlineModalLongTitle">
                    Cancel Assignment
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => SetAssignOneModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    onInput={onChangeAssignment}
                    onSubmit={(e) => onChangeAssignment()}
                  >
                    <div className="row">
                      {/* {JSON.stringify(showModalData)} */}

                      <Input
                        label="Candidate Name"
                        name={"candidate_name"}
                        className="form-control"
                        type="text"
                        value={showModalData.user_name}
                        disabled={true}
                        changeHandler={(e) => onChangeAssignment(e)}
                        lg={6}
                      />

                      <Input
                        label="Date of Availability"
                        name={"cnd_assignment_availability_date"}
                        className="form-control"
                        type="date"
                        value={showModalData.date_of_assignment}
                        disabled={true}
                        changeHandler={(e) => onChangeAssignment(e)}
                        lg={6}
                      />

                      <Input
                        label="Client Name"
                        name={"client_name"}
                        className="form-control"
                        type="text"
                        value={showModalData.client_name}
                        disabled={true}
                        changeHandler={(e) => onChangeAssignment(e)}
                        lg={6}
                      />

                      <Input
                        label="Client Branch - (Location)"
                        name={"date_of_availability"}
                        className="form-control"
                        type="text"
                        value={
                          showModalData.branch_name
                            ? showModalData.branch_name
                            : showModalData.client_name +
                              (showModalData.client_service_town
                                ? " - (" +
                                  showModalData.client_service_town +
                                  ")"
                                : "")
                        }
                        disabled={true}
                        changeHandler={(e) => onChangeAssignment(e)}
                        lg={6}
                      />

                      <Input
                        label="Start Time"
                        name={"start_time"}
                        className="form-control"
                        type="text"
                        value={showModalData.start_time}
                        disabled={true}
                        changeHandler={(e) => onChangeAssignment(e)}
                        lg={6}
                      />

                      <Input
                        label="End Time"
                        name={"end_time"}
                        className="form-control"
                        type="text"
                        value={showModalData.end_time}
                        disabled={true}
                        changeHandler={(e) => onChangeAssignment(e)}
                        lg={6}
                      />

                      <Input
                        label="Job Roles"
                        name={"job_title"}
                        className="form-control"
                        type="text"
                        value={showModalData.job_title}
                        disabled={true}
                        changeHandler={(e) => onChangeAssignment(e)}
                        lg={6}
                      />

                      <Input
                        label="Qualification and Skills"
                        name={"job_skills"}
                        className="form-control"
                        type="text"
                        value={showModalData.job_skills}
                        disabled={true}
                        changeHandler={(e) => onChangeAssignment(e)}
                        lg={6}
                      />

                      <Dropdown
                        label={"Reason for Cancellation"}
                        name={"cnd_assignment_cancel_reason"}
                        id={"cnd_assignment_cancel_reason"}
                        changeHandler={(e) => onChangeAssignment(e)}
                        options={[
                          {
                            name: "Select reason for cancellation",
                            value: "",
                          },
                          {
                            name: "Sick - Not Well",
                            value: "Sick - Not Well",
                          },
                          {
                            name: "Not Available",
                            value: "Not Available",
                          },
                          {
                            name: "Leaving the Job",
                            value: "Leaving the Job",
                          },
                          {
                            name: "Clashing with Other Shift",
                            value: "Clashing with Other Shift",
                          },
                          {
                            name: "Other - Describe in comment",
                            value: "Other - Describe in comment",
                          },
                        ]}
                        lg={12}
                      />

                      <Textarea
                        label={"Comment for Cancellation"}
                        lg={12}
                        placeholder={"Description - (optional)"}
                        name={"cnd_assignment_cancel_comment"}
                        changeHandler={(e) => onChangeAssignment(e)}
                        req={false}
                      />
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={onSubmitAssignment}
                        className="site-button green"
                      >
                        Confirm
                      </button>

                      <button
                        type="button"
                        className="site-button red"
                        // onClick={() => skipModal("hideAssignment")}
                        onClick={() => SetAssignOneModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>
          {/* Assignment Modal Ended */}
        </div>
        {/* Sigle Cancel Modal Stop */}
        {/* All Cancel Modal Start */}
        <div className="row">
          {/* Assignment Modal Started */}
          <Modal
            show={assignAllModal}
            // onHide={() => skipModal("hideAssignment")}
            onHide={() => SetAssignAllModal(false)}
            className="modal fade modal-bx-info editor"
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-titl" id="ResumeheadlineModalLongTitle">
                    Cancel All Assignments
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => SetAssignOneModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    onInput={onChangeAssignment}
                    onSubmit={(e) => onChangeAssignment()}
                  >
                    <div className="row">
                      {/* {JSON.stringify(showModalData)} */}
                      <Dropdown
                        label={"Reason for Cancellation"}
                        name={"cnd_assignment_cancel_reason"}
                        id={"cnd_assignment_cancel_reason"}
                        changeHandler={(e) => onChangeAssignment(e)}
                        options={[
                          {
                            name: "Select reason for cancellation",
                            value: "",
                          },
                          {
                            name: "Sick - Not Well",
                            value: "Sick - Not Well",
                          },
                          {
                            name: "Not Available",
                            value: "Not Available",
                          },
                          {
                            name: "Leaving the Job",
                            value: "Leaving the Job",
                          },
                          {
                            name: "Clashing with Other Shift",
                            value: "Clashing with Other Shift",
                          },
                          {
                            name: "Other - Describe in comment",
                            value: "Other - Describe in comment",
                          },
                        ]}
                        lg={12}
                      />

                      <Textarea
                        label={"Comment for Cancellation"}
                        lg={12}
                        placeholder={"Description - (optional)"}
                        name={"cnd_assignment_cancel_comment"}
                        changeHandler={(e) => onChangeAssignment(e)}
                        req={false}
                      />
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={onSubmitAssignment}
                        className="site-button green"
                      >
                        Confirm
                      </button>

                      <button
                        type="button"
                        className="site-button red"
                        // onClick={() => skipModal("hideAssignment")}
                        onClick={() => SetAssignAllModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>
          {/* Assignment Modal Ended */}
        </div>
        {/* All Cancel Modal Stop */}
      </>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    myassignments: state.myassignments.myassignments,
  };
};

export default connect(mapStateToProps)(CancelAssignments);
