import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInputFields, validationSchema } from "./FormTimeSheetHelper";
import { format } from "date-fns";
import constants from "../../../constants";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { getBase64 } from "../../../helpers/FileToBase64";
import LoadFormFields from "../../Layout/LoadFormFields";
import { useHistory } from "react-router-dom";
import Input from "../../FormElements/Input";
import Textarea from "../../FormElements/Textarea";
import { Button } from "react-bootstrap";
import { redirectWindowUrl } from "../../../services/GlobalUrls";

const Timesheet = (props) => {
  const [timesheetData, setFormdata] = useState({});
  const { loader, userDetails, timesheet, userProfileData } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    // window.scrollTo(0, 0);
    setFormdata(timesheet);
    if (formInputFields(timesheet).length > 0)
      formInputFields().map((field, key) => {
        if (timesheet[field.name]) setValue(field.name, timesheet[field.name]);
      });
    // setValue('cnd_timesheet_fname',userProfileData.users_firstname);
    // setValue('cnd_timesheet_lname',userProfileData.users_lastname);
    // setValue('cnd_timesheet_email',userProfileData.users_email);
  }, [timesheet, userProfileData]);

  const onSubmit = (e) => {
    e.preventDefault();
    let finalValid = false;
    let cnd_timesheet_client_name = document.getElementById(
      "cnd_timesheet_client_name"
    );
    let cnd_timesheet_client_name_msg = document.getElementById(
      "cnd_timesheet_client_name_msg"
    );
    let cnd_timesheet_upload_document = document.getElementById(
      "cnd_timesheet_upload_document"
    );
    let cnd_timesheet_upload_document_msg = document.getElementById(
      "cnd_timesheet_upload_document_msg"
    );
    let cnd_timesheet_week_commencing = document.getElementById(
      "cnd_timesheet_week_commencing"
    );
    let cnd_timesheet_week_commencing_msg = document.getElementById(
      "cnd_timesheet_week_commencing_msg"
    );

    if (!cnd_timesheet_client_name.value) {
      cnd_timesheet_client_name.classList.add("border-danger");
      cnd_timesheet_client_name_msg.innerText = "Please select the client name";
      finalValid = true;
    } else {
      cnd_timesheet_client_name.classList.remove("border-danger");
      cnd_timesheet_client_name_msg.innerText = "";
    }

    if (
      !cnd_timesheet_upload_document.value &&
      !timesheetData.cnd_timesheet_upload_document
    ) {
      cnd_timesheet_upload_document.classList.add("border-danger");
      cnd_timesheet_upload_document_msg.innerText =
        "Please select the timesheet";
      finalValid = true;
    } else {
      cnd_timesheet_upload_document.classList.remove("border-danger");
      cnd_timesheet_upload_document_msg.innerText = "";
    }

    let weekCommenceDate = new Date(
      String(cnd_timesheet_week_commencing.value)
    );
    let currectWeekCommence = new Date(isWeekday());
    let currentDate = new Date(new Date().toISOString().split("T")[0]);
    if (!cnd_timesheet_week_commencing.value) {
      cnd_timesheet_week_commencing.classList.add("border-danger");
      cnd_timesheet_week_commencing_msg.innerText =
        "Please select the expiry date";
      // finalValid = true;
    } else if (
      cnd_timesheet_week_commencing.value &&
      !String(cnd_timesheet_week_commencing.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      cnd_timesheet_week_commencing.classList.add("border-danger");
      cnd_timesheet_week_commencing_msg.innerText =
        "Please select the date in dd-mm-yyyy format";
      // finalValid = true;
    } else if (
      cnd_timesheet_week_commencing.value &&
      weekCommenceDate.getDay() != 1
    ) {
      cnd_timesheet_week_commencing.classList.add("border-danger");
      cnd_timesheet_week_commencing_msg.innerText =
        "You can only select Monday of the week";
      // finalValid = true;
    } else if (
      cnd_timesheet_week_commencing.value &&
      weekCommenceDate > currectWeekCommence
    ) {
      cnd_timesheet_week_commencing.classList.add("border-danger");
      cnd_timesheet_week_commencing_msg.innerText =
        "Please select the " +
        format(currectWeekCommence, "dd-MM-yyyy") +
        " or before date";
      // finalValid = true;
    } else {
      cnd_timesheet_week_commencing.classList.remove("border-danger");
      cnd_timesheet_week_commencing_msg.innerText = "";
    }

    let allData = timesheetData;
    // console.log("All Data Are :=>"+JSON.stringify(timesheetData));

    // console.log("File Is :=>"+allData['cnd_timesheet_upload_document']);
    allData["users_access_token"] = userDetails.users_access_token;
    allData["cnd_timesheet_fname"] = userProfileData.users_firstname;
    allData["cnd_timesheet_lname"] = userProfileData.users_lastname;
    allData["cnd_timesheet_email"] = userProfileData.users_email;
    allData["cnd_timesheet_week_commencing"] =
      cnd_timesheet_week_commencing.value;

    if (timesheet.cnd_timesheet_id)
      allData["cnd_timesheet_id"] = timesheet.cnd_timesheet_id;

    // console.log("All Data Are :=> "+JSON.stringify(allData));

    if (finalValid == false) {
      document.getElementById("loader").style.display = "block";
      dispatch(
        updateDataOnRequest(
          JSON.stringify(allData, null, 2),
          "SaveTimeSheet",
          "",
          getDataOnRequest(
            userDetails.users_access_token,
            "GetTimeSheets",
            "SET_TIMESHEETS"
          )
        )
      );
    }
    //history.push('/timesheet');
  };

  const onChangeInputvalue = async (e) => {
    // e.preventDefault();

    if (e.target.name === "cnd_timesheet_upload_document") {
      if (e.target.files[0])
        // timesheetData["cnd_timesheet_upload_document"] = await getBase64(e.target.files[0]);
        setFormdata({
          ...timesheetData,
          [e.target.name]: await getBase64(e.target.files[0]),
        });
    } else {
      setFormdata({
        ...timesheetData,
        [e.target.name]: e.target.value,
      });
    }

    let finalValid = false;
    let cnd_timesheet_client_name = document.getElementById(
      "cnd_timesheet_client_name"
    );
    let cnd_timesheet_client_name_msg = document.getElementById(
      "cnd_timesheet_client_name_msg"
    );
    let cnd_timesheet_upload_document = document.getElementById(
      "cnd_timesheet_upload_document"
    );
    let cnd_timesheet_upload_document_msg = document.getElementById(
      "cnd_timesheet_upload_document_msg"
    );
    let cnd_timesheet_week_commencing = document.getElementById(
      "cnd_timesheet_week_commencing"
    );
    let cnd_timesheet_week_commencing_msg = document.getElementById(
      "cnd_timesheet_week_commencing_msg"
    );

    if (
      !cnd_timesheet_client_name.value ||
      cnd_timesheet_client_name.value.trim() == ""
    ) {
      cnd_timesheet_client_name.classList.add("border-danger");
      cnd_timesheet_client_name_msg.innerText = "Please select the client name";
      // finalValid = true;
    } else {
      cnd_timesheet_client_name.classList.remove("border-danger");
      cnd_timesheet_client_name_msg.innerText = "";
    }

    if (
      !cnd_timesheet_upload_document.value &&
      !timesheetData.cnd_timesheet_upload_document
    ) {
      cnd_timesheet_upload_document.classList.add("border-danger");
      cnd_timesheet_upload_document_msg.innerText =
        "Please select the timesheet";
      // finalValid = true;
    } else {
      cnd_timesheet_upload_document.classList.remove("border-danger");
      cnd_timesheet_upload_document_msg.innerText = "";
    }

    let weekCommenceDate = new Date(
      String(cnd_timesheet_week_commencing.value)
    );
    let currectWeekCommence = new Date(isWeekday());
    let currentDate = new Date(new Date().toISOString().split("T")[0]);
    if (!cnd_timesheet_week_commencing.value) {
      cnd_timesheet_week_commencing.classList.add("border-danger");
      cnd_timesheet_week_commencing_msg.innerText =
        "Please select the expiry date";
      // finalValid = true;
    } else if (
      cnd_timesheet_week_commencing.value &&
      !String(cnd_timesheet_week_commencing.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      cnd_timesheet_week_commencing.classList.add("border-danger");
      cnd_timesheet_week_commencing_msg.innerText =
        "Please select the date in dd-mm-yyyy format";
      // finalValid = true;
    } else if (
      cnd_timesheet_week_commencing.value &&
      weekCommenceDate.getDay() != 1
    ) {
      cnd_timesheet_week_commencing.classList.add("border-danger");
      cnd_timesheet_week_commencing_msg.innerText =
        "You can only select Monday of the week";
      // finalValid = true;
    } else if (
      cnd_timesheet_week_commencing.value &&
      weekCommenceDate > currectWeekCommence
    ) {
      cnd_timesheet_week_commencing.classList.add("border-danger");
      cnd_timesheet_week_commencing_msg.innerText =
        "Please select the " +
        format(currectWeekCommence, "dd-MM-yyyy") +
        " or before date";
      // finalValid = true;
    } else {
      cnd_timesheet_week_commencing.classList.remove("border-danger");
      cnd_timesheet_week_commencing_msg.innerText = "";
    }
  };

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 800);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop
  const isWeekday = () => {
    let date = new Date();
    let sub = date.getDay() > 0 ? 1 : -6;
    let monday = new Date(date.setDate(date.getDate() - date.getDay() + sub));
    return monday.toISOString().split("T")[0];
  };

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Timesheet
          </h5>
        </div>
        <a
          style={{ cursor: "pointer" }}
          onClick={() =>
            redirectWindowUrl(
              "https://api.itstaffingsolutions.co.uk/uploads/staffing-connect-timesheet-template.pdf"
            )
          }
          className="text-primary ml-3"
        >
          Download timesheet template?..(If already not downloaded)
        </a>
        <form noValidate onSubmit={onSubmit} className="mt-3">
          {/* <LoadFormFields
            register={register}
            errors={errors}
            formInputFields={formInputFields()}
            onChangeInputvalue={(e) => onChangeInputvalue(e)}
            reset={reset}
            data={timesheet}
            documentUrl={ constants.IMAGE_URL +
              timesheet.cnd_timesheet_folderpath +
              timesheet.cnd_timesheet_upload_document}
            backUrl={"/timesheet"}
          /> */}

          {/* <div className="container"> */}
          <div className="row">
            <div className="col-sm-6">
              <Input
                label="First Name"
                type="text"
                value={userProfileData.users_firstname}
                disabled={true}
                name="cnd_timesheet_fname"
                id="cnd_timesheet_fname"
                changeHandler={(e) => onChangeInputvalue(e)}
                lg={12}
              />
            </div>
            <div className="col-sm-6">
              <Input
                label="Last Name"
                type="text"
                value={userProfileData.users_lastname}
                disabled={true}
                name="cnd_timesheet_lname"
                id="cnd_timesheet_lname"
                changeHandler={(e) => onChangeInputvalue(e)}
                lg={12}
              />
            </div>

            <div className="col-sm-6">
              <Input
                label="Email"
                type="text"
                value={userProfileData.users_email}
                disabled={true}
                name="cnd_timesheet_email"
                id="cnd_timesheet_email"
                changeHandler={(e) => onChangeInputvalue(e)}
                lg={12}
              />
            </div>
            <div className="col-sm-6">
              <Input
                label="Client Name"
                type="text"
                value={timesheet.cnd_timesheet_client_name}
                name="cnd_timesheet_client_name"
                id="cnd_timesheet_client_name"
                changeHandler={(e) => onChangeInputvalue(e)}
                lg={12}
              />
            </div>

            <div className="col-sm-6">
              <Input
                label="Week Commencing"
                name="cnd_timesheet_week_commencing"
                id="cnd_timesheet_week_commencing"
                type="date"
                max={new Date().toISOString().split("T")[0]}
                defaultValue={isWeekday()}
                step={7}
                className="form-control"
                value={timesheet.cnd_timesheet_week_commencing}
                changeHandler={(e) => onChangeInputvalue(e)}
                lg={12}
              />
            </div>
            <div className="col-sm-6">
              <Input
                label="Upload File"
                name="cnd_timesheet_upload_document"
                id="cnd_timesheet_upload_document"
                type="file"
                fileUrl={
                  timesheet.cnd_timesheet_upload_document
                    ? constants.IMAGE_URL +
                      timesheet.cnd_timesheet_folderpath +
                      timesheet.cnd_timesheet_upload_document
                    : ""
                }
                changeHandler={(e) => onChangeInputvalue(e)}
                className="form-control"
                lg={12}
              />
            </div>

            <div className="col-sm-12">
              <Button
                className="site-button m-b30 float-right mr-3"
                type="submit"
              >
                Save
              </Button>
              <a
                href="/timesheet"
                className="site-button text-white yellow m-b30 float-right mr-3"
                type="button"
              >
                Back
              </a>
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    timesheet: state.timesheet.timesheet,
    userProfileData: state.userProfile.userData,
  };
};
export default connect(mapStateToProps)(Timesheet);
