export const jobTitle = [
  {
    id: "residentialsupportwork",
    name: "Residential Support Work",
  },
  {
    id: "learningsupportassistant",
    name: "Learning Support Assistant",
  },
  {
    id: "qualifiedprimary",
    name: "Qualified Primary and Secondary School Teachers",
  },
  {
    id: "specialeucational",
    name: "Special Educational Needs Teacher",
  },
  {
    id: "ICTteacher",
    name: "ICT Teacher",
  },
  {
    id: "psychologyteacher",
    name: "Psychology/Sociology Teacher",
  },
  {
    id: "foodtechnology",
    name: "Food Technology Teacher",
  },
  {
    id: "englishteacher",
    name: "English Teacher",
  },
  {
    id: "geographyteacher",
    name: "Geography Teacher",
  },
  {
    id: "artteacher",
    name: "Art Teacher",
  },

  {
    id: "scienceteacher",
    name: "Science Teacher",
  },
  {
    id: "nursingunitmanager",
    name: "Nursing Unit Manager",
  },
  {
    id: "nurserypractitioners",
    name: "Nursery Practitioners",
  },
  {
    id: "nurseryassistant",
    name: "Nursery Assistant",
  },
  {
    id: "teachingassistant",
    name: "Teaching Assistant",
  },
  {
    id: "examinvigilator",
    name: "Exam Invigilator",
  },
  {
    id: "tuitionteacher",
    name: "Tuition Teacher",
  },
  {
    id: "tuitionmanager",
    name: "Tuition Manager",
  },

  {
    id: "nurserymanager",
    name: "Nursery Manager",
  },
  {
    id: "nurserydeputymanager",
    name: "Nursery Deputy Manager",
  },
  {
    id: "officeadmin",
    name: "Office Admin",
  },
  {
    id: "lunchtimesupervisor",
    name: "Lunch Time Supervisor",
  },
  {
    id: "receptionist",
    name: "Receptionist",
  },
  {
    id: "SMSE",
    name: "SMSE",
  },
  {
    id: "admissionsofficer",
    name: "Admissions Officer",
  },
  {
    id: "policyofficer",
    name: "Policy Officer",
  },
  {
    id: "medicalofficer",
    name: "Medical Officer",
  },

  {
    id: "careworker",
    name: "Care Worker",
  },
  {
    id: "supportworker",
    name: "Support Worker",
  },
  {
    id: "familysupportworker",
    name: "Family Support Worker",
  },
  {
    id: "educationwelfareofficer",
    name: "Education Welfare Officer",
  },
  {
    id: "humanresourceofficer",
    name: "Human Resource Officer",
  },
  {
    id: "officemanager",
    name: "Office Manager",
  },

  {
    id: "salesmanager",
    name: "Sales Manager",
  },
  {
    id: "studentsupportassistant",
    name: "Student Support Assistant",
  },
  {
    id: "specialneedsteacher",
    name: "Special Needs Teacher",
  },
  {
    id: "specialneedsofficer",
    name: "Special Needs Officer",
  },
  // Industrial Sector Start
  {
    id: "workshop_and_production_managers",
    name: "Workshop & Production Managers",
  },
  {
    id: "forklift_truck_drivers_(all_categories)",
    name: "Forklift Truck Drivers (all categories)",
  },
  {
    id: "assembly_or_machine_and_production_operatives",
    name: "Assembly, Machine & Production Operatives",
  },
  {
    id: "warehouse_operatives",
    name: "Warehouse Operatives",
  },
  {
    id: "pickersorpackers",
    name: "Pickers/Packers",
  },
  {
    id: "post_room_staff_and_mail_sorters",
    name: "Post room Staff & Mail Sorters",
  },
  {
    id: "industrial_cleaners",
    name: "Industrial Cleaners",
  },
  {
    id: "security_staff",
    name: "Security Staff",
  },
  {
    id: "grounds_maintenance_staff",
    name: "Grounds Maintenance Staff",
  },
  {
    id: "car_park_attendants",
    name: "Car Park Attendants",
  },
  {
    id: "drivers_mates",
    name: "Drivers Mates",
  },
  {
    id: "porters_and_removal_staff",
    name: "Porters & Removal Staff",
  },
  {
    id: "hgv_or_75_tonne_or_multi-drop_drivers",
    name: "HGV / 7.5 Tonne / Multi-Drop Drivers",
  },
  {
    id: "hgv_and_commercial_vehicle_technicians",
    name: "HGV & Commercial Vehicle Technicians",
  },
  {
    id: "night_shift_operatives",
    name: "Night Shift Operatives",
  },
  {
    id: "setters_and_programmers",
    name: "Setters and Programmers",
  },
  {
    id: "welders_and_fabrications",
    name: "Welders & Fabrications",
  },
  {
    id: "sheet_metal_workers",
    name: "Sheet Metal Workers",
  },
  {
    id: "quality_inspectors",
    name: "Quality Inspectors",
  },
  {
    id: "design_and_development_engineers",
    name: "Design & Development Engineers",
  },
  {
    id: "mechanical_or_electronic_or_electrical_engineers",
    name: "Mechanical / Electronic / Electrical Engineers",
  },
  {
    id: "project_engineers",
    name: "Project Engineers",
  },
  {
    id: "cad_designers",
    name: "CAD Designers",
  },
  // Industrial Sector End

  // Commercial Start
  // {
  //   id: "receptionist",
  //   name: "Receptionist",
  // },
  {
    id: "secretary",
    name: "Secretary",
  },
  // {
  //   id: "office_manager",
  //   name: "Office Manager",
  // },

  {
    id: "personal_or_executive_assistant",
    name: "Personal/ Executive Assistant",
  },
  {
    id: "administrator",
    name: "Administrator",
  },
  {
    id: "hr_administrator",
    name: "HR Administrator",
  },
  {
    id: "facilities_administrator",
    name: "Facilities Administrator",
  },
  {
    id: "marketing_administrator",
    name: "Marketing Administrator",
  },
  {
    id: "customer_service_advisor",
    name: "Customer Service Advisor",
  },
  {
    id: "project_administrator",
    name: "Project Administrator",
  },
  {
    id: "call_centre_advisor",
    name: "Call Centre Advisor",
  },
  {
    id: "call_centre_manager",
    name: "Call Centre Manager",
  },
  {
    id: "help_desk",
    name: "Help Desk",
  },
  // Commercial End

];

export const modeOfTravel = {
  car: "Car",
  bicycle: "Bicycle",
  onfoot: "On Foot",
  publictransport: "Public Transport",
};

export const qualifications = [
  {
    id: "childcarelevel1",
    name: "Level 3 Child Care",
  },
  {
    id: "childcarelevel2",
    name: "Level 2 Child Care",
  },
  {
    id: "childcarelevel2social",
    name: "Level 2 health and Social Care",
  },
  {
    id: "childcarelevel4",
    name: "Level 3 Health and Social Care",
  },
  {
    id: "childcarelevel5",
    name: "QTS qualified",
  },
  {
    id: "healthandsocialcarelevel1",
    name: "PGCE Qualified",
  },
  {
    id: "healthandsocialcarelevel2",
    name: "Bachelor’s Degree",
  },
  {
    id: "healthandsocialcarelevel3",
    name: "Master’s Degree",
  },

  {
    id: "healthandsocialcarelevel4",
    name: "Level 4 Health and Social Care",
  },
  {
    id: "healthandsocialcarelevel5",
    name: "Level 5 Health and Social Care",
  },
  {
    id: "healthandsocialcarelevel6",
    name: "Level 6 Health and Social Care",
  },
  {
    id: "healthandsocialcarelevel7",
    name: "Not Applicable",
  },
  {
    id: "healthandsocialcarelevel8",
    name: "Unqualified",
  },
  {
    id: "healthandsocialcarelevel9",
    name: "Others",
  },
  ];
