import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const JobList = (props) => {
  const [jobs, SetJobs] = useState([]);

  useEffect(() => {
    SetJobs(props.jobs);
  }, [props.jobs]);
  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <table className="table table-striped table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              <th className="col-1">#</th>
              <th>Name</th>
              <th>SKU CODE</th>
              <th>Sector</th>
              <th>Description</th>
              <th>Employement Type</th>
              <th>Contract Type</th>
              <th>Contract Duration</th>
              <th>Country</th>
              <th>Postal Code</th>
              <th>Address</th>
              <th>Min salary</th>
              <th>Max salary</th>
              <th>Salary Type</th>
              <th>End Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {jobs &&
              jobs?.map((job, i) => (
                <tr key={i}>
                  <td className="col-1" scope="row">
                    {i + 1}
                  </td>
                  <td>{job.clt_job_title}</td>
                  <td>{job.clt_job_sku_code}</td>
                  <td>{job.clt_job_sector}</td>
                  <td>{job.clt_job_description}</td>
                  <td>{job.clt_job_employment_type}</td>
                  <td>{job.clt_job_contract_type}</td>
                  <td>{job.clt_job_contract_duration}</td>
                  <td>{job.clt_job_country}</td>
                  <td>{job.clt_job_postcode}</td>
                  <td>{job.clt_job_address}</td>
                  <td>{job.clt_job_salary_min}</td>
                  <td>{job.clt_job_salary_max}</td>
                  <td>{job.clt_job_salary_type}</td>
                  <td>{job.clt_job_end_date}</td>
                  <td>
                    <Link to={"/create-job/" + job.clt_job_id}>
                      <i className="fa fa-pencil"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            {jobs.length == 0 && (
              <tr>
                <td colSpan="100">No jobs Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    jobs: state.jobs.jobs,
  };
};

export default connect(mapStateToProps)(JobList);
