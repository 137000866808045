const initialState = {individual: [],riskProfile:[]}

export const individual = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_INDIVIDUAL_RISK':
      return {...state,individual: action.payload}; 
  	case 'SET_INDIVIDUAL_RISK_MASTER':
      return {...state,riskProfile: action.payload}; 
  }
  return state
}


