import React from "react";
export const capitalizeFirstLetter = (str) => {
  const words = str ? String(str).trim().split(" ") : "";
  if (words) {
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1) + " ";
    }
  }
  return words ? words : "";
};

export const convertToSnakeCase = (str) => {
  // Convert the string to lowercase and replace spaces with underscores
  return str.toLowerCase().replace(/\s+/g, "_");
};
export const convertToOriginalForm = (str) => {
  // Split the string by underscores
  const words = str.split("_");

  // Capitalize the first letter of each word and join them with spaces
  const originalForm = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return originalForm;
};

export const concateAddress = (
  address1 = "",
  address2 = "",
  town = "",
  region = "",
  postal_code = "",
  country = ""
) => {
      return (address1 ? address1 : "") + (address2 ? ", " + address2 : "") + (town ? ", " + town : "") + (region ? ", " + region : "") + (postal_code ? ", " + postal_code : "") + (country ? ", " + country : "")
};
