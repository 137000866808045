import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer as auth } from './reducers/AuthReducer';
import { error } from './reducers/Error';
import { userProfile } from './reducers/UserProfile';
import { userExperience } from './reducers/UserExperience';
import { bankInfo } from './reducers/BankInfo';
import { trainingInfo } from './reducers/TrainingInfoReducer';
import { employementinfo } from './reducers/EmployementReducer';
import { employementGapReducers } from './reducers/EmployementGapReducers';
import { rightToWork } from './reducers/RightToWorkReducers'
import { documentCompliance } from './reducers/DocumentComplianceReducers'
import { identityDocument } from './reducers/IdentityDocumentReducers'
import { identityDocuments } from './reducers/IdentityDocumentsReducers'
import { dbsdocument } from './reducers/DbsDocument'
import { workPrefrences } from './reducers/WorkPrefrencesReducers'
import { workPrefrencesJobTitles } from './reducers/WorkPrefrencesJobTitlesReducers';
import { workPreferenceQualificationDocuments } from './reducers/WorkPrefrencesQualificationDocumentsReducers';
import { billingInfo } from './reducers/BillingInfoReducers'
import { dbsdocuments } from './reducers/DBSDocumentsReducers'
import { companyDetails } from './reducers/CompanyDetails'
import { jobs } from './reducers/Jobs'
import { job } from './reducers/Job'
import { appoinments } from './reducers/Appoinments'
import { careplan } from './reducers/CarePlan'
import { chargerate } from './reducers/ChargeRate'
import { individual } from './reducers/IndividualRisk'
import { assesmentCategory } from './reducers/AssesmentCategory'
import { assesmentShifts } from './reducers/Assesment'
import { switchStep } from './reducers/Switch'
import { careplanmaster } from './reducers/CarePlanMaster'
import { holidays } from './reducers/Holidays'
import { holiday } from './reducers/Holiday'
import { timesheets } from './reducers/TimeSheets'
import { timesheet } from './reducers/TimeSheet';
import { dailylog } from './reducers/DailyLog';
import { dailylogs } from './reducers/DailyLogs';
import { myassignments } from './reducers/MyAssignments';
import { branches } from './reducers/Branches'
import { branch } from './reducers/Branch'
import { avaibility } from './reducers/Avaibility'
import { progress } from './reducers/Progress'
import { rightToWorks } from './reducers/RightToWorks'
import { loader } from './reducers/LoaderReducer'
import { appliedjobs } from './reducers/AppliedJobs';
import { jobsdetails } from './reducers/JobsDetails';
import { fltLicences } from './reducers/FltLicenceReducer';
import { driverDetails } from './reducers/DriverDetailReducer';
import { jobsScreeningQuestions } from './reducers/JobsDetails';
import { individualJobsDetails } from './reducers/JobsDetails';
import { workAndQualificationDetails } from './reducers/CandidateJobProfileWorkQualificationReducer';
import { dailylogsserviceuserlist } from './reducers/DailyLogs';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    loader,
    auth,
    error,
    userProfile,
    userExperience,
    bankInfo,
    trainingInfo,
    employementinfo,
    employementGapReducers,
    rightToWork,
    documentCompliance,
    identityDocument,
    workPrefrences,
    fltLicences,
    driverDetails,
    workPrefrencesJobTitles,
    workPreferenceQualificationDocuments,
    billingInfo,
    dbsdocuments,
    companyDetails,
    jobs,
    appoinments,
    careplan,
    chargerate,
    individual,
    careplanmaster,
    assesmentCategory,
    assesmentShifts,
    switchStep,
    holidays,
    holiday,
    timesheets,
    timesheet,
    dailylog,
    dailylogs,
    myassignments,
    branches,
    branch,
    job,
    avaibility,
    progress,
    rightToWorks,
    identityDocuments,
    dbsdocument,
    appliedjobs,
    jobsdetails,
    jobsScreeningQuestions,
    individualJobsDetails,
    workAndQualificationDetails,
    dailylogsserviceuserlist
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
