import React from "react";
import PageTitleName from "../../src/components/Layout/PageTitleName";
export default function Backgroundimage({ backgroundImage, pageTitle }) {
  return (
    <div
      className="dez-bnr-inr  overlay-black-middle "
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <PageTitleName motherName="Home" activeName={pageTitle} />
    </div>
  );
}
