import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/social-respite-banner.jpg";
// import HomecareImg from "./../images/ourservices/social-respite.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/social-care/childrens-learning-disability-respites/our-services-social-care-children’s-learning-disability-despites.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/social-care/childrens-learning-disability-respites/our-services-social-care-children’s-learning-disability -despites-01.jpg";

import bnr3 from "./../images/lines.png";

export default function Social_Respite() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Children's Learning Disability Despites";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/childrens-learning-disability-respites"
            ? "0rem"
            : ""  }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">
              <b style={{display:"none"}}>Staffing Connect - Our Services - </b>Children's Learning Disability Despites{" "}
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                  {window.location.pathname == "/mobile/childrens-learning-disability-respites" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Social Care</li>
                  <li>Children’s Learning Disability Despites </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">
                    Children’s Learning Disability Despites{" "}
                  </h2>
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div className="col-md-12 col-lg-6 m-b10">
                  <p className="spacebar mt-3"></p>
                  <h6>
                    Delivering Excellence in Social Care Staffing with Staffing
                    Connect
                  </h6>

                  <p className="text-left">
                    Staffing Connect recognizes the unique demands faced by the
                    Care sectors and provides flexible professionals who can
                    maintain the highest service standards. Our experienced team
                    works closely with local Care providers, offering
                    exceptional staffing solutions wherever needed. With a
                    comprehensive range of qualified staff, including Social
                    Workers, Doctors, Nurses, Support Workers, and Health Care
                    Assistants, we facilitate seamless hires for both the Public
                    and Private sectors.
                  </p>

                  <p className="text-left">
                    We understand the importance of expertise and experience in
                    caring for children with Special Educational and Physical
                    Needs. Therefore, all our staff members in this field
                    possess at least one year's hands-on experience in working
                    with such children. They are well-versed in OFSTED
                    requirements, ensuring compliance and adherence to the
                    highest standards of care.
                  </p>
                </div>
              </div>
              <p className="spacebar " style={{ marginBottom: "-5%" }}></p>
              <p className="text-left">
                At Staffing Connect, our mission is to empower the Care sectors
                by providing highly qualified and compassionate professionals
                dedicated to making a positive impact. We carefully select
                candidates with the necessary skills, knowledge, and empathy to
                meet the diverse needs of individuals requiring care.
              </p>

              <p className="text-left">
                Our robust recruitment process involves thorough screening,
                interviews, and verification of qualifications and experience.
                We prioritize candidates with a genuine passion for the field
                and a commitment to delivering outstanding care. Building trust
                and maintaining the well-being of those under our care are
                fundamental responsibilities, and we strive to uphold these
                principles in every placement.
              </p>

              <p className="text-left">
                By partnering with Staffing Connect, Care providers can access a
                reliable and skilled workforce to meet their staffing
                requirements. We offer a personalized approach, understanding
                that every care setting requires unique solutions. Our
                nationwide presence and extensive network allow us to swiftly
                connect you with the right professionals who can seamlessly
                integrate into your team and provide exceptional care.
              </p>

              <p className="text-left">
                Staffing Connect is dedicated to exceeding your expectations and
                supporting your commitment to delivering high-quality care.
                Contact us today to experience our unrivalled staffing solutions
                that empower the Care sectors and enable the provision of
                exceptional care services.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
