import React from 'react'
import { Link } from 'react-router-dom';

// var bnr1 = require('./../images/banner/bnr1.jpg');
var bnr3 = require('./../images/lines.png');

// Newly Added Images
var bnr1 = require('./../images/new-updated-images/coockie-policy/cookie-policy.jpg');

export default function Cookie() {
  // Add title
  document.getElementById("titleText").innerText = "Staffing Connect - Cookie Policy";

  return (
    <>
      <div className="page-wraper">
        <div className="page-content bg-white">
          <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bnr1 + ")", height:580 }}>
            <div className="container">
              <div className="dez-bnr-inr-entry">
                
                <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Cookie Policy Details</b> Cookie Policy</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><a href={"/"}>Home</a></li>
                    <li>Cookie Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <div className="section-full content-inner overlay-white-middle">
              <div className="container">
                <div className="row align-items-center m-b50">
                  <div className="section-head text-black text-center col-lg-12">
                    <h2 className="text-uppercase m-b0">Cookie Policy</h2>
                  </div>
                  <div className='col-lg-12'>
                    <p>
                      We’d like to put your mind at rest about cookies. If you are uncertain about what a cookie is have a look at our simple guide to find out how we use them on our website.
                    </p>
                    <p>
                      Staffing Connect (Trading name of Care Education Ltd) do not use category 4 cookies within our website, but we do have links to external websites.
                      OUR SIMPLE GUIDE TO COOKIES
                    </p>
                    <p>
                      What is a cookie?
                      <br />Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises that cookie.
                    </p>
                    <p>
                      Cookies do lots of different jobs, like letting you navigate between pages efficiently remembering your preferences and generally improve your web site experience. They can also help to ensure that adverts you see online are more relevant to you and your interests.
                    </p>
                    <p>
                      We can split cookies into 4 main categories:
                      <br />Category 1: strictly necessary cookies
                      <br />Category 2: performance cookies
                      <br />Category 3: functionality cookies
                      <br />Category 4: targeting cookies or advertising cookies
                    </p>
                    <p>
                      Category 1 - Strictly necessary cookies
                      <br />These cookies are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies services you have asked for, like register for job alerts, cannot be provided.
                      <br />Please be aware our site uses this type of cookie
                    </p>
                    <p>
                      Category 2 - Performance cookies
                      <br />These cookies collect information about how visitors use a website, for instance which pages visitors go to most often, and if they get error messages from web pages. These cookies don’t collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous. It is only used to improve how a website works. By using our website and online services, you agree that we can place these types of cookies on your device.
                    </p>
                    <p>
                      Category 3 - Functionality cookies
                      <br />These cookies allow the website to remember choices you make (such as your user name and password) and provide enhanced, more personal features.
                      <br />These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymous and they cannot track your browsing activity on other websites.
                      <br />By using our website and online services, you agree that we can place these types of cookies on your device.
                    </p>
                    <p>
                      Category 4 - targeting cookies or advertising cookies
                      <br />These cookies are used to deliver adverts more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They remember that you have visited a website and this information is shared with other organisations such as advertisers. Quite often targeting or advertising cookies will be linked to site functionality provided by the other organisations.
                      <br />We do have links to other web sites and once you access another site through a link that we have provided it is the responsibility of that site to provide information as to how they use cookies on the respective site.
                    </p>
                    <p>
                      You can find more information about cookies by visiting <a href='www.allaboutcookies.org'>www.allaboutcookies.org</a>
                    </p>
                    <p>
                      Google also have a brief video which explains the use of cookies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" content-inner-2 footerImage footerImageHome" style={{backgroundImage:"url( " + bnr3 + ")", backgroundPosition:"bottom", backgroundRepeat:"repeat-x"}}>
					</div>  
          </div>
        </div>
      </div>
    </>
  )
}
