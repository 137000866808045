import React from "react";
import { connect } from "react-redux";
import { Input, Textarea, Dropdown } from "../../FormElements/Forms";
const DBSYesForm = (props) => {
  const { x, handleInputChange, i, errors, dbsType } = props;
  let errorMsg = false;
  let cerificateNumber = false;
  if (!x.cnd_dbs_certificate_exp_date) {
    errorMsg = true;
  } else {
    let issueDate = new Date(String(x.cnd_dbs_certificate_exp_date));
    let currentDate = new Date();
    let cnd_dbs_certificate_exp_date = document.getElementById(
      "cnd_dbs_certificate_exp_date"
    );
    let minDate = new Date(String(cnd_dbs_certificate_exp_date.min));
    if (issueDate > currentDate) {
      errorMsg = true;
    } else if (minDate > issueDate) {
      errorMsg = true;
    } else {
      errorMsg = false;
    }
  }

  if (String(x.cnd_dbs_holder_certificate_number).trim() == "") {
    cerificateNumber = true;
  }

  return (
    <React.Fragment>
      {/*<Dropdown
        changeHandler={(e) => handleInputChange(e,i)}
        value={x.cnd_dbs_certificate_doc_master_id}
        options={[
                  {value:'',name:'Select Certificate'},
                  {value:'dbscertificate',name:'DBS Certificate'},
                  {value:'newdbscertificate',name:'New DBS Certificate'},
                  {value:'updateserviceconfirmation',name:'Update Service Confirmation'},
                ]}
        name="cnd_dbs_certificate_doc_master_id"
        errors={errors && errors[i]}
        label="Select Document"
        
              />*/}
      {/*x.cnd_dbs_certificate_doc_master_id == "dbscertificate" ? "Yes" : "No"*/}
      <Input
        type="file"
        label="DBS Certificate"
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_dbs_certificate_upload_file"
        lg={6}
        md={6}
        placeholder="DBS Certificate"
        errors={!x.cnd_dbs_certificate_upload_file && errors && errors[i]}
      />
      <Input
        type="text"
        label="DBS Certificate Number"
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_dbs_holder_certificate_number"
        lg={6}
        md={6}
        maxLength={12}
        min={12}
        value={x.cnd_dbs_holder_certificate_number}
        placeholder="Eg. 123456789"
        errors={
          cerificateNumber == true
            ? errors[i]
            : !x.cnd_dbs_holder_certificate_number && errors[i]
        }
      />
      <Input
        type="date"
        label="Document Date of Issue"
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_dbs_certificate_exp_date"
        id={"cnd_dbs_certificate_exp_date"}
        lg={6}
        md={6}
        min={
          new Date(new Date().setFullYear(new Date().getFullYear() - 63))
            .toISOString()
            .split("T")[0]
        }
        max={new Date().toISOString().split("T")[0]}
        value={x.cnd_dbs_certificate_exp_date}
        placeholder="Eg. 10/10/2023"
        errors={errorMsg == true && errors[i]}
      />
      <Dropdown
        changeHandler={(e) => handleInputChange(e, i)}
        value={x.cnd_dbs_certificate_update_service}
        options={[
          { value: "", name: "Is Your DBS on Update Service?" },
          { value: "yes", name: "Yes" },
          { value: "no", name: "No" },
        ]}
        name="cnd_dbs_certificate_update_service"
        errors={!x.cnd_dbs_certificate_update_service && errors && errors[i]}
        label="Are You On Update Service?"
        // req={false}
      />

      {x.cnd_dbs_certificate_doc_master_id == "updateserviceconfirmation" && (
        <Input
          type="text"
          label="Document Reference Number"
          changeHandler={(e) => handleInputChange(e, i)}
          name="cnd_dbs_certificate_reference"
          lg={6}
          md={6}
          value={x.cnd_dbs_certificate_reference}
          placeholder="Document Reference Number"
          errors={errors[i]}
          req={false}
        />
      )}

      <Textarea
        changeHandler={(e) => handleInputChange(e, i)}
        //type="file"
        label="Please provide the Disclosures as per your Certificate"
        name="cnd_dbs_certificate_description"
        lg={12}
        md={12}
        value={x.cnd_dbs_certificate_description}
        placeholder="Please provide the Disclosures as per your Certificate"
        //errors={errors[i]}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
  };
};

export default connect(mapStateToProps)(DBSYesForm);
