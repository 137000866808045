import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Primarybanner.jpg";
// import HomecareImg from "./../images/ourservices/Primary.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/education/edu-primary/our-services-edu-primary.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/education/edu-primary/our-services-edu-primary-01.jpg";
// import Footer from '../components/Layout/Footer';
import bnr3 from "./../images/lines.png";

function Primary() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Our Services - Primary";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop: (window.location.pathname == "/mobile/primary" ? "0rem" : "")}}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services -</b>Primary</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                  {window.location.pathname == "/mobile/primary" ? ("Home") : (<a href={"/"}>Home</a>)}  
                  </li>
                  <li>Our Services</li>
                  <li>Education</li>
                  <li>Primary</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Primary </h2>
                  {/* <p>Why, hello there! Want to see more of our work? Check out just some of what we've been up to below.</p> */}
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div
                  className="col-md-12 col-lg-6 "
                  style={{ marginBottom: "-5%" }}
                >
                  {/* <h2 className="m-b5"> Home Care</h2> */}
                  <p className="spacebar mt-4"></p>
                  <h6>
                    Staffing Excellence for Primary Schools: Your Trusted
                    Partner for Supply Teachers
                  </h6>
                  <p className="text-left">
                    <p className="text-left">
                      Driven by our team of experienced educators with unmatched
                      local understanding, we specialize in helping schools
                      across the South of England secure the best supply of
                      teachers. Renowned for our emphasis on quality, we've
                      successfully resolved staffing issues for thousands of
                      schools, providing solutions for short-term and long-term
                      teaching cover.
                    </p>

                    <p className="text-left">
                      Our service assigns each school a dedicated consultant
                      familiar with your locality. They are ready to address all
                      your staffing requirements, ensuring our solutions fit
                      your unique needs seamlessly. With an impressive pool of
                      over 1.4 million qualified candidates, we can provide
                      supply teachers at the drop of a hat.
                    </p>
                  </p>
                </div>

                <p className="text-left mt-4">
                  Our consultants adhere to the highest ethical standards. Every
                  candidate recommended by our education specialists has
                  undergone a face-to-face interview and rigorous background
                  checks. We continually collect feedback on our teachers'
                  performance to uphold our exceptionally high standards.{" "}
                </p>

                <p className="text-left">
                  As a prospective supply teacher working with us, you'll enjoy
                  competitive pay rates, flexible work schedules, and access to
                  our network of thousands of exceptional schools. We take the
                  time to understand your specific needs and career goals,
                  matching your skills, experience, and personality to the vast
                  supply bookings we secure each week. This guarantees we'll
                  have the right opportunity that fits your availability and
                  preference.{" "}
                </p>

                <p className="text-left">
                  Our supply service is tailored to the individual needs of our
                  teachers and the schools we partner with. We leverage our
                  extensive knowledge of the education sector to ensure that all
                  our placements benefit the school and the teacher. With us,
                  you can rest assured that your staffing needs or career
                  aspirations are in capable hands.
                </p>
              </div>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
export default Primary;
