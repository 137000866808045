import { yupToFormErrors } from "formik";
import { main } from "react-devtools";
import addDays from "date-fns/addDays";
import * as Yup from "yup";

//To Get Previous Mondays only for Week Commencing
const isWeekday = () => {
  let date = new Date();
  let sub = date.getDay() > 0 ? 1 : -6;
  let monday = new Date(date.setDate(date.getDate() - date.getDay() + sub));
  return monday.toISOString().split("T")[0];
};
export const formInputFields = () => [
  {
    name: "cnd_timesheet_fname",
    type: "text",
    label: "First Name",
    disabled: true,
    md: "6",
    sm: "6",
  },
  {
    name: "cnd_timesheet_lname",
    type: "text",
    label: "Last Name",
    disabled: true,
    md: "6",
    sm: "6",
  },
  {
    name: "cnd_timesheet_email",
    type: "text",
    disabled: true,
    label: "Email",
    md: "6",
    sm: "6",
  },
  {
    name: "cnd_timesheet_client_name",
    type: "text",
    label: "Client Name",
    md: "6",
    sm: "6",
  },
  {
    name: "cnd_timesheet_week_commencing",
    type: "date",
    label: "Week Commencing",
    md: "6",
    sm: "6",
    max: new Date().toISOString().split("T")[0],
    defaultValue: isWeekday(),
    //value: isWeekday(),
    step: 7,

    //min:addDays(new Date().toISOString().split("T")[0], ),
  },
  {
    name: "cnd_timesheet_upload_document",
    type: "file",
    label: "Upload Timesheet",
    md: "6",
    sm: "6",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const yesterday = new Date(Date.now() - 86400000);
const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
export const validationSchema = Yup.object().shape({
  cnd_timesheet_fname: Yup.string()
    .required("First Name Field Required")
    .matches(/^[a-z A-Z]+$/, "Only Letters Allowed"),
  cnd_timesheet_lname: Yup.string()
    .required("Last Name Required")
    .matches(/^[a-z A-Z]+$/, "Only Letters Allowed"),
  cnd_timesheet_week_commencing: Yup.string()
    .required("Week commecing date is required!")
    .matches(
      /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
      "Please slect the date from calendar in DD-MM-YYYY format!"
    ),
  cnd_timesheet_email: Yup.string()
    .email()
    .required("Email Field Required")
    .typeError("Please Enter Valid Email"),
  cnd_timesheet_client_name: Yup.string()
    .trim("")
    .required("Client Name Field Required"),
  cnd_timesheet_upload_document: Yup.mixed()
    .required("File is required")
    .test("checkFile", "File Required", (value) => {
      if (value.length == 0) return false;
      else return true;
    }),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
