const initialState = {
  authUserData: {
    users_access_token:'',
    users_email_id:'',
    users_firstname:'',
    users_lastname:''
  },
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export const AuthReducer=(state = initialState, action)  =>       {
  switch (action.type) {
    case 'SET_AUTH_DATA':
      return {authUserData: action.payload}; 
  }
  return state
}
