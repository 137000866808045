import React, { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { Form, Col, Spinner, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Label from "./Label";
//import Form from "react-datepicker";
import Countries from "../Element/Countries";
import { ref } from "yup";
const LoadFormFields = (props) => {
  let sigCanvas = useRef({});
  const {
    formInputFields,
    buttonDisabled,
    errors,
    register,
    documentUrl,
    backUrl,
    data,
    onChangeInputvalue,
    loader,
    reset,
    loadExternalButtons,
    dispatch,
    control,
    key,
    addMoreButton,
    addMore,
    arrayForm,
    backButton,
    ...rest
  } = props;
  const history = useHistory();
  let dataURL;
  const formatIntoPng = () => {
    let user_term_condition = document.getElementById("user_term_condition");
    let user_sign_signature = document.getElementById("user_sign_signature");
    let submitCommonButtonDisabled =
      document.getElementById("submitCommonButton");
    if (sigCanvas.current) {
      dataURL = sigCanvas.current.toDataURL();
      // console.log("Format Into PNG :=> "+dataURL);
      // console.warn("user_term_condition :=> "+user_term_condition.checked);
      user_sign_signature.value = dataURL;
      if (user_sign_signature.value && user_term_condition.checked == true) {
        submitCommonButtonDisabled.disabled = false;
      } else {
        submitCommonButtonDisabled.disabled = true;
      }
      return dataURL;
    }
  };
  // Clear Canvas Start
  const clearSign = () => {
    let submitCommonButtonDisabled =
      document.getElementById("submitCommonButton");
    sigCanvas.current.clear();
    submitCommonButtonDisabled.disabled = true;
  };
  // Clear Canvas End

  if (window.location.pathname.includes("/create-timesheet/")) {
    let colSizeMd = "4";
    let colSizeMdUrl = "2";
  } else {
    let colSizeMd;
    let colSizeMdUrl;
  }

  return (
    <React.Fragment key={Date.now()}>
      <div className="row m-b30">
        {formInputFields.map((form, formKey, colSizeMd, colSizeMdUrl) => (
          <React.Fragment key={formKey + form.name}>
            {(form.type == "text" ||
              form.type == "numeric" ||
              form.type == "email" ||
              form.type == "password") && (
              <Form.Group
                as={Col}
                controlId={formKey}
                key={formKey}
                md={form.md}
                sm={form.sm}
              >
                <Label
                  labelName={form.label}
                  req={
                    form.name == "cnd_holiday_employee_no" &&
                    window.location.pathname.includes("/create-holiday")
                      ? false
                      : true
                  }
                />
                <Form.Control
                  {...rest}
                  disabled={form.disabled && form.disabled}
                  label={form.label}
                  placeholder={form.label}
                  max={form.max && form.max}
                  min={form.min && form.min}
                  value={form.value && form.value}
                  type={form.type}
                  {...register(form.name, {
                    onChange: (e) => {
                      onChangeInputvalue(e, key && key);
                    },
                  })}
                  isInvalid={
                    arrayForm
                      ? errors && errors[form.field]
                        ? true
                        : ""
                      : errors && errors[form.name]
                      ? true
                      : ""
                  }
                  isValid={
                    arrayForm
                      ? data[key] && data[key][form.name]
                        ? true
                        : false
                      : data && data[form.name]
                      ? true
                      : false
                  }
                />
                {arrayForm ? (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.field]?.message}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}

            {form.type == "file" && (
              <Form.Group
                as={Col}
                controlId={formKey}
                key={formKey}
                md={colSizeMd == "" ? form.md : colSizeMd}
                sm={colSizeMd == "" ? form.sm : colSizeMd}
              >
                <Label labelName={form.label} />
                <Form.Control
                  {...rest}
                  disabled={form.disabled && form.disabled}
                  label={form.label}
                  placeholder={form.label}
                  max={form.max && form.max}
                  accept=".pdf,.jpg,.jpeg,.png"
                  type={form.type}
                  className={form.class}
                  {...register(form.name, {
                    validateOnBlur: true,
                    onChange: (e) => {
                      onChangeInputvalue(e, key && key);
                    },
                  })}
                  isInvalid={
                    arrayForm
                      ? errors && errors[form.field]
                        ? true
                        : ""
                      : errors && errors[form.name]
                      ? true
                      : ""
                  }
                  isValid={
                    arrayForm
                      ? data[key] && data[key][form.name]
                        ? true
                        : false
                      : data && data[form.name]
                      ? true
                      : false
                  }
                />

                {arrayForm ? (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.field]?.message}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}

            {(window.location.pathname.includes("create-timesheet/") ||
              window.location.pathname.includes("create-right-to-work/") ||
              window.location.pathname.includes("create-identity-document/")) &&
              form.type == "file" && (
                <Form.Group
                  as={Col}
                  controlId={formKey}
                  key={formKey}
                  md={colSizeMdUrl == "" ? form.md : colSizeMdUrl}
                  sm={colSizeMdUrl == "" ? form.sm : colSizeMdUrl}
                >
                  <br />
                  <a
                    rel="noopener noreferrer"
                    download
                    href={documentUrl}
                    target="_blank"
                    className="link-primary"
                  >
                    <i className="fa fa-download f-26"></i>
                    View Document
                  </a>
                </Form.Group>
              )}

            {form.type == "date" && (
              <Form.Group
                as={Col}
                controlId={formKey}
                key={formKey}
                md={form.md}
                sm={form.sm}
              >
                <Label labelName={form.label} />
                <Form.Control
                  {...rest}
                  disabled={form.disabled && form.disabled}
                  label={form.label}
                  name={form.name}
                  max={form.max && form.max}
                  min={form.min && form.min}
                  id={form.id && form.id}
                  //value={form.value && form.value}
                  defaultValue={form.defaultValue && form.defaultValue}
                  step={form.step && form.step}
                  type={form.type}
                  {...register(form.name, {
                    onChange: (e) => {
                      onChangeInputvalue(e);
                    },
                  })}
                  isInvalid={
                    arrayForm
                      ? errors && errors[form.field]
                        ? true
                        : ""
                      : errors && errors[form.name]
                      ? true
                      : ""
                  }
                  isValid={
                    arrayForm
                      ? data[key] && data[key][form.name]
                        ? true
                        : false
                      : data && data[form.name]
                      ? true
                      : false
                  }
                />
                {arrayForm ? (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.field]?.message}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
            {form.type == "sign" && (
              <Form.Group
                as={Col}
                controlId={formKey}
                key={formKey}
                md={form.md}
                sm={form.sm}
              >
                <Label labelName={form.label} />
                {sigCanvas && (
                  <a
                    style={{ cursor: "pointer" }}
                    className="m-b30 text-danger pull-right"
                    onClick={clearSign}
                    title="Clear Signature"
                  >
                    <b>Clear</b>
                  </a>
                )}
                <Controller
                  name={form.name}
                  control={control && control}
                  render={({ field }) => (
                    <SignatureCanvas
                      ref={sigCanvas}
                      onEnd={(e) => field.onChange(formatIntoPng())}
                      penColor="black"
                      clearOnResize={false}
                      canvasProps={{
                        id: "user_sign_signature",
                        className: errors[form.name] ? "is-invalid" : "",
                        style: {
                          border: "1px solid green",
                          width: "100%",
                          height: "200px",
                        },
                      }}
                    />
                  )}
                />

                {arrayForm ? (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.field]?.message}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}

            {form.type == "textarea" && (
              <Form.Group
                as={Col}
                controlId={formKey}
                key={formKey}
                md={form.md}
                sm={form.sm}
              >
                <Label
                  labelName={form.label}
                  req={
                    window.location.pathname.includes(
                      "/create-right-to-work"
                    ) ||
                    window.location.pathname.includes(
                      "/create-identity-document"
                    ) ||
                    window.location.pathname.includes("/create-holiday")
                      ? false
                      : true
                  }
                />
                <Form.Control
                  as="textarea"
                  {...rest}
                  label={form.label}
                  name={form.name}
                  type={form.type}
                  {...register(form.name)}
                  onKeyUp={(e) => onChangeInputvalue(e)}
                  isInvalid={
                    arrayForm
                      ? errors && errors[form.field]
                        ? true
                        : ""
                      : errors && errors[form.name]
                      ? true
                      : ""
                  }
                  isValid={
                    arrayForm
                      ? data[key] && data[key][form.name]
                        ? true
                        : false
                      : data && data[form.name]
                      ? true
                      : false
                  }
                />
                {arrayForm ? (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.field]?.message}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
            {(form.type == "radio" || form.type == "checkbox") && (
              <Form.Group as={Col} key={formKey} md={form.md} sm={form.sm}>
                {form.label && <Label labelName={form.label} />}
                {form.inline && <div col={2}></div>}
                {form.options.map((option, key) => (
                  <Form.Check
                    inline={form.inline && form.inline}
                    key={option + key}
                    label={option.name}
                    name={form.name}
                    value={option.id}
                    type={form.type}
                    id={
                      form.name == "user_term_condition"
                        ? form.id
                        : option.id + key + form.label
                    }
                    {...register(form.name)}
                    isInvalid={
                      arrayForm
                        ? errors && errors[form.field]
                          ? true
                          : ""
                        : errors && errors[form.name]
                        ? true
                        : ""
                    }
                    isValid={
                      arrayForm
                        ? data[key] && data[key][form.name]
                          ? true
                          : false
                        : data && data[form.name]
                        ? true
                        : false
                    }
                  />
                ))}
                {arrayForm ? (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.field]?.message}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}

            {form.type == "dropdown" && (
              <Form.Group
                as={Col}
                controlId={formKey}
                key={formKey}
                md={form.md}
                sm={form.sm}
              >
                <Label labelName={form.label} />
                <Form.Control
                  as="select"
                  label={form.label}
                  {...rest}
                  name={form.name}
                  type={form.type}
                  {...register(form.name, {
                    onChange: onChangeInputvalue,
                  })}
                  isInvalid={
                    arrayForm
                      ? errors && errors[form.field]
                        ? true
                        : ""
                      : errors && errors[form.name]
                      ? true
                      : ""
                  }
                  isValid={
                    arrayForm
                      ? data[key] && data[key][form.name]
                        ? true
                        : false
                      : data && data[form.name]
                      ? true
                      : false
                  }
                >
                  {form.options.map((option, dkey) => (
                    <option
                      disabled={
                        option.disabled || option.disabled == false
                          ? option.disabled
                          : dkey == "0" && true
                      }
                      key={dkey + Date.now()}
                      value={option.id}
                    >
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
                {arrayForm ? (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.field]?.message}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
            {form.type == "country" && (
              <Form.Group
                as={Col}
                controlId={formKey}
                key={formKey}
                md={form.md}
                sm={form.sm}
              >
                <Label labelName={form.label} />
                <Form.Control
                  as="select"
                  label={form.label}
                  {...rest}
                  name={form.name}
                  type={form.type}
                  {...register(form.name, {
                    onChange: onChangeInputvalue,
                  })}
                  isInvalid={
                    arrayForm
                      ? errors && errors[form.field]
                        ? true
                        : ""
                      : errors && errors[form.name]
                      ? true
                      : ""
                  }
                  isValid={
                    arrayForm
                      ? data[key] && data[key][form.name]
                        ? true
                        : false
                      : data && data[form.name]
                      ? true
                      : false
                  }
                >
                  <Countries />
                </Form.Control>
                {arrayForm ? (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.field]?.message}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    {errors && errors[form.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
          </React.Fragment>
        ))}
        {!loadExternalButtons && (
          <Form.Group as={Col} md="12" sm="12">
            <Button
              /*disabled={
                loader || (buttonDisabled && buttonDisabled) ? "disabled" : ""
              }*/
              className="site-button m-b30 float-right"
              type="submit"
              title={
                window.location.pathname == "/sign-contract"
                  ? "Write the signature and Accept the Terms & Conditions to Proceed"
                  : ""
              }
              disabled={
                window.location.pathname == "/sign-contract" ? true : false
              }
              id="submitCommonButton"
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Save"}
            </Button>
            {/*{window.location.pathname   != "/create-right-to-work" && window.location.pathname != "/create-right-to-work" && window.location.pathname != "/create-identity-document"  && (*/}
            <Link
              //onClick={()=>history.goBack('')}
              to={backUrl}
              className="site-button yellow m-b30 m-l5 mr-2 pull-right"
              style={{
                display:
                  window.location.pathname == "/sign-contract" ? "none" : "",
              }}
            >
              Back
            </Link>
            {/*})}*/}
            {addMoreButton && (
              <Button
                className="m-l5 btn-info m-b30 mr-2 pull-right"
                onClick={addMore}
              >
                Add More
              </Button>
            )}
            {/*{
              backButton && 
              <Button
                className="site-button yellow m-b30 m-l5"
                onClick={()=>history.goBack()}
              >
                Back
            </Button>
            }*/}
            {/* <button
              disabled={loader ? "disabled" : ""}
              className="m-b30 m-l5 btn btn-warning"
              onClick={() => reset()}
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Reset"}
            </button> */}
          </Form.Group>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader.loader,
  };
};
export default connect(mapStateToProps)(LoadFormFields);
