import React from 'react'
import { Link } from 'react-router-dom';

// var bnr1 = require('./../images/banner/bnr1.jpg');
var bnr3 = require('./../images/lines.png');
// Newly Added Images
var bnr1 = require('../images/new-updated-images/privacy-policy/privacy-policy.jpg');


export default function Privacy() {
  // Add title
  document.getElementById("titleText").innerText = "Staffing Connect - Privacy Policy";
  
  return (
    <>
      <div className="page-wraper">
        <div className="page-content bg-white">
          <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bnr1 + ")", height:580 }}>
            <div className="container">
              <div className="dez-bnr-inr-entry">
                <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Privacy Policy Details</b>Privacy Policy</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><a href={"/"}>Home</a></li>
                    <li>Privacy Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <div className="section-full content-inner overlay-white-middle">
              <div className="container">
                <div className="row align-items-center m-b50">
                  <div className="section-head text-black text-center col-lg-12">
                    <h2 className="text-uppercase m-b0">Privacy Policy</h2>
                    
                  </div>
                  <div className='col-lg-12'>
                  <p><h6>Company Name:</h6> Care Education Ltd (Trading as Staffing Connect) <br /><br />
                      <h6>Subject:</h6> GDPR Privacy Notice/ Data Protection <br />
                      Version V1</p>
                    <p>
                    The Company is a recruitment business which provides work-finding services to its clients and work-seekers. The Company must process personal data (including sensitive personal data) so that it can provide these services – in doing so, the Company acts as a data controller
                    </p>
                    <p>
                    You may give your personal details to the Company directly, such as on an application (i.e your CV,) registration form or via our website, or we may collect them from another source such as a job board. The Company must have a legal basis for processing your personal data. For the purposes of providing you with work-finding services and/or information relating to roles relevant to you, we will only use your personal data in accordance with the terms of the following statement:
                      <li className='ml-2'>Collection and use of personal data.</li>
                      <li className='ml-2'>Purpose of processing and legal basis.</li>
                    </p>
                    <p>
                    The Company will collect your personal data (which may include sensitive personal data) and will process your personal data for the purposes of providing you with work-finding services
                    </p>
                    <p>
                    The legal bases we rely upon to offer these services to you are:
                    </p>
                    <h6>Legitimate interest</h6>
                      <p>
                      We require the information you have supplied to enable us to successfully find you a new position that meets your expectations.
                      </p>
                      <h6 >Recipient/s of data</h6>
                      <p>
                      We will forward your details to companies on your behalf after we have discussed job opportunities with you.
                      </p>
                      <p>
                      The Company will not process your data with any other third party without your permission unless we are legally obliged to do so.
                      </p>
                      <h6>Statutory/contractual requirement</h6>
                      <p>
                      Your personal data is not required as part of a statutory and/or contractual requirement, and/or a requirement necessary to enter into a contract.
                      </p>
                      <h6>Data retention</h6>
                      <p>
                      The Company will retain your personal data only for as long as is necessary. Different laws require us to keep different data for different periods of time.
                      </p>
                    
                    <p>
                    The Conduct of Employment Agencies and Employment Businesses Regulations 2003, require us to keep work-seeker records for at least one year from (a) the date of their creation or (b) after the date on which we last provide you with work-finding services.

We must also keep your payroll records, holiday pay, sick pay and pensions auto-enrolment records for as long as is legally required by HMRC and associated national minimum wage, social security and tax legislation.
                    </p>
                    <p>
                    Where the Company has obtained your consent to process your personal/ and sensitive personal data including the details on your CV, your salary expectations, your work aspirations etc we will do so in line with our retention policy which will be 2 years.
                    </p>
                    <p>
                    Upon expiry of that period, the Company will seek further consent from you. Where consent is not granted the Company will cease to process your personal data.
                    </p>
                    <h6>Your rights </h6>
                    <p>Please be aware that you have the following data protection rights:</p>
                    <p>
                      
                        <li className='ml-3'>
                        The right to be informed about the personal data the Company processes on you;
                        </li>

                        <li className='ml-3'>
                        The right of access to the personal data the Company processes on you;
                        </li>
                        <li className='ml-3'>
                        The right to rectification of your personal data;
                        </li>
                        <li className='ml-3'>
                        The right to erasure of your personal data in certain circumstances;
                        </li>
                        <li className='ml-3'>
                        The right to restrict processing of your personal data;
                        </li>
                        <li className='ml-3'>
                        The right to data portability in certain circumstances;
                        </li>
                        <li className='ml-3'>
                        The right to object to the processing of your personal data that was based on a public or legitimate interest;
                        </li>

                        <li className='ml-3'>
                        The right not to be subjected to automated decision making and profiling;
                        </li>
                        <li className='ml-3'>
                        The right to withdraw consent at any time.
                        </li>
                    </p>
                    <p>
                    Where you have consented to the Company processing your personal data you have the right to withdraw that consent at any time by contacting the person named on the email sent to you or Jalak Mody General Manager Care Education Ltd (Trading as Staffing Connect).
                    </p>
                    
                    <h6>Complaints or queries</h6>
                    <p>
                    If you wish to complain about this privacy notice or any of the procedures set out in it please contact the person named on a Staffing Connect email sent to you or Jalak Mody General Manager Care Education Ltd (Trading as Staffing Connect).
                    </p>
                    <p>
                    You also have the right to raise concerns with the Information Commissioner’s Office on <a href='tel:0303 123 1113'>0303 123 1113</a> or at <a href='https://ico.org.uk/concerns/'>https://ico.org.uk/concerns/</a>, or any other relevant supervisory authority should your personal data be processed outside of the UK, if you believe that your data protection rights have not been adhered to
                    </p>
                    <p className="text-right">
                      Staffing Connect (Trading Name of Care Education Ltd)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" content-inner-2 footerImage footerImageHome" style={{backgroundImage:"url( " + bnr3 + ")", backgroundPosition:"bottom", backgroundRepeat:"repeat-x"}}>
					</div>            
          </div>
        </div>
      </div>
    </>
  )
}
