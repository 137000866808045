import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../redux/actions/CommonActions";
const CompanyProfileSideBar = (props) => {
  const dispatch = useDispatch();
  const { users_firstname, users_lastname, cnd_cv_forderpath } = props.userData;

  const { users_access_token } = props.userAuth;

  const { users_profile_pic, users_profile_img_path } = props.clientPhoto;
  const { page } = props;
  const { users_category } = props.userAuth;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "140px";
      imageRef.current.style.height = "145px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(
      updateDataOnRequest(
        fd,
        "ClientImageUpdate",
        "",
        getDataOnRequest(
          users_access_token,
          "GetClientImage",
          "SET_CLIENT_PHOTO"
        )
      )
    );
  };

  useEffect(() => {
    if (users_access_token)
      dispatch(
        getDataOnRequest(
          users_access_token,
          "GetClientImage",
          "SET_CLIENT_PHOTO"
        )
      );
  }, [users_access_token]);

  useEffect(() => {
    if (users_profile_pic) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
  }, [users_profile_img_path, users_profile_pic]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };
  // sticky-top
  return (
    <div className="col-xl-3 col-lg-4 m-b30">
      <div className="">
        <div className="candidate-info">
          <div className="candidate-detail text-center">
            <div className="canditate-des">
              <Link to={""}>
                <img
                  width={"136px"}
                  height={"136px"}
                  ref={imageRef}
                  onError={addDefaultSrc}
                  src={require("./../../images/team/pic1.png")}
                />
              </Link>
              <div
                className="upload-link"
                title="update"
                data-toggle="tooltip"
                data-placement="right"
                style={{ marginTop: "50%" }}
              >
                <input
                  type="file"
                  className="update-flie"
                  onChange={(e) => onChangeInputvalue(e)}
                />
                <i className="fa fa-camera"></i>
              </div>
            </div>
            <div className="candidate-title">
              <div className="">
                <h4 className="m-b0">
                  <Link to={"#"}>
                    {users_firstname} {users_lastname}
                  </Link>
                </h4>
                <p className="m-b0">
                  <Link to={"#"}>{props.userAuth.users_category}</Link>
                </p>
              </div>
            </div>
            <ul className="text-left mt-3">
              {(users_category == "social care" ||
                users_category == "education") && (
                <>
                  <li>
                    <Link
                      to={"/client-dashboard"}
                      className={`${
                        window.location.pathname == "/client-dashboard" &&
                        "active"
                      }`}
                    >
                      <i className="fa  fa-bar-chart" aria-hidden="true"></i>
                      <span className="text-md">
                        <b>Dashboard</b>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/company-details"}
                      className={`${page == "company-details" && "active"}`}
                    >
                      <i className="fa fa-user-o" aria-hidden="true"></i>
                      <span>Company Profile</span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to={"/job"} className={`${page == "job" && "active"}`}>
                    <i className="fa fa-black-tie" aria-hidden="true"></i>
                    <span>Job</span>
                  </Link>
                </li> */}
                </>
              )}
              {/* <li>
              <Link
                to={"/assignments"}
                className={`${page == "assignments" && "active"}`}
              >
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                <span>Assignmets/Shifts</span>
              </Link>
            </li> */}
              <li>
                <Link
                  to={"/job-assesments"}
                  className={`${page == "job-assesments" && "active"}`}
                >
                  <i className="fa fa-briefcase" aria-hidden="true"></i>
                  <span>Job Assesments</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/care-plan"}
                  className={`${page == "care-plan" && "active"}`}
                >
                  <i className="fa fa-medkit" aria-hidden="true"></i>
                  <span>Care Plan</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/care-plan-next"}
                  className={`${page == "care-plan-next" && "active"}`}
                >
                  <i className="fa fa-medkit" aria-hidden="true"></i>
                  <span>Care Plan 2</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/placement"}
                  className={`${page == "placement" && "active"}`}
                >
                  <i className="fa fa-medkit" aria-hidden="true"></i>
                  <span>Placement/Service Aims</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/charge-rate"}
                  className={`${page == "charge-rate" && "active"}`}
                >
                  <i className="fa fa-dollar" aria-hidden="true"></i>
                  <span>Charge Rate</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/individual-risk-profile"}
                  className={`${page == "individual-risk-profile" && "active"}`}
                >
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span>Individual Risk Profile</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/assesment-categories"}
                  className={`${page == "assesment-categories" && "active"}`}
                >
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span>Assesment Category Profile</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/tob-temp"}
                  className={`${page == "tob-temp" && "active"}`}
                >
                  <i className="fa fa-file" aria-hidden="true"></i>
                  <span>TOB Temprory</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/tob-permanent"}
                  className={`${page == "tob-permanent" && "active"}`}
                >
                  <i className="fa fa-file" aria-hidden="true"></i>
                  <span>TOB Permanent</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/branch"}
                  className={`${page == "branch" && "active"}`}
                >
                  <i className="fa fa-file" aria-hidden="true"></i>
                  <span>Branch</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userProfile.userData,
    clientPhoto: state.userProfile.clientPhoto,
    userAuth: state.auth.authUserData,
  };
};

export default connect(mapStateToProps)(CompanyProfileSideBar);
