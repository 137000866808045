import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { documentComplianceList } from "../../../helpers/DocumentsList";
import constants from "../../../constants";
const DocumentComplianceTable = (props) => {
  const [documentCompliance, SetDocumentCompliance] = useState([]);

  useEffect(() => {
    SetDocumentCompliance(props.documentCompliance);
  }, [props.documentCompliance]);

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  return (
    <React.Fragment>
      <div className="row m-b30">
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          <table className="table table-striped table-hover table-sm table-responsive">
            <thead className="thead-dark">
              <tr>
                {/*<th className="col-1 text-center" scope="col">
                  Sr. No
                </th>*/}
                <th className="col-1 text-center" scope="col">
                  Date & Time
                </th>
                <th className="col-3 text-center" scope="col">
                  Document Name
                </th>
                <th className="col-2 text-center" scope="col">
                  Document(s) Uploaded
                </th>
                {/* <th className="col-3" scope="col">Description</th> */}
                {/* <th className="col-2 text-center" scope="col">Reference</th> */}
                <th className="col-1 text-center" scope="col">
                  Expiry Date
                </th>
                {/*<th className="col-3 text-center" scope="col">
                  Latest Document Date
                </th>*/}
                <th className="col-2 text-center" scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {documentCompliance.map((compliance, i) => (
                <tr
                  className={
                    compliance.cnd_compliance_exp_date_convert == "00-00-0000"
                      ? ""
                      : new Date() <=
                          new Date(
                            splitDateToFormat(
                              compliance.cnd_compliance_exp_date_convert
                            )
                          ) &&
                        dateDiffrence(
                          compliance.cnd_compliance_exp_date_convert
                        ) > 30
                      ? " "
                      : new Date() <=
                          new Date(
                            splitDateToFormat(
                              compliance.cnd_compliance_exp_date_convert
                            )
                          ) &&
                        dateDiffrence(
                          compliance.cnd_compliance_exp_date_convert
                        ) < 30
                      ? "table-warning"
                      : "table-danger"
                  }
                  key={i}
                >
                  {/*<th className="col-1 text-center" scope="row">
                    {i + 1}
                  </th>*/}
                  <td className="col-4 text-center">
                    {compliance.cnd_compliance_created_date}
                  </td>
                  <td className="col-4 text-center">
                    {
                      documentComplianceList[
                        compliance.cnd_compliance_doc_master_id
                      ]
                    }
                  </td>
                  <td className="col-3 text-center">
                    <a
                      download
                      href={
                        constants.IMAGE_URL +
                        compliance.cnd_compliance_folder_path +
                        compliance.cnd_compliance_upload_file
                      }
                      target="_blank"
                    >
                      <i className="fa fa-download f-26"></i>
                    </a>
                  </td>
                  {/* <td className="col-3">{compliance.cnd_compliance_description}</td> */}
                  {/* <td className="col-2 text-center" >
                    {compliance.cnd_compliance_reference}
                  </td> */}
                  <td className="col-2 text-center">
                    {compliance.cnd_compliance_exp_date_convert == "00-00-0000"
                      ? ""
                      : compliance.cnd_compliance_exp_date_convert}
                  </td>
                  {/*<td className="col-2 text-center">
                    {compliance?.cnd_compliance_created_date}
                </td>*/}
                  <td className="col-2 text-center">
                    {compliance.cnd_compliance_exp_date_convert == "00-00-0000"
                      ? "Valid"
                      : new Date() <=
                          new Date(
                            splitDateToFormat(
                              compliance.cnd_compliance_exp_date_convert
                            )
                          ) &&
                        dateDiffrence(
                          compliance.cnd_compliance_exp_date_convert
                        ) > 30
                      ? " "
                      : new Date() <=
                          new Date(
                            splitDateToFormat(
                              compliance.cnd_compliance_exp_date_convert
                            )
                          ) &&
                        dateDiffrence(
                          compliance.cnd_compliance_exp_date_convert
                        ) < 30
                      ? "About to Expire"
                      : "Expire"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    documentCompliance: state.documentCompliance.documentCompliance,
  };
};

export default connect(mapStateToProps)(DocumentComplianceTable);
