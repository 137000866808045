const initialState = {timesheets: []}

export const timesheets = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_TIMESHEETS':
      return {timesheets: action.payload}; 
  }
  return state
}


