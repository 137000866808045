import React, {useEffect, useState} from 'react';
import {  Redirect,Route, withRouter } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import PageHeader from "../components/Layout/PageHeader";
import Footer from "../components/Layout/Footer";
const ClientRoute = ({cheader:CHeader,component: Component, ...rest}) => {
  const [loginType,setLoginType] = useState('client')
  useEffect(()=>{
    if(rest.userAuth.users_role_id)
      setLoginType(rest.userAuth.users_role_id)
  },[rest.userAuth])
  return (
    <React.Fragment>
      <div className="container clearfix" style={{ marginTop: '6rem'}}></div>
      <Route {...rest} render={props => (
        (typeof rest.isAuthenticated != undefined && rest.isAuthenticated && loginType == 'client') ? 
          (typeof rest.header != 'undefined' && rest.header == false) ? 
            <>
            {rest.loader && <div className="loading"></div>}
            <Component {...props} />
            </>:
          <React.Fragment>
            {(CHeader)?<CHeader {...props}/>:<PageHeader/>}
            {rest.loader && <div className="loading"></div>}
            <Component {...props} />
            <Footer/>
          </React.Fragment>          
        : <Redirect to="/" />
      )} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    loader:state.loader.loader,
    userAuth:state.auth.authUserData
  };
};

export default withRouter(connect(mapStateToProps)(ClientRoute));
