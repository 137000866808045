export const isMobileOrLaptop = (windowParam = "") => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      if(windowParam){
        window.scrollTo(0, windowParam);
      }
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };