import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { convertToOriginalForm } from "../../../services/StringOperation";

const AppliedJobsList = ({ jobTypeParam, ...props }) => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [spinLoader, setSpinLoader] = useState(true);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const filteredJobs = props.appliedjobs.filter(
      (job) =>
        job.cnd_permanent_job_status ===
        (jobTypeParam ? jobTypeParam : "Applied")
    );
    setDataLen(filteredJobs.length);
    setAppliedJobs(filteredJobs);
  }, [props.appliedjobs]);

  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const matchesSearchText = (job) => {
    const searchText = inputValue.toLowerCase();
    return (
      job.users_firstname.toLowerCase().includes(searchText) ||
      job.users_lastname.toLowerCase().includes(searchText) ||
      job.cnd_permanent_job_title.toLowerCase().includes(searchText) ||
      job.cnd_permanent_job_status.toLowerCase().includes(searchText) ||
      format(new Date(job.cnd_permanent_job_created_date), "dd-MM-yyyy")
        .toLowerCase()
        .includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filteredJobs = appliedJobs ? appliedJobs.filter(matchesSearchText) : [];
  let checkData = filteredJobs.length > 10;

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <div className="container">
          <div className="row">
            {/* <div class="col-sm-6"></div> */}
            <div class="col-sm-12">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  id="myInput"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {filteredJobs &&
          filteredJobs.length > 0 &&
          filteredJobs.slice(startIndex, endIndex).map((appliedJob, i) => (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2"
              style={{ borderRadius: "20px" }}
              key={i}
            >
              <li className="text-dark">
                <i className="fa fa-user-circle mr-1 text-primary"></i>{" "}
                {appliedJob.users_firstname + " " + appliedJob.users_lastname}
              </li>
              <li className="text-dark">
                <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                {format(
                  new Date(appliedJob.cnd_permanent_job_created_date),
                  "dd-MM-yyyy"
                )}
              </li>
              <li className="text-dark">
                <i className="fa fa-suitcase mr-1 text-primary"></i>{" "}
                {convertToOriginalForm(appliedJob.cnd_permanent_job_title)}
              </li>
              <li className="text-dark">
                <i className="fa fa-circle-o-notch mr-1 text-primary"></i>{" "}
                {appliedJob.cnd_permanent_job_status}
              </li>
            </ul>
          ))}

        {appliedJobs.length === 0 ? (
          <ul
            className="nav navbar-nav border border-grey p-4 mb-2 text-center"
            style={{ borderRadius: "20px" }}
          >
            No Result Found
          </ul>
        ) : filteredJobs.length === 0 && inputValue ? (
          <ul
            className="nav navbar-nav border border-grey p-4 mb-2 text-center"
            style={{ borderRadius: "20px" }}
          >
            No Result Found
          </ul>
        ) : (
          ""
        )}
      </div>
      {filteredJobs && filteredJobs.length > 10 && (
        <TableDropdownAndPagination
          handleDropDown={handleDropDown}
          recordLen={recordLen}
          handleLeft={handleLeft}
          handleRight={handleRight}
          renderPageNumbers={renderPageNumbers}
          isDropdown={true}
          isPaggination={true}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appliedjobs: state.appliedjobs.appliedjobs,
  };
};

export default connect(mapStateToProps)(AppliedJobsList);
