import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Nursery-banner.jpg";
// import EducareImg from "./../images/ourservices/Nursery.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/education/edu-nursery/our-services-edu-nursery.jpg";
import EducareImg from "./../images/new-updated-images/our-services/education/edu-nursery/our-services-edu-nursery-01.jpg";
import Footer from "../components/Layout/Footer";
import bnr3 from "./../images/lines.png";

export default function Education_nursery() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Our Services - Nursery";

  return (
    <div className="page-wraper" style={{marginTop:"-0rem"}}>
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop: (window.location.pathname == "/mobile/nursery" ? "0rem" : "") }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services -</b>Nursery</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/nursery" ? ("Home") : (<a href={"/"}>Home</a>)}                    
                  </li>
                  <li>Our Services</li>
                  <li>Education</li>
                  <li>Nursery</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Nursery </h2>
                  {/* <p>Why, hello there! Want to see more of our work? Check out just some of what we've been up to below.</p> */}
                </div>
              </div>

              <div className="row  m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={EducareImg} alt="" />
                </div>
                <div
                  className="col-md-12 col-lg-6 "
                  style={{ marginBottom: "-6%" }}
                >
                  {/* <h2 className="m-b5"> Home Care</h2> */}
                  <p className="spacebar mt-3"></p>
                  <p>
                    <h5>
                      Unleash the Power of Exceptional Nursery Staffing with
                      Staffing Connect!
                    </h5>
                  </p>

                  <h6>Supreme Quality in Nursery Staffing</h6>

                  <p className=" text-left">
                    At Staffing Connect, we've established ourselves as a
                    trusted provider of top-tier nursery staff. Recognizing the
                    unique requirements of each nursery, our recruitment is
                    meticulously tailored to address your specific needs, be it
                    for permanent or temporary positions.
                  </p>
                  <h6>Prompt Local Nursery Temps and Bank Staff</h6>

                  <p className="text-left">
                    Nurseries often face staff shortages due to illness,
                    vacation, or unfilled roles. To help you navigate these
                    challenges, we offer immediate nursery supply cover.
                    Leveraging our network of local nursery staff, we ensure the
                    prompt provision of temps who reside close to your nursery,
                    ensuring seamless continuity in care.
                  </p>
                </div>

                <div className="col-md-12 col-lg-12 mt-4">
                  <h6>On-Call Support for Nursery Managers</h6>

                  <p className="text-left">
                    Understanding the unpredictability of staffing needs, we
                    operate a 24/7 on-call service. If unforeseen staff
                    shortages arise, a single call to Staffing Connect activates
                    our resources to locate the nearest temp, swiftly filling
                    your vacant position. Staffing Connect specializes in
                    supplying temporary nursery agency staff to early years
                    childcare providers, including nurseries and preschools. We
                    offer flexible staffing solutions for various durations,
                    whether it's daily, weekly, or longer-term coverage. All our
                    agency workers undergo thorough screening, including
                    obtaining a DBS check within 3 months of joining us and/or
                    being registered on the update service. We meticulously
                    collect all necessary documents, such as photo ID, proof of
                    address, proof of relevant childcare/early years
                    qualifications, two satisfactory references, and a clear
                    DBS. Additionally, we prioritize candidates with PFA or EFA
                    certification, and our team has completed the Safer
                    Recruitment course.
                  </p>
                  <h6>
                    Thorough and Selective Recruitment for Permanent Staff
                  </h6>

                  <p>
                    <p className="text-left">
                      When it comes to hiring permanent staff, we're
                      exceptionally discerning. Our rigorous recruitment process
                      includes personal interviews and comprehensive checks for
                      all child carers. We eliminate the strain of recruiting by
                      conducting all the groundwork, from advertising your
                      position and reviewing CVs to interviewing potential
                      candidates.
                    </p>
                    <p className="text-left">
                      Ultimately, you'll receive a curated list of qualified
                      child carers, each eager to fill your role and meet your
                      criteria. At Staffing Connect, we transform staffing from
                      a challenge into an opportunity, ensuring you receive only
                      the finest personnel to fit your needs.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
