import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CompanyDetailContainer from "../../../containers/CompanyDetails";
import CompanyDetails from "./CompanyDetails";
const CompanyDetail = (props) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Company Details
          </h5>
        </div>
        <CompanyDetails />
      </div>
    </React.Fragment>
  );
};

export default CompanyDetailContainer(CompanyDetail);
