import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import composedMyAssignmentsContainer from "../../../containers/MyAssignmentsContainer";
import MyAssignementList from "./MyAssignementsList";
import MyAssignmentCompletedList from "./MyAssignmentCompletedList";
import MyAssignmentCancelledList from "./MyAssignmentCancelledList";
import { useEffect } from "react";
import CancelAssignments from "./CancelAssignments";
import MyAssignmentListMobile from "./MyAssignmentListMobile";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Speed Dial - Start
import Box from "@mui/material/Box";

const MyAssignmentMainComponent = ({ deviceInfo, ...props }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [assignTab, setAssignTab] = useState("Assigned");
  const assignmentsTab = (assignTabValue = "") => {
    if (assignTabValue) {
      setAssignTab(assignTabValue);
    } else {
      setAssignTab("Assigned");
    }
  };

  // Print Content - Start
  const printContent = (assignTabValue = "") => {
    if (deviceInfo == "Mobile") {
      return (
        <MyAssignmentListMobile
          assignmentTypeParam={assignTabValue ? assignTabValue : "Assigned"}
        />
      );
    } else {
      return (
        <>
          <MyAssignementList
            assignmentTypeParam={assignTabValue ? assignTabValue : "Assigned"}
          />
        </>
      );
    }
  };
  // Print Content - End

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            My Assignments
          </h5>
        </div>
        {window.location.pathname != "/cancel-assignments" && (
          <>
            {deviceInfo == "Mobile" ? (
              <Box
              sx={{
                flexGrow: 1,
                // maxWidth: { xs: 320, sm: 480 },
                bgcolor: "background.paper",
              }}
              className="mb-3"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
                className=""
              >
                <Tab
                  label="Upcoming"
                  style={{ fontSize: "80%" }}
                  onClick={(e) => assignmentsTab("Assigned")}
                />
                <Tab
                  label="Completed"
                  style={{ fontSize: "80%" }}
                  onClick={(e) => assignmentsTab("Completed")}
                />
                <Tab
                  label="Cancelled"
                  style={{ fontSize: "80%" }}
                  onClick={(e) => assignmentsTab("Cancelled")}
                />                
              </Tabs>
            </Box>
            ) : (
              <ul
              className="nav nav-tabs bg-dark p-1"
              style={{
                borderRadius: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <li className={"nav-item cursor-pointer p-2"}>
                <a
                  className={
                    "nav-link " +
                    (assignTab == "Assigned"
                      ? "text-primary active bold"
                      : "text-white")
                  }
                  id="tab1"
                  style={{ borderRadius: "50px" }}
                  onClick={(e) => assignmentsTab("Assigned")}
                >
                  Upcoming
                </a>
              </li>
              <li className={"nav-item cursor-pointer p-2"}>
                <a
                  className={
                    "nav-link " +
                    (assignTab == "Completed"
                      ? "text-primary active"
                      : "text-white")
                  }
                  onClick={(e) => assignmentsTab("Completed")}
                  id="tab2"
                  style={{ borderRadius: "50px" }}
                >
                  Completed
                </a>
              </li>
              <li className={"nav-item cursor-pointer p-2"}>
                <a
                  className={
                    "nav-link " +
                    (assignTab == "Cancelled"
                      ? "text-primary active"
                      : "text-white")
                  }
                  id="tab3"
                  style={{ borderRadius: "50px" }}
                  onClick={(e) => assignmentsTab("Cancelled")}
                >
                  Cancelled
                </a>
              </li>
            </ul>
          )}
          {/* </div> */}

            {assignTab == "Assigned" && (
              <>
                <div id="tabFirst" className="tab-pane fade show active">
                  {/* <MyAssignementList  /> */}
                  {printContent("Assigned")}
                </div>
              </>
            )}
            {assignTab == "Completed" && (
              <>
                <div id="tabSecond" className="tab-pane show fade">
                  {/* <MyAssignmentCompletedList /> */}
                  {printContent("Completed")}
                </div>
              </>
            )}

            {assignTab == "Cancelled" && (
              <>
                <div id="tabThird" className="tab-pane show fade">
                  {/* <MyAssignmentCancelledList /> */}
                  {printContent("Cancelled")}
                </div>
              </>
            )}
          </>
        )}

        {window.location.pathname == "/cancel-assignments" && (
          <>
            <CancelAssignments />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default composedMyAssignmentsContainer(MyAssignmentMainComponent);
