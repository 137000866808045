import { yupToFormErrors } from "formik";
import { main } from "react-devtools";
import * as Yup from "yup";
import { RightToworkdocumentsList } from "../../../helpers/DocumentsList";
import { format } from "date-fns";
let currentDate = format(new Date(), "dd-MM-yyyy");
export const formInputFields = () => [
  // {
  //   name: "cnd_rightwork_document_renewal",
  //   type: "checkbox",
  //   options:[{'name':'Yes',id:'yes'}],
  //   label: "Tick, if document is renewal",
  //   md: "12",
  //   sm: "12",
  // },
  {
    name: "cnd_rightwork_document_master_id",
    type: "dropdown",
    label: "Document Name",
    md: "6",
    options: RightToworkdocumentsList,
    sm: "6",
  },
  {
    name: "cnd_rightwork_upload_file",
    type: "file",
    label: "Upload File",
    md: "6",
    sm: "6",
  },
  {
    name: "cnd_rightwork_expire_date",
    type: "date",
    label: "Expiry Date",
    md: "6",
    sm: "6",
    min: new Date().toISOString().split("T")[0],
  },

  {
    name: "cnd_rightwork_document_number",
    type: "text",
    label: "Document Number",
    md: "6",
    sm: "6",
  },
  {
    name: "cnd_rightwork_description",
    type: "textarea",
    label: "Document Description",
    md: "12",
    sm: "12",
    req: "false",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  cnd_rightwork_document_master_id: Yup.string().required(
    "Please Select Document Name"
  ),
  cnd_rightwork_document_number: Yup.string()
    .trim("")
    .required("Document Number Is Required")
    .matches(/^[0-9a-zA-Z \b]+$/, "Special Characters not allowed!"),
  cnd_rightwork_expire_date: Yup.string()
    .required("Document Expiry Date is Required")
    .matches(
      /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
      "Please slect the date from calendar in DD-MM-YYYY format!"
    ),
  // cnd_rightwork_description: Yup.string().trim(""), //.required("Document Description Is Required"),
  cnd_rightwork_upload_file: Yup.mixed()
    .required("File is required")
    .test("checkFile", "File Required", (value) => {
      if (value.length == 0) return false;
      else return true;
    }),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
