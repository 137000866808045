import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInputFields, validationSchema } from "./FormServiceHelper";
import LoadFormFields from "../../Layout/LoadFormFields";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
const Service = (props) => {
  const [company, setFormdata] = useState({});
  const { loader, userDetails, careplan } = props;
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFormdata(careplan);
    if (Object.keys(careplan).length > 0)
      Object.keys(careplan).map((key) => {
        setValue(key, careplan[key]);
      });
  }, [careplan]);

  const onSubmit = (data) => {
    let formData = data;
    formData["users_access_token"] = userDetails.users_access_token;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "SaveCarePlan",
        "",
        getDataOnRequest(
          userDetails.users_access_token,
          "GetCarePlan",
          "SET_CARE_PLAN"
        )
      )
    );
  };

  const onChangeInputvalue = (e) => {
    const { name, value } = e.target;
    company[name] = value;
    setFormdata(company);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LoadFormFields
          register={register}
          errors={errors}
          control={control}
          formInputFields={formInputFields()}
          onChangeInputvalue={(e) => onChangeInputvalue(e)}
          reset={reset}
          data={company}
          backUrl={"/care-plan"}
        />
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    careplan: state.careplan.careplan,
  };
};
export default connect(mapStateToProps)(Service);
