import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  dbsCertificates,
  dbsDocumentName,
} from "../../../helpers/DocumentsList";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { redirectUrl } from "../../../services/GlobalUrls";
const DBSTable = (props) => {
  const [dbsdocuments, SetDbsdocuments] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [inputValue, setInputValue] = useState("");

  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.dbsdocuments.length;
    // console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);

    if (props.dbsdocuments) {
        // Filter the documents based on the given conditions
        const storeArray = props.dbsdocuments.filter(docs =>
            docs.cnd_dbs_certificate_status !== "Deleted" &&
            (docs.cnd_dbs_type === "yes" || docs.cnd_dbs_type === "Yes")
        );

        // Update the state with the filtered documents
        if (storeArray.length > 0) {
            SetDbsdocuments(storeArray);
        }
    }
}, [props.dbsdocuments, currentPage, recordsPerPage]); // Ensure you include all relevant dependencies


  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };

  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  const matchesSearchText = (dbsdocuments) => {
    const searchText = inputValue.toLowerCase();
    return (
      dbsdocuments.cnd_dbs_certificate_created_date
        .toLowerCase()
        .includes(searchText) ||
      dbsCertificates[dbsdocuments.cnd_dbs_certificate_doc_master_id]
        .toLowerCase()
        .includes(searchText) ||
      dbsdocuments.cnd_dbs_certificate_number
        .toLowerCase()
        .includes(searchText) ||
      dbsdocuments.cnd_dbs_certificate_exp_date
        .toLowerCase()
        .includes(searchText)
    );
  };
  // Filter applied jobs based on the search text
  const filterRows = dbsdocuments ? dbsdocuments.filter(matchesSearchText) : [];

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        {/* Search Start */}
        <div className="container">
            <div className="row">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <label class="form-control-label text-right" for="search">
                  Search
                </label>
                <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
                </div>
              </div>
            </div>
          </div>
          {/* Search End */}
        <table className="table table-striped table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              {props.type == "yes" && (
                <>
                  {/*<th className="col-1 text-left">Sr. No</th>*/}
                  <th className="col-2 text-center">Date & Time</th>
                  {/* <th className="col-2 text-center">DBS Type</th> */}

                  <th className="col-2 text-center">Document Name</th>
                  <th className="col-2 text-center">Cetificate Number</th>
                  {/* <th className="col-1">Holder DOB</th> */}
                  {/* <th className="col-2">Holder Surname</th> */}
                  {/* <th>Document Description</th> */}
                  <th className="col-1 text-center">Document(s) Uploaded</th>
                  {/* <th>Status</th> */}
                  <th className="col-3 text-center">Expiry Date</th>
                  <th className="col-1 text-center">Status</th>
                  {/* <th className="col-2 text-center">Action</th> */}
                  {/* <th>Reference</th> */}
                </>
              )}
            </tr>
          </thead>
          <tbody >
            {filterRows &&
              filterRows.slice(startIndex, endIndex).map(
                (work, i) =>
                  work.cnd_dbs_type == "yes" &&
                  work.cnd_dbs_certificate_upload_file &&
                  work.cnd_dbs_certificate_status != "Deleted" && (
                    <tr
                      className={
                        work.cnd_dbs_certificate_exp_date &&
                        (new Date() <=
                          new Date(
                            splitDateToFormat(work.cnd_dbs_certificate_exp_date)
                          ) &&
                        dateDiffrence(work.cnd_dbs_certificate_exp_date) > 30
                          ? " "
                          : new Date() <=
                              new Date(
                                splitDateToFormat(
                                  work.cnd_dbs_certificate_exp_date
                                )
                              ) &&
                            dateDiffrence(work.cnd_dbs_certificate_exp_date) <
                              30
                          ? "table-warning"
                          : "table-danger")
                      }
                      key={i}
                    >
                      {/*<td className="col-1 text-center" scope="row">
                  {i + 1}
              </td>*/}
                      <td className="col-3 text-center">
                        {work.cnd_dbs_certificate_created_date
                          ? work.cnd_dbs_certificate_created_date
                          : "-"}
                      </td>
                      {/* <td className="col-1 text-center">{work.cnd_dbs_type}</td> */}
                      {props.type == "yes" && (
                        <React.Fragment>
                          <td className="col-1 text-center">
                            {/*work.cnd_dbs_certificate_doc_master_id*/}
                            {
                              dbsCertificates[
                                work.cnd_dbs_certificate_doc_master_id
                              ]
                            }
                          </td>
                          <td className="col-2 text-center">
                            {work.cnd_dbs_certificate_number}
                          </td>
                          {/* <td className="col-1">{work.cnd_dbs_holder_dob}</td> */}
                          {/* <td className="col-2">{work.cnd_dbs_holder_surname}</td> */}
                          {/* <td>{work.cnd_dbs_certificate_description}</td> */}
                          <td className="col-1 text-center">
                            <a
                              onClick={() =>
                                viewFileModal(
                                  constants.IMAGE_URL +
                                    work.cnd_dbs_certificate_folder_path +
                                    work.cnd_dbs_certificate_upload_file,
                                  dbsCertificates[
                                    work.cnd_dbs_certificate_doc_master_id
                                  ]
                                )
                              }
                              className={
                                "btn btn-" +
                                (work.cnd_dbs_certificate_upload_file.endsWith(
                                  ".png"
                                ) ||
                                work.cnd_dbs_certificate_upload_file.endsWith(
                                  ".jpg"
                                ) ||
                                work.cnd_dbs_certificate_upload_file.endsWith(
                                  ".jpeg"
                                ) ||
                                work.cnd_dbs_certificate_upload_file.endsWith(
                                  ".pdf"
                                )
                                  ? "dark"
                                  : "primary") +
                                " btn-sm text-white"
                              }
                            >
                              <i
                                className={
                                  "fa fa-" +
                                  (work.cnd_dbs_certificate_upload_file.endsWith(
                                    ".png"
                                  ) ||
                                  work.cnd_dbs_certificate_upload_file.endsWith(
                                    ".jpg"
                                  ) ||
                                  work.cnd_dbs_certificate_upload_file.endsWith(
                                    ".jpeg"
                                  ) ||
                                  work.cnd_dbs_certificate_upload_file.endsWith(
                                    ".pdf"
                                  )
                                    ? "eye" //"eye"
                                    : "download")
                                }
                              ></i>
                            </a>
                          </td>
                          {/* <td>
                      {dbsDocumentName[work.cnd_dbs_certificate_doc_status]}
                    </td> */}
                          <td className="col-3 text-center">
                            {work.cnd_dbs_certificate_exp_date
                              ? work.cnd_dbs_certificate_exp_date
                              : "-"}
                          </td>

                          {/* <td>
                      {work.cnd_dbs_certificate_reference
                        ? work.cnd_dbs_certificate_reference
                        : "-"}
                    </td> */}
                          <td className="col-1 text-center">
                            {work.cnd_dbs_certificate_exp_date &&
                              (new Date() <=
                                new Date(
                                  splitDateToFormat(
                                    work.cnd_dbs_certificate_exp_date
                                  )
                                ) &&
                              dateDiffrence(work.cnd_dbs_certificate_exp_date) >
                                30
                                ? "Valid "
                                : new Date() <=
                                    new Date(
                                      splitDateToFormat(
                                        work.cnd_dbs_certificate_exp_date
                                      )
                                    ) &&
                                  dateDiffrence(
                                    work.cnd_dbs_certificate_exp_date
                                  ) < 30
                                ? "About to Expire"
                                : "Expired")}
                          </td>
                          {/* <td className="col-1" scope="col">
                      {" "}
                      <a
                        href={
                          "/create-dbs-document/" + work.cnd_dbs_id
                        }
                      >
                        <i className="fa fa-pencil"></i>
                      </a>
                    </td> */}
                        </React.Fragment>
                      )}
                      {/* {props.type == "no" && (
                  <React.Fragment>
                    <td>
                      {work.cnd_dbs_birth_of_town
                        ? work.cnd_dbs_birth_of_town
                        : "-"}
                    </td>
                    <td>
                      {work.cnd_address_country
                        ? work.cnd_address_country
                        : "-"}
                    </td>
                    <td>
                      {work.cnd_dbs_nis_no
                        ? work.cnd_dbs_nis_no
                        : "-"}
                    </td>
                    <td>
                      {work.cnd_dbs_surname_of_birth
                        ? work.cnd_dbs_surname_of_birth
                        : "-"}
                    </td>
                    <td>
                      {work.cnd_address_address
                        ? work.cnd_address_address
                        : "-"}
                    </td>
                    <td>
                      {work.cnd_address_city ? work.cnd_address_city : "-"}
                    </td>
                    <td>
                      {work.cnd_address_country
                        ? work.cnd_address_country
                        : "-"}
                    </td>
                    <td>
                      {work.cnd_address_start_date
                        ? work.cnd_address_start_date
                        : "-"}
                    </td>
                    <td>
                      {work.cnd_address_end_date
                        ? work.cnd_address_end_date
                        : "-"}
                    </td>
                  </React.Fragment>
                )} */}
                    </tr>
                  )
              )}
            {filterRows.length == 0 && (
              <tr>
                <td colSpan="100">No DBS Record Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
        {dbsdocuments && dbsdocuments.length > 0 && (
          <TableDropdownAndPagination
            handleDropDown={handleDropDown}
            recordLen={recordLen}
            handleLeft={handleLeft}
            handleRight={handleRight}
            renderPageNumbers={renderPageNumbers}
            isDropdown={true}
            isPaggination={true}
          />
        )}
        <Link to={"/identity-document"} className="site-button listingnextbtn">
          Next
        </Link>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        // fileName={fileName}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"DBS Certification - Uploaded DBS Certificate"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dbsdocuments: state.dbsdocuments.dbsdocuments,
  };
};

export default connect(mapStateToProps)(DBSTable);
