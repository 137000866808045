import React from "react";
import Label from "../components/Layout/Label";

const CustomeDropdown = ({
  name,
  id,
  label,
  chooseListName,
  options,
  changeHandler,
  lg,
  requiredParam,
}) => {
  return (
    <div className={`col-lg-${lg}`}>
      {/* <label htmlFor={id}>
        {label}
        {requiredParam == false ? "" : <b className="text-danger">*</b>}
      </label> */}
      <Label labelName={label} req={requiredParam} />
      <select
        name={name}
        id={id}
        onChange={changeHandler}
        className="form-control"
      >
        <option value="">{chooseListName}</option>
        {options.map((option) => (
          <option
            key={option.domiciliary_care_client_id}
            value={option.domiciliary_care_client_id}
          >
            {`${option.domiciliary_care_client_service_user_firstname} ${option.domiciliary_care_client_service_user_lastname}`}
          </option>
        ))}
      </select>
      <div className="text-danger" id={id + "_msg"} style={{ fontSize: "80%" }}>
        
      </div>
    </div>
  );
};

export default CustomeDropdown;
