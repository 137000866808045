import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import { format } from "date-fns";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { convertToOriginalForm } from "../../../services/StringOperation";

const MyAssignmentsList = ({ assignmentTypeParam, ...props }) => {
  const [myassignments, SetMyAssignments] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);
  let checkData = false;
  useEffect(() => {
    const filterAssignments = props.myassignments.filter(
      (assign) =>
        assign.cnd_assignment_status ===
        (assignmentTypeParam ? assignmentTypeParam : "Assigned")
    );
    const lenOfData = props.myassignments.length;
    console.log("lenOfData", assignmentTypeParam);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetMyAssignments(filterAssignments);
  }, [props.myassignments]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   SetMyAssignments(props.myassignments);
  // }, [props.myassignments]);

  // Filter Data - Start
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (assign) => {
    const searchText = inputValue.toLowerCase();
    if (assignmentTypeParam == "Cancelled") {
      return (
        format(new Date(assign.cnd_assignment_availability_date), "dd-MM-yyyy")
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_assignment_availability_date
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_availability_week_day.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_start_time.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_end_time.toLowerCase().includes(searchText) ||
        assign.clt_company_name.toLowerCase().includes(searchText) ||
        assign.clt_branch_name.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_cancel_reason
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_assignment_cancel_comment.toLowerCase().includes(searchText)
      );
    } else {
      return (
        format(new Date(assign.cnd_assignment_availability_date), "dd-MM-yyyy")
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_assignment_availability_date
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_availability_week_day.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_start_time.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_end_time.toLowerCase().includes(searchText) ||
        assign.clt_branch_location.toLowerCase().includes(searchText) ||
        assign.clt_branch_address1.toLowerCase().includes(searchText) ||
        assign.clt_branch_address2.toLowerCase().includes(searchText) ||
        assign.clt_branch_region.toLowerCase().includes(searchText) ||
        assign.clt_branch_postal.toLowerCase().includes(searchText) ||
        (assign.cnd_assignment_job_roles
          ? convertToOriginalForm(assign.cnd_assignment_job_roles)
              .toLowerCase()
              .includes(searchText)
          : "") ||
        assign.clt_company_name.toLowerCase().includes(searchText) ||
        assign.clt_branch_name.toLowerCase().includes(searchText)
      );
    }
  };

  // Filter applied jobs based on the search text
  const filterRows = myassignments
    ? myassignments.filter(matchesSearchText)
    : [];
  // Filter Data - End

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6 p-2">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <table className="table table-bordered table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              {/*<th  className="col-1 text-center" scope="col">Sr. No</th>*/}
              <th className="col-2 text-center" scope="col">
                Date
              </th>
              <th className="col-2 text-center" scope="col">
                Timing
              </th>
              {/* <th  className="col-3 text-center" scope="col"> Name</th> */}
              {/*<th  className="col-2 text-center" scope="col">Email</th>*/}
              <th className="col-2 text-center" scope="col">
                Client Name
              </th>
              <th className="col-2 text-center" scope="col">
                Branch Name
              </th>
              {assignmentTypeParam == "Cancelled" ? (
                <>
                  <th className="col-2 text-center" scope="col">
                    Reason
                  </th>
                  <th className="col-2 text-center" scope="col">
                    Comment
                  </th>
                </>
              ) : (
                <>
                  <th className="col-2 text-center" scope="col">
                    Address
                  </th>
                  <th className="col-2 text-center" scope="col">
                    Job Title
                  </th>
                </>
              )}

              {/* <th className="col-2 text-center" scope="col">
                Status
              </th> */}
              {/*<th  className="col-1 text-center" scope="col">Action</th>*/}
            </tr>
          </thead>
          <tbody id="myTable">
            {filterRows &&
              filterRows.length > 0 &&
              filterRows?.slice(startIndex, endIndex).map((myassignment, i) => {
                if (i == 0) {
                  checkData = true;
                }
                return (
                  <tr key={i}>
                    {/*<td className="col-1 text-center" scope="row">
                    {i + 1}
              </td>*/}
                    <td className="col-2 text-center" scope="row">
                      {format(
                        new Date(myassignment.cnd_assignment_availability_date),
                        "dd-MM-yyyy"
                      ) +
                        " (" +
                        myassignment.cnd_availability_week_day
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ") +
                        ")"}
                    </td>
                    <td className="col-2 text-center" scope="row">
                      {myassignment.cnd_assignment_start_time +
                        " - " +
                        myassignment.cnd_assignment_end_time}
                    </td>
                    <td className="col-2 text-center" scope="row">
                      {myassignment.clt_company_name
                        ? myassignment.clt_company_name
                        : "-"}
                    </td>
                    <td className="col-2 text-center" scope="row">
                    {myassignment.cnd_assignment_client_branch_id &&
                      myassignment.cnd_assignment_client_branch_id ==
                        myassignment.clt_branch_id && (
                          <>
                          {myassignment.clt_branch_location
                            ? myassignment.clt_branch_name +
                              " - ( " +
                              myassignment.clt_branch_location +
                              " )"
                            : myassignment.clt_branch_name
                            ? myassignment.clt_branch_name
                            : "-"}
                          </>
                        
                      )}
                    
                      {!myassignment.cnd_assignment_client_branch_id && (
                        <>
                          {myassignment.clt_service_town
                            ? myassignment.clt_company_name +
                              " - ( " +
                              myassignment.clt_service_town +
                              " )"
                            : myassignment.clt_company_name
                            ? myassignment.clt_company_name
                            : "-"}
                        </>
                      )}
                    </td>
                    {assignmentTypeParam == "Cancelled" ? (
                      <>
                        <td className="col-2 text-center" scope="row">
                          {myassignment.cnd_assignment_cancel_reason
                            ? myassignment.cnd_assignment_cancel_reason
                            : " - "}
                        </td>
                        <td className="col-2 text-center" scope="row">
                          {myassignment.cnd_assignment_cancel_comment
                            ? myassignment.cnd_assignment_cancel_comment
                            : " - "}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="col-2 text-center" scope="row">
                          {myassignment.clt_branch_address1
                            ? myassignment.clt_branch_address1
                            : ""}
                          {myassignment.clt_branch_address2
                            ? ", " + myassignment.clt_branch_address2
                            : ""}
                          {myassignment.clt_branch_town
                            ? ", " + myassignment.clt_branch_town
                            : ""}
                          {myassignment.clt_branch_region
                            ? ", " + myassignment.clt_branch_region
                            : ""}
                          {myassignment.clt_branch_postal
                            ? ", " + myassignment.clt_branch_postal
                            : ""}
                        </td>
                        <td className="col-2 text-center" scope="row">
                          {myassignment.cnd_assignment_job_roles
                            ? convertToOriginalForm(
                                myassignment.cnd_assignment_job_roles
                              )
                            : " - "}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            {filterRows && filterRows.length == 0 && (
              <tr className="text-center">
                <td colSpan="100">No Results Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterRows && filterRows.length > 10 && (
        <TableDropdownAndPagination
          handleDropDown={handleDropDown}
          recordLen={recordLen}
          handleLeft={handleLeft}
          handleRight={handleRight}
          renderPageNumbers={renderPageNumbers}
          isDropdown={true}
          isPaggination={true}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    myassignments: state.myassignments.myassignments,
  };
};

export default connect(mapStateToProps)(MyAssignmentsList);
