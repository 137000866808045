import { format } from "date-fns";
export const validateStringWithoutSpecialCharacter = (string) => {
  return String(string).match(
    /^[0-9a-zA-Z \b]+$/
  );
}

export const DocumentComplianceValidations  = (data,form) => {
  let error = false;
  let errorObj = [];
  data.map((x, i) => {
    errorObj[i] = {
                    "cnd_compliance_doc_master_id":"",
                    "cnd_compliance_upload_file":"",
                    "cnd_compliance_description":"",
                    "cnd_compliance_exp_date":"",
                    "cnd_compliance_reference":""
                  }

    // Id Operations Start
   
    
    // ID Operations Stop
    if (x.cnd_compliance_doc_master_id === "") {
      errorObj[i]['cnd_compliance_doc_master_id'] = "Please Select Document Name";
      error = true;
    }
    
    if (x.cnd_compliance_description === null) {
      errorObj[i]['cnd_compliance_description'] = "Enter Description";
      error = true;
    }else if(x.cnd_compliance_description.length > 500){
      errorObj[i]['cnd_compliance_description'] = "Max 500 Characters Allowed";
      error = true;
    }
    // if (x.cnd_compliance_exp_date === "") {
    //   errorObj[i]['cnd_compliance_exp_date'] = "Select Expiry Document Date";
    //   error = true;
    // }
    if (!x.cnd_compliance_upload_file) {
      errorObj[i]['cnd_compliance_upload_file'] = "Please select file";
      error = true;
      let cnd_compliance_upload_file = document.getElementById("cnd_compliance_upload_file");
      let cnd_compliance_upload_file_error = document.getElementById("cnd_compliance_upload_file_error");
      if(!cnd_compliance_upload_file.value){
        cnd_compliance_upload_file.classList.add("text-danger");
        cnd_compliance_upload_file_error.style.display = "block";
      }else{
        cnd_compliance_upload_file.classList.remove("text-danger");
        cnd_compliance_upload_file_error.style.display = "none";
      }     
    }

    if ((!x.cnd_doc_name && window.location.pathname == "/create-document-additional") || (x.cnd_doc_name && x.cnd_doc_name.trim() == "" && window.location.pathname == "/create-document-additional")) {
      errorObj[i]['cnd_doc_name'] = "Please enter the document name";
      error = true;

      let cnd_doc_name = document.getElementById("cnd_doc_name");
      let cnd_doc_name_error = document.getElementById("cnd_doc_name_error");
      if(!cnd_doc_name.value || (cnd_doc_name.value && cnd_doc_name.value.trim() == "")){
        cnd_doc_name.classList.add("text-danger","border-danger");
        cnd_doc_name_error.style.display = "block";
      }else{
        cnd_doc_name.classList.remove("text-danger","border-danger");
        cnd_doc_name_error.style.display = "none";
      }
    }

    // Document Expiry Date Start
    if (x.cnd_compliance_exp_date && window.location.pathname == "/create-document-training-certificate") {
      let cnd_compliance_exp_date = document.getElementById("cnd_compliance_exp_date");
      let cnd_compliance_exp_date_error = document.getElementById("cnd_compliance_exp_date_error");

      let minDate = new Date(String(cnd_compliance_exp_date.min));
      let finalDate = new Date(String(cnd_compliance_exp_date.value));
      
      // console.log("Min and final date validations are :=>"+ finalDate);
      if(!cnd_compliance_exp_date.value.match(/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/)){
        errorObj[i]['cnd_compliance_exp_date'] = "Please slect the date from calendar in DD-MM-YYYY format!";
        error = true;
        cnd_compliance_exp_date.classList.add("text-danger","border-danger");
        cnd_compliance_exp_date_error.style.display = "block";
      }else if(finalDate < minDate){
        errorObj[i]['cnd_compliance_exp_date'] = "Please slect the date from calendar in DD-MM-YYYY format!";
        error = true;
        cnd_compliance_exp_date.classList.add("text-danger","border-danger");
        cnd_compliance_exp_date_error.innerText = "Date must be "+ format(minDate,"dd-MM-yyyy") +" or later";
        cnd_compliance_exp_date_error.style.display = "block";
      }else{
        cnd_compliance_exp_date.classList.remove("text-danger","border-danger");
        cnd_compliance_exp_date_error.style.display = "none";
      }
    }
    // Document Expiry Date Stop

    
    // Document Reference Number Start
    if (x.cnd_compliance_reference && (x.cnd_compliance_reference.trim() == "" || !validateStringWithoutSpecialCharacter(x.cnd_compliance_reference))) {
      errorObj[i]['cnd_compliance_reference'] = "Special characters not allowed";
      error = true;
      let cnd_compliance_reference = document.getElementById("cnd_compliance_reference");
      let cnd_compliance_reference_error = document.getElementById("cnd_compliance_reference_error");
      if(cnd_compliance_reference.value && (cnd_compliance_reference.value.trim() == "" || !validateStringWithoutSpecialCharacter(cnd_compliance_reference.value))){
        cnd_compliance_reference.classList.add("text-danger","border-danger");
        cnd_compliance_reference_error.style.display = "block";
      }else{
        cnd_compliance_reference.classList.remove("text-danger","border-danger");
        cnd_compliance_reference_error.style.display = "none";
      }
    }
    // Document Reference Number Stop

    if(i === 1){
      if (x.cnd_street_address === "") {
        errorObj[i]['cnd_street_address'] = "Please Enter Address";
        error = true;
      }
      if (x.cnd_town === "") {
        errorObj[i]['cnd_town'] = "Please Enter Town";
        error = true;
      }
      if (x.cnd_postcode === "") {
        errorObj[i]['cnd_postcode'] = "Please Enter Postal Code";
        error = true;
      }
      if (x.cnd_postcode === "") {
        errorObj[i]['cnd_region'] = "Please Enter Region";
        error = true;
      }
    }
    // if(i === 2){
    //   if (x.cnd_street_address_2 === "") {
    //     errorObj[i]['cnd_street_address_2'] = "Please Enter Address";
    //     error = true;
    //   }
      
    // }
    // if (x.cnd_compliance_reference === "") {
    //   errorObj[i]['cnd_compliance_reference'] = "Please Provide Refrence";
    //   error = true;
    // }else if (x.cnd_compliance_reference.length > 50) {
    //   errorObj[i]['cnd_compliance_reference'] = "Max length should be 50";
    //   error = true;
    // }
  })
  return {stateData:errorObj,error:error};
}