const initialState = {error: []}

export const error = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_ERROR':
      return {error: action.payload}; 
  }
  return state
}

