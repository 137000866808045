import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadloginInfo } from "./../redux/actions/AuthActions";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import { useHistory } from "react-router-dom";
import { updateDataOnRequest } from "../redux/actions/CommonActions";
import Input from "../components/FormElements/Input";
import Label from "../components/Layout/Label";
import AccountCentreSidebar from "../components/Element/AccountCentreSidebar";
import AccountCentreSidebarMobile from "../components/Element/AccountCentreSidebarMobile";
import { isMobileOrLaptop } from "../services/CheckDevice";

var bnr = require("./../images/banner/bnr2.jpg");

const ChangePassword = (props) => {
  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(350);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  let localStorageValues = JSON.parse(localStorage.getItem('loginUserDetails'));

  const [formData, setFormData] = useState({});
  const [errorMsg, setErrorsMsg] = useState("");
  let users_password, users_new_password, users_confirm_password, users_password_msg, users_new_password_msg, users_confirm_password_msg;
  let applyErrors = false;

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
  });

    // formData = {"users_email" :e.target.value};
    // console.log("This Is OTP :=>"+JSON.stringify(formData));
    // console.log("This Is OTP :=>"+localStorageValues.access_token);
    // Fields Start
    users_password = document.getElementById("users_password");
    users_new_password = document.getElementById("users_new_password");
    users_confirm_password = document.getElementById("users_confirm_password");
    // Fields End

    // Msg Start
    users_password_msg = document.getElementById("users_password_msg");
    users_new_password_msg = document.getElementById("users_new_password_msg");
    users_confirm_password_msg = document.getElementById("users_confirm_password_msg");
    // Msg End

    // Current Password Start
    if(!users_password.value){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Please enter the current password!";
    }else if(users_password.value.trim() == ""){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Please enter the valid password!";
    }else if(users_password.value.length < 6){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Password must have 6 or more characters!";
    }else{
        users_password.classList.remove("border-danger");
        users_password_msg.innerText = "";
    }
    // Current Password End
    
    // New Password Start
    if(!users_new_password.value){
        applyErrors = true;
        users_new_password.classList.add("border-danger");
        users_new_password_msg.innerText = "Please enter the new password!";
    }else if(users_new_password.value.trim() == ""){
        applyErrors = true;
        users_new_password.classList.add("border-danger");
        users_new_password_msg.innerText = "Please enter the valid password!";
    }else if(users_new_password.value.length < 6){
        applyErrors = true;
        users_new_password.classList.add("border-danger");
        users_new_password_msg.innerText = "Password must have 6 or more characters!";
    }else if(users_new_password.value == users_password.value){
        applyErrors = true;
        users_new_password.classList.add("border-danger");
        users_new_password_msg.innerText = "New password shoud be different from the current password!";
    }else{
        users_new_password.classList.remove("border-danger");
        users_new_password_msg.innerText = "";
    }
    // New Password End

    // Confirm Password Start
    if(!users_confirm_password.value){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Please enter the confirm password!";
    }else if(users_confirm_password.value.trim() == ""){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Please enter the valid password!";
    }else if(users_confirm_password.value.length < 6){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Password must have 6 or more characters!";
    }else if(users_confirm_password.value != users_new_password.value){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Confirm password doesn't match with new password!";
    }else{
        users_confirm_password.classList.remove("border-danger");
        users_confirm_password_msg.innerText = "";
    }
    // Confirm Password End
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    formData["users_access_token"] = localStorageValues.access_token;
    
    // Fields Start
    users_password = document.getElementById("users_password");
    users_new_password = document.getElementById("users_new_password");
    users_confirm_password = document.getElementById("users_confirm_password");
    // Fields End

    // Msg Start
    users_password_msg = document.getElementById("users_password_msg");
    users_new_password_msg = document.getElementById("users_new_password_msg");
    users_confirm_password_msg = document.getElementById("users_confirm_password_msg");
    // Msg End

    // Current Password Start
    if(!users_password.value){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Please enter the current password!";
    }else if(users_password.value.trim() == ""){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Please enter the valid password!";
    }else if(users_password.value.length < 6){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Password must have 6 or more characters!";
    }else{
        users_password.classList.remove("border-danger");
        users_password_msg.innerText = "";
    }
    // Current Password End
    
    // New Password Start
    if(!users_new_password.value){
        applyErrors = true;
        users_new_password.classList.add("border-danger");
        users_new_password_msg.innerText = "Please enter the new password!";
    }else if(users_new_password.value.trim() == ""){
        applyErrors = true;
        users_new_password.classList.add("border-danger");
        users_new_password_msg.innerText = "Please enter the valid password!";
    }else if(users_new_password.value.length < 6){
        applyErrors = true;
        users_new_password.classList.add("border-danger");
        users_new_password_msg.innerText = "Password must have 6 or more characters!";
    }else{
        users_new_password.classList.remove("border-danger");
        users_new_password_msg.innerText = "";
    }
    // New Password End

    // Confirm Password Start
    if(!users_confirm_password.value){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Please enter the confirm password!";
    }else if(users_confirm_password.value.trim() == ""){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Please enter the valid password!";
    }else if(users_confirm_password.value.length < 6){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Password must have 6 or more characters!";
    }else if(users_confirm_password.value != users_new_password.value){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Confirm password doesn't match with new password!";
    }else{
        users_confirm_password.classList.remove("border-danger");
        users_confirm_password_msg.innerText = "";
    }
    // Confirm Password End
    
    if(applyErrors == false){
        document.getElementById("loader").style.display = "block";
        dispatch(
            updateDataOnRequest(JSON.stringify(formData, null, 2), "ChangePassword", "")
        );
    }
    
  };

 

  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-blocks">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {checkDevice == "Mobile" ? (
                  <AccountCentreSidebarMobile page="change-password" />
                ) : (
                  <AccountCentreSidebar page="change-password" />
                )}
                
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx submit-resume" id="scrollFocusElement">
                  <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Change Password
                      </h5>
                    </div>
                  <form
                    onSubmit={onChangePassword}
                    onInput={handleChange}
                    className="tab-pane active col-12 p-a0 "
                    noValidate
                  >
                    <div className="container">
                      <div className="row">
                        {/* <Label labelName="Current Password" /> */}
                      <Input
                        label="Current Password"
                        // className="form-control"
                        type="password"
                        name="users_password"
                        id={"users_password"}
                        placeholder="Enter current password"
                        onChange={(e) => handleChange()}
                        lg={6}
                        md={6}
                        errorsMsg={errorMsg}
                      />
                      

                      {/* <Label labelName="New Password" /> */}
                      <Input
                        label="New Password"
                        // className="form-control"
                        type="password"
                        name="users_new_password"
                        id={"users_new_password"}
                        placeholder="Enter new password"
                        onChange={(e) => handleChange()}
                        lg={6}
                      />
                      

                      {/* <Label labelName="Confirm Password" /> */}
                      <Input
                        label="Confirm Password"
                        // className="form-control"
                        type="password"
                        name="users_confirm_password"
                        id={"users_confirm_password"}
                        placeholder="Enter confirm password"
                        onChange={(e) => handleChange()}
                        lg={6}
                      />
                      <div className="col-sm-12">
                      <button className="site-button green pull-right m-r5">
                        Confirm
                      </button>
                      </div>
                      </div>
                    </div>                      
                    

                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: isAuthenticated(state),
    errorMessage: state.error.error,
  };
};

export default connect(mapStateToProps)(ChangePassword);
