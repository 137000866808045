const initialState = { fltLicences: {} };

export const fltLicences = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FLT_LICENSES":
      return { fltLicences: action.payload };
    case "UPDATE_FLT_LICENSE":
      return { fltLicences: action.payload };
  }
  return state;
};
