const initialState = {appliedjobs: []}

export const appliedjobs = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_APPLIED_JOBS':
      return {appliedjobs: action.payload}; 
  }
  return state
}


