const initialState = {assesmentCategory: []}

export const assesmentCategory = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_ASSESMENT_CATEGORY_RISK':
      return {...state,assesmentCategory: action.payload};
  }
  return state
}


