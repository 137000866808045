import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { redirectUrl } from "../../../services/GlobalUrls";
const TimeSheetsListMobile = (props) => {
  const [timesheets, SetTimeSheets] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.timesheets.length;
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetTimeSheets(props.timesheets);
  }, [props.timesheets]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };

  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  //   Filter - Start
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (timesheet) => {
    const searchText = inputValue.toLowerCase();
    return (
      timesheet.cnd_timesheet_created_date.toLowerCase().includes(searchText) ||
      timesheet.fullname.toLowerCase().includes(searchText) ||
      timesheet.cnd_timesheet_client_name.toLowerCase().includes(searchText) ||
      timesheet.cnd_timesheet_week_commencing
        .toLowerCase()
        .includes(searchText) ||
      timesheet.cnd_timesheet_status.toLowerCase().includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filterRows = timesheets ? timesheets.filter(matchesSearchText) : [];
  //   Filter - End

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <div className="container">
          <div className="row">
            <div class="col-sm-12">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          {filterRows &&
            filterRows.length > 0 &&
            filterRows.slice(startIndex, endIndex).map((timesheet, i) => (
              <ul
                className="nav navbar-nav border border-grey p-4 mb-2"
                style={{ borderRadius: "20px" }}
                key={i}
              >
                <li className="text-dark">
                  <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                  {timesheet.cnd_timesheet_created_date}
                </li>
                <li className="text-dark">
                  <i className="fa fa-user-circle mr-1 text-primary"></i>{" "}
                  {timesheet.fullname && timesheet.fullname.length <= 15
                    ? timesheet.fullname
                    : timesheet.fullname
                    ? timesheet.fullname.slice(0, 15) + "..."
                    : ""}
                  <a
                    onClick={() =>
                      viewFileModal(
                        constants.IMAGE_URL +
                          timesheet.cnd_timesheet_folderpath +
                          timesheet.cnd_timesheet_upload_document
                      )
                    }
                    className={
                      "text-" +
                      (timesheet.cnd_timesheet_upload_document.endsWith(
                        ".png"
                      ) ||
                      timesheet.cnd_timesheet_upload_document.endsWith(
                        ".jpg"
                      ) ||
                      timesheet.cnd_timesheet_upload_document.endsWith(
                        ".jpeg"
                      ) ||
                      timesheet.cnd_timesheet_upload_document.endsWith(".pdf")
                        ? "primary"
                        : "primary") +
                      " text-sm pull-right mt--2"
                    }
                  >
                    <u>View File</u>
                  </a>
                </li>
                <li className="text-dark">
                  <i className="fa fa-users mr-1 text-primary"></i>{" "}
                  {timesheet.cnd_timesheet_client_name &&
                  timesheet.cnd_timesheet_client_name.length <= 15
                    ? timesheet.cnd_timesheet_client_name
                    : timesheet.cnd_timesheet_client_name
                    ? timesheet.cnd_timesheet_client_name.slice(0, 15) + "..."
                    : ""}
                </li>
                <li className="text-dark">
                  <i className="fa fa-calendar-check-o mr-1 text-primary"></i>{" "}
                  {timesheet.cnd_timesheet_week_commencing}
                </li>
                <li className="text-dark">
                  <i className="fa fa-circle-o-notch mr-1 text-primary"></i>{" "}
                  {timesheet.cnd_timesheet_status}
                </li>
              </ul>
            ))}

          {timesheets.length === 0 ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : filterRows.length === 0 && inputValue ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : (
            ""
          )}
          {timesheets && timesheets.length > 10 && (
          <TableDropdownAndPagination
            handleDropDown={handleDropDown}
            recordLen={recordLen}
            handleLeft={handleLeft}
            handleRight={handleRight}
            renderPageNumbers={renderPageNumbers}
            isDropdown={true}
            isPaggination={true}
          />
        )}
          {/* <a
            href="#"
            onClick={documentsRequiredCall}
            className="pull-right btn btn-primary mr-2"
          >
            Next
          </a> */}
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        // fileName={fileName}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"TimeSheet"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    timesheets: state.timesheets.timesheets,
  };
};

export default connect(mapStateToProps)(TimeSheetsListMobile);
