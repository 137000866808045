import React, { useState, useEffect } from "react";
import Error from "../../Layout/Error";
const PersonalInfoRadioFields = (props) => {
  const [userdata, setFormdata] = useState({});
  const [radioValues, setRadioButtonValue] = useState({});
  const { errors } = props;
  useEffect(() => {
    setFormdata(props.data);
  }, [props.data]);

  const checkForRadioButton = (field, value) => {
    if (userdata[field] && userdata[field] == value) {
      return true;
    }
  };

  return (
    <React.Fragment>
      <div className="col-lg-12 col-md-12 col-md-12">
        <div className="form-group">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label style={{ lineHeight: "1.5rem" }} id="drivingLicense">
              Do you have a driving license?
              <sup style={{ color: "red" }}>*</sup>
            </label>
          </div>                    
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="custom-control custom-radio">
              <input
                required
                name="cnd_drivinglicense"
                onChange={(e) => props.onChange(e)}
                type="radio"
                defaultChecked={checkForRadioButton("cnd_drivinglicense", 1)}
                className="custom-control-input"
                id="cnd_drivinglicense_yes"
                defaultValue="1"
              />
              <label
                className="custom-control-label"
                htmlFor="cnd_drivinglicense_yes"
                style={{ lineHeight: "1.5rem" }}
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                required
                type="radio"
                defaultChecked={checkForRadioButton("cnd_drivinglicense", 0)}
                className="custom-control-input"
                id="cnd_drivinglicense_no"
                onChange={(e) => props.onChange(e)}
                name="cnd_drivinglicense"
                value="0"
              />
              <label
                className="custom-control-label"
                htmlFor="cnd_drivinglicense_no"
                style={{ lineHeight: "1.5rem" }}
              >
                No
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <Error errors={errors} fieldName={"cnd_drivinglicense"} />
          </div>
        </div>
      </div>
      {/* i have car - Start */}
      <div className="col-lg-12 col-md-12 col-md-12">
        <div className="form-group">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label style={{ lineHeight: "1.5rem" }} id="cnd_have_a_car">
              I have a car
              <sup style={{ color: "red" }}>*</sup>
            </label>
          </div>                    
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="custom-control custom-radio">
              <input
                required
                name="cnd_have_a_car"
                onChange={(e) => props.onChange(e)}
                type="radio"
                defaultChecked={checkForRadioButton("cnd_have_a_car", 1)}
                className="custom-control-input"
                id="cnd_have_a_car_yes"
                defaultValue="1"
              />
              <label
                className="custom-control-label"
                htmlFor="cnd_have_a_car_yes"
                style={{ lineHeight: "1.5rem" }}
              >
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                required
                type="radio"
                defaultChecked={checkForRadioButton("cnd_have_a_car", 0)}
                className="custom-control-input"
                id="cnd_have_a_car_no"
                onChange={(e) => props.onChange(e)}
                name="cnd_have_a_car"
                value="0"
              />
              <label
                className="custom-control-label"
                htmlFor="cnd_have_a_car_no"
                style={{ lineHeight: "1.5rem" }}
              >
                No
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <Error errors={errors} fieldName={"cnd_have_a_car"} />
          </div>
        </div>
      </div>          
      {/* i have car - End */}
      <div className="col-lg-12 col-md-12">
        <div className="form-group ">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label style={{ lineHeight: "1.5rem" }}>
              Do you hold a license to drive a mini bus?
              <sup style={{ color: "red" }}>*</sup>
            </label>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="custom-control custom-radio">
              <input
                required
                defaultChecked={checkForRadioButton("cnd_minibuslicense", 1)}
                type="radio"
                className="custom-control-input"
                id="cnd_minibuslicense_yes"
                value="1"
                onChange={(e) => props.onChange(e)}
                name="cnd_minibuslicense"
              />
              <label
                className="custom-control-label"
                htmlFor="cnd_minibuslicense_yes"
                style={{ lineHeight: "1.5rem" }}
              >
                Yes
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="custom-control custom-radio">
              <input
                required
                defaultChecked={checkForRadioButton("cnd_minibuslicense", 0)}
                type="radio"
                className="custom-control-input"
                id="cnd_minibuslicense_no"
                value="0"
                onChange={(e) => props.onChange(e)}
                name="cnd_minibuslicense"
              />
              <label
                className="custom-control-label"
                htmlFor="cnd_minibuslicense_no"
                style={{ lineHeight: "1.5rem" }}
              >
                No
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <Error errors={errors} fieldName={"cnd_minibuslicense"} />
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-12">
        <div className="form-group">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label style={{ lineHeight: "1.5rem" }}>
              Are you a swimmer?<sup style={{ color: "red" }}>*</sup>
            </label>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="custom-control custom-radio">
              <input
                defaultChecked={checkForRadioButton("cnd_swimmer", 1)}
                type="radio"
                className="custom-control-input"
                id="cnd_swimmer_yes"
                value="1"
                onChange={(e) => props.onChange(e)}
                name="cnd_swimmer"
              />
              <label
                className="custom-control-label"
                htmlFor="cnd_swimmer_yes"
                style={{ lineHeight: "1.5rem" }}
              >
                Yes
              </label>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <div className="custom-control custom-radio">
              <input
                defaultChecked={checkForRadioButton("cnd_swimmer", 0)}
                type="radio"
                className="custom-control-input"
                id="cnd_swimmer_no"
                value="0"
                onChange={(e) => props.onChange(e)}
                name="cnd_swimmer"
              />
              <label
                className="custom-control-label"
                htmlFor="cnd_swimmer_no"
                style={{ lineHeight: "1.5rem" }}
              >
                No
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <Error errors={errors} fieldName={"cnd_swimmer"} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PersonalInfoRadioFields;
