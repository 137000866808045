import React, { useEffect, useState } from "react";
import PageTitle from "../components/Layout/PageTitle";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { allJobs } from "./Data";
import { checkUserStatus } from "./checkUserStatus";
import { goBackPage, redirectUrl } from "./GlobalUrls";
import {
  jobContractType,
  jobsEmploymentHours,
  jobsSalarytype,
} from "./JobTypeAndSectorGlobalList";
import logo2 from "../images/logo.png";
import {
  capitalizeFirstLetter,
  convertToOriginalForm,
  convertToSnakeCase,
} from "./StringOperation";
import {
  getDataOnRequest,
  updateDataOnRequest,
} from "../redux/actions/CommonActions";
import { useDispatch } from "react-redux";
import composedJobsContainer from "../containers/JobsContainer";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, Modal } from "react-bootstrap";
import { checkIfFileIsaDocument } from "./FileOperationGlobalFunctions";
import GlobalViewFileModal from "./GlobalViewFileModal";
import constants from "../constants";
import { Input } from "@mui/material";
import { getBase64 } from "../helpers/FileToBase64";
import { updateUserProfile } from "../redux/actions/UserProfileActions";
import { santetizeData } from "../helpers/FormFieldsSanetizations";
import Swal from "sweetalert2";
import JobDetails from "./JobDetails";
import { isMobileOrLaptop } from "./CheckDevice";
import JobDetailsMobile from "./JobDetailsMobile";
var bnr2 = require("./../images/banner/bnr2.jpg");

function JobDetailsMain() {
 // Check Device - Start
 const [checkDevice, setCheckDevice] = useState("Mobile");

 window.addEventListener("resize", function () {
   // Call the function when the screen size changes
   const deviceType = isMobileOrLaptop();
   setCheckDevice(deviceType);
 });

 useEffect(() => {
    if(localStorage.getItem('checkRefreshPage')){
      localStorage.setItem('checkRefreshPage', '');
      window.location.href = "";
    }
   const deviceType = isMobileOrLaptop(160);
   setCheckDevice(deviceType);
 }, []);
 // Check Device - End

 return(
    <>
    {checkDevice && checkDevice == "Mobile" ? (
        <JobDetailsMobile />
    ) : (
        <JobDetails />
    )}
    </>
 );
    
}
export default JobDetailsMain;
