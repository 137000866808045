import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import { format } from "date-fns";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import {
  concateAddress,
  convertToOriginalForm,
} from "../../../services/StringOperation";

const MyAssignmentListMobile = ({ assignmentTypeParam, ...props }) => {
  const [myassignments, SetMyAssignments] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);
  let checkData = false;
  useEffect(() => {
    const filterAssignments = props.myassignments.filter(
      (assign) =>
        assign.cnd_assignment_status ===
        (assignmentTypeParam ? assignmentTypeParam : "Assigned")
    );
    const lenOfData = props.myassignments.length;
    console.log("lenOfData", assignmentTypeParam);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetMyAssignments(filterAssignments);
  }, [props.myassignments]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   SetMyAssignments(props.myassignments);
  // }, [props.myassignments]);

  // Filter Data - Start
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (assign) => {
    const searchText = inputValue.toLowerCase();
    if (assignmentTypeParam == "Cancelled") {
      return (
        format(new Date(assign.cnd_assignment_availability_date), "dd-MM-yyyy")
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_assignment_availability_date
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_availability_week_day.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_start_time.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_end_time.toLowerCase().includes(searchText) ||
        assign.clt_company_name.toLowerCase().includes(searchText) ||
        assign.clt_branch_name.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_cancel_reason
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_assignment_cancel_comment.toLowerCase().includes(searchText)
      );
    } else {
      return (
        format(new Date(assign.cnd_assignment_availability_date), "dd-MM-yyyy")
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_assignment_availability_date
          .toLowerCase()
          .includes(searchText) ||
        assign.cnd_availability_week_day.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_start_time.toLowerCase().includes(searchText) ||
        assign.cnd_assignment_end_time.toLowerCase().includes(searchText) ||
        assign.clt_branch_location.toLowerCase().includes(searchText) ||
        assign.clt_branch_address1.toLowerCase().includes(searchText) ||
        assign.clt_branch_address2.toLowerCase().includes(searchText) ||
        assign.clt_branch_region.toLowerCase().includes(searchText) ||
        assign.clt_branch_postal.toLowerCase().includes(searchText) ||
        (assign.cnd_assignment_job_roles
          ? convertToOriginalForm(assign.cnd_assignment_job_roles)
              .toLowerCase()
              .includes(searchText)
          : "") ||
        assign.clt_company_name.toLowerCase().includes(searchText) ||
        assign.clt_branch_name.toLowerCase().includes(searchText)
      );
    }
  };

  // Filter applied jobs based on the search text
  const filterRows = myassignments
    ? myassignments.filter(matchesSearchText)
    : [];
  // Filter Data - End

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6 p-2">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {filterRows &&
          filterRows.length > 0 &&
          filterRows?.slice(startIndex, endIndex).map((myassignment, i) => {
            return (
              <ul
                className="nav navbar-nav border border-grey p-4 mb-2"
                style={{ borderRadius: "20px" }}
                key={i}
              >
                <li className="text-dark">
                  <i className="fa fa-user-circle mr-1 text-primary"></i>{" "}
                  {myassignment.clt_company_name
                    ? myassignment.clt_company_name.length <= 15
                      ? myassignment.clt_company_name
                      : myassignment.clt_company_name.slice(0, 15) + "..."
                    : ""}
                </li>
                {myassignment.cnd_assignment_client_branch_id &&
                  myassignment.cnd_assignment_client_branch_id ==
                    myassignment.clt_branch_id && (
                    <li className="text-dark">
                      <i className="fa fa-sitemap mr-1 text-primary"></i>{" "}
                      {myassignment.cnd_assignment_client_branch_id &&
                        myassignment.cnd_assignment_client_branch_id ==
                          myassignment.clt_branch_id && (
                          <>
                            {myassignment.clt_branch_location
                              ? String(
                                  myassignment.clt_branch_name +
                                    " - ( " +
                                    myassignment.clt_branch_location +
                                    " )"
                                ).length <= 15
                                ? myassignment.clt_branch_name +
                                  " - ( " +
                                  myassignment.clt_branch_location +
                                  " )"
                                : String(
                                    myassignment.clt_branch_name +
                                      " - ( " +
                                      myassignment.clt_branch_location +
                                      " )"
                                  ).slice(0, 15) + "..."
                              : myassignment.clt_branch_name &&
                                myassignment.clt_branch_name.length <= 15
                              ? myassignment.clt_branch_name
                              : myassignment.clt_branch_name
                              ? myassignment.clt_branch_name.slice(0, 15) +
                                "..."
                              : ""}
                          </>
                        )}
                      {!myassignment.cnd_assignment_client_branch_id && (
                        <>
                          {myassignment.clt_service_town
                            ? String(
                                myassignment.clt_company_name +
                                  " - ( " +
                                  myassignment.clt_service_town +
                                  " )"
                              ).length <= 15
                              ? myassignment.clt_company_name +
                                " - ( " +
                                myassignment.clt_service_town +
                                " )"
                              : String(
                                  myassignment.clt_company_name +
                                    " - ( " +
                                    myassignment.clt_service_town +
                                    " )"
                                ).slice(0, 15) + "..."
                            : myassignment.clt_company_name &&
                              myassignment.clt_company_name.length <= 15
                            ? myassignment.clt_company_name
                            : myassignment.clt_company_name
                            ? myassignment.clt_company_name.slice(0, 15) + "..."
                            : ""}
                        </>
                      )}
                    </li>
                  )}
                <li className="text-dark">
                  <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                  {format(
                    new Date(myassignment.cnd_assignment_availability_date),
                    "dd-MM-yyyy"
                  ) +
                    " (" +
                    myassignment.cnd_availability_week_day
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ") +
                    ")"}
                </li>
                <li className="text-dark">
                  <i className="fa fa-clock-o mr-1 text-primary"></i>{" "}
                  {myassignment.cnd_assignment_start_time +
                    " - " +
                    myassignment.cnd_assignment_end_time}
                </li>

                {assignmentTypeParam == "Cancelled" ? (
                  <>
                    {myassignment.cnd_assignment_cancel_reason && (
                      <li className="text-dark">
                        <i className="fa fa-sticky-note mr-1 text-primary"></i>{" "}
                        {myassignment.cnd_assignment_cancel_reason
                          ? myassignment.cnd_assignment_cancel_reason
                          : ""}
                      </li>
                    )}
                    {myassignment.cnd_assignment_cancel_comment && (
                      <li className="text-dark">
                        <i className="fa fa-commenting mr-1 text-primary"></i>{" "}
                        {myassignment.cnd_assignment_cancel_comment
                          ? myassignment.cnd_assignment_cancel_comment
                          : ""}
                      </li>
                    )}
                  </>
                ) : (
                  <>
                    <li className="text-dark">
                      <i className="fa fa-suitcase mr-1 text-primary"></i>{" "}
                      {myassignment.cnd_assignment_job_roles
                        ? String(
                            convertToOriginalForm(
                              myassignment.cnd_assignment_job_roles
                            )
                          ).length <= 15
                          ? convertToOriginalForm(
                              myassignment.cnd_assignment_job_roles
                            )
                          : convertToOriginalForm(
                              myassignment.cnd_assignment_job_roles
                            ).slice(0, 15) + "..."
                        : ""}
                    </li>
                    <li className="text-dark">
                      <i className="fa fa-map-marker mr-1 text-primary"></i>{" "}
                      {concateAddress(
                        myassignment.clt_branch_address1,
                        myassignment.clt_branch_address2,
                        myassignment.clt_branch_town,
                        myassignment.clt_branch_region,
                        myassignment.clt_branch_postal
                      ) &&
                      String(
                        concateAddress(
                          myassignment.clt_branch_address1,
                          myassignment.clt_branch_address2,
                          myassignment.clt_branch_town,
                          myassignment.clt_branch_region,
                          myassignment.clt_branch_postal
                        )
                      ).length <= 20
                        ? concateAddress(
                            myassignment.clt_branch_address1,
                            myassignment.clt_branch_address2,
                            myassignment.clt_branch_town,
                            myassignment.clt_branch_region,
                            myassignment.clt_branch_postal
                          )
                        : concateAddress(
                            myassignment.clt_branch_address1,
                            myassignment.clt_branch_address2,
                            myassignment.clt_branch_town,
                            myassignment.clt_branch_region,
                            myassignment.clt_branch_postal
                          ).slice(0, 20) + "..."}
                    </li>
                  </>
                )}
              </ul>
            );
          })}
        {myassignments.length === 0 ? (
          <ul
            className="nav navbar-nav border border-grey p-4 mb-2 text-center"
            style={{ borderRadius: "20px" }}
          >
            No Result Found
          </ul>
        ) : filterRows.length === 0 && inputValue ? (
          <ul
            className="nav navbar-nav border border-grey p-4 mb-2 text-center"
            style={{ borderRadius: "20px" }}
          >
            No Result Found
          </ul>
        ) : (
          ""
        )}
      </div>
      {filterRows && filterRows.length > 10 && (
        <TableDropdownAndPagination
          handleDropDown={handleDropDown}
          recordLen={recordLen}
          handleLeft={handleLeft}
          handleRight={handleRight}
          renderPageNumbers={renderPageNumbers}
          isDropdown={true}
          isPaggination={true}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    myassignments: state.myassignments.myassignments,
  };
};

export default connect(mapStateToProps)(MyAssignmentListMobile);
