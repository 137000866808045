import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const DailyLogsContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token && props.dailylogs){
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetDailyLogs','SET_DAILY_LOGS'))
      }

      if(props.userAuth.users_access_token && props.dailylogsserviceuserlist){
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetDailyLogsServiceUsersList','SET_DAILY_LOGS_SERVICE_USER_LIST'))
      }
      
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData,
    dailylogs:state.dailylogs.dailylogs,
    dailylogsserviceuserlist:state.dailylogsserviceuserlist.dailylogsserviceuserlist
  };
};

const composedDailyLogsContainer = compose(connect(mapStateToProps), DailyLogsContainer)
export default composedDailyLogsContainer;

