import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
const TypesOfServicesInfo = (OriginalComponent) => {
  const NewComponent = (props) => {
    toast.dismiss();
    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    typesOfServices:state.userProfile.userData,
    userAuth:state.auth.authUserData,
    loader:state.loader.loader
  };
};

const composedTypesOfServicesInfo = compose(connect(mapStateToProps), TypesOfServicesInfo)
export default composedTypesOfServicesInfo;

