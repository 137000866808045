import React, { useState, useEffect, useRef } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Logout from "./Logout";
import Signup from "./Signup";

import logo2 from "./../../images/logo.png";
import { isAuthenticated } from "../../redux/selectors/AuthSelectors";
import { connect } from "react-redux";
import Login from "./Login";
import BasicRoutes from "../../routers";
import PublicRouter from "../../routers/PublicRouter";
import { isMobileOrLaptop } from "../../services/CheckDevice";
import { getInitialsLettersOfString } from "../../services/GlobalStringOp";
import constants from "../../constants";

var bnr3 = require("./../../images/background/bg3.jpg");

const PageHeader = (props) => {
  let imageRef = useRef(null);
  const [navBarOpen, setNavBarOpen] = useState(false);
  const [profilePic, setProfilePic] = useState(false);
  let history = useHistory();
  const [show, setShow] = useState(false);
  const [pageName, setPageName] = useState("/");
  const [roleId, setUserRoleId] = useState("");
  let userType = localStorage.getItem("user_job_type");
  localStorage.setItem(
    "loggedInUserAccessToken",
    props.userAuth.users_access_token
  );
  localStorage.setItem("loggedInUserId", props.userAuth.users_id);
  localStorage.setItem("users_category", props.userAuth.users_category);
  localStorage.setItem("permanentUserStatus", props.userAuth.cnd_status);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const onClick = (page) => {
    setPageName(page);
    history.push(page);
  };

  useEffect(() => {
    setPageName(history.location.pathname);
    setUserRoleId(props.userAuth.users_role_id);
  }, [history.location.pathname, props.userAuth.users_role_id]);

  useEffect(() => {
    // sidebar open/close
    var Navicon = document.querySelector(".navicon");
    var sidebarmenu = document.querySelector(".myNavbar ");

    function toggleFunc() {
      sidebarmenu.classList.toggle("show");
      //   Navicon.classList.toggle('open');
    }

    Navicon.addEventListener("click", toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".navbar-nav > li > a, .sub-menu > li > a")
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      current.parentElement.parentElement
        .querySelectorAll("li")
        .forEach((el) =>
          current.parentElement !== el ? el.classList.remove("open") : ""
        );
      setTimeout(() => {
        current.parentElement.classList.toggle("open");
      }, 100);
    }
  }, []);

  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  const onLogout = () => {
    localStorage.clear("loginUserDetails");
    window.location.href = "/";
  };

  return (
    <React.Fragment>
      {/* <div class="container-fluid bg-dark px-5 d-none d-lg-block sticky-top toop-bar">
        <div class="row">
            <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                <div class="d-inline-flex align-items-center" style={{height: "45px"}}>
                    <small class="me-3 text-light"><i class="fa fa fa-phone me-2"></i>Hounslow: 0203 841 6440</small>
                    <small class="me-3 text-light"><i class="fa fa fa-phone me-2"></i>Out of office hours: 07508098567</small>
                    <small class="text-light"><i class="fa fa-envelope-open me-2"></i>info@example.com</small>
                </div>
            </div>
        </div>
    </div> */}
      <nav className="site-header mo-left header fullwidth is-fixed">
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar clearfix ">
            <div class="row bg-dark toop-bar">
              <div class="col-12 text-center text-lg-start mb-2 mb-lg-0">
                <div
                  class="d-inline-flex float-right"
                  style={{ height: "45px" }}
                >
                  <small class="text-light">
                    <i class="fa fa fa-phone me-2"></i>
                    <a href="tel:0203 841 6440" className="text-light">
                      {" "}
                      Hounslow: 0203 841 6440
                    </a>
                  </small>
                  <small class="ml-5 mr-1 text-light">
                    <i class="fa fa fa-phone me-2"></i>{" "}
                    <a href="tel:07508098567" className="text-light">
                      {" "}
                      Out of office hours: 07508098567
                    </a>
                  </small>
                  {/* <small class="text-light"><i class="fa fa-envelope-open me-2"></i>info@example.com</small> */}
                </div>
              </div>
            </div>

            {/* <h6 className="float-end  mt-5">Ph: 0203 841 6440</h6> */}
            <div className="container clearfix">
              <div className="logo-header mostion">
                <a href={"/"}>
                  <img
                    src={logo2}
                    className="logo m-t10"
                    style={{ height: "4rem" }}
                    alt="img"
                  />
                </a>
              </div>

                

              <a
                className={"navbar-toggler navicon justify-content-center "+(!props.userData.users_profile_pic && props.userAuth.user_name ? "bg-dark border border-success border-5 pt-0" : "")}
                style={{width:(props.userData.users_profile_pic || props.userAuth.user_name ? "40px" : ""), height:(props.userData.users_profile_pic || props.userAuth.user_name ? "40px" : ""), borderRadius:(!props.userData.users_profile_pic && props.userAuth.user_name ? "20px" : ""), border: (!props.userData.users_profile_pic && props.userAuth.user_name ? "3px" : "")}}
                
                id="navbar_drawer"
                onClick={(event) => {
                  if(navBarOpen == false){
                    setNavBarOpen(true);
                  }else{
                    setNavBarOpen(false);
                  }
                }}
              >
                {!props.userData.users_profile_pic && !props.userAuth.user_name && profilePic == false ? (
                  <>
                  <span />
                  <span />
                  <span />
                  <span />
                  </>
                ) : (props.userData.users_profile_img_path && props.userData.users_profile_pic ? (
                  <>
                  {profilePic == false && (
                    <>
                    <span />
                    <span />
                    <span />
                    </>
                  )}
                  <img
                    className={"size-thumbnail1 rounded-circle cursor-pointer "+(profilePic == true ? "border border-success border-5" : "")}
                    ref={imageRef}
                    onLoad={(event) => {
                      setProfilePic(true);
                    }}
                    src={constants.IMAGE_URL+props.userData.users_profile_img_path+ props.userData.users_profile_pic}
                  />
                  </>
                ) : (
                  <div className="text-white text-center">
                    <h6 className="p-2">
                      {props.userAuth.user_name && getInitialsLettersOfString(
                        props.userAuth.user_name
                      ) &&
                      getInitialsLettersOfString(
                        props.userAuth.user_name
                      ) != "UU" ? (
                        getInitialsLettersOfString(
                          props.userAuth.user_name
                        )
                      ) : (
                        <>
                        <span />
                        <span />
                        <span />
                        <span />
                        </>
                      )}
                    </h6>                    
                  </div>
                ))}
              </a>
              <div className="extra-nav">
                <div className="extra-cell">
                  {((!props.isAuthenticated && pageName != "/login") ||
                    pageName == "/") && (
                    <Login onClick={(page) => onClick(page)} />
                  )}
                  {((!props.isAuthenticated && pageName != "/register") ||
                    pageName == "/") && (
                    <Signup onClick={(page) => onClick(page)} />
                  )}
                  {props.isAuthenticated && <Logout />}
                </div>
              </div>

              <div
                className="header-nav navbar-expand expand myNavbar justify-content-start"
                id="navbarNavDropdown"
                // onMouseOut={(event) => {
                //   let navbar_drawer =  document.getElementById('navbar_drawer');
                //   if(navbar_drawer){
                //     navbar_drawer.click();
                //   }
                // }}                
              >
                <div className="logo-header mostion d-md-block d-lg-none">
                  <a href="#" onClick={() => onClick("/")} className="dez-page">
                    <img src={logo2} alt="" />
                  </a>
                </div>
                <ul className="nav navbar-nav" style={{ height: "100%" }}>
                  <li>
                    {userType ? (
                      <>
                        {userType == "permanentrequirement" ? (
                          <a href={"/candidate-profile"}>Profile & CV</a>
                        ) : (
                          <>
                            {props.userData.cnd_profile_is_temping ==
                            "Allowed" ? (
                              <a href="/dashboard">Dashboard</a>
                            ) : (
                              // <a href={"/candidate-profile"}>Profile & CV</a>
                              <a href="/dashboard">Dashboard</a>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <a href="/">Home</a>
                    )}
                  </li>
                  {!userType && (
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                  )}
                  <li>
                    <a href="/job-board" onClick={() => onClick("#")}>
                      Job Board {/*<i className="fa fa-chevron-down"></i>*/}
                    </a>
                    {/* <ul className="sub-menu">
                      <li>
                        <a href={"/permanent-jobs"} className="dez-page">
                          Permanent Job
                        </a>
                      </li>
                      <li>
                        <a href={"/temporary-jobs"} className="dez-page">
                          Temporary Jobs
                        </a>
                      </li>
                    </ul> */}
                  </li>

                  {!userType && (
                    <li>
                      <a href={"#"}>
                        Our Services <i className="fa fa-chevron-down"></i>
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <a href={"#"} className="dez-page">
                            Education <i className="fa fa-angle-right"></i>
                          </a>
                          <ul className="sub-menu">
                            <li>
                              <a href={"/nursery"} className="dez-page">
                                Nursery{" "}
                              </a>
                            </li>
                            <li>
                              <a href={"/primary"} className="dez-page">
                                Primary{" "}
                              </a>
                            </li>
                            <li>
                              <a href={"/secondary"} className="dez-page">
                                Secondary{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/special-education"}
                                className="dez-page"
                              >
                                Special Education{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/further-education"}
                                className="dez-page"
                              >
                                Further Education{" "}
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href={"#"} className="dez-page">
                            Home Care <i className="fa fa-angle-right"></i>
                          </a>
                          <ul className="sub-menu">
                            <li>
                              <a
                                href={"/care-in-your-home"}
                                className="dez-page"
                              >
                                {" "}
                                Care in your home{" "}
                              </a>
                            </li>
                            <li>
                              <a href={"/live-in-care"} className="dez-page">
                                {" "}
                                Live in Care{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/learning-disabilities-support"}
                                className="dez-page"
                              >
                                Learning Disabilities Support{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/physical-disabilities-support"}
                                className="dez-page"
                              >
                                Physical Disabilities Support{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/reablement-services"}
                                className="dez-page"
                              >
                                Reablement Services{" "}
                              </a>
                            </li>
                            <li>
                              <a href={"/respite-care"} className="dez-page">
                                Respite Care{" "}
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href={"#"} className="dez-page">
                            Social Care <i className="fa fa-angle-right"></i>
                          </a>
                          <ul className="sub-menu">
                            <li>
                              <a
                                href={"/childrens-residential"}
                                className="dez-page"
                              >
                                Children’s Residential{" "}
                              </a>
                            </li>
                            {/* <li><a href={"#"} className="dez-page">Elderly Residential  </a></li> */}
                            <li>
                              <a
                                href={"/learning-disability-day-centres"}
                                className="dez-page"
                              >
                                Learning Disability Day centres{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/childrens-learning-disability-respites"}
                                className="dez-page"
                              >
                                Children's Learning Disability Respites{" "}
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/elderly-care-services"}
                                className="dez-page"
                              >
                                Elderly Care Services{" "}
                              </a>
                            </li>
                            {/* <li><a href={"#"} className="dez-page">Semi Independent Living  </a></li> */}
                          </ul>
                        </li>
                        <li>
                          <a href={"/industrial"} className="dez-page">
                            Industrial
                          </a>
                        </li>
                        <li>
                          <a href={"/commercial"} className="dez-page">
                            Commercial
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}

                  {userType && (
                    <li>
                      <a href={"/applied-jobs"}>Jobs Applied</a>
                    </li>
                  )}

                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  {/* {userType && (
                    <li>
                      <a href={"/account-faq"}>Account Centre</a>
                    </li>
                  )} */}
                  {/* <ul className="nav  navbar-nav"> */}

                  {/* My Account Common Start */}
                  {props.isAuthenticated && (
                    <>
                      {roleId == "candidate" ? (
                        ""
                      ) : (
                        <li>
                          <a>
                            <i
                              class="fa fa-spinner text-center fa-pulse text-primary"
                              style={{ fontSize: "200%" }}
                            ></i>
                          </a>
                        </li>
                      )}
                    </>
                  )}
                  {checkDevice == "Mobile" ? (
                    <>
                      <li
                        style={{
                          display:
                            props.isAuthenticated && roleId == "candidate"
                              ? "block"
                              : "none",
                        }}
                        className="bg-primary"
                      >
                        <a
                          href="/candidate-profile"
                          className="bg-primary text-white"
                          style={{ border: "none" }}
                        >
                          <i
                            className="fa fa-user-circle-o text-left"
                            style={{ fontSize: "100%" }}
                          ></i>
                          My Account
                          {/* {props.userAuth.user_name}{" "} */}
                          {/* <i className="fa fa-chevron-down text-white"></i> */}
                        </a>
                        <ul className="sub-menu" style={{ display: "none" }}>
                          {/* <li>
                        <a href={"/candidate-profile"} className="dez-page">
                          Profile & CV
                        </a>
                      </li> */}
                          {/* <li>
                        <a href={userType == "permanentrequirement" ? "/permanent-candidate-dashboard" : "/dashboard"}>Dashboard</a>
                      </li> */}
                          {/* {userType != "permanentrequirement" && props.userData.cnd_profile_is_temping == "Allowed" && (
                        <>
                          <li>
                            <a href={userType == "permanentrequirement" ? "/permanent-candidate-dashboard" : "/dashboard"}>Temping</a>
                          </li>                        
                        </>
                      )} */}
                          {/* <li>
                            <a href={"/applied-jobs"}>Jobs Applied</a>
                          </li> */}
                          {/* <li>
                        <a href={"/change-password"} className="dez-page">
                          Change Password
                        </a>
                      </li> */}
                          <li>
                            <a href={"/account-faq"} className="dez-page">
                              Account Centre
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        style={{
                          display:
                            props.isAuthenticated && roleId == "candidate"
                              ? "block"
                              : "none",
                        }}
                      >
                        <a
                          href="/candidate-profile"
                          className="bg-primary text-white p-2 mt-3"
                          style={{
                            borderRadius: "20px",
                            textTransform: "capitalize",
                          }}
                        >
                          <i
                            className="fa fa-user-circle-o mr-2"
                            style={{ fontSize: "100%" }}
                          ></i>
                          {/* {props.userAuth.user_name}{" "} */}
                          My Account
                          {/* <i className="fa fa-chevron-down text-white"></i> */}
                        </a>
                        <ul className="sub-menu" style={{ display: "none" }}>
                          {/* <li>
                        <a href={"/candidate-profile"} className="dez-page">
                          Profile & CV
                        </a>
                      </li> */}
                          {/* <li>
                        <a href={userType == "permanentrequirement" ? "/permanent-candidate-dashboard" : "/dashboard"}>Dashboard</a>
                      </li> */}
                          {/* {userType != "permanentrequirement" && props.userData.cnd_profile_is_temping == "Allowed" && (
                        <>
                          <li>
                            <a href={userType == "permanentrequirement" ? "/permanent-candidate-dashboard" : "/dashboard"}>Temping</a>
                          </li>                        
                        </>
                      )} */}
                          {/* <li>
                            <a href={"/applied-jobs"}>Jobs Applied</a>
                          </li> */}
                          {/* <li>
                        <a href={"/change-password"} className="dez-page">
                          Change Password
                        </a>
                      </li> */}
                          <li>
                            <a href={"/account-faq"} className="dez-page">
                              Account Centre
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  )}
                  {/* My Account Common End */}

                  {props.isAuthenticated && roleId == "client" && (
                    <li>
                      <a href="/client-dashboard">My Account</a>
                    </li>
                  )}
                  {props.isAuthenticated && (
                    <li className="d-block d-md-none">
                      <a href="#" onClick={() => onLogout()}>
                        Logout
                      </a>
                    </li>
                  )}
                  {!props.isAuthenticated && (
                    <li className="d-block d-md-none">
                      <a href="/login" onClick={(page) => onClick(page)}>
                        Login
                      </a>
                    </li>
                  )}
                  {!props.isAuthenticated && (
                    <li className="d-block d-md-none">
                      <a href="/register" onClick={(page) => onClick(page)}>
                        Signup
                      </a>
                    </li>
                  )}
                  {/* </ul> */}

                  {/* <li>
                    <a href={"#"}>
                      For Employers <i className="fa fa-chevron-down" />
                    </a>
                    <ul className="sub-menu">
                      <li>
                        <a href={"/company-profile"} className="dez-page">
                          Company Profile <span className="new-page">New</span>
                        </a>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* {console.log("All User Data :=> "+JSON.stringify(props.userAuth.users_category))} */}
      </nav>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
  };
};

export default connect(mapStateToProps)(PageHeader);
