const initialState = {workPrefrences: {}}

export const workPrefrences = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_WORK_PREFRENCES':
      return {workPrefrences: action.payload}; 
  	case 'UPDATE_WORK_PREFRENCES':
      return {workPrefrences: action.payload}; 
  }
  return state
}


