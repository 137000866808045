const initialState = {dbsdocument: []}

export const dbsdocument = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_DBS':
      return {dbsdocument: action.payload}; 
  }
  return state
}


