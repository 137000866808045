import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
/* eslint react/prop-types: 0 */
import styles from "../../../css/nav.css";
import personal from "../../../images/form-img/personal.png";
import cv from "../../../images/form-img/cv.png";
import success from "../../../images/form-img/success.png";
import { toast } from "react-toastify";
const Nav = (props) => {
  const steps = [personal, cv, success];
  const [currentStep, SetCurrentState] = useState(0);
  const { switchStep } = props;
  const dots = [];

  const { userData } = props;
  const nextStep = (i) => {
    toast.dismiss();
    SetCurrentState(i);
    props.goToStep(i);
  };

  useEffect(() => {
    SetCurrentState(currentStep + 1);
    nextStep(currentStep + 1);
  }, [switchStep]);
  //((i <= nextForm && props.userData['form_'+props.currentStep] == 1) || props.currentStep >= i)&&
  for (let i = 1; i <= props.totalSteps; i += 1) {
    //let nextStep = i-1;
    //const isActive =  ((i <= props.currentStep || i == props.currentStep) || careplan['form_'+i] == 1);
    const isActive = userData["profile_" + i] == 1;
    let nextForm = props.currentStep + 1;
    dots.push(
      <span
        key={`step-${i}`}
        onClick={() =>
          (userData["profile_" + (i - 1)] == 1 || i == 1 || isActive) &&
          nextStep(i)
        }
      >
        <img
          src={steps[i - 1]}
          className={`step-images-care-plan ${
            isActive
              ? "active"
              : userData["profile_" + (i - 1)] == 1 || i == 1
              ? "on-process"
              : "in-active"
          }`}
        />
      </span>
    );
  }

  return <div className={styles.nav}>{dots}</div>;
};

const mapStateToProps = (state) => {
  return {
    userData: state.userProfile.userData,
    switchStep: state.switchStep.switchStep,
  };
};

export default connect(mapStateToProps)(Nav);
