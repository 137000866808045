import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { redirectUrl } from "../../services/GlobalUrls";
import { capitalizeFirstLetter } from "../../services/StringOperation";
import { jobTitle } from "../../helpers/WorkRefrencesHelper";
import { checkIfFileIsaDocument } from "../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../services/GlobalViewFileModal";
import { getInitialsLettersOfString } from "../../services/GlobalStringOp";
const PermanentProfilesidebar = (props) => {
  let cndStatus = localStorage.getItem("tempUserStatus");
  const history = useHistory();
  const { progress, userData, dbsDocuments } = props;
  // Modal States - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // Modal State - End
  const [formStates, setFormStates] = useState({
    userProfile: false,
  });
  const [progresStatus, setProgress] = useState(0);

  useEffect(() => {
    setFormStates({
      userProfile: progress.progress_bar_profile_1 ? true : false,
    });
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 50;
    }
    // For Applied Jobs
    if (progress.cnd_permanent_job_title) {
      totalProgress = totalProgress + 50;
    }

    setProgress(totalProgress);
  }, [progress, userData]);
  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    users_category,
    users_access_token,
    users_profile_pic,
    users_profile_img_path,
    user_sign_signature,
    job_type,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "135px";
      imageRef.current.style.height = "135px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };

  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
  }, [props.userData]);

  // Modal Function - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };
  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // Modal Function - End

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };

  return (
    <div className="col-xl-3 col-lg-4 m-b30">
      <div className="wrapper">
        <div className="candidate-info">
          <div className="candidate-detail text-center">
            <div
              className={
                "canditate-des " +
                (users_profile_img_path && users_profile_pic ? "" : "bg-dark")
              }
            >
              <CircularProgressbarWithChildren
                className="CircularProgressbar-background"
                value={progresStatus}
              >
                {users_profile_img_path && users_profile_pic ? (
                  <img
                    className="size-thumbnail1 rounded-circle cursor-pointer"
                    width={"135px"}
                    height={"135px"}
                    ref={imageRef}
                    onClick={() => {
                      if (users_profile_img_path && users_profile_pic) {
                        viewFileModal(
                          constants.IMAGE_URL +
                            users_profile_img_path +
                            users_profile_pic
                        );
                      } else {
                        Swal.fire({
                          title: "Profile Picture Not Available",
                          text: "",
                          icon: "warning",
                          showCancelButton: false,
                          confirmButtonText: "Ok",
                          // confirmButtonColor: 'blue'
                        });
                      }
                    }}
                    src={require("./../../images/team/pic1.jpg")}
                  />
                ) : (
                  <div className="mt-4 text-white">
                    <h1 style={{ fontSize: "60px" }}>
                      {getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) &&
                      getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) != "UU" ? (
                        getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        )
                      ) : (
                        <i
                          class="fa fa-spinner text-center fa-pulse text-white"
                          style={{ fontSize: "80px" }}
                        ></i>
                      )}
                    </h1>
                  </div>
                )}
              </CircularProgressbarWithChildren>
              <div style={{ fontSize: 18, marginTop: 5 }}>
                <strong>{progresStatus}%</strong> Completed
              </div>
              <div
                className="upload-link border"
                title="Upload Image"
                data-toggle="tooltip"
                data-placement="right"
                style={{ marginTop: "65%" }}
              >
                <input
                  type="file"
                  className="update-flie"
                  onChange={(e) => onChangeInputvalue(e)}
                  accept=".png,.jpeg,.jpg"
                />
                <i className="fa fa-camera"></i>
              </div>
            </div>

            <div className="candidate-title">
              <div className="">
                <h4 className="m-b5">
                  <b>{users_firstname}</b>
                </h4>
                <p className="m-b0">
                  <b>
                    {props.userAuth.users_category
                      ? capitalizeFirstLetter(
                          String(props.userAuth.users_category)
                        )
                      : ""}
                  </b>
                </p>
              </div>
            </div>
          </div>
          <ul>
            <li>
              <a
                href={"/permanent-candidate-dashboard"}
                className={`${
                  window.location.pathname == "/dashboard" && "active"
                }`}
              >
                <i className="fa  fa-bar-chart" aria-hidden="true"></i>
                <span className="text-md">
                  <b>Dashboard</b>
                </span>
              </a>
            </li>
            <li>
              <a
                href={"/permanent-job-profile"}
                className={`${page == "profile" && "active"}`}
              >
                <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                <span>My Profile</span>
                <i
                  className="fa fa-check-circle m-l10 text-success"
                  style={
                    progress.progress_bar_profile_1 == 0 ||
                    !progress.progress_bar_profile_1
                      ? { display: "none" }
                      : { display: "inline-block" }
                  }
                  aria-hidden="true"
                ></i>
              </a>
            </li>

            <li>
              <a
                href={"/applied-jobs"}
                className={`${page == "appliedJobs" && "active"}`}
              >
                <i className="fa fa-check-square-o" aria-hidden="true"></i>
                <span>Jobs Applied</span>
                <i
                  className="fa fa-check-circle m-l10 text-success"
                  style={
                    !progress.cnd_permanent_job_title
                      ? { display: "none" }
                      : { display: "inline-block" }
                  }
                  aria-hidden="true"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"Profile Picture"}
        // fileName={fileName}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    progress: state.progress.progress,
  };
};
export default connect(mapStateToProps)(PermanentProfilesidebar);
