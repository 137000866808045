const initialState = {switchStep: 0}

export const switchStep = (state = initialState, action) => {
	switch (action.type) {
  	case 'SWITCH_TO_NEXT':
      return {switchStep: state.switchStep + 1}; 
  }
  return state
}


