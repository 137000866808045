import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const WorkPrefrencesContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token && Object.keys(props.workPrefrences).length == 0)
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'SetWorkPrefrences','SET_WORK_PREFRENCES'))
      if(props.userAuth.users_access_token && Object.keys(props.workPrefrencesJobTitles).length == 0)
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetWorkPrefrencesJobTitles','GET_WORK_PREFRENCES_JOB_TITLES'))
      if(props.userAuth.users_access_token && Object.keys(props.workPreferenceQualificationDocuments).length == 0)
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetWorkPrefrencesQualificationDocuments','GET_WORK_PREFRENCES_QUALIFICATION_DOCUMENTS'))
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    workPrefrences:state.workPrefrences.workPrefrences,
    workPrefrencesJobTitles:state.workPrefrencesJobTitles.workPrefrencesJobTitles,
    workPreferenceQualificationDocuments:state.workPreferenceQualificationDocuments.workPreferenceQualificationDocuments,
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData
  };
};

const composedWorkPrefrencesContainer = compose(connect(mapStateToProps), WorkPrefrencesContainer)
export default composedWorkPrefrencesContainer;

