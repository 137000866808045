import { main } from "react-devtools";
import * as Yup from "yup";
import { format } from "date-fns";
let currentDate = format(new Date(), "dd-MM-yyyy");

export const formHaveExperience = () => [
  {
    name: `cnd_five_year_of_experience`,
    field: "cnd_five_year_of_experience",
    type: "dropdown",
    options: [
      { id: "", name: "Select experience", disabled: false },
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
    label: "Do you have 5 or more year's of experience?",
    md: "12",
    sm: "12",
  },
];

export const formHaveMoreThenExperience = () => [
  {
    name: `refYearsSelect`,
    field: "refYearsSelect",
    type: "dropdown",
    options: [
      { id: "", name: "Select Experience Preferenece", disabled: false },
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
    label: "Have you worked more than 5 year's for same employer?",
    md: "12",
    sm: "12",
  },
];

export const formInputEmployementRefrence = (key) => [
  {
    name: `employementReference.${key}.cnd_refer_record_company_name`,
    field: "cnd_refer_record_company_name",
    type: "text",
    label: "Organisation Name",
    md: "6",
    sm: "12",
  },
  {
    name: `employementReference.${key}.cnd_refer_record_position`,
    field: "cnd_refer_record_position",
    type: "text",
    label: "Position Held",
    md: "6",
    sm: "12",
  },
  {
    name: `employementReference.${key}.cnd_refer_record_fromDate`,
    field: "cnd_refer_record_fromDate",
    max: new Date().toISOString().split("T")[0],
    min: new Date(new Date().setFullYear(new Date().getFullYear() - 63))
      .toISOString()
      .split("T")[0],
    type: "date",
    label: "From Date",
    md: "6",
    sm: "12",
  },
  {
    name: `employementReference.${key}.cnd_refer_record_toDate`,
    field: "cnd_refer_record_toDate",
    max: new Date().toISOString().split("T")[0],
    min: new Date(new Date().setFullYear(new Date().getFullYear() - 63))
      .toISOString()
      .split("T")[0],
    type: "date",
    label: "To Date",
    md: "6",
    sm: "12",
  },
  {
    name: `employementReference.${key}.cnd_refer_record_country`,
    field: "cnd_refer_record_country",
    type: "country",
    label: "Country",
    md: "6",
    sm: "12",
  },
  {
    name: `employementReference.${key}.cnd_refer_record_phone_no`,
    field: "cnd_refer_record_phone_no",
    type: "text",
    label: "Telephone Number",
    md: "6",
    sm: "12",
  },
  {
    name: `employementReference.${key}.cnd_refer_record_email`,
    field: "cnd_refer_record_email",
    type: "email",
    label: "Contact Person's Email",
    md: "6",
    sm: "12",
  },
  {
    name: `employementReference.${key}.cnd_refer_record_contact_name`,
    field: "cnd_refer_record_contact_name",
    type: "text",
    label: "Contact Person's Name",
    md: "6",
    sm: "12",
  },
  {
    name: `employementReference.${key}.cnd_refer_record_qualification`,
    field: "cnd_refer_record_qualification",
    type: "text",
    label: "Contact Person's Designation",
    md: "6",
    sm: "12",
  },
];

export const validationSchemaEmployment = (isProfessional) => {
  // console.warn("Demo Test Date :=>"+JSON.stringify(isProfessional));
  // if(isProfessional === 'yes'){
  //   return Yup.object().shape({
  //     refYearsSelect: Yup.string()
  //           .required("Please Select Work Experience")
  //           .typeError("Please Select Work Experience"),
  //     employementReference: Yup.array().of(
  //       Yup.object().shape({
  //         cnd_refer_record_company_name: Yup.string()
  //           .required("Please Enter Institute Name")
  //           .typeError("Please Enter Institute Name"),
  //         cnd_refer_record_country: Yup.string()
  //           .required("Please Select From Country")
  //           .typeError("Please Select From Country"),
  //         cnd_refer_record_fromDate: Yup.date()
  //           .required("Please Enter From Date")
  //           .typeError("Please Enter From Date"),
  //         cnd_refer_record_toDate: Yup.date()
  //           .required("Please Enter To Date")
  //           .typeError("Please Enter To Date")
  //           .min(
  //             Yup.ref("cnd_refer_record_fromDate"),
  //             "End Date Can't Be Before Start Date"
  //           ),
  //         cnd_refer_record_phone_no: Yup.number()
  //           .required("Please Enter Telephone Number")
  //           .typeError("Please Enter Telephone Number"),
  //         cnd_refer_record_email: Yup.string().required("Please Enter Email"),
  //         cnd_refer_record_contact_name: Yup.string().required(
  //           "Please Enter Conatct Name"
  //         ),
  //         cnd_refer_record_position: Yup.string().required(
  //           "Please Enter Conatct Position"
  //         ),
  //         cnd_refer_record_qualification: Yup.string().required(
  //           "Please Enter Conatct Position Held"
  //         ),
  //       })
  //     ),
  //   });
  // }else{
  return Yup.object().shape({
    employementReference: Yup.array().of(
      Yup.object().shape({
        cnd_refer_record_company_name: Yup.string()
          .required("Please Enter Institute Name")
          .trim("")
          .typeError("Please Enter Institute Name"),
        cnd_refer_record_country: Yup.string()
          .required("Please Select From Country")
          .typeError("Please Select From Country"),
        cnd_refer_record_fromDate: Yup.date()
          .required("Please Enter From Date")
          .typeError("Please Enter From Date")
          .min(
            new Date(new Date().setFullYear(new Date().getFullYear() - 63))
              .toISOString()
              .split("T")[0],
            "Please select the valid date, date must be 23-06-1960 or later"
          )
          .max(
            new Date().toISOString().split("T")[0],
            "Please choose the " + currentDate + " or before this date"
          ),
        cnd_refer_record_toDate: Yup.date()
          .required("Please Enter To Date")
          .typeError("Please Enter To Date")
          .min(
            Yup.ref("cnd_refer_record_fromDate"),
            "End Date Can't Be Before Start Date"
          )
          .max(
            new Date().toISOString().split("T")[0],
            "Please choose the " + currentDate + " or before this date"
          ),
        cnd_refer_record_phone_no: Yup.string()
          .required("Please Enter Telephone Number")
          .typeError("Please Enter Telephone Number")
          .matches(
            /^0\d{10}$/,
            "Please enter valid 11 digit phone number starting from 0 "
          ),
        cnd_refer_record_email: Yup.string()
          .required("Please Enter Email")
          .matches(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            "Please enter the valid email address"
          ),
        cnd_refer_record_contact_name: Yup.string()
          .required("Please Enter Conatct Name")
          .trim(""),
        cnd_refer_record_position: Yup.string()
          .required("Please Enter the Position")
          .trim(""),
        cnd_refer_record_qualification: Yup.string()
          .required("Please Enter Conatct Person(s) Designation")
          .trim(""),
      })
    ),
    professionalReference: Yup.array().of(
      Yup.object().shape({
        cnd_refer_record_company_name: Yup.string()
          .required("Please Enter Institute Name")
          .typeError("Please Enter Institute Name")
          .trim(""),
        cnd_refer_record_contact_name: Yup.string()
          .required("Please Enter Contact Name")
          .typeError("Please Enter Contact Name")
          .trim(""),
        cnd_refer_record_country: Yup.string()
          .required("Please Select Country")
          .typeError("Please Select Country"),
        cnd_refer_record_fromDate: Yup.date()
          .required("Please Enter From Date")
          .typeError("Please Enter From Date")
          .min(
            new Date(new Date().setFullYear(new Date().getFullYear() - 63))
              .toISOString()
              .split("T")[0],
            "Please select the valid date, date must be 23-06-1960 or later"
          )
          .max(
            new Date().toISOString().split("T")[0],
            "Please choose the " + currentDate + " or before this date"
          ),
        cnd_refer_record_toDate: Yup.date()
          .required("Please Enter To Date")
          .typeError("Please Enter To Date")
          .min(
            Yup.ref("cnd_refer_record_fromDate"),
            "End Date Can't Be Before Start Date"
          )
          .max(
            new Date().toISOString().split("T")[0],
            "Please choose the " + currentDate + " or before this date"
          ),
        cnd_refer_record_phone_no: Yup.string()
          .required("Please Enter Telephone Number")
          .typeError("Please Enter Telephone Number")
          .matches(
            /^0\d{10}$/,
            "Please enter valid 11 digit phone number starting from 0 "
          ),
        cnd_refer_record_email: Yup.string()
          .required("Please Enter Email")
          .matches(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            "Please enter the valid email address"
          )
          .trim(""),
      })
    ),
  });

  //}
};
