import React, { useState, useEffect } from "react";
import TobContainer from "../../../containers/TobContainer";
import TobForm from "./TobForm";
const TobTemp = (props) => {
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            TOB Permanent
          </h5>
        </div>
        <TobForm />
      </div>
    </React.Fragment>
  );
};

export default TobContainer(TobTemp);
