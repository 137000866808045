import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import composedTimesheetsContainer from "../../../containers/TimeSheetsContainer";
import TimesheetList from "./TimeSheetList";
import { useEffect } from "react";
import { redirectWindowUrl } from "../../../services/GlobalUrls";
import TimeSheetListMobile from "./TimeSheetListMobile";
import { Tooltip } from "@mui/material";

const TimesheetMainComponent = ({ deviceInfo, ...props }) => {
  const [viewToolTip, setViewToolTip] = useState({
    download: false,
    upload: false,
  });
  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <h5 className="font-weight-700 pull-left text-uppercase">
                  Timesheets
                </h5>
                {deviceInfo && deviceInfo == "Mobile" ? (
                  <>
                    <Tooltip
                      title="Upload Timesheet"
                      className="text-sm"
                      open={viewToolTip.upload}
                      placement="top"
                    >
                      <a
                        href={"/create-timesheet"}
                        className="pull-right btn btn-success"
                        onTouchStart={(e) => {
                          setViewToolTip({ upload: true });
                        }}
                        onTouchEnd={(e) => {
                          setViewToolTip({ upload: false });
                        }}
                      >
                        <i className="fa fa-upload"></i>
                      </a>
                    </Tooltip>

                    <Tooltip
                      title="Download Timesheet"
                      className="text-sm"
                      open={viewToolTip.download}
                      placement="top"
                    >
                      <a
                        href="https://api.itstaffingsolutions.co.uk/uploads/staffing-connect-timesheet-template.pdf"
                        className="pull-right btn btn-primary mr-2"
                        onTouchStart={(e) => {
                          setViewToolTip({ download: true });
                        }}
                        onTouchEnd={(e) => {
                          setViewToolTip({ download: false });
                        }}
                      >
                        <i className="fa fa-download"></i>
                      </a>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <a
                      href="/create-timesheet"
                      className="pull-right btn btn-primary"
                    >
                      Upload Timesheet
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        redirectWindowUrl(
                          "https://api.itstaffingsolutions.co.uk/uploads/staffing-connect-timesheet-template.pdf"
                        )
                      }
                      className="pull-right btn btn-dark text-white mr-2"
                      data-toggle="tooltip"
                      title="Download Timesheet Template"
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>{" "}
                      Timesheet Template
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {deviceInfo && deviceInfo == "Mobile" ? (
          <TimeSheetListMobile />
        ) : (
          <TimesheetList />
        )}
      </div>
    </React.Fragment>
  );
};

export default composedTimesheetsContainer(TimesheetMainComponent);
