import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CarePlanContainer from "../../../containers/CarePlanContainer";
import Service from "./Service";
const CarePlan = (props) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">Services</h5>
        </div>
        <Service />
      </div>
    </React.Fragment>
  );
};

export default CarePlanContainer(CarePlan);
