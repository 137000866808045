import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Dropdown from "../../FormElements/Dropdown";
import { Button, Spinner } from "react-bootstrap";
import UserProfile from "../../../containers/UserProfile";
import { updatePermanentCandidateProfile } from "../../../redux/actions/UserProfileActions";
import AddressFields from "./AddressFields";
import PersonalInfoFields from "./PersonalInfoForm";
import PersonalInfoRadioFields from "./PersonalInfoRadioFields";
import { santetizeData } from "../../../helpers/FormFieldsSanetizations";
import { getBase64 } from "../../../helpers/FileToBase64";
import { CandidatePersonalInfoValidation } from "../../../helpers/PermanentCandidateValidation";
// import TrainingQualifications from "../TrainingQualifications";
import Resume from "../Resume";
import Input from "../../FormElements/Input";
import { jobArray, jobsArray } from "./JobArray";
const PersonalInfoContainer = (props) => {
  const dispatch = useDispatch();
  const [userdata, setFormdata] = useState(props.userData);
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [formData, setFormData] = useState({}); //Set Form Data While Form Submission
  const history = useHistory();
  const { loader } = props;

  const onChangeInputvalue = async (e) => {
    if (e.target.name === "cnd_upload_cv") {
      if (e.target.files[0])
        userdata["cnd_upload_cv"] = await getBase64(e.target.files[0]);
    } else if (e.target.name === "cnd_upload_cover") {
      if (e.target.files[0])
        userdata["cnd_upload_cover"] = await getBase64(e.target.files[0]);
    } else if (e.target.name === "cnd_upload_dbs") {
      if (e.target.files[0])
        userdata["cnd_upload_dbs"] = await getBase64(e.target.files[0]);
    } else if (e.target.name == "cnd_consent_check") {
      if (e.target.checked == true) {
        // console.log("Demo Check :=>"+e.target.name);
        userdata["cnd_consent_check"] = "yes";
      } else {
        userdata["cnd_consent_check"] = "no";
      }
    } /*else if (e.target.name == "cnd_permanent_job_title") {
      userdata["cnd_job_position"] = e.target.value;
      userdata["cnd_permanent_job_title"] = e.target.value;
    }*/ else {
      if (e.target.value) userdata[e.target.name] = e.target.value;
      else userdata[e.target.name] = "";
    }

    validateDataBeforeSubmit("submit");
    // validateDataBeforeSubmit(e.target.name);
    setFormdata(userdata);
  };

  const validateDataBeforeSubmit = (type) => {
    let validations = CandidatePersonalInfoValidation(userdata, type, errors);
    setErrors(validations.stateData);
    return validations.error;
  };
  const updateUserProfilePersonalInfo = (e) => {
    e.preventDefault();
    //console.warn("Target value :=> "+e.target[3].value);

    userdata["cnd_birth_date"] = e.target[5].value; //Cnd Birth Date
    userdata["cnd_street_address"] = e.target[8].value;
    userdata["cnd_street_address_2"] = e.target[9].value;
    userdata["cnd_town"] = e.target[10].value;
    userdata["cnd_region"] = e.target[11].value;
    userdata["cnd_postcode"] = e.target[12].value;
    userdata["cnd_county"] = "GB"; //For Country
    userdata["cnd_consent_check"] = userdata["cnd_consent_check"]
      ? userdata["cnd_consent_check"]
      : "no";
    // Job Position Start
    // console.log("Data Testing :=>" + e.target[15].name);
    // if (e.target[15].value == "yes") {
    //   userdata["cnd_job_position"] = e.target[17].value;
    //   userdata["cnd_permanent_job_title"] = e.target[17].value;
    // } else {
    //   userdata["cnd_job_position"] = e.target[16].value;
    //   userdata["cnd_permanent_job_title"] = e.target[16].value;
    // }

    let cnd_gender = document.getElementById("cnd_gender");
    let cnd_birth_date = document.getElementById("cnd_birth_date");
    let cnd_traing_and_qualifications = document.getElementById(
      "cnd_traing_and_qualifications"
    );
    let cnd_street_address = document.getElementById("cnd_street_address");
    let cnd_street_address_2 = document.getElementById("cnd_street_address_2");
    let cnd_town = document.getElementById("cnd_town");
    let cnd_region = document.getElementById("cnd_region");
    let cnd_postcode = document.getElementById("cnd_postcode");
    let cnd_county = document.getElementById("cnd_county");
    // let cnd_right_to_work =  document.getElementById("cnd_right_to_work");
    let cnd_drivinglicense = document.getElementById("cnd_drivinglicense");
    let cnd_disability_condition = document.getElementById(
      "cnd_disability_condition"
    );
    let cnd_disability_description = document.getElementById(
      "cnd_disability_description"
    );
    let cnd_permanent_job_title = document.getElementById(
      "cnd_permanent_job_title"
    );
    let cnd_upload_cv = document.getElementById("cnd_upload_cv");
    let cnd_consent_check = document.getElementById("cnd_consent_check_label");

    // Date Focus Start
    let birthDate = new Date(String(cnd_birth_date.value));
    let currentDate = new Date();
    let minDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 73)
    );
    let maxDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 15)
    );
    // console.warn("Full Year of Current Date :=>"+birthDate);
    if (!cnd_gender.value) {
      cnd_gender.focus();
    } else if (!cnd_birth_date.value) {
      cnd_birth_date.focus();
    } /*if(birthDate > currentDate || birthDate.getFullYear() > 2011 || birthDate.getFullYear() < 1950 || (birthDate.getFullYear() >= 2011 && birthDate.getMonth() > 5)){*/ else if (
      birthDate > currentDate ||
      birthDate > maxDate ||
      birthDate < minDate
    ) {
      cnd_birth_date.focus();
    } else if (
      !cnd_traing_and_qualifications.value ||
      cnd_traing_and_qualifications.value.trim() == ""
    ) {
      cnd_traing_and_qualifications.focus();
    } else if (
      !cnd_street_address.value ||
      cnd_street_address.value.trim() == ""
    ) {
      cnd_street_address.focus();
    } else if (
      cnd_street_address_2.value &&
      cnd_street_address_2.value.trim() == ""
    ) {
      cnd_street_address_2.focus();
    } else if (!cnd_town.value || cnd_town.value.trim() == "") {
      cnd_town.focus();
    } else if (cnd_region.value && cnd_region.value.trim() == "") {
      cnd_region.focus();
    } else if (!cnd_postcode.value || cnd_postcode.value.trim() == "") {
      cnd_postcode.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    } else if (
      !String(cnd_postcode.value).match(
        /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
      )
    ) {
      cnd_postcode.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    } else if (!cnd_county.value || cnd_county.value.trim() == "") {
      cnd_county.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    } /*else if(!cnd_right_to_work.value){
      cnd_right_to_work.focus();
    }*/ else if (!cnd_drivinglicense.value) {
      cnd_drivinglicense.focus();
    } else if (!cnd_disability_condition.value) {
      cnd_disability_condition.focus();
    } else if (
      cnd_disability_condition.value == "yes" &&
      (!cnd_disability_description.value ||
        cnd_disability_description.value.trim() == "")
    ) {
      cnd_disability_description.focus();
      cnd_disability_condition.scrollIntoView({ behavior: "smooth" }, true);
    } /*else if (!cnd_permanent_job_title.value) {
      cnd_permanent_job_title.focus();
    }*/ else if (!cnd_upload_cv.value && !userdata.cnd_upload_cv) {
      cnd_upload_cv.focus();
    } else if (!cnd_consent_check.value) {
      // cnd_consent_check.focus();
      cnd_consent_check.scrollIntoView({ behavior: "smooth" }, true);
    }

    if (!validateDataBeforeSubmit("submit")) {
      document.getElementById("loader").style.display = "block";
      userdata["users_access_token"] = props.userAuth.users_access_token;
      // console.log("All Data is :=> "+JSON.stringify(userdata));
      dispatch(
        updatePermanentCandidateProfile(
          santetizeData(userdata),
          "UpdatePersonalPermanentInfo"
        )
      );
      //history.push('/emplyement-references');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setFormdata(props.userData);
  }, [props.userData]);

  // Middle Name Disabled Start
  // let users_middlename = document.getElementById("users_middlename");

  // if(users_middlename.value && userdata.users_middlename){
  //   users_middlename.disabled =  true;
  // }
  // // Middle Name Disabled Stop

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Personal Information
          </h5>
          {/* <a
            href="/applied-jobs"
            className="btn btn-success site-button float-right"
          >
            Applied Jobs
          </a> */}
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => updateUserProfilePersonalInfo(e)}
          onKeyUp={(e) => onChangeInputvalue(e)}
          onClick={(e) => onChangeInputvalue(e)}
          noValidate
          encType="multipart/form-data"
        >
          <div className="row m-b30">
            <PersonalInfoFields
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            />
            <AddressFields
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            />
            {/* <PersonalInfoRadioFields
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            /> */}
            {/* <TrainingQualifications
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            /> */}
            {/* <Dropdown
              name="cnd_permanent_job_title"
              value={userdata.cnd_permanent_job_title}
              id={"cnd_permanent_job_title"}
              label="Select Job Position"
              options={jobsArray}
              changeHandler={(e) => onChangeInputvalue(e)}
              errors={errors}
              lg={12}
            /> */}
            <Resume
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            />

            {/* Consent Start */}
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="text-justify " id="cnd_consent_check_label">
                    <h6>
                      I consent to the Company processing the above personal
                      data for the following purposes:
                    </h6>{" "}
                    <p className="ml-3">
                      <b>•</b> For the Company to provide me with work-finding
                      services.{" "}
                    </p>{" "}
                    <p className="ml-3">
                      <b>•</b> For the Company to process with or transfer my
                      personal data to their named client/s in order to provide
                      me with work-finding services.
                    </p>{" "}
                    <p className="ml-3">
                      <b>•</b> For the Company to process my data on a
                      computerised database (provided by Recruitment Software)
                      in order to provide me with work-finding services.
                    </p>{" "}
                    I, also consent to the company to process my information and
                    forward the personal data contained on my CV to the
                    Employer.
                    <br />
                    The consent I give to the Company will last for two years.
                    <br /> I am aware that I have the right to withdraw my
                    consent at any time by informing the Company that I wish to
                    do so, or by completing the withdrawal of consent form.
                    <br /> Further information on how we store, use, and share
                    your data is detailed in our Privacy Policy.
                    <br />
                    <br />
                    <h6 className="">I give my consent for:*</h6>
                    <div class="form-check form-switch ml-5 mt-3">
                      {/* <input
                        type="checkbox"
                        name="cnd_availability_week_status"
                        checked
                        role="checkbox"
                        id="cnd_consent_check"
                        // value={data[day] && data[day].cnd_availability_week_status}
                        className={"form-check-input"}
                      /> */}
                      {userdata.cnd_consent_check == "no" && (
                        <input
                          type="checkbox"
                          name="cnd_consent_check"
                          role="switch"
                          id="cnd_consent_check"
                          value={userdata.cnd_consent_check}
                          onChange={(e) => onChangeInputvalue(e)}
                          className="form-check-input"
                          errors={errors}
                        />
                      )}
                      {!userdata.cnd_consent_check && (
                        <input
                          type="checkbox"
                          name="cnd_consent_check"
                          role="switch"
                          id="cnd_consent_check"
                          value={userdata.cnd_consent_check}
                          onChange={(e) => onChangeInputvalue(e)}
                          className="form-check-input"
                          errors={errors}
                        />
                      )}
                      {userdata.cnd_consent_check == "yes" && (
                        <input
                          type="checkbox"
                          name="cnd_consent_check"
                          role="switch"
                          checked
                          id="cnd_consent_check"
                          value={userdata.cnd_consent_check}
                          onChange={(e) => onChangeInputvalue(e)}
                          className="form-check-input"
                          errors={errors}
                        />
                      )}
                      The Company to process my data and forward my CV to the
                      Employer.
                      <p
                        id="errorMsg"
                        style={{ display: "none" }}
                        className="text-danger"
                      >
                        {" "}
                        Please agree the consent to proceed
                      </p>
                    </div>
                    {/* <div class="form-check form-switch ml-5">
                      <input
                        type="checkbox"
                        name="cnd_availability_week_status"
                        role="switch"
                        id="cnd_consent_check"
                        //value={data[day] && data[day].cnd_availability_week_status}
                        className="form-check-input"
                      />
                      The Company to only process my data.
                    </div> */}
                    <br />
                    <b className="">Commitment to proceed to interview</b>
                    <br />
                    <p className="mt-3">
                      <>
                        If my application is successful, I confirm that I am
                        committed to attending the first interview online or in
                        person for the role discussed with Staffing Connect.
                      </>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Consent Stop */}
          </div>
          <Button
            disabled={loader}
            className="site-button m-b30 float-right"
            type="submit"
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader ? (userdata.cnd_consent_check == "yes" ? "Update" : "Save") : ""}
          </Button>
          <br />
          <br />
        </form>
      </div>
      {/* <TrainingQualifications /> */}
    </React.Fragment>
  );
};

export default UserProfile(PersonalInfoContainer);
