//TableDropdown
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
function TableDropdownAndPagination({
  handleDropDown,
  recordLen,
  handleLeft,
  handleRight,
  renderPageNumbers,
  isDropdown,
  isPaggination,
  deviceType
}) {
  return (
    <div className="d-flex flex-column flex-md-row">
    {isDropdown && (
      <div className="col-12 col-md-4 mt-1 text-center text-md-left">
        <Dropdown onSelect={(e) => handleDropDown(e)}>
          <Dropdown.Toggle variant="success" id="dropdown-basic" value={recordLen} className="mx-auto green">
            {recordLen}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="10">10</Dropdown.Item>
            <Dropdown.Item eventKey="25">25</Dropdown.Item>
            <Dropdown.Item eventKey="50">50</Dropdown.Item>
            <Dropdown.Item eventKey="100">100</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )}
  
    <div className="col-12 col-md-4"></div>
  
    {isPaggination && (
      <div className="col-12 col-md-4 mt-1 d-flex justify-content-center justify-content-md-end">
        <ul className="pagination">
          <li >
            <Link to="#" onClick={handleLeft}>
              Prev
            </Link>
          </li>
  
          {renderPageNumbers()}
  
          <li className="pagination-li">
            <Link to="#" onClick={handleRight}>
              Next
            </Link>
          </li>
        </ul>
      </div>
    )}
  </div>
  
  
  
  );
}
export default TableDropdownAndPagination;
