import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, Route } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
const Profilesidebar = (props) => {
  const history = useHistory();
  const { progress, userData, dbsDocuments } = props;
  const [formStates, setFormStates] = useState({
    userProfile: false,
    employmentReferences: false,
    typesOfServices: false,
    agreements: false,
    bankDetails: false,
    rightToWork: false,
    workPreferences: false,
    documentsCompliance: false,
    dbsCertificate: false,
    identityDocument: false,
  });
  const [progresStatus, setProgress] = useState(0);
  useEffect(() => {
    setFormStates({
      userProfile: progress.progress_bar_profile_1 ? true : false,
      employmentReferences: progress.progress_bar_reference_2 ? true : false,
      typesOfServices: userData.cnd_types_of_services !== "" ? true : false,
      agreements: progress.progress_bar_agreement_3 ? true : false,
      bankDetails: progress.progress_bar_bank_4 ? true : false,
      rightToWork: progress.progress_bar_right_to_work_5 ? true : false,
      workPreferences: progress.progress_bar_work_preference_6 ? true : false,
      documentsCompliance: progress.progress_bar_compliance_7 ? true : false,
      dbsCertificate: progress.progress_bar_dbs_8 ? true : false,
      identityDocument: progress.progress_bar_identity_9 ? true : false,
    });
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 10;
    }

    if (progress.progress_bar_reference_2 == "1") {
      totalProgress = totalProgress + 10;
    }
    // if(progress.cnd_types_of_services !== ""){
    //   totalProgress = totalProgress+10
    // }
    if (progress.progress_bar_agreement_3 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_bank_4 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_right_to_work_5 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_work_preference_6 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_compliance_7 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_dbs_8 == "1") {
      totalProgress = totalProgress + 15;
    }
    if (progress.progress_bar_identity_9 == "1") {
      totalProgress = totalProgress + 15;
    }

    setProgress(totalProgress);
  }, [progress, userData]);
  const dispatch = useDispatch();
  const {
    users_firstname,
    users_category,
    users_access_token,
    users_profile_pic,
    users_profile_img_path,
    user_sign_signature,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "135px";
      imageRef.current.style.height = "135px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };

  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
  }, [props.userData]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };
  // sticky-top
  return (
    <div className="col-xl-3 col-lg-4 m-b30">
      <div className="wrapper">
        <div className="candidate-info">
          <div className="candidate-detail text-center">
            <div className="canditate-des">
              {/* <CircularProgressbarWithChildren className="CircularProgressbar-background" value={progresStatus}> */}
              <img
                className="size-thumbnail1 rounded-circle"
                width={"136px"}
                height={"136px"}
                ref={imageRef}
                src={require("./../../images/team/pic1.jpg")}
              />

              {/* </CircularProgressbarWithChildren> */}
              {/* <div style={{ fontSize: 18, marginTop: 5 }}>
                <strong>{progresStatus}%</strong> Completed
              </div> */}
              <div
                className="upload-link border"
                title="Upload Image"
                data-toggle="tooltip"
                data-placement="right"
                style={{ marginTop: "50%" }}
              >
                <input
                  type="file"
                  className="update-flie"
                  onChange={(e) => onChangeInputvalue(e)}
                  accept=".png,.jpeg,.jpg"
                />
                <i className="fa fa-camera"></i>
              </div>
            </div>

            <div className="candidate-title">
              <div className="">
                <h4 className="m-b5">
                  <Link to={"#"}>{users_firstname}</Link>
                </h4>
                <p className="m-b0">
                  <Link to={"#"}>{props.userAuth.users_category}</Link>
                </p>
              </div>
            </div>
          </div>
          <ul>
            <li>
              <Link
                to={"/client-dashboard"}
                className={`${
                  window.location.pathname == "/client-dashboard" && "active"
                }`}
              >
                <i className="fa  fa-bar-chart" aria-hidden="true"></i>
                <span className="text-md">
                  <b>Dashboard</b>
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={"/company-details"}
                className={`${
                  window.location.pathname == "/comapny-details" && "active"
                }`}
              >
                <i className="fa fa-building-o" aria-hidden="true"></i>
                <span>Company Profile</span>
              </Link>
            </li>

            <li>
              <Link
                to={"/job"}
                className={`${
                  window.location.pathname == "/job"
                    ? "active"
                    : window.location.pathname.includes("create-job") == true &&
                      "active"
                }`}
              >
                <i className="fa fa-suitcase"></i>
                <span>Jobs</span>
              </Link>
            </li>

            <li>
              <Link
                to={"/assignments"}
                className={`${
                  window.location.pathname == "/assignments"
                    ? "active"
                    : window.location.pathname.includes("create-assignments") ==
                        true && "active"
                }`}
              >
                <i className="fa fa-sticky-note-o"></i>
                <span>Assignments</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(Profilesidebar);
