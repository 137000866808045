import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInputFields, validationSchema } from "./FormHelper";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import LoadFormFields from "../../Layout/LoadFormFields";
const ChargeRateForm = (props) => {
  const [enterdata, setFormdata] = useState({});
  const { loader, userDetails, chargerate } = props;
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (chargerate) setFormdata(chargerate);
    if (chargerate && Object.keys(chargerate).length > 0)
      Object.keys(chargerate).map((key) => {
        setValue(key, chargerate[key]);
      });
  }, [chargerate]);

  const onSubmit = (data) => {
    let formData = data;
    formData["users_access_token"] = userDetails.users_access_token;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "SaveChangeRate",
        ""
      )
    );
  };

  const onChangeInputvalue = (e) => {
    const { name, value } = e.target;
    enterdata[name] = value;
    setFormdata(enterdata);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LoadFormFields
          register={(e, v) => register(e, v)}
          errors={errors}
          formInputFields={formInputFields()}
          onChangeInputvalue={(e) => onChangeInputvalue(e)}
          reset={reset}
          control={control}
          data={enterdata}
        />
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    chargerate: state.chargerate.chargerate,
  };
};
export default connect(mapStateToProps)(ChargeRateForm);
