import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { capitalizeFirstLetter } from "../../services/StringOperation";
import { redirectUrl } from "../../services/GlobalUrls";
import { checkIfFileIsaDocument } from "../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../services/GlobalViewFileModal";
import { getInitialsLettersOfString } from "../../services/GlobalStringOp";
let checkRed = "0",
  urlRed;
const PermanentDashboardSidebar = (props) => {
  const { progress, userData, dbsDocuments } = props;
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    users_phonenumber,
    users_email,
    users_category,
    users_access_token,
    users_profile_pic,
    job_type,
    users_profile_img_path,
    user_sign_signature,
    cnd_status,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "140px";
      imageRef.current.style.height = "145px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };

  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
  }, [props.userData]);

  // Modal Code - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };
  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // Modal Code - End

  return (
    <>
      <div className="col-xl-3 col-lg-4 m-b30 " id="content-dashboard">
        <div className="">
          <div className="candidate-info">
            <div className="candidate-detail text-center">
            <div
                  className={
                    "canditate-des " +
                    (users_profile_img_path && users_profile_pic
                      ? ""
                      : "bg-dark")
                  }
                >
                  {users_profile_img_path && users_profile_pic ? (
                    <img
                      className="size-thumbnail border border-default rounded-circle cursor-pointer"
                      width={"136px"}
                      height={"136px"}
                      ref={imageRef}
                      onClick={() => {
                        if (users_profile_img_path && users_profile_pic) {
                          viewFileModal(
                            constants.IMAGE_URL +
                              users_profile_img_path +
                              users_profile_pic
                          );
                        } else {
                          Swal.fire({
                            title: "Profile Picture Not Available",
                            text: "",
                            icon: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                            // confirmButtonColor: 'blue'
                          });
                        }
                      }}
                      src={require("./../../images/team/pic1.jpg")}
                    />
                  ) : (
                    <div className="text-white" style={{ marginTop: "35%" }}>
                      <h1 style={{ fontSize: "60px" }}>
                        {getInitialsLettersOfString(users_firstname + " " + users_lastname) &&
                        getInitialsLettersOfString(users_firstname + " " + users_lastname) !=
                          "UU" ? (
                          getInitialsLettersOfString(users_firstname + " " + users_lastname)
                        ) : (
                          <i
                            class="fa fa-spinner text-center fa-pulse text-white"
                            style={{ fontSize: "80px", marginTop: "-10%" }}
                          ></i>
                        )}
                      </h1>
                    </div>
                  )}
                  <div
                    className="upload-link border"
                    title="update"
                    data-toggle="tooltip"
                    data-placement="right"
                    style={{
                      marginTop:
                        users_profile_img_path && users_profile_pic
                          ? "50%"
                          : "70%",
                    }}
                  >
                    <input
                      type="file"
                      className="update-flie"
                      onChange={(e) => onChangeInputvalue(e)}
                      accept=".png,.jpeg,.jpg"
                    />
                    <i className="fa fa-camera"></i>
                  </div>
                </div>
              <div className="candidate-title">
                <div className="">
                  <h4 className="m-b5">
                    <b>
                      {users_firstname}
                      <br />
                    </b>
                  </h4>
                  <p className="m-b0">
                    <b>
                      {props.userAuth.users_category
                        ? capitalizeFirstLetter(
                            String(props.userAuth.users_category)
                          )
                        : ""}
                    </b>
                  </p>
                  <p className="m-b0">
                    <b>{users_phonenumber}</b>
                  </p>
                  <p className="m-b0">
                    <b>{users_email}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GlobalViewFileModal
          modalOption={modalOption}
          closeFileModal={closeFileModal}
          fileUrl={fileUrl}
          loaderEnd={loaderEnd}
          modalHeight={modalHeight}
          callModal={callModal}
          title={"Profile Picture"}
          // fileName={fileName}
        />
      </div>
    </>
  );
};

/*const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData
  };
};*/

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(PermanentDashboardSidebar);
