import React from 'react';
import Profilesidebar from '../components/Element/DashboardSideBar';
import Dashboard from '../components/Element/Dashboard';
const DashboardContainer = (props) => {
	
	return (
		<React.Fragment>
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								<Profilesidebar page="job"/>
								<div className="col-xl-9 col-lg-8 m-b30 ">
									<Dashboard />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default DashboardContainer;