import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
const CarePlanContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const { userAuth, careplan } = props;
    const dispatch = useDispatch();
    useEffect(()=>{
      if(userAuth.users_access_token && Object.keys(careplan).length == 0)
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetCarePlan','SET_CARE_PLAN'))
    },[userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    careplan:state.careplan.careplan
  };
};

const composedCarePlanContainer = compose(connect(mapStateToProps), CarePlanContainer)
export default composedCarePlanContainer;

