const initialState = {userData: {},clientPhoto:{}}

export const userProfile = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_USER_DATA':
      return {...state,userData: action.payload}; 
  	case 'SET_CLIENT_PHOTO':
      return {...state,clientPhoto: action.payload}; 
  }
  return state
}


