import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const BankDetailInfo = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      if(props.userAuth.users_access_token && Object.keys(props.bankInfo).length == 0)
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'BankDetails','SET_BANK_DATA'))
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props} />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    bankInfo: state.bankInfo.bankInfo,
    userAuth: state.auth.authUserData,
    loader: state.loader.loader
  };
};

const composedBankDetailInfo = compose(connect(mapStateToProps), BankDetailInfo)
export default composedBankDetailInfo;

