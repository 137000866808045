import React from "react";
import { Link } from "react-router-dom";
import icon from "../images/logo/icon1.png";
import Background from "../images/banner/bnr1.jpg";
import Jobsection from "../components/Element/Jobsection";

const TemporaryJobs = () => {
  // Add title
  document.getElementById("titleText").innerText = "Staffing Connect - Job Board(Temporary Jobs)";

  return (
    <Jobsection />
  );
};
export default TemporaryJobs;
