import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {loadUserExperienceData} from '../redux/actions/UserProfileActions'
import { toast } from 'react-toastify';
import { getDataOnRequest } from '../redux/actions/CommonActions';
const UserExperience = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token && props.useExperience.length == 0){
        dispatch(loadUserExperienceData(props.userAuth.users_access_token))
      }
      if(props.userAuth.users_access_token && Object.keys(props.workAndQualificationDetails).length == 0){
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'SetWorkAndQualification','SET_WORK_AND_QUALIFICATION_DETAILS'))
      }
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    useExperience:state.userExperience.userData,
    userAuth:state.auth.authUserData,
    workAndQualificationDetails:state.workAndQualificationDetails.workAndQualificationDetails,
    userInfo:state.userProfile.userData
  };
};

const composedUserExperience = compose(connect(mapStateToProps), UserExperience)
export default composedUserExperience;

