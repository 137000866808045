import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import JobsContainer from "../../../containers/Jobs";
import JobsForm from "./JobsForm";
import JobList from "./JobList";
const PersonalInfoContainer = (props) => {
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">Jobs</h5>
          <Link
            to="/create-job"
            className="pull-right btn btn-secondry site-button btn-sm"
          >
            Create Job
          </Link>
        </div>
        <JobList />
      </div>
    </React.Fragment>
  );
};

export default JobsContainer(PersonalInfoContainer);
