import React from "react";
const Error = (props) => {
  return (
    <React.Fragment>
      {typeof props.errors != "undefined" && (
        <div className="text-danger fs-12 error">
          {typeof props.errors[props.fieldName] != undefined &&
            props.errors[props.fieldName]}
        </div>
      )}
    </React.Fragment>
  );
};

export default Error;
