import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Input from "../../FormElements/Input";
import Dropdown from "../../FormElements/Dropdown";
import Label from "../../Layout/Label";
import Textarea from "../../FormElements/Textarea";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const AppliedJobsForm = (props) => {
  const [userdata, setFormdata] = useState({});
  const location = useLocation();
  const selectedTitle = location.state || {};
  const { errors } = props;
  // console.log("Demo Test :=> "+JSON.stringify(selectedTitle));
  // useEffect(() => {
  //     setFormdata(props.data);
  // }, [props.data]);
  return (
    <React.Fragment>
      <Dropdown
        name="cnd_permanent_jobs_id"
        id={"cnd_permanent_jobs_id"}
        value={
          selectedTitle.selectedTitle
            ? selectedTitle.selectedTitle
            : userdata.cnd_permanent_jobs_id
        }
        label="Select Job Position "
        options={props.data}
        className={"mb-0"}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
        lg={12}
      />
    </React.Fragment>
  );
};

export default AppliedJobsForm;
