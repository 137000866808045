import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IdentityDocumentListView } from "../../../helpers/DocumentsList";
import constants from "../../../constants";
import { redirectUrl } from "../../../services/GlobalUrls";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
const IdentityListMobile = (props) => {
  const [identityDocuments, setIdentityDocument] = useState([]);
  let cndApproved = localStorage.getItem("tempUserStatus");
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [inputValue, setInputValue] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.identityDocuments.length;
    console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    setIdentityDocument(props.identityDocuments);
  }, [props.identityDocuments]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      // document.getElementById("loader").style.display = "block";
      // setModalOption(true);
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  // const loaderEnd = () => {
  //   document.getElementById("loader").style.display = "none";
  //   setModalOption(true);
  //   setModalHeight("auto");
  // };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  const matchesSearchText = (identityDoc) => {
    const searchText = inputValue.toLowerCase();
    return (
      identityDoc.cnd_identity_doc_created_date
        .toLowerCase()
        .includes(searchText) ||
      IdentityDocumentListView[identityDoc.cnd_identity_doc_master_id]
        .toLowerCase()
        .includes(searchText) ||
      identityDoc.cnd_identity_doc_exp_date
        .toLowerCase()
        .includes(searchText) ||
      identityDoc.cnd_identity_doc_reference.toLowerCase().includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filterRows = identityDocuments
    ? identityDocuments.filter(matchesSearchText)
    : [];

  return (
    <React.Fragment>
      <div className="row m-b30" id="scrollFocusElement">
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          {/* Search Start */}
          <div className="container">
            <div className="row">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <label class="form-control-label text-right" for="search">
                  Search
                </label>
                <div class="form-group form-group-default">
                  <input
                    type="text"
                    name="search"
                    class="form-control "
                    placeholder="Enter key to search"
                    onInput={(event, value) => {
                      setInputValue(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Search End */}
          <div className="col-lg-12 col-md-12 m-b0 wrapper">
            {filterRows &&
              filterRows.length > 0 &&
              filterRows.slice(startIndex, endIndex).map((identity, i) => (
                <ul
                  className="nav navbar-nav border border-grey p-4 mb-2"
                  style={{ borderRadius: "20px" }}
                  key={i}
                >
                  <li className="text-dark">
                    <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                    {identity.cnd_identity_doc_created_date}
                  </li>
                  <li className="text-dark">
                    <i className="fa fa-file-text mr-1 text-primary"></i>{" "}
                    <a
                      onClick={() =>
                        viewFileModal(
                          constants.IMAGE_URL +
                            identity.cnd_identity_doc_folder_path +
                            identity.cnd_identity_doc_upload_name,
                          IdentityDocumentListView[
                            identity.cnd_identity_doc_master_id
                          ]
                        )
                      }
                      className={
                        "text-" +
                        (identity.cnd_identity_doc_upload_name.endsWith(
                          ".png"
                        ) ||
                        identity.cnd_identity_doc_upload_name.endsWith(
                          ".jpg"
                        ) ||
                        identity.cnd_identity_doc_upload_name.endsWith(
                          ".jpeg"
                        ) ||
                        identity.cnd_identity_doc_upload_name.endsWith(".pdf")
                          ? "primary"
                          : "primary") +
                        ""
                      }
                    >
                      <u>
                        {identity.cnd_identity_doc_master_id
                          ? IdentityDocumentListView[
                              identity.cnd_identity_doc_master_id
                            ] &&
                            String(
                              IdentityDocumentListView[
                                identity.cnd_identity_doc_master_id
                              ]
                            ).length <= 15
                            ? IdentityDocumentListView[
                                identity.cnd_identity_doc_master_id
                              ]
                            : String(
                                IdentityDocumentListView[
                                  identity.cnd_identity_doc_master_id
                                ]
                              ).slice(0, 15) + "..."
                          : ""}
                      </u>
                    </a>
                    <a
                      onClick={() =>
                        viewFileModal(
                          constants.IMAGE_URL +
                            identity.cnd_identity_doc_folder_path +
                            identity.cnd_identity_doc_upload_name,
                          IdentityDocumentListView[
                            identity.cnd_identity_doc_master_id
                          ]
                        )
                      }
                      className={
                        "text-" +
                        (identity.cnd_identity_doc_upload_name.endsWith(
                          ".png"
                        ) ||
                        identity.cnd_identity_doc_upload_name.endsWith(
                          ".jpg"
                        ) ||
                        identity.cnd_identity_doc_upload_name.endsWith(
                          ".jpeg"
                        ) ||
                        identity.cnd_identity_doc_upload_name.endsWith(".pdf")
                          ? "primary"
                          : "primary") +
                        " text-sm pull-right mt--2"
                      }
                    >
                      {/* <i
                          className={
                            "fa fa-" +
                            (identity.cnd_identity_doc_upload_name.endsWith(".png") ||
                            identity.cnd_identity_doc_upload_name.endsWith(".jpg") ||
                            identity.cnd_identity_doc_upload_name.endsWith(".jpeg") ||
                            identity.cnd_identity_doc_upload_name.endsWith(".pdf")
                              ? "eye" //"eye"
                              : "download")
                          }
                        ></i> */}
                      <u>View File</u>
                    </a>
                  </li>
                  <li className="text-dark">
                    <i className="fa fa-certificate mr-1 text-primary"></i>{" "}
                    {identity.cnd_identity_doc_reference &&
                    identity.cnd_identity_doc_reference.length <= 15
                      ? identity.cnd_identity_doc_reference
                      : identity.cnd_identity_doc_reference &&
                        identity.cnd_identity_doc_reference.slice(0, 15) +
                          "..."}
                  </li>
                  <li className="text-dark">
                    <i className="fa fa-calendar-times-o mr-1 text-primary"></i>{" "}
                    {identity.cnd_identity_doc_exp_date}
                  </li>
                  <li
                    className={
                      new Date() <=
                        new Date(
                          splitDateToFormat(identity.cnd_identity_doc_exp_date)
                        ) &&
                      dateDiffrence(identity.cnd_identity_doc_exp_date) > 30
                        ? "text-success "
                        : new Date() <=
                            new Date(
                              splitDateToFormat(
                                identity.cnd_identity_doc_exp_date
                              )
                            ) &&
                          dateDiffrence(identity.cnd_identity_doc_exp_date) < 30
                        ? "text-warning"
                        : "text-danger"
                    }
                  >
                    <i className="fa fa-circle-o-notch mr-1 "></i>{" "}
                    {new Date() <=
                      new Date(
                        splitDateToFormat(identity.cnd_identity_doc_exp_date)
                      ) &&
                    dateDiffrence(identity.cnd_identity_doc_exp_date) > 30
                      ? "Valid "
                      : new Date() <=
                          new Date(
                            splitDateToFormat(
                              identity.cnd_identity_doc_exp_date
                            )
                          ) &&
                        dateDiffrence(identity.cnd_identity_doc_exp_date) < 30
                      ? "About to Expire"
                      : "Expired"}
                  </li>
                </ul>
              ))}

            {identityDocuments.length === 0 ? (
              <ul
                className="nav navbar-nav border border-grey p-4 mb-2 text-center"
                style={{ borderRadius: "20px" }}
              >
                No Result Found
              </ul>
            ) : filterRows.length === 0 && inputValue ? (
              <ul
                className="nav navbar-nav border border-grey p-4 mb-2 text-center"
                style={{ borderRadius: "20px" }}
              >
                No Result Found
              </ul>
            ) : (
              ""
            )}
            {filterRows && filterRows.length > 10 && (
           <TableDropdownAndPagination
              handleDropDown={handleDropDown}
              recordLen={recordLen}
              handleLeft={handleLeft}
              handleRight={handleRight}
              renderPageNumbers={renderPageNumbers}
              isDropdown={true}
              isPaggination={true}
            />
          )}
            <a
              href="#"
              onClick={() => redirectUrl("/agreements")}
              className="pull-right btn btn-primary mr-2"
            >
              Next
            </a>
          </div>
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        // title={"Identity Document"}
        fileName={fileName}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    identityDocuments: state.identityDocuments.identityDocuments,
  };
};

export default connect(mapStateToProps)(IdentityListMobile);
