import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
const Label = (props) => {
  const [mandetory, setIsMandetory] = useState(true);
  useEffect(() => {
    if (typeof props.mandetory == "undefined") {
      setIsMandetory(true);
    } else {
      setIsMandetory(props.mandetory);
    }
  }, []);

  return (
    <React.Fragment>
      <Form.Label htmlFor={props.htmlFor}>
        {props.labelName}
        {mandetory && (
          <sup
            style={
              props.req === false
                ? { color: "red", display: "none" }
                : { color: "red" }
            }
          >
            *
          </sup>
        )}
      </Form.Label>
    </React.Fragment>
  );
};

Label.propTypes = {
  labelName: PropTypes.string.isRequired,
};

export default Label;
