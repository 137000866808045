import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
const IdentityDocumentContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token && props.identityDocument.length == 0){
        (params.id) && 
          dispatch(getDataOnRequest(props.userAuth.users_access_token,'SetIdentityDocument','SET_IDENTITY_DOCUMENT',params.id))
      }
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    identityDocument:state.identityDocument.identityDocument,
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData
  };
};

const composedIdentityDocumentContainer = compose(connect(mapStateToProps), IdentityDocumentContainer)
export default composedIdentityDocumentContainer;

