import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { documentsList } from "../../../helpers/DocumentsList";
import constants from "../../../constants";
import { redirectUrl } from "../../../services/GlobalUrls";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";

const RightToWorkList = (props) => {
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [rightToWorks, SetRightToWork] = useState([]);
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const { progress } = props;

  let cndApproved = localStorage.getItem("tempUserStatus");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.rightToWorks.length;
    console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetRightToWork(props.rightToWorks);
  }, [props.rightToWorks]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  // const loaderEnd = () => {
  //   document.getElementById("loader").style.display = "none";
  //   setModalOption(true);
  //   setModalHeight("auto");
  // };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  const documentsRequiredCall = () => {
    let urlRed;
    if (
      progress.progress_bar_compliance_7 == null ||
      progress.progress_bar_compliance_7 == 0 ||
      progress.progress_bar_compliance_7 == "0"
    ) {
      if (!progress.cnd_compliance_doc_master_id) {
        urlRed = "/create-document-address1";
      } else if (
        progress.cnd_compliance_doc_master_id &&
        progress.cnd_compliance_doc_master_id == "address1"
      ) {
        urlRed = "/create-document-address2";
      } else if (
        progress.cnd_compliance_doc_master_id &&
        progress.cnd_compliance_doc_master_id == "address2"
      ) {
        urlRed = "/create-document-national-insurance";
      } else {
        urlRed = "/document-required";
      }
    }
    redirectUrl(urlRed ? urlRed : "/document-required");
  };

  return (
    <React.Fragment>
      <div className="row m-b30" id="scrollFocusElement">
        {/* Search Start */}
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  id="myInput"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          <table className="table table-striped table-hover table-sm table-responsive">
            <thead className="thead-dark">
              <tr>
                {/*<th className="col-1 text-left" scope="col">Sr. No</th>*/}
                <th className="col-1 text-left" scope="col">
                  Date & Time
                </th>
                <th className="col-3 text-center" scope="col">
                  Document Name
                </th>
                <th className="col-2 text-center" scope="col">
                  Document(s) Uploaded
                </th>
                <th className="col-2 text-center" scope="col">
                  Expiry Date
                </th>
                <th className="col-3 text-center" scope="col">
                  Status
                </th>
                {cndApproved != "Approved" && (
                  <th className="col-1 text-center" scope="col">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody id="myTable">
              {rightToWorks &&
                rightToWorks.slice(startIndex, endIndex).map((work, i) => (
                  <tr
                    className={
                      new Date() <=
                        new Date(
                          splitDateToFormat(work.cnd_rightwork_expire_date)
                        ) && dateDiffrence(work.cnd_rightwork_expire_date) > 30
                        ? " "
                        : new Date() <=
                            new Date(
                              splitDateToFormat(work.cnd_rightwork_expire_date)
                            ) &&
                          dateDiffrence(work.cnd_rightwork_expire_date) < 30
                        ? "table-warning"
                        : "table-dander"
                    }
                    key={i}
                  >
                    {/*<th className="col-1 text-center" scope="row">{i + 1}</th>*/}
                    <td className="col-4 text-center">
                      {work.cnd_rightwork_created_date}
                    </td>
                    <td className="col-3 text-center">
                      {documentsList[work.cnd_rightwork_document_master_id]}
                    </td>
                    <td className="col-2 text-center">
                      <a
                        onClick={() =>
                          viewFileModal(
                            constants.IMAGE_URL +
                              work.cnd_rightwork_folder_path +
                              work.cnd_rightwork_upload_file,
                            documentsList[work.cnd_rightwork_document_master_id]
                          )
                        }
                        className={
                          "btn btn-" +
                          (work.cnd_rightwork_upload_file.endsWith(".png") ||
                          work.cnd_rightwork_upload_file.endsWith(".jpg") ||
                          work.cnd_rightwork_upload_file.endsWith(".jpeg") ||
                          work.cnd_rightwork_upload_file.endsWith(".pdf")
                            ? "dark"
                            : "primary") +
                          " btn-sm text-white"
                        }
                      >
                        <i
                          className={
                            "fa fa-" +
                            (work.cnd_rightwork_upload_file.endsWith(".png") ||
                            work.cnd_rightwork_upload_file.endsWith(".jpg") ||
                            work.cnd_rightwork_upload_file.endsWith(".jpeg") ||
                            work.cnd_rightwork_upload_file.endsWith(".pdf")
                              ? "eye" //"eye"
                              : "download")
                          }
                        ></i>
                      </a>
                    </td>
                    <td className="col-2 text-center">
                      {work.cnd_rightwork_expire_date}
                    </td>
                    <td className="col-3 text-center">
                      {new Date() <=
                        new Date(
                          splitDateToFormat(work.cnd_rightwork_expire_date)
                        ) && dateDiffrence(work.cnd_rightwork_expire_date) > 30
                        ? "Valid "
                        : new Date() <=
                            new Date(
                              splitDateToFormat(work.cnd_rightwork_expire_date)
                            ) &&
                          dateDiffrence(work.cnd_rightwork_expire_date) < 30
                        ? "About to Expire"
                        : "Expired"}
                    </td>
                    {cndApproved != "Approved" && (
                      <td className="col-1" scope="col">
                        {" "}
                        <Link
                          to={"/create-right-to-work/" + work.cnd_rightwork_id}
                        >
                          <i className="fa fa-pencil"></i>
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          {rightToWorks && rightToWorks.length > 0 && (
            <TableDropdownAndPagination
              handleDropDown={handleDropDown}
              recordLen={recordLen}
              handleLeft={handleLeft}
              handleRight={handleRight}
              renderPageNumbers={renderPageNumbers}
              isDropdown={true}
              isPaggination={true}
            />
          )}
          <a
            href="#"
            onClick={documentsRequiredCall}
            className="site-button listingnextbtn"
          >
            Next
          </a>
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        fileName={fileName}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"Right To Work"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    rightToWorks: state.rightToWorks.rightToWorks,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(RightToWorkList);
