import * as Yup from "yup";
export const formInputFields = (company) => [
  {
    name: "clt_company_name",
    type: "input",
    label: "Company Name",
    md: "6",
    sm: "12",
    value: company.clt_company_name,
  },
  {
    name: "clt_trade_name",
    type: "input",
    label: "Company Trade Name",
    md: "6",
    sm: "12",
    value: company.clt_trade_name,
  },
  {
    name: "clt_company_number",
    type: "input",
    label: "Company Number",
    md: "4",
    sm: "12",
    value: company.clt_company_number,
  },
  {
    name: "clt_company_vat_number",
    type: "input",
    label: "Vat Number",
    md: "4",
    sm: "12",
    value: company.clt_company_vat_number,
  },
  {
    name: "clt_company_authorised",
    type: "input",
    label: "Authorised",
    md: "4",
    sm: "12",
    value: company.clt_company_authorised,
  },
  // Company Rgister Address Start
  {
    name: "clt_registered_address_1",
    type: "input",
    id: "clt_registered_address_1",
    placeholder: "Address Line 1",
    label: "Registered Address",
    md: "12",
    sm: "12",
    value: company.clt_registered_address_1,
  },
  {
    name: "clt_registered_address_2",
    type: "input",
    id: "clt_registered_address_2",
    placeholder: "Address Line 2 (optional)",
    md: "12",
    sm: "12",
    req: false,
    value: company.clt_registered_address_2,
  },
  {
    name: "clt_registered_town",
    type: "input",
    id: "clt_registered_town",
    placeholder: "Town",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_registered_town,
  },
  {
    name: "clt_registered_region",
    type: "input",
    id: "clt_registered_region",
    placeholder: "Region (optional)",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_registered_region,
  },
  {
    name: "clt_registered_postalcode",
    type: "input",
    id: "clt_registered_postalcode",
    placeholder: "Postal Code",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_registered_postalcode,
  },
  {
    name: "clt_registered_country",
    type: "hidden",
    id: "clt_registered_country",
    placeholder: "Country",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_registered_country,
  },
  // Company Register Address Stop

  // Company Billing Address Start
  {
    name: "clt_billing_address_1",
    type: "input",
    id: "clt_billing_address_1",
    placeholder: "Address Line 1",
    label: "Billing Address",
    md: "12",
    sm: "12",
    value: company.clt_billing_address_1,
  },
  {
    name: "clt_billing_address_2",
    type: "input",
    id: "clt_billing_address_2",
    placeholder: "Address Line 2 (optional)",
    md: "12",
    sm: "12",
    req: false,
    value: company.clt_billing_address_2,
  },
  {
    name: "clt_billing_town",
    type: "input",
    id: "clt_billing_town",
    placeholder: "Town",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_billing_town,
  },
  {
    name: "clt_billing_region",
    type: "input",
    id: "clt_billing_region",
    placeholder: "Region (optional)",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_billing_region,
  },
  {
    name: "clt_billing_postalcode",
    type: "input",
    id: "clt_billing_postalcode",
    placeholder: "Postal Code",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_billing_postalcode,
  },
  {
    name: "clt_billing_country",
    type: "hidden",
    id: "clt_billing_country",
    placeholder: "Country",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_billing_country,
  },
  // Company Billing Address Stop

  // Company Service Address Start
  {
    name: "clt_service_address_1",
    type: "input",
    id: "clt_service_address_1",
    placeholder: "Address Line 1",
    label: "Service Address",
    md: "12",
    sm: "12",
    value: company.clt_service_address_1,
  },
  {
    name: "clt_service_address_2",
    type: "input",
    id: "clt_service_address_2",
    placeholder: "Address Line 2 (optional)",
    md: "12",
    sm: "12",
    req: false,
    value: company.clt_service_address_2,
  },
  {
    name: "clt_service_town",
    type: "input",
    id: "clt_service_town",
    placeholder: "Town",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_service_town,
  },
  {
    name: "clt_service_region",
    type: "input",
    id: "clt_service_region",
    placeholder: "Region (optional)",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_service_region,
  },
  {
    name: "clt_service_postalcode",
    type: "input",
    id: "clt_service_postalcode",
    placeholder: "Postal Code",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_service_postalcode,
  },
  {
    name: "clt_service_country",
    type: "hidden",
    id: "clt_service_country",
    placeholder: "Country",
    req: false,
    md: "0",
    sm: "12",
    value: company.clt_service_country,
  },
  // Company Service Address Stop
  // Contact Information Start
  {
    name: "clt_contact_name",
    type: "input",
    label: "Contact Name",
    req: true,
    md: "4",
    sm: "12",
    value: company.clt_contact_name,
  },
  {
    name: "clt_trade_contact_no",
    type: "input",
    label: "Trade Contact No",
    req: true,
    md: "4",
    sm: "12",
    value: company.clt_trade_contact_no,
  },
  {
    name: "clt_telephone_1",
    type: "input",
    label: "Telephone 1",
    req: true,
    md: "4",
    sm: "12",
    value: company.clt_telephone_1,
  },
  {
    name: "clt_telephone_2",
    type: "input",
    label: "Telephone 2",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_telephone_2,
  },
  {
    name: "clt_fax",
    type: "input",
    label: "Fax",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_fax,
  },
  {
    name: "clt_website",
    type: "url",
    label: "Website",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_website,
  },
  // Contact Information Start
  // Social Media Start
  {
    name: "clt_twitter",
    type: "input",
    label: "Twitter",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_twitter,
  },
  {
    name: "clt_linkedin",
    type: "input",
    label: "LinkedIn",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_linkedin,
  },
  {
    name: "clt_facebook",
    type: "input",
    label: "Facebook",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_facebook,
  },
  // Social Media Stop

  // Email Setting and Addresses Start
  {
    name: "clt_email_1",
    type: "input",
    label: "Email 1",
    req: true,
    md: "4",
    sm: "12",
    value: company.clt_email_1,
  },
  {
    name: "clt_email_2",
    type: "input",
    label: "Email 2",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_email_2,
  },
  {
    name: "clt_email_3",
    type: "input",
    label: "Email 3",
    req: false,
    md: "4",
    sm: "12",
    value: company.clt_email_3,
  },
  // Email Setting and Addresses Stop
];

export const validationSchema = Yup.object().shape({
  clt_company_name: Yup.string().required("Company Name Field is Required"),
  clt_company_number: Yup.string()
    .required("Company Number is required")
    .min(11, "Company Number must be at least 11 characters")
    .max(11, "Company Number must not exceed 11 characters")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Number"
    ),
  clt_company_address: Yup.string()
    .required("Company Address is required")
    .min(6, "Company Address must be at least 6 characters")
    .max(40, "Company Address must not exceed 40 characters"),
  clt_company_authorised: Yup.string()
    .required("Authorised is required")
    .min(6, "Authorised must be at least 3 characters")
    .max(40, "Authorised must not exceed 40 characters"),
  clt_company_vat_number: Yup.string()
    .required("VAT number is required")
    .min(6, "VAT number must be at least 3 characters")
    .max(40, "VAT number must not exceed 40 characters"),
  clt_company_contact_person: Yup.string()
    .required("Contact Person field is required")
    .min(6, "Contact Person field must be at least 6 characters")
    .max(40, "Contact Person field must not exceed 40 characters"),
  clt_company_tel_number: Yup.string()
    .required("Telephone Number field is required")
    .min(11, "Telephone Number field must be at least 6 characters")
    .max(11, "Telephone Number field must not exceed 40 characters")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Telephone Number"
    ),
  clt_company_mobile_number: Yup.string()
    .required("Mobile Number field is required")
    .min(11, "Mobile Number field must be at least 6 characters")
    .max(11, "Mobile Number field must not exceed 40 characters")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Mobile Number"
    ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
