import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams, useHistory, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInputFields, validationSchema } from "./FormBranchHelper";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import LoadFormFields from "../../Layout/LoadFormFields";
import Input from "../../FormElements/Input";
import AddressFields from "./AddressFields";
import { Button } from "react-bootstrap";

const Branch = (props) => {
  const [branchData, setFormdata] = useState({});
  const [formdata, setFormData] = useState({});
  const { loader, userDetails, branch } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFormdata(branch);
    if (formInputFields(branch).length > 0)
      formInputFields().map((field, key) => {
        if (branch[field.name]) setValue(field.name, branch[field.name]);
      });
  }, [branch, params.id]);

  const onSubmit = (formdata) => {
    let formData = formdata;
    formData["users_access_token"] = userDetails.users_access_token;
    if (branch.cnd_branch_id) formData["cnd_branch_id"] = branch.branch;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "SaveBranch",
        "",
        getDataOnRequest(
          userDetails.users_access_token,
          "GetBranches",
          "SET_BRANCHES"
        )
      )
    );
    // history.push("/branch");
  };

  const handleSend = (e) => {
    e.preventDefault();
    console.log("Demo Value :=>" + e.target[1].value);
    if (e.target[0].value) {
      formdata["clt_branch_name"] = e.target[0].value;
    }
    if (e.target[1].value) {
      formdata["clt_branch_location"] = e.target[1].value;
    }
    if (e.target[2].value) {
      formdata["clt_branch_country"] = e.target[2].value;
    }
    if (e.target[3].value) {
      formdata["clt_branch_address1"] = e.target[3].value;
    }
    if (e.target[4].value) {
      formdata["clt_branch_address2"] = e.target[4].value;
    }
    if (e.target[5].value) {
      formdata["clt_branch_town"] = e.target[5].value;
    }
    if (e.target[6].value) {
      formdata["clt_branch_region"] = e.target[6].value;
    }
    if (e.target[7].value) {
      formdata["clt_branch_postal"] = e.target[7].value;
    }

    if (
      e.target[0].value &&
      e.target[1].value &&
      e.target[2].value &&
      e.target[3].value &&
      e.target[4].value &&
      e.target[5].value &&
      e.target[6].value &&
      e.target[7].value
    ) {
      onSubmit(formdata);
    }
  };

  const onChangeInputvalue = (e) => {
    // const { name, value } = e.target;
    // let branchDetail = {...branchData}
    // branchDetail[name] = value;
    // setFormdata(branchDetail);
  };

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">Branch</h5>
        </div>

        <form onSubmit={handleSend}>
          {/* <LoadFormFields
            register={register}
            errors={errors}
            formInputFields={formInputFields()}
            onChangeInputvalue={(e) => onChangeInputvalue(e)}
            reset={reset}
            data={branch}
            backUrl={"/branch"}
          /> */}
          <div className="row m-b30">
            <AddressFields
              errors={errors}
              data={branch}
              onChange={(e) => onChangeInputvalue(e)}
            />
          </div>
          <Button
            disabled={loader}
            className="site-button m-b30 float-right"
            type="submit"
          >
            Save
          </Button>
          <Link
            to={"/branch"}
            disabled={loader}
            className="site-button yellow m-b30 mr-2 float-right"
            type="submit"
          >
            Back
          </Link>
        </form>
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    branch: state.branch.branch,
  };
};
export default connect(mapStateToProps)(Branch);
