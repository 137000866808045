const initialState = {dailylog: []}

export const dailylog = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_DAILY_LOG':
      return {dailylog: action.payload}; 
  }
  return state
}


