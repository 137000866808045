import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Secondary-banner.jpg";
// import HomecareImg from "./../images/ourservices/Secondary.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/education/edu-secondary/our-services-edu-secondary.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/education/edu-secondary/our-services-edu-secondary-01.jpg";
// import Footer from '../components/Layout/Footer';
import bnr3 from "./../images/lines.png";

export default function Secondary() {
  // Add title
  document.getElementById("titleText").innerText =
    "Staffing Connect - Our Services - Secondary";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{
            backgroundImage: `url(${Background})`,
            height: 580,
            marginTop:
              window.location.pathname == "/mobile/secondary" ? "0rem" : "",
          }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">
                <b style={{ display: "none" }}>
                  Staffing Connect - Our Services -{" "}
                </b>
                Secondary
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/secondary" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Education</li>
                  <li>Secondary</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Secondary </h2>
                  {/* <p>Why, hello there! Want to see more of our work? Check out just some of what we've been up to below.</p> */}
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                  <br />
                </div>
                <div className="col-md-12 col-lg-6 m-b20">
                  {/* <h2 className="m-b5"> Home Care</h2> */}
                  <p className="spacebar mt-3"></p>

                  <h6>
                    Reliable Staffing Solutions for Educational Institutions:
                    Beyond Teachers, We've Got You Covered
                  </h6>

                  <p className="text-left">
                    Staffing Connect is a comprehensive recruitment solution for
                    secondary schools, academies, PRUs, colleges, and
                    universities that require reliable staffing support. We
                    cater to temporary and permanent staffing needs, and our
                    services extend beyond mere teacher supply.
                  </p>
                  <p className="text-left" style={{ marginBottom: "-5%" }}>
                    Our rich experience of two decades has enabled us to curate
                    a diverse database of professionals, ranging from English
                    and Math teachers to PE instructors, science educators,
                    history lecturers, and design and technology tutors. Our
                    strength lies in our ability to provide staff for every
                    department, ensuring a seamless continuation of services in
                    your institution.
                  </p>
                </div>
                <p className="text-left mt-3">
                  But we don't just stop at the teaching staff. Our offerings
                  encompass office, technical support staff, and teaching
                  assistants, making us a holistic solution for your staffing
                  requirements. We understand the importance of consistency in
                  service and have honed our processes to ensure just that.
                </p>

                <p className="text-left">
                  At Staffing Connect, safeguarding children and young people is
                  the core of our commitments. We are stringent about our
                  responsibilities and enforce thorough checks and verifications
                  for all registered staff. This includes administrators, exam
                  invigilators, teaching assistants, and office staff, who are
                  all subject to rigorous interviewing, referencing checks, and
                  are either in possession of or prepared to undergo, an
                  Enhanced DBS check registered on the Update Service. This
                  diligent practice also extends to our supply teachers,
                  reflecting our uncompromised commitment to safety and quality.
                </p>
              </div>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
