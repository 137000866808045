const initialState = {rightToWorks: []}

export const rightToWorks = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_RIGHT_TO_WORKS':
      return {rightToWorks: action.payload}; 
  }
  return state
}


