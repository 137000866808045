import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import Error from "../../Layout/Error";
import { agreementRehabitArray } from "../../../helpers/AgreementContent";
import { updateUserProfile } from "../../../redux/actions/UserProfileActions";
import { RehebitationDataValidations } from "../../../helpers/RehebitationDataValidations";
import { setError } from "../../../redux/actions/ErrorActions";
const RehabitationDeclaration = (props) => {
  const { errors } = props;
  const [formData, setFormdata] = useState({});
  const { userData, loader } = props;
  const dispatch = useDispatch();

  const agreeExtraCondition = (e, field) => {
    formData[field] = e.target.value;
    setFormdata(formData);
  };

  const validationErrors = () => {
    let validations = RehebitationDataValidations(formData);
    if (validations.error == true) {
      dispatch(setError(validations.stateData));
      return true;
    } else {
      return false;
    }
  };
  const updateAgreement = (e) => {
    e.preventDefault();
    if (!validationErrors()) {
      document.getElementById("loader").style.display = "block";
      formData["users_access_token"] = props.userAuth.users_access_token;
      dispatch(updateUserProfile(formData, "updateRehabitationData"));
    }
  };

  const checkIsAgreed = (field) => {
    if (typeof props.userData[field] != "undefined") {
      return props.userData[field] == "1";
    }
  };

  const checkForRadioButton = (field, value) => {
    if (userData[field] && userData[field] == value) {
      return true;
    }
  };

  useEffect(() => {
    // window.scrollTo(0,0);
    const formdata = {
      cnd_rehabilitation_checkbox_eight:
        userData.cnd_rehabilitation_checkbox_eight,
      cnd_rehabilitation_checkbox_five:
        userData.cnd_rehabilitation_checkbox_five,
      cnd_rehabilitation_checkbox_four:
        userData.cnd_rehabilitation_checkbox_four,
      cnd_rehabilitation_checkbox_one: userData.cnd_rehabilitation_checkbox_one,
      cnd_rehabilitation_checkbox_seven:
        userData.cnd_rehabilitation_checkbox_seven,
      cnd_rehabilitation_checkbox_six: userData.cnd_rehabilitation_checkbox_six,
      cnd_rehabilitation_checkbox_three:
        userData.cnd_rehabilitation_checkbox_three,
      cnd_rehabilitation_checkbox_two: userData.cnd_rehabilitation_checkbox_two,
    };
    setFormdata(formdata);
  }, [userData]);

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <form className="was-validated" noValidate id="scrollFocusElement">
        <div className="job-bx submit-resume">
          {agreementRehabitArray.map((value, key) => (
            <div key={key} className="col-lg-12 col-md-12">
              <div className="form-group">
                <label>{value.upperText}</label>
                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                  <div className="custom-control custom-radio">
                    <input
                      defaultChecked={checkForRadioButton(value.field, 1)}
                      type="radio"
                      className="custom-control-input"
                      id={`yes-${value.field}`}
                      value="1"
                      onChange={(e) => agreeExtraCondition(e, value.field)}
                      name={value.field}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`yes-${value.field}`}
                      style={{ lineHeight: "1.5rem" }}
                    >
                      Yes
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                  <div className="custom-control custom-radio">
                    <input
                      defaultChecked={checkForRadioButton(value.field, 0)}
                      type="radio"
                      className="custom-control-input"
                      id={`no-${value.field}`}
                      value="0"
                      onChange={(e) => agreeExtraCondition(e, value.field)}
                      name={value.field}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`no-${value.field}`}
                      style={{ lineHeight: "1.5rem" }}
                    >
                      No
                    </label>
                  </div>
                </div>
                <Error errors={errors} fieldName={value.field} />
                <label>{value.lowerText}</label>
                <textarea
                  name=""
                  type="text"
                  rows={30}
                  cols={5}
                  defaultValue={props.userData[value.textField]}
                  onChange={(e) => agreeExtraCondition(e, value.textField)}
                  placeholder=""
                  className="form-control"
                ></textarea>
                <Error errors={errors} fieldName={value.textField} />
              </div>
            </div>
          ))}
          <Button
            disabled={loader}
            className="site-button m-b30 pull-right"
            onClick={(e) => updateAgreement(e)}
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader ? "Save & Continue" : "Loading..."}
          </Button>
          <br />
          <br />
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};
export default connect(mapStateToProps)(RehabitationDeclaration);
