import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Dropdown from "../../FormElements/Dropdown";
import Input from "../../FormElements/Input";
import Error from "../../Layout/Error";
const PersonalInfoFields = (props) => {
  const [userdata, setFormdata] = useState({});
  const { errors } = props;
  useEffect(() => {
    setFormdata(props.data);
  }, [props.data]);

  return (
    <React.Fragment>
      <Input
        type="text"
        label="First Name"
        placeholder={"Eg., John"}
        changeHandler={(e) => props.onChange(e)}
        name="users_firstname"
        id={"users_firstname"}
        disabled={userdata.users_firstname ? true : false}
        value={userdata.users_firstname}
        errors={errors}
      />
      <Input
        type="text"
        label="Last Name"
        placeholder={"Eg., Doe"}
        changeHandler={(e) => props.onChange(e)}
        name="users_lastname"
        id={"users_lastname"}
        disabled={userdata.users_lastname ? true : false}
        value={userdata.users_lastname}
        errors={errors}
      />
      <Input
        type="email"
        label="Email"
        placeholder={"Eg. johndoe@example.com"}
        disabled={"disabled"}
        changeHandler={(e) => props.onChange(e)}
        name="users_email"
        id={"users_email"}
        value={userdata.users_email}
        errors={errors}
      />
      <Dropdown
        name="cnd_gender"
        id={"cnd_gender"}
        value={userdata.cnd_gender}
        label="Select Gender"
        options={[
          {
            name: "Select Gender",
            value: "",
          },
          {
            name: "Male",
            value: "male",
          },
          {
            name: "Female",
            value: "female",
          },
          {
            name: "Other",
            value: "other",
          },
        ]}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
      />
      <Input
        type="date"
        label="Date of Birth"
        placeholder={"Eg., 01/01/1990"}
        name="cnd_birth_date"
        id={"cnd_birth_date"}
        min={
          new Date(new Date().setFullYear(new Date().getFullYear() - 73))
            .toISOString()
            .split("T")[0]
        }
        max={
          new Date(new Date().setFullYear(new Date().getFullYear() - 15))
            .toISOString()
            .split("T")[0]
        }
        defaultValue={userdata.cnd_birth_date}
        value={userdata.cnd_birth_date}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
      />
      <Input
        type="text"
        label="Phone Number"
        placeholder={"Eg., 01234567890"}
        changeHandler={(e) => props.onChange(e)}
        disabled={userdata.users_phonenumber ? true : false}
        name="users_phonenumber"
        id={"users_phonenumber"}
        value={userdata.users_phonenumber}
        errors={errors}
      />
      <Input
        type="text"
        label="Home Telephone"
        placeholder={"Eg., 012345678"}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_home_mobile_number"
        id={"cnd_home_mobile_number"}
        value={userdata.cnd_home_mobile_number}
        errors={errors}
        req={false}
      />
      <Input
        type="text"
        label="Next of Kin First Name"
        placeholder={"Eg., Jane"}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_kin_firstname"
        id={"cnd_kin_firstname"}
        value={userdata.cnd_kin_firstname}
        errors={errors}
      />
      <Input
        type="text"
        label="Next of Kin Last Name"
        placeholder={"Eg., Doe"}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_kin_lastname"
        id={"cnd_kin_lastname"}
        value={userdata.cnd_kin_lastname}
        errors={errors}
      />
      <Input
        type="text"
        label="Next of Kin Phone Number"
        placeholder={"Eg., 01234567890"}
        changeHandler={(e) => props.onChange(e)}
        id={"cnd_kin_phonenumber"}
        name="cnd_kin_phonenumber"
        value={userdata.cnd_kin_phonenumber}
        errors={errors}
      />
    </React.Fragment>
  );
};

export default PersonalInfoFields;
