import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, Route } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import { Modal } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../services/StringOperation";
import GlobalViewFileModal from "../../services/GlobalViewFileModal";
import { redirectUrl } from "../../services/GlobalUrls";
import { checkIfFileIsaDocument } from "../../services/FileOperationGlobalFunctions";
import Swal from "sweetalert2";
import { getInitialsLettersOfString } from "../../services/GlobalStringOp";
import SwipeableTemporaryDrawer from "../../services/DrawerMenus";
import IconMenu from "../../services/ClickMenus";
const Profilesidebar = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [myProfileMenu, setMyProfileMenu] = useState(false);
  const { progress, userData, dbsDocuments } = props;
  // Modal States - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // Modal State - End
  let usersCategory = localStorage.getItem("users_category");
  const [formStates, setFormStates] = useState({
    userProfile: false,
    employmentReferences: false,
    typesOfServices: false,
    agreements: false,
    bankDetails: false,
    rightToWork: false,
    workPreferences: false,
    documentsCompliance: false,
    dbsCertificate: false,
    identityDocument: false,
  });
  const [progresStatus, setProgress] = useState(0);
  useEffect(() => {
    setFormStates({
      userProfile: progress.progress_bar_profile_1 ? true : false,
      employmentReferences: progress.progress_bar_reference_2 ? true : false,
      typesOfServices: userData.cnd_types_of_services !== "" ? true : false,
      agreements: progress.progress_bar_agreement_3 ? true : false,
      bankDetails: progress.progress_bar_bank_4 ? true : false,
      rightToWork: progress.progress_bar_right_to_work_5 ? true : false,
      workPreferences: progress.progress_bar_work_preference_6 ? true : false,
      documentsCompliance: progress.progress_bar_compliance_7 ? true : false,
      dbsCertificate: progress.progress_bar_dbs_8 ? true : false,
      identityDocument: progress.progress_bar_identity_9 ? true : false,
    });
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 10;
    }

    if (progress.progress_bar_reference_2 == "1") {
      totalProgress = totalProgress + 10;
    }
    // if(progress.cnd_types_of_services !== ""){
    //   totalProgress = totalProgress+10
    // }
    if (progress.progress_bar_agreement_3 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_bank_4 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_right_to_work_5 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_work_preference_6 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_compliance_7 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_dbs_8 == "1") {
      totalProgress = totalProgress + 15;
    }
    if (progress.progress_bar_identity_9 == "1") {
      totalProgress = totalProgress + 15;
    }

    setProgress(totalProgress);
  }, [progress, userData]);
  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    users_category,
    users_access_token,
    users_profile_pic,
    users_profile_img_path,
    user_sign_signature,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "135px";
      imageRef.current.style.height = "135px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };
  // Modal Function - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };
  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // Modal Function - End
  
  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
    // Close Menu - Start
    const handleClick = () => {
      if(myProfileMenu == true){
        setMyProfileMenu(false);
      }
    };

    // Add the event listener for mouse clicks
    window.addEventListener('click', handleClick);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('click', handleClick);
    };
    // Close Menu - End
  }, [props.userData, myProfileMenu]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };

  const handleLink = (e, link) => {
    setShow(true);
  };
  const handleClose = () => setShow(false);
  // sticky-top

  const[menuList, setMenuList] = useState([
    {
      name: "My Profile",
      icon: "fa fa-user-circle-o",
      url: "/job-profile",
      divider: false
    },
    {
      name: "Work Preference",
      icon: "fa fa-object-ungroup",
      url: "/work-prefrences",
      divider: false
    },
    {
      name: "Employment and professional reference",
      icon: "fa fa-briefcase",
      url: "/employment-and-professional-references",
      divider: false
    },
    {
      name: "Bank Details",
      icon: "fa fa-file-text-o",
      url: "/bank-details",
      divider: false
    },
    {
      name: "Right To Work",
      icon: "fa fa-check-circle-o",
      url: "/right-to-work",
      divider: false
    },
    {
      name: "Documents Required",
      icon: "fa fa-id-card-o",
      url: "/document-required",
      divider: false
    },
    {
      name: "DBS Certificate",
      icon: "fa fa-list-alt",
      url: "/dbs-document",
      divider: false
    },
    {
      name: "Identity Documents",
      icon: "fa fa-id-badge",
      url: "/identity-document",
      divider: false
    },
    {
      name: "Agreements",
      icon: "fa fa-print",
      url: "/agreements",
      divider: false
    },
    {
      name: "Sign Contract",
      icon: "fa fa-pencil-square-o",
      url: "/sign-contract",
      divider: false
    }
  ]);
  return (
    <>
      <div className="profile-modal">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Coming Soon!</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body></Modal.Body> */}
        </Modal>
      </div>
      <div className="col-xl-3 col-lg-4 m-b30">
        <div className="wrapper">
          <div className="candidate-info">
            <div className="candidate-detail text-center">
              <div
                className={
                  "canditate-des " +
                  (users_profile_img_path && users_profile_pic ? "" : "bg-dark")
                }
              >
                <CircularProgressbarWithChildren
                  className="CircularProgressbar-background"
                  value={progresStatus}
                >
                  {users_profile_img_path && users_profile_pic ? (
                    <img
                      className="size-thumbnail1 rounded-circle cursor-pointer"
                      width={"135px"}
                      height={"135px"}
                      ref={imageRef}
                      onClick={() => {
                        if (users_profile_img_path && users_profile_pic) {
                          viewFileModal(
                            constants.IMAGE_URL +
                              users_profile_img_path +
                              users_profile_pic
                          );
                        } else {
                          Swal.fire({
                            title: "Profile Picture Not Available",
                            text: "",
                            icon: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                            // confirmButtonColor: 'blue'
                          });
                        }
                      }}
                      src={require("./../../images/team/pic1.jpg")}
                    />
                  ) : (
                    <div className="mt-4 text-white">
                      <h1 style={{ fontSize: "60px" }}>
                        {getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        ) &&
                        getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        ) != "UU" ? (
                          getInitialsLettersOfString(
                            users_firstname + " " + users_lastname
                          )
                        ) : (
                          <i
                            class="fa fa-spinner text-center fa-pulse text-white"
                            style={{ fontSize: "80px" }}
                          ></i>
                        )}
                      </h1>
                    </div>
                  )}
                </CircularProgressbarWithChildren>
                <div style={{ fontSize: 18, marginTop: 5 }}>
                  <strong>{progresStatus}%</strong> Completed
                </div>
                <div
                  className="upload-link border"
                  title="Upload Image"
                  data-toggle="tooltip"
                  data-placement="right"
                  style={{ marginTop: "65%" }}
                >
                  <input
                    type="file"
                    className="update-flie"
                    id="users_profile_pic"
                    // onChange={(e) => onChangeInputvalue(e)}
                    accept=".png,.jpeg,.jpg"
                  />
                  <i className="fa fa-camera"></i>
                </div>
              </div>

              <div className="candidate-title">
                <div className="">
                  <h4 className="m-b5">
                    <b>{users_firstname}</b>
                  </h4>
                  <p className="m-b0">
                    <b>
                      {props.userAuth.users_category
                        ? capitalizeFirstLetter(
                            String(props.userAuth.users_category)
                          )
                        : ""}
                    </b>
                  </p>
                </div>
              </div>
            </div>
            <ul>
              <li>
                <a
                  href={"/dashboard"}
                  className={`${
                    window.location.pathname == "/dashboard" && "active"
                  }`}
                >
                  <i className="fa  fa-bar-chart" aria-hidden="true"></i>
                  <span className="text-md">
                    <b>Dashboard</b>
                  </span>
                </a>
              </li>
              <li className="cursor-pointer">
                <a
                  // href={"/job-profile"}
                  onClick={(event) => {
                    setMyProfileMenu(true);
                  }}
                  className={`${
                    window.location.pathname == "/profile" && "active"
                  } text-dark`}
                >
                  <i className="fa fa-user-o" aria-hidden="true"></i>
                  {myProfileMenu == true ? (
                    <i class="fa fa-chevron-right pull-right mt-1" style={{fontSize: "80%", fontWeight:"normal", opacity:"0.5"}} aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-chevron-down pull-right mt-1" style={{fontSize: "80%", fontWeight:"normal", opacity:"0.5"}} aria-hidden="true"></i>
                  )}
                  <span>My Profile</span>
                </a>
              </li>

              <li>
                <a
                  href={"/timesheet"}
                  className={`${
                    window.location.pathname == "/timesheet"
                      ? "active"
                      : window.location.pathname.includes("create-timesheet") ==
                          true && "active"
                  }`}
                >
                  <i className="fa fa-calendar"></i>
                  <span> Submit Timesheet</span>
                </a>
              </li>
              {/* Holiday Hided */}
              {/* <li>
              <a
                href={"/holiday"}
                className={`${window.location.pathname == "/holiday"? "active" : window.location.pathname.includes('create-holiday') == true && "active"}`}
              >
                <i className="fa fa-calendar-times-o" ></i>
                <span>Apply for Holiday</span>
                
              </a>
            </li> */}
              <li>
                <a
                  href={"/availability"}
                  className={`${
                    window.location.pathname == "/availability" && "active"
                  }`}
                >
                  <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                  <span>My Availability</span>
                </a>
              </li>
              {usersCategory == "home care" && (
                <li>
                  <a
                    href={"/daily-logs"}
                    className={`${
                      (window.location.pathname == "/daily-logs" ||
                        window.location.pathname.includes(
                          "create-daily-logs"
                        )) &&
                      "active"
                    }`}
                  >
                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                    <span>Daily Logs</span>
                  </a>
                </li>
              )}
              <li onClick={handleLink}>
                <a
                  href={"#"}
                  className={`${
                    window.location.pathname == "//payroll" && "active"
                  }`}
                >
                  <i className="fa fa-address-card-o" aria-hidden="true"></i>
                  <span>My Payroll</span>
                </a>
              </li>
              <li>
                <a
                  href={"/my-assignments"}
                  className={`${
                    window.location.pathname == "/my-assignments" && "active"
                  }`}
                >
                  <i className="fa fa-sticky-note-o" aria-hidden="true"></i>
                  <span>My Assignments</span>
                </a>
              </li>

              <li onClick={() => redirectUrl('/jobs-applied')}>
                <a
                  href={"#"}
                  className={`${
                    window.location.pathname == "/jobs-applied" && "active"
                  }`}
                >
                  <i className="fa fa-briefcase" aria-hidden="true"></i>
                  <span>Jobs Applied</span>
                </a>
              </li>
              <li onClick={handleLink}>
                <a
                  href={"#"}
                  className={`${
                    window.location.pathname == "/jobs-available" && "active"
                  }`}
                >
                  <i className="fa fa-bullhorn" aria-hidden="true"></i>
                  <span>Jobs Available</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <SwipeableTemporaryDrawer
          drawerDirection={"right"}
          drawerState={false}
        /> */}
        <IconMenu 
          menuList={menuList}
          menuListState={myProfileMenu}
          marginXValue={"260px"}
          marginYValue={"-440px"}
        />
        <GlobalViewFileModal
          modalOption={modalOption}
          closeFileModal={closeFileModal}
          fileUrl={fileUrl}
          loaderEnd={loaderEnd}
          modalHeight={modalHeight}
          callModal={callModal}
          title={"Profile Picture"}
          // fileName={fileName}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(Profilesidebar);
