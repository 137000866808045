const initialState = {billingInfo: []}

export const billingInfo = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_BILLING_DATA':
      return {userData: action.payload}; 
  }
  return state
}


