import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const DBSDocumentContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token && props.dbsdocuments.length == 0)
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetDBSDocuments','SET_DBS_DOCUMENT'))
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    dbsdocuments:state.dbsdocuments.dbsdocuments,
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData
  };
};

const composedDBSDocumentContainer = compose(connect(mapStateToProps), DBSDocumentContainer)
export default composedDBSDocumentContainer;

