const initialState = {workPreferenceQualificationDocuments: {}}

export const workPreferenceQualificationDocuments = (state = initialState, action) => {
	switch (action.type) {
  	case 'GET_WORK_PREFRENCES_QUALIFICATION_DOCUMENTS':
      return {workPreferenceQualificationDocuments: action.payload}; 
  }
  return state
}


