import React, { useEffect, useState } from "react";
import PageTitle from "../components/Layout/PageTitle";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { allJobs } from "./Data";
import { checkUserStatus } from "./checkUserStatus";
import { goBackPage, redirectUrl } from "./GlobalUrls";
import {
  jobContractType,
  jobsEmploymentHours,
  jobsSalarytype,
} from "./JobTypeAndSectorGlobalList";
import logo2 from "../images/logo.png";
import {
  capitalizeFirstLetter,
  convertToOriginalForm,
  convertToSnakeCase,
} from "./StringOperation";
import {
  getDataOnRequest,
  updateDataOnRequest,
} from "../redux/actions/CommonActions";
import { useDispatch } from "react-redux";
import composedJobsContainer from "../containers/JobsContainer";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, Modal } from "react-bootstrap";
import { checkIfFileIsaDocument } from "./FileOperationGlobalFunctions";
import GlobalViewFileModal from "./GlobalViewFileModal";
import constants from "../constants";
import { Input } from "@mui/material";
import { getBase64 } from "../helpers/FileToBase64";
import { updateUserProfile } from "../redux/actions/UserProfileActions";
import { santetizeData } from "../helpers/FormFieldsSanetizations";
import Swal from "sweetalert2";
var bnr2 = require("./../images/banner/bnr2.jpg");

function JobDetails(props) {
  //   const permanentJobs = allJobs.filter((j) => j.jobType === "permanent");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  // const { id } = useParams();
  const [skillSetData, setSkillSetData] = useState([]);
  const [coverLetterPreference, setCoverLetterPreference] = useState("");
  const [showCover, setShowCover] = useState(false);
  const [screeningQuestions, setScreeningQuestions] = useState([]);
  const [individaulJobDetail, setIndividaulJobDetails] = useState({});
  const [showCV, setShowCV] = useState(false);
  const [updateCVModal, setUpdateCVModal] = useState(false);
  const [uploadFileElement, setUploadFileElement] = useState("");
  const [jobsApplyType, setjobsApplyType] = useState("draft");
  const [checkValidation, setCheckValidation] = useState("true");
  const [userDetails, setUserDetails] = useState({});
  const [totalProgressBar, setTotalProgressBar] = useState(0);
  let countSimilarJobs = 0;
  const location = useLocation();
  // URL Params - Start
  // const searchParams = new URLSearchParams(location.search);
  // const source = searchParams.get("source");
  // const filterJobs = searchParams.get("filterjobs");
  // const filterJobTitle = searchParams.get("jobtitle");
  // const filterJobSector = searchParams.get("jobsector");
  // const filterJobLocation = searchParams.get("location");
  // URL Params - End
  const updateCVModalOperation = (callParam) => {
    setUpdateCVModal(callParam);
  };
  // View File Operation - Start
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // View File Operation - End

  const history = useHistory();
  const jobDetails = location.state.jobDetails ? location.state.jobDetails : {};
  const searchBarDetails = location.state.searchBarDetails
    ? location.state.searchBarDetails
    : {};
  const allJobsRecordsData = location.state.allJobsRecordsData
    ? location.state.allJobsRecordsData
    : {};
  const htmlContent = `${jobDetails.jobs_description}`;

  const sanitizedHtmlContent = htmlContent
    .replace(/\s+/g, " ")
    .replace(/;/g, "");

  let userType = localStorage.getItem("user_job_type");
  let cndStatus = localStorage.getItem("permanentUserStatus");
  let jobUrl = "";
  if (userType) {
    // if (userType == "permanentrequirement") {
    if (cndStatus) {
      jobUrl = "/apply-more-jobs";
    } else {
      jobUrl = "/permanent-job-profile";
    }
    // } else {
    //   jobUrl = "/dashboard";
    // }
  } else {
    jobUrl = "/login";
  }

  useEffect(() => {
    if (localStorage.getItem("applyJobDetails")) {
      localStorage.setItem("applyJobDetails", "");
      window.scrollTo(0, 1800);
    } else {
      // window.scrollTo(0, 0);
    }
    // Skills Set
    let skillsArray = [];
    if (jobDetails.jobs_required_skills) {
      skillsArray = jobDetails.jobs_required_skills.split(",");
      setSkillSetData(skillsArray);
    } else {
      setSkillSetData([]);
    }
    if (props.userAuth.users_access_token) {
      if (jobDetails.jobs_id) {
        if (props.userAuth.users_access_token) {
          dispatch(
            getDataOnRequest(
              jobDetails.jobs_id + "/" + props.userAuth.users_access_token,
              "GetJobsScreeningQuestions",
              "SET_JOBS_SCREENING_QUESTIONS"
            )
          );
        } else {
          dispatch(
            getDataOnRequest(
              jobDetails.jobs_id,
              "GetJobsScreeningQuestions",
              "SET_JOBS_SCREENING_QUESTIONS"
            )
          );
        }

        dispatch(
          getDataOnRequest(
            jobDetails.jobs_id + "/" + props.userAuth.users_access_token,
            "GetIndividualJobsDetails",
            "SET_INDIVIDUAL_JOB_DETAILS"
          )
        );
      }
    }
    // Set User Details - Start
    if (props.userInfo) {
      setUserDetails(props.userInfo);
    }
    // Set User Details - End

    // Set Work and Quallification Details - Start
    if (
      props.userAuth.users_access_token &&
      Object.keys(props.workAndQualificationDetails).length == 0
    ) {
      dispatch(
        getDataOnRequest(
          props.userAuth.users_access_token,
          "SetWorkAndQualification",
          "SET_WORK_AND_QUALIFICATION_DETAILS"
        )
      );
    }

    // Set Work and Quallification Details - End
  }, [
    props.userAuth.users_access_token,
    props.userInfo,
    props.workAndQualificationDetails,
  ]);

  // Set Progress Bar - Start
  useEffect(() => {
    let totalProgress = 0;
    // Profile Picture - Start
    if (props.userInfo.users_profile_pic) {
      totalProgress = totalProgress + 15;
    }
    // Profile Picture - End
    // CV - Start
    if (props.userInfo.cnd_upload_cv) {
      totalProgress = totalProgress + 15;
    }
    // CV - End

    // Looking for Start
    if (
      props.userInfo.cnd_desired_job_title &&
      props.userInfo.cnd_job_salary_amount &&
      props.userInfo.cnd_job_salary_type
    ) {
      totalProgress = totalProgress + 10;
    }
    // Looking for End

    // Identity video Start
    // if (props.userInfo.cnd_upload_identity_video) {
    //   totalProgress = totalProgress + 10;
    // }
    // Identity video End
    // Status & Availability - Saart
    // if (cnd_job_employment_status && cnd_job_notice_period) {
    //   totalProgress = totalProgress + 10;
    // }
    // Status & Availability - End

    // Work Experience - Start
    if (
      props.workAndQualificationDetails.employementReference &&
      props.workAndQualificationDetails.employementReference.length > 0
    ) {
      totalProgress = totalProgress + 30;
    }
    // Work Experience - End

    // Qualification - Start
    if (
      props.workAndQualificationDetails.educationReference &&
      props.workAndQualificationDetails.educationReference.length > 0
    ) {
      totalProgress = totalProgress + 30;
    }
    // Qualification - End

    setTotalProgressBar(totalProgress);
  }, [props.userInfo, props.workAndQualificationDetails]);
  // Set Progress Bar - End

  useEffect(() => {
    const screeningQuestion = Object.values(props.jobsScreeningQuestions);
    if (screeningQuestion) {
      setScreeningQuestions(screeningQuestion);
    }

    const individualJobDetails = props.individualJobsDetails;

    if (individualJobDetails) {
      setIndividaulJobDetails(individualJobDetails);
    }
  }, [
    props.jobsScreeningQuestions,
    props.userAuth,
    props.userInfo,
    props.individualJobsDetails,
  ]);

  const changeSkill = (skills) => {
    let skillsArray = [];
    if (skills) {
      skillsArray = skills.split(",");
      setSkillSetData(skillsArray);
    } else {
      setSkillSetData([]);
    }
  };

  const changeCoverLetterPreferences = (coverLetter) => {
    if (coverLetter) {
      let coverType = convertToSnakeCase(coverLetter);
      setCoverLetterPreference(convertToSnakeCase(coverLetter));
      if (coverType == "optional" || coverType == "required") {
        setShowCover(true);
      } else {
        setShowCover(false);
      }
    } else {
      setShowCover(false);
      setCoverLetterPreference("");
    }
  };

  const changeQuestions = (questionsJobsId) => {
    if (questionsJobsId) {
      setShowCover(false);
      setFormData([]);
      setScreeningQuestions([]);

      if (props.userAuth.users_access_token) {
        dispatch(
          getDataOnRequest(
            questionsJobsId + "/" + props.userAuth.users_access_token,
            "GetJobsScreeningQuestions",
            "SET_JOBS_SCREENING_QUESTIONS"
          )
        );
      } else {
        dispatch(
          getDataOnRequest(
            questionsJobsId,
            "GetJobsScreeningQuestions",
            "SET_JOBS_SCREENING_QUESTIONS"
          )
        );
      }

      dispatch(
        getDataOnRequest(
          questionsJobsId + "/" + props.userAuth.users_access_token,
          "GetIndividualJobsDetails",
          "SET_INDIVIDUAL_JOB_DETAILS"
        )
      );
    } else {
      setShowCover(false);
      setFormData([]);
      setScreeningQuestions(props.jobsScreeningQuestions);
    }
  };

  const goBackPage = () => {
    history.push({
      pathname: "/job-board",
      state: {
        category: searchBarDetails.category,
        area: searchBarDetails.area,
        title: searchBarDetails.title,
        isHomePage:
          searchBarDetails.category != "" ||
          searchBarDetails.area != "" ||
          searchBarDetails.title != ""
            ? true
            : false,
      },
    });
    // redirectUrl("/job-board?"+(title || area || category ? "source=searchresult&&" : "")+((title ? "jobtitle="+title.toLowerCase().replace(' ','-') : "")+ (category ? (title ? "&&" : "")+"jobsector="+category.toLowerCase().replace(' ','-') : "")+(area ? (category ? "&&" : "")+"location="+area.toLowerCase().replace(' ','-') : "")));
  };

  const validForm = () => {
    let applyError = false;

    const multiIdsDiv = document.getElementById("formDiv");

    if (multiIdsDiv && checkValidation == "true") {
      const elementsInsideDiv = multiIdsDiv.querySelectorAll("[id]");

      let yesRadio, noRadio, msgRadio;

      msgRadio = document.getElementById(
        "cnd_job_application_screening_question_answer_msg"
      );
      elementsInsideDiv.forEach((element) => {
        if (element.id.endsWith("_yes")) {
          yesRadio = document.getElementById(element.id);
        }
        if (element.id.endsWith("_no")) {
          noRadio = document.getElementById(element.id);
        }

        if (
          yesRadio &&
          yesRadio.checked == false &&
          noRadio &&
          noRadio.checked == false &&
          msgRadio
        ) {
          if (msgRadio) {
            msgRadio.innerText =
              "Please answer all of the application questions!";
          }
          applyError = true;
        } else {
          if (msgRadio && applyError == false) {
            msgRadio.innerText = "";
          }
        }
      });
    }

    if (applyError == false) {
      setShowCV(true);
      changeCoverLetterPreferences(
        props.individualJobsDetails.jobs_cover_letter_preferences
      );
      setjobsApplyType("send_application");
      applyJobs();
    }

    return applyError;
  };

  const userNotLoggedIn = (accessUrl) => {
    if (accessUrl == "/login") {
      localStorage.setItem(
        "applyJobDetails",
        JSON.stringify({
          pathname: "/job-details",
          state: {
            jobDetails: jobDetails,
            searchBarDetails: searchBarDetails,
            allJobsRecordsData: allJobsRecordsData,
          },
        })
      );
    } else {
      localStorage.setItem("applyJobDetails", JSON.stringify({}));
    }

    redirectUrl(accessUrl);
  };

  const setRadioButton = (callId, callVal) => {
    let radioButton = document.getElementById(callId);
    if (radioButton) {
      if (callVal == "Yes" || callVal == "No") {
        radioButton.checked = true;
      } else {
        radioButton.checked = false;
      }
    }
  };

  const onInputValues = (e) => {
    setRadioButton(e.target.id, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const applyJobs = () => {
    // e.preventDefault();
    // console.log("target value Data  :=>"+JSON.stringify(formData));

    document.getElementById("loader").style.display = "block";
    formData["users_access_token"] = props.userAuth.users_access_token;
    formData["jobs_id"] = jobDetails.jobs_id;
    formData["jobs_title"] = jobDetails.jobs_title;
    formData["jobs_apply_type"] = jobsApplyType;
    // let formdata = {};
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData),
        "ApplyJobsDirectly",
        "",
        getDataOnRequest(
          jobDetails.jobs_id + "/" + props.userAuth.users_access_token,
          "GetIndividualJobsDetails",
          "SET_INDIVIDUAL_JOB_DETAILS"
        )
      )
    );
  };

  const resetRadio = () => {
    setShowCV(false);
    const multiIdsDiv = document.getElementById("formDiv");

    if (multiIdsDiv) {
      const elementsInsideDiv = multiIdsDiv.querySelectorAll("[id]");
      let msgRadio = document.getElementById(
        "cnd_job_application_screening_question_answer_msg"
      );

      if (msgRadio) {
        msgRadio.innerText = "";
      }

      elementsInsideDiv.forEach((element) => {
        element.checked = false;
      });
    }
  };

  const uploadCVFile = async (e) => {
    document.getElementById("loader").style.display = "block";
    let userdata = {};
    if (e.target.name === uploadFileElement) {
      if (e.target.files[0])
        userdata[uploadFileElement] = await getBase64(e.target.files[0]);
    }

    if (userdata[uploadFileElement]) {
      userdata["users_access_token"] = props.userAuth.users_access_token;
      dispatch(
        updateUserProfile(santetizeData(userdata), "UpdateJobCandidateCV")
      );
      setUploadFileElement("");
      setUpdateCVModal(false);
    }
  };

  return (
    <>
      {localStorage.getItem("applyJobDetails") && (
        <>{(window.location.href = "")}</>
      )}

      {jobDetails && (
        <>
          <div
            className="dez-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(" + bnr2 + ")" }}
          >
            <PageTitle activeName="Job Details" motherName="Home" />
          </div>

          <div className="section-full content-inner- 1">
            <div className="container">
              <div className="row mt-3 mb-2">
                <u style={{ fontWeight: "700" }}>
                  <h5>
                    <a
                      onClick={() => goBackPage()}
                      className="text-primary cursor-pointer"
                    >
                      <i className="fa fa-chevron-circle-left "></i> Search
                      Result
                    </a>
                  </h5>
                </u>
              </div>

              <div className="row mb-5">
                <div className="col-lg-4">
                  <div className="s ticky-top border border-grey p-3">
                    <div className="row">
                      <div className="col-lg-12 col-md-6">
                        <div className="m-b30">
                          <center>
                            <img src={logo2} alt="" />
                          </center>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6 mb-0">
                        <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                          <h4 className="text-black font-weight-700 p-t10 m-b15">
                            Job Details
                          </h4>
                          <ul>
                            <li>
                              <i className="ti-location-pin"></i>
                              <strong className="font-weight-700 text-black">
                                Area
                              </strong>
                              <span className="text-black-light">
                                {" "}
                                {jobDetails.jobs_location_town}{" "}
                              </span>
                            </li>
                            <li>
                              <i class="bi bi-currency-pound"></i>
                              <strong className="font-weight-700 text-black">
                                Salary
                              </strong>
                              <span className="text-black-light">
                                {jobDetails.jobs_salary_details_salary_preview.substr(
                                  7
                                )}
                              </span>
                            </li>
                            <li>
                              <i className="ti-shield"></i>
                              <strong className="font-weight-700 text-black">
                                Type Of Job
                              </strong>{" "}
                              <span className="text-black-light">
                                {" "}
                                {
                                  jobContractType[
                                    jobDetails.jobs_job_type_contract_type
                                  ]
                                }
                                ,{" "}
                                {
                                  jobsEmploymentHours[
                                    jobDetails.jobs_job_type_employment_hours
                                  ]
                                }
                                {jobDetails.jobs_location_remote_location_check ==
                                1
                                  ? ", Work from home"
                                  : ""}
                              </span>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6 mb-0">
                        <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
                          <h4 className="text-black font-weight-700 p-t10 m-b15">
                            Related Jobs
                          </h4>
                          <ul className="mb-3">
                            <>
                              {allJobsRecordsData.map((item, index) => {
                                if (
                                  item.job_sector_title ==
                                    jobDetails.job_sector_title &&
                                  item.jobs_id != jobDetails.jobs_id && item.cnd_permanent_job_status != "Applied"
                                ) {
                                  countSimilarJobs++;
                                  if (countSimilarJobs <= 5) {
                                    return (
                                      <>
                                        {/* <li> */}
                                        <div
                                          key={index}
                                          className="border border-grey p-2 mb-2"
                                          style={{
                                            color: "#9cdae3",
                                            fontSize: "15px",
                                            borderRadius: "10px",
                                          }}
                                        >
                                          <Link
                                            className="text-primary"
                                            to={{
                                              pathname: "/job-details",
                                              state: {
                                                jobDetails: item,
                                                searchBarDetails:
                                                  searchBarDetails,
                                                allJobsRecordsData:
                                                  allJobsRecordsData,
                                              },
                                            }}
                                            onClick={(event, newInputValue) => {
                                              // window.scrollTo(0, 200);
                                              changeSkill(
                                                item.jobs_required_skills
                                              );
                                              changeCoverLetterPreferences(
                                                item.jobs_cover_letter_preferences
                                              );
                                              changeQuestions(item.jobs_id);
                                              resetRadio();
                                              window.location = "";
                                            }}
                                          >
                                            {item.jobs_title ? (item.jobs_title.length <= 30 ? item.jobs_title : item.jobs_title.slice(0,30)+"...") : ""}{item.cnd_permanent_job_status == "Applied" ? (<button class="btn btn-success btn-sm p-1 ml-1" aria-hidden="true" style={{borderRadius:"10px", fontSize:"50%"}}>Applied</button>) : (item.cnd_permanent_job_status == "Draft" ? (<button class="btn btn-dark btn-sm p-1 ml-1" aria-hidden="true" style={{borderRadius:"10px", fontSize:"50%"}}>Draft</button>) : (''))}
                                          </Link>
                                          <div
                                            className="text-dark"
                                            style={{ fontSize: "80%" }}
                                          >
                                            <text className="mb-1 mr-1 bold">
                                              {item.jobs_location_town}
                                            </text>{" "}
                                            |
                                            <text className="mb-1 ml-1 bold">
                                              {item.jobs_salary_details_salary_preview
                                                ? item.jobs_salary_details_salary_preview.substr(
                                                    7
                                                  )
                                                : ""}
                                            </text>
                                          </div>
                                        </div>
                                        {/* </li> */}
                                      </>
                                    );
                                  }
                                }
                              })}
                            </>
                          </ul>
                          <strong className="text-center mb-2" style={{}}>
                            <a className="text-primary" href="/job-board">
                              View All Jobs{" "}
                              <i
                                class="fa fa-arrow-circle-right"
                                style={{ position: "static", fontSize: "90%" }}
                              ></i>
                            </a>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {"Demo"+totalProgressBar} */}
                <div className="col-lg-8">
                  <div className="job-info-box">
                    <h3 className="m-t0 mb-0 font-weight-700 title-head">
                      <h2 className="text-secondry mb-0 m-r30">
                        {jobDetails.jobs_title}
                      </h2>
                    </h3>
                    <text className="mb-0">
                      {jobDetails.jobs_post_date}{" "}
                      <a className="text-primary">Staffing Connect</a>
                    </text>
                    {/*<ul className="job-info">
											<li><strong>Education</strong> Web Designer</li>
											<li><strong>Deadline:</strong> 25th January 2018</li>
											<li><i className="ti-location-pin text-black m-r5"></i> NewYark </li>
										</ul> */}
                    {/* <h6>Here you will find a selection of ongoing vacancies we have available.</h6>
										<p className="p-t20">Please note: all of our candidates are required to undergo our registration process, which, in addition to a CV screening, telephone screening and face-to-face interview, entails a thorough qualification and identification check. A Criminal Records Bureau check, organised by us, is also required of all candidates.If you are interested in applying for any of these roles or would like to enquire about other vacancies in Social Care, Education and Homecare, please email your CV to info@staffingconnect.co.uk outlining which vacancy or type of work you would like to be considered for.</p> */}
                    {/*<h5 className="font-weight-600">Job Description</h5> */}
                    <div className="dez-divider divider-2p`x bg-gray-dark mb-4 mt-0"></div>
                    {/* <p id="job_description"></p>; */}
                    <div
                      dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
                    />
                    {skillSetData && skillSetData.length > 0 && (
                      <>
                        <div className="dez-divider divider-2p`x bg-gray-dark mb-4 mt-0"></div>
                        <div className="container-fluid">
                          <div className="row">
                            <h5 className="pull-left">Required Skills</h5>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <ul>
                                {skillSetData.map((item, index) => (
                                  <a
                                    className="btn btn-primary text-white p-2 mr-1 mb-2"
                                    style={{ borderRadius: "10px" }}
                                    key={index}
                                  >
                                    {item ? capitalizeFirstLetter(item) : ""}
                                  </a>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* {JSON.stringify(screeningQuestions)} */}
                    {(!individaulJobDetail.cnd_permanent_job_status ||
                      individaulJobDetail.cnd_permanent_job_status ==
                        "Draft") &&
                      screeningQuestions &&
                      screeningQuestions.length > 0 && (
                        <>
                          <div className="dez-divider divider-2p`x bg-gray-dark mb-4 mt-0"></div>
                          <div className="container-fluid">
                            <div className="row">
                              <h5 className="pull-left">
                                Application Questions
                              </h5>
                              {/* {JSON.stringify(formData)} */}
                            </div>
                            <div className="row">
                              <div className="col-sm-12" id="formDiv">
                                {screeningQuestions.map((item, index) => (
                                  <p
                                    className="bg-light text-black p-3 text-xl mr-1 mb-2"
                                    style={{ borderRadius: "10px" }}
                                    key={index}
                                  >
                                    <h6>
                                      {item.screening_questions_question_title
                                        ? convertToOriginalForm(
                                            item.screening_questions_question_title +
                                              (item.screening_questions_question_title.endsWith(
                                                "?"
                                              )
                                                ? ""
                                                : "?")
                                          )
                                        : ""}
                                    </h6>

                                    <p className="p-2 mb-0">
                                      <Form.Check
                                        outline
                                        label={"Yes"}
                                        value={"Yes"}
                                        defaultValue={"Yes"}
                                        defaultChecked={
                                          item.screening_questions_job_applied_answer ===
                                          "Yes"
                                        }
                                        name={
                                          "cnd_job_application_screening_question_answer_" +
                                          (item.selected_screening_questions_id
                                            ? item.selected_screening_questions_id
                                            : item.screening_questions_id)
                                        }
                                        onChange={(e) => {
                                          onInputValues(e);
                                          if (
                                            item.selected_screening_questions_id
                                          ) {
                                            setCheckValidation("false");
                                          }
                                        }}
                                        type="radio"
                                        id={
                                          "cnd_job_application_screening_question_answer_" +
                                          (item.selected_screening_questions_id
                                            ? item.selected_screening_questions_id
                                            : item.screening_questions_id) +
                                          "_yes"
                                        }
                                      />

                                      <Form.Check
                                        outline
                                        label={"No"}
                                        value={"No"}
                                        defaultValue={"No"}
                                        defaultChecked={
                                          item.screening_questions_job_applied_answer ===
                                          "No"
                                        }
                                        onChange={(e) => {
                                          onInputValues(e);
                                          if (
                                            item.selected_screening_questions_id
                                          ) {
                                            setCheckValidation("false");
                                          }
                                        }}
                                        name={
                                          "cnd_job_application_screening_question_answer_" +
                                          (item.selected_screening_questions_id
                                            ? item.selected_screening_questions_id
                                            : item.screening_questions_id)
                                        }
                                        type="radio"
                                        id={
                                          "cnd_job_application_screening_question_answer_" +
                                          (item.selected_screening_questions_id
                                            ? item.selected_screening_questions_id
                                            : item.screening_questions_id) +
                                          "_no"
                                        }
                                      />
                                    </p>
                                  </p>
                                ))}
                              </div>
                            </div>
                            <p
                              className={"text-danger"}
                              id={
                                "cnd_job_application_screening_question_answer_msg"
                              }
                            ></p>
                          </div>
                        </>
                      )}

                    {/* {coverLetterPreference} */}

                    {/* CV Show - Start */}
                    {/* {individaulJobDetail.cnd_permanent_job_status} */}
                    {(!individaulJobDetail.cnd_permanent_job_status ||
                      individaulJobDetail.cnd_permanent_job_status ==
                        "Draft") &&
                      showCV == true && (
                        <>
                          <div className="dez-divider divider-2p`x bg-gray-dark mb-4 mt-0"></div>
                          <div className="container-fluid">
                            <div className="row">
                              <h5 className="pull-left">Your Application</h5>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                {showCover == true && (
                                  <>
                                    <p
                                      className="bg-light text-black p-3 border"
                                      style={{ borderRadius: "20px" }}
                                    >
                                      <b>
                                        <a
                                          onClick={(event, newInputValue) => {
                                            if (
                                              props.userInfo
                                                .cnd_cover_forderpath &&
                                              props.userInfo.cnd_upload_cover
                                            ) {
                                              viewFileModal(
                                                constants.IMAGE_URL +
                                                  props.userInfo
                                                    .cnd_cover_forderpath +
                                                  props.userInfo
                                                    .cnd_upload_cover,
                                                "Cover Letter"
                                              );
                                            } else {
                                              Swal.fire({
                                                title:
                                                  "Cover Letter Not Available",
                                                text: "Do you want to upload the Cover Letter to apply for this job role?",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonText: "Yes",
                                                // confirmButtonColor: 'green',
                                                // cancelButtonColor:  'red',
                                                cancelButtonText: "No",
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  updateCVModalOperation(true);
                                                  setUploadFileElement(
                                                    "cnd_upload_cover"
                                                  );
                                                }
                                              });
                                            }
                                          }}
                                          className="cursor-pointer"
                                        >
                                          <i className="fa fa-file text-danger mr-1"></i>
                                          Cover Letter{" "}
                                          {coverLetterPreference ==
                                          "required" ? (
                                            <>
                                              <b className="text-danger">*</b>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </a>
                                        <a
                                          onClick={(event, newInputValue) => {
                                            updateCVModalOperation(true);
                                            setUploadFileElement(
                                              "cnd_upload_cover"
                                            );
                                          }}
                                          className="text-primary pull-right cursor-pointer"
                                        >
                                          {props.userInfo.cnd_upload_cover
                                            ? "Update"
                                            : "Upload"}{" "}
                                          Cover Letter
                                        </a>
                                      </b>
                                    </p>
                                  </>
                                )}
                                <p
                                  className="bg-light text-black p-3 border"
                                  style={{ borderRadius: "20px" }}
                                >
                                  <b>
                                    <a
                                      onClick={(event, newInputValue) => {
                                        if (
                                          props.userInfo.cnd_cv_forderpath &&
                                          props.userInfo.cnd_upload_cv
                                        ) {
                                          viewFileModal(
                                            constants.IMAGE_URL +
                                              props.userInfo.cnd_cv_forderpath +
                                              props.userInfo.cnd_upload_cv,
                                            "CV"
                                          );
                                        } else {
                                          Swal.fire({
                                            title: "CV Not Available",
                                            text: "Do you want to upload the CV to apply for this job role?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonText: "Yes",
                                            // confirmButtonColor: 'green',
                                            // cancelButtonColor:  'red',
                                            cancelButtonText: "No",
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              updateCVModalOperation(true);
                                              setUploadFileElement(
                                                "cnd_upload_cv"
                                              );
                                            }
                                          });
                                        }
                                      }}
                                      className="cursor-pointer"
                                    >
                                      <i className="fa fa-file text-danger mr-1"></i>
                                      Your CV <b className="text-danger">*</b>
                                    </a>
                                    <a
                                      onClick={(event, newInputValue) => {
                                        updateCVModalOperation(true);
                                        setUploadFileElement("cnd_upload_cv");
                                      }}
                                      className="text-primary pull-right cursor-pointer"
                                    >
                                      {props.userInfo.cnd_upload_cv
                                        ? "Update"
                                        : "Upload"}{" "}
                                      CV
                                    </a>
                                  </b>
                                </p>
                              </div>
                            </div>
                            <p
                              className={"text-danger mb-0"}
                              id={
                                "cnd_job_application_screening_question_answer_msg"
                              }
                            ></p>
                          </div>
                        </>
                      )}
                    {/* CV Show - End */}
                    {/* Update CV Modal - Start */}
                    <Modal
                      show={updateCVModal}
                      onHide={() => updateCVModalOperation(false)}
                      className="col-sm-12 fade"
                      style={{ marginTop: "10%" }}
                    >
                      <div className="modal-header bg-dark text-white">
                        <h5 className="" id="ResumeheadlineModalLongTitle">
                          Update your{" "}
                          {uploadFileElement == "cnd_upload_cv"
                            ? "CV"
                            : "Cover Letter"}
                        </h5>
                        <a
                          className="btn btn-danger btn-sm text-white m-2 cursor-pointer"
                          onClick={() => updateCVModalOperation(false)}
                        >
                          <b>
                            <i className="fa fa-close"></i>
                          </b>
                        </a>
                      </div>
                      <div className="modal-body">
                        <form className="attach-resume">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div
                                className="form-group border border-primary p-3"
                                style={{
                                  borderRadius: "30px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className="custom-file"
                                  style={{
                                    borderRadius: "30px",
                                    width: "100%",
                                    height: "auto",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    cursor: "pointer",
                                  }}
                                >
                                  <p
                                    className="m-auto align-self-center text-primary"
                                    style={{ zIndex: 1, position: "absolute" }}
                                  >
                                    <i className="fa fa-upload mr-1"></i>
                                    Upload Your{" "}
                                    {uploadFileElement == "cnd_upload_cv"
                                      ? "CV"
                                      : "Cover Letter"}
                                  </p>
                                  <input
                                    type="file"
                                    accept=".pdf, .png, .jpeg, .jpg, .doc, .docx, .docm, .dot, .dotm"
                                    name={uploadFileElement}
                                    onChange={(e) => uploadCVFile(e)}
                                    className="site-button form-control"
                                    id="customFile"
                                    style={{
                                      opacity: 0,
                                      width: "100%",
                                      height: "100%",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal>
                    {/* Update CV Modal - End */}
                    {/* <h5 className="font-weight-600">How to Apply</h5>*/}

                    {/* <h5 className="font-weight-600">
                  Main Responsibilities, Tasks & Duties
                </h5>
                <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                <ul className="list-num-count no-round">
                  {jobDetails &&
                    jobDetails.responsibilities &&
                    jobDetails.responsibilities.map((li, index) => (
                      <div key={index}>
                        <li className="p-1 ml-3">{li}</li>
                      </div>
                    ))}
                </ul>
                <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                <div className="list-num-count no-round">
                  {jobDetails &&
                    Array.isArray(jobDetails.othercontaint) &&
                    jobDetails.othercontaint.map((li, index) => (
                      <div key={index}>
                        {li}
                        {index < jobDetails.othercontaint.length - 1 && (
                          <div>&nbsp;</div>
                        )}
                      </div>
                    ))}
                </div> */}

                    {showCV == false ? (
                      <>
                        {jobUrl == "/apply-more-jobs" ? (
                          <>
                            {!individaulJobDetail.cnd_permanent_job_status ||
                            individaulJobDetail.cnd_permanent_job_status ==
                              "Draft" ? (
                              <>
                                <a
                                  className="site-button green cursor-pointer text-white float-right mt-4 mb-4"
                                  // to={{
                                  //   pathname: jobUrl,
                                  //   state: {
                                  //     selectedTitle: jobDetails.jobs_id
                                  //       ? convertToSnakeCase(jobDetails.jobs_id)
                                  //       : "",
                                  //   },
                                  // }}
                                  onClick={(event, newInputValue) => {
                                    if (
                                      totalProgressBar &&
                                      ((!props.userInfo.cnd_upload_cv &&
                                        totalProgressBar >= 90) ||
                                        (props.userInfo.cnd_upload_cv &&
                                          totalProgressBar == 100))
                                    ) {
                                      if (
                                        showCV == true &&
                                        (!props.userInfo.cnd_cv_forderpath ||
                                          !props.userInfo.cnd_upload_cv)
                                      ) {
                                        Swal.fire({
                                          title: "CV Required",
                                          text: "Please upload the CV",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonText: "Ok",
                                          // confirmButtonColor: 'green',
                                          cancelButtonColor: "red",
                                          cancelButtonText: "Cancel",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            updateCVModalOperation(true);
                                            setUploadFileElement(
                                              "cnd_upload_cv"
                                            );
                                          }
                                        });
                                      } else if (
                                        showCover == true &&
                                        jobDetails.jobs_cover_letter_preferences ==
                                          "Required" &&
                                        (!props.userInfo.cnd_cover_forderpath ||
                                          !props.userInfo.cnd_upload_cover)
                                      ) {
                                        Swal.fire({
                                          title: "Cover Letter Required",
                                          text: "Please upload the Cover Letter",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonText: "Ok",
                                          // confirmButtonColor: 'green',
                                          cancelButtonColor: "red",
                                          cancelButtonText: "Cancel",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            updateCVModalOperation(true);
                                            setUploadFileElement(
                                              "cnd_upload_cover"
                                            );
                                          }
                                        });
                                      } else {
                                        setjobsApplyType("draft");
                                        validForm();
                                        // applyJobs();
                                      }
                                    } else {
                                      Swal.fire({
                                        title: "Profile Incomplete",
                                        text: "Please complete your profile to apply for the jobs",
                                        icon: "warning",
                                        showCancelButton: false,
                                        confirmButtonText: "Ok",
                                        // confirmButtonColor: 'green',
                                        cancelButtonColor: "red",
                                        cancelButtonText: "Cancel",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          window.location.href =
                                            "/candidate-profile";
                                        }
                                      });
                                    }
                                  }}
                                >
                                  {individaulJobDetail.cnd_permanent_job_status ==
                                  "Draft"
                                    ? "Continue Applying"
                                    : "Apply Now"}
                                </a>
                              </>
                            ) : (
                              <>
                                <div
                                  class="alert alert-warning text-dark alert-dismissible fade show clearfix"
                                  role="alert"
                                  style={{ borderRadius: "20px" }}
                                >
                                  <i
                                    className="fa fa-info-circle text-dark"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  <strong>
                                    You applied for this job on{" "}
                                    {
                                      individaulJobDetail.cnd_permanent_job_updated_date
                                    }{" "}
                                    .
                                  </strong>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <a
                              className="site-button green cursor-pointer text-white float-right mt-4 mb-4"
                              onClick={() => userNotLoggedIn(jobUrl)}
                              // onClick={() => validForm()}
                            >
                              Apply Now
                            </a>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <a
                          className="site-button green cursor-pointer text-white float-right mt-4 mb-4"
                          // onClick={() => userNotLoggedIn(jobUrl)}
                          onClick={(event, newInputValue) => {
                            // setjobsApplyType('send_application');
                            if (
                              totalProgressBar &&
                              ((!props.userInfo.cnd_upload_cv &&
                                totalProgressBar >= 90) ||
                                (props.userInfo.cnd_upload_cv &&
                                  totalProgressBar == 100))
                            ) {
                              if (
                                showCV == true &&
                                (!props.userInfo.cnd_cv_forderpath ||
                                  !props.userInfo.cnd_upload_cv)
                              ) {
                                Swal.fire({
                                  title: "CV Required",
                                  text: "Please upload the CV",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: "Ok",
                                  // confirmButtonColor: 'green',
                                  cancelButtonColor: "red",
                                  cancelButtonText: "Cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    updateCVModalOperation(true);
                                    setUploadFileElement("cnd_upload_cv");
                                  }
                                });
                              } else if (
                                props.individualJobsDetails
                                  .jobs_cover_letter_preferences ==
                                  "Required" &&
                                (!props.userInfo.cnd_cover_forderpath ||
                                  !props.userInfo.cnd_upload_cover)
                              ) {
                                Swal.fire({
                                  title: "Cover Letter Required",
                                  text: "Please upload the Cover Letter",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: "Ok",
                                  // confirmButtonColor: 'green',
                                  cancelButtonColor: "red",
                                  cancelButtonText: "Cancel",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    updateCVModalOperation(true);
                                    setUploadFileElement("cnd_upload_cover");
                                  }
                                });
                              } else {
                                applyJobs();
                                // validForm();
                              }
                            } else {
                              Swal.fire({
                                title: "Profile Incomplete",
                                text: "Please complete your profile to apply for the jobs",
                                icon: "warning",
                                showCancelButton: false,
                                confirmButtonText: "Ok",
                                // confirmButtonColor: 'green',
                                cancelButtonColor: "red",
                                cancelButtonText: "Cancel",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  window.location.href = "/candidate-profile";
                                }
                              });
                            }
                          }}
                        >
                          Submit Application
                        </a>
                      </>
                    )}
                    <a
                      className="site-button yellow text-white float-right mt-4 mb-4 mr-2"
                      onClick={() => goBackPage()}
                    >
                      Back
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <GlobalViewFileModal
            modalOption={modalOption}
            closeFileModal={closeFileModal}
            fileUrl={fileUrl}
            fileName={fileName}
            loaderEnd={loaderEnd}
            modalHeight={modalHeight}
            callModal={callModal}
            title={"Read Document"}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    individualJobsDetails: state.individualJobsDetails.individualJobsDetails,
    jobsScreeningQuestions: state.jobsScreeningQuestions.jobsScreeningQuestions,
    workAndQualificationDetails:
      state.workAndQualificationDetails.workAndQualificationDetails,
  };
};

export default composedJobsContainer(JobDetails);
