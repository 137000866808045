const initialState = {dailylogs: []}
const initialStateOne = {dailylogsserviceuserlist: []}

export const dailylogs = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_DAILY_LOGS':
      return {dailylogs: action.payload}; 
  }
  return state
}

export const dailylogsserviceuserlist = (state = initialStateOne, action) => {
	switch (action.type) {
  	case 'SET_DAILY_LOGS_SERVICE_USER_LIST':
      return {dailylogsserviceuserlist: action.payload}; 
  }
  return state
}


