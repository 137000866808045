import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
const AssesmentCategoryProfileContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const { userAuth, assesmentCategory } = props;
    const dispatch = useDispatch();
    useEffect(()=>{
      if(userAuth.users_access_token && Object.keys(assesmentCategory).length == 0){
        dispatch(getDataOnRequest('','GetIndividualRiskProfileMaster','SET_INDIVIDUAL_RISK_MASTER'))
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetGetCarePlans','SET_CARE_PLAN'))
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetAssesmentProfile','SET_ASSESMENT_CATEGORY_RISK'))
      }
    },[userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    assesmentCategory:state.assesmentCategory.assesmentCategory
  };
};

const composedAssesmentCategoryProfileContainer = compose(connect(mapStateToProps), AssesmentCategoryProfileContainer)
export default composedAssesmentCategoryProfileContainer;

