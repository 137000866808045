const initialState = {careplanmaster: []}

export const careplanmaster = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_MASTER_CARE_PLAN':
      return {careplanmaster: action.payload}; 

  }
  return state
}


