import {
    validateStringWithoutSpecialCharacter,
    validateEmail,
    validateUkNumber,
  } from "./ValidationHelpers";
import { format } from "date-fns";  
  
  let errorsObj = {};
      
  export const CandidatePersonalInfoValidation = (userdata, fieldName) => {
    let error = false;
    const errorObj = { ...errorsObj };
    let cnd_street_address = document.getElementById("cnd_street_address");
  let cnd_town =  document.getElementById("cnd_town");
  let cnd_postcode =  document.getElementById("cnd_postcode");
  let cnd_county =  document.getElementById("cnd_county");
  // console.log("Country Value :=>"+ cnd_county.value);

  
    if (fieldName == "users_firstname" || fieldName == "submit") {
      if (userdata.users_firstname === "") {
        errorObj.users_firstname = "First Name is Required";
        error = true;
      } else if (
        !validateStringWithoutSpecialCharacter(userdata.users_firstname)
      ) {
        errorObj.users_firstname = "Please enter valid firstname";
        error = true;
      }
    }
    if (fieldName == "users_middlename" || fieldName == "submit") {
      if (userdata.users_middlename && userdata.users_middlename.trim() == "") {
        errorObj.users_middlename = "Please enter the valid middle name";
        error = true;
      }
    }
    if (fieldName == "users_lastname" || fieldName == "submit") {
      if (!userdata.users_lastname) {
        errorObj.users_lastname = "Last Name is Required";
        error = true;
      } else if (
        !validateStringWithoutSpecialCharacter(userdata.users_lastname)
      ) {
        errorObj.users_lastname = "Please enter valid lastname";
        error = true;
      }
    }
    if (fieldName == "users_email" || fieldName == "submit") {
      if (!userdata.users_email) {
        errorObj.users_email = " The Email is Required";
        error = true;
      }
    }
  
    if (fieldName == "cnd_gender" || fieldName == "submit") {
      if (!userdata.cnd_gender) {
        errorObj.cnd_gender = "Gender Field is Required";
        error = true;
      }
    }
  
    if (fieldName == "users_email" || fieldName == "submit") {
      if (!validateEmail(userdata.users_email)) {
        errorObj.users_email = "Please enter valid email";
        error = true;
      }
    }

    if (fieldName == "cnd_birth_date" || fieldName == "submit") {
      if (!userdata.cnd_birth_date) {
        errorObj.cnd_birth_date = "Please select the valid date";
        error = true;
      }else{
        let birthDate = new Date(String(userdata.cnd_birth_date));
        let currentDate = new Date();
        let minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 73));
        let maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 15));
        // console.warn("Full Year of Current Date :=>"+birthDate);
        if(birthDate > currentDate || birthDate > maxDate || birthDate < minDate){
          // console.log("Birth Date is :=>"+ birthDate);
          errorObj.cnd_birth_date = "Date must be before or "+format(maxDate,"dd-MM-yyyy")+" and "+format(minDate,"dd-MM-yyyy")+" or later";
          error = true;
        }
      }
    }
  
    if (fieldName == "users_phonenumber" || fieldName == "submit") {
      if (!userdata.users_phonenumber) {
        errorObj.users_phonenumber = "Please enter phone number";
        error = true;
      } else if (!validateUkNumber(userdata.users_phonenumber)) {
        errorObj.users_phonenumber = "Please enter valid phone number";
        error = true;
      }
    }
  
    
    if (fieldName == "cnd_street_address" || fieldName == "submit") {
      if (!cnd_street_address.value) {
        errorObj.cnd_street_address = "Street address required";
        error = true;
      }else if (cnd_street_address.value.trim() == "") {
        errorObj.cnd_street_address = "Please enter the valid Street Address";
        error = true;
      }
    }

    if (fieldName == "cnd_street_address_2" || fieldName == "submit") {
      if(userdata.cnd_street_address_2 && userdata.cnd_street_address_2.trim() == "") {
        errorObj.cnd_street_address_2 = "Please enter the valid Street Address";
        error = true;
      }
    }

    if (fieldName == "cnd_region" || fieldName == "submit") {
      if(userdata.cnd_region && userdata.cnd_region.trim() == "") {
        errorObj.cnd_region = "Please enter the valid Region";
        error = true;
      }
    }
  
    if (fieldName == "cnd_town" || fieldName == "submit") {
      if (!cnd_town.value) {
        errorObj.cnd_town = "Town required";
        error = true;
      }else if (cnd_town.value.trim() == "") {
        errorObj.cnd_town = "Please enter the valid Town";
        error = true;
      }
    }
  
    if (fieldName == "cnd_postcode" || fieldName == "submit") {
      if (!cnd_postcode.value) {
        errorObj.cnd_postcode = "Postcode required";
        error = true;
      } else if(cnd_postcode.value.trim() == ""){
        errorObj.cnd_postcode = "Please enter the valid postal code";
        error = true;
      }
      else if (
        !String(cnd_postcode.value).match(
           /* /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/*/
           
           /*/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/*/
  
           /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
          )
      ) {
        errorObj.cnd_postcode = "Please Enter Valid Postcode";
        error = true;
      }
    }
    // console.log("Country Value :=>");
    if (fieldName == "cnd_county" || fieldName == "submit") {

      if (!cnd_county.value) {
        errorObj.cnd_county = "Country required";
        error = true;
      }else if(cnd_county.value.trim() == ""){
        errorObj.cnd_county = "Please enter the valid country";
        error = true;
      }
    }
    // if (fieldName == "cnd_training_details" || fieldName == "submit") {
    //   if (!userdata.cnd_training_details) {
    //     errorObj.cnd_training_details = "Training Detail Field Required";
    //     error = true;
    //   }
    // }
    if (!userdata.cnd_upload_cv) {
      errorObj.cnd_upload_cv = "Please select the CV";
      error = true;
    }
    if (!userdata.cnd_traing_and_qualifications) {
        errorObj.cnd_traing_and_qualifications = "Please enter Training & Qualifications";
        error = true;
    }else if(userdata.cnd_traing_and_qualifications.trim() == ""){
      errorObj.cnd_traing_and_qualifications = "Please enter the valid details";
      error = true;
    }
    // if (!userdata.cnd_right_to_work) {
    //     errorObj.cnd_right_to_work = "Please select the option";
    //     error = true;
    // }
    if (!userdata.cnd_drivinglicense) {
        errorObj.cnd_drivinglicense = "Please select the option";
        error = true;
    }

    if (!userdata.cnd_disability_condition) {
        errorObj.cnd_disability_condition = "Please select the option";
        error = true;
    }else{
        if(!userdata.cnd_disability_description && userdata.cnd_disability_condition == "yes"){
            errorObj.cnd_disability_description = "Please enter the description about Disability/health condition";
            error = true;
        }else if(userdata.cnd_disability_description && userdata.cnd_disability_condition == "yes" && userdata.cnd_disability_description.trim() == ""){
          errorObj.cnd_disability_description = "Please enter the valid description";
            error = true;

        }
    }

    // if (!userdata.cnd_permanent_job_title) {
    //     errorObj.cnd_permanent_job_title = "Please select a job position";
    //     error = true;
    // }

    if (!userdata.cnd_consent_check || userdata.cnd_consent_check == "no") {
         errorObj.cnd_consent_check = "Please agree the consent to proceed";
         error = true;
        document.getElementById("errorMsg").style.display = "block";
    }else{
        document.getElementById("errorMsg").style.display = "none";
    }

  
    return { stateData: errorObj, error: error };
  };
  