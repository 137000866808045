import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadloginInfo } from "./../redux/actions/AuthActions";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import { useHistory } from "react-router-dom";
import { updateDataOnRequest } from "../redux/actions/CommonActions";
import Input from "../components/FormElements/Input";
import Label from "../components/Layout/Label";
import AccountCentreSidebar from "../components/Element/AccountCentreSidebar";
import { isMobileOrLaptop } from "../services/CheckDevice";
import AccountCentreSidebarMobile from "../components/Element/AccountCentreSidebarMobile";

var bnr = require("./../images/banner/bnr2.jpg");

const AccountCentre = (props) => {
  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(350);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End
  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-blocks">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {checkDevice == "Mobile" ? (
                  <AccountCentreSidebarMobile page="account-faq" />
                ) : (
                  <AccountCentreSidebar page="account-faq" />
                )}
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx submit-resume" id="scrollFocusElement">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Steps to Delete User Account
                      </h5>
                      {/* <Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link> */}
                    </div>
                    <p>
                      Deleting a user account in the Account Centre is a
                      significant action, as it permanently removes access to
                      the associated services and data. Follow the steps below
                      to delete a user account, and be sure to read the warnings
                      and considerations before proceeding.
                    </p>

                    <div className="container ml-2">
                      <h6>Step 1: Locate "Delete My Account" Option</h6>
                      <p className="ml-4">
                        <ul>
                          <li>
                            To Delete your account click on the{" "}
                            <b className="text-danger">
                              <i
                                class="fa fa-trash text-lg mr-1"
                                aria-hidden="true"
                              ></i>{" "}
                              Delete My Account{" "}
                            </b>{" "}
                            option from the left sidebar.
                          </li>
                        </ul>
                      </p>
                    </div>

                    <div className="container ml-2">
                      <h6>Step 2: Confirm Your Decision</h6>
                      <p className="ml-4">
                        <ul>
                          <li>
                            After reaching{" "}
                            <b className="text-danger">
                              <i
                                class="fa fa-trash text-lg mr-1"
                                aria-hidden="true"
                              ></i>{" "}
                              Delete My Account{" "}
                            </b>{" "}
                            page, carefully review the warning message that
                            appears. Make sure you understand that deleting your
                            account is irreversible and will result in permanent
                            loss of all data associated with it.
                          </li>
                        </ul>
                      </p>
                    </div>

                    <div className="container ml-2">
                      <h6>Step 3: Provide Feedback (Optional)</h6>
                      <p className="ml-4">
                        <ul>
                          <li>
                            If you have any feedback or reasons for leaving, you
                            may have an opportunity to provide them at this
                            stage. Your input can help us improve our services
                            for future users.
                          </li>
                        </ul>
                      </p>
                    </div>

                    <div className="container ml-2">
                      <h6>
                        Step 4: ONLY FOR TEMPORARY WORKERS - Cancel all the
                        booked assignments - (If you have booked assignments){" "}
                      </h6>
                      <p className="ml-4">
                        <ul>
                          <li>
                            To Delete your account you need to cancel all the
                            booked assignments with the valid reason.
                          </li>
                        </ul>
                      </p>
                    </div>

                    <div className="container ml-2">
                      <h6>Step 5: Verify Your Decision</h6>
                      <p className="ml-4">
                        <ul>
                          <li>
                            To confirm that you truly want to delete your
                            account, you might be required to re-enter your
                            password or go through an additional verification
                            step. This ensures that the account deletion request
                            is genuine and prevents any accidental deletions.
                          </li>
                        </ul>
                      </p>
                    </div>

                    <div className="container ml-2">
                      <h5 className="text-warning">Important Warnings:</h5>
                      <p>
                        Deleting your account will permanently remove all your
                        personal information, job applications, saved jobs, and
                        any other data associated with your profile. This action
                        cannot be undone.
                      </p>

                      <p>
                        Please note that once your account is deleted, you will
                        no longer have access to any job applications or
                        communications related to the account. We recommend
                        saving any important information before proceeding with
                        the deletion.
                      </p>

                      <p>
                        If you have any active job applications or outstanding
                        timesheets, please resolve them before deleting your
                        account. Otherwise, all related data will be lost.
                      </p>

                      <p>
                        If you are certain about deleting your
                        StaffingConnect.co.uk account and understand the
                        consequences mentioned above, please proceed with
                        caution and follow the steps outlined above.
                      </p>

                      <h6>
                        For further assistance or clarification, please contact
                        our support team at{" "}
                        <a
                          href="mailto:info@staffingconnect.co.uk"
                          className="text-primary"
                        >
                          info@staffingconnect.co.uk
                        </a>
                        .
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: isAuthenticated(state),
    errorMessage: state.error.error,
  };
};

export default connect(mapStateToProps)(AccountCentre);
