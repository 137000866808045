import React from 'react';
import {Link} from 'react-router-dom';
import PageHeader from '../components/Layout/PageHeader';
import Footer from '../components/Layout/Footer';
import PageTitle from '../components/Layout/PageTitle';

var bnr = require('./../images/banner/bnr1.jpg');

function AdminApproval(){
	// Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Wait for Admin Approval";

	return(
		<div className="page-wraper">
			<div className="page-content">
				<div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + bnr + ")"}}>
					<PageTitle  motherName="Home" activeName="Wait for Admin Approval" />
				</div>
				<div className="section-full content-inner-2 bg-white">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 error-page text-center">
								<h1 className=" " >Please, wait for Admin approval !</h1>
								{/* <h3>OOPS!</h3>
								<h4 className="text-primary">Wait for Admin Approval</h4> */}
                                {/* <img src='https://admin.itstaffingsolutions.co.uk/assets/img/loader/loader.gif' style={{}} /><br /> */}
                                <br />
								<Link to={"./"} className="site-button">Back To Home</Link>

							</div>
						</div>
					</div>
				</div>
			</div>	
		</div>
	)
}
export default AdminApproval;