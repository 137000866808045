import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { agreementArray } from "../../../helpers/AgreementContent";
import { updateUserProfile } from "../../../redux/actions/UserProfileActions";
import Errors from "../../Layout/Error";
const ApplicationDeclaration = (props) => {
  const dispatch = useDispatch();
  const { loader } = props;
  const [isAgree, setIsAgree] = useState({});
  const [isTextDisabled, setTextDisabled] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const history = useHistory();
  const { userData } = props;
  const [isReadMore, setIsReadMore] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
  });
  const toggleReadMore = (key) => {
    let readMore = { ...isReadMore };
    readMore[key] = readMore[key] ? false : true;
    setIsReadMore(readMore);
  };

  const onChangeAgree = (e) => {
    let formData = { ...isAgree };
    formData[e.target.name] = isAgree[e.target.name] == 1 ? 0 : 1;
    if (getValues().cnd_interest_digital_signature == true) {
      console.log(
        "Get Values :=>" + getValues().cnd_interest_digital_signature
      );
      let isAgree48HourAgreement = {
        title:
          "Declaration of Interest Terms of Opt Out – 48 Hour Working Week Agreement",
        field: "cnd_interest_isagree",
        showExtraText: false,
        extraTextLabel: "",
        msg: "I do hereby declare that I am not engaged in or do not intend to engage in any work and / or business that is prejudicial to the work and / or business I will be undertaking for and on behalf of the agency.",
      };
      let agreementMerge = [...agreementArray];
      if (formData[e.target.name]) {
        agreementMerge = [...agreementArray, isAgree48HourAgreement];
        setAgreements(agreementMerge);
      }
    } else {
      let agreementMerge = [...agreementArray];
      setAgreements(agreementMerge);
    }
    if (e.target.name === "cnd_profile_medical") {
      if (e.target.value === "no") setValue("cnd_medical_condition", "");
      setTextDisabled(e.target.value == "yes" ? false : true);
      formData[e.target.name] = e.target.value;
    }
    setIsAgree(formData);
  };

  const validationSchema = () => {
    //console.log("Last testing :=> "+isAgree.cnd_interest_digital_signature);
    if (isAgree.cnd_profile_medical == "yes") {
      return Yup.object().shape({
        cnd_application_isagree: Yup.bool().oneOf(
          [true, 1],
          "Please agree term & conditions"
        ),
        cnd_confidentiality_isagree: Yup.bool().oneOf(
          [true, 1],
          "Please agree term & conditions"
        ),
        cnd_medical_isagree: Yup.bool().oneOf(
          [true, 1, false, 0],
          "Please agree term & conditions"
        ),
        cnd_represent_isagree: Yup.bool().oneOf(
          [true, 1],
          "Please agree term & conditions"
        ),
        cnd_medical_condition: Yup.string().required(
          "Please agree term & conditions"
        ),
        cnd_interest_isagree:
          isAgree.cnd_interest_digital_signature == 1
            ? Yup.bool().oneOf([true, 1], "Please agree term & conditions")
            : "",
      });
    } else {
      return Yup.object().shape({
        cnd_application_isagree: Yup.bool().oneOf(
          [true, 1],
          "Please agree term & conditions"
        ),
        cnd_confidentiality_isagree: Yup.bool().oneOf(
          [true, 1],
          "Please agree term & conditions"
        ),
        cnd_medical_isagree: Yup.bool().oneOf(
          [true, 1], //[true, 1, false, 0],
          "Please agree term & conditions"
        ),
        cnd_represent_isagree: Yup.bool().oneOf(
          [true, 1],
          "Please agree term & conditions"
        ),
        cnd_interest_isagree:
          isAgree.cnd_interest_digital_signature == 1
            ? Yup.bool().oneOf([true, 1], "Please agree term & conditions")
            : "",
      });
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema()),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const updateAgreement = (data) => {
    document.getElementById("loader").style.display = "block";
    let formData = data;
    if (isAgree.cnd_interest_digital_signature == 1) {
      formData["cnd_interest_isagree"] = 1;
    } else {
      formData["cnd_interest_isagree"] = 0;
    }
    formData["cnd_profile_medical"] =
      isAgree.cnd_profile_medical == "yes" ? 1 : 0;
    formData["users_access_token"] = props.userAuth.users_access_token;
    dispatch(updateUserProfile(formData, "updateIntrestAgreement"));
    //history.push('/bank-details');
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    setValue(
      "cnd_application_isagree",
      userData.cnd_application_isagree == 1 && true
    );
    setValue(
      "cnd_confidentiality_isagree",
      userData.cnd_confidentiality_isagree == 1 && true
    );
    setValue("cnd_medical_isagree", userData.cnd_medical_isagree == 1 && true);
    if (userData.cnd_medical_condition != 0)
      setValue("cnd_medical_condition", userData.cnd_medical_condition);
    else setValue("cnd_medical_condition", "");
    setValue(
      "cnd_interest_isagree",
      userData.cnd_interest_isagree == 1 && true
    );
    setValue(
      "cnd_represent_isagree",
      userData.cnd_represent_isagree == 1 && true
    );
    setValue(
      "cnd_interest_digital_signature",
      userData.cnd_interest_digital_signature == 1 && true
    );
    if (userData.cnd_profile_medical == 0) {
      userData.cnd_profile_medical = "no";
      setTextDisabled(true);
    } else {
      userData.cnd_profile_medical = "yes";
    }
    let isAgree48HourAgreement = {
      title:
        "Declaration of Interest Terms of Opt Out – 48 Hour Working Week Agreement",
      field: "cnd_interest_isagree",
      showExtraText: false,
      extraTextLabel: "",
      msg: "I do hereby declare that I am not engaged in or do not intend to engage in any work and / or business that is prejudicial to the work and / or business I will be undertaking for and on behalf of the agency.",
    };
    let agreementMerge = [...agreementArray];
    if (userData.cnd_interest_digital_signature == 1)
      agreementMerge = [...agreementArray, isAgree48HourAgreement];
    setAgreements(agreementMerge);
    setIsAgree(userData);
  }, [props.userData]);

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <form onSubmit={handleSubmit(updateAgreement)}>
          {agreements.map((value, key) => (
            <div key={key} className="row m-b20">
              <div className="col-lg-12 col-md-12 m-b0">
                <div className="job-bx-title">
                  <div className="row">
                    <div className="col-sm-6 col-md-8 col-lg-10">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        {value.title}
                      </h5>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-2">
                      <Form.Group as={Col} md={12} sm={12}>
                        <Form.Check
                          {...register(value.field, {
                            onChange: (e) => {
                              onChangeAgree(e);
                            },
                          })}
                          label={"I Agree"}
                          type="checkbox"
                          name={value.field}
                          isInvalid={errors && errors[value.field] ? true : ""}
                        />
                        <Form.Control.Feedback>
                          {errors && errors[value.field]?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <label className="text">
                    {isReadMore[key] ? value.msg.slice(0, 150) : value.msg}
                    <span
                      onClick={() => toggleReadMore(key)}
                      className="read-or-hide"
                    >
                      {isReadMore[key] ? "...read more" : " show less"}
                    </span>
                  </label>
                  {value.showExtraText && isAgree[value.field] == 1 && (
                    <React.Fragment>
                      <label className="agree-label">
                        Do you have any medical conditions which we will be
                        aware of?
                      </label>
                      <Form.Group as={Col} md={12} sm={12}>
                        <Form.Check
                          label={"Yes"}
                          {...register("cnd_profile_medical", {
                            onChange: (e) => {
                              onChangeAgree(e);
                            },
                          })}
                          type="radio"
                          value={"yes"}
                          checked={isAgree.cnd_profile_medical == "yes" && true}
                          name={"cnd_profile_medical"}
                        />
                        <Form.Check
                          label={"No"}
                          {...register("cnd_profile_medical", {
                            onChange: (e) => {
                              onChangeAgree(e);
                            },
                          })}
                          type="radio"
                          value={"no"}
                          checked={isAgree.cnd_profile_medical == "no" && true}
                          name={"cnd_profile_medical"}
                        />
                      </Form.Group>
                      <label className="agree-label">
                        {value.extraTextLabel}
                      </label>
                      <textarea
                        name={value.extraFieldName}
                        type="text"
                        rows={30}
                        cols={5}
                        readOnly={isTextDisabled}
                        {...register(value.extraFieldName)}
                        placeholder="Please provide details about your Medical condition"
                        className="form-control"
                      ></textarea>
                      <div className="text-danger fs-12 error">
                        {errors && errors[value.extraFieldName]?.message}
                      </div>

                      <label className="agree-label">
                        Medical Health and Safety in the workplace and our duty
                        of care
                      </label>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          ))}

          <Button
            disabled={loader}
            type="submit"
            className="site-button m-b30 pull-right"
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader ? "Save & Continue" : "Loading..."}
          </Button>
        </form>
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    error: state.error.error,
    loader: state.loader.loader,
  };
};
export default connect(mapStateToProps)(ApplicationDeclaration);
