import React, { Component } from "react";
import Slider from "react-slick";
// image: require('./../../images/testimonials/pic1.jpg'),
// image: require('./../../images/testimonials/pic2.jpg'),

const postBlog = [
  {
    image: require("./../../images/testimonials/testimonial-1.jpg"),
    content:
      "Very well run and organised team. Easy to use web site. Louise always available to answer questions. I have regular work since last 4 months and happy to recommend.",
    owner: "Michael",
  },
  {
    // image: require('./../../images/testimonials/pic2.jpg'),
    image: require("./../../images/testimonials/pic2.jpg"),
    content:
      "Great to work for Staffing Connect. Jobs are easy to accept. Any queries they are answered swiftly. Once timesheets are completed and submitted, payroll makes weekly payments. ",
    owner: "Cathy Thomas",
  },
  {
    image: require("./../../images/testimonials/pic3.jpg"),
    content:
      "Really easy Sign-up process. DBS was done in 2 days. Booked onto shifts really quickly so started to earn straight away. Enjoyed the nursery assistant work. Very happy.",
    owner: "Laura Blundell",
  },
  {
    image: require("./../../images/testimonials/pic1.jpg"),
    content:
      "Fantastic flexible work options & get paid on weekly basis. Lots of variety of roles that you're matched to. No trawling through irrelevant jobs just the ones that meet your criteria.",
    owner: "Suzanne Brown",
  },
];

class owltestimonial extends Component {
  render() {
    var settings = {
      slidesToShow: 3,
      arrows: false,
      infinite: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <Slider
        className="blog-carousel-center owl-carousel owl-none "
        {...settings}
      >
        {postBlog.map((item, index) => (
          <div className="item p-3" key={index}>
            <div className="testimonial-5">
              <div className="testimonial-text">
                <p>{item.content}</p>
              </div>
              <div className="testimonial-detail clearfix">
                <div className="testimonial-pic radius shadow">
                  <img
                    src={item.image}
                    style={{ width: "100%", height: "auto" }}
                    alt=""
                  />
                  {/* <p width="100" height="100" ></p>  */}
                </div>
                <strong className="testimonial-name">{item.owner}</strong>
                {/* <span className="testimonial-position">Student</span>  */}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

export default owltestimonial;
