import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { redirectUrl } from "../../../services/GlobalUrls";
const TimeSheetsList = (props) => {
  const [timesheets, SetTimeSheets] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.timesheets.length;
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetTimeSheets(props.timesheets);
  }, [props.timesheets]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };

  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        {/* Search Start */}
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  id="myInput"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <table className="table table-striped table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              {/*<th  className="col-1 text-center" scope="col">Sr. No</th>*/}
              <th className="col-1 text-center" scope="col">
                Date & Time
              </th>
              <th className="col-3 text-center" scope="col">
                {" "}
                Name
              </th>
              {/*<th  className="col-2 text-center" scope="col">Email</th>*/}
              <th className="col-2 text-center" scope="col">
                Client Name
              </th>
              <th className="col-2 text-center" scope="col">
                Week Commencing
              </th>
              <th className="col-3 text-center" scope="col">
                Uploaded Timesheet
              </th>
              <th className="col-1 text-center" scope="col">
                Status
              </th>
              {/*<th  className="col-1 text-center" scope="col">Action</th>*/}
            </tr>
          </thead>
          <tbody id="myTable">
            {timesheets &&
              timesheets.length > 0 &&
              timesheets?.slice(startIndex, endIndex).map((timesheet, i) => (
                <tr key={i}>
                  {/*<td className="col-1 text-center" scope="row">
                    {i + 1}
              </td>*/}
                  <td className="col-4 text-center" scope="row">
                    {timesheet.cnd_timesheet_created_date}
                  </td>
                  <td className="col-3 text-center" scope="row">
                    {timesheet.fullname}
                  </td>
                  {/*<td  className="col-2" scope="col">{timesheet.users_email}</td>*/}
                  <td className="col-3 text-center" scope="row">
                    {timesheet.cnd_timesheet_client_name}
                  </td>
                  <td className="col-3 text-center" scope="row">
                    {timesheet.cnd_timesheet_week_commencing}
                  </td>
                  <td className="col-3 text-center" scope="col">
                    <a
                      rel="noopener noreferrer"
                      download
                      onClick={() =>
                        viewFileModal(
                          constants.IMAGE_URL +
                            timesheet.cnd_timesheet_folderpath +
                            timesheet.cnd_timesheet_upload_document
                        )
                      }
                      className={
                        "btn btn-" +
                        (timesheet.cnd_timesheet_upload_document.endsWith(
                          ".png"
                        ) ||
                        timesheet.cnd_timesheet_upload_document.endsWith(
                          ".jpg"
                        ) ||
                        timesheet.cnd_timesheet_upload_document.endsWith(
                          ".jpeg"
                        ) ||
                        timesheet.cnd_timesheet_upload_document.endsWith(".pdf")
                          ? "dark"
                          : "primary") +
                        " btn-sm text-white"
                      }
                    >
                      <i
                        className={
                          "fa fa-" +
                          (timesheet.cnd_timesheet_upload_document.endsWith(
                            ".png"
                          ) ||
                          timesheet.cnd_timesheet_upload_document.endsWith(
                            ".jpg"
                          ) ||
                          timesheet.cnd_timesheet_upload_document.endsWith(
                            ".jpeg"
                          ) ||
                          timesheet.cnd_timesheet_upload_document.endsWith(
                            ".pdf"
                          )
                            ? "eye" //"eye"
                            : "download")
                        }
                      ></i>
                    </a>
                  </td>
                  <td className="col-1 text-center" scope="col">
                    {" "}
                    {timesheet.cnd_timesheet_status}
                  </td>
                  {/*<td  className="col-1 text-center" scope="col"> <Link to={"/create-timesheet/"+timesheet.cnd_timesheet_id}><i className="fa fa-pencil"></i></Link></td>*/}
                </tr>
              ))}
            {timesheets.length == 0 && (
              <tr>
                <td colSpan="100">No timesheets Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
        {timesheets && timesheets.length > 0 && (
          <TableDropdownAndPagination
            handleDropDown={handleDropDown}
            recordLen={recordLen}
            handleLeft={handleLeft}
            handleRight={handleRight}
            renderPageNumbers={renderPageNumbers}
            isDropdown={true}
            isPaggination={true}
          />
        )}
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        // fileName={fileName}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"TimeSheet - Uploaded TimeSheet"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    timesheets: state.timesheets.timesheets,
  };
};

export default connect(mapStateToProps)(TimeSheetsList);
