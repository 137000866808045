import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Form, Button, Spinner } from "react-bootstrap";
import Error from "../../Layout/Error";
import { setError } from "../../../redux/actions/ErrorActions";
import { updateUserProfile } from "../../../redux/actions/UserProfileActions";
import { getDataOnRequest } from "../../../redux/actions/CommonActions";
import { useHistory } from "react-router-dom";

const FinalDeclaration = (props) => {
  const { errors } = props;
  const [formData, setFormdata] = useState({ cnd_declaration_isagree: 0 });
  const { loader } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const agreeDisagreeCondition = (field) => {
    if (formData[field] == "1") {
      formData[field] = "0";
    } else {
      formData[field] = "1";
    }
    setFormdata(formData);
  };

  const updateAgreement = (e) => {
    e.preventDefault();
    dispatch(setError([]));
    if (
      formData.cnd_declaration_isagree &&
      formData.cnd_declaration_isagree != "0"
    ) {
      document.getElementById("loader").style.display = "block";
      formData["users_access_token"] = props.userAuth.users_access_token;
      dispatch(updateUserProfile(formData, "updateFinalAgreement"));
      dispatch(
        getDataOnRequest(
          props.userAuth.users_access_token,
          "ProgressBar",
          "SET_PROGRESS"
        )
      );
      //history.push('/bank-details');
    } else {
      dispatch(
        setError({ cnd_declaration_isagree: "Please Accept Declaration." })
      );
    }
  };

  const checkIsAgreed = (field) => {
    if (typeof props.userData[field] != "undefined") {
      return props.userData[field] == "1";
    }
  };

  useEffect(() => {
    // window.scrollTo(0,0);
    setFormdata({
      cnd_declaration_isagree: props.userData.cnd_declaration_isagree,
    });
  }, [props.userData]);

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <form className="was-validated" noValidate id="scrollFocusElement">
        <div className="job-bx submit-resume">
          <div className="col-lg-12 col-md-12">
            <h5 className="font-weight-700 pull-left text-uppercase">
              Declaration
            </h5>
            <div className="form-group">
              <label>
                I have read the guidance notes for applicants that accompanied
                my application form, and I consent to the information provided
                in this declaration from being used by Care education Ltd
                trading as Staffing Connect T/A for the purpose of assessing my
                application. I confirm that the information that I have provided
                in the Declaration Form is correct and complete. I understand
                and accept that if I with hold information or provide false or
                misleading information, this may result in my application being
                rejected, or if I am appointed, in my dismissal.
              </label>
              <div
                className="col-lg-12 col-md-12 m-b0 "
                style={{ marginLeft: "-12px" }}
              >
                <div className="form-group">
                  <Form.Check
                    outline
                    label={"I Agree"}
                    onClick={(e) =>
                      agreeDisagreeCondition("cnd_declaration_isagree")
                    }
                    type="checkbox"
                    name="agreeCheck"
                    defaultChecked={checkIsAgreed("cnd_declaration_isagree")}
                  />
                </div>
              </div>
              <Error errors={errors} fieldName={"cnd_declaration_isagree"} />
            </div>
          </div>
          <Button
            disabled={loader}
            className="site-button m-b30 pull-right"
            onClick={(e) => updateAgreement(e)}
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader ? "Save & Continue" : "Loading..."}
          </Button>
          <br />
          <br />
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};
export default connect(mapStateToProps)(FinalDeclaration);
