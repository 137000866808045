import { main } from "react-devtools";
import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_care_short_aim",
    type: "text",
    label: "Short Aim",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_medium_aim",
    type: "text",
    label: "Medium Aim",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_long_aim",
    type: "text",
    label: "Long Aim",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_hours_per_week",
    type: "text",
    label: "Hour Per Week",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_specifued_to_cover",
    type: "text",
    label: "Specified To Cover",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_info_source",
    type: "text",
    label: "Source Info",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_medical_history",
    type: "textarea",
    label: "Medical History",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_current_diagnoses",
    type: "textarea",
    label: "Current Dignoses",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_medication",
    type: "textarea",
    label: "Medication",
    md: "6",
    sm: "12",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  clt_care_short_aim: Yup.string().required("Short Field Required"),
  clt_care_medium_aim: Yup.string().required("Medium Aim Field Required"),
  clt_care_long_aim: Yup.string().required("Long Aim Field Required"),
  clt_care_hours_per_week: Yup.number()
    .typeError("Enter Valid Per Week Value")
    .required("Hours Per Week Field Required"),
  clt_care_specifued_to_cover: Yup.string().required(
    "Specified To Cover Field Required"
  ),
  clt_care_info_source: Yup.string().required("Source Field Required"),
  clt_care_medical_history: Yup.string().required(
    "Medical History Field Required"
  ),
  clt_care_current_diagnoses: Yup.string().required(
    "Current Diagnoses Field Required"
  ),
  clt_care_medication: Yup.string().required("Medication Field Required"),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
