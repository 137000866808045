import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import composedDbs from "../../../containers/DBSDocumentContainer";
import DBSTable from "../Tables/DBSTable";
import DBSTableNo from "../Tables/DBSTableNo";
import { useEffect } from "react";
import DBSTableMobile from "../Tables/DBSTableMobile";
import DBSTableNoMobile from "../Tables/DBSTableNoMobile";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Speed Dial - Start
import Box from "@mui/material/Box";
import { redirectUrl } from "../../../services/GlobalUrls";
import { Tooltip } from "@mui/material";

const DBSlist = ({ deviceInfo, ...props }) => {
  const [value, setValue] = React.useState(0);
  const [viewToolTip, setViewToolTip] = useState(false);
  const [tabFocus, setTabFocus] = useState("dbs_certificate");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    let currentUrl = window.location.pathname;
    if(currentUrl == "/dbs-applied-document"){
      setTabFocus("applied_dbs_certificate");
    }else{
      setTabFocus("dbs_certificate");
    }
  },[])

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            DBS Documents
          </h5>

          {deviceInfo && deviceInfo == "Mobile" ? (
            <Tooltip
              title="Add DBS"
              className="text-sm"
              open={viewToolTip}
              placement="top"
            >
              <a
                href="/create-right-to-work"
                className="pull-right btn btn-success"
                onTouchStart={(e) => {
                  setViewToolTip(true);
                }}
                onTouchEnd={(e) => {
                  setViewToolTip(false);
                }}
              >
                <i className="fa fa-plus"></i>
              </a>
            </Tooltip>
          ) : (
            <a
              href="/create-dbs-document"
              className="pull-right btn btn-secondry site-button yellow btn-sm"
            >
              Add DBS
            </a>
          )}
        </div>

        {deviceInfo && deviceInfo == "Mobile" ? (
          <Box
            sx={{
              flexGrow: 1,
              // maxWidth: { xs: 320, sm: 480 },
              bgcolor: "background.paper",
            }}
            className="mb-3"
          >
            <Tabs
              value={window.location.pathname == "/dbs-document" ? 0 : 1}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
              className=""
            >
              <Tab
                label="DBS Certificates"
                style={{ fontSize: "80%" }}
                id="tab1"
                onClick={() => redirectUrl("/dbs-document")}
              />
              <Tab
                label="Applied for DBS Certificate with Staffing Connect"
                style={{ fontSize: "80%" }}
                id="tab2"
                onClick={() => redirectUrl("/dbs-applied-document")}
              />
            </Tabs>
          </Box>
        ) : (
          <>
          {/* <ul className="nav nav-tabs">
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a href="/dbs-document" className="nav-link " id="tab1">
                DBS Certificates
              </a>
            </li>
            <li className="nav-item" style={{ cursor: "pointer" }}>
              <a href="/dbs-applied-document" className="nav-link" id="tab2">
                Applied for DBS Certificate with Staffing Connect
              </a>
            </li>
          </ul> */}
          <ul
                  className="nav nav-tabs bg-dark p-1"
                  style={{
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "nav-link " +
                        (tabFocus == "dbs_certificate"
                          ? "text-primary active bold"
                          : "text-white")
                      }
                      id="tab1"
                      style={{ borderRadius: "50px" }}
                      onClick={() => redirectUrl('/dbs-document')}
                    >
                      DBS Certificates
                    </a>
                  </li>
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "nav-link " +
                        (tabFocus == "applied_dbs_certificate"
                          ? "text-primary active"
                          : "text-white")
                      }
                      id="tab2"
                      style={{ borderRadius: "50px" }}
                      onClick={() => redirectUrl('/dbs-applied-document')}
                    >
                      Applied for DBS Certificate with Staffing Connect
                    </a>
                  </li>
                </ul>
          </>
        )}

        <div className="tab-content mt-3">
          <div id="tabFirst" className="tab-pane fade show">
            {deviceInfo && deviceInfo == "Mobile" ? (
              <DBSTableMobile type={"yes"} />
            ) : (
              <DBSTable type={"yes"} />
            )}
          </div>
          <div id="tabSecond" className="tab-pane fade">
            {deviceInfo && deviceInfo == "Mobile" ? (
              <DBSTableNoMobile type={"no"} />
            ) : (
              <DBSTableNo type={"no"} />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default composedDbs(DBSlist);
