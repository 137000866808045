import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { redirectUrl } from "./GlobalUrls";
import { convertToSnakeCase } from "./StringOperation";

export default function SwipeableTemporaryDrawer({
  drawerMenuList,
  drawerDirection,
  drawerState,
  onDrawerStateChange,
}) {
  const [menuState, setMenuState] = useState(drawerState);
  const [state, setState] = React.useState({
    top: drawerDirection == "top" ? true : false,
    left: drawerDirection == "left" ? true : false,
    bottom: drawerDirection == "bottom" ? true : false,
    right: drawerDirection == "right" ? true : false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuState(open);
    onDrawerStateChange(open);
  };

  useEffect(() => {
    setMenuState(drawerState);
  }, [drawerState]);

  const list = (anchor) => (
    <>
      <Box
        sx={{
          width:
            drawerDirection === "top" || drawerDirection === "bottom"
              ? "auto"
              : 250,
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        className="text-white"
      >
        <List>
          {drawerMenuList.map((item, index) => (
            <>
              {item.divider == true && <Divider />}
              <ListItem
                key={index}
                disablePadding
                onClick={() => redirectUrl(item.url)}
                id={
                  "drawer_item_" +
                  (item.name ? convertToSnakeCase(item.name) : "")
                }
                onTouchStart={(event) => {
                  event.currentTarget.classList.add("bg-primary", "text-white");
                }}
                onTouchEnd={(event) => {
                  event.currentTarget.classList.remove(
                    "bg-primary",
                    "text-white"
                  );
                }}
              >
                <ListItemButton>
                  <i
                    className={item.icon + " mr-2 text-xl"}
                    style={{ fontSize: "20px" }}
                  ></i>
                  <ListItemText
                    primary={
                      item.name && item.name.length <= 30
                        ? item.name
                        : item.name
                          ? item.name.slice(0, 30) + "..."
                          : ""
                    }
                  />
                </ListItemButton>
              </ListItem>
            </>
          ))}
        </List>
      </Box>
    </>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor={drawerDirection}
        open={menuState}
        onClose={toggleDrawer(drawerDirection, false)}
        onOpen={toggleDrawer(drawerDirection, true)}
      >
        {list(drawerDirection)}
      </SwipeableDrawer>
    </div>
  );
}
