const initialState = {bankInfo: ""}

export const bankInfo = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_BANK_DATA':
      return {bankInfo: action.payload}; 
  	case 'UPDATE_BANK_DATA':
      return {bankInfo: action.payload}; 
  }
  return state
}


