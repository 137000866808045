import { main } from "react-devtools";
import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_care_practitioner_fname",
    type: "text",
    label: "First Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_practitioner_lname",
    type: "text",
    label: "Last Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_practitioner_phone_no",
    type: "text",
    label: "Phone Number",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_practitioner_email",
    type: "text",
    label: "Email",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_practitioner_address",
    type: "textarea",
    label: "Address",
    md: "6",
    sm: "12",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  clt_care_practitioner_fname: Yup.string().required(
    "First Name Field Required"
  ),
  clt_care_practitioner_lname: Yup.string().required(
    "Last Name Field Required"
  ),
  clt_care_practitioner_address: Yup.string()
    .required("Address Field Required")
    .min(10, "Please Provide Valid address"),
  clt_care_practitioner_email: Yup.string()
    .required("Email Field Required")
    .email("Please Enter Valid Email"),
  clt_care_practitioner_phone_no: Yup.string()
    .required("Phone Number Field Required")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Telephone Number"
    ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
