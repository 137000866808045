import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
import { Modal } from "react-bootstrap";

const dashboards = [
  {
    name: "Company Profile",
    link: "/company-details",
    icon: "fa fa-building-o",
    class: "blue",
  },
  {
    name: "Jobs",
    link: "job",
    icon: "fa fa-suitcase",
    class: "pink",
  },
  {
    name: "Assignments",
    link: "/assignments",
    icon: "fa fa-sticky-note-o",
    class: "yellow",
  },
  //   {
  //     name: "My Availability",
  //     link: "/availability",
  //     //link: "/dashboard",
  //     icon:"fa fa-calendar-check-o",
  //     class:'red'
  //   },
  //  {
  //     name: "My Payroll",
  //     link: "/dashboard",
  //     icon:"fa fa-address-card-o",
  //     class:'orange'
  //   },
  //   {
  //     name: "My Assignments",
  //     link: "/my-assignments",
  //     icon:"fa fa-sticky-note-o",
  //     class:'green'
  //   },
  //   {
  //     name: "Jobs Applied",
  //     link: "/dashboard",
  //     icon:"fa fa-check-square-o",
  //     class:'navyblue'
  //   },
  //   {
  //     name: "Jobs Available",
  //     link: "/dashboard",
  //     icon:"fa fa-bullhorn",
  //     class:'black'
  //   },
];
const Dashboard = (props) => {
  const [show, setShow] = useState(false);
  // const notify = () => toast("Coming Soon!", { autoClose: 3000 });
  // console.log(show)

  const handleLink = (e, link) => {
    // e.preventDefault();
    let dash = "/dashboard";
    console.log(dash);
    if (link == dash) {
      console.log("hii");
      setShow(true);
      // notify()
      // console.log(show)
    }
  };
  const handleClose = () => setShow(false);
  return (
    <React.Fragment>
      <div className="profile-modal">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Coming Soon!</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body></Modal.Body> */}
        </Modal>
      </div>

      <div className="job-categories bg-white">
        <div className="container">
          <div className="row">
            {dashboards.map((dashboard, key) => (
              <Link
                to={dashboard.link}
                onClick={(e) => handleLink(e, dashboard.link)}
                key={key}
                className="col-lg-3 col-md-6 col-sm-6"
              >
                <div className={"icon-bx-wraper " + dashboard.class}>
                  <div className="icon-content">
                    <div className="icon-md text-primary m-b20">
                      <i className={dashboard.icon}></i>
                    </div>
                    <p className="m-a0">{dashboard.name}</p>
                    <div className="rotate-icon">
                      <i className={dashboard.icon}></i>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
