import React from 'react';
import Profilesidebar from '../components/Element/ClientSidebar';
import StepWizard from 'react-step-wizard';
import Nav from '../components/Element/CarePlan2/Nav';
import LifeStory from '../components/Element/CarePlan2/LifeStory';
import PersonalDevelopement from '../components/Element/CarePlan2/PersonalDevelopement';
const CarePlan2 = (props) => {
	return (
		<React.Fragment>
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								<Profilesidebar page="care-plan-next" />
								<div className="col-xl-9 col-lg-8 m-b30">
									<StepWizard nav={<Nav />} isLazyMount={true}>
										<LifeStory />
										<PersonalDevelopement />
									</StepWizard>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}


export default CarePlan2;