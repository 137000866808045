import {
  getRequestData,
  postRequestData,
  deleteRequestData,
} from "../../middleware/GetRequestData";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { useState } from "react";
import { redirectUrl } from "../../services/GlobalUrls";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
export const SET_ERROR = "SET_ERROR";
let homeCare1, homeCare2;
const setInfo = (content, type) => {
  return {
    type: type,
    payload: content.data.data,
  };
};

const failure = (content) => {
  return {
    type: SET_ERROR,
    payload: content,
  };
};

export const getDataOnRequest = (userToken, action, type, id = "") => {
  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const data = await getRequestData(action, id, userToken);
      if (data.data.status == "success") {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(setInfo(data, type));        
      } else {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(failure(data, type));
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failure({ msg: "Invalid Request" }));
    }
  };
};

export const deleteDataOnRequest = (
  submitData,
  action,
  type,
  successDispatchAction = {},
  params = ""
) => {
  toast.dismiss();
  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const data = await deleteRequestData(action, submitData, params);
      if (data.data.status == "success") {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(successDispatchAction);
        dispatch(setInfo(data, type));
      } else {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(failure(data, type));
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failure({ msg: "Invalid Request" }));
    }
  };
};

export const updateDataOnRequest = (
  submitData,
  action,
  type,
  successDispatchAction = {},
  params = ""
) => {
  toast.dismiss();
  return (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const data = postRequestData(action, submitData, params)
        .then((response) => {
          if (response.data.status == "success") {
            document.getElementById("loader").style.display = "none";
            // console.log("Demo OTP Success!"+action);
            if (action == "VerifyOTP") {
              swal({
                icon: "success",
                title: response.data.msg,
                text: "Thank you for joining Staffing Connect \nPlease login using your Credentials!",
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then((checkProf) => {
                localStorage.clear();
                window.location.href = "/login";
              });
              // console.log("Demo OTP Success!"+action);
            } else if (action == "ResendOTP") {
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                // buttons:{
                //   Ok:{
                //     checkProf:true
                //   }
                // },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then(
                (document.getElementById("loader").style.display = "none")
              );
            } else if (action == "ForgotPassword") {
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then((checkProf) => {
                window.location.href = "/forgot-password-otp";
                document.getElementById("loader").style.display = "none";
              });
            } else if (action == "ForgotResendOTP") {
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                // buttons:{
                //   Ok:{
                //     checkProf:true
                //   }
                // },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              });
              document.getElementById("loader").style.display = "none";
            } else if (action == "VerifyForgotOTP") {
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then((checkProf) => {
                document.getElementById("loader").style.display = "none";
                // localStorage.clear();
                window.location.href = "/new-password";
              });
            } else if (action == "NewPassword") {
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then((checkProf) => {
                localStorage.clear();
                document.getElementById("loader").style.display = "none";
                window.location.href = "/login";
              });
            } else if (action == "ContactUs") {
              swal({
                icon: "success",
                title: response.data.title,
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then((checkProf) => {
                localStorage.clear();
                document.getElementById("loader").style.display = "none";
                window.location.href = "/";
              });
            } else if (action == "ChangePassword") {
              swal({
                icon: "success",
                title: response.data.title ? response.data.title : "",
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then((checkProf) => {
                localStorage.clear();
                document.getElementById("loader").style.display = "none";
                window.location.href = "/login";
              });
            } else if (action == "SendFeedback") {
              swal({
                icon: "success",
                title: response.data.title ? response.data.title : "",
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then((checkProf) => {
                localStorage.setItem("feedbackDetails", "true");
                document.getElementById("loader").style.display = "none";
                window.location.href = "";
              });
            } else if (action == "VerifyPassword") {
              if (response.data.data == true) {
                swal({
                  icon: "warning",
                  title: response.data.title ? response.data.title : "",
                  text: response.data.msg,
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                }).then((checkProf) => {
                  // localStorage.setItem('feedbackDetails','');
                  // localStorage.setItem('usersDetails',JSON.stringify(response.data.data));
                  document.getElementById("loader").style.display = "none";
                  window.location.href = "/cancel-assignments";
                });
              } else {
                swal({
                  icon: "success",
                  title: response.data.title ? response.data.title : "",
                  text: response.data.msg,
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                }).then((checkProf) => {
                  localStorage.setItem("feedbackDetails", "");
                  localStorage.setItem(
                    "usersDetails",
                    JSON.stringify(response.data.data)
                  );
                  document.getElementById("loader").style.display = "none";
                  window.location.href = "/delete-account-otp-verification";
                });
              }
            } else if (action == "DeleteAccountResendOTP") {
              swal({
                icon: "success",
                title: response.data.title ? response.data.title : "",
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              });
            } else if (action == "VerifyDeleteAccountOTP") {
              swal({
                icon: "success",
                title: response.data.title ? response.data.title : "",
                text: response.data.msg,
                // timer:"2000",
                dangerMode: true,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside: true,
              }).then((checkProf) => {
                localStorage.clear();
                document.getElementById("loader").style.display = "none";
                window.location.href = "/acount-deletion-proceed";
              });
            } else if (action == "CancelAssignments") {
              if (response.data.data) {
                swal({
                  icon: "success",
                  title: response.data.title ? response.data.title : "",
                  text: response.data.msg,
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                }).then((checkProf) => {
                  localStorage.setItem("cancelAllAssignments", "true");
                  document.getElementById("loader").style.display = "none";
                  window.location.href = "/delete-account-otp-verification";
                });
              } else {
                swal({
                  icon: "success",
                  title: response.data.title ? response.data.title : "",
                  text: response.data.msg,
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                }).then((checkProf) => {
                  localStorage.setItem("cancelAllAssignments", "");
                  document.getElementById("loader").style.display = "none";
                  window.location.href = "";
                });
              }
            } else if (action == "DeleteWorkPreferenceQualificationDocument") {
              if (response.data.data) {
                swal({
                  icon: "success",
                  title: response.data.title ? response.data.title : "",
                  text: response.data.msg,
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                }).then((checkProf) => {
                  document.getElementById("loader").style.display = "none";
                  window.location.href = "";
                });
              } else {
                swal({
                  icon: "success",
                  title: response.data.title ? response.data.title : "",
                  text: response.data.msg,
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                }).then((checkProf) => {
                  document.getElementById("loader").style.display = "none";
                  window.location.href = "";
                });
              }
            } else if (action == "UpdateWorkPreferences") {
              if (response.data.data) {
                successToast(response.data.msg);
                let interval = setInterval(myUrl, 2000);
                function myUrl() {
                  if (localStorage.getItem("fltMenu") == "true") {
                    window.location = "/flt-license";
                  } else if (localStorage.getItem("driverMenu") == "true") {
                    window.location = "/driver-details";
                  } else {
                    window.location = "/employment-and-professional-references";
                  }
                  clearInterval(interval);
                }
              }
            } else if (action == "UpdateFltLicense") {
              if (response.data.data) {
                // successToast(response.data.msg);
                let interval = setInterval(myUrl, 2000);
                function myUrl() {
                  if (localStorage.getItem("driverMenu") == "true") {
                    window.location = "/driver-details";
                  } else {
                    window.location = "/employment-and-professional-references";
                  }
                  clearInterval(interval);
                }
              }
            } else if (action == "UpdateDriverDetails") {
              if (response.data.data) {
                // successToast(response.data.msg);
                let interval = setInterval(myUrl, 2000);
                function myUrl() {
                  window.location = "/employment-and-professional-references";
                  clearInterval(interval);
                }
              }
            } else if (action == "CustomerImageUpdate") {
              // For Profile Picture Update
              if (response.data.data) {
                // successToast(response.data.msg);
                let interval = setInterval(myUrl, 2000);
                function myUrl() {
                  window.location = "";
                  clearInterval(interval);
                }
              }
            }else if (action == "UpdateUserSign") {
              if (response.data.data) {
                swal({
                  icon: "success",
                  title: "Congratulations",
                  text: "You have successfully uploaded your application, once approved you can start accepting Temporary Assignments.",
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                }).then((checkProf) => {
                  document.getElementById("loader").style.display = "none";
                  window.location.href = "/wait-for-approval";
                });
              } 
            }else if (action == "ApplyJobsDirectly") {
              if (response.data.data && response.data.status == "success") {
                swal({
                  icon: response.data.status,
                  title: response.data.title,
                  text: response.data.msg,
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                }).then((checkProf) => {
                  window.location.href = "/job-board";
                });
              } 
            }

            dispatch({ type: "SET_LOADER", payload: false });
            dispatch({ type: "SWITCH_TO_NEXT", payload: true });
            if (action != "ApplyJobsDirectly" &&
              action != "CancelAssignments" &&
              action != "VerifyDeleteAccountOTP" &&
              action != "DeleteAccountResendOTP" &&
              action != "VerifyPassword" &&
              action != "SendFeedback" &&
              action != "ChangePassword" &&
              action != "ContactUs" &&
              action != "NewPassword" &&
              action != "VerifyForgotOTP" &&
              action != "ForgotResendOTP" &&
              action != "ForgotPassword" &&
              action != "ResendOTP" &&
              action != "UpdateUserSign" &&
              action != "updateDocumentCompliance" &&
              window.location.pathname != "/bank-details" &&
              window.location.pathname != "/work-prefrences" &&
              action != "VerifyOTP"
            ) {
              successToast(response.data.msg);
              dispatch(successDispatchAction);
            }

            if (action == "updateDocumentCompliance") {
              successToast("Document Saved Successfully");
              dispatch(successDispatchAction);
            }

            //Redirection Code
            // window.location.href = "/login";
            let interval = setInterval(myUrl, 2000);
            function myUrl() {
              if (action == "SaveTimeSheet") {
                window.location.href = "/timesheet";
              } else if (action == "updateRightToWork") {
                window.location.href = "/right-to-work";
              } else if (action == "UpdateDBSDocuments") {
                // console.log("DBS All Data :=>"+JSON.stringify(submitData[0].cnd_dbs_type));
                if (submitData[0].cnd_dbs_type == "yes") {
                  window.location.href = "/dbs-document";
                } else {
                  window.location.href = "/dbs-applied-document";
                }
              } else if (action == "updateIdentityDoc") {
                window.location.href = "/identity-document";
              } else if (action == "UpdateEmployementData") {
                homeCare1 = submitData.includes("home care");
                homeCare2 = submitData.includes("homecare");
                //console.warn("Reference Progress Details Are :-"+ homeCare);
                if (homeCare1 == true) {
                  window.location.href = "/type-of-services";
                } else if (homeCare2 == true) {
                  window.location.href = "/type-of-services";
                } else {
                  window.location.href = "/bank-details";
                }
              } else if (action == "UpdatetypesOfServices") {
                window.location.href = "/bank-details";
              } else if (action == "SaveDailyLog") {
                window.location.href = "/daily-logs";
              } else if (action == "updateDocumentCompliance") {
                // window.location.href = "/create-document-address2";
                // console.log("Documents Reuqired :=> "+ submitData[0].cnd_compliance_doc_master_id);
                if (submitData[0].cnd_compliance_doc_master_id == "address1") {
                  window.location.href = "/create-document-address2";
                } else if (
                  submitData[0].cnd_compliance_doc_master_id == "address2"
                ) {
                  window.location.href = "/create-document-national-insurance";
                } else if (
                  submitData[0].cnd_compliance_doc_master_id ==
                  "nationalinsurance"
                ) {
                  window.location.href =
                    "/create-document-training-certificate";
                } else if (
                  submitData[0].cnd_compliance_doc_master_id == "training"
                ) {
                  window.location.href = "/create-document-additional";
                } else if (
                  submitData[0].cnd_compliance_doc_master_id == "additional"
                ) {
                  window.location.href = "/document-required";
                }
              }
              clearInterval(interval);
            }
          } else {
            dispatch({ type: "SET_LOADER", payload: false });

            document.getElementById("loader").style.display = "none";
            if (action == "VerifyPassword") {
              swal({
                icon: "error",
                title: response.data.title ? response.data.title : "",
                text: response.data.msg,
                buttons: {
                  Ok: {
                    checkProf: true,
                  },
                },

                closeOnClickOutside: true,
              }).then((checkProf) => {
                localStorage.setItem("feedbackDetails", "true");
                document.getElementById("loader").style.display = "none";
                window.location.href = "";
              });
            }else if (action == "UpdateEmployementData") {
              if (response.data.status == "warning") {
                swal({
                  icon: response.data.status,
                  title: response.data.title,
                  text: response.data.msg,
                  // timer:"2000",
                  dangerMode: true,
                  buttons: {
                    Ok: {
                      checkProf: true,
                    },
                  },
                  //defeat:true,
                  //buttons:false,
                  closeOnClickOutside: true,
                })
              } 
            } else {
              if(response.data.status == "error" || response.data.status == "failed"){
                swal({
                  icon: "error",
                  title: response.data.title,
                  text: response.data.msg,
                });
              }
            }
            // if(action == "VerifyPassword"){
            //   window.location.href = "";
            // }
            /*if(action != "UpdateDBSDocuments"){
            errorToast(response.data.msg);
          }*/

            //Redirection Code
            /*if(action == "SaveTimeSheet"){
            let interval = setInterval(myUrl, 2000);
            function myUrl(){
              window.location.reload();
              clearInterval(interval);
            }
          }*/
          }
        })
        .catch((error) => {
          dispatch({ type: "SET_LOADER", payload: false });
          dispatch(failure(type));
        });
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failure({ msg: "Invalid Request" }));
    }
  };
};

export const emptyAction = (type) => {
  return (dispatch) => {
    dispatch({ type: type, payload: {} });
  };
};

const successToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const errorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
