import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_care_service_fname",
    type: "text",
    label: "First Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_service_lname",
    type: "text",
    label: "Last Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_service_preferred_name",
    type: "text",
    label: "Preffered Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_service_address",
    type: "textarea",
    label: "Address",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_service_postcode",
    type: "text",
    label: "Postal Code",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_service_phone_no",
    type: "text",
    label: "Phone Number",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_service_gender",
    type: "dropdown",
    label: "Gender",
    md: "6",
    sm: "12",
    options: [
      { id: "", name: "Select Gender" },
      { id: "male", name: "Male" },
      { id: "female", name: "Female" },
      { id: "other", name: "Other" },
    ],
  },
  {
    name: "clt_care_service_material_status",
    type: "dropdown",
    label: "Marital Status",
    md: "6",
    sm: "12",
    options: [
      { id: "", name: "Select Marital Status" },
      { id: "single", name: "Single" },
      { id: "married", name: "Married" },
      { id: "seprate", name: "Seprate" },
    ],
  },
  {
    name: "clt_care_service_ethnicity",
    type: "text",
    label: "Ethnicity",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_service_dob",
    type: "date",
    label: "DOB",
    md: "6",
    sm: "12",
    max: new Date(new Date().setFullYear(new Date().getFullYear() - 0))
      .toISOString()
      .split("T")[0],
  },
  {
    name: "clt_care_service_faith",
    type: "text",
    label: "Faith",
    md: "6",
    sm: "12",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  clt_care_service_fname: Yup.string().required("First Name Field Required"),
  clt_care_service_lname: Yup.string().required("Last Name Field Required"),
  clt_care_service_preferred_name: Yup.string().required(
    "Preffered Name Field Required"
  ),
  clt_care_service_address: Yup.string()
    .required("Address Field Required")
    .min(10, "Please provide detailed Address"),
  clt_care_service_postcode: Yup.string().required(
    "Postal Code Field Required"
  ),
  clt_care_service_ethnicity: Yup.string().required("Ethnicity Field Required"),
  clt_care_service_faith: Yup.string().required("Faith Field Required"),
  clt_care_service_gender: Yup.string().required("Gender Field Required"),
  clt_care_service_dob: Yup.string()
    .required("DOB Field Required")
    .max(yesterday, "Date Should be less than Today"),
  clt_care_service_material_status: Yup.string().required(
    "Marital Status Field Required"
  ),
  clt_care_service_phone_no: Yup.string()
    .required("Postal Code Field Required")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Telephone Number"
    ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
