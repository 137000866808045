import { IdentityDocumentList } from "../../../helpers/DocumentsList";
import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "cnd_identity_doc_master_id",
    type: "dropdown",
    label: "Document Name",
    md: "6",
    options: IdentityDocumentList,
    sm: "6",
  },
  {
    name: "cnd_identity_doc_upload_name",
    type: "file",
    label: "Upload File",
    md: "6",
    sm: "6",
  },
  {
    name: "cnd_identity_doc_exp_date",
    type: "date",
    label: "Expiry Date",
    md: "6",
    sm: "6",
    min: new Date().toISOString().split("T")[0],
  },
  {
    name: "cnd_identity_doc_reference",
    type: "text",
    label: "Document Reference",
    md: "6",
    sm: "6",
  },
  {
    name: "cnd_identity_doc_description",
    type: "textarea",
    label: "Document Description",
    md: "12",
    sm: "12",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  cnd_identity_doc_master_id: Yup.string().required(
    "Please Select Document Name"
  ),
  cnd_identity_doc_reference: Yup.string()
    .trim("")
    .required("Document Reference Is Required")
    .matches(/^[0-9a-zA-Z \b]+$/, "Special Characters not allowed!"),
  cnd_identity_doc_exp_date: Yup.string()
    .required("Document Expiry Date is Required")
    .matches(
      /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
      "Please slect the date from calendar in DD-MM-YYYY format!"
    ),
  cnd_identity_doc_description: Yup.string(), //.required("Document Description Required"),
  cnd_identity_doc_upload_name: Yup.mixed()
    .required("File is required")
    .test("checkFile", "File Required", (value) => {
      if (value.length == 0) return false;
      else return true;
    }),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
