export const urls={
    Auth:'login/verifyuser',
    ContactUs:'contact-us/store',
    ForgotPassword:'forgot_password/verify-password',
    ForgotResendOTP:'forgot_password/resend-otp',
    VerifyForgotOTP:'forgot_password/otp-verification',
    NewPassword:'forgot_password/new-password',
    ChangePassword: 'change-password/store',
    // Jobs Details - Start
    GetJobsDetails:'jobs/fetch-jobs',
    GetJobsCandidateAppliedDetails: 'jobs/fetch-jobs/',
    GetIndividualJobsDetails:'jobs/fetch-jobs/',
    GetJobsScreeningQuestions:'jobs/fetch-jobs-screening-questions/',
    ApplyJobsDirectly: 'jobs/apply-jobs-directly',
    // Jobs Details - End
    // Daily Logs Start
    SaveDailyLog: 'daily-logs/store',
    GetDailyLogs: '/daily-logs/listing/',
    GetDailyLogsServiceUsersList: '/daily-logs/assign-service-users-list/',
    // Daily Logs End
    // Delete My Account Start
    SendFeedback: 'delete-my-account/delete-feedback',
    VerifyPassword: 'delete-my-account/verify-password',
    DeleteAccountResendOTP: 'delete-my-account/resend-otp',
    VerifyDeleteAccountOTP: 'delete-my-account/verify-delete-account-otp',
    CancelAssignments: 'delete-my-account/cancel-assignments',
    // Delete My Account Stop
    Signup:'registers/store',
    VerifyOTP:'registers/otp-verification',
    ResendOTP:'registers/resend-otp',
    PersonInfo:'candidate/fetch-personal-details/',
    PersonalPermanentInfo:'candidate/fetch-permanent-candidate-details/',//For Permanent Candidates
    UpdatePersonalInfo:'candidate/personal-details',
    SetWorkAndQualification: '/candidate-profile/showCandidateJobPreferencesDetails/',
    UpdateCandidateAboutYou:'candidate-profile/updateCandidateAboutYouDetails',
    UpdateJobCandidateCV: 'jobs/update-candidate-cv',
    UpdateJobPreferenceDetails: 'candidate-profile/updateCandidateJobPreferencesDetails',
    UpdatePersonalPermanentInfo: 'candidate/permanent-personal-details',
    GetAppliedJobs: 'candidate/permanent-jobs-list/',
    AuthAPI:'login/authenticate',
    UserExperience:'references/show/',
    RefrenceUpdate:'candidate/reference-details',
    BankDetails:'candidate/fetch-bank-details/',
    UpdateBankInfo:'candidate/bank-details/',
    TrainingDetail:'candidate/fetch-personal-details/',
    UpdateTrainingInfo:'candidate/training-qualifications',
    UpdatetypesOfServices:'candidate/types-of-service',
    //UpdateEmployementData:'/candidate/reference-details',
    UpdateEmployementData:'reference/reference-details',
    updateIntrestAgreement:'candidate/applicant-assents',
    updateRehabitationData:'candidate/rehabilitation-of-offenders',
    updateFinalAgreement:'/candidate/final-declaration',
    updateEducationGap:'/candidate/employment-gaps',
    GetExperienceGapData:'/candidate/fetch-employment-gaps/',
    updateRightToWork:'/righttowork/store',
    SetRightToWork:'/righttowork/show/',
    SetRightToWorks:'/righttowork/listing/',
    SetDocumentCompliance:'/candidate/fetch-compliance-document/',
    SetIdentityDocument:'/identity/show/',
    SetIdentityDocuments:'/identity/listing/',
    updateIdentityDoc:'/identity/store',
    updateDocumentCompliance:'/candidate/compliance-document',
    SetWorkPrefrences: window.location.pathname == "/sign-contract" ? '/candidate/selected-job-title/' : '/candidate/fetch-work-preference/',
    GetWorkPrefrencesJobTitles:'/candidate/work-preference-job-title/',
    GetWorkPrefrencesQualificationDocuments: '/workpreference/qualification-documents/',
    DeleteWorkPreferenceQualificationDocument: '/workpreference/delete-qualification-documents/',
    UpdateWorkPreferences:'/candidate/work-preference/',
    SetFltLicense: '/candidates/fetch-flt-license/',
    UpdateFltLicense:'/candidates/update-flt-license/',
    SetDriverDetails: '/candidates/fetch-driver-details/',
    UpdateDriverDetails:'/candidates/update-driver-details/',
    UploadResume:'/candidate/upload-resume',
    CustomerImageUpdate:'/candidate/upload-photo/',
    ClientImageUpdate:'/upload-photo/store',
    GetClientImage:'/upload-photo/show/',
    ClientProfileGet:'/clients/fetch-company-details/',
    BillingInfo:'/candidate/fetch-work-preference/',
    UpdateBillingInfo:'/clients/billing-details',
    GetDBSDocuments: window.location.pathname == "/dbs-applied-document" ? '/dbscertificatedeclaration/listing-no/' : '/dbscertificatedeclaration/listing/',
    // GetDBSDocumentsNo: '/dbscertificatedeclaration/listing-no/',
    GetDBSDocument:'/dbscertificatedeclaration/show/',
    UpdateDBSDocuments:'/dbscertificatedeclaration/store/',
    DeleteRightToWork:'/candidate/delete-right-to-work/',
    DeleteDocumentCompliance:'/candidate/delete-compliance-document/',
    DeleteEmployementGap:'/candidate/delete-employment-gaps/',
    UpdateCompanyDetails:'/company-details/store',
    GetCompanyDetails:'/company-details/show/',
    UpdateJobs:'/jobs/store',
    GetJob:'/jobs/show/',
    GetJobs:'/jobs/listing/',
    UpdateAppoinment:'/assessment-schedule/store',
    GetAppoinment:'/assessment-schedule/show/',
    GetCarePlan:'/careplan/show/',
    SaveCarePlan:'/careplan/service_store',
    SaveNextToKin:'/careplan/nxt_of_kin',
    SaveEmergencyContact:'/careplan/emergency',
    SaveCareManager:'/careplan/care-manager',
    SaveGenralPractitioner:'/careplan/general-practitioner',
    SaveCareCordinator:'/careplan/case-coordinate',
    SaveSocialService:'/careplan/commissioner-manager',
    SaveLpa:'/careplan/lpa',
    SaveStory:'/careplan/story',
    SavePhysicalInfo:'/careplan/physical',
    SaveNurse:'/careplan/nurse',
    SaveOtherManager:'/careplan/other-manager',
    SaveServiceAims:'/careplan/service-aims',
    GetChangeRate:'/charge-details/show/',
    SaveChangeRate:'/charge-details/store/',
    GetChangeRateTable:'/charge-details/fetch/',
    SaveAssignmentData:'/shifts/store',
    SaveIndividualRiskProfile:'/careplan/individual-risk-profile',
    GetIndividualRisk:'/careplan/fetch-risk-profile/',
    GetIndividualRiskProfileMaster:'/careplan/fetch-individual-risk/',
    GetGetCarePlans:'//careplan/show/',
    GetAssesmentMaster:'/careplan/fetch-assessment-master/',
    SaveSupportPlan:'/careplan/support-plan',
    GetAssesmentProfile:'/careplan/fetch-assessment-profile/',
    SaveAssesmentProfile:'/careplan/assessment-profile',
    GetShiftData:'/shifts/show/',
    SaveAvaibilityStatus:'/availability/store',
    GetAvaibilityStatus:'/availability/show/',
    GetHolidays:'/holidays/listing/',
    SaveHoliday:'/holidays/store',
    GetHoliday:'/holidays/show/',
    GetTimeSheets:'/timesheet/listing/',
    SaveTimeSheet:'/timesheet/store',
    GetTimeSheet:'/timesheet/show/',
    GetBranch:'/branch/show/',
    GetBranches:'/branch/listing/',
    SaveBranch:'/branch/store',
    ProgressBar:'/progress-bar/show/',
    UpdateUserSign:'/user-sign/store/',
    //?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII'

    GetMyAssignments:'/myassignment/listing/',

}

/*
//Old Plesk Api Routes

Auth:'api/login/verifyuser',
    Signup:'api/registers/store',
    PersonInfo:'api/candidate/fetch-personal-details/',
    UpdatePersonalInfo:'api/candidate/personal-details',
    AuthAPI:'api/login/authenticate',
    UserExperience:'api/references/show/',
    RefrenceUpdate:'api/candidate/reference-details',
    BankDetails:'api/candidate/fetch-bank-details/',
    UpdateBankInfo:'api/candidate/bank-details/',
    TrainingDetail:'api/candidate/fetch-personal-details/',
    UpdateTrainingInfo:'api/candidate/training-qualifications',
    UpdatetypesOfServices:'api/candidate/types-of-service',
    UpdateEmployementData:'/api/candidate/reference-details',
    updateIntrestAgreement:'api/candidate/applicant-assents',
    updateRehabitationData:'api/candidate/rehabilitation-of-offenders',
    updateFinalAgreement:'/api/candidate/final-declaration',
    updateEducationGap:'/api/candidate/employment-gaps',
    GetExperienceGapData:'/api/candidate/fetch-employment-gaps/',
    updateRightToWork:'/api/righttowork/store',
    SetRightToWork:'/api/righttowork/show/',
    SetRightToWorks:'/api/righttowork/listing/',
    SetDocumentCompliance:'/api/candidate/fetch-compliance-document/',
    SetIdentityDocument:'/api/identity/show/',
    SetIdentityDocuments:'/api/identity/listing/',
    updateIdentityDoc:'/api/identity/store',
    updateDocumentCompliance:'/api/candidate/compliance-document',
    SetWorkPrefrences:'/api/candidate/fetch-work-preference/',
    UpdateWorkPreferences:'/api/candidate/work-preference/',
    UploadResume:'/api/candidate/upload-resume',
    CustomerImageUpdate:'/api/candidate/upload-photo/',
    ClientImageUpdate:'/api/upload-photo/store',
    GetClientImage:'/api/upload-photo/show/',
    ClientProfileGet:'/api/clients/fetch-company-details/',
    BillingInfo:'/api/candidate/fetch-work-preference/',
    UpdateBillingInfo:'/api/clients/billing-details',
    GetDBSDocuments:'/api/dbscertificatedeclaration/listing/',
    GetDBSDocument:'/api/dbscertificatedeclaration/show/',
    UpdateDBSDocuments:'/api/dbscertificatedeclaration/store/',
    DeleteRightToWork:'/api/candidate/delete-right-to-work/',
    DeleteDocumentCompliance:'/api/candidate/delete-compliance-document/',
    DeleteEmployementGap:'/api/candidate/delete-employment-gaps/',
    UpdateCompanyDetails:'/api/company-details/store',
    GetCompanyDetails:'/api/company-details/show/',
    UpdateJobs:'/api/jobs/store',
    GetJob:'/api/jobs/show/',
    GetJobs:'/api/jobs/listing/',
    UpdateAppoinment:'/api/assessment-schedule/store',
    GetAppoinment:'/api/assessment-schedule/show/',
    GetCarePlan:'/api/careplan/show/',
    SaveCarePlan:'/api/careplan/service_store',
    SaveNextToKin:'/api/careplan/nxt_of_kin',
    SaveEmergencyContact:'/api/careplan/emergency',
    SaveCareManager:'/api/careplan/care-manager',
    SaveGenralPractitioner:'/api/careplan/general-practitioner',
    SaveCareCordinator:'/api/careplan/case-coordinate',
    SaveSocialService:'/api/careplan/commissioner-manager',
    SaveLpa:'/api/careplan/lpa',
    SaveStory:'/api/careplan/story',
    SavePhysicalInfo:'/api/careplan/physical',
    SaveNurse:'/api/careplan/nurse',
    SaveOtherManager:'/api/careplan/other-manager',
    SaveServiceAims:'/api/careplan/service-aims',
    GetChangeRate:'/api/charge-details/show/',
    SaveChangeRate:'/api/charge-details/store/',
    GetChangeRateTable:'/api/charge-details/fetch/',
    SaveAssignmentData:'/api/shifts/store',
    SaveIndividualRiskProfile:'/api/careplan/individual-risk-profile',
    GetIndividualRisk:'/api/careplan/fetch-risk-profile/',
    GetIndividualRiskProfileMaster:'/api/careplan/fetch-individual-risk/',
    GetGetCarePlans:'/api//careplan/show/',
    GetAssesmentMaster:'/api/careplan/fetch-assessment-master/',
    SaveSupportPlan:'/api/careplan/support-plan',
    GetAssesmentProfile:'/api/careplan/fetch-assessment-profile/',
    SaveAssesmentProfile:'/api/careplan/assessment-profile',
    GetShiftData:'/api/shifts/show/',
    SaveAvaibilityStatus:'/api/availability/store',
    GetAvaibilityStatus:'/api/availability/show/',
    GetHolidays:'/api/holidays/listing/',
    SaveHoliday:'/api/holidays/store',
    GetHoliday:'/api/holidays/show/',
    GetTimeSheets:'/api/timesheet/listing/',
    SaveTimeSheet:'/api/timesheet/store',
    GetTimeSheet:'/api/timesheet/show/',
    GetBranch:'/api/branch/show/',
    GetBranches:'/api/branch/listing/',
    SaveBranch:'/api/branch/store',
    ProgressBar:'/api/progress-bar/show/',
    UpdateUserSign:'/api/user-sign/store/',
    //?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII'


*/