import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ChargeRateForm from "./ChargeRateForm";
import ChargeRateTable from "./ChargeRateTable";
const ChargeRate = (props) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Charge Rate
          </h5>
        </div>
        <ChargeRateTable />
        <hr />
        <ChargeRateForm />
      </div>
    </React.Fragment>
  );
};

export default ChargeRate;
