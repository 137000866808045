const initialState = {rightToWork: []}

export const rightToWork = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_RIGHT_TO_WORK':
      return {rightToWork: action.payload}; 
  }
  return state
}


