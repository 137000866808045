import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const RightToWorkContainer = (OriginalComponent) => {

  const NewComponent = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token){
        (params.id) && 
          dispatch(getDataOnRequest(props.userAuth.users_access_token,'SetRightToWork','SET_RIGHT_TO_WORK',params.id))
      }
    },[props.userAuth.users_access_token,params.id])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    rightToWork:state.rightToWork.rightToWork,
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData
  };
};

const composedRightToWork = compose(connect(mapStateToProps), RightToWorkContainer)
export default composedRightToWork;


