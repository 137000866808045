import React,{useEffect} from 'react';
import {  Redirect,Route, withRouter } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";

import { isAuthenticated } from "../redux/selectors/AuthSelectors";

import PageHeader from "../components/Layout/PageHeader";
import Footer from "../components/Layout/Footer";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - 404 Page Not Found";

  return (
    <Route {...rest} render={props => (
      (rest.isAuthenticated && restricted) ?
        <Redirect to="/" />
        :  
        (typeof rest.header != 'undefined' && rest.header == false) ?
          <>
            {rest.loader && <div className="loading"></div>}
            <Component {...props} />
          </> :
        <div>
          <PageHeader/>
          {rest.loader && <div className="loading"></div>}
          <Component {...props} />
          <Footer/>
        </div>
      )} 
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    loader:state.loader.loader
  };
};

export default withRouter(connect(mapStateToProps)(PublicRoute));
