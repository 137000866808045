import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { loadSignUpInfo } from "./../redux/actions/AuthActions";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import { validationRegister } from "../helpers/RegisterFormValidations";
import RegisterIndex from "../components/Element/Register"
import { Helmet, HelmetProvider } from "react-helmet-async";
const Register = (props) => {
  // Add title
  let host = window.location.host;
  if (host == "www.staffingconnect.co.uk" || host == "staffingconnect.co.uk") {
    document.getElementById("titleText").innerText = "Staffing Connect - Registration Page";
  }
  // <meta name="robots" content="noindex" />
  return (
    <RegisterIndex />
  );
};

const mapStateToProps = (state) => {
  return {
    authData: isAuthenticated(state),
    errorMessage: state.error.error,
  };
};
export default connect(mapStateToProps)(Register);
