import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Nursery-banner.jpg";
// import EducareImg from "./../images/ourservices/Nursery.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/industrial/our-service-industrial-background.jpg";
import industrialImg from "./../images/new-updated-images/our-services/industrial/our-service-industrial-content.jpg";
import Footer from "../components/Layout/Footer";
import bnr3 from "./../images/lines.png";

export default function Industrial() {
  // Add title
  document.getElementById("titleText").innerText =
    "Staffing Connect - Our Services - Industrial";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/industrial"
            ? "0rem"
            : "" }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">
                <b style={{ display: "none" }}>
                  Staffing Connect - Our Services -
                </b>
                Industrial
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                  {window.location.pathname == "/mobile/industrial" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Industrial</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5 mb-0">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Industrial </h2>
                  {/* <h6 className="mb-0">
                    Welcome to Staffing Connect - Your Trusted Partner in
                    Industrial Workforce Solutions!
                  </h6> */}
                </div>
              </div>

              <div className="row  m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={industrialImg} alt="" />
                </div>
                <div className="col-md-12 col-lg-6 ">
                  {/* <h2 className="m-b5"> Home Care</h2> */}
                  <p className="spacebar mt-3"></p>
                  <p className="mb-1">
                    Discover the Power of Exceptional Industrial Workforce
                    Solutions with Staffing Connect!
                  </p>

                  <p className="mb-1 mt-1">
                    Welcome to Staffing Connect, where we redefine industrial
                    workforce solutions by seamlessly connecting skilled
                    professionals with the forefront of industrial innovation.
                    If you're in search of top-tier talent to drive your
                    industrial operations, your quest ends here. Staffing
                    Connect is your trusted partner for unparalleled recruitment
                    services in the manufacturing, logistics, and engineering
                    sectors.
                  </p>
                  <h6 className="mt-2">Our Commitment:</h6>
                  <p>
                    At Staffing Connect, excellence is not just a goal; it's our
                    commitment. With a dedicated team possessing a wealth of
                    industry expertise, we understand the distinctive challenges
                    faced by industrial businesses. Our mission is clear: to
                    bridge the gap between exceptional talent and companies
                    propelling industrial progress.
                  </p>
                </div>

                <div className="col-md-12 col-lg-12 mt-2">
                  <h6>Why chose Staffing Connect?</h6>

                  <ol className="ml-4">
                    <li>
                      <b>Industry Expertise:</b>
                      <ul className="ml-3">
                        <li>
                          Our team comprises experts with an intimate
                          understanding of the industrial sector. We don't just
                          recruit; we speak your language, comprehend your
                          challenges, and provide solutions that align with the
                          dynamic nature of your industry.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Tailored Solutions:</b>
                      <ul className="ml-3">
                        <li>
                          Recognizing that every industry and business is
                          unique, we reject the one-size-fits-all approach. Our
                          recruitment strategies are meticulously crafted to
                          match the specific requirements of your industrial
                          domain, ensuring a seamless fit for your organization.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Proven Results:</b>
                      <ul className="ml-3">
                        <li>
                          Our success stories stand testament to the impact
                          we've made. Dive into our portfolio to witness how
                          Staffing Connect has consistently placed skilled
                          professionals who have become integral contributors to
                          the success of our esteemed clients.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div className="col-md-12 col-lg-12 mt-2">
                  <h6>
                    Join the ranks of industrial businesses that have elevated
                    their workforce with Staffing Connect. Unleash the potential
                    of your operations by partnering with a recruitment agency
                    that goes beyond the conventional, delivering tailored
                    solutions that drive industrial excellence.
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
