import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const redirectWindowUrl = (url) => {
  if (url) {
    window.open(url, "_blank");
  }
};

export const redirectUrl = (url) => {
  if (url) {
    // window.open(url);
    window.location.href = url;
  }
};



