import React from "react";
import composedBranchesContainer from "../../../containers/BranchesContainer";
import BranchList from "./BranchList";
const BranchMainComponent = (props) => {
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">Branches</h5>
          <a
            href="/create-branch"
            className="pull-right btn btn-secondry site-button btn-sm"
          >
            Create Branch
          </a>
        </div>
        <BranchList />
      </div>
    </React.Fragment>
  );
};

export default composedBranchesContainer(BranchMainComponent);
