import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import TimeRangeSlider from "react-time-range-slider";
import { Link, useHistory } from "react-router-dom";
import { Form, Col, Spinner, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import playStore from "../../../images/play-store.png";
import appStore from "../../../images/app_store.png";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "../../FormElements/Dropdown";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import Label from "../../Layout/Label";
import { format } from "date-fns";
import { optionsArray, optionsArrayEndTime, statusArray } from "./TimeOptions";
import composedAvaibilityContainer from "../../../containers/AvaibilityContainer";
import { calculateTimeDifference } from "../../../services/TimeDurationCalc";
const MobileAvailabilityForm = (props) => {
  const [weekNumberSet, setWeekNumber] = useState(1);
  const [weekStatus, setWeekStatus] = useState("");
  let inTime,
    outTime,
    hrsResult,
    timeCheck,
    msg,
    finalCheck,
    lastCheck,
    checkTime,
    timeStart,
    checkCopyStatus; //To Print Availibility Hrs
  const { userDetails, avaibility } = props;
  const dispatch = useDispatch();
  const weekDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  //Week Commencing Code Start
  //To Get Previous Mondays only for Week Commencing
  const isWeekday = () => {
    let date = new Date();
    let sub = date.getDay() > 0 ? 1 : -6;
    let monday = new Date(date.setDate(date.getDate() - date.getDay() + sub));
    return monday.toISOString().split("T")[0];
  };
  //Week Commencing End

  const setMondayDate = (e) => {
    // console.log("Value Date :=> "+e.target.value);
    let date = new Date(String(e.target.value));
    let sub = date.getDay() > 0 ? 1 : -6;
    let monday = new Date(date.setDate(date.getDate() - date.getDay() + sub));
    setValue("cnd_avail_date_from_date", formatDate(monday));
  };

  const validationSchema = Yup.object().shape({
    cnd_avail_date_from_date: Yup.string()
      .required("Date is required")
      .typeError("Please Select Valid Date")
      .matches(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
        "Please slect the date from calendar in DD-MM-YYYY format!"
      ),
    /*cnd_avail_date_to_date: Yup.date()
      .required("Please Select End Date")
      .typeError("Please Select Valid Date")
      .min(
        Yup.ref("cnd_avail_date_from_date"),
        "End date can't be before Start date"
      ),
    cnd_availability_week_in_time : Yup.date(),*/
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [searchFormData, searchForm] = useState({});
  const [dates, setDates] = useState([]);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [pageNumber, setPageNumber] = useState();
  const history = useHistory();

  const nextPageHandle = () => {
    //Get The Week From The Selected Date
    let dateMin = String(getValues().cnd_avail_date_from_date);
    var currentDateMin = new Date(dateMin);
    var yearMin = new Date(currentDateMin.getFullYear(), 0, 1);
    var daysMin = Math.floor(
      (currentDateMin - yearMin) / (24 * 60 * 60 * 1000)
    );
    var weekMin = Math.ceil((currentDateMin.getDay() + 1 + daysMin) / 7);
    yearMin = String(yearMin);
    let subYearMin = yearMin.substring(11, 15);
    let finalWeekMin = subYearMin + "-W" + weekMin;
    //Week Code Start
    let years = finalWeekMin;
    let test; /// = years.toString();
    //console.log("Week Data :=>"+ years.substring(6, 8));
    const weekNumber = years.substring(6, 8); //11;
    const year = years.substring(0, 4);
    const firstDayOfYear = new Date(year, 0, 1);
    const firstDayOfWeek = firstDayOfYear.getDay() - 1;
    const firstWeekStartDate = new Date(year, 0, 1 - firstDayOfWeek);
    const weekStartDate = new Date(
      firstWeekStartDate.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
    );
    const weekEndDate = new Date(
      weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000
    );
    //Week Logic Stop

    let formdata = {};
    if (pageNumber < availabilityData.length - 1) {
      setPageNumber(pageNumber + 1);
    } else {
      formdata.cnd_avail_date_from_date = formatDate(weekStartDate);
      formdata.cnd_avail_date_to_date = formatDate(weekEndDate); //end_date;
      formdata.cnd_avail_week = finalWeekMin;
      formdata.cnd_availability_week_copy =
        getValues().cnd_availability_week_copy;
      formdata.users_access_token = userDetails.users_access_token;
      formdata.weekly_availability = availabilityData;
      onSubmit(formdata);
    }
  };

  const onSubmit = (formdata) => {
    document.getElementById("loader").style.display = "block";
    let weeklyAvaiilityData = [];
    let weekcount = 0;

    dispatch(
      updateDataOnRequest(
        JSON.stringify(formdata, null, 2),
        "SaveAvaibilityStatus",
        ""
      )
    );

    let interval = setInterval(myUrl, 2000);
    function myUrl() {
      window.location.href = "/availability";
      clearInterval(interval);
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    let start_date = avaibility.cnd_avail_date_from_date;
    let to_date = avaibility.cnd_avail_date_to_date;
    let to_week = avaibility.cnd_avail_week;
    //console.log("Week Set Value :=> "+to_week);
    setValue("cnd_avail_date_from_date", start_date);
    setValue("cnd_avail_date_to_date", to_date);
    setValue("cnd_avail_week", to_week);
    searchFormSubmit();
  }, [avaibility]);

  const setFormattedAvaibilityData = (avaibilityFormWeekData) => {
    let page = 0;
    let formdata = [...avaibilityFormWeekData];
    let tempdata = [];
    avaibilityFormWeekData.map((data, weekIndex) => {
      if (weekIndex === page) {
        let count = 1;
        tempdata[page] = [];
        weekDays.map((day, index) => {
          avaibility.weekly_availability &&
            avaibility.weekly_availability[0].map((weekData, index) => {
              if (
                data[day] &&
                data[day].cnd_availability_week_date ==
                  weekData.cnd_availability_week_date
              ) {
                if (weekData)
                  formdata[page][day] = { ...weekData /*, ...formdata[page]*/ };
                count++;
              }
            });
        });
      }
      page++;
    });
    setFormattedData(formdata);
    setValue("avaibility", formdata);
  };

  const prevPageHandle = () => {
    if (pageNumber >= 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  /*const onChangeTimeHandler = (time, day, week) => {
    let avaibilityForm = [...formattedData];
    avaibilityForm[week][day].cnd_availability_week_in_time = time.start;
    avaibilityForm[week][day].cnd_availability_week_out_time = time.end;
    let todayDate = formatDate(new Date());
    let startTime = new Date(`${todayDate} ${time.start}:00`);
    let endTime = new Date(`${todayDate} ${time.end}:00`);
    let timeDiff = Math.abs(startTime.getTime() - endTime.getTime());
    var hh = Math.floor(timeDiff / 1000 / 60 / 60);
    if (hh < 8 && hh > 4) {
      avaibilityForm[week][day].cnd_availability_week_status = "halfday";
    } else if (hh < 4) {
      avaibilityForm[week][day].cnd_availability_week_status = "unavilable";
    } else {
      avaibilityForm[week][day].cnd_availability_week_status = "fullday";
    }
    setFormattedData(avaibilityForm);
    setAvailabilityData(avaibilityForm);
  };*/

  const getDatesBetweenTwoDates = (startdate, enddate) => {
    const start = new Date(startdate);
    const end = new Date(enddate);
    const daysBetween = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
    const arr = [];
    for (let i = 0; i <= daysBetween; i++) {
      let temp = getDatAfterDay(startdate, i);
      arr.push(formatDate(temp));
    }
    setDates(arr);
    return arr;
  };

  const getDatAfterDay = (selectDate, selectDay) => {
    let result = new Date(selectDate);
    result.setDate(result.getDate() + selectDay);
    let date = new Date(result),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  function formatDate(d1) {
    let date = new Date(d1),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const searchFormSubmit = () => {
    //Get The Week From The Selected Date
    let dateMin = String(getValues().cnd_avail_date_from_date);
    var currentDateMin = new Date(dateMin);
    var yearMin = new Date(currentDateMin.getFullYear(), 0, 1);
    var daysMin = Math.floor(
      (currentDateMin - yearMin) / (24 * 60 * 60 * 1000)
    );
    var weekMin = Math.ceil((currentDateMin.getDay() + 1 + daysMin) / 7);
    yearMin = String(yearMin);
    let subYearMin = yearMin.substring(11, 15);
    let finalWeekMin = subYearMin + "-W" + weekMin;
    //Week Code Start
    let years = finalWeekMin;
    let test; /// = years.toString();
    //console.log("Week Data :=>"+ years.substring(6, 8));
    const weekNumber = years.substring(6, 8); //11;
    const year = years.substring(0, 4);
    const firstDayOfYear = new Date(year, 0, 1);
    const firstDayOfWeek = firstDayOfYear.getDay() - 1;
    const firstWeekStartDate = new Date(year, 0, 1 - firstDayOfWeek);
    const weekStartDate = new Date(
      firstWeekStartDate.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
    );
    const weekEndDate = new Date(
      weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000
    );
    //console.log("Week Data :=>"+ weekStartDate);
    //Week Code Ended
    //alert(`Week ${weekNumber} of ${year} starts on ${weekStartDate.toDateString()} and ends on ${weekEndDate.toDateString()}`);
    setPageNumber(0);
    let dateData = getDatesBetweenTwoDates(
      formatDate(weekStartDate),
      formatDate(weekEndDate)
    );
    updateAvalibilityDataArray(dateData);
  };

  // let count = 0;
  const changeWeek = (weekType) => {
    //Get The Week From The Selected Date

    var currentDateMin = new Date(isWeekday(currentDateMin));
    var yearMin = new Date(currentDateMin.getFullYear(), 0, 1);
    var daysMin = Math.floor(
      (currentDateMin - yearMin) / (24 * 60 * 60 * 1000)
    );
    let weekMin;
    let currentWeek = Math.ceil((currentDateMin.getDay() + 1 + daysMin) / 7);
    yearMin = String(yearMin);
    // Prev & Next Week Code Start
    // console.log("Week Name and Number :=>"+ weekNumberSet);

    if (weekType == "prev") {
      if (weekNumberSet > 1) {
        setWeekNumber(weekNumberSet - 1);
        weekMin = weekStatus - 1;
      }
    } else if (weekType == "next") {
      setWeekNumber(weekNumberSet + 1);
      weekMin = weekStatus + 1;
    }
    setWeekStatus(weekMin);

    let subYearMin = yearMin.substring(11, 15);
    let finalWeekMin = subYearMin + "-W" + weekMin;
    //Week Code Start
    // Prev & Next Week Code End
    let years = finalWeekMin;
    let test; /// = years.toString();
    // console.log("Week Data :=>"+ weekMin);
    const weekNumber = years.substring(6, 8); //11;
    const year = years.substring(0, 4);
    const firstDayOfYear = new Date(year, 0, 1);
    const firstDayOfWeek = firstDayOfYear.getDay() - 1;
    const firstWeekStartDate = new Date(year, 0, 1 - firstDayOfWeek);
    const weekStartDate = new Date(
      firstWeekStartDate.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
    );
    const weekEndDate = new Date(
      weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000
    );

    setValue("cnd_avail_date_from_date", formatDate(weekStartDate));
    //console.log("Week Data :=>"+ weekStartDate);
    //Week Code Ended
    //alert(`Week ${weekNumber} of ${year} starts on ${weekStartDate.toDateString()} and ends on ${weekEndDate.toDateString()}`);

    setPageNumber(0);
    let dateData = getDatesBetweenTwoDates(
      formatDate(weekStartDate),
      formatDate(weekEndDate)
    );
    updateAvalibilityDataArray(dateData);
    // console.log("Demo Test :=> "+weekStatus);
  };

  const updateAvalibilityDataArray = (dateData) => {
    // Default Availibility Start
    if (dateData == "") {
      //Get The Week From The Selected Date
      const today = new Date();
      const dayOfWeek = today.getDay();
      const monday = new Date(today);

      monday.setDate(today.getDate() - dayOfWeek + 1);

      let dateMin = monday;
      var currentDateMin = new Date(isWeekday(currentDateMin));
      // console.log("Monday Testing :=> " + currentDateMin);
      var yearMin = new Date(currentDateMin.getFullYear(), 0, 1);
      var daysMin = Math.floor(
        (currentDateMin - yearMin) / (24 * 60 * 60 * 1000)
      );
      var weekMin = Math.ceil((currentDateMin.getDay() + 1 + daysMin) / 7);
      yearMin = String(yearMin);
      let subYearMin = yearMin.substring(11, 15);
      let finalWeekMin = subYearMin + "-W" + weekMin;
      //Week Code Start
      let years = finalWeekMin;
      let test; /// = years.toString();
      //console.log("Week Data :=>"+ years.substring(6, 8));
      const weekNumber = years.substring(6, 8); //11;
      setWeekStatus(weekMin);
      const year = years.substring(0, 4);
      const firstDayOfYear = new Date(year, 0, 1);
      const firstDayOfWeek = firstDayOfYear.getDay() - 1;
      const firstWeekStartDate = new Date(year, 0, 1 - firstDayOfWeek);
      const weekStartDate = new Date(
        firstWeekStartDate.getTime() +
          (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
      );
      const weekEndDate = new Date(
        weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000
      );

      dateData = getDatesBetweenTwoDates(
        formatDate(weekStartDate),
        formatDate(weekEndDate)
      );
      // console.log("Week Date := >"+formatDate(weekStartDate));
      setValue("cnd_avail_date_from_date", formatDate(weekStartDate));
      // console.log("Week Status Is :=>"+weekStatus);
    }
    // Default Availability Stop
    let formIndex = 0;
    let avaibilityFormWeekData = [];
    let days = [];
    dateData.map((date, dateIndex) => {
      const d = new Date(date);
      days[dateIndex] = { day: d.getDay(), date: date };
    });
    days.sort(function (x, y) {
      let a = new Date(x.date),
        b = new Date(y.date);
      return a - b;
    });

    //Changes Done
    let count = 0;
    days.map((day) => {
      //console.log("Days Information :=>" + JSON.stringify(day));
      avaibilityFormWeekData[formIndex] = {
        [weekDays[count]]: {
          cnd_availability_week_status: "available",
          cnd_availability_week_day: weekDays[count],
          cnd_availability_week_date: day.date, //format(new Date(day.date),"dd-MM-yyyy"),
          cnd_availability_week_in_time: "08:00",
          cnd_availability_week_out_time: "12:00",
        },
        ...avaibilityFormWeekData[formIndex],
      };
      count++;
      if (day >= 7) {
        formIndex++;
      }
    });

    //chnages done
    //console.log("Demo testing :=>" + JSON.stringify(avaibilityFormWeekData[0]));
    setAvailabilityData(avaibilityFormWeekData);
    setFormattedAvaibilityData(avaibilityFormWeekData);
  };

  /*For Start time */
  let demo;

  let errorOptions = 0;
  const onChangeStartTime = (e, day, week) => {
    let avaibilityForm = [...availabilityData];
    avaibilityForm[week][day][e.target.name] = e.target.value;

    //console.log("Demo Test :=> "+e.target.selectedIndex);
    //Start Time Should Be Start From 8 Am

    /*if(e.target.selectedIndex <= 31){
      avaibilityForm[week][day]['cnd_availability_week_status'] = "timeStart";   
    }else if(e.target.selectedIndex > 31){*/
    try {
      demo = e.target.selectedIndex + 16;
      avaibilityForm[week][day]["cnd_availability_week_out_time"] =
        optionsArray[demo].value;
    } catch (error) {
      let lengthArray = optionsArray.length;
      avaibilityForm[week][day]["cnd_availability_week_out_time"] =
        optionsArray[lengthArray - 1].value;
    }
    //}

    setAvailabilityData(avaibilityForm);
  };

  /*For end Time */
  const onChangeEndTime = (e, day, week) => {
    let avaibilityForm = [...availabilityData];

    //console.log("Demo Test :=> "+avaibilityForm[week][day].cnd_availability_week_in_time);
    try {
      avaibilityForm[week][day][e.target.name] = e.target.value;
      if (avaibilityForm[week][day].cnd_availability_week_in_time == "00:00") {
        avaibilityForm[week][day]["cnd_availability_week_in_time"] =
          optionsArray[32].value;
      } else if (
        avaibilityForm[week][day].cnd_availability_week_status != "all_day" ||
        avaibilityForm[week][day].cnd_availability_week_status != "unavailable"
      ) {
        avaibilityForm[week][day]["cnd_availability_week_in_time"] =
          avaibilityForm[week][day].cnd_availability_week_in_time;
      } else {
        avaibilityForm[week][day]["cnd_availability_week_in_time"] =
          optionsArray[0].value;
      }
    } catch (error) {
      let lengthArray = optionsArray.length;
      avaibilityForm[week][day][e.target.name] =
        optionsArray[lengthArray - 1].value;
      if (avaibilityForm[week][day].cnd_availability_week_in_time == "00:00") {
        avaibilityForm[week][day]["cnd_availability_week_in_time"] =
          optionsArray[32].value;
      } else if (
        avaibilityForm[week][day].cnd_availability_week_status != "all_day" ||
        avaibilityForm[week][day].cnd_availability_week_status != "unavailable"
      ) {
        avaibilityForm[week][day]["cnd_availability_week_in_time"] =
          avaibilityForm[week][day].cnd_availability_week_in_time;
      } else {
        avaibilityForm[week][day]["cnd_availability_week_in_time"] =
          optionsArray[0].value;
      }
    }

    setAvailabilityData(avaibilityForm);
  };

  //OnChange Status
  const onChangeStatusAll = (e, day, week) => {
    //console.log("Status Show :=>"+e.target.checked);
    //console.log("Day & Week :=> "+day + week);
    let avaibilityForm = [...availabilityData];
    //avaibilityForm[week][day][e.target.name] = e.target.value;

    if (e.target.checked) {
      avaibilityForm[week][day]["cnd_availability_week_status"] = "all_day";
      avaibilityForm[week][day]["cnd_availability_week_in_time"] =
        optionsArray[0].value;
      avaibilityForm[week][day]["cnd_availability_week_out_time"] =
        optionsArray[0].value;
    } else {
      avaibilityForm[week][day]["cnd_availability_week_status"] = "available";
      avaibilityForm[week][day]["cnd_availability_week_in_time"] =
        optionsArray[32].value;
      avaibilityForm[week][day]["cnd_availability_week_out_time"] =
        optionsArray[48].value;
    }

    setAvailabilityData(avaibilityForm);
  };

  //OnChange Status
  const onChangeStatusUn = (e, day, week) => {
    //console.log("Status Show :=>"+e.target.checked);
    //console.log("Day & Week :=> "+day + week);
    let avaibilityForm = [...availabilityData];
    //avaibilityForm[week][day][e.target.name] = e.target.value;

    if (e.target.checked) {
      avaibilityForm[week][day]["cnd_availability_week_status"] = "unavailable";
      avaibilityForm[week][day]["cnd_availability_week_in_time"] =
        optionsArray[0].value;
      avaibilityForm[week][day]["cnd_availability_week_out_time"] =
        optionsArray[0].value;
    } else {
      avaibilityForm[week][day]["cnd_availability_week_status"] = "available";
      avaibilityForm[week][day]["cnd_availability_week_in_time"] =
        optionsArray[32].value;
      avaibilityForm[week][day]["cnd_availability_week_out_time"] =
        optionsArray[48].value;
    }

    setAvailabilityData(avaibilityForm);
  };

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    let mobileScreen = document.getElementById("mobileScreen");
    console.log("Device Type:", deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      mobileScreen.style.display = "none";
      scrollFocusElement.style.display = "block";
      window.scrollTo(0, 800);
    } else {
      mobileScreen.style.display = "none";
      scrollFocusElement.style.display = "block";
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop
  return (
    <React.Fragment>
      <div
        className="job-bx table-job-bx clearfix"
        id="scrollFocusElement"
        style={{ display: "none" }}
      >
        <div className="job-bx-title clearfix" style={{ marginBottom: 0 }}>
          <h5 className="font-weight-700 pull-left text-uppercase">
            Availability
          </h5>
          {/* <div className="float-right">
            <span className="select-title">Sort by</span>
            <select className="custom-btn">
              <option>All</option>
              <option>Earliest first</option>
              <option>Latest first</option>
            </select>
          </div> */}
        </div>
        <form
          onChange={handleSubmit(searchFormSubmit)}
          onInput={setMondayDate}
          on
        >
          <div className="job-bx-list row m-t20">
            <Form.Group as={Col} md={12} sm={12}>
              <Label labelName={"Week Commencing"} />
              <Form.Control
                label="Select Week"
                name="cnd_avail_date_from_date"
                type="date"
                //defaultValue={isWeekday()}
                //value={avaibility.cnd_avail_date_from_date ? avaibility.cnd_avail_date_from_date : isWeekday()}
                // onInput={(e) => setMondayDate()}
                min={isWeekday()}
                step={7}
                {...register("cnd_avail_date_from_date")}
                isInvalid={
                  errors && errors["cnd_avail_date_from_date"] ? true : ""
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors["cnd_avail_week"]?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={12} sm={12} className="mt-4">
              <center>
                <a
                  onClick={(e) => changeWeek("prev")}
                  class="site-button black btn-md text-white mr-3 "
                >
                  <b>
                    <i class="fa fa-angle-left"></i>
                  </b>
                  <br />
                  {"Prev Week"}
                </a>
                <a
                  onClick={(e) => changeWeek("next")}
                  class="site-button black btn-md text-white "
                >
                  <b>
                    <i class="fa fa-angle-right"></i>
                  </b>
                  <br />
                  {"Next Week"}
                </a>
              </center>
            </Form.Group>
          </div>
        </form>
        <div className="row">
          <table className="table table-bordered">
            <tbody>
              {formattedData.map((data, weekIndex) => {
                //console.log("Json test :=>"+ JSON.stringify(data));

                if (weekIndex === pageNumber) {
                  return weekDays.map((day, index) => {
                    let todayDate = formatDate(new Date());
                    inTime = data[day].cnd_availability_week_in_time;
                    outTime = data[day].cnd_availability_week_out_time;
                    let startTime = new Date(`${todayDate} ${inTime}`);
                    let endTime = new Date(`${todayDate} ${outTime}`);

                    let calctime = {};
                  // if (outTime === "00:00" && data[day].cnd_availability_week_status == "available") {
                    if (outTime === "00:00") {
                      endTime.setDate(endTime.getDate() + 1);
                      calctime = calculateTimeDifference(inTime, outTime);
                    }
                    timeCheck = Math.abs(
                      startTime.getTime() - endTime.getTime()
                    );
                    var days = Math.floor(timeCheck / (1000 * 60 * 60 * 24));
                    var hours = (calctime && calctime.hours ? calctime.hours :  Math.floor(
                      (timeCheck % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    ));
                    var minutes = (calctime && calctime.minutes ? calctime.minutes : Math.floor(
                      (timeCheck % (1000 * 60 * 60)) / (1000 * 60)
                    ));
                    var seconds = Math.floor((timeCheck % (1000 * 60)) / 1000);

                    let msg, stime, etime;
                    stime = inTime.replace(":", ".");
                    etime = outTime.replace(":", ".");

                    if (stime != null && etime != null) {
                      if (stime > etime) {
                        if(outTime == "00:00" && hours >= 4){
                          msg = "";
                          finalCheck +=
                          "";
                          hrsResult = (hours > 0 ? hours  + " hours " : "" )+ (minutes > 0 ? (hours > 0 ? ":" : "") + minutes + " minutes" : "");                          
                        }else{
                          msg = "In Time should be less than Out Time!";
                          finalCheck +=
                          hours + " hours : " + minutes + " minutes";
                          hrsResult = "";                          
                        }
                      } else if (stime <= etime) {
                        if (hours == 0) {
                          hrsResult = minutes + " minutes";
                        } else if (hours > 0) {
                          if (minutes == 0) {
                            hrsResult = hours + " hours";
                          } else {
                            hrsResult =
                              hours + " hours : " + minutes + " minutes";
                          }
                        } else {
                          hrsResult = "";
                        }

                        //4 hours Mandattory Conddition Start
                        let checkStatus;
                        if (
                          data[day].cnd_availability_week_status != "all_day" &&
                          data[day].cnd_availability_week_status !=
                            "unavailable"
                        ) {
                          checkStatus += data[day].cnd_availability_week_status;
                        }

                        if (hours < 4 && checkStatus) {
                          checkTime +=
                            hours + " hours : " + minutes + " minutes";
                        }
                        //4 hours Mandattory Conddition Stop

                        //Unavailable Show Status While Unavailable Switch On
                        if (
                          hrsResult == "0 minutes" &&
                          data[day].cnd_availability_week_status ==
                            "unavailable"
                        ) {
                          hrsResult = "Unavailable";
                        }
                      }
                      //Final Check Condition to Disabled Button
                      if (finalCheck != null && finalCheck != "") {
                        finalCheck = finalCheck.replace("undefined", "");
                      } else {
                        finalCheck = "";
                      }

                      //Hrs Should Be 4 Hrs
                      if (checkTime != null && checkTime != "") {
                        checkTime = checkTime.replace("undefined", "");
                      } else {
                        checkTime = "";
                      }

                      //Hrs Result
                      if (hrsResult == "23h : 59m") {
                        hrsResult = "4h";
                        /*lastCheck += "0 minutes";
                       finalCheck = lastCheck.replace("undefined","");*/
                      }
                    } else {
                      msg = "";
                      finalCheck = "";
                      hrsResult = "";
                    }

                    checkCopyStatus =
                      data[day].cnd_availability_week_copy_status;

                    return (
                      data[day] && (
                        <tr key={weekIndex + index}>
                          <td
                            className="text-center text-capitalize"
                            colSpan={2}
                          >
                            <div className="container">
                              <div className="row text-center">
                                <div className="col-sm-12">
                                  <h4 className="mt-2">{day}</h4>
                                  <b>
                                    {data[day] &&
                                      format(
                                        new Date(
                                          data[day].cnd_availability_week_date
                                        ),
                                        "dd-MM-yyyy"
                                      )}
                                  </b>
                                </div>

                                <div className="col-sm-2"></div>
                                <div className="col-sm-4">
                                  <div
                                    class="form-check form-switch "
                                    //   style={{ marginLeft: "45%", marginTop: "45%" }}
                                  >
                                    {data[day] &&
                                      data[day].cnd_availability_week_status ==
                                        "all_day" && (
                                        <>
                                          <label
                                            class="form-control-label mt-1"
                                            style={{ marginRight: "20%" }}
                                          >
                                            Full Day:{" "}
                                          </label>

                                          <input
                                            type="checkbox"
                                            name="cnd_availability_week_status"
                                            checked
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            //value={data[day] && data[day].cnd_availability_week_status}
                                            className={"form-check-input"}
                                            onChange={(e) =>
                                              onChangeStatusAll(
                                                e,
                                                day,
                                                weekIndex
                                              )
                                            }
                                            style={{
                                              width: "45px",
                                              height: "18px",
                                            }}
                                          />
                                        </>
                                      )}

                                    {data[day] &&
                                      data[day].cnd_availability_week_status !=
                                        "all_day" && (
                                        <>
                                          <label
                                            class="form-control-label mt-1"
                                            style={{ marginRight: "20%" }}
                                          >
                                            Full Day:{" "}
                                          </label>
                                          <input
                                            type="checkbox"
                                            name="cnd_availability_week_status"
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            //value={data[day] && data[day].cnd_availability_week_status}
                                            onChange={(e) =>
                                              onChangeStatusAll(
                                                e,
                                                day,
                                                weekIndex
                                              )
                                            }
                                            className="form-check-input"
                                            style={{
                                              width: "45px",
                                              height: "18px",
                                            }}
                                          />
                                        </>
                                      )}
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div
                                    class="form-check form-switch "
                                    //   style={{ marginLeft: "45%", marginTop: "25%" }}
                                  >
                                    {data[day] &&
                                      data[day].cnd_availability_week_status ==
                                        "unavailable" && (
                                        <>
                                          <label
                                            class="form-control-label mt-1"
                                            style={{ marginRight: "20%" }}
                                          >
                                            Unavailable:{" "}
                                          </label>
                                          <input
                                            type="checkbox"
                                            name="cnd_availability_week_status"
                                            checked
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            //value={data[day] && data[day].cnd_availability_week_status}
                                            className={"form-check-input"}
                                            onChange={(e) =>
                                              onChangeStatusUn(
                                                e,
                                                day,
                                                weekIndex
                                              )
                                            }
                                            style={{
                                              width: "45px",
                                              height: "18px",
                                            }}
                                          />
                                        </>
                                      )}

                                    {data[day] &&
                                      data[day].cnd_availability_week_status !=
                                        "unavailable" && (
                                        <>
                                          <label
                                            class="form-control-label mt-1"
                                            style={{ marginRight: "20%" }}
                                          >
                                            Unavailable:{" "}
                                          </label>
                                          <input
                                            type="checkbox"
                                            name="cnd_availability_week_status"
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            //value={data[day] && data[day].cnd_availability_week_status}
                                            onChange={(e) =>
                                              onChangeStatusUn(
                                                e,
                                                day,
                                                weekIndex
                                              )
                                            }
                                            className={"form-check-input"}
                                            style={{
                                              width: "45px",
                                              height: "18px",
                                            }}
                                          />
                                        </>
                                      )}
                                  </div>
                                </div>
                                <div className="col-sm-2"></div>
                              </div>
                              <div className="row text-left">
                                <div className="col-sm-2"></div>

                                <Dropdown
                                  label="In Time"
                                  name="cnd_availability_week_in_time"
                                  value={
                                    (data[day] &&
                                      data[day].cnd_availability_week_in_time !=
                                        "00:00") ||
                                    data[day].cnd_availability_week_status ==
                                      "unavailable" ||
                                    data[day].cnd_availability_week_status ==
                                      "all_day"
                                      ? data[day].cnd_availability_week_in_time
                                      : optionsArray[32].value
                                  }
                                  options={optionsArray}
                                  changeHandler={(e) =>
                                    onChangeStartTime(e, day, weekIndex)
                                  }
                                  disabled={
                                    data[day].cnd_availability_week_status ==
                                      "all_day" ||
                                    (data[day].cnd_availability_week_status ==
                                      "unavailable" &&
                                      true)
                                  }
                                  lg={4}
                                />

                                <Dropdown
                                  label="Out Time"
                                  name="cnd_availability_week_out_time"
                                  value={
                                    data[day] &&
                                    data[day].cnd_availability_week_out_time !=
                                      "23:59"
                                      ? data[day].cnd_availability_week_out_time
                                      : optionsArrayEndTime[48].value
                                  }
                                  options={optionsArrayEndTime}
                                  disabled={
                                    data[day].cnd_availability_week_status ==
                                      "all_day" ||
                                    (data[day].cnd_availability_week_status ==
                                      "unavailable" &&
                                      true)
                                  }
                                  changeHandler={(e) =>
                                    onChangeEndTime(e, day, weekIndex)
                                  }
                                  lg={4}
                                />

                                <div className="col-sm-2"></div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-12">
                                <h6>
                                  {msg != null && msg != "" && (
                                    <h6
                                      className="text-danger"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {msg}
                                    </h6>
                                  )}
                                  {data[day].cnd_availability_week_status !=
                                    "timeStart" &&
                                    hrsResult != null &&
                                    hrsResult != "" &&
                                    hrsResult != "23h : 59m" &&
                                    data[day].cnd_availability_week_status !=
                                      "all_day" &&
                                    hrsResult &&
                                    data[day].cnd_availability_week_status !=
                                      "unavailable" &&
                                    hrsResult}
                                  {data[day].cnd_availability_week_status ==
                                    "all_day" && "Full Day"}
                                  {data[day].cnd_availability_week_status ==
                                    "unavailable" && (
                                    <h6 className="text-danger">{hrsResult}</h6>
                                  )}
                                </h6>
                              </div>
                            </div>
                          </td>
                          {/*<td className={"text-center"}>
                          <Dropdown
                            label=""
                            name="cnd_availability_week_status"
                            value={data[day] && data[day].cnd_availability_week_status}
                            options={}
                            changeHandler={(e) =>
                              onChangeIsAvailable(e, day, weekIndex)
                            }
                            width={12}
                          />
                        </td>*/}
                        </tr>
                      )
                    );
                  });
                }
              })}
            </tbody>
          </table>
        </div>

        <div className="row ">
          <div className="col-sm-8 mb-3 mt-2">
            <Form.Group as={Col} md={10} sm={12}>
              <Label
                labelName={
                  !checkCopyStatus &&
                  pageNumber < availabilityData.length &&
                  "Availability to be repeated in weeks"
                }
                req={false}
              />

              {!checkCopyStatus && pageNumber < availabilityData.length && (
                <select
                  class="form-select"
                  name="cnd_availability_week_copy"
                  aria-label="Default select example"
                  {...register("cnd_availability_week_copy")}
                >
                  <option selected value="">
                    Select Weeks
                  </option>
                  <option value="1">1 Week</option>
                  <option value="2">2 Weeks</option>
                  <option value="3">3 Weeks</option>
                  <option value="4">4 Weeks</option>
                </select>
              )}
            </Form.Group>
          </div>
          <div className="col-sm-4">
            <div className="pagination-bx float-right m-t20">
              <ul className="pagination">
                {pageNumber > 0 && (
                  <li className="previous">
                    <Link
                      to={"#"}
                      onClick={(e) => {
                        prevPageHandle();
                      }}
                    >
                      <i className="ti-arrow-left"></i> Prev
                    </Link>
                  </li>
                )}
                {pageNumber < availabilityData.length && (
                  <li className="next pull-left">
                    <Button
                      to={"#"}
                      onClick={(e) => {
                        nextPageHandle();
                      }}
                      disabled={
                        timeStart ||
                        finalCheck ||
                        checkTime ||
                        hrsResult == null ||
                        hrsResult == "" ||
                        hrsResult == "23h : 59m"
                          ? true
                          : false
                      }
                      className="site-button green"
                    >
                      {pageNumber == availabilityData.length - 1 &&
                      !checkCopyStatus
                        ? "Save"
                        : "Update"}{" "}
                      {pageNumber !== availabilityData.length - 1 && (
                        <i className="ti-arrow-right"></i>
                      )}
                    </Button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {/* <button className="site-button m-b30 m-t30">Save Details</button> */}

        <b className="text-danger text-center">
          {finalCheck != null &&
            finalCheck != "" &&
            "Please select the correct time!"}
          {checkTime != null &&
            checkTime != "" &&
            "Please select at least 4 hours availibility for the day!"}

          {/*hrsResult*/}
        </b>
      </div>

      <div
        className="job-bx table-job-bx clearfix"
        id="mobileScreen"
        style={{ display: "none" }}
      >
        <p className="">
          To add the availability, please download our Staffingconnect App or
          you can fill the availability in web app by using desktop or laptop
        </p>
        <center>
          <a
            href="https://play.google.com/store/apps/details?id=com.ittrp.staffing_connect"
            target="_blank"
          >
            <img
              src={playStore}
              alt="Download on Google Play"
              id="playStore"
              className="img-fluid appImage"
            />
          </a>
          <a target="_blank">
            <img
              src={appStore}
              id="appStore"
              alt="Download on the App Store"
              className="img-fluid appImage"
            />
          </a>
        </center>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.authUserData,
    avaibility: state.avaibility.avaibility,
  };
};

export default connect(mapStateToProps)(
  composedAvaibilityContainer(MobileAvailabilityForm)
);
