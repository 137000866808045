const initialState = { workAndQualificationDetails: {} };

export const workAndQualificationDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WORK_AND_QUALIFICATION_DETAILS":
      return { workAndQualificationDetails: action.payload };
    case "UPDATE_WORK_AND_QUALIFICATION_DETAILS":
      return { workAndQualificationDetails: action.payload };
  }
  return state;
};
