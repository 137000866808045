import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Jobfindbox from "../components/Element/Jobfindbox";
import { allJobs } from "./Data";
import Backgroundimage from "./Backgroundimage";
import { checkUserStatus } from './checkUserStatus';
export default function PermanentJobs() {
  const permanentJobs = allJobs.filter((j) => j.jobType === "permanent");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const [permanentRecords, setPermanentRecords] = useState(permanentJobs);
  const [maxHeight, setMaxHeight] = useState("auto");
  const [area, setArea] = useState("")
  const [title, setTitle] = useState("")
  let userType = localStorage.getItem("user_job_type");
  
  const totalPages = Math.ceil(permanentRecords.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = Math.min(
    startIndex + recordsPerPage,
    permanentRecords.length
  );

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  


  useEffect(() => {
  
    const listItems = document.querySelectorAll(".post-bx");
    let max = 0;

    listItems.forEach((item) => {
      const height = item.getBoundingClientRect().height;
      if (height > max) {
        max = height;
      }
    });

    setMaxHeight(max + "px");
  }, [permanentRecords]);

 const handleSubmit = (e) => {
     e.preventDefault()
    const filteredJobs = permanentJobs.filter((job) => {
        const matchesArea = area === "" || job.area.toLowerCase().includes(area.toLowerCase());
        const matchesTitle = title === "" || job.title.toLowerCase().includes(title.toLowerCase());
        return matchesArea && matchesTitle;
    });

    setPermanentRecords(filteredJobs);
};
const isLogin = checkUserStatus();
    //const userType = "permanent requirement"; 

    const linkTo = isLogin
        ? userType === "permanentrequirement"
            ? "/applied-jobs"
            : "/dashboard"
        : "/login";



  return (
    <>
      <div className="page-content bg-white">
        <Backgroundimage
          backgroundImage={require("../images/new-updated-images/job-board/permanent-jobs/job-board-permanent-job.jpg")}
          pageTitle="Permanent Job Board"
        />
        <div className="content-block">
        <Jobfindbox  
            area={area} 
            setArea={setArea}
            title={title}
            setTitle={setTitle}
            handleSubmit={handleSubmit}
              jobTitles={allJobs}
            // onSearch={() => {
            //     setPermanentRecords(permanentJobs.filter((p) => 
            //         p.area.includes(area) && p.title.toLowerCase().includes(title.toLowerCase())
            //     ));
            // }}
        />


        <div className="section-full bg-white browse-job p-b50">
          <div className="container">
            <ul
              className="post-job-bx browse-job-grid row"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {permanentRecords
                .slice(startIndex, endIndex)
                .map((item, index) => (
                  <li
                    className="col-lg-4 col-md-6"
                    key={index}
                    style={{
                      height: maxHeight,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="post-bx"
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex m-b30">
                        <div className="job-post-info">
                          <h6 className="font-size-info">
                            <Link
                              to={{
                                pathname: "/jobDetails",
                                state: { jobDetails: item },
                              }}
                            >
                              {item.title}
                            </Link>
                          </h6>
                          <ul>
                            <li>
                              <i className="fa fa-map-marker"></i>{item.area}
                            </li>
                            <li>
                              <i className="fa fa-bookmark-o"></i>{" "}
                              {item.jobType === "permanent"
                                ? "Permanent"
                                : "Temporary"}
                              , Full Time
                            </li>
                            <li>
                              <i className="fa fa-clock-o"></i>
                              {item.price}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="flex-row-reverse mb-2"
                        style={{ alignSelf: "flex-end" }}
                      >
                        <div className="job-time-info">
                        <Link className="site-button green float-right mt-2 mb-0"  to={linkTo}>Apply Now</Link>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="pagination-bx m-t30 ">
              <ul className="pagination d-flex justify-content-end">
                <li className="previous">
                  <Link to="#" onClick={handleLeft}>
                    <i className="ti-arrow-left"></i> Prev
                  </Link>
                  </li>
                {[...Array(totalPages)].map((_, i) => (
                  <li key={i} className={currentPage === i + 1 ? "active" : ""}>
                    <Link to="#" onClick={() => setCurrentPage(i + 1)}>
                      {i + 1}
                    </Link>
                  </li>
                ))}
                <li className="next">
                  <Link to="#" onClick={handleRight}>
                    Next <i className="ti-arrow-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
