const initialState = {documentCompliance: []}

export const documentCompliance = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_DOCUMENT_COMPLIANCE':
      return {documentCompliance: action.payload}; 
  }
  return state
}


