import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Form, Button, Spinner } from "react-bootstrap";
import Error from "../../Layout/Error";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Label from "../../Layout/Label";
import Textarea from "../../FormElements/Textarea";
import Input from "../../FormElements/Input";
const FltLicenseForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [usersCategory, setUsersCategory] = useState("home care");
  const [errors, setErrors] = useState({});
  const [question5description, setQuestion5Description] = useState(false);
  const [question6description, setQuestion6Description] = useState(false);
  const [formData, setFormData] = useState({});

  const { loader } = props;

  const showDescription = (currentId) => {
    let descriptionId = document.getElementById(currentId);

    if (
      currentId == "cnd_flt_licenses_question_5_yes" &&
      descriptionId.checked == true
    ) {
      setQuestion5Description(true);
    } else if (
      currentId == "cnd_flt_licenses_question_5_no" &&
      descriptionId.checked == true
    ) {
      setQuestion5Description(false);
    }

    if (
      currentId == "cnd_flt_licenses_question_6_yes" &&
      descriptionId.checked == true
    ) {
      setQuestion6Description(true);
    } else if (
      currentId == "cnd_flt_licenses_question_6_no" &&
      descriptionId.checked == true
    ) {
      setQuestion6Description(false);
    }
  };

  const validForm = () => {
    let applyError = false;

    let cnd_flt_licenses_question_1_yes = document.getElementById(
      "cnd_flt_licenses_question_1_yes"
    );
    let cnd_flt_licenses_question_1_no = document.getElementById(
      "cnd_flt_licenses_question_1_no"
    );
    let cnd_flt_licenses_question_1_msg = document.getElementById(
      "cnd_flt_licenses_question_1_msg"
    );

    let cnd_flt_licenses_question_2_yes = document.getElementById(
      "cnd_flt_licenses_question_2_yes"
    );
    let cnd_flt_licenses_question_2_no = document.getElementById(
      "cnd_flt_licenses_question_2_no"
    );
    let cnd_flt_licenses_question_2_msg = document.getElementById(
      "cnd_flt_licenses_question_2_msg"
    );

    let cnd_flt_licenses_question_3 = document.getElementById(
      "cnd_flt_licenses_question_3"
    );
    // let cnd_flt_licenses_question_3_yes = document.getElementById(
    //   "cnd_flt_licenses_question_3_yes"
    // );
    // let cnd_flt_licenses_question_3_no = document.getElementById(
    //   "cnd_flt_licenses_question_3_no"
    // );
    let cnd_flt_licenses_question_3_msg = document.getElementById(
      "cnd_flt_licenses_question_3_msg"
    );

    let cnd_flt_licenses_question_4_yes = document.getElementById(
      "cnd_flt_licenses_question_4_yes"
    );
    let cnd_flt_licenses_question_4_no = document.getElementById(
      "cnd_flt_licenses_question_4_no"
    );
    let cnd_flt_licenses_question_4_msg = document.getElementById(
      "cnd_flt_licenses_question_4_msg"
    );

    let cnd_flt_licenses_question_5_yes = document.getElementById(
      "cnd_flt_licenses_question_5_yes"
    );
    let cnd_flt_licenses_question_5_no = document.getElementById(
      "cnd_flt_licenses_question_5_no"
    );
    let cnd_flt_licenses_question_5_msg = document.getElementById(
      "cnd_flt_licenses_question_5_msg"
    );

    let cnd_flt_licenses_question_5_description = document.getElementById(
      "cnd_flt_licenses_question_5_description"
    );
    let cnd_flt_licenses_question_5_description_msg = document.getElementById(
      "cnd_flt_licenses_question_5_description_msg"
    );

    let cnd_flt_licenses_question_6_yes = document.getElementById(
      "cnd_flt_licenses_question_6_yes"
    );
    let cnd_flt_licenses_question_6_no = document.getElementById(
      "cnd_flt_licenses_question_6_no"
    );
    let cnd_flt_licenses_question_6_msg = document.getElementById(
      "cnd_flt_licenses_question_6_msg"
    );

    let cnd_flt_licenses_question_6_description = document.getElementById(
      "cnd_flt_licenses_question_6_description"
    );
    let cnd_flt_licenses_question_6_description_msg = document.getElementById(
      "cnd_flt_licenses_question_6_description_msg"
    );

    // Question 1
    if (
      cnd_flt_licenses_question_1_yes.checked == false &&
      cnd_flt_licenses_question_1_no.checked == false
    ) {
      if (applyError == false) {
        cnd_flt_licenses_question_1_yes.focus();
      }
      cnd_flt_licenses_question_1_yes.classList.add("border-danger");
      cnd_flt_licenses_question_1_no.classList.add("border-danger");
      cnd_flt_licenses_question_1_msg.innerText =
        "Please select one of the above options!";
      applyError = true;
    } else {
      // setFormData({...formData,
      //   cnd_flt_licenses_question_1 : cnd_flt_licenses_question_1_yes.checked == true ? "1" : "0",
      // })
      cnd_flt_licenses_question_1_yes.classList.remove("border-danger");
      cnd_flt_licenses_question_1_no.classList.remove("border-danger");
      cnd_flt_licenses_question_1_msg.innerText = "";
    }

    // Question 2
    if (
      cnd_flt_licenses_question_2_yes.checked == false &&
      cnd_flt_licenses_question_2_no.checked == false
    ) {
      if (applyError == false) {
        cnd_flt_licenses_question_2_yes.focus();
      }
      cnd_flt_licenses_question_2_yes.classList.add("border-danger");
      cnd_flt_licenses_question_2_no.classList.add("border-danger");
      cnd_flt_licenses_question_2_msg.innerText =
        "Please select one of the above options!";
      applyError = true;
    } else {
      cnd_flt_licenses_question_2_yes.classList.remove("border-danger");
      cnd_flt_licenses_question_2_no.classList.remove("border-danger");
      cnd_flt_licenses_question_2_msg.innerText = "";
    }

    // Question 3
    if (
      !cnd_flt_licenses_question_3.value ||
      cnd_flt_licenses_question_3.value.trim() == ""
    ) {
      if (applyError == false) {
        cnd_flt_licenses_question_3.focus();
      }
      cnd_flt_licenses_question_3.classList.add("border-danger");
      cnd_flt_licenses_question_3_msg.innerText =
        "Please enter the years in digit!";
      applyError = true;
    } else {
      cnd_flt_licenses_question_3.classList.remove("border-danger");
      cnd_flt_licenses_question_3_msg.innerText = "";
    }

    // Question 4
    if (
      cnd_flt_licenses_question_4_yes.checked == false &&
      cnd_flt_licenses_question_4_no.checked == false
    ) {
      if (applyError == false) {
        cnd_flt_licenses_question_4_yes.focus();
      }
      cnd_flt_licenses_question_4_yes.classList.add("border-danger");
      cnd_flt_licenses_question_4_no.classList.add("border-danger");
      cnd_flt_licenses_question_4_msg.innerText =
        "Please select one of the above options!";
      applyError = true;
    } else {
      cnd_flt_licenses_question_4_yes.classList.remove("border-danger");
      cnd_flt_licenses_question_4_no.classList.remove("border-danger");
      cnd_flt_licenses_question_4_msg.innerText = "";
    }

    // Question 5
    if (
      cnd_flt_licenses_question_5_yes.checked == false &&
      cnd_flt_licenses_question_5_no.checked == false
    ) {
      if (applyError == false) {
        cnd_flt_licenses_question_5_yes.focus();
      }
      cnd_flt_licenses_question_5_yes.classList.add("border-danger");
      cnd_flt_licenses_question_5_no.classList.add("border-danger");
      cnd_flt_licenses_question_5_msg.innerText =
        "Please select one of the above options!";
      applyError = true;
    } else {
      cnd_flt_licenses_question_5_yes.classList.remove("border-danger");
      cnd_flt_licenses_question_5_no.classList.remove("border-danger");
      cnd_flt_licenses_question_5_msg.innerText = "";
    }

    if (cnd_flt_licenses_question_5_yes.checked == true) {
      if (
        !cnd_flt_licenses_question_5_description.value ||
        cnd_flt_licenses_question_5_description.value.trim() == ""
      ) {
        if (applyError == false) {
          cnd_flt_licenses_question_5_description.focus();
        }
        cnd_flt_licenses_question_5_description.classList.add("border-danger");
        cnd_flt_licenses_question_5_description_msg.innerText =
          "Please enter the description!";
        applyError = true;
      } else {
        cnd_flt_licenses_question_5_description.classList.remove(
          "border-danger"
        );
        cnd_flt_licenses_question_5_description_msg.innerText = "";
      }
    }

    // Question 6
    if (
      cnd_flt_licenses_question_6_yes.checked == false &&
      cnd_flt_licenses_question_6_no.checked == false
    ) {
      if (applyError == false) {
        cnd_flt_licenses_question_6_yes.focus();
      }
      cnd_flt_licenses_question_6_yes.classList.add("border-danger");
      cnd_flt_licenses_question_6_no.classList.add("border-danger");
      cnd_flt_licenses_question_6_msg.innerText =
        "Please select one of the above options!";
      applyError = true;
    } else {
      cnd_flt_licenses_question_6_yes.classList.remove("border-danger");
      cnd_flt_licenses_question_6_no.classList.remove("border-danger");
      cnd_flt_licenses_question_6_msg.innerText = "";
    }

    if (cnd_flt_licenses_question_6_yes.checked == true) {
      if (
        !cnd_flt_licenses_question_6_description.value ||
        cnd_flt_licenses_question_6_description.value.trim() == ""
      ) {
        if (applyError == false) {
          cnd_flt_licenses_question_6_description.focus();
        }
        cnd_flt_licenses_question_6_description.classList.add("border-danger");
        cnd_flt_licenses_question_6_description_msg.innerText =
          "Please enter the description!";
        applyError = true;
      } else {
        cnd_flt_licenses_question_6_description.classList.remove(
          "border-danger"
        );
        cnd_flt_licenses_question_6_description_msg.innerText = "";
      }
    }

    // console.log("Demo test :=>" + cnd_flt_licenses_question_1_no.checked);
    return applyError;
  };

  const onInputValues = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateLicense = (e) => {
    e.preventDefault();
    // console.log("target value Data  :=>"+JSON.stringify(formData));
    let checkValid = validForm();
    if (checkValid == false) {
      document.getElementById("loader").style.display = "block";
      formData["users_access_token"] = props.userAuth.users_access_token;
      formData["cnd_flt_licenses_progress_bar"] = "1";
      // let formdata = {};
      dispatch(
        updateDataOnRequest(
          JSON.stringify(formData),
          "UpdateFltLicense",
          "UPDATE_FLT_LICENSE",
          getDataOnRequest(
            props.userAuth.users_access_token,
            "ProgressBar",
            "SET_PROGRESS"
          )
        )
      );
    }
  };

  useEffect(() => {
    let form_data = {};
    if (Object.keys(props.fltLicences).length > 0) {
      form_data = props.fltLicences;
      setFormData(form_data);
    }

    if (form_data.cnd_flt_licenses_question_5 == "1") {
      setQuestion5Description(true);
    } else {
      setQuestion5Description(false);
    }

    if (form_data.cnd_flt_licenses_question_6 == "1") {
      setQuestion6Description(true);
    } else {
      setQuestion6Description(false);
    }

    // console.log("Data Is Testing :=> " + JSON.stringify(form_data.cnd_flt_licenses_question_6));
  }, [props.fltLicences]);

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  // console.log("Data Is :=> " + JSON.stringify(formData));

  return (
    <React.Fragment>
      {/* <EmployementGapForm /> */}
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            FLT License
          </h5>
        </div>
        <form noValidate>
          <div className="row m-b20">
            <div className="col-lg-12 col-md-12 m-b0">
              <Label labelName="1). Do you hold a valid counterbalance FLT licence?" />
              <div className="form-group ml-3">
                <Form.Check
                  outline
                  label={"Yes"}
                  value={"1"}
                  checked={
                    formData.cnd_flt_licenses_question_1 == "1" ? true : false
                  }
                  name="cnd_flt_licenses_question_1"
                  onChange={(e) => onInputValues(e)}
                  type="radio"
                  id={"cnd_flt_licenses_question_1_yes"}
                />

                <Form.Check
                  outline
                  label={"No"}
                  value={"0"}
                  checked={
                    formData.cnd_flt_licenses_question_1 == "0" ? true : false
                  }
                  onChange={(e) => onInputValues(e)}
                  name="cnd_flt_licenses_question_1"
                  type="radio"
                  id={"cnd_flt_licenses_question_1_no"}
                />
                <div
                  className={"text-danger mb-0"}
                  id="cnd_flt_licenses_question_1_msg"
                ></div>
              </div>
            </div>

            <div className="col-lg-12 col-md-06 m-b0">
              <Label labelName="2). Do you hold a valid counterbalance Pivot, Articulated, Bendi or Flexi licence?" />
              <div className="form-group ml-3">
                <Form.Check
                  outline
                  label={"Yes"}
                  value={"1"}
                  name="cnd_flt_licenses_question_2"
                  checked={
                    formData.cnd_flt_licenses_question_2 == "1" ? true : false
                  }
                  onChange={(e) => onInputValues(e)}
                  type="radio"
                  id={"cnd_flt_licenses_question_2_yes"}
                />

                <Form.Check
                  outline
                  label={"No"}
                  value={"0"}
                  checked={
                    formData.cnd_flt_licenses_question_2 == "0" ? true : false
                  }
                  name="cnd_flt_licenses_question_2"
                  onChange={(e) => onInputValues(e)}
                  type="radio"
                  id={"cnd_flt_licenses_question_2_no"}
                />

                <div
                  className={"text-danger mb-0"}
                  id="cnd_flt_licenses_question_2_msg"
                ></div>
              </div>
            </div>

            <div className="col-lg-12 col-md-06 m-b0">
              <Label labelName="3). How many years of relevant warehouse experience do you have?" />
              <div className="form-group ">
                <Input
                  type="number"
                  min={"1"}
                  // label="License Number"
                  placeholder={"Eg., 1/2/3"}
                  changeHandler={(e) => onInputValues(e)}
                  name="cnd_flt_licenses_question_3"
                  id={"cnd_flt_licenses_question_3"}
                  value={formData.cnd_flt_licenses_question_3}
                  lg={12}
                />
              </div>
            </div>

            <div className="col-lg-12 col-md-06 m-b0">
              <Label labelName="4). Have you worked in a BRC Accredited warehouse before?" />
              <div className="form-group ml-3">
                <Form.Check
                  outline
                  label={"Yes"}
                  value={"1"}
                  checked={
                    formData.cnd_flt_licenses_question_4 == "1" ? true : false
                  }
                  name="cnd_flt_licenses_question_4"
                  onChange={(e) => onInputValues(e)}
                  type="radio"
                  id={"cnd_flt_licenses_question_4_yes"}
                />

                <Form.Check
                  outline
                  label={"No"}
                  value={"0"}
                  checked={
                    formData.cnd_flt_licenses_question_4 == "0" ? true : false
                  }
                  name="cnd_flt_licenses_question_4"
                  onChange={(e) => onInputValues(e)}
                  type="radio"
                  id={"cnd_flt_licenses_question_4_no"}
                />
                <div
                  className={"text-danger mb-0"}
                  id="cnd_flt_licenses_question_4_msg"
                ></div>
              </div>
            </div>

            <div className="col-lg-12 col-md-06 m-b0">
              <Label labelName="5). Are you currently suffering from any medical problem that could affect your ability to drive an FLT safely?" />
              <div className="form-group ml-3">
                <Form.Check
                  outline
                  label={"Yes"}
                  value={"1"}
                  checked={
                    formData.cnd_flt_licenses_question_5 == "1" ? true : false
                  }
                  name="cnd_flt_licenses_question_5"
                  onChange={() =>
                    showDescription("cnd_flt_licenses_question_5_yes")
                  }
                  onChangeCapture={(e) => onInputValues(e)}
                  type="radio"
                  id={"cnd_flt_licenses_question_5_yes"}
                />

                <Form.Check
                  outline
                  label={"No"}
                  value={"0"}
                  checked={
                    formData.cnd_flt_licenses_question_5 == "0" ? true : false
                  }
                  name="cnd_flt_licenses_question_5"
                  type="radio"
                  id={"cnd_flt_licenses_question_5_no"}
                  onChangeCapture={(e) => onInputValues(e)}
                  onChange={() =>
                    showDescription("cnd_flt_licenses_question_5_no")
                  }
                />
                <div
                  className={"text-danger mb-0"}
                  id="cnd_flt_licenses_question_5_msg"
                ></div>
              </div>
              {question5description == true ? (
                <>
                  <Textarea
                    name="cnd_flt_licenses_question_5_description"
                    id={"cnd_flt_licenses_question_5_description"}
                    label="Description"
                    value={
                      question5description == true
                        ? formData.cnd_flt_licenses_question_5_description
                        : ""
                    }
                    changeHandler={(e) => onInputValues(e)}
                    lg={12}
                  />
                  <div
                    className={"text-danger mb-0 ml-3"}
                    id="cnd_flt_licenses_question_5_description_msg"
                  ></div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="col-lg-12 col-md-06 m-b0">
              <Label labelName="6). Are you currently taking any medicines or prescription drugs that may induce drowsiness or could otherwise impair your FLT driving ability?" />
              <div className="form-group ml-3">
                <Form.Check
                  outline
                  label={"Yes"}
                  value={"1"}
                  checked={
                    formData.cnd_flt_licenses_question_6 == "1" ? true : false
                  }
                  name="cnd_flt_licenses_question_6"
                  type="radio"
                  id={"cnd_flt_licenses_question_6_yes"}
                  onChangeCapture={(e) => onInputValues(e)}
                  onChange={() =>
                    showDescription("cnd_flt_licenses_question_6_yes")
                  }
                />
                <Form.Check
                  outline
                  label={"No"}
                  value={"0"}
                  checked={
                    formData.cnd_flt_licenses_question_6 == "0" ? true : false
                  }
                  name="cnd_flt_licenses_question_6"
                  onChangeCapture={(e) => onInputValues(e)}
                  type="radio"
                  id={"cnd_flt_licenses_question_6_no"}
                  onChange={() =>
                    showDescription("cnd_flt_licenses_question_6_no")
                  }
                />
                <div
                  className={"text-danger mb-0"}
                  id="cnd_flt_licenses_question_6_msg"
                ></div>
              </div>

              {question6description == true ? (
                <>
                  <Textarea
                    name="cnd_flt_licenses_question_6_description"
                    id={"cnd_flt_licenses_question_6_description"}
                    changeHandler={(e) => onInputValues(e)}
                    value={
                      question6description == true
                        ? formData.cnd_flt_licenses_question_6_description
                        : ""
                    }
                    label="Description"
                    lg={12}
                  />
                  <div
                    className={"text-danger mb-0 ml-3"}
                    id="cnd_flt_licenses_question_6_description_msg"
                  ></div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <Button
            disabled={loader}
            className="site-button m-b30 pull-right mr-3"
            onClick={(e) => updateLicense(e)}
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader
              ? props.fltLicences.cnd_flt_licenses_question_1
                ? "Update & Next"
                : "Save & Next"
              : "Loading..."}
          </Button>
          {/* <button className="site-button m-b30" onClick={(e)=>updateUserProfilePersonalInfo(e)}>Save Setting</button> */}
        </form>
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    fltLicences: state.fltLicences.fltLicences,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(FltLicenseForm);
