import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Nursery-banner.jpg";
// import EducareImg from "./../images/ourservices/Nursery.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/commercial/our-service-commercial-background.jpg";
import commercialImg from "./../images/new-updated-images/our-services/commercial/our-service-commercial-content.jpg";
import Footer from "../components/Layout/Footer";
import bnr3 from "./../images/lines.png";

export default function Commercial() {
  // Add title
  document.getElementById("titleText").innerText =
    "Staffing Connect - Our Services - Commercial";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/commercial"
            ? "0rem"
            : ""  }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">
                <b style={{ display: "none" }}>
                  Staffing Connect - Our Services -
                </b>
                Commercial
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                  {window.location.pathname == "/mobile/commercial" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Commercial</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b0">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Commercial</h2>
                  {/* <h6 className="mb-0">
                    Welcome to Staffing Connect - Your Premier Partner in
                    Commercial Workforce Solutions!
                  </h6> */}
                </div>
              </div>

              <div className="row  m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={commercialImg} alt="" />
                </div>
                <div
                  className="col-md-12 col-lg-6 "
                  style={{ marginBottom: "-6%" }}
                >
                  {/* <h2 className="m-b5"> Home Care</h2> */}
                  <p className="spacebar mt-3"></p>
                  <p>
                  Ready to embark on a journey of workforce transformation? Connect with Staffing Connect today!
                  </p>

                  <p>
                    Welcome to Staffing Connect - Your Gateway to Unparalleled Commercial Workforce Solutions!
                    Elevate your business to new heights with Staffing Connect, your premier partner for customized staffing solutions in the commercial sector. We specialize in providing top-tier talent that not only meets but exceeds the dynamic needs of your enterprise. At Staffing Connect, we go beyond traditional recruitment, offering a strategic partnership to ensure your workforce aligns seamlessly with your commercial objectives.
                  </p>

                  <h6>Our Mission:</h6>
                  <p>Staffing Connect is on a mission to propel commercial enterprises to unprecedented success through strategic and tailored workforce solutions. With a dedicated team that blends industry expertise with a personalized touch, we are committed to helping your business thrive in a competitive landscape.</p>
                </div>

                <div className="col-md-12 col-lg-12 mt-4">
                  <h6>Why chose Staffing Connect?</h6>

                  <ol className="ml-4">
                    <li>
                      <b>Strategic Recruitment:</b>
                      <ul className="ml-3">
                        <li>
                        We recognize that the commercial sector demands agility and adaptability. Our recruitment strategies are meticulously crafted to align seamlessly with your business goals, ensuring that your workforce becomes a strategic asset in achieving success.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Diverse Talent Pool:</b>
                      <ul className="ml-3">
                        <li>
                        Access a diverse pool of skilled professionals capable of driving success across various commercial domains. Our expansive network allows us to connect you with the right talent, whether you're in retail, finance, technology, or any other commercial field.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Innovation in Staffing:</b>
                      <ul className="ml-3">
                        <li>
                        Embrace the future of workforce solutions with our innovative approach. We understand that the commercial sector is ever-evolving, and we stay ahead of the curve by implementing cutting-edge strategies that adapt to the dynamic landscape, ensuring your business remains at the forefront of innovation.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div className="col-md-12 col-lg-12 mt-2">
                  <h6>
                  Partner with Staffing Connect and unlock the full potential of your business through a strategic workforce that drives success. Whether you are a startup or an established enterprise, our commitment is to provide you with the talent and solutions that propel your commercial endeavors to new heights.Ready to revolutionize your workforce strategy? Connect with Staffing Connect today!
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
