import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import swal from "sweetalert";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { formInputFieldsSign, validationSchemaSign } from "./SignDocHelper";
import LoadFormFields from "../../Layout/LoadFormFields";
import IdentityDocumentList from "./IdentityDocumentList";
import composedIdentityDocumentsContainer from "../../../containers/IdentityDocumentsContainer";
import pdfTemp from "../../../docs/Employment-Contract.pdf";
import { Tooltip } from "@mui/material";
import IdentityDocumentListMobile from "./IdentityDocumentListMobile";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const IdentityContainer = ({ deviceInfo, ...props }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [msgShow, setMessageShow] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { progress, userData } = props;
  const [viewToolTip, setViewToolTip] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemaSign),
    mode: "onBlur",
    reValidateMode: "submit",
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onSubmitSign = (data) => {
    let formData = data;
    formData["users_access_token"] = props.userAuth.users_access_token;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "UpdateUserSign",
        "",
        getDataOnRequest(
          props.userAuth.users_access_token,
          "ClientProfileGet",
          "SET_USER_DATA"
        )
      )
    );
    setShow(false);
    setPageNumber(1);
    //history.push('/dashboard')
    swal({
      //checkProf:"true",
      title: "Congratulations!",
      icon: "success",
      text:
        "Hey " +
        userData.users_firstname +
        ", your profile information is 100% completed ! You are ready to apply for the job !",
      //timer:"7000",
      //dangerMode: true,
      buttons: {
        Ok: {
          checkProf: true,
        },
      },
      //buttons:false,
      closeOnClickOutside: true,
    }).then((checkProf) => {
      window.location.href = "/dashboard";
    });

    //window.location.href = "/dashboard";
  };

  const checkFormProfileCompletion = () => {
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_reference_2 == "1") {
      totalProgress = totalProgress + 10;
    }
    // if (progress.cnd_types_of_services !== "") {
    //   totalProgress = totalProgress + 10;
    // }
    if (progress.progress_bar_agreement_3 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_bank_4 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_right_to_work_5 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_work_preference_6 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_compliance_7 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_dbs_8 == "1") {
      totalProgress = totalProgress + 15;
    }
    if (progress.progress_bar_identity_9 == "1") {
      totalProgress = totalProgress + 15;
    }
    if (totalProgress >= 100) {
      setShow(true);
    } else {
      setMessageShow(true);
    }
  };

  const onChangeInputvalue = (e) => {
    // const { name, value } = e.target;
    // let holidayDetail = {...{a}
    // holidayDetail[name] = value;
    // setFormdata(holidayDetail);
  };

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Indentity Documents
          </h5>
          {deviceInfo && deviceInfo == "Mobile" ? (
            <Tooltip
              title="Add Identity Document"
              className="text-sm"
              open={viewToolTip}
              placement="top"
            >
              <a
                href="/create-identity-document"
                className="pull-right btn btn-success"
                onTouchStart={(e) => {
                  setViewToolTip(true);
                }}
                onTouchEnd={(e) => {
                  setViewToolTip(false);
                }}
              >
                <i className="fa fa-plus"></i>
              </a>
            </Tooltip>
          ) : (
            <a
              href="/create-identity-document"
              className="pull-right btn btn-secondry yellow site-button btn-sm"
            >
              Add Identity Document
            </a>
          )}
        </div>

        {deviceInfo && deviceInfo == "Mobile" ? (
          <IdentityDocumentListMobile />
        ) : (
          <IdentityDocumentList />
        )}

        {/* <Link to={"/agreements"} className="site-button  m-b10 listingnextbtn">
          Next
        </Link> */}

        <br />
        <br />
      </div>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Sign Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="job-bx submit-resume">
            <form onSubmit={handleSubmit(onSubmitSign)}>
              <Document
                className="custom-canvas"
                file={pdfTemp}
                width={"800"}
                height={"800"}
                onLoadError={console.error}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page width="800" height="800" pageNumber={page} />
                  ))}
              </Document>
              <hr />
              <LoadFormFields
                register={register}
                errors={errors}
                formInputFields={formInputFieldsSign()}
                onChangeInputvalue={(e) => onChangeInputvalue(e)}
                reset={reset}
                control={control}
                data={{}}
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={msgShow} onHide={() => setMessageShow(false)} size="l">
        <Modal.Header closeButton>
          <Modal.Title>Sign Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="job-bx submit-resume">
            <h6>Please complete your profile to sign the contract.</h6>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    progress: state.progress.progress,
    userData: state.userProfile.userData,
    userAuth: state.auth.authUserData,
  };
};

export default connect(mapStateToProps)(
  composedIdentityDocumentsContainer(IdentityContainer)
);
