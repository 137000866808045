import React, { useEffect, useState, userdata } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { getBase64 } from "./../../helpers/FileToBase64";
import { CandidatePersonalInfoValidation } from "./../../helpers/PermanentCandidateValidation";
import { jobsArray } from "../../components/Element/PermanentRequirements/JobArray";
import composedUserProfile from "../../containers/UserProfile";
import PersonalInfoFields from "../../components/Element/PermanentRequirements/PersonalInfoForm";
import AddressFields from "../../components/Element/PermanentRequirements/AddressFields";
import Resume from "../../components/Element/Resume";
import AppliedJobsForm from "../../components/Element/PermanentRequirements/AppliedJobsForm";
import { updatePermanentCandidateProfile } from "../../redux/actions/UserProfileActions";
import { useDispatch } from "react-redux";
import { santetizeData } from "../../helpers/FormFieldsSanetizations";
import { convertToSnakeCase } from "../../services/StringOperation";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { isMobileOrLaptop } from "../../services/CheckDevice";
import swal from "sweetalert";

function Permanentform(props) {
  const [errors, setErrors] = useState({});
  const [userdata, setFormdata] = useState(props.userData);
  const [jobsArrayValue, setJobsArrayValue] = useState([]);
  const [jobsTitle, setJobsTitle] = useState("");
  let getTitle;
  const dispatch = useDispatch();

  useEffect(() => {
    setFormdata(props.userData);
  }, [props.userData]);

  const getTitleByID = (userdata) => {
    props.jobsdetails.map((item, index) =>{
      if(item.jobs_id == userdata["cnd_permanent_jobs_id"]){
        getTitle = item.jobs_title;
      }
    })
    // console.log("Demo Data For test :=> "+JSON.stringify(getTitle));
    if(getTitle){
      setJobsTitle(getTitle);
    }
  }

  useEffect(() => {
    const lenOfData = props.jobsdetails;
    let dropData = [{}];

    dropData = lenOfData.map((item) => item.jobs_title).map((title) => {
        const job = lenOfData.find((item) => item.jobs_title === title);
        if (job.jobs_job_type_contract_type === "permanentrequirement") {
          return {
            name: title+(job.jobs_location_town ? " - ("+job.jobs_location_town+")" : ""),
            value: job.jobs_id,
          };
        }
        return null; // Return null for items that don't meet the condition
      }).filter((item) => item !== null); // Remove null items from the result

    dropData.unshift({ name: "Select Job Positions", value: "" });

    setJobsArrayValue(dropData);
  }, [props.jobsdetails]);

  const { loader } = props;

  const checkProfileComplete = () => {
    if (!userdata.cnd_birth_date) {
      swal({
        icon: "warning",
        title: "Please Complete the Profile!",
        text: "Please fill your profile before applying for this job!",
        timer: "5000",
        dangerMode: true,
        buttons: {
          Ok: {
            checkProf: true,
          },
        },
        //defeat:true,
        //buttons:false,
        closeOnClickOutside: true,
      }).then((checkProf) => {
        window.location.href = "/permanent-job-profile";
      });
      return true;
    }
  };

  const validateForm = (callType) => {
    let applyError = false;
    if (callType == "submit" || callType == "cnd_permanent_jobs_id") {
      let cnd_permanent_jobs_id = document.getElementById("cnd_permanent_jobs_id");
      let cnd_permanent_jobs_id_msg = document.getElementById(
        "cnd_permanent_jobs_id_msg"
      );

      if (!cnd_permanent_jobs_id.value) {
        cnd_permanent_jobs_id.classList.add("border-danger");
        cnd_permanent_jobs_id_msg.innerText =
          "Please select the job position to apply for job role!";
        applyError = true;
      } else {
        cnd_permanent_jobs_id.classList.remove("border-danger");
        cnd_permanent_jobs_id_msg.innerText = "";
      }
    }

    if (callType == "submit") {
      return applyError;
    }
  };

  const onChangeInputvalue = async (e) => {
    // if (e.target.name == "cnd_permanent_job_title") {
    userdata["cnd_permanent_jobs_id"] = e.target.value;
    userdata["cnd_permanent_job_title"] = e.target.value;
    // }
    setFormdata(userdata);
    getTitleByID(userdata);

    validateForm(e.target.name);
  };

  const updateUserProfilePersonalInfo = (e) => {
    e.preventDefault();

    userdata["cnd_permanent_jobs_id"] = e.target[0].value;
    userdata["cnd_permanent_job_title"] = e.target[0].value;
    if (checkProfileComplete() != true) {
      if (validateForm("submit") == false) {
        document.getElementById("loader").style.display = "block";
        userdata["users_access_token"] = props.userAuth.users_access_token;
        userdata["cnd_job_position"] = jobsTitle ? convertToSnakeCase(jobsTitle) : "";
        dispatch(
          updatePermanentCandidateProfile(
            santetizeData(userdata),
            "UpdatePersonalPermanentInfo"
          )
        );
      }
    }
  };

  useEffect(() => {
    let scrollFocusElement;
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    let mobileScreen = document.getElementById("mobileScreen");
    // console.log("Device Type:", deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 800);
    } else {
      window.scrollTo(0, 0);
    }
  }, []);
  
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            {/* Personal Information */}Apply More Jobs
          </h5>
          {/* <a
                href="/applied-jobs"
                className="btn btn-success site-button float-right"
            >
              Applied Jobs
            </a> */}
        </div>
        <form
          // ref={formRef}
          onSubmit={(e) => updateUserProfilePersonalInfo(e)}
          onKeyUp={(e) => onChangeInputvalue(e)}
          onClick={(e) => onChangeInputvalue(e)}
          onSubmitCapture={() => checkProfileComplete()}
          noValidate
          encType="multipart/form-data"
        >
          <div className="row m-b30">
            <AppliedJobsForm
              errors={errors}
              data={jobsArrayValue}
              onChange={(e) => onChangeInputvalue(e)}
              
            />
          </div>
          <Button
            // disabled={loader}
            className="site-button m-b30 float-right"
            type="submit"
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader ? "Apply" : ""}{" "}
          </Button>
          <br />
          <br />
        </form>
      </div>
      {/* <TrainingQualifications /> */}
    </React.Fragment>
  );
}

export default composedUserProfile(Permanentform);
