const initialState = {progress: []}

export const progress = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_PROGRESS':
      return {progress: action.payload}; 
  }
  return state
}


