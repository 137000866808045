import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import constants from "../../../constants";
import Skeleton from "@mui/material/Skeleton";
import Swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import UserProfile from "../../../containers/UserProfile";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import { redirectUrl } from "../../../services/GlobalUrls";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { Avatar, Typography } from "@mui/material";
import { getBase64 } from "../../../helpers/FileToBase64";
import { updateUserProfile } from "../../../redux/actions/UserProfileActions";
import { santetizeData } from "../../../helpers/FormFieldsSanetizations";
import { Modal } from "react-bootstrap";

const UpdateCandidateProfileCV = ({ callParam, ...props }) => {
  const [userdata, setFormdata] = useState(props.userData);
  const [loading, setLoading] = useState(true);
  const [updateCVModal, setUpdateCVModal] = useState(false);
  const [uploadFileElement, setUploadFileElement] = useState("");
  // Modal States - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  // Modal Function - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // Modal State - End

  const dispatch = useDispatch();

  useEffect(() => {
    setFormdata(props.userData);
  }, [props.userData]);

  useEffect(() => {
    // Loader - Start
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, []);

  const updateCVModalOperation = (callParam) => {
    setUpdateCVModal(callParam);
  };

  const uploadCVFile = async (e) => {
    document.getElementById("loader").style.display = "block";
    let userdata = {};
    if (e.target.name === uploadFileElement) {
      if (e.target.files[0])
        userdata[uploadFileElement] = await getBase64(e.target.files[0]);
    }

    if (userdata[uploadFileElement]) {
      userdata["users_access_token"] = props.userAuth.users_access_token;
      dispatch(
        updateUserProfile(santetizeData(userdata), "UpdateJobCandidateCV")
      );
      setUploadFileElement("");
      setUpdateCVModal(false);
    }
  };

  if (callParam) {
    return (
      <React.Fragment>
        <div className="border border-grey" style={{ borderRadius: "20px" }}>
          <h5 className="mb-0 p-3">
            <b className="" style={{ fontWeight: "700" }}>
            {callParam === "cnd_upload_cv" ? (
              <span>
                CV
                {userdata.cnd_upload_cv ? (
                  <i className="fa fa-check-circle m-l5 text-success"></i>
                ) : (<b className="text-danger">*</b>)}
              </span>
            ) : callParam === "cnd_upload_cover" ? (
              <span>
                Cover Letter
                {userdata.cnd_upload_cover && (
                  <i className="fa fa-check-circle m-l5 text-success"></i>
                )}
              </span>
            ) : callParam === "cnd_upload_personal_statement" ? (
              <span>
              Personal Statement
              {userdata.cnd_upload_personal_statement && (
                <i className="fa fa-check-circle m-l5 text-success"></i>
              )}
            </span>
            ) : callParam === "cnd_upload_identity_video" ? (
              <span>
              Identity Video
              {userdata.cnd_upload_identity_video ? (
                <i className="fa fa-check-circle m-l5 text-success"></i>
              ) : (<b className="text-danger">*</b>)}
            </span>
            )  : (
              ""
            )}
              <Tooltip
                className="pull-right"
                title={
                  "Update " +
                  (callParam == "cnd_upload_cv"
                    ? "CV"
                    : callParam == "cnd_upload_cover"
                    ? "Cover Letter"
                    : callParam == "cnd_upload_personal_statement"
                    ? "Personal Statement" : callParam == "cnd_upload_identity_video" ? "Identity Video"
                    : "")
                }
              >
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    updateCVModalOperation(true);
                    setUploadFileElement(callParam);
                  }}
                >
                  <i
                    className="fa fa-pencil-square-o text-primary pull-right mt-1 cursor-pointer"
                    style={{ fontSize: "125%" }}
                  ></i>
                </a>
              </Tooltip>
            </b>
          </h5>
          <div
            style={{
              width: "100%",
              height: "1px",
              right: "0",
              left: "20",
              backgroundColor: "#ccc",
            }}
          ></div>

          {loading == false ? (
            <>
              <p className="p-3">
                <b className="ml-1 text-primary cursor-pointer">
                  <Tooltip
                    className=""
                    title={
                      "View " +
                      (callParam == "cnd_upload_cv"
                        ? "CV"
                        : callParam == "cnd_upload_cover"
                        ? "Cover Letter"
                        : callParam == "cnd_upload_personal_statement"
                        ? "Personal Statement"
                        : callParam == "cnd_upload_identity_video" ? "Identity Video"
                        : "")
                    }
                  >
                    <a
                      onClick={() => {
                        if (
                          (callParam == "cnd_upload_cv" &&
                            userdata.cnd_cv_forderpath &&
                            userdata.cnd_upload_cv) ||
                          (callParam == "cnd_upload_cover" &&
                            userdata.cnd_cover_forderpath &&
                            userdata.cnd_upload_cover) ||
                          (callParam == "cnd_upload_personal_statement" &&
                            userdata.cnd_upload_personal_statement_folder &&
                            userdata.cnd_upload_personal_statement) || (callParam == "cnd_upload_identity_video" &&
                              userdata.cnd_upload_identity_video_folder_path &&
                              userdata.cnd_upload_identity_video)
                        ) {
                          viewFileModal(
                            constants.IMAGE_URL +
                              (callParam == "cnd_upload_cv"
                                ? userdata.cnd_cv_forderpath +
                                  userdata.cnd_upload_cv
                                : callParam == "cnd_upload_cover"
                                ? userdata.cnd_cover_forderpath +
                                  userdata.cnd_upload_cover
                                : callParam == "cnd_upload_personal_statement"
                                ? userdata.cnd_upload_personal_statement_folder +
                                  userdata.cnd_upload_personal_statement : callParam == "cnd_upload_identity_video"
                                  ? userdata.cnd_upload_identity_video_folder_path +
                                    userdata.cnd_upload_identity_video : "")
                          );
                        } else {
                          Swal.fire({
                            title:
                              (callParam == "cnd_upload_cv"
                                ? "CV"
                                : callParam == "cnd_upload_cover"
                                ? "Cover Letter"
                                : callParam == "cnd_upload_personal_statement"
                                ? "Personal Statement"
                                : callParam == "cnd_upload_identity_video" ? "Identity Video"
                                : "") + " Not Available",
                            text:
                              "Do you want to upload " +
                              (callParam == "cnd_upload_cv"
                                ? "CV"
                                : callParam == "cnd_upload_cover"
                                ? "Cover Letter"
                                : callParam == "cnd_upload_personal_statement"
                                ? "Personal Statement"
                                : callParam == "cnd_upload_identity_video" ? "Identity Video"
                                : "") +
                              "?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Ok",
                            // confirmButtonColor: 'green',
                            cancelButtonColor: "red",
                            cancelButtonText: "Cancel",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              updateCVModalOperation(true);
                              setUploadFileElement(callParam);
                            }
                          });
                        }
                      }}
                    >
                      <b>
                        {callParam == "cnd_upload_cv"
                          ? "Your CV"
                          : callParam == "cnd_upload_cover"
                          ? "Cover Letter"
                          : callParam == "cnd_upload_personal_statement"
                          ? "Personal Statement"
                          : callParam == "cnd_upload_identity_video" ? "Identity Video"
                          : ""}
                      </b>
                    </a>
                  </Tooltip>
                </b>
                <Tooltip
                  className="pull-right"
                  title={
                    "View " +
                    (callParam == "cnd_upload_cv"
                      ? "CV"
                      : callParam == "cnd_upload_cover"
                      ? "Cover Letter"
                      : callParam == "cnd_upload_personal_statement"
                      ? "Personal Statement" : callParam == "cnd_upload_identity_video" ? "Identity Video"
                      : "")
                  }
                >
                  <a
                    onClick={() => {
                      if (
                        (callParam == "cnd_upload_cv" &&
                          userdata.cnd_cv_forderpath &&
                          userdata.cnd_upload_cv) ||
                        (callParam == "cnd_upload_cover" &&
                          userdata.cnd_cover_forderpath &&
                          userdata.cnd_upload_cover) ||
                        (callParam == "cnd_upload_personal_statement" &&
                          userdata.cnd_upload_personal_statement_folder &&
                          userdata.cnd_upload_personal_statement) || (callParam == "cnd_upload_identity_video" &&
                            userdata.cnd_upload_identity_video_folder_path &&
                            userdata.cnd_upload_identity_video)
                      ) {
                        viewFileModal(
                          constants.IMAGE_URL +
                            (callParam == "cnd_upload_cv"
                              ? userdata.cnd_cv_forderpath +
                                userdata.cnd_upload_cv
                              : callParam == "cnd_upload_cover"
                              ? userdata.cnd_cover_forderpath +
                                userdata.cnd_upload_cover
                              : callParam == "cnd_upload_personal_statement"
                              ? userdata.cnd_upload_personal_statement_folder +
                                userdata.cnd_upload_personal_statement : callParam == "cnd_upload_identity_video"
                                ? userdata.cnd_upload_identity_video_folder_path +
                                  userdata.cnd_upload_identity_video : "")
                        );
                      } else {
                        Swal.fire({
                          title:
                            (callParam == "cnd_upload_cv"
                              ? "CV"
                              : callParam == "cnd_upload_cover"
                              ? "Cover Letter"
                              : callParam == "cnd_upload_personal_statement"
                              ? "Personal Statement"
                              : callParam == "cnd_upload_identity_video" ? "Identity Video"
                              : "") + " Not Available",
                          text:
                            "Do you want to upload " +
                            (callParam == "cnd_upload_cv"
                              ? "CV"
                              : callParam == "cnd_upload_cover"
                              ? "Cover Letter"
                              : callParam == "cnd_upload_personal_statement"
                              ? "Personal Statement"
                              : callParam == "cnd_upload_identity_video" ? "Identity Video"
                              : "") +
                            "?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          // confirmButtonColor: 'green',
                          cancelButtonColor: "red",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            updateCVModalOperation(true);
                            setUploadFileElement(callParam);
                          }
                        });
                      }
                    }}
                  >
                    <i
                      className={"fa "+(callParam == "cnd_upload_identity_video" ? "fa fa-film" : "fa-file-text")+" text-primary pull-right mt-1 cursor-pointer"}
                      style={{ fontSize: "120%" }}
                    ></i>
                  </a>
                </Tooltip>
              </p>
            </>
          ) : (
            <>
              <p className="p-3 mb-0">
                <div className="row mb-0">
                  <div className="col-sm-11">
                    <Skeleton
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#e6d5ff",
                      }}
                      variant="rounded"
                      className="bg-default mb-2"
                      width={"100%"}
                      height={30}
                    />
                  </div>
                  <div className="col-sm-1">
                    <Skeleton
                      variant="circular"
                      style={{ backgroundColor: "#e6d5ff" }}
                      className="bg-default mb-2"
                      width={30}
                      height={30}
                    />
                  </div>
                </div>
              </p>
            </>
          )}
        </div>
        <>
          {/* Update CV Modal - Start */}
          <Modal
            show={updateCVModal}
            onHide={() => updateCVModalOperation(false)}
            className="col-sm-12 fade"
            style={{ marginTop: "10%" }}
          >
            <div className="modal-header bg-dark text-white">
              <h5 className="" id="ResumeheadlineModalLongTitle">
                Update your{" "}
                {callParam == "cnd_upload_cv"
                  ? "CV"
                  : callParam == "cnd_upload_cover"
                  ? "Cover Letter"
                  : callParam == "cnd_upload_personal_statement"
                  ? "Personal Statement"
                  : callParam == "cnd_upload_identity_video" ? "Identity Video"
                  : ""}
              </h5>
              <a
                className="btn btn-danger btn-sm text-white m-2 cursor-pointer"
                onClick={() => updateCVModalOperation(false)}
              >
                <b>
                  <i className="fa fa-close"></i>
                </b>
              </a>
            </div>
            <div className="modal-body">
              <form className="attach-resume">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div
                      className="form-group border border-primary p-3"
                      style={{ borderRadius: "30px", position: "relative" }}
                    >
                      <div
                        className="custom-file"
                        style={{
                          borderRadius: "30px",
                          width: "100%",
                          height: "auto",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          cursor: "pointer",
                        }}
                      >
                        <p
                          className="m-auto align-self-center text-primary"
                          style={{ zIndex: 1, position: "absolute" }}
                        >
                          <center>
                            <i className="fa fa-upload mr-1"></i>
                            Upload Your{" "}
                            {callParam == "cnd_upload_cv"
                              ? "CV"
                              : callParam == "cnd_upload_cover"
                              ? "Cover Letter"
                              : callParam == "cnd_upload_personal_statement"
                              ? "Personal Statement"
                              : callParam == "cnd_upload_identity_video" ? "Identity Video"
                              : ""}
                          </center>
                        </p>
                        <input
                          type="file"
                          accept={callParam == "cnd_upload_identity_video" ? ".mp4, .mkv" : ".pdf, .png, .jpeg, .jpg, .doc, .docx, .docm, .dot, .dotm"}
                          name={callParam}
                          onChange={(e) => uploadCVFile(e)}
                          className="site-button form-control"
                          id="customFile"
                          style={{
                            opacity: 0,
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
          {/* Update CV Modal - End */}
          <GlobalViewFileModal
            modalOption={modalOption}
            closeFileModal={closeFileModal}
            fileUrl={fileUrl}
            loaderEnd={loaderEnd}
            modalHeight={modalHeight}
            callModal={callModal}
            title={
              callParam == "cnd_upload_cv"
                ? "CV"
                : callParam == "cnd_upload_cover"
                ? "Cover Letter"
                : callParam == "cnd_upload_personal_statement"
                ? "Personal Statement"
                : callParam == "cnd_upload_identity_video" ? "Identity Video"
                : ""
            }
            // fileName={fileName}
          />
        </>
      </React.Fragment>
    );
  } else {
    return <></>;
  }
};

export default UserProfile(UpdateCandidateProfileCV);
