import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
const AppoinmentContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const { userAuth, appoinments } = props;
    const dispatch = useDispatch();
    useEffect(()=>{
      if(userAuth.users_access_token && Object.keys(appoinments).length == 0)
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetAppoinment','SET_APPOINMENTS'))
    },[userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    appoinments:state.appoinments.appoinments
  };
};

const composedAppoinmentContainer = compose(connect(mapStateToProps), AppoinmentContainer)
export default composedAppoinmentContainer;

