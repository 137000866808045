import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  dbsCertificates,
  dbsDocumentName,
} from "../../../helpers/DocumentsList";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { redirectUrl } from "../../../services/GlobalUrls";

const DBSTableNoMobile = (props) => {
  const [dbsdocuments, SetDbsdocuments] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);
  const [inputSearchValue, setInputValue] = useState("");

  useEffect(() => {
    const lenOfData = props.dbsdocuments.length;
    // console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetDbsdocuments(props.dbsdocuments);
  }, [props.dbsdocuments]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  const matchesSearchTextContent = (dbsdocuments) => {
    const searchText = inputSearchValue ? inputSearchValue.toLowerCase() : "";
    if (
      dbsdocuments &&
      dbsdocuments.cnd_address_created_date &&
      dbsdocuments.cnd_address_address &&
      dbsdocuments.cnd_dbs_status
    ) {
      return (
        dbsdocuments.cnd_address_created_date
          .toLowerCase()
          .includes(searchText) ||
        dbsdocuments.cnd_address_address.toLowerCase().includes(searchText) ||
        dbsdocuments.cnd_dbs_status.toLowerCase().includes(searchText)
      );
    }
  };
  // Filter applied jobs based on the search text
  const filterRowsNo = dbsdocuments
    ? dbsdocuments.filter(matchesSearchTextContent)
    : [];

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        {/* Search Start */}
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          {filterRowsNo &&
            filterRowsNo.length > 0 &&
            filterRowsNo.slice(startIndex, endIndex).map((dbsdoc, i) => (
              <ul
                className="nav navbar-nav border border-grey p-4 mb-2"
                style={{ borderRadius: "20px" }}
                key={i}
              >
                <li className="text-dark">
                  <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                  {dbsdoc.cnd_address_created_date}
                </li>
                <li className="text-dark">
                  <i className="fa fa-map-marker mr-1 text-primary"></i>{" "}
                  {dbsdoc.cnd_address_address}
                </li>
                <li className="text-dark">
                  <i className="fa fa-circle-o-notch mr-1 text-primary"></i>{" "}
                  {dbsdoc.cnd_dbs_status}
                </li>
              </ul>
            ))}

          {dbsdocuments.length === 0 ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : filterRowsNo.length === 0 && inputSearchValue ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : (
            ""
          )}

          {filterRowsNo && filterRowsNo.length > 10 && (
            <TableDropdownAndPagination
              handleDropDown={handleDropDown}
              recordLen={recordLen}
              handleLeft={handleLeft}
              handleRight={handleRight}
              renderPageNumbers={renderPageNumbers}
              isDropdown={true}
              isPaggination={true}
            />
          )}
          <a
            href="#"
            onClick={() => redirectUrl("/identity-document")}
            className="pull-right btn btn-primary mr-2"
          >
            Next
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dbsdocuments: state.dbsdocuments.dbsdocuments,
  };
};

export default connect(mapStateToProps)(DBSTableNoMobile);
