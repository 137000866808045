const initialState = {branches: []}

export const branches = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_HOLIDAYS':
      return {branches: action.payload}; 
  }
  return state
}


