export const deleteFeedbackOptions = [
    {
      value: "it_was_easy_to_find_all_the_information_regarding_the_position",
      name: "It was easy to find all the information regarding the position?",
    },
    {
      value: "recruiter_told_me_how_to_prepare_for_the_shift_or_interview",
      name: "Recruiter told me how to prepare for the shift/interview?",
    },
    {
      value: "recruiter_was_friendly",
      name: "Recruiter was friendly?",
    },
    {
        value: "recruiter_was_professional",
        name: "Recruiter was professional?",
    },
    {
        value: "i_got_all_the_responses_from_recruiter_i_needed",
        name: "I got all the responses from recruiter I needed?",
    },
    {
        value: "all_my_questions_were_answered_in_a_timely_manner",
        name: "All my questions were answered in a timely manner?",
    },
    {
        value: "it_was_easy_to_apply_for_the_temporary_or_permanent_roles",
        name: "It was easy to apply for the temporary/permanent roles?",
    },
    {
        value: "it_was_easy_to_find_the_company_for_the_interview_or_shifts",
        name: "It was easy to find the company for the interview/shifts?",
    },
    {
        value: "it_was_clear_how_did_i_need_to_compare_for_the_interview_or_shifts",
        name: "It was clear how did I need to compare for the interview/shifts?",
    },
    {
        value: "i_felt_i_was_very_welcomed_when_i_came_for_the_interview_or_shift",
        name: "I felt I was very welcomed when I came for the interview/shift?",
    },
    {
        value: "i_was_happy_with_the_time_between_the_application_and_the_response",
        name: "I was happy with the time between the application and the response?",
    },
    {
        value: "i_was_happy_with_the_time_between_the_interview_or_shift_and_feedback",
        name: "I was happy with the time between the interview/shift and feedback?",
    },
];