import { main } from "react-devtools";
import * as Yup from "yup";

export const formInputProfessionalRefrence = (key) => [
  {
    name: `professionalReference.${key}.cnd_refer_record_company_name`,
    field: "cnd_refer_record_company_name",
    type: "text",
    label: "Organisation Name",
    md: "6",
    sm: "12",
  },
  {
    name: `professionalReference.${key}.cnd_refer_record_contact_name`,
    field: "cnd_refer_record_contact_name",
    type: "text",
    label: "Known Person's Name",
    md: "6",
    sm: "12",
  },
  {
    name: `professionalReference.${key}.cnd_refer_record_fromDate`,
    field: "cnd_refer_record_fromDate",
    max: new Date().toISOString().split("T")[0],
    min: new Date(new Date().setFullYear(new Date().getFullYear() - 63))
      .toISOString()
      .split("T")[0],
    type: "date",
    label: "Known From Date",
    md: "6",
    sm: "12",
  },
  {
    name: `professionalReference.${key}.cnd_refer_record_toDate`,
    field: "cnd_refer_record_toDate",
    max: new Date().toISOString().split("T")[0],
    min: new Date(new Date().setFullYear(new Date().getFullYear() - 63))
      .toISOString()
      .split("T")[0],
    type: "date",
    label: "Known To Date",
    md: "6",
    sm: "12",
  },
  {
    name: `professionalReference.${key}.cnd_refer_record_country`,
    field: "cnd_refer_record_country",
    type: "country",
    label: "Country",
    md: "6",
    sm: "12",
  },
  {
    name: `professionalReference.${key}.cnd_refer_record_phone_no`,
    field: "cnd_refer_record_phone_no",
    type: "text",
    label: "Telephone Number",
    md: "6",
    sm: "12",
  },
  {
    name: `professionalReference.${key}.cnd_refer_record_email`,
    field: "cnd_refer_record_email",
    type: "email",
    label: "Known Person's Email",
    md: "6",
    sm: "12",
  },
];
