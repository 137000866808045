import * as Yup from "yup";
export const formInputFields = (company) => [
  {
    name: "clt_schedule_calender",
    type: "date",
    label: "Calender",
    md: "6",
    sm: "12",
    value: company.clt_schedule_calender,
  },
  {
    name: "clt_schedule_time",
    type: "time",
    label: "Select Time",
    md: "6",
    sm: "12",
    value: company.clt_schedule_time,
  },
  {
    name: "clt_schedule_appoinment_status",
    type: "dropdown",
    label: "Appoinment Status",
    md: "6",
    sm: "12",
    options: [{ name: "Pending", id: "pending" }],
    value: company.clt_schedule_appoinment_status,
  },
];

export const validationSchema = Yup.object().shape({
  clt_schedule_calender: Yup.string().required("Select Calender Date"),
  clt_schedule_time: Yup.string().required("Select Schedule Time"),
  clt_schedule_appoinment_status: Yup.string().required(
    "Select Appoinment Status"
  ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
