import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Reablement-banner.jpg";
// import HomecareImg from "./../images/ourservices/Reablement.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/home-care/reable-services/our-services-home-care-reablement-services.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/home-care/reable-services/our-services-home-care-reablement-services-01.jpg";

import bnr3 from "./../images/lines.png";

export default function Home_Reablementcare() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Reablement Services";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/reablement-services"
            ? "0rem"
            : "" }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Reablement Services</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/reablement-services" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Home Care</li>
                  <li>Reablement Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Reablement Services </h2>
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>

                <div className="col-md-12 col-lg-6 ">
                  <p className="spacebar mt-3"></p>
                  <h6>
                    Supporting Independence: Personalized Home Care Solutions by
                    Staffing Connect
                  </h6>
                  <p className="text-left">
                    Many individuals sometimes require extra support to maintain
                    their independence and stay in the comfort of their homes.
                    Whether during periods of heightened medication
                    requirements, following a hospital discharge, or as part of
                    an intermediate care program, Staffing Connect is here to
                    provide the necessary assistance to rebuild confidence and
                    maximize independence.
                  </p>

                  <p className="text-left">
                    We understand the importance of local initiatives in
                    fostering a sense of community and ensuring individuals
                    receive the support they need promptly. That's why we strive
                    to offer our services on the very day of inquiry, ensuring
                    that people have the necessary resources and care to remain
                    at home or facilitate a smooth transition back home after a
                    challenging period.
                  </p>
                </div>
              </div>
              <p className="text-left">
                Rebuilding confidence and offering regular reassurance are vital
                components of our approach. Doing so empowers individuals to
                regain their independence and reduces the need for ongoing care.
                Our dedicated team is committed to providing personalized care
                that meets immediate needs, encourages self-reliance, and
                enhances overall well-being.
              </p>

              <p className="text-left">
                At Staffing Connect, we believe that everyone deserves to feel
                secure and comfortable in their own home. We are dedicated to
                offering tailored support, understanding each individual's
                unique circumstances, and working collaboratively to design a
                care plan that promotes independence and confidence.
              </p>

              <p className="text-left">
                Let us be your partner in providing the assistance you need to
                maintain or regain independence at home. Together, we can ensure
                you have the support and resources necessary to thrive in your
                environment.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
