import { yupToFormErrors } from "formik";
import { main } from "react-devtools";
import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_branch_name",
    type: "text",
    label: "Branch Name",
    md: "6",
    sm: "6",
  },
  {
    name: "clt_branch_location",
    type: "text",
    label: "Branch Location",
    md: "6",
    sm: "6",
  },
  {
    name: "clt_branch_address1",
    type: "text",
    label: "Address Line 1",
    md: "6",
    sm: "6",
  },
  {
    name: "clt_branch_address2",
    type: "text",
    label: "Address Line 2",
    md: "6",
    sm: "6",
  },
  {
    name: "clt_branch_town",
    type: "text",
    label: "Town",
    md: "6",
    sm: "6",
  },
  {
    name: "clt_branch_region",
    type: "text",
    label: "Region",
    md: "6",
    sm: "6",
  },
  {
    name: "clt_branch_postal",
    type: "text",
    label: "Postal Code",
    md: "6",
    sm: "6",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const yesterday = new Date(Date.now() - 86400000);
const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
export const validationSchema = Yup.object().shape({
  // clt_branch_name: Yup.string()
  //   .required("Branch Name Field Required")
  //   .matches(/^[a-z A-Z]+$/, "Only Letters Allowed"),
  //   clt_branch_location: Yup.string()
  //   .required("Branch Location Required")
  //   .matches(/^[a-z A-Z]+$/, "Only Letters Allowed"),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
