import React, { useEffect, useState, Suspense } from "react";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {
  documentComplianceList,
  documentComplianceShortList,
} from "../../../helpers/DocumentsList";
import { Input, Textarea, Dropdown } from "../../FormElements/Forms";
import Label from "../../Layout/Label";
import { connect } from "react-redux";
const DocFormAddress1 = (props) => {
  const {
    inputList,
    handleInputChange,
    errors,
    onSubmit,
    handleAddClick,
    handleRemoveClick,
    loader,
    userProfile,
    hideNextButton,
    handleChangeAddressOne,
    handleChangeAddress2One,
    progress,
  } = props;

  const [dropdownValues, setDropdownValues] = useState(
    documentComplianceShortList
  );
  const [dropdown, setDropDown] = useState("");
  const [radioValue, setRadioValue] = useState("No");
  const [radioSecondValue, setRadioSecondValue] = useState("No");
  const [existing, setExisting] = useState([]);
  const history = useHistory();

  const handleInputDropDownChange = (e, i) => {
    handleInputChange(e, i);
    setDropDown(e.target.value);
  };

  const onChangeRadio = (e, i) => {
    console.log(e, i);
    setRadioValue(e);
    handleChangeAddressOne(e, i);
  };

  const onChangeSecondRadio = (e, i) => {
    setRadioSecondValue(e);
    handleChangeAddress2One(e, i);
  };

  const routeChange = () => {
    let path = `/create-document-additional`;
    history.push(path);
  };

  useEffect(() => {
    var a = [];
    inputList.map((id) => {
      console.log(id.cnd_compliance_doc_master_id);
      a = [...a, id.cnd_compliance_doc_master_id];
      setExisting(a);
    });
  }, [inputList]);

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      // window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <form id="scrollFocusElement">
        <div className="row m-b30">
          <React.Fragment>
            {inputList.map((x, i) => (
              <React.Fragment key={i}>
                <div className="col-lg-12 col-md-12">
                  {inputList.length !== 1 && i > 1 && (
                    <i
                      className="pull-right fa fa-trash red-icon"
                      onClick={(e) => handleRemoveClick(i)}
                    ></i>
                  )}
                  <h6>Upload Proof of Address 2</h6>
                  <br />
                </div>

                <Input
                  type="file"
                  label="Upload Document"
                  changeHandler={(e) => handleInputChange(e, i)}
                  name="cnd_compliance_upload_file"
                  id={"cnd_compliance_upload_file"}
                  lg={6}
                  md={6}
                  errors={errors && errors[i]}
                />
                <Input
                  type="text"
                  label="Document Reference Number"
                  changeHandler={(e) => handleInputChange(e, i)}
                  name="cnd_compliance_reference"
                  id={"cnd_compliance_reference"}
                  value={x.cnd_compliance_reference}
                  lg={6}
                  md={6}
                  placeholder="Reference"
                  errors={errors && errors[i]}
                  req={false}
                />

                <Textarea
                  label="Document Description"
                  changeHandler={(e) => handleInputChange(e, i)}
                  name="cnd_compliance_description"
                  value={x.cnd_compliance_description}
                  lg={12}
                  md={12}
                  req={false}
                  errors={errors && errors[i]}
                />

                <div className="col-lg-12 col-md-12">
                  {inputList.length - 1 === i && (
                    <Button
                      disabled={loader ? "disabled" : ""}
                      className="site-button m-b30 pull-right"
                      onClick={(e) => onSubmit(e)}
                    >
                      {loader && (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      )}
                      {!loader ? "Save" : "Loading..."}
                    </Button>
                  )}
                </div>
              </React.Fragment>
            ))}
          </React.Fragment>
        </div>
      </form>
    </React.Fragment>
  );
};

// export default DocFormAddress1;
const mapStateToProps = (state) => {
  return {
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(DocFormAddress1);
