import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/banner/about-us-new.jpg";
// import HomecareImg from "./../images/our-work/About_home_care_01.jpg";
// import SocialcareImg from "./../images/our-work/About_socialcare.jpg";
// import EducationImg from "./../images/our-work/About_us_education.jpg";
// Newly Updated Images
import Background from "./../images/new-updated-images/about-us/about-us.jpg";
import HomecareImg from "./../images/new-updated-images/about-us/about-us-home-care.jpg";
import SocialcareImg from "./../images/new-updated-images/about-us/about-us-social-care.jpg";
import EducationImg from "./../images/new-updated-images/about-us/about-us-education.jpg";
import industrialImage from "./../images/new-updated-images/about-us/about-us-industrial.jpg";
import commercialImage from "./../images/new-updated-images/about-us/about-us-commercial.jpg";

import Footer from "../components/Layout/Footer";
// import Latestblogowl from './../Element/Owlblog2';
import Owltestimonial from "./../components/Element/Owlblog1";
// var bnr1 = require('./../images/banner/DesktopAboutus_banner_new.jpg');
// var bnr2 = require('./../images/banner/about_testimonials.jpg');
// var bnr3 = require('./../images/lines.png');
import bnr2 from "./../images/new-updated-images/home/home-testimonial.jpg";
import bnr3 from "./../images/lines.png";

// style={{ backgroundImage: "url(" + Background + ")",height:580 }}
function DesktopAboutus() {
  // Add title
  document.getElementById("titleText").innerText =
    "Staffing Connect - About Us";

  return (
    <div className="page-wraper text-left">
      <div className="page-content bg-white">
        <div
          className="dez-bnr-inr content-inner-3 dez-bnr-inr-md overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop: (window.location.pathname == "/mobile/about-us" ? "0rem" : "") }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">
                <b style={{ display: "none" }}>
                  Staffing Connect - Explore Information Here
                </b>
                About Us
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                  {window.location.pathname == "/mobile/about-us" ? ("Home") : (<a href={"/"}>Home</a>)}  
                  </li>
                  <li>About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Desktop Start */}
        <div className="content-block" id="desktopAbout">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center ">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">
                    Welcome to Staffing Connect!
                  </h2>
                  <p className="text-left">
                    We invite you to explore our accomplishments and discover
                    how we make a real difference in the lives of the people we
                    serve.
                  </p>
                </div>
                {/* <div className="col-md-12 col-lg-12 m-b20">
									<h2 className="m-b5">About Us</h2>
									<h3 className="fw4">Why, hello there! Want to see more of our work? Check out just some of what we've been up to below.</h3>
									<p className="m-b15"></p>
									<p className="m-b15">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less.</p>
									<Link to={"#"} className="site-button">Read More</Link>
								</div>
								<div className="col-md-12 col-lg-6">
									<img src={require('./../../images/our-work/pic1.jpg')} alt="" />
								</div> */}
              </div>

              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <img
                    src={HomecareImg}
                    alt="Home Care Section"
                    className="aboutImage"
                    style={{ borderRadius: "10%" }}
                  />
                </div>
                <div className="col-md-12 col-lg-6 ">
                  <p className="spacebar mt-3"></p>
                  <h2 className="m-b5"> Home Care</h2>
                  <p className="m-b15 text-left">
                    {" "}
                    Home is where comfort resides. At Staffing Connect, we will
                    ensure you continue to savour this comfort with our adept
                    and compassionate home carers. They are more than just
                    professionals; they are companions who transform your living
                    experience into a comfortable, enjoyable journey, responding
                    to every need with dedication and love.
                  </p>
                  <p className="m-b15"> Our carers can assist you with:</p>
                  <div className="row ">
                    <ul className="col-md-12 col-lg-6 m-b0">
                      <li className="m-l20">Shopping</li>
                      <li className="m-l20">Escorted outings</li>
                      <li className="m-l20">Personal care</li>
                      {/* <li className="m-l20">Personal care</li> */}
                    </ul>
                    <ul className="col-md-12 col-lg-6 m-b20">
                      <li className="m-l20">Domestic chores</li>
                      <li className="m-l20">
                        Post-hospitalization home support
                      </li>
                      <li className="m-l20">Respite care</li>
                    </ul>
                  </div>
                  <p className="m-b15 text-left">
                    Whatever your needs, we can design a personalised care plan
                    that suits you, at a very competitive price ensuring you get
                    the very best value out of it.
                  </p>
                  {/* <Link to={"#"} className="site-button">Read More</Link> */}
                </div>
              </div>
              <br />
              <hr />
              {/* Destop Start */}
              <div className="row desktopAbout">
                <div className="col-md-12 col-lg-6 ">
                  <h2 className="m-b5">Social Care</h2>
                  <p className="m-b15">
                    In Social Care, Staffing Connect is a reliable partner for
                    private, voluntary, and independent organizations. Our
                    team's integrity guarantees high standards, with a
                    continuous cycle of review and improvement that never
                    impinges on our core values.
                  </p>
                  <p className="m-b15">We take pride in providing: </p>
                  {/* <Link to={"#"} className="site-button">Read More</Link> */}
                  <ul>
                    <li className="m-l20">Residential Home Support Workers</li>
                    <li className="m-l20">Care Assistants</li>
                    <li className="m-l20">
                      Deputy Managers with NVQ3 in Health and Social Care
                    </li>
                    <li className="m-l20">Registered Social Workers</li>
                    <li className="m-l20">
                      Senior Workers with NVQ3 in Health and Social Care
                    </li>
                    <li className="m-l20">
                      Contact Supervisors and Children's Services Support
                      Workers with childcare experience
                    </li>
                    {/* <li className="m-l20">Children’s services support workers with a minimum of 12 months’ childcare experience.</li> */}
                  </ul>
                  <p className="m-b15 text-left">
                    We connect our clients with experienced professionals,
                    ensuring they receive the quality service they deserve.
                  </p>
                </div>

                <div className="col-md-12 col-lg-6 ">
                  <img
                    src={SocialcareImg}
                    alt="Social Care Section"
                    className="aboutImage"
                    style={{ borderRadius: "10%" }}
                  />
                </div>
              </div>
              {/* Desktop Stop */}

              <br />
              <hr />
              <div className="row ">
                <div className="col-md-12 col-lg-6 ">
                  <img
                    src={EducationImg}
                    alt="Education Section"
                    className="aboutImage"
                    style={{ borderRadius: "10%" }}
                  />
                </div>
                <div className="col-md-12 col-lg-6 ">
                  <p className="spacebar mt-3"></p>
                  <h2 className="m-b5">Education</h2>
                  <p className="m-b15">
                    Education is a calling that requires exceptional
                    individuals. As leading providers of education staff, we
                    understand this and have built our reputation on
                    professionalism, reliability, and integrity. We commit
                    ourselves to providing a cost-effective recruitment service,
                    whether for teaching or non-teaching roles in London.
                  </p>
                  <p className="m-b15">
                    Our team is dedicated to building enduring relationships
                    with our clients and candidates, offering a personalized,
                    high-quality service.
                  </p>
                  {/* <Link to={"#"} className="site-button">Read More</Link> */}
                  <p className="m-b15">We take pride in providing: </p>
                  <div className="row ">
                    <ul className="col-md-12 col-lg-6 m-b0">
                      <li className="m-l20"> GTC Registered Teachers</li>
                      <li className="m-l20">
                        {" "}
                        Experienced Teaching Assistants
                      </li>
                      <li className="m-l20"> Administrative Staff</li>
                      <li className="m-l20">
                        {" "}
                        Nursery Staff with NVQ2 or higher in Childcare and Early
                        Years Foundation Stage
                      </li>
                      {/* <li className="m-l20"> Exam invigilators.</li> */}
                    </ul>
                    <ul className="col-md-12 col-lg-6 m-b20">
                      <li className="m-l20"> Exam Invigilators</li>
                      <li className="m-l20">
                        {" "}
                        Nursery Managers, Deputy Managers, Room Leaders, Nurses,
                        and Assistants with appropriate NVQ qualifications and
                        experience
                      </li>
                      <li className="m-l20">
                        {" "}
                        Domestic Staff with relevant experience
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr />
              {/* Industrial Start */}
              {/* Destop Start */}
              <div className="row desktopAbout">
                <div className="col-md-12 col-lg-6 ">
                  <h2 className="m-b5">Industrial</h2>
                  <p className="m-b15">
                    Are you seeking skilled and reliable temporary workers to
                    meet the dynamic demands of the Industrial sector? Look no
                    further! At Staffing Connect, we specialize in connecting
                    businesses with the right talent to drive success in their
                    operations. At Staffing Connect, we understand the unique
                    challenges faced by industries that rely on a flexible and
                    responsive workforce. Our commitment is to provide you with
                    top-tier temporary workers who not only possess the skills
                    and expertise needed for your specific roles but also align
                    with your company's values and goals.
                  </p>
                  <h6 className="m-b15">Positions we cover: </h6>
                  {/* <Link to={"#"} className="site-button">Read More</Link> */}
                  <ul>
                    <li className="m-l20">
                      <b>Workshop & Production Managers:</b> Experienced
                      managers to oversee and optimize workshop and production
                      processes.
                    </li>
                    <li className="m-l20">
                      <b>Forklift Truck Drivers (all categories):</b> Skilled
                      forklift operators available for various categories,
                      ensuring efficient material handling.
                    </li>
                    <li className="m-l20">
                      <b>Assembly, Machine & Production Operatives:</b> Talented
                      individuals skilled in assembly, machine operation, and
                      production tasks.
                    </li>
                    <li className="m-l20">
                      <b>Warehouse Operatives - Night Shift operatives:</b>{" "}
                      Reliable night shift workers for warehouse operations,
                      including inventory management and order fulfillment.
                    </li>
                    <li className="m-l20">
                      <b>Pickers/Packers:</b> Detail-oriented individuals adept
                      at selecting and packing items for shipment.
                    </li>
                    <li className="m-l20">
                      <b>Post Room Staff & Mail Sorters:</b> Efficient staff to
                      manage post rooms and sort mail accurately.
                    </li>
                    <li className="m-l20">
                      <b>Industrial Cleaners:</b> Dedicated cleaners trained for
                      industrial environments, maintaining a safe and hygienic
                      workspace.
                    </li>

                    {/* <li className="m-l20">Children’s services support workers with a minimum of 12 months’ childcare experience.</li> */}
                  </ul>
                </div>

                <div className="col-md-12 col-lg-6 ">
                  <img
                    src={industrialImage}
                    alt="Industrial Section"
                    className="aboutImage"
                    style={{ borderRadius: "10%" }}
                  />
                  <ul className="mt-4">
                    <li className="m-l20">
                      <b>Security Staff:</b> Trained security personnel to
                      safeguard your premises.
                    </li>
                    <li className="m-l20">
                      <b>Grounds Maintenance Staff:</b> Skilled workers for the
                      upkeep of outdoor spaces and grounds.
                    </li>
                    <li className="m-l20">
                      <b>Car Park Attendants:</b> Attendants to manage and
                      organize parking facilities.
                    </li>
                    <li className="m-l20">
                      <b>Drivers Mates:</b> Assistants to support drivers in the
                      transportation of goods.
                    </li>
                    <li className="m-l20">
                      <b>Porters & Removal Staff:</b> Experienced porters and
                      removal staff for smooth relocations.
                    </li>
                    <li className="m-l20">
                      <b>HGV / 7.5 Tonne / Multi-Drop Drivers:</b> Licensed
                      drivers for heavy goods vehicles and multi-drop
                      deliveries.
                    </li>
                  </ul>
                </div>
              </div>
              {/* Desktop Stop */}
              
              {/* Industrial End */}

              {/* Commercial Start */}
              <hr />
              <div className="row ">
                <div className="col-md-12 col-lg-6 ">
                  <img
                    src={commercialImage}
                    alt="Commercial Section"
                    className="aboutImage"
                    style={{ borderRadius: "10%" }}
                  />
                  <ul className="mt-4">
                    <li className="m-l20">
                      <b>Event Coordinators:</b> Experienced coordinators to
                      plan and execute successful events.
                    </li>
                    <li className="m-l20">
                      <b>Project Managers:</b> Seasoned managers to oversee and
                      lead your commercial projects.
                    </li>
                    <li className="m-l20">
                      <b>Human Resources Support:</b> Skilled HR professionals
                      to assist with various HR functions.
                    </li>
                    <li className="m-l20">
                      <b>Legal Support Staff:</b> Support staff for legal
                      departments, including paralegals and legal assistants.
                    </li>
                    <li className="m-l20">
                      <b>IT and Technical Support:</b> Technical experts to
                      provide IT support and technical assistance.
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 col-lg-6 ">
                  <p className="spacebar mt-3"></p>
                  <h2 className="m-b5">Commercial</h2>
                  <p className="m-b15">
                    Are you in search of dynamic and versatile temporary workers
                    to propel your success in the Commercial sector? Look no
                    further! At Staffing Connect, we redefine excellence in
                    connecting businesses with the perfect blend of talent,
                    expertise, and innovation.
                  </p>
                  <p className="m-b15">
                    At Staffing Connect, we recognize that the Commercial sector
                    thrives on precision, creativity, and adaptability. Our
                    commitment is to deliver a curated pool of temporary workers
                    who not only bring essential skills and expertise to your
                    roles but also embody the innovation and strategic thinking
                    that drive success in the commercial landscape.
                  </p>
                  {/* <Link to={"#"} className="site-button">Read More</Link> */}
                  <h6 className="m-b15">Positions we Offer: </h6>
                  <div className="row">
                    <ul className="col-md-12 col-lg-12 m-b0">
                      <li className="m-l20">
                        <b>Office Managers and Administrators:</b> Experienced
                        professionals to manage and optimize office operations.
                      </li>
                      <li className="m-l20">
                        <b>Customer Service Representatives:</b> Skilled
                        representatives to ensure excellent customer
                        experiences.
                      </li>
                      <li className="m-l20">
                        <b>Accounting and Finance Professionals:</b> Qualified
                        individuals for all your financial and accounting needs.
                      </li>
                      <li className="m-l20">
                        <b>Marketing and Sales Support:</b> Assistants to
                        support your marketing and sales initiatives.
                      </li>
                      <li className="m-l20">
                        <b>Data Entry Clerks:</b> Detail-oriented clerks to
                        handle data entry tasks accurately.
                      </li>
                      <li className="m-l20">
                        <b>Executive Assistants:</b> Highly organized assistants
                        to support your executives.
                      </li>
                      <li className="m-l20">
                        <b>Receptionists and Front Desk Staff:</b> Welcoming
                        staff to manage front desk duties and ensure a positive
                        first impression.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr />
              {/* Commercial End */}
              <div className="row">
                <h6 className="mb-0 text-left">
                  Staffing Connect is your dependable partner, bridging the gap
                  between talented professionals and distinguished institutions
                  in Education, Home Care, Social Care, Industral and Commercial
                  sectors. We are here to connect, support, and empower.
                </h6>
              </div>
            </div>
          </div>

          {/* <p className="spacebar " style={{marginTop : "20%"}}></p> */}
          <div
            className="section-full content-inner-2 call-to-action overlay-black-dark text-white text-center bg-img-fix"
            style={{ backgroundImage: "url(" + bnr2 + ")" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Owltestimonial />
                </div>
              </div>
            </div>
          </div>

          <div
            className=" content-inner-2 footerImage footerImageHome"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          >
            {/* <div className="container text-center"> */}
            {/* <div className="section-head text-black text-center">
								<h2 className="text-uppercase m-b0">Our Latest Blog</h2>
								<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
							</div> */}
            {/* <Latestblogowl /> */}
            {/* <Link to={"/contact"} className="site-button">Reach Out to Us</Link> */}
            {/* </div> */}
          </div>
        </div>
        {/* Desktop Stop */}
      </div>
    </div>
  );
}
export default DesktopAboutus;
