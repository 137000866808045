import { main } from "react-devtools";
import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_care_support_plan_username",
    type: "text",
    label: "Username",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_support_plan_assessor",
    type: "text",
    label: "Accessor",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_support_plan_date",
    type: "date",
    label: "Date",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_support_plan_aim",
    type: "text",
    label: "Aim",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_support_plan_intervention",
    type: "textarea",
    label: "Intervention",
    md: "6",
    sm: "12",
  },
];

export const validationSchema = Yup.object().shape({
  clt_care_support_plan_username: Yup.string().required(
    "Username Field Required"
  ),
  clt_care_support_plan_assessor: Yup.string().required(
    "Assessor Field Required"
  ),
  clt_care_support_plan_date: Yup.string().required("Date Field Required"),
  clt_care_support_plan_aim: Yup.string().required("Aim Field Required"),
  clt_care_support_plan_intervention: Yup.string().required(
    "Intervention Field Required"
  ),
});
