import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadloginInfo } from "./../redux/actions/AuthActions";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import { useHistory } from "react-router-dom";
import {
  getDataOnRequest,
  updateDataOnRequest,
} from "../redux/actions/CommonActions";
import Input from "../components/FormElements/Input";
import Label from "../components/Layout/Label";
import AccountCentreSidebar from "../components/Element/AccountCentreSidebar";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import Dropdown from "../components/FormElements/Dropdown";
import { Form } from "react-bootstrap";
import { redirectUrl } from "../services/GlobalUrls";
import { optionsArray } from "../components/Element/SideBarForms/TimeOptions";
import { deleteFeedbackOptions } from "./FeedbackOptionsList";
import Textarea from "../components/FormElements/Textarea";
import StarRating from "./StartRates";
import { isMobileOrLaptop } from "../services/CheckDevice";
import AccountCentreSidebarMobile from "../components/Element/AccountCentreSidebarMobile";

var bnr = require("./../images/banner/bnr2.jpg");

const DeleteAccount = (props) => {
  // Password Verification Start
  const [passwordModal, setPasswordModal] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState({});
  // Password Verification Stop

  const [starRate, setStarRating] = useState("");
  const [starRateValue, setStartRateValue] = useState("");
  const [sendFeedback, setSendFeedback] = useState({});
  const [modal, setFeedbackModal] = useState(false);
  let delete_feedback_ans,
    delete_feedback_ans_msg,
    users_password,
    users_password_msg;
  let applyError = false;

  let localStorageValues = JSON.parse(localStorage.getItem("loginUserDetails"));
  let feedbackStore;

  useEffect(() => {
    feedbackStore = localStorage.getItem("feedbackDetails")
      ? JSON.parse(localStorage.getItem("feedbackDetails"))
      : "";
    if (feedbackStore == true) {
      setPasswordModal(feedbackStore);
    }
  }, [feedbackStore]);

  const dispatch = useDispatch();

  const skipModal = (modalName) => {
    if (modalName == "feedback") {
      setFeedbackModal(false);
      setPasswordModal(true);
      setStartRateValue("");
      setStarRating("");
    }

    if (modalName == "hideFeedback") {
      // setFeedbackModal(false);
      // setStartRateValue("");
      // setStarRating("");
      redirectUrl("/delete-account");
    }

    if (modalName == "cancelPassword") {
      localStorage.setItem("feedbackDetails", "");
      redirectUrl("/delete-account");
    }
  };

  const handleRatingChange = (rating) => {
    // console.log("Rating Is :=>"+rating);
    if (rating <= 2) {
      setStarRating("😒");
    } else if (rating <= 4) {
      setStarRating("😊");
    } else {
      setStarRating("😍");
    }

    setStartRateValue(rating);

    setSendFeedback({
      ...sendFeedback,
      ["delete_account_star_rating"]: rating,
    });
  };

  const onChangeFeedback = (e) => {
    e.preventDefault();

    setSendFeedback({
      ...sendFeedback,
      [e.target.name]: e.target.value,
    });
    {
      deleteFeedbackOptions.map((opt, i) => {
        if (starRateValue != "") {
          delete_feedback_ans = document.getElementById(
            "delete_feedback_ans" + i
          );
          delete_feedback_ans_msg = document.getElementById(
            "delete_feedback_ans" + i + "_msg"
          );
          if (!delete_feedback_ans.value) {
            delete_feedback_ans.classList.add("border-danger");
            delete_feedback_ans_msg.style.display = "block";
            delete_feedback_ans_msg.innerText =
              "Please select the rates from the above list!";
            applyError = true;
          } else {
            delete_feedback_ans.classList.remove("border-danger");
            delete_feedback_ans_msg.style.display = "none";
            delete_feedback_ans_msg.innerText = "";
            applyError = false;
          }
        }
      });
    }

    // console.log("All The Feedback Data :=> " + JSON.stringify(sendFeedback));
  };
  const onSubmitFeedback = (e) => {
    e.preventDefault();
    {
      deleteFeedbackOptions.map((opt, i) => {
        if (starRateValue != "") {
          delete_feedback_ans = document.getElementById(
            "delete_feedback_ans" + i
          );
          delete_feedback_ans_msg = document.getElementById(
            "delete_feedback_ans" + i + "_msg"
          );
          if (!delete_feedback_ans.value) {
            delete_feedback_ans.classList.add("border-danger");
            delete_feedback_ans_msg.style.display = "block";
            delete_feedback_ans_msg.innerText =
              "Please select the rates from the above list!";
            applyError = true;
          } else {
            delete_feedback_ans.classList.remove("border-danger");
            delete_feedback_ans_msg.style.display = "none";
            delete_feedback_ans_msg.innerText = "";
            applyError = false;

            sendFeedback["delete_feedback_question" + i] = opt.value;
          }
        }
      });
    }
    // console.log("Process Name For Delete Operation :=> "+JSON.stringify(sendFeedback));
    if (applyError == false) {
      sendFeedback["users_access_token"] = localStorageValues.access_token;
      setFeedbackModal(false);
      document.getElementById("loader").style.display = "block";
      dispatch(
        updateDataOnRequest(
          JSON.stringify(sendFeedback, null, 2),
          "SendFeedback",
          ""
        )
      );
    }

    // console.log("All The Feedback Data :=> "+JSON.stringify(sendFeedback));
  };

  // Password Logic Start
  const onChangePassword = (e) => {
    e.preventDefault();
    setVerifyPassword({
      ...verifyPassword,
      [e.target.name]: e.target.value,
    });

    users_password = document.getElementById("users_password");
    users_password_msg = document.getElementById("users_password_msg");
    if (!users_password.value) {
      users_password.classList.add("border-danger");
      users_password_msg.style.display = "block";
      users_password_msg.innerText = "Please enter the current password!";
      applyError = true;
    } else if (users_password.value && users_password.value.trim() == "") {
      users_password.classList.add("border-danger");
      users_password_msg.style.display = "block";
      users_password_msg.innerText = "Please enter the valid password!";
      applyError = true;
    } else if (users_password.value && users_password.value.length < 6) {
      users_password.classList.add("border-danger");
      users_password_msg.style.display = "block";
      users_password_msg.innerText = "Password must have 6 or more characters!";
      applyError = true;
    } else {
      users_password.classList.remove("border-danger");
      users_password_msg.style.display = "none";
      users_password_msg.innerText = "";
      applyError = false;
    }
    // console.log("All The Feedback Data :=> "+JSON.stringify(verifyPassword));
  };
  const onSubmitPassword = (e) => {
    e.preventDefault();

    users_password = document.getElementById("users_password");
    users_password_msg = document.getElementById("users_password_msg");
    if (!users_password.value) {
      users_password.classList.add("border-danger");
      users_password_msg.style.display = "block";
      users_password_msg.innerText = "Please enter the current password!";
      applyError = true;
    } else if (users_password.value && users_password.value.trim() == "") {
      users_password.classList.add("border-danger");
      users_password_msg.style.display = "block";
      users_password_msg.innerText = "Please enter the valid password!";
      applyError = true;
    } else if (users_password.value && users_password.value.length < 6) {
      users_password.classList.add("border-danger");
      users_password_msg.style.display = "block";
      users_password_msg.innerText = "Password must have 6 or more characters!";
      applyError = true;
    } else {
      users_password.classList.remove("border-danger");
      users_password_msg.style.display = "none";
      users_password_msg.innerText = "";
      applyError = false;
    }

    if (applyError == false) {
      verifyPassword["users_access_token"] = localStorageValues.access_token;
      document.getElementById("loader").style.display = "block";
      setPasswordModal(false);
      dispatch(
        updateDataOnRequest(
          JSON.stringify(verifyPassword, null, 2),
          "VerifyPassword",
          ""
        )
      );
    }
  };
  // Password Logic Stop
  const callAlert = () => {
    Swal.fire({
      title: "Delete Account",
      text: "Are you sure you want to delete your account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      // confirmButtonColor: 'green',
      // cancelButtonColor:  'red',
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        // Delete account logic
        setFeedbackModal(true);
        // Swal.fire('Account Deleted', 'Your account has been deleted.', 'success');
      } else {
        setFeedbackModal(false);
        // Cancel delete account logic
        Swal.fire(
          "Cancelled",
          "Your account deletion has been cancelled.",
          "info"
        );
      }
    });
  };

  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(350);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-blocks">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {checkDevice == "Mobile" ? (
                  <AccountCentreSidebarMobile page="delete-account" />
                ) : (
                  <AccountCentreSidebar page="delete-account" />
                )}
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx submit-resume" id="scrollFocusElement">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Thinking of leaving us?
                      </h5>
                      {/* <Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link> */}
                    </div>
                    <div
                      class="alert alert-warning text-dark alert-dismissible fade show clearfix"
                      role="alert"
                      id="scrollFocusElement"
                    >
                      <i
                        className="fa fa-warning text-warning"
                        aria-hidden="true"
                      ></i>{" "}
                      <strong>
                        <b>Deleting your account will :</b>
                      </strong>
                    </div>

                    <div className="container ml-2">
                      <p className="ml-4">
                        <ol>
                          <li>Delete your account from Satffing Connect.</li>
                          <li>Erased your entered data.</li>
                          <li>Delete your personal details.</li>
                          <li>
                            Delete your all the professional/work details.
                          </li>
                          <li>Delete your uploaded documents.</li>
                        </ol>
                      </p>
                      <p>
                        The Company will retain your personal data only for as
                        long as is necessary. Different laws require us to keep
                        different data for different periods of time. For more
                        details please visit Privacy Policy.
                      </p>
                      <h6>
                        I would like to{" "}
                        <a
                          onClick={() => callAlert()}
                          style={{ cursor: "pointer" }}
                          className="text-lg text-danger"
                        >
                          Delete My Account
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <>
                  {/* Feedback Modal Started */}
                  <Modal
                    show={modal}
                    onHide={() => skipModal("hideFeedback")}
                    className="modal fade modal-bx-info editor"
                  >
                    <div className="modal-dialog my-0" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-titl"
                            id="ResumeheadlineModalLongTitle"
                          >
                            Provide feedback to us - (optional)
                          </h5>
                          <button
                            type="button"
                            className="close"
                            onClick={() => setFeedbackModal(false)}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div
                            class="alert alert-info text-dark alert-dismissible fade show clearfix"
                            role="alert"
                            id="scrollFocusElement"
                          >
                            <i
                              className="fa fa-info-circle text-dark"
                              aria-hidden="true"
                            ></i>{" "}
                            <strong>
                              Our feedback form allows you to share your
                              thoughts and experiences as you choose to delete
                              your account. Your feedback is valuable to us as
                              we strive to enhance our services and better
                              understand our users' needs.Thank you for taking
                              the time to provide us with your valuable
                              feedback.
                            </strong>
                          </div>
                          <form
                            onInput={onChangeFeedback}
                            onSubmit={(e) => onChangeFeedback()}
                          >
                            <div className="row">
                              <label className="mb-0 ml-1">
                                Give us rating
                                <sup style={{ color: "red" }}>*</sup>{" "}
                                <b
                                  className="text-xl ml-2"
                                  style={{
                                    fontWeight: "bolder",
                                    fontSize: "30px",
                                  }}
                                >
                                  {starRate}
                                </b>
                              </label>
                              <StarRating
                                totalStars={5}
                                initialRating={0}
                                onChange={handleRatingChange}
                              />
                              {starRateValue && (
                                <>
                                  {deleteFeedbackOptions.map((opt, i) => (
                                    <>
                                      <input
                                        name={"delete_feedback_question" + i}
                                        type="hidden"
                                      />
                                      <Dropdown
                                        label={opt.name}
                                        name={"delete_feedback_ans" + i}
                                        id={"delete_feedback_ans" + i}
                                        changeHandler={(e) =>
                                          onChangeFeedback(e)
                                        }
                                        options={[
                                          {
                                            name: "Select the ratings between 1-5",
                                            value: "",
                                          },
                                          {
                                            name: "1",
                                            value: "1",
                                          },
                                          {
                                            name: "2",
                                            value: "2",
                                          },
                                          {
                                            name: "3",
                                            value: "3",
                                          },
                                          {
                                            name: "4",
                                            value: "4",
                                          },
                                          {
                                            name: "5",
                                            value: "5",
                                          },
                                        ]}
                                        lg={12}
                                      />
                                    </>
                                  ))}
                                  <Textarea
                                    label={
                                      "Do you have any additional feedback to help us?"
                                    }
                                    lg={12}
                                    placeholder={"Description - (optional)"}
                                    name={"delete_feedback_description"}
                                    changeHandler={(e) => onChangeFeedback(e)}
                                    req={false}
                                  />
                                </>
                              )}
                            </div>

                            <div className="modal-footer">
                              {starRateValue && (
                                <button
                                  type="button"
                                  onClick={onSubmitFeedback}
                                  className="site-button green"
                                >
                                  Submit
                                </button>
                              )}
                              <button
                                type="button"
                                className="site-button"
                                onClick={() => skipModal("feedback")}
                              >
                                Skip
                              </button>
                              <button
                                type="button"
                                className="site-button red"
                                onClick={() => skipModal("hideFeedback")}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  {/* Feedback Modal Ended */}

                  {/* Password Confirm Modal Started */}
                  <Modal
                    show={passwordModal}
                    onHide={() => skipModal("cancelPassword")}
                    className="modal fade modal-bx-info editor"
                  >
                    <div className="modal-dialog my-0" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="" id="ResumeheadlineModalLongTitle">
                            Password Confirmation to Delete Account
                          </h5>
                          <button
                            type="button"
                            className="close"
                            onClick={() => setPasswordModal(false)}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div
                            class="alert alert-warning text-dark alert-dismissible fade show clearfix"
                            role="alert"
                            id="scrollFocusElement"
                          >
                            <i
                              className="fa fa-warning text-dark"
                              aria-hidden="true"
                            ></i>{" "}
                            <strong>
                              Enter your account password to proceed for account
                              deletion.
                            </strong>
                          </div>
                          <form
                            onInput={onChangePassword}
                            onSubmit={(e) => onSubmitPassword()}
                            noValidate
                          >
                            <div className="row mb-0">
                              <Input
                                label="Current Password"
                                name={"users_password"}
                                className="form-control"
                                type="password"
                                id={"users_password"}
                                placeholder={"Enter Current Password"}
                                changeHandler={(e) => onChangePassword(e)}
                                lg={12}
                              />
                            </div>

                            <div className="modal-footer">
                              <button
                                type="submit"
                                onClick={onSubmitPassword}
                                className="site-button green"
                              >
                                Confirm
                              </button>
                              <button
                                type="button"
                                className="site-button red"
                                onClick={() => skipModal("cancelPassword")}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  {/* Password Confirm Modal Ended */}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: isAuthenticated(state),
    errorMessage: state.error.error,
  };
};

export default connect(mapStateToProps)(DeleteAccount);
