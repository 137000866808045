import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Form, Col, Spinner, Button } from "react-bootstrap";
import Label from "../../Layout/Label";
import { hidePassword, showPassword } from "../../FormElements/ShowPassword";
const RegisterForm = (props) => {
  const [formFields, setFormFields] = useState([]);
  const [formData, setFormData] = useState();
  const [userCategory, setUserCategory] = useState("");
  let usersEmail;
  const {
    userdata,
    category,
    userRole,
    register,
    errors,
    handlechange,
    candidateFormFields,
    clientFormFields,
    clientFormSpecificFields,
    onChangeDropDownValue,
    usertype,
  } = props;

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    if (name == "users_category") {
      setUserCategory(value);
      onChangeDropDownValue(value);
    }
    // formData["users_role_id"] = usertype;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if(formData){
      formData["users_email"] = formData.users_email?.trim();
      formData["users_firstname"] = formData.users_firstname?.trim();
      formData["users_lastname"] = formData.users_lastname?.trim();
    }
    localStorage.setItem("users_email", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    const fieldsLoadOnCondition =
      userRole == "client"
        ? userCategory == "social care" || userCategory == "education"
          ? clientFormSpecificFields
          : clientFormFields
        : candidateFormFields;
    setFormFields(fieldsLoadOnCondition);
  }, [userCategory, userRole]);

  return (
    <React.Fragment>
      {formFields.map((form, formKey) => (
        <React.Fragment key={formKey + form.name}>
          {(form.type == "text" ||
            form.type == "date" ||
            form.type == "password") && (
            <Form.Group as={Col} controlId={formKey} md={form.md} sm={form.sm}>
              <Label labelName={form.label} />
              <Form.Control
                label={form.label}
                name={form.name}
                type={form.type}
                id={form.id}
                {...register(form.name, {
                  onChange: handleChange,
                })}
                isInvalid={errors[form.name] ? true : ""}
                isValid={userdata[form.name] ? true : false}
              />
              {form.type == "password" && (
                <>
                  <a
                    onClick={() => showPassword(form.id)}
                    className="text-primary"
                    id={"showPassword" + form.id}
                    style={{ display: "none" }}
                  >
                    <i
                      className="fa fa-eye pull-right mr-2"
                      style={{ marginTop: "-8%" }}
                    ></i>
                  </a>
                  <a
                    onClick={() => hidePassword(form.id)}
                    className="text-primary"
                    id={"hidePassword" + form.id}
                    style={{ display: "none" }}
                  >
                    <i
                      className="fa fa-eye-slash pull-right mr-2"
                      style={{ marginTop: "-8%" }}
                    ></i>
                  </a>
                </>
              )}
              <Form.Control.Feedback type="invalid">
                {errors[form.name]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {form.type == "dropdown" && (
            <Form.Group
              as={Col}
              controlId={formKey}
              key={formKey}
              md={form.md}
              sm={form.sm}
            >
              <Label labelName={form.label} />
              <Form.Control
                as="select"
                label={form.label}
                name={form.name}
                type={form.type}
                id={form.id}
                {...register(form.name, {
                  onChange: onChangeValue,
                })}
                isInvalid={errors[form.name] ? true : ""}
                isValid={userdata[form.name] ? true : false}
              >
                {form.options.map((option, key) => (
                  <option key={key + option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors[form.name]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {form.type == "radio" && (
            <Form.Group
              as={Col}
              controlId={formKey}
              key={formKey}
              md={form.md}
              sm={form.sm}
              id={form.id}
            >
              <Label labelName={form.label} />
              {form.options.map((option, key) => (
                <Form.Check
                  key={option + key}
                  label={option.name}
                  name={form.name}
                  value={option.id}
                  type={form.type}
                  id={option.id + key}
                  {...register(form.name)}
                  isInvalid={errors[form.name] ? true : ""}
                  isValid={userdata[form.name] ? true : false}
                />
              ))}
              <Form.Control.Feedback type="invalid">
                {errors[form.name]?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    companyDetails: state.companyDetails.companyDetails,
  };
};
export default connect(mapStateToProps)(RegisterForm);
