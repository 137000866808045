const initialState = {dbsdocuments: []}

export const dbsdocuments = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_DBS_DOCUMENT':
      return {dbsdocuments: action.payload}; 
  }
  return state
}


