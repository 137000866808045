import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import Error from "../../Layout/Error";
import Label from "../../Layout/Label";
import { Input, Textarea, Dropdown } from "../../FormElements/Forms";
const DBSYesUpperForm = (props) => {
  const { onChangeFirstFormValue, errors, dbsType, inputList } = props;
  return (
    <React.Fragment>
      <Input
        type="text"
        label="DBS Certificate Surname"
        changeHandler={(e) => onChangeFirstFormValue(e)}
        name="cnd_dbs_holder_surname"
        lg={6}
        md={6}
        value={inputList[0] && inputList[0].cnd_dbs_holder_surname}
        placeholder="Eg., Smith"
        errors={errors[0]}
        //disabled
      />
      <Input
        type="date"
        label="Holder's Date of Birth"
        changeHandler={(e) => onChangeFirstFormValue(e)}
        name="cnd_dbs_holder_dob"
        lg={6}
        md={6}
        max={
          new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            .toISOString()
            .split("T")[0]
        }
        value={inputList[0] && inputList[0].cnd_dbs_holder_dob}
        placeholder="Eg., 01/01/1990"
        errors={errors[0]}
        disabled
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
  };
};

export default connect(mapStateToProps)(DBSYesUpperForm);
