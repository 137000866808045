const initialState = {branch: {}}

export const branch = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_BRANCH':
      return {branch: action.payload}; 
  }
  return state
}


