import { main } from "react-devtools";
import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_care_life_story",
    type: "textarea",
    label: "Life Story",
    md: "12",
    sm: "12",
  },
];

export const validationSchema = Yup.object().shape({
  clt_care_life_story: Yup.string().required("Life Story Field"),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
