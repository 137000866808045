const initialState = {jobs: []}

export const jobs = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_JOBS':
      return {jobs: action.payload}; 
  }
  return state
}


