import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { jobSectorList } from "../../services/JobTypeAndSectorGlobalList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { redirectUrl } from "../../services/GlobalUrls";
const Jobfindbox = ({
  allJobs,
  filteredJobs,
  handleSubmit,
  isHomePage = false,
  area,
  title,
  category,
}) => {
  const history = useHistory();
  const { loader } = allJobs;

  const [titleInputValue, setTitleInputValue] = useState("");
  const [areaInputValue, setAreaInputValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [showTitleList, setShowTitleList] = useState(false);
  const [showAreaList, setShowAreaList] = useState(false);
  const [showSectorList, setShowSectorList] = useState(false);

  useEffect(() => {
    setAreaInputValue(area);
    setTitleInputValue(title);
    setCategoryValue(category);
    if (area || title || category) {
      document.addEventListener("keydown", function (event) {
        let find_button = document.getElementById("find_button");
        if (
          (event.ctrlKey || event.metaKey) &&
          event.shiftKey &&
          (event.key === "R" || event.keyCode === 82)
        ) {
          find_button.click();
        } else if (
          (event.ctrlKey || event.metaKey) &&
          (event.key === "R" || event.keyCode === 82)
        ) {
          find_button.click();
        } else if (event.key === "F5" || event.keyCode === 116) {
          find_button.click();
        }
      });
    }
    // console.log("area", area, title, category);
  }, [area, title, category]);

  const handleFindJob = () => {
    let findBoxCall = document.getElementById("findBoxCall");
    // let findBoxCallMsg = document.getElementById('findBoxCallMsg');
    if (!categoryValue && !titleInputValue && !areaInputValue) {
      findBoxCall.classList.add("border", "border-danger");
      // findBoxCallMsg.innerText = "Please fill at least one field to find the jobs";

      toast.error("Please fill at least one field to find jobs", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      findBoxCall.classList.remove("border", "border-danger");
      // findBoxCallMsg.innerText = "";
      if (!isHomePage) {
        handleSubmit(titleInputValue, areaInputValue, categoryValue);
      }
      history.push({
        pathname: "/job-board",
        state: {
          category: categoryValue,
          area: areaInputValue,
          title: titleInputValue,
          isHomePage: isHomePage,
        },
      });
    }
  };

  return (
    <div className="container" style={{ borderRadius: "30px" }}>
      <div className="find-job-bx" style={{ borderRadius: "30px" }}>
        <form
          className={
            "dezPlaceAni " +
            (window.location.pathname == "/" ||
            window.location.pathname == "/home"
              ? "searchFormSize"
              : "")
          }
          id="findBoxCall"
          style={{ borderRadius: "30px" }}
        >
          <div className="row">
            <div className={"col-lg-"+(titleInputValue || areaInputValue || categoryValue ? "3" : "4")+" col-md-6"}>
              <div className="form-group">
                <div className="input-group">
                  <Autocomplete
                    freeSolo
                    id="disable-close-on-select"
                    options={[
                      ...new Set(allJobs.map((jobs) => jobs.jobs_title)),
                    ]}
                    inputValue={titleInputValue || ""}
                    onChange={(event, newValue) => {
                      setTitleInputValue(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setTitleInputValue(newInputValue);
                      // Show title list when user starts typing
                      setShowTitleList(true);
                      // Hide title list if input is empty
                      if (newInputValue === "") {
                        setShowTitleList(false);
                      }
                    }}
                    onBlur={(event, newInputValue) => {
                      setShowTitleList(false);
                    }}
                    onFocus={(event, newInputValue) => {
                      if (titleInputValue) {
                        setShowTitleList(true);
                      } else {
                        setShowTitleList(false);
                      }
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          style={{ marginTop: "4%" }}
                          {...params}
                          placeholder="Job Title, Keywords, or Phrase"
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                        <div className="input-group-append text-lg">
                          {!titleInputValue && (
                            <span className="input-group-text">
                              <i className="fa fa-search"></i>
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    style={{ width: "100%" }}
                    // Set open to show/hide options based on showTitleList state
                    open={showTitleList}
                    clearIcon={<CloseIcon className="text-danger" />}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <div className="input-group">
                  <Autocomplete
                    freeSolo
                    id="disable-close-on-select"
                    options={[
                      ...new Set(
                        allJobs.map((jobs) => jobs.jobs_location_town)
                      ),
                    ]}
                    inputValue={areaInputValue || ""}
                    onChange={(event, newValue) => {
                      setAreaInputValue(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setAreaInputValue(newInputValue);

                      setShowAreaList(true);
                      if (newInputValue === "") {
                        setShowAreaList(false);
                      }
                    }}
                    onBlur={(event, newInputValue) => {
                      setShowAreaList(false);
                    }}
                    onFocus={(event, newInputValue) => {
                      if (areaInputValue) {
                        setShowAreaList(true);
                      } else {
                        setShowAreaList(false);
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <>
                          <TextField
                            style={{ marginTop: "6%" }}
                            {...params}
                            placeholder="Area"
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                          <div className="input-group-append">
                            {!areaInputValue && (
                              <span className="input-group-text">
                                <i
                                  className="fa fa-map-marker"
                                  style={{ position: "absolute" }}
                                ></i>
                              </span>
                            )}
                          </div>
                        </>
                      );
                    }}
                    style={{ width: "100%" }}
                    open={showAreaList}
                    clearIcon={<CloseIcon className="text-danger" />}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <Autocomplete
                  freeSolo
                  id="disable-close-on-select"
                  options={[
                    ...new Set(allJobs.map((jobs) => jobs.job_sector_title)),
                  ]}
                  inputValue={categoryValue || ""}
                  onChange={(event, newValue) => {
                    setCategoryValue(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setCategoryValue(newInputValue);

                    setShowSectorList(true);
                    if (newInputValue === "") {
                      setShowSectorList(false);
                    }
                  }}
                  onBlur={(event, newInputValue) => {
                    setShowSectorList(false);
                  }}
                  onFocus={(event, newInputValue) => {
                    if (categoryValue) {
                      setShowSectorList(true);
                    } else {
                      setShowSectorList(false);
                    }
                  }}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField
                          style={{ marginTop: "6%" }}
                          {...params}
                          placeholder="Select Job Sector"
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                        <div className="input-group-append">
                          {!categoryValue && (
                            <span className="input-group-text">
                              <i
                                className="fa fa-chevron-circle-down"
                                style={{ position: "absolute" }}
                              ></i>
                            </span>
                          )}
                        </div>
                      </>
                    );
                  }}
                  style={{ width: "100%" }}
                  // open={showSectorList}
                  clearIcon={<CloseIcon className="text-danger" />}
                />
              </div>
            </div>
            <div className={"col-lg-"+(titleInputValue || areaInputValue || categoryValue ? "3" : "2")+" col-md-6"}>
             
                  {titleInputValue || areaInputValue || categoryValue ? (
                    <center>
                    <a
                      disabled={loader}
                      id="find_button"
                      className="btn btn-primary cursor-pointer text-white btn-md mt-2 mr-2"
                      onClick={handleFindJob}
                      style={{ borderRadius: "30px" }}
                    >
                      Find Jobs
                    </a>
                    <a
                    disabled={loader}
                    className="btn btn-danger cursor-pointer text-white btn-md mt-2"
                    onClick={() => redirectUrl('/job-board')}
                    style={{ borderRadius: "30px" }}
                  >
                    Clear Search
                  </a>
                  </center>
                  ) : (
                    <button
                disabled={loader}
                id="find_button"
                type="button"
                className="site-button bg-primary btn-block"
                onClick={handleFindJob}
                style={{ borderRadius: "30px" }}
              >
                Find Job
              </button>
                    
                  )}
              
              
            </div>
            {/* <div className="col-lg-2 col-md-6">
              <button
                disabled={loader}
                id="find_button"
                type="button"
                className="site-button bg-primary btn-block"
                onClick={handleFindJob}
                style={{ borderRadius: "30px" }}
              >
                Find Job
              </button>
            </div> */}
          </div>

          {/* <div className="text-danger text-center" id="findBoxCallMsg"></div> */}
        </form>
      </div>
    </div>
  );
};

export default Jobfindbox;
