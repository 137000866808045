export const checkUserStatus = () => {
    const isLogin = localStorage.getItem("loginUserDetails")
    ? JSON.parse(localStorage.getItem("loginUserDetails"))
    : false;

    if(isLogin) {
        return isLogin.isLogin
    } else {
        return false
    }
}