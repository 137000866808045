const initialState = {companyDetails: {}}

export const companyDetails = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_COMPANY_DETAILS':
      return {companyDetails: action.payload}; 
  }
  return state
}


