const initialState = {trainingInfo: ""}

export const trainingInfo = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_TRAINING_DATA':
      return {trainingInfo: action.payload}; 
  	case 'UPDATE_TRAINING_DATA':
      return {trainingInfo: action.payload}; 
  }
  return state
}


