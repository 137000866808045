import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IdentityDocumentListView } from "../../../helpers/DocumentsList";
import constants from "../../../constants";
import { redirectUrl } from "../../../services/GlobalUrls";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
const IdentityList = (props) => {
  const [identityDocuments, setIdentityDocument] = useState([]);
  let cndApproved = localStorage.getItem("tempUserStatus");
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.identityDocuments.length;
    console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    setIdentityDocument(props.identityDocuments);
  }, [props.identityDocuments]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      // document.getElementById("loader").style.display = "block";
      // setModalOption(true);
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  // const loaderEnd = () => {
  //   document.getElementById("loader").style.display = "none";
  //   setModalOption(true);
  //   setModalHeight("auto");
  // };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <div className="row m-b30" id="scrollFocusElement">
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          {/* Search Start */}
          <div className="container">
            <div className="row">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <label class="form-control-label text-right" for="search">
                  Search
                </label>
                <div class="form-group form-group-default">
                  <input
                    type="text"
                    name="search"
                    class="form-control "
                    placeholder="Enter key to search"
                    id="myInput"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Search End */}
          <table className="table table-striped table-hover table-sm">
            <thead className="thead-dark">
              <tr>
                {/*<th className="col-1 text-center" scope="col">Sr. No</th>*/}
                <th className="col-2 text-center" scope="col">
                  Date & Time
                </th>
                <th className="col-2 text-center" scope="col">
                  Document Name
                </th>
                <th className="col-2 text-center" scope="col">
                  Document(s) Uploaded
                </th>
                {/* <th scope="col">Description</th> */}
                <th className="col-2 text-center" scope="col">
                  Reference
                </th>
                <th className="col-3 text-center" scope="col">
                  Expiry Date
                </th>
                <th className="col-2 text-center" scope="col">
                  Status
                </th>
                {cndApproved != "Approved" && (
                  <th className="col-2 text-center" scope="col">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody id="myTable">
              {identityDocuments &&
                identityDocuments
                  .slice(startIndex, endIndex)
                  .map((compliance, i) => (
                    <tr
                      className={
                        new Date() <=
                          new Date(
                            splitDateToFormat(
                              compliance.cnd_identity_doc_exp_date
                            )
                          ) &&
                        dateDiffrence(compliance.cnd_identity_doc_exp_date) > 30
                          ? " "
                          : new Date() <=
                              new Date(
                                splitDateToFormat(
                                  compliance.cnd_identity_doc_exp_date
                                )
                              ) &&
                            dateDiffrence(
                              compliance.cnd_identity_doc_exp_date
                            ) < 30
                          ? "table-warning"
                          : "table-danger"
                      }
                      key={i}
                    >
                      {/*<th className="col-1 text-center" scope="row">{i + 1}</th>*/}
                      <td className="col-4 text-center">
                        {compliance.cnd_identity_doc_created_date}
                      </td>
                      <td className="col-2 text-center">
                        {
                          IdentityDocumentListView[
                            compliance.cnd_identity_doc_master_id
                          ]
                        }
                      </td>
                      <td className="col-2 text-center">
                        <a
                          onClick={() =>
                            viewFileModal(
                              constants.IMAGE_URL +
                                compliance.cnd_identity_doc_folder_path +
                                compliance.cnd_identity_doc_upload_name,
                              IdentityDocumentListView[
                                compliance.cnd_identity_doc_master_id
                              ]
                            )
                          }
                          className={
                            "btn btn-" +
                            (compliance.cnd_identity_doc_upload_name.endsWith(
                              ".png"
                            ) ||
                            compliance.cnd_identity_doc_upload_name.endsWith(
                              ".jpg"
                            ) ||
                            compliance.cnd_identity_doc_upload_name.endsWith(
                              ".jpeg"
                            ) ||
                            compliance.cnd_identity_doc_upload_name.endsWith(
                              ".pdf"
                            )
                              ? "dark"
                              : "primary") +
                            " btn-sm text-white"
                          }
                        >
                          <i
                            className={
                              "fa fa-" +
                              (compliance.cnd_identity_doc_upload_name.endsWith(
                                ".png"
                              ) ||
                              compliance.cnd_identity_doc_upload_name.endsWith(
                                ".jpg"
                              ) ||
                              compliance.cnd_identity_doc_upload_name.endsWith(
                                ".jpeg"
                              ) ||
                              compliance.cnd_identity_doc_upload_name.endsWith(
                                ".pdf"
                              )
                                ? "eye" //"eye"
                                : "download")
                            }
                          ></i>
                        </a>
                      </td>
                      {/* <td>{compliance.cnd_identity_doc_description}</td> */}
                      <td className="col-2 text-center">
                        {compliance.cnd_identity_doc_reference}
                      </td>
                      <td className="col-3 text-center">
                        {compliance.cnd_identity_doc_exp_date}
                      </td>
                      <td className="col-2 text-center">
                        {new Date() <=
                          new Date(
                            splitDateToFormat(
                              compliance.cnd_identity_doc_exp_date
                            )
                          ) &&
                        dateDiffrence(compliance.cnd_identity_doc_exp_date) > 30
                          ? "Valid "
                          : new Date() <=
                              new Date(
                                splitDateToFormat(
                                  compliance.cnd_identity_doc_exp_date
                                )
                              ) &&
                            dateDiffrence(
                              compliance.cnd_identity_doc_exp_date
                            ) < 30
                          ? "About to Expire"
                          : "Expired"}
                      </td>
                      {cndApproved != "Approved" && (
                        <td className="col-1" scope="col">
                          {" "}
                          <Link
                            to={
                              "/create-identity-document/" +
                              compliance.cnd_identity_doc_id
                            }
                          >
                            <i className="fa fa-pencil"></i>
                          </Link>
                        </td>
                      )}
                    </tr>
                  ))}
            </tbody>
          </table>
          {identityDocuments && identityDocuments.length > 0 && (
            <TableDropdownAndPagination
              handleDropDown={handleDropDown}
              recordLen={recordLen}
              handleLeft={handleLeft}
              handleRight={handleRight}
              renderPageNumbers={renderPageNumbers}
              isDropdown={true}
              isPaggination={true}
            />
          )}
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"Identity Document"}
        fileName={fileName}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    identityDocuments: state.identityDocuments.identityDocuments,
  };
};

export default connect(mapStateToProps)(IdentityList);
