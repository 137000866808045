import React from "react";
// import Profilesidebar from '../../components/Element/Profilesidebar';
// import StepWizard from 'react-step-wizard';
// import Nav from '../components/Element/CandidateProfileForms/Nav';
// import Resume from '../components/Element/Resume';
import { ProgressBar } from "react-bootstrap";
import CandidateProfileSidebar from "./CandidateProfileSidebar";
import UpdateCandidateProfileCV from "./CV";
import CandidateFullDetailsForm from "./CandidateProfileDetails/CandidateFullDetailsForm";
import CandidateJobProfileWorkQualification from "../../containers/CandidateJobProfileWorkQualification";

const CandidateProfile = (props) => {
  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-blocks">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-12 m-b30">
                  <CandidateProfileSidebar page="profile-and-cv" />
                </div>
                <div className="col-xl-8 col-lg-12 m-b30">
                  <div
                    className="mb-4"
                    style={{ borderRadius: "20px" }}
                  >
                    {/* Big Form Start */}
                      <CandidateFullDetailsForm />
                    {/* Big Form End */}
                  </div>
                  <div className="mb-4 mt-2">
                    <UpdateCandidateProfileCV callParam="cnd_upload_cv" />
                  </div>
                  {/* Video Upload - Start */}
                  {/* <div className="mb-4 mt-2">
                    <UpdateCandidateProfileCV callParam="cnd_upload_identity_video" />
                  </div> */}
                  {/* Video Upload - End */}
                  <div className="mb-4">
                    <UpdateCandidateProfileCV callParam="cnd_upload_cover" />
                  </div>
                  <div className="mb-4">
                    <UpdateCandidateProfileCV callParam="cnd_upload_personal_statement" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateJobProfileWorkQualification(CandidateProfile);
