import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Respite-care-banner.jpg";
// import HomecareImg from "./../images/ourservices/Respite-care.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/home-care/respite-care/our-services-home-care-respite-care.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/home-care/respite-care/our-services-home-care-respite-care-01.jpg";
import bnr3 from "./../images/lines.png";

export default function Home_Respitecare() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Our Services - Respite Care";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/respite-care"
            ? "0rem"
            : "" }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Respite Care</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                  {window.location.pathname == "/mobile/respite-care" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Home Care</li>
                  <li>Respite Care</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Respite Care </h2>
                </div>
              </div>

              <div className="row align-items-center m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                  <br />
                </div>
                <div className="col-md-12 col-lg-6 m-b10">
                  <p className="spacebar mt-3"></p>
                  <h6>
                    Reliable Respite Care: Supporting Family Caregivers with
                    Staffing Connect
                  </h6>

                  <p className="text-left">
                    Respite care offers essential temporary relief to those
                    dedicated individuals who provide care for their family
                    members. At Staffing Connect, we understand the immense joy
                    families experience in caring for their loved ones and
                    enabling them to remain at home. However, we also recognize
                    the physical, emotional, and financial toll it can take on
                    family caregivers. That's why we are here to provide
                    much-needed support and respite services.
                  </p>

                  <p className="text-left">
                    Our respite care program is designed to offer a planned and
                    structured approach, accommodating families' specific needs.
                    Whether you require a few hours of respite or a few weeks,
                    we can create a tailored care schedule to ensure that
                    families and unpaid caregivers receive the temporary break
                    they deserve.
                  </p>
                </div>
              </div>
              <p className="spacebar " style={{ marginBottom: "-5%" }}></p>
              <p className="text-left">
                Taking a break from the demanding caregiving responsibilities is
                crucial for the well-being of the family caregiver. Respite care
                allows them to relax, recharge, and attend to their physical and
                emotional needs. It has been proven that respite care not only
                sustains family caregivers but also brings them significant
                health and well-being benefits.
              </p>

              <p className="text-left">
                Staffing Connect is here to lend a helping hand. Our
                compassionate team provides high-quality respite care services
                that prioritize the needs of the family caregiver and the
                individual requiring care. With our support, family caregivers
                can enjoy a well-deserved break or holiday, knowing their loved
                ones are in capable hands.
              </p>

              <p className="text-left">
                We recognize the value of respite care in sustaining family
                caregivers' health and overall well-being. Let Staffing Connect
                be your trusted partner in providing the respite care you need,
                ensuring that you can continue to provide loving care while
                taking care of yourself.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
