import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const RightToWorksContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token && props.rightToWorks.length <= 0){
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'SetRightToWorks','SET_RIGHT_TO_WORKS'))
      }
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    rightToWorks:state.rightToWorks.rightToWorks,
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData
  };
};

const composedRightToWork = compose(connect(mapStateToProps), RightToWorksContainer)
export default composedRightToWork;