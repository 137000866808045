export const SET_ERROR = "SET_ERROR";

const failure = (content) => {
  return {
    type: SET_ERROR,
    payload: content,
  };
};

export const setError = (errors) => {
  return (dispatch) => {
    dispatch(failure(errors));
  };
};
