import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadloginInfo } from "./../redux/actions/AuthActions";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import { useHistory } from "react-router-dom";
import { updateDataOnRequest } from "../redux/actions/CommonActions";

var bnr = require("./../images/banner/bnr2.jpg");

const OtpVerification = (props) => {
  // Add title
  document.getElementById("titleText").innerText = "Staffing Connect - OTP Verification";
  let history = useHistory();
  let usersInfo, usersEmail, usersRoles;
  const [formData, setFormData] = useState({});
  const [otpData, setOtpData] = useState({});

  // To Get The Email And User Role - (Start)
  usersInfo = JSON.parse(localStorage.getItem("users_email")) ? JSON.parse(localStorage.getItem("users_email")) : "";
  usersEmail = usersInfo.users_email;
  usersRoles = usersInfo.users_role_id;

  if(!usersEmail && !usersRoles){
    window.location.href = "/login";
  }
  // console.log("Users Role :=> "+ usersRoles);
  // console.log("Users Email :=> "+ usersEmail);

  // To Get The Email And User Role - (Start)

  //   const [email, setEmail] = useState("");
  const [otp, setOtp] = useState();
  //   let errorsObj = { otp: "" };
  //   const [errors, setErrors] = useState(errorsObj);
  //   const [password, setPassword] = useState("");

  const [userType, setUserType] = useState("candidate");
  const dispatch = useDispatch();

  // To Disable The Paste Feature on Screen

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    //formData = {"otp" :e.target.value};
    // console.log("This Is OTP :=>"+JSON.stringify(formData));
  };

  let resendOtp = (e) => {
    document.getElementById("loader").style.display = "block";
    let finalForm;
    e.preventDefault();
    
    
    finalForm = {
      users_email: usersEmail,
      users_role_id: usersRoles,
    };
    // console.log("Testing for Resend OTP");
    
    dispatch(
      updateDataOnRequest(JSON.stringify(finalForm, null, 2), "ResendOTP", "")
    );
  };

  const handleSend = (e) => {
    // Set the date we're counting down to
    
    
    const currentTime = new Date();
    const futureTime = new Date(currentTime.getTime() + 60000);
    var countDownDate = futureTime;

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      document.getElementById("demo").innerHTML = /*days + "d " + hours + "h "
      + minutes + "m " +*/"Resend in " + seconds + "s ";
      document.getElementById("resendButton").style.display = "none";
      document.getElementById("demo").style.display = "block";

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        // document.getElementById("demo").innerHTML = "EXPIRED";
        document.getElementById("demo").style.display = "none";
        document.getElementById("resendButton").style.display = "block";
      }
    }, 1000);
  };

  const onLogin = (e) => {
    // let formData;
    e.preventDefault();
    // Code Start for OTP
    let jsonFormData,
      jsonObject,
      one,
      two,
      three,
      four,
      five,
      six,
      finalOTP,
      finalForm;
    jsonFormData = JSON.stringify(formData);
    jsonObject = JSON.parse(jsonFormData);

    one = jsonObject.one;
    two = jsonObject.two;
    three = jsonObject.three;
    four = jsonObject.four;
    five = jsonObject.five;
    six = jsonObject.six;

    finalOTP = one + two + three + four + five + six;

    finalForm = {
      users_email: usersEmail,
      users_role_id: usersRoles,
      user_otp: finalOTP,
    };

    //console.log("This Is OTP :=>"+ JSON.stringify(finalForm));
    // let error = false;
    // const errorObj = { ...errorsObj };

    // if (otp === "") {
    //   errorObj.otp = "OTP is required!";
    //   error = true;
    // }
    // setErrors(errorObj);
    // if (error) {
    //   return;
    // }
    document.getElementById("loader").style.display = "block";
    dispatch(
      updateDataOnRequest(JSON.stringify(finalForm, null, 2), "VerifyOTP", "")
    );
  };

  

  //   const changeTab = (activeTab) => {
  //     setUserType(activeTab);
  //   };
  //   console.log(props.errorMessage);

  // Set the date we're counting down to
  
  if(!usersEmail && !usersRoles){
    window.location.href = "/login";
  }else{
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="section-full content-inner-2 shop-account">
            <div className="container">
              <div className="max-w500 m-auto bg-white m-b30">
                <div className="p-a30 job-bx browse-job radius-sm seth loginSignup">
                  <div className="tab-content nav" onPaste={false}>
                    <form
                      id="login"
                      onSubmit={onLogin}
                      onInput={handleChange}
                      className="tab-pane active col-12 p-a0 "
                      onPaste={false}
                      autocomplete="off"
                    >
                      <h1 style={{display:"none"}}>Staffing Connect - OTP Verification Details</h1>
                      <h4 className="font-weight-700 text-center">Enter OTP</h4>
  
                      <div className="form-group mt-5 otp-form">
                        <input
                          className="otp-field form-control"
                          type="text"
                          name="one"
                          maxLength={1}
                          required
                          onPaste={false}
                        />
                        <input
                          className="otp-field form-control"
                          type="text"
                          name="two"
                          maxLength={1}
                          required
                          onPaste={false}
                        />
                        <input
                          className="otp-field form-control"
                          type="text"
                          name="three"
                          maxLength={1}
                          required
                          onPaste={false}
                        />
                        <input
                          className="otp-field form-control"
                          type="text"
                          name="four"
                          maxLength={1}
                          required
                          onPaste={false}
                        />
                        <input
                          className="otp-field form-control"
                          type="text"
                          name="five"
                          maxLength={1}
                          required
                          onPaste={false}
                        />
                        <input
                          className="otp-field form-control"
                          type="text"
                          name="six"
                          maxLength={1}
                          required
                          onPaste={false}
                        />
  
                        {/* <input
                          
                          name="otp-value"
                          // required=""
                          className="form-control otp-value"
                          placeholder="Your OTP Is"
                          type="hidden"
                          id="otp-value"
                          maxLength={6}
                          onBeforeInput={handleChange}                        
                        /> */}
  
                        {/* {errors.otp && (
                          <div className="text-danger fs-12">{errors.otp}</div>
                        )} */}
                      </div>
  
                      <div className="text-center mt-5">
                        <button className="btn btn-success green pull-right m-r5 button-md">
                          Verify
                        </button>
                        <p id="demo" className="btn btn-dark pull-right m-r5 button-md"></p>
                        <button
                          type="button"
                          id="resendButton"
                          onClick={handleSend}
                          onClickCapture={resendOtp}
                          className="site-button blue pull-right m-r5 button-lg"
                          style={{display:"none"}}
                        >
                          Resend
                        </button>
                        {/* <Link
                          data-toggle="tab"
                          to="#forgot-password"
                          className="m-l5 m-t15 forget-pass float-right"
                        >
                          <i className="fa fa-unlock-alt" /> Forgot Password
                        </Link> */}
                      </div>
                    </form>
                    <div className="row mt-3">
                      <div className="col-sm-12">
                        <h6
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                          }}
                        >
                          Didn't receive an OTP Email? This shouldn't have
                          happened. However, you can try the following options:
                        </h6>
                        <ul className="ml-4">
                          <li>
                            Please make sure that you have typed the correct
                            email.
                          </li>
                          <li>
                            Wait for at least 5 minutes to see if you receive
                            the email.
                          </li>
                          <li>
                            Check your spam/junk folder and other folders like
                            Promotions/Social/Updates.
                          </li>
                        </ul>
                        <p>
                          If you still have not received the OTP, you can raise
                          a support query by contacting us at{" "}
                          <a
                            href="mailto:info@staffingconnect.co.uk"
                            className="text-primary"
                          >
                            info@staffingconnect.co.uk
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

  }
};

const mapStateToProps = (state) => {
  return {
    authData: isAuthenticated(state),
    errorMessage: state.error.error,
  };
};

export default connect(mapStateToProps)(OtpVerification);
