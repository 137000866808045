import {sortCode, nationalInsurance, accountNumber} from './ValidationHelpers'

let errorsObj = {cnd_rehabilitation_checkbox_one:'',
                  cnd_rehabilitation_text_one:'',
                  cnd_rehabilitation_checkbox_two:'',
                  cnd_rehabilitation_text_two:'',
                  cnd_rehabilitation_text_three:'',
                  cnd_rehabilitation_checkbox_three:'',
                  cnd_rehabilitation_text_four:'',
                  cnd_rehabilitation_checkbox_four:'',
                  cnd_rehabilitation_checkbox_five:'',
                  cnd_rehabilitation_text_five:'',
                  cnd_rehabilitation_checkbox_six:'',
                  cnd_rehabilitation_text_six:'',
                  cnd_rehabilitation_checkbox_seven:'',
                  cnd_rehabilitation_text_seven:'',
                  cnd_rehabilitation_checkbox_eight:'',
                  cnd_rehabilitation_text_eight:'',
};

export const RehebitationDataValidations = (data) => {
  let error = false;
  const errorObj = { ...errorsObj };
  if (!data.cnd_rehabilitation_checkbox_one) {
    errorObj.cnd_rehabilitation_checkbox_one = "Please select valid value";
    error = true;
  }else if(data.cnd_rehabilitation_checkbox_one == '1' && !data.cnd_rehabilitation_text_one){
    errorObj.cnd_rehabilitation_text_one = "please enter description";
    error = true;
  }
  if (!data.cnd_rehabilitation_checkbox_two) {
    errorObj.cnd_rehabilitation_checkbox_two = "Please select valid value";
    error = true;
  }else if(data.cnd_rehabilitation_checkbox_two == '1' && !data.cnd_rehabilitation_text_two){
    errorObj.cnd_rehabilitation_text_two = "please enter description";
    error = true;
  }
  if (!data.cnd_rehabilitation_checkbox_three) {
    errorObj.cnd_rehabilitation_checkbox_three = "Please select valid value";
    error = true;
  }else if(data.cnd_rehabilitation_checkbox_three == '1' && !data.cnd_rehabilitation_text_three){
    errorObj.cnd_rehabilitation_text_three = "please enter description";
    error = true;
  }
  if (!data.cnd_rehabilitation_checkbox_four) {
    errorObj.cnd_rehabilitation_checkbox_four = "Please select valid value";
    error = true;
  }else if(data.cnd_rehabilitation_checkbox_four == '1' && !data.cnd_rehabilitation_text_four){
    errorObj.cnd_rehabilitation_text_four = "please enter description";
    error = true;
  }
  if (!data.cnd_rehabilitation_checkbox_five) {
    errorObj.cnd_rehabilitation_checkbox_five = "Please select valid value";
    error = true;
  }else if(data.cnd_rehabilitation_checkbox_five == '1' && !data.cnd_rehabilitation_text_five){
    errorObj.cnd_rehabilitation_text_five = "please enter description";
    error = true;
  }
  if (!data.cnd_rehabilitation_checkbox_six) {
    errorObj.cnd_rehabilitation_checkbox_six = "Please select valid value";
    error = true;
  }else if(data.cnd_rehabilitation_checkbox_six == '1' && !data.cnd_rehabilitation_text_six){
    errorObj.cnd_rehabilitation_text_six = "please enter description";
    error = true;
  }
  if (!data.cnd_rehabilitation_checkbox_seven) {
    errorObj.cnd_rehabilitation_checkbox_seven = "Please select valid value";
    error = true;
  }else if(data.cnd_rehabilitation_checkbox_seven == '1' && !data.cnd_rehabilitation_text_seven){
    errorObj.cnd_rehabilitation_text_seven = "please enter description";
    error = true;
  }
  if (!data.cnd_rehabilitation_checkbox_eight) {
    errorObj.cnd_rehabilitation_checkbox_eight = "Please select valid value";
    error = true;
  }else if(data.cnd_rehabilitation_checkbox_eight == '1' && !data.cnd_rehabilitation_text_eight){
    errorObj.cnd_rehabilitation_text_eight = "please enter description";
    error = true;
  }
  
  return {stateData:errorObj,error:error};
}