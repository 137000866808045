import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Form, Col, Spinner, Button, Table } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInputFields, validationSchema } from "./FormHelper";
import LoadFormFields from "../../Layout/LoadFormFields";
import { updateDataOnRequest } from "../../../redux/actions/CommonActions";
const Assignment = (props) => {
  const [assignments, setFormdata] = useState({});
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [dates, setDates] = useState(0);
  const { loader, userDetails, assesmentShifts } = props;

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const dispatch = useDispatch();
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(d1) {
    let date = new Date(d1),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const onSubmit = (data) => {
    let formData = data;
    formData["users_access_token"] = userDetails.users_access_token;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "SaveAssignmentData",
        ""
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var today = new Date();
    var day = today.getDay() || 7; // Get current day number, converting Sun. to 7
    if (day !== 1)
      // Only manipulate the date if it isn't Mon.
      today.setHours(-24 * (day - 1));
    let dateAftersixDays = getDatAfterDay(today, 6);
    assignments["clt_shift_toDate"] = dateAftersixDays;
    var date = formatDate(today);
    assignments["clt_shift_fromDate"] = date;
    setValue("clt_shift_toDate", dateAftersixDays, {
      shouldValidate: true,
    });
    setValue("clt_shift_fromDate", date, {
      shouldValidate: true,
    });

    setFormdata(assignments);
    let currentDate = today;
    let firstday = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)
    ).toUTCString();
    let lastday = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7)
    ).toUTCString();
    setStartDate(formatDate(firstday));
    setEndDate(formatDate(lastday));
    getDatesBetweenTwoDates(firstday, lastday);
  }, []);

  const getDatAfterDay = (selectDate, selectDay) => {
    let result = new Date(selectDate);
    result.setDate(result.getDate() + selectDay);
    let date = new Date(result),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const onChangeInputvalue = (e) => {
    const { name, value } = e.target;
    assignments[name] = value;
    if (name === "clt_shift_fromDate") {
      let dateAftersixDays = getDatAfterDay(value, 6);
      getDatesBetweenTwoDates(value, dateAftersixDays);
      assignments["clt_shift_toDate"] = dateAftersixDays;
      setValue("clt_shift_toDate", dateAftersixDays, {
        shouldValidate: true,
      });
    }
    setFormdata(assignments);
  };

  const getDatesBetweenTwoDates = (startdate, enddate) => {
    const start = new Date(startdate);
    const end = new Date(enddate);
    const daysBetween = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
    const arr = [];

    for (let i = 0; i <= daysBetween; i++) {
      let temp = getDatAfterDay(startdate, i);
      arr.push(formatDate(temp));
    }
    setValue("client", arr);
    setDates(arr);
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <input type="text" name="daterange" value="01/01/2018 - 01/15/2018" /> */}
        <LoadFormFields
          register={register}
          errors={errors}
          formInputFields={formInputFields()}
          onChangeInputvalue={(e) => onChangeInputvalue(e)}
          reset={reset}
          control={control}
          loadExternalButtons={true}
          data={assignments}
        />
        <React.Fragment>
          <div className="table-responsive ">
            <Table bordered hover size="xs">
              <thead>
                <tr>
                  <th sm={1} md={1} lg={1} className="th-days m-r0">
                    #
                  </th>
                  <th className="th-days">DATE</th>
                  <th className="th-days m-r0">DAY</th>
                  <th className="th-days">IN TIME</th>
                  <th className="th-days">OUT TIME</th>
                  <th className="th-days">WAKING NIGHT</th>
                  <th className="th-days">SLEEP IN</th>
                </tr>
              </thead>
              <tbody>
                {dates &&
                  dates.map((date, index) => {
                    const formError =
                      (errors.client?.length && errors.client[index]) || {};
                    return (
                      <React.Fragment key={index + date}>
                        <tr>
                          <td className="custom-td">
                            <Form.Group>
                              <Form.Check />
                            </Form.Group>
                          </td>
                          <td className="custom-td ">
                            <Form.Group className="th-formgroup m-r0" as={Col}>
                              <Form.Control
                                name={`${
                                  "client." + index + ".client_week_date"
                                }`}
                                className="th-input m-r0"
                                disabled={true}
                                type={"text"}
                                {...register(
                                  `${"client." + index + ".client_week_date"}`
                                )}
                                defaultValue={date}
                                isInvalid={
                                  formError.client_week_date ? true : ""
                                }
                              />
                            </Form.Group>
                          </td>
                          <td className="custom-td">
                            <Form.Group className="th-formgroup" as={Col}>
                              <Form.Control
                                disabled={true}
                                name={`${
                                  "client." + index + ".client_week_day"
                                }`}
                                className="th-input"
                                type={"text"}
                                defaultValue={weekdays[index]}
                                {...register(
                                  `${"client." + index + ".client_week_day"}`
                                )}
                                isInvalid={
                                  formError.client_week_day ? true : ""
                                }
                              />
                            </Form.Group>
                          </td>
                          <td className="custom-td">
                            <Form.Group className="th-formgroup" as={Col}>
                              <Form.Control
                                name={`${
                                  "client." + index + ".client_week_in_time"
                                }`}
                                className="th-input"
                                type={"time"}
                                {...register(
                                  `${
                                    "client." + index + ".client_week_in_time"
                                  }`
                                )}
                                isInvalid={
                                  formError.client_week_in_time ? true : ""
                                }
                              />
                            </Form.Group>
                          </td>
                          <td className="custom-td">
                            <Form.Group className="th-formgroup" as={Col}>
                              <Form.Control
                                name={`${
                                  "client." + index + ".client_week_out_time"
                                }`}
                                className="th-input"
                                type={"time"}
                                {...register(
                                  `${
                                    "client." + index + ".client_week_out_time"
                                  }`
                                )}
                                isInvalid={
                                  formError.client_week_out_time ? true : ""
                                }
                              />
                            </Form.Group>
                          </td>
                          <td className="custom-td">
                            <Form.Group className="th-formgroup" as={Col}>
                              <Form.Control
                                as="select"
                                name={`${
                                  "client." +
                                  index +
                                  ".client_week_waking_night"
                                }`}
                                className="th-input form-option"
                                type={"text"}
                                {...register(
                                  `${
                                    "client." +
                                    index +
                                    ".client_week_waking_night"
                                  }`
                                )}
                                isInvalid={
                                  formError.client_week_waking_night ? true : ""
                                }
                              >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Form.Control>
                            </Form.Group>
                          </td>
                          <td className="custom-td">
                            <Form.Group className="th-formgroup" as={Col}>
                              <Form.Control
                                as="select"
                                name={`${
                                  "client." + index + ".client_week_slip_in"
                                }`}
                                className="th-input form-option"
                                type={"text"}
                                {...register(
                                  `${
                                    "client." + index + ".client_week_slip_in"
                                  }`
                                )}
                                isInvalid={
                                  formError.client_week_slip_in ? true : ""
                                }
                              >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Form.Control>
                            </Form.Group>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </React.Fragment>
        <hr />
        <Form.Group as={Col} md="12" sm="12">
          <Button
            disabled={loader ? "disabled" : ""}
            className="site-button m-b30 float-right"
            type="submit"
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader && "Save"}
          </Button>
          <Button
            disabled={loader ? "disabled" : ""}
            className="m-b30 m-r5 site-button orange float-right"
            onClick={() => reset()}
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader && "Reset"}
          </Button>

          <br />
        </Form.Group>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    assesmentShifts: state.assesmentShifts.assesmentShifts,
  };
};
export default connect(mapStateToProps)(Assignment);
