import React from "react";
import { Link } from "react-router-dom";
import Background from "../../images/new-updated-images/job-board/permanent-jobs/job-board-permanent-job.jpg";
const postBlog = [
  {
    image: require("./../../images/logo/icon1.png"),
  },
  {
    image: require("./../../images/logo/icon1.png"),
  },
  {
    image: require("./../../images/logo/icon1.png"),
  },
  {
    image: require("./../../images/logo/icon1.png"),
  },
  {
    image: require("./../../images/logo/icon1.png"),
  },
  {
    image: require("./../../images/logo/icon1.png"),
  },
];

function Jobsection() {
  return (
    <>
      {window.location.pathname == "/temporary-jobs" && (
        <div
          className="dez-bnr-inr content-inner-3 dez-bnr-inr-md overlay-black-middle "
          style={{ backgroundImage: `url(${Background})`, height: 580 }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">
                <b style={{ display: "none" }}>Staffing Connect - </b>Job Board
                - Temporary Jobs
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li>Job Board</li>
                  <li>Temporary Jobs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="section-full bg-white content-inner-2">
        <div className="container">
          <div className="d-flex job-title-bx section-head">
            <div className="mr-auto">
              <h2 className="m-b5">Recent Assignments</h2>
              {/* <h6 className="fw4 m-b0">20+ Recently Added Jobs</h6> */}
            </div>
            {/* <div className="align-self-end">
						<Link to={"/browse-job-list"} className="site-button button-sm">Browse All Jobs 
						<i className="fa fa-long-arrow-right"></i></Link>
					</div> */}
          </div>
          <div className="row">
            <div className="col-lg-9">
              <ul className="post-job-bx browse-job">
                {/* {postBlog.map((item,index)=>(
									<li key={index}></li> */}
                <li>
                  <div className="post-bx">
                    <div className="d-flex m-b30">
                      <div className="job-post-company">
                        <span>
                          <img
                            alt="Icon 1"
                            src={require("./../../images/logo/icon1.png")}
                          />
                        </span>
                      </div>
                      <div className="job-post-info">
                        <h4>
                          <a href="/register"> Nursery Assistant</a>
                        </h4>
                        <ul>
                          <li>
                            <i className="fa"></i> Hounslow Part Time
                          </li>
                          {/* <li><i className="fa fa-bookmark-o"></i> Full Time</li> */}
                          {/* <li><i className="fa fa-clock-o"></i> Published 11 months ago</li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="job-time mr-auto">
                        <Link to={""}>
                          <span>Contract type: Temporary</span>
                        </Link>
                      </div>
                      <div className="salary-bx">
                        <span>£11.50 per hour</span>
                      </div>
                    </div>
                    {/* <label className="like-btn">
											<input type="checkbox" />
											<span className="checkmark"></span>
										</label>  */}
                  </div>
                </li>
                {/* ))}	 */}
                <li>
                  <div className="post-bx">
                    <div className="d-flex m-b30">
                      <div className="job-post-company">
                        {/* <span><img alt="" src={item.image} /></span> */}
                        <span>
                          <img
                            alt="Icon 2"
                            src={require("./../../images/logo/icon1.png")}
                          />
                        </span>
                      </div>
                      <div className="job-post-info">
                        <h4>
                          <a href="/register"> Exam Invigilator</a>
                        </h4>

                        <ul>
                          <li>
                            <i className="fa"></i> Hounslow Part Time
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="job-time mr-auto">
                        <Link to={""}>
                          <span>Contract type: Temporary</span>
                        </Link>
                      </div>
                      <div className="salary-bx">
                        <span>£11.50 per hour</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="post-bx">
                    <div className="d-flex m-b30">
                      <div className="job-post-company">
                        <span>
                          <img
                            alt="Icon 3"
                            src={require("./../../images/logo/icon1.png")}
                          />
                        </span>
                      </div>
                      <div className="job-post-info">
                        <h4>
                          <a href="/register"> Support Worker</a>
                        </h4>

                        <ul>
                          <li>
                            <i className="fa"></i> Richmond Part Time
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="job-time mr-auto">
                        <Link to={""}>
                          <span>Contract type: Temporary</span>
                        </Link>
                      </div>
                      <div className="salary-bx">
                        <span>£13 - £14 per hour</span>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="post-bx">
                    <div className="d-flex m-b30">
                      <div className="job-post-company">
                        <span>
                          <img
                            alt="Icon 4"
                            src={require("./../../images/logo/icon1.png")}
                          />
                        </span>
                      </div>
                      <div className="job-post-info">
                        <h4>
                          <a href="/register"> Care Worker</a>
                        </h4>
                        <ul>
                          <li>
                            <i className="fa"></i> Chessington Part Time
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="job-time mr-auto">
                        <Link to={""}>
                          <span>Contract type: Temporary</span>
                        </Link>
                      </div>
                      <div className="salary-bx">
                        <span>£13 - £14 per hour</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="post-bx">
                    <div className="d-flex m-b30">
                      <div className="job-post-company">
                        <span>
                          <img
                            alt="Icon 5"
                            src={require("./../../images/logo/icon1.png")}
                          />
                        </span>
                      </div>
                      <div className="job-post-info">
                        <h4>
                          <a href="/register">Warehouse Operative</a>
                        </h4>
                        <ul>
                          <li>
                            <i className="fa"></i> Hayes Full Time{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="job-time mr-auto">
                        <Link to={""}>
                          <span>Contract type: Temporary</span>
                        </Link>
                      </div>
                      <div className="salary-bx">
                        <span>£12.67 - £14.67 per hour</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="m-t30">
                <div className="d-flex">
                  {/* <Link className="site-button button-sm mr-auto" to={""}><i className="ti-arrow-left"></i> Prev</Link> */}
                  {/* <Link className="site-button button-sm" to={""}>Next <i className="ti-arrow-right"></i></Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div>
                <div className="candidates-are-sys m-b30">
                  <div className="candidates-bx">
                    <div className="testimonial-pic radius">
                      <img
                        src={require("./../../images/testimonials/testimonial-4.jpg")}
                        alt="Icon 6"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div className="testimonial-text">
                      <p style={{ marginTop: "35px" }}>
                        I was looking for part time work where I could manage my
                        child with school times. Staffing Connect found me exact
                        temporary work that fits my timetable perfectly.
                      </p>
                    </div>
                    <div className="testimonial-detail">
                      {" "}
                      <strong
                        className="testimonial-name"
                        style={{ color: "#6f6f6f" }}
                      >
                        SERENA ANDERSON HAMMERSMITH
                      </strong>{" "}
                      <span
                        className="testimonial-position"
                        style={{ color: "#6f6f6f" }}
                      >
                        LONDON
                      </span>{" "}
                    </div>
                  </div>
                </div>
                <div className="quote-bx">
                  <div className="quote-info">
                    <h4>Make a Difference with your Online CV!</h4>
                    <p>Apply for Temporary and Permanent jobs online. </p>
                    <a href={"/job-board"} className="site-button">
                      Go To Job Board
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Jobsection;
