import { format } from "date-fns";
import {validateStringWithoutSpecialCharacter,validateEmail,validateUkNumber} from './ValidationHelpers'
export const checkDBSValidation  = (data,type) => {
  let error = false;
  let errorObj = [];
  // console.log("All Data Are :=>"+JSON.stringify(data));
  data.map((x, i) => {
    errorObj[i] = {
                    cnd_dbs_type: "",
                    cnd_dbs_holder_certificate_number: "",
                    cnd_dbs_holder_surname: "",
                    cnd_dbs_holder_dob: "",
                    cnd_dbs_certificate_upload_file: "",
                    cnd_dbs_certificate_description: "",
                    cnd_dbs_certificate_exp_date: "",
                    cnd_dbs_certificate_reference: "",
                    cnd_dbs_certificate_update_service:"",
                    cnd_dbs_city: "",
                    cnd_dbs_country: "",
                    cnd_dbs_start_date :"",
                    cnd_dbs_end_date :"",
                    cnd_dbs_country_of_birth:"",
                    cnd_dbs_nis_no:"",
                    cnd_dbs_birth_of_town:"",
                    cnd_dbs_holder_title: "",
                    cnd_address_county: "",
                    cnd_dbs_surname_of_birth:"",
                  }
    
    if(type === 'yes'){
      if (!x.cnd_dbs_type) {
        errorObj[i]['cnd_dbs_type'] = "Please Select Valid Option";
        error = true;
      }
      if (!x.cnd_dbs_certificate_update_service) {
        errorObj[i]['cnd_dbs_certificate_update_service'] = "Please Select Valid Option";
        error = true;
      }
      if (!x.cnd_dbs_holder_certificate_number) {
        errorObj[i]['cnd_dbs_holder_certificate_number'] = "DBS Certificate Number Field is Required";
        error = true;
      }      
      if(x.cnd_dbs_holder_certificate_number && String(x.cnd_dbs_holder_certificate_number).trim() == ""){
        errorObj[i]['cnd_dbs_holder_certificate_number'] = "Please enter the valid certificate number";
        error = true;
      }
      if (!x.cnd_dbs_holder_surname) {
        errorObj[i]['cnd_dbs_holder_surname'] = "Holder Surname Field is Required";
        error = true;
      }
      
      if (!x.cnd_dbs_holder_dob) {
        errorObj[i]['cnd_dbs_holder_dob'] = "DOB Field is Required";
        error = true;
      }
      /*if (!x.cnd_dbs_certificate_doc_master_id) {
        errorObj[i]['cnd_dbs_certificate_doc_master_id'] = "Select Valid Option";
        error = true;
      }*/
      if (!x.cnd_dbs_certificate_upload_file) {
        errorObj[i]['cnd_dbs_certificate_upload_file'] = "Select Valid File";
        error = true;
      }
      /*if (!x.cnd_dbs_certificate_description) {
        errorObj[i]['cnd_dbs_certificate_description'] = "Please Enter Description";
        error = true;
      }else if (x.cnd_dbs_certificate_description.length > 500) {
        errorObj[i]['cnd_dbs_certificate_description'] = "Only 500 Characters Allowed";
        error = true;
      }*/
      if (!x.cnd_dbs_certificate_exp_date) {
        errorObj[i]['cnd_dbs_certificate_exp_date'] = "Issue Date Field is Required";
        error = true;
      }else {
        let issueDate = new Date(String(x.cnd_dbs_certificate_exp_date));
        let currentDate = new Date();
        let cnd_dbs_certificate_exp_date = document.getElementById("cnd_dbs_certificate_exp_date");
        let minDate = new Date(String(cnd_dbs_certificate_exp_date.min));
        if(issueDate > currentDate){
          errorObj[i]['cnd_dbs_certificate_exp_date'] = "Date must be "+format(currentDate,"dd-MM-yyyy")+" or before";
          error = true;
        }else if(minDate > issueDate){
          errorObj[i]['cnd_dbs_certificate_exp_date'] = "Date must be "+format(minDate,"dd-MM-yyyy")+" or later";
          error = true;
        }
      }
      // if (!x.cnd_dbs_certificate_reference) {
      //   errorObj[i]['cnd_dbs_certificate_reference'] = "Refrence Field is Required";
      //   error = true;
      // }
      // if (!x.cnd_dbs_certificate_update_service) {
      //   errorObj[i]['cnd_dbs_certificate_update_service'] = "Please Select Service";
      //   error = true;
      // }
    }else{
      if (!x.cnd_dbs_holder_title) {
        errorObj[i]['cnd_dbs_holder_title'] = "Holder Title Field is Required";
        error = true;
      }
      if (!x.cnd_dbs_country_of_birth) {
        errorObj[i]['cnd_dbs_country_of_birth'] = "Country Field is Required";
        error = true;
      }else if(!validateStringWithoutSpecialCharacter(x.cnd_dbs_country_of_birth) || x.cnd_dbs_country_of_birth.trim() == ""){
        errorObj[i]['cnd_dbs_country_of_birth'] = "Country Field Should Contain Only Characters";
        error = true;
      }
      if (!x.cnd_dbs_nis_no) {
        errorObj[i]['cnd_dbs_nis_no'] = "DBS NIS Field is Required";
        error = true;
      }
      if (!x.cnd_dbs_birth_of_town) {
        errorObj[i]['cnd_dbs_birth_of_town'] = "Birth Of Town Field is Required";
        error = true;
      }else if(!validateStringWithoutSpecialCharacter(x.cnd_dbs_birth_of_town) || x.cnd_dbs_birth_of_town.trim() == "" ){
        errorObj[i]['cnd_dbs_birth_of_town'] = "Birth Town Field Should Contain Only Characters";
        error = true;
      }
      if (!x.cnd_dbs_surname_of_birth) {
        errorObj[i]['cnd_dbs_surname_of_birth'] = "DBS Surname Of Birth";
        error = true;
      }else if(!validateStringWithoutSpecialCharacter(x.cnd_dbs_surname_of_birth) || x.cnd_dbs_surname_of_birth.trim() == ""){
        errorObj[i]['cnd_dbs_surname_of_birth'] = "Surname Field Should Contain Only Characters";
        error = true;
      }
      // Title - Start
      if (!x.cnd_dbs_holder_title) {
        errorObj[i]['cnd_dbs_holder_title'] = "Title required!";
        error = true;
      }else if(!validateStringWithoutSpecialCharacter(x.cnd_dbs_holder_title) || x.cnd_dbs_holder_title.trim() == ""){
        errorObj[i]['cnd_dbs_holder_title'] = "Special characters not allowed!";
        error = true;
      }
      // Title - End
      // County - Start
      if (!x.cnd_address_county) {
        errorObj[i]['cnd_address_county'] = "County/State is required!";
        error = true;
      }else if(!validateStringWithoutSpecialCharacter(x.cnd_address_county) || x.cnd_address_county.trim() == ""){
        errorObj[i]['cnd_address_county'] = "Special characters not allowed!";
        error = true;
      }
      // County - End
      if (x.cnd_address_city == "") {
        errorObj[i]['cnd_address_city'] = "City Field is Required";
        error = true;
      }else if (x.cnd_address_city.trim() == "") {
        errorObj[i]['cnd_address_city'] = "City Field is Required";
        error = true;
      }
      // Postcode Start
      if (x.cnd_address_postcode == "" || x.cnd_address_postcode.trim() == "") {
        errorObj[i]['cnd_address_postcode'] = "Post code is required!";
        error = true;
      }else if (!String(x.cnd_address_postcode).match(
        /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
       )) {
        errorObj[i]['cnd_address_postcode'] = "Please enter the valid Postcode!";
        error = true;
      }
      // PostCode End
      if (x.cnd_address_country == "") {
        errorObj[i]['cnd_address_country'] = "Country Field is Required";
        error = true;
      }else if(!validateStringWithoutSpecialCharacter(x.cnd_address_country)){
        errorObj[i]['cnd_address_country'] = "Country Field Should Contain Only Characters";
        error = true;
      }
      if (!x.cnd_address_address) {
        errorObj[i]['cnd_address_address'] = "Address Field is Required";
        error = true;
      }else if (x.cnd_address_address && x.cnd_address_address.trim() == "") {
        errorObj[i]['cnd_address_address'] = "Address Field is Required";
        error = true;
      }
      if (!x.cnd_address_start_date) {
        errorObj[i]['cnd_address_start_date'] = "Start Date Field is Required";
        error = true;
      }else if(x.cnd_address_start_date) {
        let startDate = new Date(String(x.cnd_address_start_date));
        let currentDate = new Date();
        let cnd_address_start_date = document.getElementById("cnd_address_start_date");
        let minDate = new Date(String(cnd_address_start_date.min));
        if(startDate > currentDate){
          errorObj[i]['cnd_address_start_date'] = "Date must be "+format(currentDate,"dd-MM-yyyy")+" or before";
          error = true;
        }else if(minDate > startDate){
          errorObj[i]['cnd_address_start_date'] = "Date must be "+format(minDate,"dd-MM-yyyy")+" or later";
          error = true;
        }
        // if(startDate > currentDate){
        //   errorObj[i]['cnd_address_start_date'] = "Date must be "+format(currentDate,"dd-MM-yyyy")+" or before";
        //   error = true;
        // }
      }
      if (!x.cnd_address_end_date) {
        errorObj[i]['cnd_address_end_date'] = "End Date Field is Required";
        error = true;
      }else if (x.cnd_address_start_date) {
        var d2 = new Date(x.cnd_address_end_date);
        var d1 = new Date(x.cnd_address_start_date);
        
        let endDate = new Date(String(x.cnd_address_end_date));
        let currentDate = new Date();
        let cnd_address_end_date = document.getElementById("cnd_address_end_date");
        let minDate = new Date(String(cnd_address_end_date.min));
        if(endDate > currentDate){
          errorObj[i]['cnd_address_end_date'] = "Date must be "+format(currentDate,"dd-MM-yyyy")+" or before";
          error = true;
        }else if(minDate > endDate){
          errorObj[i]['cnd_address_end_date'] = "Date must be "+format(minDate,"dd-MM-yyyy")+" or later";
          error = true;
        }else if(d2 <= d1){
          errorObj[i]['cnd_address_end_date'] = "End Date Should Be Greater Than Start Date";
          error = true;
        }
      
      }

      // if (x.cnd_address_start_date !== "" && x.cnd_address_end_date != "") {
      //   var d2 = new Date(x.cnd_address_end_date);
      //   var d1 = new Date(x.cnd_address_start_date);
      //   if(d2 < d1){
      //     errorObj[i]['cnd_address_end_date'] = "End Date Should Be Greater Than Start Date";
      //     error = true;
      //   }
      // }
      
    }
  })
  return {stateData:errorObj,error:error};
}