import { main } from "react-devtools";
import * as Yup from "yup";
export const formInputFields = (riskProfile, key) => [
  {
    name: `assesmentCategory.${key}.clt_ass_develop_assessment_master_id`,
    field: "clt_ass_develop_assessment_master_id",
    type: "dropdown",
    options: riskProfile,
    label: "Assesment Profile Master Name",
    md: "6",
    sm: "12",
  },
  {
    name: `assesmentCategory.${key}.clt_ass_develop_careplan_id`,
    field: "clt_ass_develop_careplan_id",
    type: "hidden",
    md: "6",
    sm: "12",
  },
  {
    name: `assesmentCategory.${key}.clt_ass_develop_support`,
    type: "textarea",
    label: "Develop Support",
    field: "clt_ass_develop_support",
    md: "6",
    sm: "12",
  },
  {
    name: `assesmentCategory.${key}.clt_ass_develop_link_to`,
    type: "textarea",
    label: "Develop Link To",
    field: "clt_ass_develop_link_to",
    md: "6",
    sm: "12",
  },
  {
    name: `assesmentCategory.${key}.clt_ass_develop_personal_aim`,
    type: "textarea",
    label: "Develop Plan Aim",
    field: "clt_ass_develop_personal_aim",
    md: "6",
    sm: "12",
  },
];

export const validationSchema = Yup.object().shape({
  assesmentCategory: Yup.array().of(
    Yup.object().shape({
      clt_ass_develop_assessment_master_id: Yup.string()
        .required("Please Select Profile Name")
        .typeError("Please Select Profile Name"),
      clt_ass_develop_support: Yup.string().required(
        "Develop Support Field Required"
      ),
      clt_ass_develop_link_to: Yup.string().required(
        "Develop Link To Field Required"
      ),
      clt_ass_develop_personal_aim: Yup.string().required(
        "Develop Personal Aim Field Required"
      ),
    })
  ),
});
