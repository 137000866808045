import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Form, Col, Spinner, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Label from "../../Layout/Label";
import { formInputFields, validationSchema } from "./FormHelper";
import { updateDataOnRequest } from "../../../redux/actions/CommonActions";
const AppoinmentForm = (props) => {
  const [company, setFormdata] = useState({});
  const { loader, userDetails, appoinments } = props;
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFormdata(appoinments);
    if (Object.keys(appoinments).length > 0)
      Object.keys(appoinments).map((key) => {
        setValue(key, appoinments[key]);
      });
  }, [appoinments]);

  const onSubmit = (data) => {
    let formData = data;
    formData["users_access_token"] = userDetails.users_access_token;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "UpdateAppoinment",
        ""
      )
    );
  };

  const onChangeInputvalue = (e) => {
    const { name, value } = e.target;
    company[name] = value;
    setFormdata(company);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-b30">
          {formInputFields(company).map((form, formKey) => (
            <React.Fragment key={formKey + form.type}>
              {form.type !== "dropdown" && (
                <Form.Group
                  as={Col}
                  controlId={formKey}
                  key={formKey}
                  md={form.md}
                  sm={form.sm}
                >
                  <Label labelName={form.label} />
                  <Form.Control
                    label={form.label}
                    name={form.name}
                    type={form.type}
                    {...register(form.name)}
                    onKeyUp={(e) => onChangeInputvalue(e)}
                    isInvalid={errors[form.name] ? true : ""}
                    isValid={company[form.name] ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[form.name]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              {form.type == "dropdown" && (
                <Form.Group
                  as={Col}
                  controlId={formKey}
                  key={formKey}
                  md={form.md}
                  sm={form.sm}
                >
                  <Label labelName={form.label} />
                  <Form.Control
                    as="select"
                    label={form.label}
                    name={form.name}
                    type={form.type}
                    {...register(form.name)}
                    isInvalid={errors[form.name] ? true : ""}
                    isValid={company[form.name] ? true : false}
                  >
                    {form.options.map((option, key) => (
                      <option key={key + option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors[form.name]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </React.Fragment>
          ))}
          <Form.Group as={Col} md="12" sm="12">
            <Button
              disabled={loader ? "disabled" : ""}
              className="site-button m-b30 float-right"
              type="submit"
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Save"}
            </Button>
            <Button
              disabled={loader ? "disabled" : ""}
              className="m-b30 m-r5 site-button orange float-right"
              onClick={() => reset()}
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Reset"}
            </Button>
          </Form.Group>
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    appoinments: state.appoinments.appoinments,
  };
};
export default connect(mapStateToProps)(AppoinmentForm);
