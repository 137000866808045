import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import RegisterForm from "./RegisterForm";
import { loadSignUpInfo } from "../../../redux/actions/AuthActions";
import {
  candidateFormFields,
  clientFormFields,
  clientFormSpecificFields,
  validationSchemaClient,
  validationSchemaSpecificClient,
  validationSchemaCandidate,
} from "./FormHelper";
const RegisterIndex = (props) => {
  const [userdata, setFormdata] = useState({});
  const [userType, setUserType] = useState("candidate");
  const [category, setUserCategory] = useState("");
  const [displayIntrestedIn, setDisplayIntrestedIn] = useState(false);
  const { loader, error } = props;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      userType == "client"
        ? category == "social care" || category == "education"
          ? validationSchemaSpecificClient
          : validationSchemaClient
        : validationSchemaCandidate
    ),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    let formData = data;
    formData["users_role_id"] = userType;
    delete formData["right_to_work"];
    //Candidate On Focus Start
    let right_to_work = document.getElementById("right_to_work");
    let users_firstname = document.getElementById("users_firstname");
    let users_lastname = document.getElementById("users_lastname");
    let job_type = document.getElementById("job_type");
    let users_category = document.getElementById("users_category");
    let users_email = document.getElementById("users_email");
    let users_phonenumber = document.getElementById("users_phonenumber");
    let users_password = document.getElementById("users_password");

    // if(!right_to_work.value){
    //   right_to_work.focus();
    // }else if(!users_firstname.value){
    //   users_firstname.focus();
    // }
    if(formData.users_email){
      formData["users_email"] = formData.users_email.trim();
    }
    if(formData.users_firstname){
      formData["users_firstname"] = formData.users_firstname.trim();
    }
    if(formData.users_lastname){
      formData["users_lastname"] = formData.users_lastname.trim();
    }
    
    document.getElementById("loader").style.display = "block";
    // Candidate on Focus Stop
    dispatch(loadSignUpInfo(JSON.stringify(formData, null, 2)));
  };

  const handleChange = () => {
    let users_password, hidePassword, showPassword;
    users_password = document.getElementById("users_password");
    showPassword = document.getElementById("showPasswordusers_password");
    hidePassword = document.getElementById("hidePasswordusers_password");

    if (users_password.value.length > 5) {
      if (showPassword) {
        showPassword.style.display = "block";
      }
    } else {
      if (showPassword) {
        showPassword.style.display = "none";
      }
    }
  };

  const setUserTypeHandler = (type) => {
    reset();
    setUserType(type);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="section-full content-inner browse-job shop-account ">
          <div className="container loginSignup">
            <div className="row">
              <div className="col-md-12 m-b30">
                <div className="p-a30 job-bx max-w500 radius-sm bg-white m-auto">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    onChange={handleChange}
                  >
                    <h4>REGISTER</h4>
                    <h1 style={{ display: "none" }}>
                      Staffing Connect - Registration Page Details
                    </h1>
                    <p className="font-weight-600">
                      If you have an account with us, please{" "}
                      <a className="blue" href="/login">
                        log in
                      </a>
                      .
                    </p>
                    {error.length > 0 && (
                      <div className="bg-red-300 font-weight-bold text-red text-danger border border-danger p-1 my-2">
                        {error}
                      </div>
                    )}
                    {/* <div className="careerfy-user-options">
                      <ul className="nav nav-tabs custom-tabs">
                        <li
                          className={`${userType == "candidate" && "active"}`}
                          id="login-candidate"
                        >
                          <Link
                            to="#"
                            onClick={() => setUserTypeHandler("candidate")}
                            data-toggle="tab"
                          >
                            <i className="careerfy-icon careerfy-user"></i>
                            <h5 className="mb-0">Candidate</h5>
                            <p>Discover new jobs</p>
                          </Link>
                        </li>
                        <li
                          className={`${userType == "client" && "active"}`}
                          id="login-employer"
                        >
                          <Link
                            to="#"
                            data-toggle="tab"
                            onClick={() => setUserTypeHandler("client")}
                          >
                            <i className="careerfy-icon careerfy-building"></i>
                            <h5 className="mb-0">Client</h5>
                            <p>I want to attract the best talent.</p>
                          </Link>
                        </li>
                      </ul>
                    </div> */}

                    <RegisterForm
                      onSubmit={() => onSubmit()}
                      userdata={userdata}
                      userRole={userType}
                      register={register}
                      errors={errors}
                      usertype={userType}
                      onChangeDropDownValue={(value) => setUserCategory(value)}
                      category={userdata.users_category}
                      candidateFormFields={candidateFormFields}
                      clientFormFields={clientFormFields}
                      clientFormSpecificFields={clientFormSpecificFields}
                    />
                    <div className="col-md-12 m-b10">
                      <div className="condition-message">
                        By clicking Register, you agree to Staffing Connect’s
                        Terms and Conditions and you acknowledge that you have
                        read Staffing Connect’s Privacy Notice which will apply
                        to the processing of your personal data in the provision
                        of our services.
                      </div>
                    </div>
                    <div className="col-md-12 m-b30">
                      <div className="text-left">
                        <button className="site-button m-r5 button-lg">
                          REGISTER
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.error.error,
  };
};
export default connect(mapStateToProps)(RegisterIndex);
