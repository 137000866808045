import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";

//import {logout} from '../../store/actions/AuthActions';
import { isAuthenticated } from "../../redux/selectors/AuthSelectors";

const LogoutPage = (props) => {
  let history = useHistory();

  const onLogout = () => {
    localStorage.clear("loginUserDetails");
    window.location.href = "/";
  };

  return (
    <React.Fragment>
      <Link
        style={{ display: props.isAuthenticated ? "inline-block" : "none" }}
        to={"#"}
        title="READ MORE"
        className="site-button"
        onClick={() => onLogout()}
      >
        <i className="fa fa-lock" /> Logout
      </Link>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(LogoutPage));
