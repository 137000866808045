import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/social-respite-banner.jpg";
// import HomecareImg from "./../images/ourservices/social-respite.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/social-care/elderly-care-services/our-services-social-care-elderly-care-services.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/social-care/elderly-care-services/our-services-social-care-elderly-care-services-01.jpg";

import bnr3 from "./../images/lines.png";

export default function Social_Elderly() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Elderly Care Services";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/elderly-care-services"
            ? "0rem"
            : ""  }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Elderly Care Services </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                  {window.location.pathname == "/mobile/elderly-care-services" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Social Care</li>
                  <li>Elderly Care Services </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">
                    Elderly Care Services{" "}
                  </h2>
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div className="col-md-12 col-lg-6 m-b10">
                  <p className="spacebar mt-3"></p>

                  <h6>
                    Delivering Excellence in General Elderly Care Services
                  </h6>

                  <p className="text-left">
                    We understand how important it is in an elderly care setting
                    to have a team of compassionate and dedicated people
                    providing a high support standard.
                  </p>

                  <p className="text-left">
                    Our core focus is on maintaining those high standards in
                    order to ensure consistency of care for residents and
                    service users. Our professional team of carers and nurses is
                    carefully selected and highly trained to provide support,
                    comfort, and companionship to older people, including those
                    with dementia.
                  </p>

                  <p className="text-left">
                    We work in partnership with you to really get to know you
                    and your service and to ensure that we are supplying people
                    that will fit in and interact effectively with both your
                    teams and your residents.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
