import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Special-education-banner.jpg";
// import HomecareImg from "./../images/ourservices/Special-education.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/education/edu-special/our-services-edu-special-education.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/education/edu-special/our-services-edu-special-education-01.jpg";
import Footer from "../components/Layout/Footer";
import bnr3 from "./../images/lines.png";

function EducationSpecial() {
  // Add title
  document.getElementById("titleText").innerText =
    "Staffing Connect - Our Services - Sepcial Education";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{
            backgroundImage: `url(${Background})`,
            height: 580,
            marginTop:
              window.location.pathname == "/mobile/special-education"
                ? "0rem"
                : "",
          }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">
                <b style={{ display: "none" }}>
                  Staffing Connect - Our Services -{" "}
                </b>
                Special Education
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/special-education" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Education</li>
                  <li>Special Education</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Special Education</h2>
                  {/* <p>Why, hello there! Want to see more of our work? Check out just some of what we've been up to below.</p> */}
                </div>
              </div>

              <div className="row ">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div className="col-md-12 col-lg-6 ">
                  {/* <h2 className="m-b5"> Home Care</h2> */}
                  <p className="spacebar mt-2"></p>
                  <h5>
                    Empowering Special Education with Staffing Connects
                    Exceptional Support
                  </h5>

                  <p className="text-left">
                    At Staffing Connect, we are dedicated to crafting a superior
                    support structure for young individuals who access special
                    needs services. Our seasoned, passionate team works
                    tirelessly, providing full-time assistance that ensures
                    these individuals navigate their educational journey
                    successfully.
                  </p>
                  <p className="text-left">
                    Our recruitment process in the special education sector is
                    meticulously curated, hinged on utmost patience, keen
                    understanding, and an unerring focus on skills. We uphold
                    the core principle of prioritizing pupils in every decision,
                    realizing that their best interests are integral to our
                    success.
                  </p>
                  <p className="text-left">
                    Our team of Special Education Needs (SEN) recruitment
                    consultants are not just experts; they are lifelong
                    learners. They participate in many SEN-specific training
                    sessions to equip them with the skills, expertise, and
                    insights to make optimal recruitment decisions for your
                    pupils.
                  </p>
                </div>

                <div className="row ">
                  <ul
                    className="col-md-12 col-lg-12 "
                    style={{ marginBottom: "-1%" }}
                  >
                    <h6 className="text-left">
                      We pride ourselves on our unique approach to supporting
                      special needs schools. Our commitment spans several key
                      areas, including:
                    </h6>
                    <p className="m-l20">
                      <li className="m-l20">
                        Innovative staff recruitment strategies
                      </li>
                      <li className="m-l20">
                        Striving for remarkable pupil outcomes
                      </li>
                      <li className="m-l20">
                        Managing inclusion with empathy and understanding
                      </li>
                      <li className="m-l20">
                        Ensuring stringent pupil safeguarding measures
                      </li>
                      <li className="m-l20">
                        Prioritizing health & care in all circumstances
                      </li>
                    </p>
                  </ul>

                  <ul
                    className="col-md-12 col-lg-12 "
                    style={{ marginBottom: "-1%" }}
                  >
                    <h6 className="text-left">
                      We cater to an extensive array of staffing needs, whether
                      they are:
                    </h6>
                    <p className="m-l20">
                      <li className="m-l20">
                        Short-term supply cover for unexpected staff absence
                      </li>
                      <li className="m-l20">
                        Longer-term temporary positions to support curriculum
                        demands.
                      </li>
                      <li className="m-l20">
                        Pupil interventions for specific learning needs
                      </li>
                      <li className="m-l20">
                        SEN support to ensure all pupils reach their potential.
                      </li>
                      <li className="m-l20">
                        Assistance for looked-after children for their holistic
                        development.
                      </li>
                      <li className="m-l20">
                        Permanent roles that offer stability and long-term
                        impact
                      </li>
                    </p>
                  </ul>
                </div>
                <p className="text-left">
                  The individuals who make it through our stringent selection
                  process are truly exceptional. They bring a wealth of
                  experience, knowledge, and qualifications that meet and often
                  exceed Staffing connects high standards. These individuals are
                  well-equipped to cater to the diverse needs of young people in
                  our care. Our staff roster boasts of professionals with rich
                  backgrounds in residential social care, youth work, and
                  education.{" "}
                </p>
              </div>
            </div>
          </div>

          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
export default EducationSpecial;
