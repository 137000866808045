import React from "react";
import { Link } from "react-router-dom";
import img1 from "./../../images/city/Hounslow-01.jpg";
import img2 from "./../../images/city/Heathrow.jpg";
import img3 from "./../../images/city/Ealing-01.jpg";
import img4 from "./../../images/city/Kew_Bridge-01.jpg";
import img5 from "./../../images/city/Acton-01.jpg";
import img6 from "./../../images/city/Slough.jpg";
import img7 from "./../../images/city/Teddington-01.jpg";
import img8 from "./../../images/city/Wembley-01.jpg";

// var img1 = require('./../../images/city/Hounslow-01.jpg');
// var img2 = require('./../../images/city/Croydon-01.jpg');
// var img3 = require('./../../images/city/Ealing-01.jpg');
// var img4 = require('./../../images/city/Kew_Bridge-01.jpg');
// var img5 = require('./../../images/city/Acton-01.jpg');
// var img6 = require('./../../images/city/Kingston-01.jpg');
// var img7 = require('./../../images/city/Teddington-01.jpg');
// var img8 = require('./../../images/city/Wembley-01.jpg');

function Featureblog() {
  return (
    <div className="section-full content-inner bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 section-head text-center">
            <h2 className="m-b5">Featured Towns</h2>
            <h6 className="fw4 m-b0">
              100+ of Temporary Assignments on daily basis
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
            <Link to={""}>
              <div
                className="city-bx align-items-end d-flex"
                style={{ backgroundImage: "url(" + img1 + ")" }}
              >
                <div className="city-info">
                  <h5>Hounslow</h5>
                  <span>1765 Jobs</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
            <Link to={""}>
              <div
                className="city-bx align-items-end  d-flex"
                style={{ backgroundImage: "url(" + img2 + ")" }}
              >
                <div className="city-info">
                  <h5>Heathrow</h5>
                  <span>352 Jobs</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
            <Link to={""}>
              <div
                className="city-bx align-items-end  d-flex"
                style={{ backgroundImage: "url(" + img3 + ")" }}
              >
                <div className="city-info">
                  <h5>Ealing</h5>
                  <span>893 Jobs</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
            <Link to={""}>
              <div
                className="city-bx align-items-end  d-flex"
                style={{ backgroundImage: "url(" + img4 + ")" }}
              >
                <div className="city-info">
                  <h5>Kew Bridge</h5>
                  <span>502 Jobs</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
            <Link to={""}>
              <div
                className="city-bx align-items-end  d-flex"
                style={{ backgroundImage: "url(" + img5 + ")" }}
              >
                <div className="city-info">
                  <h5>Acton</h5>
                  <span>765 Jobs</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
            <Link to={""}>
              <div
                className="city-bx align-items-end  d-flex"
                style={{ backgroundImage: "url(" + img6 + ")" }}
              >
                <div className="city-info">
                  <h5>Slough</h5>
                  <span>352 Jobs</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
            <Link to={""}>
              <div
                className="city-bx align-items-end  d-flex"
                style={{ backgroundImage: "url(" + img7 + ")" }}
              >
                <div className="city-info">
                  <h5>Teddington</h5>
                  <span>893 Jobs</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-6 m-b30">
            <Link to={""}>
              <div
                className="city-bx align-items-end  d-flex"
                style={{ backgroundImage: "url(" + img8 + ")" }}
              >
                <div className="city-info">
                  <h5>Wembley</h5>
                  <span>502 Jobs</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Featureblog;
