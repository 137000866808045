import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/daycentres-banners.jpg";
// import HomecareImg from "./../images/ourservices/daycentres.jpg";
// Newly Added Image
import Background from "./../images/new-updated-images/our-services/social-care/learrning-disability-day-centres/our-services-social-care-learning-disability-day-centers.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/social-care/learrning-disability-day-centres/our-services-social-care-learning-disability-day-centers-01.jpg";

import bnr3 from "./../images/lines.png";

export default function Home_Daycentres() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Learning Disability Day Centers";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/learning-disability-day-centres"
            ? "0rem"
            : "" }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Learning Disability Day Centers </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                <li>
                {window.location.pathname == "/mobile/learning-disability-day-centres" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Social Care</li>
                  <li>Learning Disability Day Centers </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">
                    Learning Disability Day Centers{" "}
                  </h2>
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                  <br />
                </div>
                <div className="col-md-12 col-lg-6 m-b10">
                <p className="spacebar mt-3"></p>
                    <h6>
                      Delivering Excellence in Social Care Staffing with
                      Staffing Connect
                    </h6>

                  <p className="text-left">
                    In the Social Care industry, where sensitivity is paramount,
                    Staffing Connect is committed to delivering the highest
                    quality staff to our esteemed clients. In the Social Care
                    industry, where sensitivity is paramount, Staffing Connect
                    is committed to delivering the highest quality staff to our
                    esteemed clients. As a trusted source for top care
                    assistants and experienced support workers, we take pride in
                    our meticulous recruitment process, specifically designed to
                    meet the unique needs of individuals with various learning
                    disabilities. Our comprehensive expertise covers a range of
                    learning disabilities, including autism, dyslexia, ADHD, and
                    more. With a focus on diligence and care, we ensure that our
                    candidates possess the necessary qualifications, experience,
                    and understanding to provide exceptional support and care
                    for individuals with diverse learning needs.
                  </p>

                  

                </div>
              </div>
              <p className="spacebar " style={{marginBottom:"-5%"}}></p>
              <p className="text-left">
                    With years of experience in similar care settings, our
                    dedicated professionals possess the skills and expertise
                    necessary to excel in Social Care. We understand the
                    industry's unique demands and the importance of delivering
                    compassionate and reliable care. Through our established
                    network, we connect clients with candidate’s adept at
                    providing exceptional support tailored to individual needs.
                  </p>
                  <p className="text-left">
                    At Staffing Connect, we uphold rigorous standards to ensure
                    excellence in our candidate selection. We conduct thorough
                    interviews and meticulously verify qualifications,
                    thoroughly assessing each candidate's aptitude for the role.
                    We prioritize empathy, compassion, and a genuine commitment
                    to positively impacting the lives of those in their care.
                  </p>

                  <p className="text-left">
                    Our comprehensive screening process includes rigorous
                    background checks, including DBS screenings and reference
                    checks, guaranteeing the integrity and professionalism of
                    our candidates. We recognized that trust is paramount in the
                    Social Care industry and only recommend candidates who meet
                    our stringent criteria.
                  </p>

                  <p className="text-left">
                    By partnering with Staffing Connect, you can be confident in
                    our ability to deliver outstanding staff who uphold the
                    highest standards of professionalism and dedication. Whether
                    you require care assistants for elderly care, support
                    workers for individuals with learning disabilities, or
                    expertise in other specialized areas, our team is equipped
                    to meet your unique staffing needs.
                  </p>

                  <p className="text-left">
                    We understand that every client and care setting is unique,
                    and our personalized approach ensures that we provide
                    tailored solutions that align with your requirements. With
                    Staffing Connect, you can trust that our commitment to
                    excellence will exceed your expectations and contribute to
                    the well-being of those needing social care services.
                  </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
