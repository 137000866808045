import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest, emptyAction} from '../redux/actions/CommonActions'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const BranchContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token){
        dispatch(emptyAction("SET_BRANCH"));
        (params.id) && 
          dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetBranch','SET_BRANCH',params.id))
      }
    },[props.userAuth.users_access_token,params.id])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData,
    branch:state.branch.branch
  };
};

const composedBranchContainer = compose(connect(mapStateToProps), BranchContainer)
export default composedBranchContainer;

