import React, { useState } from 'react';

const StarRating = ({ totalStars, initialRating, onChange }) => {
  const [rating, setRating] = useState(initialRating);

  const handleStarClick = (starIdx) => {
    setRating(starIdx + 1);
    if (onChange) {
      onChange(starIdx + 1);
    }
  };

  return (
    <div>
      {[...Array(totalStars)].map((_, idx) => (
        <span
          key={idx}
          onClick={() => handleStarClick(idx)}
          style={{
            cursor: 'pointer',
            color: idx < rating ? 'green' : 'gray',
            fontSize: '50px',
          }}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default StarRating;
