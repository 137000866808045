import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { redirectUrl } from "./GlobalUrls";

function GlobalViewFileModal({
  modalOption,
  closeFileModal,
  fileUrl,
  loaderEnd,
  modalHeight,
  callModal,
  title,
  fileName,
}) {
  // document.getElementById("loader").style.display = "block";
  // console.log("fileurl", fileUrl);
  const [fileLoad, setFileLoad] = useState(false);
  const [fileNameInModal, setFileNameInModal] = useState("");

  useEffect(() => {
    setFileNameInModal(fileName);
  }, [fileName]);

  const handlePdfLoad = () => {
    console.log("onload called");
    loaderEnd();
    setFileLoad(true);
  };

  const handlePdfError = () => {
    console.log("handle errror");
    loaderEnd();
    closeFileModal(true);
    setFileLoad(false);
  };

  return (
    <>
      {(fileUrl.endsWith(".pdf") || fileUrl.endsWith(".mkv") || fileUrl.endsWith(".mp4")) ? (
        <iframe
          src={fileUrl}
          onLoad={() => callModal()}
          allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
              allowFullScreen            
          style={{
            width: "100%",
            height: "500px",
            display: "none",
            padding: 0
          }}
        ></iframe>
      ) : (
        <center>
          <img
            src={fileUrl}
            onLoad={() => callModal()}
            style={{
              height: "auto",
              display: "none",
            }}
          ></img>
        </center>
      )}

      <Modal
        show={modalOption}
        onHide={() => closeFileModal(false)}
        size="lg"
        className="col-sm-12 fade"
      >
        <div className="modal-header bg-warning">
          <h5
            className="modal-title text-left pl-0"
            id="ResumeheadlineModalLongTitle"
          >
            {/* Document Viewer */}
            {fileNameInModal ? (title ? title + " - " + fileNameInModal : fileNameInModal) : title}
          </h5>

          <div className="pull-right">
          {!fileUrl.endsWith(".pdf") && !fileUrl.endsWith(".mkv") && !fileUrl.endsWith(".mp4") ? (
            <a className="btn btn-primary btn-sm text-white m-2" onClick={() => redirectUrl(fileUrl)}>
              <i className="fa fa-download"></i>
            </a>
          ) : (
            ""
          )}
          <a
            className="btn btn-danger btn-sm text-white m-2"
            onClick={() => closeFileModal(false)}
            style={{ cursor: "pointer" }}
          >
            
            <b>
              <i className="fa fa-close"></i>
            </b>
          </a>
          </div>
        </div>
        <div className="modal-body bg-dark p-0" style={{ height: modalHeight }}>
          {(fileUrl.endsWith(".pdf") || fileUrl.endsWith(".mkv") || fileUrl.endsWith(".mp4")) ? (
            <iframe
              src={fileUrl}
              onLoad={handlePdfLoad}
              onError={handlePdfError}
              onLoadedData={handlePdfLoad}
              allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
              allowFullScreen
              style={{
                width: "100%",
                height: "500px",
              }}
            ></iframe>
          ) : (
            <center>
              <img
                src={fileUrl}
                onLoad={() => loaderEnd()}
                style={{
                  height: "auto",
                }}
              ></img>
            </center>
          )}
        </div>
      </Modal>
    </>
  );
}

export default GlobalViewFileModal;
