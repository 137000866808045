import { main } from "react-devtools";
import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_care_physical_height",
    type: "numeric",
    label: "Heigth",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_physical_weight",
    type: "numeric",
    label: "Wieght",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_physical_eyes",
    type: "text",
    label: "Eyes",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_physical_hair",
    type: "text",
    label: "Hair",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_physical_any_sibiling",
    type: "textarea",
    label: "Siblings",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_physical_pets",
    type: "text",
    label: "Do you have a Pets?",
    md: "6",
    sm: "12",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  clt_care_physical_height: Yup.number()
    .typeError("Enter Valid Height")
    .required("Height Field Required")
    .min(1, "Please Enter Valid Number"),
  clt_care_physical_weight: Yup.number()
    .typeError("Enter Valid Weight")
    .required("Weight Field Required")
    .min(1, "Please Enter Valid Number"),
  clt_care_physical_eyes: Yup.string().required("Eyes Field Required"),
  clt_care_physical_hair: Yup.string().required("Hair Field Required"),
  clt_care_physical_any_sibiling: Yup.string().required(
    "Sibling Field Required"
  ),
  clt_care_physical_pets: Yup.string().required("Pet Field Required"),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
