import React from "react";
import { Modal } from "react-bootstrap";

export const validateFileSizeInMb = (selectedFile, mbSize) => {
  if (selectedFile) {
    let fileSizeInMB = (selectedFile.size / (1024 * 1024)).toFixed(2);
    if (fileSizeInMB <= mbSize) {
      return true;
    } else {
      return false;
    }
  }
};

//Function to check if a file is a document or not with url as param
export const checkIfFileIsaDocument = (url) => {
  if (
    url.endsWith(".png") ||
    url.endsWith(".jpg") ||
    url.endsWith(".jpeg") ||
    url.endsWith(".pdf") ||
    url.endsWith(".mkv") ||
    url.endsWith(".mp4")
  ) {
    return true;
  } else {
    return false;
  }
};

// Get File Size In MB By ID Start
export const getFileSizeInMbByID = (selectedFileID) => {
  const fileInput = document.getElementById(selectedFileID);
  if (fileInput && fileInput.files.length > 0) {
    const selectedFile = fileInput.files[0];
    const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to MB
    return fileSizeInMB.toFixed(2);
  }

  return null;
};
// Get File Size In MB By ID End

export const viewFilesExtension = {
  pdf: "pdf",
  jpeg: "jpeg",
  jpg: "jpg",
  png: "png",
  gif: "gif",
  bmp: "bmp",
  mp4: "mp4",
  mkv: "mkv"
};

export const checkViewFileExtension = (fileName) => {
  let splitText = fileName.split(".");
  let findExtension = viewFilesExtension[splitText[1]]
    ? viewFilesExtension[splitText[1]]
    : "";
  return findExtension;
};

export const viewFileModal = (fileUrl, fileName) => {
  let fileExtesion = checkViewFileExtension(fileName);

  return (
    <Modal
      show={true}
      // onHide={() => closeFileModal(false)}
      size="lg"
      className="col-sm-12 fade"
    >
      <div className="modal-header bg-warning">
        <h5
          className="modal-title text-left pl-0"
          id="ResumeheadlineModalLongTitle"
        >
          Document Viewer
        </h5>
        <a
          className="text-white pull-right"
          // onClick={() => closeFileModal(false)}
          style={{ cursor: "pointer", fontSize: "20px" }}
        >
          <b>
            <i className="fa fa-close"></i>
          </b>
        </a>
      </div>
    </Modal>
  );
};
