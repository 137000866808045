import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Childrens-residential-banner.jpg";
// import HomecareImg from "./../images/ourservices/Childrens-residential.jpg";
// Newy Added Images
import Background from "./../images/new-updated-images/our-services/social-care/children-residential/our-services-social-care-children-residential.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/social-care/children-residential/our-services-social-care-children-residential-01.jpg";
import bnr3 from "./../images/lines.png";

export default function Social_Residential() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Our Services - Children's Residential";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/childrens-residential"
            ? "0rem"
            : "" }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Children's Residential </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                  {window.location.pathname == "/mobile/childrens-residential" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Social Care</li>
                  <li>Children's Residential </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Children's Residential </h2>
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div className="col-md-12 col-lg-6 m-b10">
                  <p className="spacebar mt-3"></p>
                  <h6>
                    Specialized Staffing Solutions for the Social Care Industry
                    with Staffing Connect
                  </h6>

                  <p className="text-left">
                    At Staffing Connect, we understand the unique needs of the
                    Social Care industry and offer a specialized recruitment
                    service tailored to this sector. Our commitment to honesty,
                    professionalism, and personalized staffing solutions sets us
                    apart. We cater to various client groups, including elderly
                    care, dementia, learning disabilities, physical
                    disabilities, mental health, and young people. With a focus
                    on West London, we provide temporary staffing solutions for
                    a range of residential settings, ensuring all our staff are
                    fully compliant and possess at least one year of experience
                    in their respective fields.
                  </p>
                </div>
              </div>
              <p className="spacebar " style={{ marginBottom: "-5%" }}></p>
              <p className="text-left">
                At Staffing Connect, we understand the unique needs of the
                Social Care industry, specifically in children's residential
                settings. Our specialized recruitment service is tailored to
                meet these specific requirements. We focus on providing staffing
                solutions for a variety of children's residential settings,
                including residential homes, therapeutic communities, and
                supported living accommodations. Our dedicated team caters to
                children with learning disabilities, physical disabilities,
                mental health needs, and young people needing specialized care.
                We offer comprehensive temporary staffing options. Our highly
                qualified and experienced staff members are fully compliant,
                extensively vetted, and possess at least one year of experience
                working with children in residential care. By partnering with
                Staffing Connect, you can ensure the provision of exceptional
                care within a safe and nurturing environment for vulnerable
                children.
              </p>
              <p className="text-left">
                Our aim is to provide you with skilled and dedicated
                professionals who can deliver high-quality care and support to
                individuals across different age groups and care requirements.
                Our comprehensive recruitment process ensures that we source
                candidates well-suited to the social care industry's specific
                demands. We thoroughly screen and assess each candidate,
                considering their experience, qualifications, and ability to
                work effectively in various care settings.
              </p>

              <p className="text-left">
                By partnering with Staffing Connect, you gain access to a pool
                of qualified and experienced staff passionate about making a
                positive impact in the lives of vulnerable individuals. Whether
                you require temporary cover to manage staffing gaps or ongoing
                support to meet the demands of your care facility, we have the
                resources and expertise to fulfil your staffing needs.
              </p>

              <p className="text-left">
                We prioritize compliance and understand the importance of
                maintaining a safe and regulated care environment. All our staff
                undergo rigorous background checks, including DBS screenings, to
                ensure they meet the highest standards of professionalism and
                safeguarding. You can trust that the professionals we provide
                are well-prepared to handle the unique challenges and
                responsibilities associated with Social Care.
              </p>

              <p className="text-left">
                Staffing Connect takes pride in delivering tailored staffing
                solutions that align with the specific requirements of the
                Social Care industry. Our dedicated team is committed to
                building strong partnerships with care facilities and helping
                them maintain the highest standards of care provision.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
