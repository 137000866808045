import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, Route } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import { Modal } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { checkIfFileIsaDocument } from "../../services/FileOperationGlobalFunctions";
import { redirectUrl } from "../../services/GlobalUrls";
import GlobalViewFileModal from "../../services/GlobalViewFileModal";
import { getInitialsLettersOfString } from "../../services/GlobalStringOp";
// Navbar Bottom - Start
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { capitalizeFirstLetter, convertToOriginalForm } from "../../services/StringOperation";
// Navbar Bottom - End
const AccountCentreSidebar = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  let userType = localStorage.getItem("user_job_type");
  const { progress, userData, dbsDocuments } = props;
  //   Navbar Bottoms - Start
  const [isScrolling, setIsScrolling] = useState(false);
  const [value, setValue] = useState("");
  const ref = useRef(null);
  const [touchMove, setTouchMove] = useState("false");
  // Navbar Bottoms - End
  // Modal - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // Modal - End
  const [formStates, setFormStates] = useState({
    userProfile: false,
    employmentReferences: false,
    typesOfServices: false,
    agreements: false,
    bankDetails: false,
    rightToWork: false,
    workPreferences: false,
    documentsCompliance: false,
    dbsCertificate: false,
    identityDocument: false,
  });
  const [progresStatus, setProgress] = useState(0);
  useEffect(() => {
    setFormStates({
      userProfile: progress.progress_bar_profile_1 ? true : false,
      employmentReferences: progress.progress_bar_reference_2 ? true : false,
      typesOfServices: userData.cnd_types_of_services !== "" ? true : false,
      agreements: progress.progress_bar_agreement_3 ? true : false,
      bankDetails: progress.progress_bar_bank_4 ? true : false,
      rightToWork: progress.progress_bar_right_to_work_5 ? true : false,
      workPreferences: progress.progress_bar_work_preference_6 ? true : false,
      documentsCompliance: progress.progress_bar_compliance_7 ? true : false,
      dbsCertificate: progress.progress_bar_dbs_8 ? true : false,
      identityDocument: progress.progress_bar_identity_9 ? true : false,
    });
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 10;
    }

    if (progress.progress_bar_reference_2 == "1") {
      totalProgress = totalProgress + 10;
    }
    // if(progress.cnd_types_of_services !== ""){
    //   totalProgress = totalProgress+10
    // }
    if (progress.progress_bar_agreement_3 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_bank_4 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_right_to_work_5 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_work_preference_6 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_compliance_7 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_dbs_8 == "1") {
      totalProgress = totalProgress + 15;
    }
    if (progress.progress_bar_identity_9 == "1") {
      totalProgress = totalProgress + 15;
    }

    setProgress(totalProgress);
  }, [progress, userData]);
  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    users_category,
    users_access_token,
    users_profile_pic,
    users_profile_img_path,
    user_sign_signature,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "135px";
      imageRef.current.style.height = "135px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };

  const scrollNavTab = (page) => {
    const element = document.getElementById(page);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
    if (page) {
      scrollNavTab(page);
    }
  }, [props.userData, page]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };

  const handleLink = (e, link) => {
    setShow(true);
  };
  const handleClose = () => setShow(false);
  // sticky-top
  // Modal Code - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };
  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // Modal Code - End

  //   Navbar Menus - Start
  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      //   if (isScrolling == false) {
      scrollNavTab(page);
      // Clear the previous timeout if it's still running
      if (timeoutId) clearTimeout(timeoutId);

      // Set isScrolling to true when scroll event is detected
      setIsScrolling(true);

      // Set a timeout to change isScrolling to false after scrolling stops
      timeoutId = setTimeout(() => {
        // Check if the page is at the top or bottom
        const atTop = window.scrollY === 0;
        const atBottom =
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight;

        // if (!atTop && !atBottom) {
        //   setIsScrolling(false);
        // }
        setIsScrolling(touchMove == "true" ? true : false);
        // console.log("Touch Move:=> " + touchMove);
      }, 6000); // Adjust the timeout as needed
      //   }
    };

    window.addEventListener("scroll", handleScroll);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [touchMove]);
  //   Navbar Menus - End

  return (
    <>
      <div className="profile-modal">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Coming Soon!</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body></Modal.Body> */}
        </Modal>
      </div>
      <div className="col-xl-3 col-lg-4 m-b30">
        <div className="wrapper mb-0">
          <div className="candidate-info">
              <div className="candidate-detail text-center" style={{display: (page && page == "profile-and-cv" ? "none" : "block")}}>
              <div
                className={
                  "canditate-des " +
                  (users_profile_img_path && users_profile_pic ? "" : "bg-dark")
                }
              >
                {users_profile_img_path && users_profile_pic ? (
                  <img
                    className="size-thumbnail border border-default rounded-circle cursor-pointer"
                    width={"136px"}
                    height={"136px"}
                    ref={imageRef}
                    onClick={() => {
                      if (users_profile_img_path && users_profile_pic) {
                        viewFileModal(
                          constants.IMAGE_URL +
                            users_profile_img_path +
                            users_profile_pic
                        );
                      } else {
                        Swal.fire({
                          title: "Profile Picture Not Available",
                          text: "",
                          icon: "warning",
                          showCancelButton: false,
                          confirmButtonText: "Ok",
                          // confirmButtonColor: 'blue'
                        });
                      }
                    }}
                    src={require("./../../images/team/pic1.jpg")}
                  />
                ) : (
                  <div className="text-white" style={{ marginTop: "35%" }}>
                    <h1 style={{ fontSize: "60px" }}>
                      {getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) &&
                      getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) != "UU" ? (
                        getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        )
                      ) : (
                        <i
                          class="fa fa-spinner text-center fa-pulse text-white"
                          style={{ fontSize: "80px", marginTop: "-10%" }}
                        ></i>
                      )}
                    </h1>
                  </div>
                )}
                <div
                  className="upload-link border"
                  title="update"
                  data-toggle="tooltip"
                  data-placement="right"
                  style={{
                    marginTop:
                      users_profile_img_path && users_profile_pic
                        ? "50%"
                        : "70%",
                  }}
                >
                  <input
                    type="file"
                    className="update-flie"
                    id="users_profile_pic"
                    // onChange={(e) => onChangeInputvalue(e)}
                    accept=".png,.jpeg,.jpg"
                  />
                  <i className="fa fa-camera"></i>
                </div>
              </div>

              <div className="candidate-title">
                <div className="">
                  <h4 className="m-b5">
                    <a href={"#"}>{users_firstname}</a>
                  </h4>
                  <p className="m-b0">
                    <a href={"#"}>{props.userAuth.users_category ? capitalizeFirstLetter(props.userAuth.users_category) : ""}</a>
                  </p>
                </div>
              </div>
              </div>
            <div
              className=""
              onTouchStart={() => setTouchMove("true")}
              onTouchEnd={() => setTouchMove("false")}
            >
              {isScrolling == true && (
                <Box ref={ref}>
                  <Paper
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      overflowX: "auto",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      opacity: 1,
                      zIndex: 10,
                    }}
                    elevation={3}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        overflowX: "auto",
                      }}
                    >
                      <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onMouseEnter={() => setIsScrolling(true)}
                      >
                        <BottomNavigationAction
                          label={
                            String("Profile & CV").length <= 12
                              ? String("Profile & CV")
                              : String("Profile & CV").slice(0, 12) + "..."
                          }
                          id="profile-and-cv"
                          icon={
                            <>
                              <i
                                className="fa fa-user-circle"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "profile-and-cv" ? "bg-dark text-white" : ""
                          }
                          onClick={() => redirectUrl("/candidate-profile")}
                        />

                        <BottomNavigationAction
                          label={
                            String("Change Password").length <= 12
                              ? String("Change Password")
                              : String("Change Password").slice(0, 12) + "..."
                          }
                          id="change-password"
                          icon={
                            <>
                              <i
                                className="fa fa-unlock-alt"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "change-password"
                              ? "bg-dark text-white"
                              : ""
                          }
                          onClick={() => redirectUrl("/change-password")}
                        />

                        <BottomNavigationAction
                          label={
                            String("Account FAQs").length <= 12
                              ? String("Account FAQs")
                              : String("Account FAQs").slice(0, 12) + "..."
                          }
                          id="account-faq"
                          icon={
                            <>
                              <i
                                className="fa fa-info-circle"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "account-faq" ? "bg-dark text-white" : ""
                          }
                          onClick={() => redirectUrl("/account-faq")}
                        />

                        {page && page != "profile-and-cv" && (
                          <BottomNavigationAction
                            label={
                              String("Delete Account").length <= 12
                                ? String("Delete Account")
                                : String("Delete Account").slice(0, 12) + "..."
                            }
                            id="delete-account"
                            icon={
                              <>
                                <i
                                  className="fa fa-trash"
                                  style={{ fontSize: "15px", fontWeight: "500" }}
                                ></i>
                              </>
                            }
                            className={
                              page == "delete-account" ? "bg-dark text-white" : ""
                            }
                            onClick={() => redirectUrl("/delete-account")}
                          />
                        )}
                      </BottomNavigation>
                    </Box>
                  </Paper>
                </Box>
              )}
            </div>
          </div>
          <GlobalViewFileModal
            modalOption={modalOption}
            closeFileModal={closeFileModal}
            fileUrl={fileUrl}
            loaderEnd={loaderEnd}
            modalHeight={modalHeight}
            callModal={callModal}
            title={"Profile Picture"}
            // fileName={fileName}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(AccountCentreSidebar);
