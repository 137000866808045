import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Form, Col, Spinner, Button } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import Label from "../../Layout/Label";
import {
  formInputFields,
  validationSchema,
  formArrayInputFields,
} from "./FormHelper";
import { updateDataOnRequest } from "../../../redux/actions/CommonActions";
import LoadFormFields from "../../Layout/LoadFormFields";
import composedJob from "../../../containers/Job";
const JobsForm = (props) => {
  const [company, setFormdata] = useState({});
  const [jobQuestions, setJobQuestions] = useState([]);
  const { loader, job, userDetails } = props;
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "job_questions",
  });

  useEffect(() => {
    setFormdata(job);
    if (job.job_questions) {
      setJobQuestions(job.job_questions);
      setValue("job_questions", job.job_questions);
    }
    if (Object.keys(job).length > 0)
      Object.keys(job).map((key) => {
        setValue(key, job[key]);
      });

    // if (formArrayInputFields().length > 0)
    //   formArrayInputFields().map((field) => {
    //     if (jobQuestions[field.name]) setValue(field.name, jobQuestions[field.name]);
    //   });

    if (jobQuestions.length <= 0) {
      let defaultFormData = [{}];
      setValue("job_questions", defaultFormData);
      setJobQuestions(defaultFormData);
    }
  }, [job]);

  const onSubmit = (data) => {
    let formData = data;
    formData["users_access_token"] = userDetails.users_access_token;
    if (params.id) formData["clt_job_id"] = params.id;
    dispatch(
      updateDataOnRequest(JSON.stringify(formData, null, 2), "UpdateJobs", "")
    );
    history.push("/job");
  };

  const onChangeInputvalue = (e) => {
    // const { name, value } = e.target;
    // company[name] = value;
    // setFormdata(company);
  };

  const addMore = () => {
    let loadMoredata = [...jobQuestions, {}];
    setJobQuestions(loadMoredata);
  };

  const handleRemoveClick = (index) => {
    const list = [...jobQuestions];
    list.splice(index, 1);
    setJobQuestions(list);
    remove(index);
  };
  return (
    <React.Fragment>
      <div className="job-bx">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Job Details
          </h5>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LoadFormFields
            register={register}
            errors={errors}
            formInputFields={formInputFields()}
            onChangeInputvalue={(e) => onChangeInputvalue(e)}
            reset={reset}
            loadExternalButtons={true}
            data={job}
          />
          <hr />
          <h5>Questions</h5>
          {jobQuestions.map((jobQuestion, i) => (
            <React.Fragment key={i}>
              <LoadFormFields
                key={i}
                register={register}
                control={control}
                errors={errors.job_questions && errors.job_questions[i]}
                formInputFields={formArrayInputFields(i)}
                onChangeInputvalue={(e) => onChangeInputvalue(e)}
                reset={reset}
                addMoreButton={true}
                arrayForm={true}
                addMore={addMore}
                data={jobQuestion}
                loadExternalButtons={true}
              />
              {jobQuestions.length !== 1 && (
                <Link to="#" onClick={(e) => handleRemoveClick(i)}>
                  Delete Document
                </Link>
              )}
              <hr />
            </React.Fragment>
          ))}
          <Form.Group as={Col} md="12" sm="12">
            <Button
              disabled={loader ? "disabled" : ""}
              className="site-button m-b30"
              type="submit"
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Save"}
            </Button>
            <Button className="m-l5 btn-info m-b30" onClick={addMore}>
              Add More
            </Button>
            <button
              disabled={loader ? "disabled" : ""}
              className="m-b30 m-l5 btn btn-warning"
              onClick={() => reset()}
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Reset"}
            </button>
          </Form.Group>
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    job: state.job.job,
  };
};
export default connect(mapStateToProps)(composedJob(JobsForm));
