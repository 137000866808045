//index.js
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import composedRightToWorks from "../../../containers/RightToWorksContainer";
import RightToWork from "./RightToWorkList";
import TableGlobal from "../../../services/TableGlobal";
import RightToWorkList from "./RightToWorkList";
import RightToWorkListMobile from "./RightToWorkListMobile";
// Speed Dial - Start
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TaskAlt from "@mui/icons-material/TaskAlt";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { redirectUrl } from "../../../services/GlobalUrls";
import { Tooltip } from "@mui/material";

const actions = [
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
  { icon: <TaskAlt />, name: "Applied Jobs" },
];
// Speed Dial - End

const RightToWorkContainer = ({ deviceInfo, ...props }) => {
  const [rightToWorks, SetRightToWork] = useState([]);
  const [viewToolTip, setViewToolTip] = useState(false);

  useEffect(() => {
    SetRightToWork(props.rightToWorks);
    console.log("data", props.rightToWorks);
  }, [props.rightToWorks]);

  const tableHeadings = [
    "Date & Time",
    "Document Name",
    "Document(s) Uploaded",
    "Expiry Date",
    "Status",
  ];

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Right To Work
          </h5>
          {deviceInfo && deviceInfo == "Mobile" ? (
            <>
              <Tooltip
                title="Add Right To Work"
                className="text-sm"
                open={viewToolTip}
                placement="top"
              >
                <a
                  href="/create-right-to-work"
                  className="pull-right btn btn-success"
                  onTouchStart={(e) => {
                    setViewToolTip(true);
                  }}
                  onTouchEnd={(e) => {
                    setViewToolTip(false);
                  }}
                >
                  <i className="fa fa-plus"></i>
                </a>
              </Tooltip>

              {/* <Box
                    sx={{
                      position: "fixed", // Fixed position for the entire Box
                      bottom: 60,
                      right: 16,
                      height: "auto", // Allow height to adjust based on its content
                      transform: "translateZ(0px)",
                      zIndex: "10",
                      opacity: "1"
                    }}
                  >
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{ position: "relative" }} // Relative positioning within the fixed Box
                      icon={<i className="fa fa-plus"></i>}
                      onClick={() => redirectUrl('/create-right-to-work')}
                    >
                      
                    </SpeedDial>
                  </Box>                   */}
            </>
          ) : (
            <a
              href="/create-right-to-work"
              className="pull-right btn btn-secondry site-button yellow btn-sm"
            >
              Add Right To Work
            </a>
          )}
        </div>
        {/* <TableGlobal 
          data={rightToWorks}
          headings={tableHeadings}
          isDropdown={true}
          isPaggination={true}
        />  */}
        {deviceInfo && deviceInfo == "Mobile" ? (
          <RightToWorkListMobile />
        ) : (
          <RightToWorkList />
        )}
      </div>
    </React.Fragment>
  );
};

export default composedRightToWorks(RightToWorkContainer);
