import React from "react";
import { Link } from "react-router-dom";
// import Background from './../images/ourservices/Learning-disability-banner.jpg';
// import HomecareImg from './../images/ourservices/Learning-disability.jpg';
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/home-care/lerning-disabilities-support/our-services-home-care-learning-disability-care.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/home-care/lerning-disabilities-support/our-services-home-care-learning-disability-care-01.jpg";

import bnr3 from "./../images/lines.png";

export default function Home_Learningcare() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Our Services - Learning Disabilities Support";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/learning-disabilities-support"
            ? "0rem"
            : "" }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Learning Disabilities Support</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/learning-disabilities-support" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Home Care</li>

                  <li>Learning Disabilities Support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">
                    Learning Disabilities Support{" "}
                  </h2>
                </div>
              </div>

              <div className="row mb-10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div className="col-md-12 col-lg-6 m-b10">
                  <p className="spacebar mt-4"></p>
                  <h6>
                    Personalized Care for Unique Learning Disabilities at
                    Staffing Connect
                  </h6>

                  <p className="text-left">
                    At Staffing Connect, we provide care for individuals with
                    learning disabilities that are as unique as they are. We
                    firmly stand against the 'one size fits all' approach,
                    believing that every individual's needs are distinct and
                    should be treated as such.
                  </p>

                  <p className="text-left">
                    Our process initiates with a comprehensive dialogue
                    involving our customers and key stakeholders such as family
                    members and healthcare professionals. While our professional
                    expertise guides our advisory, we prioritize the personal
                    wishes of our customers, ensuring their needs always come
                    first.
                  </p>
                </div>
              </div>
              <h6 className="text-left mt-3">
                Customizable and Adaptive Care
              </h6>

              <p className="text-left" style={{ marginTop: "-1%" }}>
                Our care spectrum is broad and adaptable, from personal care to
                fostering independence. This could encompass many activities,
                from a shopping spree in town to social outings with friends to
                indulge in their favorite sport or hobby. The offerings depend
                on the customer's preferences and a thoughtful approach to the
                facilities available in the vicinity. We also provide a live-in
                care service. Please contact us to discuss the options available
                in your locality and how we can assist.
              </p>

              <p className="text-left">
                Our care workers are not only highly trained, but they also
                bring extensive practical experience across various learning
                disability areas. This includes managing challenging behavior’s,
                assisting those with autistic spectrum disorders, addressing
                complex health needs, supporting those with physical
                disabilities, and aiding sensory impairment. With our team, you
                can be confident that you or your loved ones are in capable,
                experienced, and compassionate hands.
              </p>

              <h6 className="text-left">Specialist Care</h6>

              <p className="text-left" style={{ marginTop: "-1%" }}>
                Our care workers are not only highly trained, but they also
                bring extensive practical experience across various learning
                disability areas. This includes managing challenging behavior’s,
                assisting those with autistic spectrum disorders, addressing
                complex health needs, supporting those with physical
                disabilities, and aiding sensory impairment. With our team, you
                can be confident that you or your loved ones are in capable,
                experienced, and compassionate hands.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
