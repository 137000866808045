import React, { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "./css/plugins.css";
import "./css/templete.css";
import "./css/skin/skin-1.css";
import "./plugins/slick/slick.min.css";
import "./plugins/slick/slick-theme.min.css";
import "./css/style.css";
import { setUserdataWhenUserLoggedIn } from "./redux/actions/AuthActions";
import { isAuthenticated } from "./redux/selectors/AuthSelectors";
import { getDataOnRequest } from "./redux/actions/CommonActions";
import Routers from "./routers";
import InstallButton from "./services/InstallButton";
const App = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    let localStorageUserAccessToken = JSON.parse(
      localStorage.getItem("loginUserDetails")
    );
    if (localStorageUserAccessToken != null) {
      dispatch(
        setUserdataWhenUserLoggedIn(
          localStorageUserAccessToken.access_token,
          history
        )
      );
      let urlType = "";
      if (props.userAuth.users_role_id == "candidate"){
        if (props.userAuth.job_type == "permanentrequirement"){
          urlType = "PersonalPermanentInfo";
        }else{
          urlType = "PersonInfo";
        }
        
      }else if (props.userAuth.users_role_id == "client"){
        urlType = "ClientProfileGet";
      }
        
      if (urlType)
        dispatch(
          getDataOnRequest(
            localStorageUserAccessToken.access_token,
            urlType,
            "SET_USER_DATA"
          )
        );
        dispatch(
          getDataOnRequest(
            localStorageUserAccessToken.access_token,
            'ProgressBar',
            "SET_PROGRESS"
          )
        );
        if(localStorageUserAccessToken.access_token)
          dispatch(getDataOnRequest(localStorageUserAccessToken.access_token,'GetDBSDocuments','SET_DBS_DOCUMENT'))
    }
    dispatch({ type: "SET_LOADER", payload: false });
  }, [props.userAuth.users_role_id]);


  return (
    <React.Fragment>
      <>
      <ToastContainer
        position="top-right"
        autoClose={1100000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routers />
      <InstallButton />
      </>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: isAuthenticated(state),
    userAuth: state.auth.authUserData,
    loader:state.loader.loader
  };
};
export default connect(mapStateToProps)(App);

//export default App;
