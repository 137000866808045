import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const IdentityDocumentsContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token && props.identityDocuments.length == 0)
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'SetIdentityDocuments','SET_IDENTITY_DOCUMENTS'))
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    identityDocuments:state.identityDocuments.identityDocuments,
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData
  };
};

const composedIdentityDocumentsContainer = compose(connect(mapStateToProps), IdentityDocumentsContainer)
export default composedIdentityDocumentsContainer;

