import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PublicRoute from "./PublicRouter";
import Login from "../pages/Loginpage";
import OtpVerification from "../pages/OtpVerification";
import ForgotPassword from "../pages/ForgotPassword";
import Register from "../pages/Register";
import Homepage from "../pages/Homepage";
import Aboutus from "../pages/Aboutus";
import Contact from "../pages/Contact";
import Privacy from "../pages/Privacy";
import Cookie from "../pages/Cookie";
import Error404 from "../pages/Error404";
// added sandip
import EducationNursery from "../pages/Education_nursery";
import EducationPrimary from "../pages/Education_primary";
import EducationSecondary from "../pages/Education_secondary";
import EducationSpecial from "../pages/Education_special";
import EducationFurther from "../pages/Education_further";

import HomecareCome from "../pages/Home_carecome";
import Home_LiveCare from "../pages/Home_LiveCare";
import Home_LearningCare from "../pages/Home_Learningcare";
import Home_Physicalcare from "../pages/Home_Physicalcare";
import Home_Reablementcare from "../pages/Home_Reablementcare";
import Home_Respitecare from "../pages/Home_Respitecare";

import Social_Residential from "../pages/Social_Residential";
import Social_Daycentres from "../pages/Home_Daycentres";
import Social_Respite from "../pages/Social_Respite";

import Industrial from "../pages/Industrial";
import Commercial from "../pages/Commercial";

import ClientRoutes from "./ClientRoutes";
import CandidateRoutes from "./CandidateRoutes";

// client routes
import ClientProfile from "../pages/ClientProfile";
import Job from "../pages/Jobs";
import Assignments from "../pages/Assignments";
import Branches from "../pages/Branches";
import Branch from "../pages/CreateBranch";

// customer Routes
import Jobprofile from "../pages/Jobprofile";
import BankDetails from "../pages/BankDetails";
import RightToWork from "../pages/RightToWork";
import CreateRightToWork from "../pages/CreateRightToWork";
import DocumentCompliance from "../pages/DocumentCompliance";
import IdentityDocument from "../pages/IdentityDocument";
import CreateIdentityDocument from "../pages/CreateIdentityDocument";
import WorkPrefences from "../pages/WorkPrefences";
import Availability from "../pages/Availability";
import DBSDocuments from "../pages/DBSDocuments";
import CreateDBS from "../pages/CreateDBS";
import EmployementRefrence from "../pages/EmployementRefrence";
import Typeofservices from "../pages/Typeofservices";
import Agreements from "../pages/Agreements";
import JobAssesment from "../pages/JobAssesment";
import CarePlan from "../pages/CarePlan";
import Placement from "../pages/Placements";
import ChargeRate from "../pages/ChargeRate";
import CarePlan2 from "../pages/Careplan2";
import IndividualRiskProfile from "../pages/IndividualRisk";
import AssesmentCategory from "../pages/AssesmentCategory";
import TobTemperory from "../pages/TobTemperory";
import TobPermanent from "../pages/TobPermanent";
import CreateJobs from "../pages/Job";

import CreateTimesheet from "../pages/CreateTimeSheet";
import TimeSheets from "../pages/TimeSheets";
import EmailVerification from "../pages/EmailVerification";
import MyAssignments from "../pages/MyAssignements";
import Dashboard from "../pages/Dashboard";
import JobBoard from "../pages/JobBoard";
import DocumentRequired from "../pages/DocumentRequired";
import CreateDocumentRequired from "../pages/CreateDocumentRequired";
import SignContract from "../pages/SignContract";
import ForgotOTP from "../pages/ForgotOTP";
import NewPasswordUpdate from "../pages/NewPasswordUpdate";
import PermanentJobprofile from "../pages/PermanentRequirement";
import TemporaryJobs from "../pages/TemporaryJobs";
import AppliedJob from "../pages/AppliedJobs";
import ClientDashboardContainer from "../pages/ClientDashboard";
import Social_Elderly from "../pages/Social_elderly";
import AdminApproval from "../pages/WaitForAdmin";
import ChangePassword from "../pages/ChangePassword";
import AccountCentre from "../pages/AccountCentre";
import DeleteAccount from "../pages/DeleteAccount";
import DeleteAccountOtp from "../pages/DeleteAccountOtp";
import CancelAssignments from "../components/Element/MyAssignments/CancelAssignments";
import DeleteApproval from "../pages/WaitForAccountDeletionProcess";
import DailyLogs from "../pages/DailyLogs";
import CreateDailyLogs from "../components/Element/DailyLogs/CreateDailyLogs";
import CreateDailyLog from "../pages/CreateDailyLogs";
import PermanentCandidateRoutes from "./PermanentCandidateRoutes";
import CommonCandidateRoute from "./CommonCandidateRoute";
import FltLicense from "../pages/FltLicense";
import DriverDetail from "../pages/DriverDetail";
import PermanentJobs from "../services/PermanentJobs";
import TempJobs from "../services/TempJobs";
import JobDetails from "../services/JobDetails";
import PermanentDashboardContainer from "../pages/PermanentCandidate/PermanentDashboardContainer";
import Permanentform from "../pages/PermanentCandidate/Permanentform";
import PermanentFormContainer from "../pages/PermanentCandidate/PermanentFormContainer";
import CandidateProfile from "../pages/My Profile/CandidateProfile";
import JobDetailsMain from "../services/JobDetailsMain";
import SwipeableTemporaryDrawer from "../pages/TestDrawer";
import IconMenu from "../services/ClickMenus";

const BasicRoutes = (props) => {
  let userType = localStorage.getItem("user_job_type");
  let userJobSector = localStorage.getItem("users_category");

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <PublicRoute
            path="/"
            header={true}
            component={Homepage}
            exact
            sensitive
          />
          <PublicRoute
            path="/home"
            header={true}
            component={Homepage}
            exact
            sensitive
          />
          <PublicRoute
            path="/register"
            header={true}
            component={Register}
            exact
            sensitive
            restricted={true}
          />
          <PublicRoute
            path="/login"
            header={true}
            component={Login}
            exact
            sensitive
            restricted={true}
          />
          <PublicRoute
            path="/otp-verification"
            header={true}
            component={OtpVerification}
            exact
            sensitive
            restricted={true}
          />
          <PublicRoute
            path="/forgot-password"
            header={true}
            component={ForgotPassword}
            exact
            sensitive
            restricted={true}
          />
          <PublicRoute
            path="/forgot-password-otp"
            header={true}
            component={ForgotOTP}
            exact
            sensitive
            restricted={true}
          />
          <PublicRoute
            path="/new-password"
            header={true}
            component={NewPasswordUpdate}
            exact
            sensitive
            restricted={true}
          />
          <PublicRoute
            path="/about-us"
            header={true}
            component={Aboutus}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/about-us"
            header={false}
            component={Aboutus}
            exact
            sensitive
          />
          <PublicRoute
            path="/contact-us"
            header={true}
            component={Contact}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/contact-us"
            header={false}
            component={Contact}
            exact
            sensitive
          />
          <PublicRoute
            path="/contact"
            header={true}
            component={Contact}
            exact
            sensitive
          />

          <PublicRoute
            path="/privacy-policy"
            header={true}
            component={Privacy}
            exact
            sensitive
          />
          <PublicRoute
            path="/cookie-policy"
            header={true}
            component={Cookie}
            exact
            sensitive
          />
          <PublicRoute
            path="/job-board"
            header={true}
            component={JobBoard}
            exact
            sensitive
          />
          {/* <PublicRoute
            path="/Permanent-Jobs"
            header={true}
            component={JobBoard}
            exact
            sensitive
          /> */}
          <PublicRoute
            path="/permanent-Jobs"
            header={true}
            component={PermanentJobs}
            exact
            sensitive
          />
          <PublicRoute
            path="/tem-Jobs"
            header={true}
            component={TempJobs}
            exact
            sensitive
          />
          <PublicRoute
            path="/all-jobs"
            header={true}
            component={JobBoard}
            exact
            sensitive
          />
          <PublicRoute
            path="/job-details/:id?"
            header={true}
            component={JobDetailsMain}
            exact
            sensitive
          />

          <PublicRoute
            path="/temporaryJobs"
            header={false}
            component={TemporaryJobs}
            exact
            sensitive
          />

          <PublicRoute
            path="/acount-deletion-proceed"
            header={true}
            component={DeleteApproval}
            exact
            sensitive
          />

          {/* Common Candidate Routes Start */}
          <CommonCandidateRoute
            path="/applied-jobs/:id?"
            header={true}
            component={AppliedJob}
          />
          <CandidateRoutes
            path="/jobs-applied/:id?"
            header={true}
            component={AppliedJob}
          />
          <CommonCandidateRoute
            path="/delete-account-otp-verification"
            header={true}
            component={DeleteAccountOtp}
          />
          <CommonCandidateRoute
            path="/change-password"
            header={true}
            component={ChangePassword}
          />
          <CommonCandidateRoute
            path="/account-faq"
            header={true}
            component={AccountCentre}
          />
          <CommonCandidateRoute
            path="/delete-account"
            header={true}
            component={DeleteAccount}
          />
          {/* Common Candidate Routes End */}

          {/* Temp Candidate Start */}

          <CandidateRoutes
            path="/cancel-assignments"
            header={true}
            component={MyAssignments}
          />

          <CommonCandidateRoute
            path="/candidate-profile"
            header={true}
            component={CandidateProfile}
          />

          <CandidateRoutes
            path="/job-profile"
            header={true}
            component={Jobprofile}
          />

          <CandidateRoutes
            path="/bank-details"
            header={true}
            component={BankDetails}
          />
          <CandidateRoutes
            path="/document-compliance"
            header={true}
            component={DocumentCompliance}
          />
          <CandidateRoutes
            path="/identity-document/:id?"
            header={true}
            component={IdentityDocument}
          />
          <CandidateRoutes
            path="/create-identity-document/:id?"
            header={true}
            component={CreateIdentityDocument}
          />
          <CandidateRoutes
            path="/sign-contract/:id?"
            header={true}
            component={SignContract}
          />
          <CandidateRoutes
            path="/work-prefrences"
            header={true}
            component={WorkPrefences}
          />
          <CandidateRoutes
            path="/availability"
            header={true}
            component={Availability}
          />
          <CandidateRoutes
            path="/dbs-document/:id?"
            header={true}
            component={DBSDocuments}
          />
          <CandidateRoutes
            path="/dbs-applied-document/:id?"
            header={true}
            component={DBSDocuments}
          />
          <CandidateRoutes
            path="/create-dbs-document/:id?"
            header={true}
            component={CreateDBS}
          />
          <CandidateRoutes
            path="/employment-and-professional-references"
            header={true}
            component={EmployementRefrence}
          />
          <CandidateRoutes
            path="/availability"
            header={true}
            component={Availability}
          />
          <CandidateRoutes
            path="/type-of-services"
            header={true}
            component={Typeofservices}
          />
          <CandidateRoutes
            path="/flt-license"
            header={true}
            component={FltLicense}
          />
          <CandidateRoutes
            path="/driver-details"
            header={true}
            component={DriverDetail}
          />
          <CandidateRoutes
            path="/agreements"
            header={true}
            component={Agreements}
          />
          {/* <CandidateRoutes path="/holiday" header={true} component={Holidays} />
          <CandidateRoutes
            path="/create-holiday/:id?"
            header={true}
            component={CreateHoliday}
          /> */}
          <CandidateRoutes
            path="/create-timesheet/:id?"
            header={true}
            component={CreateTimesheet}
          />
          <CandidateRoutes
            path="/timesheet/:id?"
            header={true}
            component={TimeSheets}
          />
          <CandidateRoutes
            path="/create-daily-logs/:id?"
            header={true}
            component={CreateDailyLog}
          />
          <CandidateRoutes
            path="/daily-logs/:id?"
            header={true}
            component={DailyLogs}
          />
          <CandidateRoutes
            path="/my-assignments/:id?"
            header={true}
            component={MyAssignments}
          />
          <CandidateRoutes
            path="/email-verification/:id?"
            header={true}
            component={EmailVerification}
          />
          <CandidateRoutes
            path="/dashboard"
            header={true}
            component={Dashboard}
          />
          <CandidateRoutes
            path="/create-right-to-work/:id?"
            header={true}
            component={CreateRightToWork}
          />
          <CandidateRoutes
            path="/right-to-work/:id?"
            header={true}
            component={RightToWork}
          />
          <CandidateRoutes
            path="/document-required"
            header={true}
            component={DocumentRequired}
          />
          <CandidateRoutes
            path="/create-document-national-insurance/:id?"
            header={true}
            component={CreateDocumentRequired}
          />
          <CandidateRoutes
            path="/create-document-address1/:id?"
            header={true}
            component={CreateDocumentRequired}
          />
          <CandidateRoutes
            path="/create-document-address2/:id?"
            header={true}
            component={CreateDocumentRequired}
          />
          <CandidateRoutes
            path="/create-document-training-certificate/:id?"
            header={true}
            component={CreateDocumentRequired}
          />
          <CandidateRoutes
            path="/create-document-additional/:id?"
            header={true}
            component={CreateDocumentRequired}
          />
          <CandidateRoutes
            path="/create-document-update-address/:id?"
            header={true}
            component={CreateDocumentRequired}
          />
          {/* Temp Candidate End */}

          {/* Permanent Candidate Start */}
          <PermanentCandidateRoutes
            path="/permanent-job-profile"
            header={true}
            component={PermanentJobprofile}
          />
          <PermanentCandidateRoutes
            path="/permanent-candidate-dashboard"
            header={true}
            component={PermanentDashboardContainer}
          />
          {/* <PermanentCandidateRoutes
            path="/applied-jobs/:id?"
            header={true}
            component={AppliedJob}
          /> */}
          {/* <PermanentCandidateRoutes
            path="/apply-more-jobs"
            header={true}
            component={PermanentFormContainer}
          /> */}
          {/* Permanent Candidate End */}

          {/* Client Started */}
          {/* Client Dashboard Start*/}
          <ClientRoutes
            path="/client-dashboard"
            header={true}
            component={ClientDashboardContainer}
          />
          {/* Client Dashboard Stop */}
          <ClientRoutes
            path="/company-details"
            header={true}
            component={ClientProfile}
          />
          <ClientRoutes path="/job" header={true} component={Job} />
          <ClientRoutes
            path="/assignments"
            header={true}
            component={Assignments}
          />
          <ClientRoutes
            path="/job-assesments"
            header={true}
            component={JobAssesment}
          />
          <ClientRoutes path="/care-plan" header={true} component={CarePlan} />
          <ClientRoutes path="/placement" header={true} component={Placement} />
          <ClientRoutes
            path="/charge-rate"
            header={true}
            component={ChargeRate}
          />
          <ClientRoutes
            path="/care-plan-next"
            header={true}
            component={CarePlan2}
          />
          <ClientRoutes
            path="/individual-risk-profile"
            header={true}
            component={IndividualRiskProfile}
          />
          <ClientRoutes
            path="/assesment-categories"
            header={true}
            component={AssesmentCategory}
          />
          <ClientRoutes
            path="/tob-temp"
            header={true}
            component={TobTemperory}
          />
          <ClientRoutes
            path="/tob-permanent"
            header={true}
            component={TobPermanent}
          />
          <ClientRoutes path="/branch" header={true} component={Branches} />
          <ClientRoutes
            path="/create-branch/:id?"
            header={true}
            component={Branch}
          />
          <ClientRoutes
            path="/create-job/:id?"
            header={true}
            component={CreateJobs}
          />
          <PublicRoute
            path="/nursery"
            header={true}
            component={EducationNursery}
          />
          <PublicRoute
            path="/test-drawer"
            header={false}
            component={IconMenu}
          />

          <PublicRoute
            path="/mobile/nursery"
            header={false}
            component={EducationNursery}
          />
          <PublicRoute
            path="/primary"
            header={true}
            component={EducationPrimary}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/primary"
            header={false}
            component={EducationPrimary}
            exact
            sensitive
          />
          <PublicRoute
            path="/secondary"
            header={true}
            component={EducationSecondary}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/secondary"
            header={false}
            component={EducationSecondary}
            exact
            sensitive
          />
          <PublicRoute
            path="/special-education"
            header={true}
            component={EducationSpecial}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/special-education"
            header={false}
            component={EducationSpecial}
            exact
            sensitive
          />
          <PublicRoute
            path="/further-education"
            header={true}
            component={EducationFurther}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/further-education"
            header={false}
            component={EducationFurther}
            exact
            sensitive
          />

          <PublicRoute
            path="/care-in-your-home"
            header={true}
            component={HomecareCome}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/care-in-your-home"
            header={false}
            component={HomecareCome}
            exact
            sensitive
          />
          <PublicRoute
            path="/permanent-Jobs"
            header={false}
            component={PermanentJobs}
            exact
            sensitive
          />
          <PublicRoute
            path="/temporaryJobs"
            header={false}
            component={TemporaryJobs}
            exact
            sensitive
          />
          <PublicRoute
            path="/live-in-care"
            header={true}
            component={Home_LiveCare}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/live-in-care"
            header={false}
            component={Home_LiveCare}
            exact
            sensitive
          />
          <PublicRoute
            path="/learning-disabilities-support"
            header={true}
            component={Home_LearningCare}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/learning-disabilities-support"
            header={false}
            component={Home_LearningCare}
            exact
            sensitive
          />
          <PublicRoute
            path="/physical-disabilities-support"
            header={true}
            component={Home_Physicalcare}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/physical-disabilities-support"
            header={false}
            component={Home_Physicalcare}
            exact
            sensitive
          />
          <PublicRoute
            path="/reablement-services"
            header={true}
            component={Home_Reablementcare}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/reablement-services"
            header={false}
            component={Home_Reablementcare}
            exact
            sensitive
          />
          <PublicRoute
            path="/respite-care"
            header={true}
            component={Home_Respitecare}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/respite-care"
            header={false}
            component={Home_Respitecare}
            exact
            sensitive
          />

          <PublicRoute
            path="/childrens-residential"
            header={true}
            component={Social_Residential}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/childrens-residential"
            header={false}
            component={Social_Residential}
            exact
            sensitive
          />

          <PublicRoute
            path="/learning-disability-day-centres"
            header={true}
            component={Social_Daycentres}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/learning-disability-day-centres"
            header={false}
            component={Social_Daycentres}
            exact
            sensitive
          />
          <PublicRoute
            path="/childrens-learning-disability-respites"
            header={true}
            component={Social_Respite}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/childrens-learning-disability-respites"
            header={false}
            component={Social_Respite}
            exact
            sensitive
          />

          <PublicRoute
            path="/elderly-care-services"
            header={true}
            component={Social_Elderly}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/elderly-care-services"
            header={false}
            component={Social_Elderly}
            exact
            sensitive
          />

          <PublicRoute
            path="/industrial"
            header={true}
            component={Industrial}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/industrial"
            header={false}
            component={Industrial}
            exact
            sensitive
          />

          <PublicRoute
            path="/commercial"
            header={true}
            component={Commercial}
            exact
            sensitive
          />
          <PublicRoute
            path="/mobile/commercial"
            header={false}
            component={Commercial}
            exact
            sensitive
          />

          {/* New Route Added For Admin Approval Waiting */}
          <PublicRoute
            path="/wait-for-approval"
            header={true}
            component={AdminApproval}
            exact
            sensitive
          />

          <PublicRoute
            path="/page-not-found"
            header={true}
            component={Error404}
            exact
            sensitive
          />

          <PublicRoute component={Error404} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default BasicRoutes;
