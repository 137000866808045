import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
const BranchList = (props) => {
  const [branches, SetBranches] = useState([]);

  useEffect(() => {
    SetBranches(props.branches);
  }, [props.branches]);
  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <table className="table table-striped table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              <th className="col-1">#</th>
              <th>Branch Name</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {branches &&
              branches.length > 0 &&
              branches.map((branch, i) => (
                <tr key={i}>
                  <td className="col-1" scope="row">
                    {i + 1}
                  </td>
                  <td>{branch.clt_branch_name}</td>
                  <td>{branch.clt_branch_location}</td>
                </tr>
              ))}
            {branches.length == 0 && (
              <tr>
                <td colSpan="100">No branches Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    branches: state.branches.branches,
  };
};

export default connect(mapStateToProps)(BranchList);
