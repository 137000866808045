import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import composedDailyLogsContainer from "../../../containers/DailyLogsContainer";
import DailyLogList from "./DailyLogsList";
import { useEffect } from "react";
import { redirectWindowUrl } from "../../../services/GlobalUrls";
import DailyLogsListMobile from "./DailyLogsListMobile";
import { Tooltip } from "@mui/material";

const DailyLogMainComponent = ({ deviceInfo, ...props }) => {
  const [viewToolTip, setViewToolTip] = useState(false);
  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <h5 className="font-weight-700 pull-left text-uppercase">
                  Daily Logs
                </h5>
                {deviceInfo && deviceInfo == "Mobile" ? (
                  <Tooltip
                    title="Create Daily Logs"
                    className="text-sm"
                    open={viewToolTip}
                    placement="top"
                  >
                    <a
                      href={"/create-daily-logs"}
                      className="pull-right btn btn-success"
                      onTouchStart={(e) => {
                        setViewToolTip(true);
                      }}
                      onTouchEnd={(e) => {
                        setViewToolTip(false);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </a>
                  </Tooltip>
                ) : (
                  <a
                    href="/create-daily-logs"
                    className="pull-right site-button btn-sm"
                  >
                    Create Daily Logs
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        {deviceInfo && deviceInfo == "Mobile" ? (
          <DailyLogsListMobile />
        ) : (
          <DailyLogList />
        )}
      </div>
    </React.Fragment>
  );
};

export default composedDailyLogsContainer(DailyLogMainComponent);
