import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Countries from "../Countries";
import Input from "../../FormElements/Input";
import Label from "../../Layout/Label";
const AddressFields = (props) => {
  const [userdata, setFormdata] = useState({});
  const { errors } = props;
  useEffect(() => {
    setFormdata(props.data);
  }, [props.data]);
  return (
    <React.Fragment>
      <Input
        type="text"
        label="Address"
        placeholder={"Eg., 123 Main St"}
        lg={12}
        md={12}
        disabled={props.cndApproved}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_street_address"
        id={"cnd_street_address"}
        value={userdata.cnd_street_address}
        errors={errors}
      />
      <Input
        type="text"
        lg={12}
        md={12}
        changeHandler={(e) => props.onChange(e)}
        disabled={props.cndApproved}
        name="cnd_street_address_2"
        id={"cnd_street_address_2"}
        value={userdata.cnd_street_address_2}
        placeholder="Eg., Apartment, suite, unit etc. (optional)"
        errors={errors}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        disabled={props.cndApproved}
        name="cnd_town"
        id={"cnd_town"}
        lg={4}
        md={4}
        value={userdata.cnd_town}
        placeholder="Eg., London"
        errors={errors}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        disabled={props.cndApproved}
        name="cnd_region"
        id={"cnd_region"}
        lg={4}
        md={4}
        value={userdata.cnd_region}
        placeholder="Eg., London"
        errors={errors}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        disabled={props.cndApproved}
        name="cnd_postcode"
        id={"cnd_postcode"}
        lg={4}
        md={4}
        //maxLength={8}
        value={userdata.cnd_postcode}
        placeholder="Eg., AB12CD"
        errors={errors}
      />
      <Form.Group as={Col} md="6" controlId={"cnd_county"}>
        <Label labelName="Country" />
        <Form.Control
          as="select"
          name="cnd_county"
          id={"cnd_county"}
          custom
          value={userdata.cnd_county}
          defaultValue={userdata.cnd_county}
          onChange={(e) => props.onChange(e)}
          // isInvalid={
          //   errors && errors["cnd_county"]
          //     ? true
          //     : false
          // }
          // className={
          //   errors && errors["cnd_county"]
          //     ? "invalid custom-select"
          //     : "valid custom-select"
          // }
        >
          {/* <option value="">Select Country</option> */}
          <option value="GB">UK</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors && errors["cnd_county"]}
        </Form.Control.Feedback>
      </Form.Group>
    </React.Fragment>
  );
};

export default AddressFields;
