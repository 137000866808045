import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, Route } from "react-router-dom";
// import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import { redirectUrl } from "../../services/GlobalUrls";
import {
  capitalizeFirstLetter,
  convertToSnakeCase,
} from "../../services/StringOperation";
import { jobTitle } from "../../helpers/WorkRefrencesHelper";
import GlobalViewFileModal from "../../services/GlobalViewFileModal";
import { checkIfFileIsaDocument } from "../../services/FileOperationGlobalFunctions";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import {
  capitalizeWords,
  getInitialsLettersOfString,
} from "../../services/GlobalStringOp";
import { Button, Modal } from "react-bootstrap";
import Input from "../../components/FormElements/Input";
import { santetizeData } from "../../helpers/FormFieldsSanetizations";
import { updateUserProfile } from "../../redux/actions/UserProfileActions";
import { validateUkNumber } from "../../helpers/ValidationHelpers";
import { isMobileOrLaptop } from "../../services/CheckDevice";
import AccountCentreSidebarMobile from "../../components/Element/AccountCentreSidebarMobile";
const CandidateProfileSidebar = (props) => {
  let cndStatus = localStorage.getItem("tempUserStatus");
  const [loading, setLoading] = useState(true);
  const [updateAboutYou, updateAboutYouOperation] = useState(false);
  // Modal States - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // Modal State - End
  const history = useHistory();
  const { progress, userData, dbsDocuments } = props;
  const [formStates, setFormStates] = useState({
    userProfile: false,
    employmentReferences: false,
    typesOfServices: false,
    agreements: false,
    bankDetails: false,
    rightToWork: false,
    workPreferences: false,
    documentsCompliance: false,
    dbsCertificate: false,
    identityDocument: false,
  });
  const [progresStatus, setProgress] = useState(0);
  const [fltMenu, setFltMenu] = useState(false);
  const [driverMenu, setDriverMenu] = useState(false);
  const [selectedJobTitles, setSelectedJobTitle] = useState("");

  const convertJobTitleValuesInArray = (valuesInArray) => {
    let mainObj = [];
    let arrayValues = {};
    jobTitle.map((val, i) => {
      arrayValues[val.id] = val.name;
    });
    valuesInArray.map((value, key) => {
      mainObj[key] = { name: arrayValues[value], id: value };
    });
    setSelectedJobTitle(mainObj);
  };

  useEffect(() => {
    // Loader - Start
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, [props.workAndQualificationDetails]);

  useEffect(() => {
    if (progress.cnd_work_job_title) {
      var valuesInArrayJobTitle = progress.cnd_work_job_title.split(",");
      convertJobTitleValuesInArray(valuesInArrayJobTitle, "jobTitle");
    }

    let jobTitleSortObject = Object.values(selectedJobTitles);
    let flt_menu, driver_menu;
    if (jobTitleSortObject) {
      jobTitleSortObject.map((item, index) => {
        // FLT License
        if (item.id == "forklift_truck_drivers_(all_categories)") {
          flt_menu = true;
        }
        // Driver License
        if (
          item.id ==
            convertToSnakeCase("HGV / 7.5 Tonne / Multi-Drop Drivers") ||
          item.id == convertToSnakeCase("HGV & Commercial Vehicle Technicians")
        ) {
          driver_menu = true;
        }
      });
    }
    setFltMenu(flt_menu);
    setDriverMenu(driver_menu);
    if (!localStorage.getItem("fltMenu") && flt_menu == true) {
      localStorage.setItem("fltMenu", true);
    }
    if (!localStorage.getItem("driverMenu") && driver_menu == true) {
      localStorage.setItem("driverMenu", true);
    }

    setFormStates({
      userProfile: progress.progress_bar_profile_1 ? true : false,
      employmentReferences: progress.progress_bar_reference_2 ? true : false,
      typesOfServices: userData.cnd_types_of_services !== "" ? true : false,
      agreements: progress.progress_bar_agreement_3 ? true : false,
      bankDetails: progress.progress_bar_bank_4 ? true : false,
      rightToWork: progress.progress_bar_right_to_work_5 ? true : false,
      workPreferences: progress.progress_bar_work_preference_6 ? true : false,
      documentsCompliance: progress.progress_bar_compliance_7 ? true : false,
      dbsCertificate: progress.progress_bar_dbs_8 ? true : false,
      identityDocument: progress.progress_bar_identity_9 ? true : false,
    });
    let totalProgress = 0;

    // Profile Picture - Start
    if (users_profile_pic) {
      totalProgress = totalProgress + 15;
    }
    // Profile Picture - End
    // CV - Start
    if (cnd_upload_cv) {
      totalProgress = totalProgress + 15;
    }
    // CV - End

    // Looking for Start
    if (cnd_desired_job_title && cnd_job_salary_amount && cnd_job_salary_type) {
      totalProgress = totalProgress + 10;
    }
    // Looking for End

    // Identity video Start
    // if (cnd_upload_identity_video) {
    //   totalProgress = totalProgress + 10;
    // }
    // Identity video End
    // Status & Availability - Saart
    // if (cnd_job_employment_status && cnd_job_notice_period) {
    //   totalProgress = totalProgress + 10;
    // }
    // Status & Availability - End

    // Work Experience - Start
    if (
      props.workAndQualificationDetails.employementReference &&
      props.workAndQualificationDetails.employementReference.length > 0
    ) {
      totalProgress = totalProgress + 30;
    }
    // Work Experience - End

    // Qualification - Start
    if (
      props.workAndQualificationDetails.educationReference &&
      props.workAndQualificationDetails.educationReference.length > 0
    ) {
      totalProgress = totalProgress + 30;
    }
    // Qualification - End

    // Skills - Start
    // if (cnd_jobs_skills) {
    //   totalProgress = totalProgress + 10;
    // }
    // Skills - End

    // Car & Driving License - Start
    // if (cnd_drivinglicense && cnd_have_a_car) {
    //   totalProgress = totalProgress + 10;
    // }
    // Car & Driving License - End

    setProgress(totalProgress);
  }, [progress, userData]);
  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    cnd_street_address,
    cnd_town,
    cnd_postcode,
    cnd_upload_cv,
    cnd_upload_identity_video,
    cnd_desired_job_title,
    cnd_job_salary_amount,
    cnd_job_salary_type,
    cnd_job_employment_status,
    cnd_job_notice_period,
    cnd_jobs_skills,
    cnd_drivinglicense,
    cnd_have_a_car,
    users_phonenumber,
    users_email,
    users_category,
    users_access_token,
    users_profile_pic,
    users_profile_img_path,
    user_sign_signature,
    job_type,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "135px";
      imageRef.current.style.height = "135px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };

  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
  }, [props.userData]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };

  const documentsRequiredCall = () => {
    let urlRed;
    if (
      progress.progress_bar_compliance_7 == null ||
      progress.progress_bar_compliance_7 == 0 ||
      progress.progress_bar_compliance_7 == "0"
    ) {
      if (!progress.cnd_compliance_doc_master_id) {
        urlRed = "/create-document-address1";
      } else if (
        progress.cnd_compliance_doc_master_id &&
        progress.cnd_compliance_doc_master_id == "address1"
      ) {
        urlRed = "/create-document-address2";
      } else if (
        progress.cnd_compliance_doc_master_id &&
        progress.cnd_compliance_doc_master_id == "address2"
      ) {
        urlRed = "/create-document-national-insurance";
      } else {
        urlRed = "/document-required";
      }
    }
    redirectUrl(urlRed ? urlRed : "/document-required");
  };

  // Modal Function - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  const callSkeleton = () => {
    return (
      <>
        <div className="row mb-0">
          <div className="col-sm-1">
            <Skeleton
              variant="circular"
              style={{ backgroundColor: "#e6d5ff" }}
              className="bg-default mb-2"
              width={30}
              height={30}
            />
          </div>
          <div className="col-sm-10 ml-2">
            <Skeleton
              style={{
                borderRadius: "20px",
                backgroundColor: "#e6d5ff",
              }}
              variant="rounded"
              className="bg-default mb-2"
              width={"100%"}
              height={30}
            />
          </div>
        </div>
      </>
    );
  };

  // Main About you operation -  Start
  const [userdata, setFormdata] = useState({});
  const validateDataBeforeSubmit = (type = "") => {
    let applyError = false;

    // First Name
    if (type == "submit" || type == "users_firstname") {
      let users_firstname = document.getElementById("users_firstname");
      let users_firstname_msg = document.getElementById("users_firstname_msg");
      if (!users_firstname.value || users_firstname.value.trim() == "") {
        users_firstname.classList.add("border-danger");
        users_firstname_msg.innerText = "Please enter the first name!";
        if (applyError == false) {
          users_firstname.focus();
        }
        applyError = true;
      } else {
        users_firstname.classList.remove("border-danger");
        users_firstname_msg.innerText = "";
        if (users_firstname.value) {
          userdata["users_firstname"] = users_firstname.value;
          setFormdata(userdata);
        }
      }
    }

    // Last Name
    if (type == "submit" || type == "users_lastname") {
      let users_lastname = document.getElementById("users_lastname");
      let users_lastname_msg = document.getElementById("users_lastname_msg");
      if (!users_lastname.value || users_lastname.value.trim() == "") {
        users_lastname.classList.add("border-danger");
        users_lastname_msg.innerText = "Please enter the last name!";
        if (applyError == false) {
          users_lastname.focus();
        }
        applyError = true;
      } else {
        users_lastname.classList.remove("border-danger");
        users_lastname_msg.innerText = "";
        if (users_lastname.value) {
          userdata["users_lastname"] = users_lastname.value;
          setFormdata(userdata);
        }
      }
    }

    // Phone Number
    if (type == "submit" || type == "users_phonenumber") {
      let users_phonenumber = document.getElementById("users_phonenumber");
      let users_phonenumber_msg = document.getElementById(
        "users_phonenumber_msg"
      );
      if (!users_phonenumber.value || users_phonenumber.value.trim() == "") {
        users_phonenumber.classList.add("border-danger");
        users_phonenumber_msg.innerText = "Please enter the phone number!";
        if (applyError == false) {
          users_phonenumber.focus();
        }
        applyError = true;
      } else if (
        users_phonenumber.value &&
        !validateUkNumber(users_phonenumber.value)
      ) {
        users_phonenumber.classList.add("border-danger");
        users_phonenumber_msg.innerText =
          "Please enter the valid UK phone number!";
        if (applyError == false) {
          users_phonenumber.focus();
        }
        applyError = true;
      } else {
        users_phonenumber.classList.remove("border-danger");
        users_phonenumber_msg.innerText = "";
        if (users_phonenumber.value) {
          userdata["users_phonenumber"] = users_phonenumber.value;
          setFormdata(userdata);
        }
      }
    }

    if (type == "submit") {
      return applyError;
    } else {
      return true;
    }
  };
  const updateUserProfilePersonalInfo = () => {
    if (validateDataBeforeSubmit("submit") == false) {
      document.getElementById("loader").style.display = "block";
      userdata["users_access_token"] = props.userAuth.users_access_token;
      updateAboutYouOperation(false);
      dispatch(
        updateUserProfile(santetizeData(userdata), "UpdateCandidateAboutYou")
      );
    }
  };

  // Main About you operation - End

  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(600);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  return (
    <div>
      <div className="wrapper">
        <div className="candidate-info">
          <div className="candidate-detail text-center">
            <div
              className={
                "canditate-des " +
                (users_profile_img_path && users_profile_pic ? "" : "bg-dark")
              }
            >
              <CircularProgressbarWithChildren
                className="CircularProgressbar-background"
                value={progresStatus}
                // styles={buildStyles({
                //   // Colors
                //   pathColor: progresStatus > 70 ? "#1fe519" : "#f66", // Change the color based on the progress status
                //   trailColor: "#d6d6d6",
                //   backgroundColor: "#3e98c7",
                // })}
              >
                {users_profile_img_path && users_profile_pic ? (
                  <img
                    className="size-thumbnail1 rounded-circle cursor-pointer"
                    width={"135px"}
                    height={"135px"}
                    ref={imageRef}
                    onClick={() => {
                      if (users_profile_img_path && users_profile_pic) {
                        viewFileModal(
                          constants.IMAGE_URL +
                            users_profile_img_path +
                            users_profile_pic
                        );
                      } else {
                        Swal.fire({
                          title: "Profile Picture Not Available",
                          text: "",
                          icon: "warning",
                          showCancelButton: false,
                          confirmButtonText: "Ok",
                          // confirmButtonColor: 'blue'
                        });
                      }
                    }}
                    src={require("./../../images/team/pic1.jpg")}
                  />
                ) : (
                  <div className="mt-4 text-white">
                    <h1 style={{ fontSize: "60px" }}>
                      {getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) &&
                      getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) != "UU" ? (
                        getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        )
                      ) : (
                        <i
                          class="fa fa-spinner text-center fa-pulse text-white"
                          style={{ fontSize: "80px" }}
                        ></i>
                      )}
                    </h1>
                  </div>
                )}
              </CircularProgressbarWithChildren>
              <div style={{ fontSize: 18, marginTop: 5 }}>
                <strong>{progresStatus}%</strong> Completed
              </div>
              <div
                className="upload-link border"
                title="Upload Image"
                data-toggle="tooltip"
                data-placement="right"
                style={{ marginTop: "65%" }}
              >
                <input
                  type="file"
                  className="update-flie"
                  id="users_profile_pic"
                  // onChange={(e) => onChangeInputvalue(e)}
                  accept=".png,.jpeg,.jpg"
                />
                <i className="fa fa-camera"></i>
              </div>
            </div>

            {/* <div className="candidate-title">
              <div className="">
                <h4 className="m-b5">
                  <b>{users_firstname}</b>
                </h4>
                <p className="m-b0">
                  <b>
                    {props.userAuth.users_category
                      ? capitalizeFirstLetter(
                          String(props.userAuth.users_category)
                        )
                      : ""}
                  </b>
                </p>
              </div>
            </div> */}
          </div>
          <ul>
            {/* <li>
              <div
                className="border border-grey mt-3 p-3"
                style={{ borderRadius: "20px" }}
              >
                <h5 className="mb-2">
                  <b className="mt-2" style={{ fontWeight: "700" }}>
                    Profile and CV
                  </b>
                  

                  <Switch
                    className="pull-right"
                    defaultChecked={true}
                    // onChange={(
                    //   event,
                    //   newInputValue
                    // ) => {
                    //   if (tableColMode == true) {
                    //     changeSwitchMode(
                    //       "non_rolled_up_pay"
                    //     );
                    //   } else {
                    //     changeSwitchMode(
                    //       "rolled_up_pay"
                    //     );
                    //   }
                    // }}
                    inputProps={{
                      "aria-label": "controlled",
                    }}
                    color="primary"
                  />

                  
                </h5>
                <p>
                  Increase your chances of finding a new job by allowing
                  recruiters to find you by your profile & CV.
                </p>
              </div>
            </li> */}
            <li>
              <div
                className="border border-grey mt-3"
                style={{ borderRadius: "20px" }}
              >
                <p className="mb-0 p-3">
                  <h5 className="mt-2 mb-1" style={{ fontWeight: "700" }}>
                    About You
                    <Tooltip className="pull-right" title={"Edit"}>
                      <i
                        className="fa fa-pencil-square-o text-primary pull-right mt-1 cursor-pointer"
                        style={{ fontSize: "125%" }}
                        onClick={() => updateAboutYouOperation(true)}
                      ></i>
                    </Tooltip>
                  </h5>
                </p>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    right: "0",
                    left: "20",
                    backgroundColor: "#ccc",
                  }}
                ></div>
                <p className="p-4 mb-0">
                  <h6>
                    {users_firstname && users_lastname ? (
                      <>
                        <i
                          className="fa fa-user-circle-o mr-2 text-primary"
                          style={{ fontSize: "120%" }}
                        ></i>
                        {users_firstname + " " + users_lastname}
                      </>
                    ) : (
                      callSkeleton()
                    )}
                  </h6>
                  {cnd_desired_job_title && (
                    <>
                      <h6>
                        {loading == false ? (
                          <>
                            <i
                              className="fa fa-suitcase mr-2 text-primary"
                              style={{ fontSize: "120%" }}
                            ></i>
                            {cnd_desired_job_title}
                          </>
                        ) : (
                          callSkeleton()
                        )}
                      </h6>
                    </>
                  )}

                  <h6>
                    {users_phonenumber ? (
                      <>
                        <i
                          className="fa fa-phone mr-2 text-primary"
                          style={{ fontSize: "120%" }}
                        ></i>
                        {users_phonenumber}
                      </>
                    ) : (
                      callSkeleton()
                    )}
                  </h6>

                  <h6>
                    {users_email ? (
                      <>
                        <i
                          className="fa fa-envelope mr-2 text-primary"
                          style={{ fontSize: "120%" }}
                        ></i>
                        {users_email}
                      </>
                    ) : (
                      callSkeleton()
                    )}
                  </h6>

                  <h6>
                    {users_category ? (
                      <>
                        <i
                          className="fa fa-building mr-2 text-primary"
                          style={{ fontSize: "120%" }}
                        ></i>
                        {capitalizeFirstLetter(String(users_category))}
                      </>
                    ) : (
                      callSkeleton()
                    )}
                  </h6>
                </p>
              </div>
            </li>
          </ul>

          {page && page == "profile-and-cv" && (
            <>
            {checkDevice && checkDevice == "Mobile" ? (
            <AccountCentreSidebarMobile page="profile-and-cv" />
          ) : (
            <ul className="border border-grey mt-3" style={{borderRadius:"20px"}}>
            <p className="mb-0 p-3">
                    <h5 className="mt-2 mb-1" style={{ fontWeight: "700" }}>
                      Account Centre
                      
                    </h5>
                  </p>
               
                <li>
                  <a
                    href={"/change-password"}
                    className={`${
                      window.location.pathname == "/change-password" && "active"
                    }`}
                  >
                    <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                    <span className="text-md">
                      <b>Change Password</b>
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href={"/account-faq"}
                    className={`${
                      window.location.pathname == "/account-faq" && "active"
                    }`}
                  >
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                    <span>Account FAQ'S</span>
                  </a>
                </li>            
            </ul>
          )}
            </>
          )}
        </div>
      </div>

      {/* Update About Modal - Start */}
      <Modal
        show={updateAboutYou}
        onHide={() => updateAboutYouOperation(false)}
        className="col-sm-12 fade"
        // size="lg"
        style={{ marginTop: "5%" }}
      >
        <div className="modal-header bg-dark text-white">
          <h5 className="" id="ResumeheadlineModalLongTitle">
            Update About You{" "}
          </h5>
          <a
            className="btn btn-danger btn-sm text-white m-2 cursor-pointer"
            onClick={() => updateAboutYouOperation(false)}
          >
            <b>
              <i className="fa fa-close"></i>
            </b>
          </a>
        </div>
        <div className="modal-body">
          <form className="attach-resume">
            <div className="row">
              <Input
                type="text"
                label="First Name"
                placeholder={"Eg., John"}
                changeHandler={(e) => validateDataBeforeSubmit(e.target.id)}
                name="users_firstname"
                id={"users_firstname"}
                lg={12}
                value={users_firstname}
                toolTip={false}
              />
              <Input
                type="text"
                label="Last Name"
                placeholder={"Eg., Doe"}
                changeHandler={(e) => validateDataBeforeSubmit(e.target.id)}
                name="users_lastname"
                id={"users_lastname"}
                lg={12}
                value={users_lastname}
                toolTip={false}
              />
              <Input
                type="text"
                label="Phone No"
                placeholder={"Eg., 01234567890"}
                changeHandler={(e) => validateDataBeforeSubmit(e.target.id)}
                name="users_phonenumber"
                id={"users_phonenumber"}
                lg={12}
                value={users_phonenumber}
                toolTip={false}
              />

              <div className="col-sm-12">
                <Button
                  className="site-button green pull-right"
                  onClick={() => updateUserProfilePersonalInfo()}
                >
                  Update
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      {/* Update About Modal - End */}
      {fileUrl && (
        <>
          <GlobalViewFileModal
            modalOption={modalOption}
            closeFileModal={closeFileModal}
            fileUrl={fileUrl}
            loaderEnd={loaderEnd}
            modalHeight={modalHeight}
            callModal={callModal}
            title={"Profile Picture"}
            // fileName={fileName}
          />
        </>
      )}
    </div>
  );
};

const successToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
    workAndQualificationDetails:
      state.workAndQualificationDetails.workAndQualificationDetails,
  };
};

export default connect(mapStateToProps)(CandidateProfileSidebar);
