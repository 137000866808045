import { main } from "react-devtools";
import * as Yup from "yup";
import { format } from "date-fns";
let currentDate = format(new Date(), "dd-MM-yyyy");

export const formWorkExperience = () => [
  {
    name: `cnd_refer_work_experience`,
    field: "cnd_refer_work_experience",
    type: "dropdown",
    options: [
      { id: "", name: "Select Work Experience", disabled: false },
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
    label: "Do you have work experience?",
    md: "12",
    sm: "12",
  },
];

export const formInputFields = (key) => [
  {
    name: `educationReference.${key}.cnd_refer_record_company_name`,
    field: "cnd_refer_record_company_name",
    type: "text",
    label: "Institute Name",
    md: "6",
    sm: "12",
  },
  {
    name: `educationReference.${key}.cnd_refer_record_qualification`,
    field: "cnd_refer_record_qualification",
    type: "text",
    label: "Qualification",
    md: "6",
    sm: "12",
  },
  {
    name: `educationReference.${key}.cnd_refer_record_fromDate`,
    field: "cnd_refer_record_fromDate",
    max: new Date().toISOString().split("T")[0],
    min: new Date(new Date().setFullYear(new Date().getFullYear() - 63))
      .toISOString()
      .split("T")[0],
    type: "date",
    label: "From Date",
    md: "6",
    sm: "12",
  },
  {
    name: `educationReference.${key}.cnd_refer_record_toDate`,
    field: "cnd_refer_record_toDate",
    max: new Date().toISOString().split("T")[0],
    min: new Date(new Date().setFullYear(new Date().getFullYear() - 63))
      .toISOString()
      .split("T")[0],
    type: "date",
    label: "To Date",
    md: "6",
    sm: "12",
  },
  {
    name: `educationReference.${key}.cnd_refer_record_country`,
    field: "cnd_refer_record_country",
    type: "country",
    label: "Country",
    md: "6",
    sm: "12",
  },
  {
    name: `educationReference.${key}.cnd_refer_record_phone_no`,
    field: "cnd_refer_record_phone_no",
    type: "text",
    label: "Telephone Number",
    md: "6",
    sm: "12",
  },
  {
    name: `educationReference.${key}.cnd_refer_record_email`,
    field: "cnd_refer_record_email",
    type: "text",
    label: "Teacher/School/University Email",
    md: "6",
    sm: "12",
  },
  {
    name: `educationReference.${key}.cnd_refer_record_contact_name`,
    field: "cnd_refer_record_contact_name",
    type: "text",
    label: "Contact Person's Name",
    md: "6",
    sm: "12",
  },
  // {
  //   name: `educationReference.${key}.cnd_refer_record_position`,
  //   field: "cnd_refer_record_position",
  //   type: "text",
  //   label: "Contact Person's Position",
  //   md: "6",
  //   sm: "12",
  // },
  {
    name: `educationReference.${key}.cnd_refer_record_position`,
    field: "cnd_refer_record_position",
    type: "text",
    label: "Contact Person's Designation",
    md: "6",
    sm: "12",
  },
];

export const validationSchema = Yup.object().shape({
  cnd_refer_work_experience: Yup.string()
    .required("Please Select Work Experience")
    .typeError("Please Select Work Experience"),
  educationReference: Yup.array().of(
    Yup.object().shape({
      cnd_refer_record_company_name: Yup.string()
        .required("Please Enter Institute Name")
        .typeError("Please Enter Institute Name")
        .trim(""),
      cnd_refer_record_qualification: Yup.string()
        .required("Please Enter Qualification Name")
        .typeError("Please Enter Qualification Name")
        .trim(""),
      cnd_refer_record_country: Yup.string()
        .required("Please Select Country")
        .typeError("Please Select Country"),
      cnd_refer_record_fromDate: Yup.date()
        .required("Please Select From Date")
        .typeError("Please Select From Date")
        .min(
          new Date(new Date().setFullYear(new Date().getFullYear() - 63))
            .toISOString()
            .split("T")[0],
          "Please select the valid date, date must be 23-06-1960 or later"
        )
        .max(
          new Date().toISOString().split("T")[0],
          "Please choose the " + currentDate + " or before this date"
        ),
      cnd_refer_record_toDate: Yup.date()
        .required("Please Select To Date")
        .typeError("Please Select To Date")
        .min(
          Yup.ref("cnd_refer_record_fromDate"),
          "End Date Can't Be Before Start Date"
        )
        .max(
          new Date().toISOString().split("T")[0],
          "Please choose the " + currentDate + " or before this date"
        ),
      cnd_refer_record_phone_no: Yup.string()
        .required("Please Enter Telephone Number")
        .typeError("Please Enter Telephone Number")
        .matches(
          /^0\d{10}$/,
          "Please enter valid 11 digit phone number starting from 0 "
        ),
      cnd_refer_record_email: Yup.string()
        .required("Please Enter Email")
        .matches(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          "Please enter the valid email address"
        )
        .trim(""),
      cnd_refer_record_contact_name: Yup.string()
        .required("Please Enter Conatct Name")
        .trim(""),
      cnd_refer_record_position: Yup.string()
        .required("Please Enter Conatct Position")
        .trim(""),
    })
  ),
  professionalReference: Yup.array().of(
    Yup.object().shape({
      cnd_refer_record_company_name: Yup.string()
        .required("Please Enter Institute Name")
        .typeError("Please Enter Institute Name")
        .trim(""),
      cnd_refer_record_contact_name: Yup.string()
        .required("Please Enter Contact Name")
        .typeError("Please Enter Contact Name")
        .trim(""),
      cnd_refer_record_country: Yup.string()
        .required("Please Select Country")
        .typeError("Please Select Country"),
      cnd_refer_record_fromDate: Yup.date()
        .required("Please Enter From Date")
        .typeError("Please Enter From Date")
        .min(
          new Date(new Date().setFullYear(new Date().getFullYear() - 63))
            .toISOString()
            .split("T")[0],
          "Please select the valid date, date must be 23-06-1960 or later"
        )
        .max(
          new Date().toISOString().split("T")[0],
          "Please choose the " + currentDate + " or before this date"
        ),
      cnd_refer_record_toDate: Yup.date()
        .required("Please Enter To Date")
        .typeError("Please Enter To Date")
        .min(
          Yup.ref("cnd_refer_record_fromDate"),
          "End Date Can't Be Before Start Date"
        )
        .max(
          new Date().toISOString().split("T")[0],
          "Please choose the " + currentDate + " or before this date"
        ),
      cnd_refer_record_phone_no: Yup.string()
        .required("Please Enter Telephone Number")
        .typeError("Please Enter Telephone Number")
        .matches(
          /^0\d{10}$/,
          "Please enter valid 11 digit phone number starting from 0 "
        ),
      cnd_refer_record_email: Yup.string()
        .required("Please Enter Email")
        .matches(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          "Please enter the valid email address"
        )
        .trim(""),
    })
  ),
});
