import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  documentComplianceList,
  documentsList,
} from "../../../helpers/DocumentsList";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import { redirectUrl } from "../../../services/GlobalUrls";

const DocumentRequiredListMobile = (props) => {
  const [documentCompliance, SetDocumentCompliance] = useState([]);
  const { progress, userData } = props;
  const [inputValue, setInputValue] = useState("");

  const [filter, setFilter] = useState("");
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.documentCompliance.length;
    console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetDocumentCompliance(props.documentCompliance);
  }, [props.documentCompliance]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName, subFileName) => {
    if (checkIfFileIsaDocument(url)) {
      // document.getElementById("loader").style.display = "block";
      // setModalOption(true);
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName + " " + subFileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };

  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  useEffect(() => {
    SetDocumentCompliance(props.documentCompliance);
  }, [props.documentCompliance]);

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  const matchesSearchText = (documents) => {
    const searchText = inputValue.toLowerCase();
    return (
      documents.cnd_compliance_created_date
        .toLowerCase()
        .includes(searchText) ||
      documentComplianceList[documents.cnd_compliance_doc_master_id]
        .toLowerCase()
        .includes(searchText) ||
      documents.cnd_doc_name.toLowerCase().includes(searchText) ||
      documents.cnd_compliance_exp_date_convert
        .toLowerCase()
        .includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filterRows = documentCompliance
    ? documentCompliance.filter(matchesSearchText)
    : [];

  return (
    <React.Fragment>
      <div className="row m-b30">
        {/* Search Start */}
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          {filterRows &&
            filterRows.length > 0 &&
            filterRows.slice(startIndex, endIndex).map((compliance, i) => (
              <ul
                className="nav navbar-nav border border-grey p-4 mb-2"
                style={{ borderRadius: "20px" }}
                key={i}
              >
                <li className="text-dark">
                  <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                  {compliance.cnd_compliance_created_date}
                </li>
                <li className="text-dark">
                  <i className="fa fa-file-text mr-1 text-primary"></i>{" "}
                  <a
                    onClick={() =>
                      viewFileModal(
                        constants.IMAGE_URL +
                          compliance.cnd_compliance_folder_path +
                          compliance.cnd_compliance_upload_file,
                        documentComplianceList[
                          compliance.cnd_compliance_doc_master_id
                        ],
                        compliance.cnd_compliance_doc_master_id == "additional"
                          ? "(" + compliance.cnd_doc_name + ")"
                          : ""
                      )
                    }
                    className={
                      "text-" +
                      (compliance.cnd_compliance_upload_file.endsWith(".png") ||
                      compliance.cnd_compliance_upload_file.endsWith(".jpg") ||
                      compliance.cnd_compliance_upload_file.endsWith(".jpeg") ||
                      compliance.cnd_compliance_upload_file.endsWith(".pdf")
                        ? "primary"
                        : "primary") +
                      ""
                    }
                  >
                    <u>
                      {documentComplianceList[
                        compliance.cnd_compliance_doc_master_id
                      ] &&
                      String(
                        documentComplianceList[
                          compliance.cnd_compliance_doc_master_id
                        ]
                      ).length <= 20
                        ? documentComplianceList[
                            compliance.cnd_compliance_doc_master_id
                          ]
                        : String(
                            documentComplianceList[
                              compliance.cnd_compliance_doc_master_id
                            ]
                          ).slice(0, 20) + "..."}
                      {/* {compliance.cnd_compliance_doc_master_id == "additional"
                          ? "(" + compliance.cnd_doc_name + ")"
                          : ""} */}
                    </u>
                  </a>
                  <a
                    onClick={() =>
                      viewFileModal(
                        constants.IMAGE_URL +
                          compliance.cnd_compliance_folder_path +
                          compliance.cnd_compliance_upload_file,
                        documentComplianceList[
                          compliance.cnd_compliance_doc_master_id
                        ],
                        compliance.cnd_compliance_doc_master_id == "additional"
                          ? "(" + compliance.cnd_doc_name + ")"
                          : ""
                      )
                    }
                    className={
                      "text-" +
                      (compliance.cnd_compliance_upload_file.endsWith(".png") ||
                      compliance.cnd_compliance_upload_file.endsWith(".jpg") ||
                      compliance.cnd_compliance_upload_file.endsWith(".jpeg") ||
                      compliance.cnd_compliance_upload_file.endsWith(".pdf")
                        ? "primary"
                        : "primary") +
                      " pull-right"
                    }
                  >
                    {/* <i
                          className={
                            "fa fa-" +
                            (compliance.cnd_rightwork_upload_file.endsWith(".png") ||
                            compliance.cnd_rightwork_upload_file.endsWith(".jpg") ||
                            compliance.cnd_rightwork_upload_file.endsWith(".jpeg") ||
                            compliance.cnd_rightwork_upload_file.endsWith(".pdf")
                              ? "eye" //"eye"
                              : "download")
                          }
                        ></i> */}
                    <u>View File</u>
                  </a>
                </li>
                {compliance.cnd_compliance_exp_date_convert == "00-00-0000" ? (
                  ""
                ) : compliance.cnd_compliance_exp_date_convert ? (
                  <li className="text-dark">
                    <i className="fa fa-calendar-times-o mr-1 text-primary"></i>{" "}
                    {compliance.cnd_compliance_exp_date_convert}
                  </li>
                ) : (
                  ""
                )}

                <li
                  className={
                    compliance.cnd_compliance_exp_date_convert == "00-00-0000"
                      ? "text-success"
                      : new Date() <=
                          new Date(
                            splitDateToFormat(
                              compliance.cnd_compliance_exp_date_convert
                            )
                          ) &&
                        dateDiffrence(
                          compliance.cnd_compliance_exp_date_convert
                        ) > 30
                      ? " "
                      : new Date() <=
                          new Date(
                            splitDateToFormat(
                              compliance.cnd_compliance_exp_date_convert
                            )
                          ) &&
                        dateDiffrence(
                          compliance.cnd_compliance_exp_date_convert
                        ) < 30
                      ? "text-warning"
                      : "text-danger"
                  }
                >
                  <i className="fa fa-circle-o-notch mr-1 "></i>{" "}
                  {compliance.cnd_compliance_exp_date_convert == "00-00-0000"
                    ? "Valid"
                    : new Date() <=
                        new Date(
                          splitDateToFormat(
                            compliance.cnd_compliance_exp_date_convert
                          )
                        ) &&
                      dateDiffrence(
                        compliance.cnd_compliance_exp_date_convert
                      ) > 30
                    ? " "
                    : new Date() <=
                        new Date(
                          splitDateToFormat(
                            compliance.cnd_compliance_exp_date_convert
                          )
                        ) &&
                      dateDiffrence(
                        compliance.cnd_compliance_exp_date_convert
                      ) < 30
                    ? "About to Expire"
                    : "Expire"}
                </li>
              </ul>
            ))}

          {documentCompliance.length === 0 ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : filterRows.length === 0 && inputValue ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : (
            ""
          )}
          {filterRows && filterRows.length > 10 && (
            <TableDropdownAndPagination
              handleDropDown={handleDropDown}
              recordLen={recordLen}
              handleLeft={handleLeft}
              handleRight={handleRight}
              renderPageNumbers={renderPageNumbers}
              isDropdown={true}
              isPaggination={true}
            />
          )}
          <a
            href={
              progress.progress_bar_dbs_8
                ? "/dbs-document"
                : "/create-dbs-document"
            }
            className="pull-right btn btn-primary mr-2"
          >
            Next
          </a>

          {/* {documentCompliance && documentCompliance.length > 0 && (
            <TableDropdownAndPagination
              handleDropDown={handleDropDown}
              recordLen={recordLen}
              handleLeft={handleLeft}
              handleRight={handleRight}
              renderPageNumbers={renderPageNumbers}
              isDropdown={true}
              isPaggination={true}
            />
          )} */}
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        fileName={fileName}
        // title={"Documents Required"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    documentCompliance: state.documentCompliance.documentCompliance,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(DocumentRequiredListMobile);
