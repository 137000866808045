import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
const ChargeRate = (OriginalComponent) => {
  const NewComponent = (props) => {
    const { userAuth, chargerate, chargerateTable } = props;
    const dispatch = useDispatch();
    useEffect(()=>{
      if(userAuth.users_access_token && Object.keys(chargerate).length == 0){
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetChangeRate','SET_CHANGE_RATE'));
      }
      if(userAuth.users_access_token && Object.keys(chargerateTable).length == 0){
        dispatch(getDataOnRequest('','GetChangeRateTable','SET_CHANGE_RATE_TABLE'));
      }
    },[userAuth])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    chargerate:state.chargerate.chargerate,
    chargerateTable:state.chargerate.chargeratetable,
  };
};

const composedChargeRate = compose(connect(mapStateToProps), ChargeRate)
export default composedChargeRate;

