import React from 'react';
import Profilesidebar from '../components/Element/ClientDashboardSidebar';
import ClientDashboard from '../components/Element/ClientDashboard';
const ClientDashboardContainer = (props) => {
	return (
		<React.Fragment>
           <div className="page-content bg-white mt-5">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								<Profilesidebar page="job"/>
								<div className="col-xl-9 col-lg-8 m-b30 ">
									<ClientDashboard />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default ClientDashboardContainer;