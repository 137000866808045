import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import composedEmailVerificationContainer from "../../../containers/EmailVerificationContainer";
import EmailVerificationList from "./EmailVerificationList";

const EmailVerificationMainComponent = (props) => {
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <a
            target="_blank"
            href="https://www.staffingconnect.co.uk/_files/ugd/a083fb_61f82a3b3fcd4e5bb6fd17fb541e91a8.pdf"
            className="btn btn-primary  btn-md ite-button"
          >
            Download EmailVerification Template{" "}
            <i className="fa fa-download" aria-hidden="true"></i>
          </a>

          <a
            href="/create-timesheet"
            className="pull-right btn btn-dark ite-button btn-md"
          >
            Upload EmailVerification
          </a>
        </div>
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            EmailVerifications
          </h5>
        </div>
        <EmailVerificationList />
      </div>
    </React.Fragment>
  );
};

export default composedEmailVerificationContainer(
  EmailVerificationMainComponent
);
