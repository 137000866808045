import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInputFields, validationSchema } from "./FormDailyLogsHelper";
import { format } from "date-fns";
import constants from "../../../constants";
import Dropdown from "../../FormElements/Dropdown";
import SignatureCanvas from "react-signature-canvas";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { getBase64 } from "../../../helpers/FileToBase64";
import LoadFormFields from "../../Layout/LoadFormFields";
import { useHistory } from "react-router-dom";
import Input from "../../FormElements/Input";
import Textarea from "../../FormElements/Textarea";
import { Button, Col, Form } from "react-bootstrap";
import { redirectWindowUrl } from "../../../services/GlobalUrls";
import ReactSignatureCanvas from "react-signature-canvas";
import Label from "../../Layout/Label";
import penCursor from "../../../images/cursors/pen.ico";
import { useRef } from "react";
import CustomeDropdown from "../../../services/CustomeDropdown";
const DailyLog = (props) => {
  const [dailylogData, setFormdata] = useState({});
  const [dailyLogsServiceUserList, setDailyLogsServiceUserList] = useState([]);

  // Fields Var Start
  let cnd_daily_logs_service_user_id,
    cnd_daily_logs_date,
    cnd_daily_logs_start_time,
    cnd_daily_logs_end_time,
    cnd_daily_logs_signature,
    cnd_daily_logs_consent;
  // Fields Var End

  // Err Msg Start
  let cnd_daily_logs_service_user_id_msg,
    cnd_daily_logs_date_msg,
    cnd_daily_logs_start_time_msg,
    cnd_daily_logs_end_time_msg,
    cnd_daily_logs_signature_msg,
    cnd_daily_logs_consent_msg;
  // Err Msg End

  // Id Adding Start
  // First Name Start
  cnd_daily_logs_service_user_id = document.getElementById(
    "cnd_daily_logs_service_user_id"
  );
  cnd_daily_logs_service_user_id_msg = document.getElementById(
    "cnd_daily_logs_service_user_id_msg"
  );

  // Date Start
  cnd_daily_logs_date = document.getElementById("cnd_daily_logs_date");
  cnd_daily_logs_date_msg = document.getElementById("cnd_daily_logs_date_msg");
  // Date End
  // Start Time Start
  cnd_daily_logs_start_time = document.getElementById(
    "cnd_daily_logs_start_time"
  );
  cnd_daily_logs_start_time_msg = document.getElementById(
    "cnd_daily_logs_start_time_msg"
  );
  // Start Time End
  // End Time Start
  cnd_daily_logs_end_time = document.getElementById("cnd_daily_logs_end_time");
  cnd_daily_logs_end_time_msg = document.getElementById(
    "cnd_daily_logs_end_time_msg"
  );
  // End Time End

  // Signature Start
  cnd_daily_logs_signature = document.getElementById(
    "cnd_daily_logs_signature"
  );
  cnd_daily_logs_signature_msg = document.getElementById(
    "cnd_daily_logs_signature_msg"
  );
  // Signature End
  // Consent Start
  cnd_daily_logs_consent = document.getElementById("cnd_daily_logs_consent");
  cnd_daily_logs_consent_msg = document.getElementById(
    "cnd_daily_logs_consent_msg"
  );
  // Consent End
  // Id Adding End

  // False Condition Start
  let applyError = false;
  // False Condition End

  const [signatureCode, setSignatureCode] = useState("");
  const { loader, userDetails, dailylog, userProfileData } = props;
  let sigCanvas = useRef({});
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    // window.scrollTo(0, 0);
    setFormdata(dailylog);
    if (props.dailylogsserviceuserlist) {
      setDailyLogsServiceUserList(props.dailylogsserviceuserlist);
    }
    if (formInputFields(dailylog).length > 0)
      formInputFields().map((field, key) => {
        if (dailylog[field.name]) setValue(field.name, dailylog[field.name]);
      });
    // setValue('cnd_daily_logs_firstname',userProfileData.users_firstname);
    // setValue('cnd_daily_logs_lastname',userProfileData.users_lastname);
    // setValue('cnd_daily_logs_staff_email',userProfileData.users_email);
  }, [dailylog, userProfileData, props.dailylogsserviceuserlist]);

  const onSubmit = (e) => {
    e.preventDefault();
    applyError = false;
    // Firstname Start
    if (
      !cnd_daily_logs_service_user_id.value ||
      cnd_daily_logs_service_user_id.value.trim() == ""
    ) {
      cnd_daily_logs_service_user_id.classList.add("border-danger");
      cnd_daily_logs_service_user_id.focus();
      cnd_daily_logs_service_user_id_msg.style.display = "block";
      cnd_daily_logs_service_user_id_msg.innerText =
        "Please select the service user from the list!";
      applyError = true;
    } else {
      cnd_daily_logs_service_user_id.classList.remove("border-danger");
      cnd_daily_logs_service_user_id_msg.style.display = "none";
      cnd_daily_logs_service_user_id_msg.innerText = "";
      // applyError = false;
    }

    // Date Start
    let dateVal = new Date(String(cnd_daily_logs_date.value));

    let currentDate = new Date(new Date().toISOString().split("T")[0]);

    if (!cnd_daily_logs_date.value) {
      // console.log("Checking"+cnd_daily_logs_date.value);
      cnd_daily_logs_date.classList.add("border-danger");
      if (applyError == false) {
        cnd_daily_logs_date.focus();
      }
      cnd_daily_logs_date_msg.style.display = "block";
      cnd_daily_logs_date_msg.innerText = "Please select the date";
      applyError = true;
    } else if (
      cnd_daily_logs_date.value &&
      !String(cnd_daily_logs_date.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      cnd_daily_logs_date.classList.add("border-danger");
      if (applyError == false) {
        cnd_daily_logs_date.focus();
      }
      cnd_daily_logs_date_msg.style.display = "block";
      cnd_daily_logs_date_msg.innerText =
        "Please select the date in dd-mm-yyyy format";
      applyError = true;
    } else if (cnd_daily_logs_date.value && dateVal > currentDate) {
      cnd_daily_logs_date.classList.add("border-danger");
      if (applyError == false) {
        cnd_daily_logs_date.focus();
      }
      cnd_daily_logs_date_msg.style.display = "block";
      cnd_daily_logs_date_msg.innerText =
        "Please select the " +
        format(currentDate, "dd-MM-yyyy") +
        " or before date";
      applyError = true;
    } else {
      cnd_daily_logs_date.classList.remove("border-danger");
      cnd_daily_logs_date_msg.style.display = "none";
      cnd_daily_logs_date_msg.innerText = "";
      // applyError = false;
    }

    // Start Time Start
    if (!String(cnd_daily_logs_start_time.value)) {
      cnd_daily_logs_start_time.classList.add("border-danger");
      if (applyError == false) {
        cnd_daily_logs_start_time.focus();
      }
      cnd_daily_logs_start_time_msg.style.display = "block";
      cnd_daily_logs_start_time_msg.innerText = "Please select the start time";
      applyError = true;
    } else {
      cnd_daily_logs_start_time.classList.remove("border-danger");
      cnd_daily_logs_start_time_msg.style.display = "none";
      cnd_daily_logs_start_time_msg.innerText = "";
      // applyError = false;
    }

    // End Time

    let startTime = new Date(
      `${cnd_daily_logs_date.value} ${cnd_daily_logs_start_time.value}`
    );
    let endTime = new Date(
      `${cnd_daily_logs_date.value} ${cnd_daily_logs_end_time.value}`
    );
    // console.log("Start Time :=>"+startTime+"\n\n End Time :=>"+endTime);
    if (!String(cnd_daily_logs_end_time.value)) {
      cnd_daily_logs_end_time.classList.add("border-danger");
      if (applyError == false) {
        cnd_daily_logs_end_time.focus();
      }
      cnd_daily_logs_end_time_msg.style.display = "block";
      cnd_daily_logs_end_time_msg.innerText = "Please select the end time";
      applyError = true;
    } else if (String(cnd_daily_logs_end_time.value) && startTime > endTime) {
      cnd_daily_logs_end_time.classList.add("border-danger");
      if (applyError == false) {
        cnd_daily_logs_end_time.focus();
      }
      cnd_daily_logs_end_time_msg.style.display = "block";
      cnd_daily_logs_end_time_msg.innerText =
        "End time should be greater than Start Time";
      applyError = true;
    } else {
      cnd_daily_logs_end_time.classList.remove("border-danger");
      cnd_daily_logs_end_time_msg.style.display = "none";
      cnd_daily_logs_end_time_msg.innerText = "";
      // applyError = false;
    }

    // Signature Start
    if (!signatureCode || signatureCode == "") {
      if (applyError == false) {
        window.scrollTo(0, 1700);
      }
      cnd_daily_logs_signature.style.border = "solid red 1px";
      cnd_daily_logs_signature_msg.style.display = "block";
      cnd_daily_logs_signature_msg.innerText = "Signature is required ";
      applyError = true;
    } else {
      cnd_daily_logs_signature.style.border = "solid green 1px";
      cnd_daily_logs_signature_msg.style.display = "none";
      cnd_daily_logs_signature_msg.innerText = "";
      // applyError = false;
    }

    // Consent Start
    if (dailylogData["cnd_daily_logs_consent"] != "1") {
      cnd_daily_logs_consent.classList.add("border-danger");
      if (applyError == false) {
        cnd_daily_logs_consent.focus();
      }
      cnd_daily_logs_consent_msg.style.display = "block";
      cnd_daily_logs_consent_msg.innerText =
        "Plese check the consent to proceed";
      applyError = true;
    } else {
      cnd_daily_logs_consent.classList.remove("border-danger");
      cnd_daily_logs_consent_msg.style.display = "none";
      cnd_daily_logs_consent_msg.innerText = "";
      // applyError = false;
    }

    let allData = dailylogData;
    // console.log("All Data Are :=>"+JSON.stringify(dailylogData));

    // console.log("File Is :=>"+allData['cnd_dailylog_upload_document']);
    allData["users_access_token"] = userDetails.users_access_token;
    allData["cnd_daily_logs_signature"] = signatureCode;

    // console.log("All Data Are :=> " + JSON.stringify(allData));

    if (applyError == false) {
      document.getElementById("loader").style.display = "block";
      dispatch(
        updateDataOnRequest(
          JSON.stringify(allData, null, 2),
          "SaveDailyLog",
          "",
          getDataOnRequest(
            userDetails.users_access_token,
            "GetDailyLogs",
            "SET_DAILY_LOGS"
          )
        )
      );
    }
    //history.push('/dailylog');
  };

  let dataURL;
  const formatIntoPng = () => {
    if (sigCanvas.current) {
      dataURL = sigCanvas.current.toDataURL();
      // console.log("Format Into PNG :=> "+dataURL);
      // console.warn("user_term_condition :=> "+user_term_condition.checked);
      setSignatureCode(dataURL);
      if (dataURL) {
        cnd_daily_logs_signature.style.border = "solid green 1px";
        cnd_daily_logs_signature_msg.style.display = "none";
        cnd_daily_logs_signature_msg.innerText = "";
      }
    }
  };

  const clearSign = () => {
    sigCanvas.current.clear();
    setSignatureCode("");
    // Signature Start
    cnd_daily_logs_signature.style.border = "solid red 1px";
    cnd_daily_logs_signature_msg.style.display = "block";
    cnd_daily_logs_signature_msg.innerText = "Signature is required ";
  };

  const agreeDisagreeCondition = (field) => {
    if (dailylogData[field] == "1") {
      dailylogData[field] = "0";
      cnd_daily_logs_consent.classList.add("border-danger");
      cnd_daily_logs_consent_msg.style.display = "block";
      cnd_daily_logs_consent_msg.innerText =
        "Plese check the consent to proceed";
    } else {
      dailylogData[field] = "1";
      cnd_daily_logs_consent.classList.remove("border-danger");
      cnd_daily_logs_consent_msg.style.display = "none";
      cnd_daily_logs_consent_msg.innerText = "";
    }
    setFormdata(dailylogData);
  };

  const changeFoodOptionCheck = (field) => {
    if (dailylogData[field] == "1") {
      dailylogData[field] = "0";
    } else {
      dailylogData[field] = "1";
    }
    setFormdata(dailylogData);
  };

  // console.log("Demo Started For Signature :=> " + signatureCode);
  const onChangeInputvalue = async (e) => {
    // e.preventDefault();

    if (e.target.name === "cnd_daily_logs_upload_file") {
      if (e.target.files[0])
        // dailylogData["cnd_daily_logs_upload_file"] = await getBase64(e.target.files[0]);
        setFormdata({
          ...dailylogData,
          [e.target.name]: await getBase64(e.target.files[0]),
        });
    } else {
      setFormdata({
        ...dailylogData,
        [e.target.name]: e.target.value,
      });
    }

    applyError = false;
    // Firstname Start
    if (
      !cnd_daily_logs_service_user_id.value ||
      cnd_daily_logs_service_user_id.value.trim() == ""
    ) {
      cnd_daily_logs_service_user_id.classList.add("border-danger");
      cnd_daily_logs_service_user_id_msg.style.display = "block";
      cnd_daily_logs_service_user_id_msg.innerText =
        "Please select the service user from the list!";
      applyError = true;
    } else {
      cnd_daily_logs_service_user_id.classList.remove("border-danger");
      cnd_daily_logs_service_user_id_msg.style.display = "none";
      cnd_daily_logs_service_user_id_msg.innerText = "";
      // applyError = false;
    }

    // Date Start
    let dateVal = new Date(String(cnd_daily_logs_date.value));

    let currentDate = new Date(new Date().toISOString().split("T")[0]);

    if (!cnd_daily_logs_date.value) {
      // console.log("Checking"+cnd_daily_logs_date.value);
      cnd_daily_logs_date.classList.add("border-danger");
      cnd_daily_logs_date_msg.style.display = "block";
      cnd_daily_logs_date_msg.innerText = "Please select the date";
      applyError = true;
    } else if (
      cnd_daily_logs_date.value &&
      !String(cnd_daily_logs_date.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      cnd_daily_logs_date.classList.add("border-danger");
      cnd_daily_logs_date_msg.style.display = "block";
      cnd_daily_logs_date_msg.innerText =
        "Please select the date in dd-mm-yyyy format";
      applyError = true;
    } else if (cnd_daily_logs_date.value && dateVal > currentDate) {
      cnd_daily_logs_date.classList.add("border-danger");
      cnd_daily_logs_date_msg.style.display = "block";
      cnd_daily_logs_date_msg.innerText =
        "Please select the " +
        format(currentDate, "dd-MM-yyyy") +
        " or before date";
      applyError = true;
    } else {
      cnd_daily_logs_date.classList.remove("border-danger");
      cnd_daily_logs_date_msg.style.display = "none";
      cnd_daily_logs_date_msg.innerText = "";
      // applyError = false;
    }

    // Start Time Start
    if (!String(cnd_daily_logs_start_time.value)) {
      cnd_daily_logs_start_time.classList.add("border-danger");
      cnd_daily_logs_start_time_msg.style.display = "block";
      cnd_daily_logs_start_time_msg.innerText = "Please select the start time";
      applyError = true;
    } else {
      cnd_daily_logs_start_time.classList.remove("border-danger");
      cnd_daily_logs_start_time_msg.style.display = "none";
      cnd_daily_logs_start_time_msg.innerText = "";
      // applyError = false;
    }

    // End Time

    let startTime = new Date(
      `${cnd_daily_logs_date.value} ${cnd_daily_logs_start_time.value}`
    );
    let endTime = new Date(
      `${cnd_daily_logs_date.value} ${cnd_daily_logs_end_time.value}`
    );
    // console.log("Start Time :=>"+startTime+"\n\n End Time :=>"+endTime);
    if (!String(cnd_daily_logs_end_time.value)) {
      cnd_daily_logs_end_time.classList.add("border-danger");
      cnd_daily_logs_end_time_msg.style.display = "block";
      cnd_daily_logs_end_time_msg.innerText = "Please select the end time";
      applyError = true;
    } else if (String(cnd_daily_logs_end_time.value) && startTime > endTime) {
      cnd_daily_logs_end_time.classList.add("border-danger");
      cnd_daily_logs_end_time_msg.style.display = "block";
      cnd_daily_logs_end_time_msg.innerText =
        "End time should be greater than Start Time";
      applyError = true;
    } else {
      cnd_daily_logs_end_time.classList.remove("border-danger");
      cnd_daily_logs_end_time_msg.style.display = "none";
      cnd_daily_logs_end_time_msg.innerText = "";
      // applyError = false;
    }

    // Signature Start
    if (!signatureCode || signatureCode == "") {
      cnd_daily_logs_signature.style.border = "solid red 1px";
      cnd_daily_logs_signature_msg.style.display = "block";
      cnd_daily_logs_signature_msg.innerText = "Signature is required ";
      applyError = true;
    } else {
      cnd_daily_logs_signature.style.border = "solid green 1px";
      cnd_daily_logs_signature_msg.style.display = "none";
      cnd_daily_logs_signature_msg.innerText = "";
      // applyError = false;
    }

    // Consent Start
    if (dailylogData["cnd_daily_logs_consent"] != "1") {
      cnd_daily_logs_consent.classList.add("border-danger");
      cnd_daily_logs_consent_msg.style.display = "block";
      cnd_daily_logs_consent_msg.innerText =
        "Plese check the consent to proceed";
      applyError = true;
    } else {
      cnd_daily_logs_consent.classList.remove("border-danger");
      cnd_daily_logs_consent_msg.style.display = "none";
      cnd_daily_logs_consent_msg.innerText = "";
      // applyError = false;
    }
  };

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 800);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix mb-0">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Daily Logs
          </h5>
        </div>
        <div className="row pl-2 p-2">
          <div
            class="alert alert-warning text-dark alert-dismissible fade show clearfix"
            role="alert"
            id="scrollFocusElement"
          >
            <h6>Daily logs</h6>
            <p className="ml-2">
              The Service User daily log is for carers to submit daily logs
              about the children, young people, Adults, and Old people in their
              care. We keep records of logs in our social care system. It will
              be read by professionals involved in the service user's care
              including the:
            </p>
            <p className="ml-2">
              Fostering Team, Child Social Work Team, Independent Reviewing
              Officer (IRO) Team, CQC, Etc.
            </p>

            <h6>If the child is in immediate danger</h6>
            <p className="ml-2">
              Do not use the service user's daily log to report an immediate
              concern, for example, the child is missing or at risk of harm. In
              an emergency phone 999. If you think the child is at immediate
              risk of harm phone us straight away:
            </p>
            <p className="ml-2">
              Monday to Friday from 9am to 5pm on{" "}
              <a href="tel:02038416440" className="text-danger">
                02038416440
              </a>
            </p>
            <p className="ml-2">
              Out of hours on{" "}
              <a href="tel:07508098567" className="text-danger">
                07508098567
              </a>
              .
            </p>
            <h6>Why it's important</h6>
            <p className="ml-1">
              <b>The log:</b>

              <ul className="ml-2">
                <li>contributes to plans about the service user's future</li>
                <li>may be useful for the service user in later life</li>
                <li>will help protect service user in care</li>
                <li>will help protect family members from false allegations</li>
                <li>may be used in court hearings.</li>
              </ul>
            </p>
            <h6>Complete logs</h6>
            <p>
              The log is for one service user. If you are caring for more than
              one service user you’ll need to complete a separate log for each
              service user.
            </p>
          </div>
        </div>
        <form noValidate onSubmit={onSubmit} className="mt-3">
          <div className="row">
            {/* {JSON.stringify(props.dailylogsserviceuserlist)} */}
            <CustomeDropdown
              name="cnd_daily_logs_service_user_id"
              id="cnd_daily_logs_service_user_id"
              label="Service User"
              chooseListName="Choose Service User"
              options={dailyLogsServiceUserList}
              changeHandler={(e) => onChangeInputvalue(e)}
              lg={6}
            />
            {/* <p>Selected User ID: {selectedUser}</p> */}
            {/* <Input
              label="Service User First Name"
              type="text"
              name="cnd_daily_logs_firstname"
              id="cnd_daily_logs_firstname"
              changeHandler={(e) => onChangeInputvalue(e)}
              lg={6}
            />

            <Input
              label="Service User Last Name"
              type="text"
              name="cnd_daily_logs_lastname"
              id="cnd_daily_logs_lastname"
              changeHandler={(e) => onChangeInputvalue(e)}
              lg={6}
            /> */}

            <Input
              label="Date of Visit"
              type="date"
              name="cnd_daily_logs_date"
              max={new Date().toISOString().split("T")[0]}
              id="cnd_daily_logs_date"
              changeHandler={(e) => onChangeInputvalue(e)}
              lg={6}
            />

            <Input
              label="Start Time"
              type="time"
              name="cnd_daily_logs_start_time"
              id="cnd_daily_logs_start_time"
              changeHandler={(e) => onChangeInputvalue(e)}
              lg={6}
            />

            <Input
              label="End Time"
              type="time"
              name="cnd_daily_logs_end_time"
              id="cnd_daily_logs_end_time"
              changeHandler={(e) => onChangeInputvalue(e)}
              lg={6}
            />

            <Textarea
              label="Activities"
              name="cnd_daily_logs_activities"
              type="text"
              className="form-control"
              placeholder="Enter Daily Activities"
              lg={12}
              changeHandler={(e) => onChangeInputvalue(e)}
              req={false}
            />
            {/* New Fields Start */}
            <Textarea
              label="Medication"
              name="cnd_daily_logs_medication"
              type="text"
              className="form-control"
              placeholder="Enter Medication"
              lg={12}
              changeHandler={(e) => onChangeInputvalue(e)}
              req={false}
            />
            <Label labelName="Food Details" req={false} />
            <Form.Group as={Col} md={12} className="ml-2">
              <Form.Check
                outline
                label={"Breakfast"}
                onClick={(e) =>
                  changeFoodOptionCheck("cnd_daily_logs_breakfast_check")
                }
                type="checkbox"
                name="cnd_daily_logs_breakfast_check"
                id="cnd_daily_logs_breakfast_check"
              />
              <Form.Check
                outline
                label={"Lunch"}
                onClick={(e) =>
                  changeFoodOptionCheck("cnd_daily_logs_launch_check")
                }
                type="checkbox"
                name="cnd_daily_logs_launch_check"
                id="cnd_daily_logs_launch_check"
              />

              <Form.Check
                outline
                label={"Dinner"}
                onClick={(e) =>
                  changeFoodOptionCheck("cnd_daily_logs_dinner_check")
                }
                type="checkbox"
                name="cnd_daily_logs_dinner_check"
                id="cnd_daily_logs_dinner_check"
              />
            </Form.Group>
            <Textarea
              // label="Behaviour"
              name="cnd_daily_logs_food_description"
              type="text"
              className="form-control"
              placeholder="Enter Food Description"
              lg={12}
              changeHandler={(e) => onChangeInputvalue(e)}
              req={false}
            />

            {/* New Fields End */}

            <Textarea
              label="Behaviour"
              name="cnd_daily_logs_behaviour"
              type="text"
              className="form-control"
              placeholder="Enter Behaviour"
              lg={12}
              changeHandler={(e) => onChangeInputvalue(e)}
              req={false}
            />

            <Textarea
              label="Comments or any concerns"
              name="cnd_daily_logs_comments"
              type="text"
              className="form-control"
              placeholder="Enter Comments or any concerns"
              lg={12}
              changeHandler={(e) => onChangeInputvalue(e)}
              req={false}
            />

            <Input
              label="Upload File"
              name="cnd_daily_logs_upload_file"
              id="cnd_daily_logs_upload_file"
              type="file"
              fileUrl={
                dailylog.cnd_daily_logs_upload_file
                  ? constants.IMAGE_URL +
                    dailylog.cnd_daily_logs_upload_file_folder_path +
                    dailylog.cnd_daily_logs_upload_file
                  : ""
              }
              changeHandler={(e) => onChangeInputvalue(e)}
              className="form-control"
              lg={12}
              req={false}
            />

            <Form.Group as={Col} md={12}>
              <Label labelName={"Signature"} />
              {signatureCode && (
                <a
                  style={{ cursor: "pointer", marginBottom: "-10%" }}
                  onClick={clearSign}
                  className="btn btn-danger btn-sm text-white mr-2 pull-right"
                  title="Clear Signature"
                >
                  Clear
                </a>
              )}
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  id: "cnd_daily_logs_signature",
                  style: {
                    border: "solid green 1px",
                    width: "100%",
                    height: "200px",
                    borderRadius: "8px",
                    cursor: "crosshair",
                    marginBottom: !signatureCode ? "-1%" : "0%",
                  },
                }}
                // onEnd={(e) => onChangeInputvalue(e)}
                onEnd={(e) => formatIntoPng()}
              />

              <p
                className="text-danger text-left"
                style={{ fontSize: "80%", display: "none" }}
                id="cnd_daily_logs_signature_msg"
              ></p>
            </Form.Group>

            {/* Consent Start */}
            <Form.Group as={Col} md={12} className="ml-2">
              <Form.Check
                outline
                label={
                  "I hereby declare that the information provided is true and correct."
                }
                onClick={(e) =>
                  agreeDisagreeCondition("cnd_daily_logs_consent")
                }
                type="checkbox"
                name="cnd_daily_logs_consent"
                id="cnd_daily_logs_consent"
              />
              <p
                className="text-danger "
                style={{ fontSize: "80%", display: "none" }}
                id="cnd_daily_logs_consent_msg"
              ></p>
            </Form.Group>
            {/* Consent End */}

            <div className="col-sm-12">
              <Button className="site-button m-b30 float-right" type="submit">
                Save
              </Button>
              <a
                href="/daily-logs"
                className="site-button text-white yellow m-b30 float-right mr-3"
                type="button"
              >
                Back
              </a>
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    dailylog: state.dailylog.dailylog,
    dailylogsserviceuserlist:
      state.dailylogsserviceuserlist.dailylogsserviceuserlist,
    // dailylogs:state.dailylogs.dailylogs,
    userProfileData: state.userProfile.userData,
  };
};
export default connect(mapStateToProps)(DailyLog);
