export const jobWorkPostionTitles = [
  {
    name: "Software Engineer",
    value: "software_engineer",
  },
  {
    name: "Web Developer",
    value: "web_developer",
  },
  {
    name: "Data Scientist",
    value: "data_scientist",
  },
  {
    name: "UX/UI Designer",
    value: "ux/ui_designer",
  },
  {
    name: "Product Manager",
    value: "product_manager",
  },
  {
    name: "Marketing Manager",
    value: "marketing_manager",
  },
  {
    name: "Sales Representative",
    value: "sales_representative",
  },
  {
    name: "Customer Support Specialist",
    value: "customer_support_specialist",
  },
  {
    name: "Financial Analyst",
    value: "financial_analyst",
  },
  {
    name: "Human Resources Manager",
    value: "human_resources_manager",
  },
  {
    name: "Graphic Designer",
    value: "graphic_designer",
  },
  {
    name: "Content Writer",
    value: "content_writer",
  },
  {
    name: "Project Manager",
    value: "project_manager",
  },
  {
    name: "Network Engineer",
    value: "network_engineer",
  },
  {
    name: "Systems Administrator",
    value: "systems_administrator",
  },
  {
    name: "Business Analyst",
    value: "business_analyst",
  },
  {
    name: "Quality Assurance Engineer",
    value: "quality_assurance_engineer",
  },
  {
    name: "Mobile App Developer",
    value: "mobile_app_developer",
  },
  {
    name: "IT Support Technician",
    value: "it_support_technician",
  },
  {
    name: "DevOps Engineer",
    value: "devops_engineer",
  },
  // Add more job titles as needed
];

export const jobWorkSkillsDropList = [
  {
    name: "Account Management",
    value: "account_management",
  },
  {
    name: "Accounting",
    value: "accounting",
  },
  {
    name: "Accounts Payable",
    value: "accounts_payable",
  },
  {
    name: "Accounts Receivable",
    value: "accounts_receivable",
  },
  {
    name: "Analysis",
    value: "analysis",
  },
  {
    name: "Analytical",
    value: "analytical",
  },
  {
    name: "Analytical Skills",
    value: "analytical_skills",
  },
  {
    name: "Analytics",
    value: "analytics",
  },
  {
    name: "Analyze Data",
    value: "analyze_data",
  },
  {
    name: "Analyzing Data",
    value: "analyzing_data",
  },
  {
    name: "Annual Budget",
    value: "annual_budget",
  },
  {
    name: "Asset Management",
    value: "asset_management",
  },
  {
    name: "Audit",
    value: "audit",
  },
  {
    name: "Auditing",
    value: "auditing",
  },
  {
    name: "Banking",
    value: "banking",
  },
  {
    name: "Billing",
    value: "billing",
  },
  {
    name: "Budget Management",
    value: "budget_management",
  },
  {
    name: "Budgeting",
    value: "budgeting",
  },
  {
    name: "Business Administration",
    value: "business_administration",
  },
  {
    name: "Business Cases",
    value: "business_cases",
  },
  {
    name: "Business Issues",
    value: "business_issues",
  },
  {
    name: "Business Management",
    value: "business_management",
  },
  {
    name: "Business Planning",
    value: "business_planning",
  },
  {
    name: "Business Plans",
    value: "business_plans",
  },
  {
    name: "Business Process",
    value: "business_process",
  },
  {
    name: "Business Requirements",
    value: "business_requirements",
  },
  {
    name: "Business Stakeholders",
    value: "business_stakeholders",
  },
  {
    name: "Business Strategy",
    value: "business_strategy",
  },
  {
    name: "Business Systems",
    value: "business_systems",
  },
  {
    name: "Cad",
    value: "cad",
  },
  {
    name: "Cash Flow",
    value: "cash_flow",
  },
  {
    name: "Cfa",
    value: "cfa",
  },
  {
    name: "Client Relationships",
    value: "client_relationships",
  },
  {
    name: "Client Service",
    value: "client_service",
  },
  {
    name: "Client Services",
    value: "client_services",
  },
  {
    name: "Compliance",
    value: "compliance",
  },
  {
    name: "Consulting Experience",
    value: "consulting_experience",
  },
  {
    name: "Consulting Services",
    value: "consulting_services",
  },
  {
    name: "Consumers",
    value: "consumers",
  },
  {
    name: "Contracts",
    value: "contracts",
  },
  {
    name: "Co-Op",
    value: "co_op",
  },
  {
    name: "Cost Effective",
    value: "cost_effective",
  },
  {
    name: "Cost Reduction",
    value: "cost_reduction",
  },
  {
    name: "Data Analysis",
    value: "data_analysis",
  },
  {
    name: "Data Collection",
    value: "data_collection",
  },
  {
    name: "Data Management",
    value: "data_management",
  },
  {
    name: "Data Quality",
    value: "data_quality",
  },
  {
    name: "Database",
    value: "database",
  },
  {
    name: "Deposits",
    value: "deposits",
  },
  {
    name: "Due Diligence",
    value: "due_diligence",
  },
  {
    name: "Dynamic Environment",
    value: "dynamic_environment",
  },
  {
    name: "Economics",
    value: "economics",
  },
  {
    name: "Emea",
    value: "emea",
  },
  {
    name: "Expenses",
    value: "expenses",
  },
  {
    name: "External Partners",
    value: "external_partners",
  },
  {
    name: "Finance",
    value: "finance",
  },
  {
    name: "Financial Analysis",
    value: "financial_analysis",
  },
  {
    name: "Financial Management",
    value: "financial_management",
  },
  {
    name: "Financial Performance",
    value: "financial_performance",
  },
  {
    name: "Financial Reporting",
    value: "financial_reporting",
  },
  {
    name: "Financial Reports",
    value: "financial_reports",
  },
  {
    name: "Financial Services",
    value: "financial_services",
  },
  {
    name: "Financial Statements",
    value: "financial_statements",
  },
  {
    name: "Financing",
    value: "financing",
  },
  {
    name: "Forecasting",
    value: "forecasting",
  },
  {
    name: "Forecasts",
    value: "forecasts",
  },
  {
    name: "Gaap",
    value: "gaap",
  },
  {
    name: "General Ledger",
    value: "general_ledger",
  },
  {
    name: "Governance",
    value: "governance",
  },
  {
    name: "Internal Audit",
    value: "internal_audit",
  },
  {
    name: "Internal Controls",
    value: "internal_controls",
  },
  {
    name: "Internal Customers",
    value: "internal_customers",
  },
  {
    name: "Internal Stakeholders",
    value: "internal_stakeholders",
  },
  {
    name: "International",
    value: "international",
  },
  {
    name: "Inventory",
    value: "inventory",
  },
  {
    name: "Inventory Management",
    value: "inventory_management",
  },
  {
    name: "Investigate",
    value: "investigate",
  },
  {
    name: "Investigation",
    value: "investigation",
  },
  {
    name: "Investigations",
    value: "investigations",
  },
  {
    name: "Invoices",
    value: "invoices",
  },
  {
    name: "Invoicing",
    value: "invoicing",
  },
  {
    name: "Journal Entries",
    value: "journal_entries",
  },
  {
    name: "Law Enforcement",
    value: "law_enforcement",
  },
  {
    name: "Leadership Development",
    value: "leadership_development",
  },
  {
    name: "Lean",
    value: "lean",
  },
  {
    name: "Legal",
    value: "legal",
  },
  {
    name: "Legislation",
    value: "legislation",
  },
  {
    name: "Litigation",
    value: "litigation",
  },
  {
    name: "Machine Learning",
    value: "machine_learning",
  },
  {
    name: "Mis",
    value: "mis",
  },
  {
    name: "Mortgage",
    value: "mortgage",
  },
  {
    name: "Non-Profit",
    value: "non_profit",
  },
  {
    name: "Partnership",
    value: "partnership",
  },
  {
    name: "Partnerships",
    value: "partnerships",
  },
  {
    name: "Payments",
    value: "payments",
  },
  {
    name: "Payroll",
    value: "payroll",
  },
  {
    name: "Physical Security",
    value: "physical_security",
  },
  {
    name: "Pmp",
    value: "pmp",
  },
  {
    name: "Policies",
    value: "policies",
  },
  {
    name: "Portfolio Management",
    value: "portfolio_management",
  },
  {
    name: "Presentations",
    value: "presentations",
  },
  {
    name: "Process Improvement",
    value: "process_improvement",
  },
  {
    name: "Project Planning",
    value: "project_planning",
  },
  {
    name: "Proposal",
    value: "proposal",
  },
  {
    name: "Prospecting",
    value: "prospecting",
  },
  {
    name: "Purchase Orders",
    value: "purchase_orders",
  },
  {
    name: "Purchasing",
    value: "purchasing",
  },
  {
    name: "Real Estate",
    value: "real_estate",
  },
  {
    name: "Reconcile",
    value: "reconcile",
  },
  {
    name: "Reconciliation",
    value: "reconciliation",
  },
  {
    name: "Regulations",
    value: "regulations",
  },
  {
    name: "Relationship Management",
    value: "relationship_management",
  },
  {
    name: "Reporting",
    value: "reporting",
  },
  {
    name: "Revenue Growth",
    value: "revenue_growth",
  },
  {
    name: "Rfp",
    value: "rfp",
  },
  {
    name: "Rfps",
    value: "rfps",
  },
  {
    name: "Risk Assessment",
    value: "risk_assessment",
  },
  {
    name: "Risk Assessments",
    value: "risk_assessments",
  },
  {
    name: "Risk Management",
    value: "risk_management",
  },
  {
    name: "Security Clearance",
    value: "security_clearance",
  },
  {
    name: "Small Business",
    value: "small_business",
  },
  {
    name: "Spreadsheets",
    value: "spreadsheets",
  },
  {
    name: "Statistical Analysis",
    value: "statistical_analysis",
  },
  {
    name: "Statistics",
    value: "statistics",
  },
  {
    name: "Strategic Direction",
    value: "strategic_direction",
  },
  {
    name: "Strategic Initiatives",
    value: "strategic_initiatives",
  },
  {
    name: "Strategic Planning",
    value: "strategic_planning",
  },
  {
    name: "Strategic Plans",
    value: "strategic_plans",
  },
  {
    name: "Strategy",
    value: "strategy",
  },
  {
    name: "Tableau",
    value: "tableau",
  },
  {
    name: "Tax",
    value: "tax",
  },
  {
    name: "Technical Knowledge",
    value: "technical_knowledge",
  },
  {
    name: "Transactions",
    value: "transactions",
  },
  {
    name: "Underwriting",
    value: "underwriting",
  },
  {
    name: "Vendor Management",
    value: "vendor_management",
  },
  {
    name: "Vendors",
    value: "vendors",
  },
  {
    name: "Beverage",
    value: "beverage",
  },
  {
    name: "Billing",
    value: "billing",
  },
  {
    name: "Build Relationships",
    value: "build_relationships",
  },
  {
    name: "Client Relationships",
    value: "client_relationships",
  },
  {
    name: "Client Service",
    value: "client_service",
  },
  {
    name: "Consumers",
    value: "consumers",
  },
  {
    name: "Customer Experience",
    value: "customer_experience",
  },
  {
    name: "Customer-Facing",
    value: "customer_facing",
  },
  {
    name: "Customer Requirements",
    value: "customer_requirements",
  },
  {
    name: "Customer Service",
    value: "customer_service",
  },
  {
    name: "Customer-Facing",
    value: "customer_facing",
  },
  {
    name: "Employee Relations",
    value: "employee_relations",
  },
  {
    name: "Filing",
    value: "filing",
  },
  {
    name: "Help Desk",
    value: "help_desk",
  },
  {
    name: "Hospitality",
    value: "hospitality",
  },
  {
    name: "Hotel",
    value: "hotel",
  },
  {
    name: "Hotels",
    value: "hotels",
  },
  {
    name: "Human Resource",
    value: "human_resource",
  },
  {
    name: "Human Resources",
    value: "human_resources",
  },
  {
    name: "Immigration",
    value: "immigration",
  },
  {
    name: "In-Store",
    value: "in_store",
  },
  {
    name: "Internship",
    value: "internship",
  },
  {
    name: "Media Relations",
    value: "media_relations",
  },
  {
    name: "Office Software",
    value: "office_software",
  },
  {
    name: "On-Boarding",
    value: "on_boarding",
  },
  {
    name: "Outreach",
    value: "outreach",
  },
  {
    name: "Peoplesoft",
    value: "peoplesoft",
  },
  {
    name: "Performance Metrics",
    value: "performance_metrics",
  },
  {
    name: "Phone Calls",
    value: "phone_calls",
  },
  {
    name: "Retail",
    value: "retail",
  },
  {
    name: "Sales",
    value: "sales",
  },
  {
    name: "Sales Experience",
    value: "sales_experience",
  },
  {
    name: "Sales Goals",
    value: "sales_goals",
  },
  {
    name: "Sales Operations",
    value: "sales_operations",
  },
  {
    name: "Salesforce",
    value: "salesforce",
  },
  {
    name: "Staffing",
    value: "staffing",
  },
  {
    name: "Supervising",
    value: "supervising",
  },
  {
    name: "Supervisory Experience",
    value: "supervisory_experience",
  },
  {
    name: "Support Services",
    value: "support_services",
  },
  {
    name: "Talent Acquisition",
    value: "talent_acquisition",
  },
  {
    name: "Talent Management",
    value: "talent_management",
  },
  {
    name: "Travel",
    value: "travel",
  },
  {
    name: "Travel Arrangements",
    value: "travel_arrangements",
  },
  {
    name: "Administrative Support",
    value: "administrative_support",
  },
  {
    name: "Admissions",
    value: "admissions",
  },
  {
    name: "Biology",
    value: "biology",
  },
  {
    name: "Chemistry",
    value: "chemistry",
  },
  {
    name: "Coaching",
    value: "coaching",
  },
  {
    name: "Computer Science",
    value: "computer_science",
  },
  {
    name: "Computer Software",
    value: "computer_software",
  },
  {
    name: "Counsel",
    value: "counsel",
  },
  {
    name: "Counseling",
    value: "counseling",
  },
  {
    name: "Fundraising",
    value: "fundraising",
  },
  {
    name: "German",
    value: "german",
  },
  {
    name: "Higher Education",
    value: "higher_education",
  },
  {
    name: "Mathematics",
    value: "mathematics",
  },
  {
    name: "Physics",
    value: "physics",
  },
  {
    name: "Program Development",
    value: "program_development",
  },
  {
    name: "Public Relations",
    value: "public_relations",
  },
  {
    name: "Recruiting",
    value: "recruiting",
  },
  {
    name: "Recruitment",
    value: "recruitment",
  },
  {
    name: "Relationship Building",
    value: "relationship_building",
  },
  {
    name: "Research Projects",
    value: "research_projects",
  },
  {
    name: "Researching",
    value: "researching",
  },
  {
    name: "Spelling",
    value: "spelling",
  },
  {
    name: "Sports",
    value: "sports",
  },
  {
    name: "Teaching",
    value: "teaching",
  },
  {
    name: "Training",
    value: "training",
  },
  {
    name: "Writing",
    value: "writing",
  },
  {
    name: "Case Management",
    value: "case_management",
  },
  {
    name: "Cpr",
    value: "cpr",
  },
  {
    name: "Fda",
    value: "fda",
  },
  {
    name: "Fitness",
    value: "fitness",
  },
  {
    name: "Health",
    value: "health",
  },
  {
    name: "Healthcare",
    value: "healthcare",
  },
  {
    name: "Hospital",
    value: "hospital",
  },
  {
    name: "Logistics",
    value: "logistics",
  },
  {
    name: "Medical Device",
    value: "medical_device",
  },
  {
    name: "Nursing",
    value: "nursing",
  },
  {
    name: "On-Call",
    value: "on_call",
  },
  {
    name: "Ordering",
    value: "ordering",
  },
  {
    name: "Pharmaceutical",
    value: "pharmaceutical",
  },
  {
    name: "Pharmacy",
    value: "pharmacy",
  },
  {
    name: "Presentation",
    value: "presentation",
  },
  {
    name: "Psychology",
    value: "psychology",
  },
  {
    name: "Public Health",
    value: "public_health",
  },
  {
    name: "Public Policy",
    value: "public_policy",
  },
  {
    name: "Safety",
    value: "safety",
  },
  {
    name: "Therapeutic",
    value: "therapeutic",
  },
  {
    name: ".Net",
    value: ".net",
  },
  {
    name: "Algorithms",
    value: "algorithms",
  },
  {
    name: "Android",
    value: "android",
  },
  {
    name: "Architecture",
    value: "architecture",
  },
  {
    name: "Architectures",
    value: "architectures",
  },
  {
    name: "Audio",
    value: "audio",
  },
  {
    name: "Autocad",
    value: "autocad",
  },
  {
    name: "Aws",
    value: "aws",
  },
  {
    name: "Big Data",
    value: "big_data",
  },
  {
    name: "Business Analysis",
    value: "business_analysis",
  },
  {
    name: "Business Continuity",
    value: "business_continuity",
  },
  {
    name: "C (Programming Language)",
    value: "c_(programming_language)",
  },
  {
    name: "C#",
    value: "c#",
  },
  {
    name: "C++",
    value: "c++",
  },
  {
    name: "Cad",
    value: "cad",
  },
  {
    name: "Certification",
    value: "certification",
  },
  {
    name: "Cisco",
    value: "cisco",
  },
  {
    name: "Cloud",
    value: "cloud",
  },
  {
    name: "Compliance",
    value: "compliance",
  },
  {
    name: "Computer Applications",
    value: "computer_applications",
  },
  {
    name: "Computer Science",
    value: "computer_science",
  },
  {
    name: "Controls",
    value: "controls",
  },
  {
    name: "Css",
    value: "css",
  },
  {
    name: "D (Programming Language)",
    value: "d_(programming_language)",
  },
  {
    name: "Data Center",
    value: "data_center",
  },
  {
    name: "Data Collection",
    value: "data_collection",
  },
  {
    name: "Data Entry",
    value: "data_entry",
  },
  {
    name: "Data Management",
    value: "data_management",
  },
  {
    name: "Database",
    value: "database",
  },
  {
    name: "Datasets",
    value: "datasets",
  },
  {
    name: "Design",
    value: "design",
  },
  {
    name: "Development Activities",
    value: "development_activities",
  },
  {
    name: "Digital Marketing",
    value: "digital_marketing",
  },
  {
    name: "Digital Media",
    value: "digital_media",
  },
  {
    name: "Distribution",
    value: "distribution",
  },
  {
    name: "Dns",
    value: "dns",
  },
  {
    name: "Ecommerce",
    value: "ecommerce",
  },
  {
    name: "E-Commerce",
    value: "e_commerce",
  },
  {
    name: "End User",
    value: "end_user",
  },
  {
    name: "Experimental",
    value: "experimental",
  },
  {
    name: "Experiments",
    value: "experiments",
  },
  {
    name: "Frameworks",
    value: "frameworks",
  },
  {
    name: "Front-End",
    value: "front_end",
  },
  {
    name: "Gis",
    value: "gis",
  },
  {
    name: "Graphic Design",
    value: "graphic_design",
  },
  {
    name: "Hardware",
    value: "hardware",
  },
  {
    name: "Html5",
    value: "html5",
  },
  {
    name: "I-Deas",
    value: "i_deas",
  },
  {
    name: "Information Management",
    value: "information_management",
  },
  {
    name: "Information Security",
    value: "information_security",
  },
  {
    name: "Information Technology",
    value: "information_technology",
  },
  {
    name: "Intranet",
    value: "intranet",
  },
  {
    name: "Ios",
    value: "ios",
  },
  {
    name: "Iphone",
    value: "iphone",
  },
  {
    name: "It Infrastructure",
    value: "it_infrastructure",
  },
  {
    name: "Itil",
    value: "itil",
  },
  {
    name: "Java",
    value: "java",
  },
  {
    name: "Javascript",
    value: "javascript",
  },
  {
    name: "Jira",
    value: "jira",
  },
  {
    name: "Lan",
    value: "lan",
  },
  {
    name: "Licensing",
    value: "licensing",
  },
  {
    name: "Linux",
    value: "linux",
  },
  {
    name: "Machine Learning",
    value: "machine_learning",
  },
  {
    name: "Matlab",
    value: "matlab",
  },
  {
    name: "Matrix",
    value: "matrix",
  },
  {
    name: "Mechanical Engineering",
    value: "mechanical_engineering",
  },
  {
    name: "Migration",
    value: "migration",
  },
  {
    name: "Mobile",
    value: "mobile",
  },
  {
    name: "Modeling",
    value: "modeling",
  },
  {
    name: "Networking",
    value: "networking",
  },
  {
    name: "Operations Management",
    value: "operations_management",
  },
  {
    name: "Oracle",
    value: "oracle",
  },
  {
    name: "Os",
    value: "os",
  },
  {
    name: "Process Development",
    value: "process_development",
  },
  {
    name: "Process Improvement",
    value: "process_improvement",
  },
  {
    name: "Process Improvements",
    value: "process_improvements",
  },
  {
    name: "Product Design",
    value: "product_design",
  },
  {
    name: "Product Development",
    value: "product_development",
  },
  {
    name: "Product Knowledge",
    value: "product_knowledge",
  },
  {
    name: "Program Management",
    value: "program_management",
  },
  {
    name: "Programming",
    value: "programming",
  },
  {
    name: "Protocols",
    value: "protocols",
  },
  {
    name: "Prototype",
    value: "prototype",
  },
  {
    name: "Python",
    value: "python",
  },
  {
    name: "Quality Assurance",
    value: "quality_assurance",
  },
  {
    name: "Real-Time",
    value: "real_time",
  },
  {
    name: "Research",
    value: "research",
  },
  {
    name: "Resource Management",
    value: "resource_management",
  },
  {
    name: "Root Cause",
    value: "root_cause",
  },
  {
    name: "Routing",
    value: "routing",
  },
  {
    name: "Saas",
    value: "saas",
  },
  {
    name: "Sas",
    value: "sas",
  },
  {
    name: "Sci",
    value: "sci",
  },
  {
    name: "Scripting",
    value: "scripting",
  },
  {
    name: "Scrum",
    value: "scrum",
  },
  {
    name: "Sdlc",
    value: "sdlc",
  },
  {
    name: "Seo",
    value: "seo",
  },
  {
    name: "Service Delivery",
    value: "service_delivery",
  },
  {
    name: "Software Development",
    value: "software_development",
  },
  {
    name: "Software Development Life Cycle",
    value: "software_development_life_cycle",
  },
  {
    name: "Software Engineering",
    value: "software_engineering",
  },
  {
    name: "Sql",
    value: "sql",
  },
  {
    name: "Sql Server",
    value: "sql_server",
  },
  {
    name: "Tablets",
    value: "tablets",
  },
  {
    name: "Technical",
    value: "technical",
  },
  {
    name: "Technical Issues",
    value: "technical_issues",
  },
  {
    name: "Technical Knowledge",
    value: "technical_knowledge",
  },
  {
    name: "Technical Skills",
    value: "technical_skills",
  },
  {
    name: "Technical Support",
    value: "technical_support",
  },
  {
    name: "Test Cases",
    value: "test_cases",
  },
  {
    name: "Test Plans",
    value: "test_plans",
  },
  {
    name: "Testing",
    value: "testing",
  },
  {
    name: "Troubleshooting",
    value: "troubleshooting",
  },
  {
    name: "Ui",
    value: "ui",
  },
  {
    name: "Unix",
    value: "unix",
  },
  {
    name: "Usability",
    value: "usability",
  },
  {
    name: "User Experience",
    value: "user_experience",
  },
  {
    name: "Ux",
    value: "ux",
  },
  {
    name: "Variances",
    value: "variances",
  },
  {
    name: "Vendor Management",
    value: "vendor_management",
  },
  {
    name: "Vmware",
    value: "vmware",
  },
  {
    name: "Web Services",
    value: "web_services",
  },
  {
    name: "Workflows",
    value: "workflows",
  },
  {
    name: "Assembly",
    value: "assembly",
  },
  {
    name: "Asset Management",
    value: "asset_management",
  },
  {
    name: "Audit",
    value: "audit",
  },
  {
    name: "Auditing",
    value: "auditing",
  },
  {
    name: "Automation",
    value: "automation",
  },
  {
    name: "Aviation",
    value: "aviation",
  },
  {
    name: "Budget",
    value: "budget",
  },
  {
    name: "Budget Management",
    value: "budget_management",
  },
  {
    name: "Budgeting",
    value: "budgeting",
  },
  {
    name: "Chemicals",
    value: "chemicals",
  },
  {
    name: "Circuits",
    value: "circuits",
  },
  {
    name: "Construction",
    value: "construction",
  },
  {
    name: "Cost Reduction",
    value: "cost_reduction",
  },
  {
    name: "Cross-Functional Team",
    value: "cross_functional_team",
  },
  {
    name: "Distribution",
    value: "distribution",
  },
  {
    name: "Documentation",
    value: "documentation",
  },
  {
    name: "Documenting",
    value: "documenting",
  },
  {
    name: "Drafting",
    value: "drafting",
  },
  {
    name: "Drawings",
    value: "drawings",
  },
  {
    name: "Driving Record",
    value: "driving_record",
  },
  {
    name: "Electrical",
    value: "electrical",
  },
  {
    name: "Electrical Engineering",
    value: "electrical_engineering",
  },
  {
    name: "Electronics",
    value: "electronics",
  },
  {
    name: "Engineering",
    value: "engineering",
  },
  {
    name: "Fabrication",
    value: "fabrication",
  },
  {
    name: "Installation",
    value: "installation",
  },
  {
    name: "Instructional Design",
    value: "instructional_design",
  },
  {
    name: "Instrumentation",
    value: "instrumentation",
  },
  {
    name: "Inventory",
    value: "inventory",
  },
  {
    name: "Inventory Management",
    value: "inventory_management",
  },
  {
    name: "Iso",
    value: "iso",
  },
  {
    name: "Life Cycle",
    value: "life_cycle",
  },
  {
    name: "Lifecycle",
    value: "lifecycle",
  },
  {
    name: "Manage Projects",
    value: "manage_projects",
  },
  {
    name: "Management Experience",
    value: "management_experience",
  },
  {
    name: "Mining",
    value: "mining",
  },
  {
    name: "Operations",
    value: "operations",
  },
  {
    name: "Operations Management",
    value: "operations_management",
  },
  {
    name: "Outsourcing",
    value: "outsourcing",
  },
  {
    name: "Performance Improvement",
    value: "performance_improvement",
  },
  {
    name: "Process Improvement",
    value: "process_improvement",
  },
  {
    name: "Process Improvements",
    value: "process_improvements",
  },
  {
    name: "Procurement",
    value: "procurement",
  },
  {
    name: "Product Design",
    value: "product_design",
  },
  {
    name: "Product Development",
    value: "product_development",
  },
  {
    name: "Product Knowledge",
    value: "product_knowledge",
  },
  {
    name: "Product Line",
    value: "product_line",
  },
  {
    name: "Product Marketing",
    value: "product_marketing",
  },
  {
    name: "Product Quality",
    value: "product_quality",
  },
  {
    name: "Project Delivery",
    value: "project_delivery",
  },
  {
    name: "Project Management",
    value: "project_management",
  },
  {
    name: "Project Management Skills",
    value: "project_management_skills",
  },
  {
    name: "Project Plan",
    value: "project_plan",
  },
  {
    name: "Qa",
    value: "qa",
  },
  {
    name: "Quality Assurance",
    value: "quality_assurance",
  },
  {
    name: "Quality Control",
    value: "quality_control",
  },
  {
    name: "Quality Management",
    value: "quality_management",
  },
  {
    name: "Quality Standards",
    value: "quality_standards",
  },
  {
    name: "Raw Materials",
    value: "raw_materials",
  },
  {
    name: "Regulatory",
    value: "regulatory",
  },
  {
    name: "Regulatory Compliance",
    value: "regulatory_compliance",
  },
  {
    name: "Regulatory Requirements",
    value: "regulatory_requirements",
  },
  {
    name: "Repairs",
    value: "repairs",
  },
  {
    name: "Safety",
    value: "safety",
  },
  {
    name: "Six Sigma",
    value: "six_sigma",
  },
  {
    name: "Solidworks",
    value: "solidworks",
  },
  {
    name: "Sourcing",
    value: "sourcing",
  },
  {
    name: "Specifications",
    value: "specifications",
  },
  {
    name: "Standard Operating Procedures",
    value: "standard_operating_procedures",
  },
  {
    name: "Supply Chain",
    value: "supply_chain",
  },
  {
    name: "Supply Chain Management",
    value: "supply_chain_management",
  },
  {
    name: "Transport",
    value: "transport",
  },
  {
    name: "Transportation",
    value: "transportation",
  },
  {
    name: "Valid Drivers License",
    value: "valid_drivers_license",
  },
  {
    name: "Vendor Management",
    value: "vendor_management",
  },
  {
    name: "Warehouse",
    value: "warehouse",
  },
  {
    name: "Workflows",
    value: "workflows",
  },
  {
    name: "Account Management",
    value: "account_management",
  },
  {
    name: "Acquisition",
    value: "acquisition",
  },
  {
    name: "Acquisitions",
    value: "acquisitions",
  },
  {
    name: "Administrative Support",
    value: "administrative_support",
  },
  {
    name: "Adobe",
    value: "adobe",
  },
  {
    name: "Adobe Creative Suite",
    value: "adobe_creative_suite",
  },
  {
    name: "Advertising",
    value: "advertising",
  },
  {
    name: "Affiliate",
    value: "affiliate",
  },
  {
    name: "Agile",
    value: "agile",
  },
  {
    name: "Algorithms",
    value: "algorithms",
  },
  {
    name: "Alliances",
    value: "alliances",
  },
  {
    name: "Analytical Skills",
    value: "analytical_skills",
  },
  {
    name: "Analytics",
    value: "analytics",
  },
  {
    name: "Analyze Data",
    value: "analyze_data",
  },
  {
    name: "Analyzing Data",
    value: "analyzing_data",
  },
  {
    name: "Api",
    value: "api",
  },
  {
    name: "Apis",
    value: "apis",
  },
  {
    name: "Automation",
    value: "automation",
  },
  {
    name: "Benchmark",
    value: "benchmark",
  },
  {
    name: "Bi",
    value: "bi",
  },
  {
    name: "Brand",
    value: "brand",
  },
  {
    name: "Branding",
    value: "branding",
  },
  {
    name: "Broadcast",
    value: "broadcast",
  },
  {
    name: "Budgeting",
    value: "budgeting",
  },
  {
    name: "Build Relationships",
    value: "build_relationships",
  },
  {
    name: "Business Analysis",
    value: "business_analysis",
  },
  {
    name: "Business Continuity",
    value: "business_continuity",
  },
  {
    name: "Business Development",
    value: "business_development",
  },
  {
    name: "Business Intelligence",
    value: "business_intelligence",
  },
  {
    name: "Business Planning",
    value: "business_planning",
  },
  {
    name: "Business Process",
    value: "business_process",
  },
  {
    name: "Business Strategy",
    value: "business_strategy",
  },
  {
    name: "Business Systems",
    value: "business_systems",
  },
  {
    name: "Case Management",
    value: "case_management",
  },
  {
    name: "Change Management",
    value: "change_management",
  },
  {
    name: "Client Relationships",
    value: "client_relationships",
  },
  {
    name: "Client Services",
    value: "client_services",
  },
  {
    name: "Cms",
    value: "cms",
  },
  {
    name: "Coding",
    value: "coding",
  },
  {
    name: "Commissioning",
    value: "commissioning",
  },
  {
    name: "Complex Projects",
    value: "complex_projects",
  },
  {
    name: "Consulting",
    value: "consulting",
  },
  {
    name: "Content",
    value: "content",
  },
  {
    name: "Continuous Improvement",
    value: "continuous_improvement",
  },
  {
    name: "Contract Management",
    value: "contract_management",
  },
  {
    name: "Conversion",
    value: "conversion",
  },
  {
    name: "Correspondence",
    value: "correspondence",
  },
  {
    name: "Counseling",
    value: "counseling",
  },
  {
    name: "Cpg",
    value: "cpg",
  },
  {
    name: "Crm",
    value: "crm",
  },
  {
    name: "Cross-Functional Team",
    value: "cross_functional_team",
  },
  {
    name: "Customer Experience",
    value: "customer_experience",
  },
  {
    name: "Daily Operations",
    value: "daily_operations",
  },
  {
    name: "Data Analysis",
    value: "data_analysis",
  },
  {
    name: "Data Collection",
    value: "data_collection",
  },
  {
    name: "Data Management",
    value: "data_management",
  },
  {
    name: "Data Quality",
    value: "data_quality",
  },
  {
    name: "Digital Marketing",
    value: "digital_marketing",
  },
  {
    name: "Digital Media",
    value: "digital_media",
  },
  {
    name: "Ecommerce",
    value: "ecommerce",
  },
  {
    name: "E-Commerce",
    value: "e_commerce",
  },
  {
    name: "Editing",
    value: "editing",
  },
  {
    name: "Editorial",
    value: "editorial",
  },
  {
    name: "Employee Engagement",
    value: "employee_engagement",
  },
  {
    name: "Engagement",
    value: "engagement",
  },
  {
    name: "Erp",
    value: "erp",
  },
  {
    name: "Etl",
    value: "etl",
  },
  {
    name: "Event Planning",
    value: "event_planning",
  },
  {
    name: "Facebook",
    value: "facebook",
  },
  {
    name: "Fashion",
    value: "fashion",
  },
  {
    name: "Field Sales",
    value: "field_sales",
  },
  {
    name: "Flex",
    value: "flex",
  },
  {
    name: "Fulfillment",
    value: "fulfillment",
  },
  {
    name: "Hris",
    value: "hris",
  },
  {
    name: "Html",
    value: "html",
  },
  {
    name: "Ibm",
    value: "ibm",
  },
  {
    name: "Indesign",
    value: "indesign",
  },
  {
    name: "Industry Experience",
    value: "industry_experience",
  },
  {
    name: "Industry Trends",
    value: "industry_trends",
  },
  {
    name: "Information System",
    value: "information_system",
  },
  {
    name: "Information Systems",
    value: "information_systems",
  },
  {
    name: "Internal Communications",
    value: "internal_communications",
  },
  {
    name: "Inventory Management",
    value: "inventory_management",
  },
  {
    name: "Jira",
    value: "jira",
  },
  {
    name: "Journalism",
    value: "journalism",
  },
  {
    name: "Key Performance Indicators",
    value: "key_performance_indicators",
  },
  {
    name: "Kpi",
    value: "kpi",
  },
  {
    name: "Kpis",
    value: "kpis",
  },
  {
    name: "Lighting",
    value: "lighting",
  },
  {
    name: "Machine Learning",
    value: "machine_learning",
  },
  {
    name: "Management Consulting",
    value: "management_consulting",
  },
  {
    name: "Market Research",
    value: "market_research",
  },
  {
    name: "Marketing",
    value: "marketing",
  },
  {
    name: "Marketing Materials",
    value: "marketing_materials",
  },
  {
    name: "Marketing Plans",
    value: "marketing_plans",
  },
  {
    name: "Marketing Programs",
    value: "marketing_programs",
  },
  {
    name: "Marketing Strategy",
    value: "marketing_strategy",
  },
  {
    name: "Merchandising",
    value: "merchandising",
  },
  {
    name: "Metrics",
    value: "metrics",
  },
  {
    name: "Microsoft Office",
    value: "microsoft_office",
  },
  {
    name: "Microsoft Office Suite",
    value: "microsoft_office_suite",
  },
  {
    name: "Microsoft Word",
    value: "microsoft_word",
  },
  {
    name: "Ms Excel",
    value: "ms_excel",
  },
  {
    name: "Ms Office",
    value: "ms_office",
  },
  {
    name: "Ms Project",
    value: "ms_project",
  },
  {
    name: "Negotiation",
    value: "negotiation",
  },
  {
    name: "Networking",
    value: "networking",
  },
  {
    name: "Operating Systems",
    value: "operating_systems",
  },
  {
    name: "Operational Excellence",
    value: "operational_excellence",
  },
  {
    name: "Performance Management",
    value: "performance_management",
  },
  {
    name: "Photography",
    value: "photography",
  },
  {
    name: "Photoshop",
    value: "photoshop",
  },
  {
    name: "Portfolio Management",
    value: "portfolio_management",
  },
  {
    name: "Positioning",
    value: "positioning",
  },
  {
    name: "Pr",
    value: "pr",
  },
  {
    name: "Process Development",
    value: "process_development",
  },
  {
    name: "Process Improvement",
    value: "process_improvement",
  },
  {
    name: "Process Improvements",
    value: "process_improvements",
  },
  {
    name: "Product Management",
    value: "product_management",
  },
  {
    name: "Product Marketing",
    value: "product_marketing",
  },
  {
    name: "Product Quality",
    value: "product_quality",
  },
  {
    name: "Project Delivery",
    value: "project_delivery",
  },
  {
    name: "Project Management",
    value: "project_management",
  },
  {
    name: "Public Relations",
    value: "public_relations",
  },
  {
    name: "Publications",
    value: "publications",
  },
  {
    name: "Publishing",
    value: "publishing",
  },
  {
    name: "Quality Assurance",
    value: "quality_assurance",
  },
  {
    name: "R (Programming Language)",
    value: "r_(programming_language)",
  },
  {
    name: "Recruit",
    value: "recruit",
  },
  {
    name: "Regulatory Compliance",
    value: "regulatory_compliance",
  },
  {
    name: "Relationship Building",
    value: "relationship_building",
  },
  {
    name: "Reporting",
    value: "reporting",
  },
  {
    name: "Resource Management",
    value: "resource_management",
  },
  {
    name: "Retention",
    value: "retention",
  },
  {
    name: "Sales Management",
    value: "sales_management",
  },
  {
    name: "Sap",
    value: "sap",
  },
  {
    name: "Scheduling",
    value: "scheduling",
  },
  {
    name: "Scrum",
    value: "scrum",
  },
  {
    name: "Sdlc",
    value: "sdlc",
  },
  {
    name: "Segmentation",
    value: "segmentation",
  },
  {
    name: "Sharepoint",
    value: "sharepoint",
  },
  {
    name: "Six Sigma",
    value: "six_sigma",
  },
  {
    name: "Social Media",
    value: "social_media",
  },
  {
    name: "Sops",
    value: "sops",
  },
  {
    name: "Spreadsheets",
    value: "spreadsheets",
  },
  {
    name: "Sql Server",
    value: "sql_server",
  },
  {
    name: "Stakeholder Management",
    value: "stakeholder_management",
  },
  {
    name: "Standardization",
    value: "standardization",
  },
  {
    name: "Startup",
    value: "startup",
  },
  {
    name: "Start-Up",
    value: "start_up",
  },
  {
    name: "Statistical Analysis",
    value: "statistical_analysis",
  },
  {
    name: "Statistics",
    value: "statistics",
  },
  {
    name: "Status Reports",
    value: "status_reports",
  },
  {
    name: "Strategic Direction",
    value: "strategic_direction",
  },
  {
    name: "Strategic Initiatives",
    value: "strategic_initiatives",
  },
  {
    name: "Strategic Planning",
    value: "strategic_planning",
  },
  {
    name: "Strategic Plans",
    value: "strategic_plans",
  },
  {
    name: "Strategy",
    value: "strategy",
  },
  {
    name: "Strong Analytical Skills",
    value: "strong_analytical_skills",
  },
  {
    name: "Telecom",
    value: "telecom",
  },
  {
    name: "Trade Shows",
    value: "trade_shows",
  },
  {
    name: "Tv",
    value: "tv",
  },
  {
    name: "Twitter",
    value: "twitter",
  },
  {
    name: "Value Proposition",
    value: "value_proposition",
  },
  {
    name: "Vendors",
    value: "vendors",
  },
  {
    name: "Video",
    value: "video",
  },
  {
    name: "Windows",
    value: "windows",
  },
  {
    name: "Workflows",
    value: "workflows",
  },
  {
    name: "Active Listening",
    value: "active_listening",
  },
  {
    name: "Storytelling",
    value: "storytelling",
  },
  {
    name: "Written Communication",
    value: "written_communication",
  },
  {
    name: "Editing",
    value: "editing",
  },
  {
    name: "Teaching",
    value: "teaching",
  },
  {
    name: "Negotiating",
    value: "negotiating",
  },
  {
    name: "Open-Mindedness",
    value: "open_mindedness",
  },
  {
    name: "Inquiring",
    value: "inquiring",
  },
  {
    name: "Body Language",
    value: "body_language",
  },
  {
    name: "Customer Service",
    value: "customer_service",
  },
  {
    name: "Presenting",
    value: "presenting",
  },
  {
    name: "Summarizing",
    value: "summarizing",
  },
  {
    name: "Nonverbal Communication",
    value: "nonverbal_communication",
  },
  {
    name: "Documenting",
    value: "documenting",
  },
  {
    name: "Reporting",
    value: "reporting",
  },
  {
    name: "Patience",
    value: "patience",
  },
  {
    name: "Positivity",
    value: "positivity",
  },
  {
    name: "Conflict Management",
    value: "conflict_management",
  },
  {
    name: "Coaching",
    value: "coaching",
  },
  {
    name: "Mediating",
    value: "mediating",
  },
  {
    name: "Motivating",
    value: "motivating",
  },
  {
    name: "Compassion",
    value: "compassion",
  },
  {
    name: "Caring",
    value: "caring",
  },
  {
    name: "Networking",
    value: "networking",
  },
  {
    name: "Inspiring",
    value: "inspiring",
  },
  {
    name: "Flexibility",
    value: "flexibility",
  },
  {
    name: "Collaboration",
    value: "collaboration",
  },
  {
    name: "Sourcing Feedback",
    value: "sourcing_feedback",
  },
  {
    name: "Reliability",
    value: "reliability",
  },
  {
    name: "Empathy",
    value: "empathy",
  },
  {
    name: "Observing",
    value: "observing",
  },
  {
    name: "Problem-Solving",
    value: "problem_solving",
  },
  {
    name: "Inferring",
    value: "inferring",
  },
  {
    name: "Simplifying",
    value: "simplifying",
  },
  {
    name: "Conceptual Thinking",
    value: "conceptual_thinking",
  },
  {
    name: "Evaluating",
    value: "evaluating",
  },
  {
    name: "Streamlining",
    value: "streamlining",
  },
  {
    name: "Creative Thinking",
    value: "creative_thinking",
  },
  {
    name: "Brainstorming",
    value: "brainstorming",
  },
  {
    name: "Cost-Benefit Analyzing",
    value: "cost_benefit_analyzing",
  },
  {
    name: "Deductive Reasoning",
    value: "deductive_reasoning",
  },
  {
    name: "Inductive Reasoning",
    value: "inductive_reasoning",
  },
  {
    name: "Assessing",
    value: "assessing",
  },
  {
    name: "Evidence Collecting",
    value: "evidence_collecting",
  },
  {
    name: "Troubleshooting",
    value: "troubleshooting",
  },
  {
    name: "Mentoring",
    value: "mentoring",
  },
  {
    name: "Envisioning",
    value: "envisioning",
  },
  {
    name: "Goal-Setting",
    value: "goal_setting",
  },
  {
    name: "Employee Development",
    value: "employee_development",
  },
  {
    name: "Performance Reviewing",
    value: "performance_reviewing",
  },
  {
    name: "Managing",
    value: "managing",
  },
  {
    name: "Planning",
    value: "planning",
  },
  {
    name: "Delegating",
    value: "delegating",
  },
  {
    name: "Directing",
    value: "directing",
  },
  {
    name: "Supervising",
    value: "supervising",
  },
  {
    name: "Training",
    value: "training",
  },
  {
    name: "Earning Trust",
    value: "earning_trust",
  },
  {
    name: "Influencing",
    value: "influencing",
  },
  {
    name: "Adapting",
    value: "adapting",
  },
  {
    name: "Crisis Managing",
    value: "crisis_managing",
  },
  {
    name: "Accounting",
    value: "accounting",
  },
  {
    name: "Word Processing",
    value: "word_processing",
  },
  {
    name: "Spreadsheet Building",
    value: "spreadsheet_building",
  },
  {
    name: "Coding",
    value: "coding",
  },
  {
    name: "Programming",
    value: "programming",
  },
  {
    name: "Operating Equipment",
    value: "operating_equipment",
  },
  {
    name: "Engineering",
    value: "engineering",
  },
  {
    name: "Experimenting",
    value: "experimenting",
  },
  {
    name: "Testing",
    value: "testing",
  },
  {
    name: "Constructing",
    value: "constructing",
  },
  {
    name: "Restoring",
    value: "restoring",
  },
  {
    name: "Product Developing",
    value: "product_developing",
  },
  {
    name: "Quality Controlling",
    value: "quality_controlling",
  },
  {
    name: "Blueprint Drafting",
    value: "blueprint_drafting",
  },
  {
    name: "Repairing",
    value: "repairing",
  },
  {
    name: "Translating",
    value: "translating",
  },
  {
    name: "Speaking",
    value: "speaking",
  },
  {
    name: "Writing",
    value: "writing",
  },
  {
    name: "Conversing",
    value: "conversing",
  },
  {
    name: "Reinterpreting",
    value: "reinterpreting",
  },
  {
    name: "Public Speaking",
    value: "public_speaking",
  },
  {
    name: "Following Etiquette",
    value: "following_etiquette",
  },
  {
    name: "Explaining",
    value: "explaining",
  },
  {
    name: "Respecting",
    value: "respecting",
  },
  {
    name: "Signaling",
    value: "signaling",
  },
  {
    name: "Proofreading",
    value: "proofreading",
  },
  {
    name: "Introducing",
    value: "introducing",
  },
  {
    name: "Representing",
    value: "representing",
  },
  {
    name: "Rephrasing",
    value: "rephrasing",
  },
  {
    name: "Code-Switching",
    value: "code_switching",
  },
  {
    name: "Graphic Design",
    value: "graphic_design",
  },
  {
    name: "User Experience Development",
    value: "user_experience_development",
  },
  {
    name: "User Interface Development",
    value: "user_interface_development",
  },
  {
    name: "Typography",
    value: "typography",
  },
  {
    name: "Layout Development",
    value: "layout_development",
  },
  {
    name: "Web Design",
    value: "web_design",
  },
  {
    name: "Data Visualization",
    value: "data_visualization",
  },
  {
    name: "Photo And Video Editing",
    value: "photo_and_video_editing",
  },
  {
    name: "Wireframing",
    value: "wireframing",
  },
  {
    name: "Researching",
    value: "researching",
  },
  {
    name: "Interpreting",
    value: "interpreting",
  },
  {
    name: "Information Processing",
    value: "information_processing",
  },
  {
    name: "Organizing",
    value: "organizing",
  },
  {
    name: "Processing",
    value: "processing",
  },
  {
    name: "Graphing",
    value: "graphing",
  },
  {
    name: "Computing",
    value: "computing",
  },
  {
    name: "Calculating",
    value: "calculating",
  },
  {
    name: "Modeling",
    value: "modeling",
  },
  {
    name: "Extrapolating",
    value: "extrapolating",
  },
  {
    name: "Predicting",
    value: "predicting",
  },
  {
    name: "Forecasting",
    value: "forecasting",
  },
  {
    name: "Investigating",
    value: "investigating",
  },
  {
    name: "Surveying",
    value: "surveying",
  },
  {
    name: "Statistical Analysis",
    value: "statistical_analysis",
  },
];
