import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AssesmentContainer from "../../../containers/AssesmentContainer";
import Assignments from "./AssignmentForm";
const AssignmentsContainer = (props) => {
  const dispatch = useDispatch();
  const [userdata, setFormdata] = useState(props.userData);

  useEffect(() => {
    setFormdata(props.userData);
  }, [props.userData]);

  return (
    <React.Fragment>
      <div className="job-bx">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Assignment/Shifts
          </h5>
          {/* <Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link> */}
        </div>
        <Assignments data={userdata} />
      </div>
    </React.Fragment>
  );
};

export default AssesmentContainer(AssignmentsContainer);
