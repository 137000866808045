import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const MyAssignmentsContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token)
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetMyAssignments','SET_MYASSIGNMENTS'))
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData,
    myassignments:state.myassignments.myassignments
  };
};

const composedMyAssignmentsContainer = compose(connect(mapStateToProps), MyAssignmentsContainer)
export default composedMyAssignmentsContainer;

