import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const AppliedJobsContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token)
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetAppliedJobs','SET_APPLIED_JOBS'))
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData,
    appliedjobs:state.appliedjobs.appliedjobs
  };
};

const composedAppliedJobsContainer = compose(connect(mapStateToProps), AppliedJobsContainer)
export default composedAppliedJobsContainer;

