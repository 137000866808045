import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Spinner } from "react-bootstrap";
import TypesOfServicesInfo from "../../../containers/TypesOfServicesInfo";
import Error from "../../Layout/Error";
import { typeOfServicesData } from "../../../helpers/TypeOfServicesData";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const TypesOfServices = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [typesOfServices, setFormdata] = useState([]);
  const [usersCategory, setUsersCategory] = useState("home care");
  if (
    props.typesOfServices.users_category &&
    props.typesOfServices.users_category != "home care"
  ) {
    document.getElementById("loader").style.display = "block";

    window.location = "/page-not-found";
  }
  const [errors, setErrors] = useState({});
  const { loader } = props;
  let arrayIndexCount = typesOfServices.length;

  const selectValue = (e, index, service) => {
    let isExists = typesOfServices.includes(service);
    if (isExists) {
      let getExistsIndex = typesOfServices.indexOf(service);
      typesOfServices.splice(getExistsIndex, 1);
    } else {
      typesOfServices[arrayIndexCount] = service;
      arrayIndexCount++;
    }
    setFormdata(typesOfServices);
  };

  const updateServices = (e) => {
    e.preventDefault();
    let formdata = {};
    if (typesOfServices.length > 0) {
      document.getElementById("loader").style.display = "block";
      let nonEmptyServices = [];
      let indexCount = 0;
      typesOfServices.map((service, index) => {
        if (service) {
          nonEmptyServices[indexCount] = service;
          indexCount++;
        }
      });
      let implodedValues = nonEmptyServices.join(",");
      formdata.cnd_types_of_services = implodedValues.trim();
      formdata.users_access_token = props.userAuth.users_access_token;
      dispatch(
        updateDataOnRequest(
          formdata,
          "UpdatetypesOfServices",
          "UPDATE_TYPE_OF_SERVICES_DATA",
          getDataOnRequest(
            props.userAuth.users_access_token,
            "PersonInfo",
            "SET_USER_DATA"
          )
        )
      );
    } else {
      setErrors({ cnd_types_of_services: "Please select atleast one value" });
    }
  };

  //history.push('/agreements');
  const checkIsServiceSelect = (prevService) => {
    if (typesOfServices.length > 0) {
      return typesOfServices.includes(prevService);
    }
  };

  useEffect(() => {
    if (props.typesOfServices.cnd_types_of_services) {
      let explodedArray =
        props.typesOfServices.cnd_types_of_services.split(",");
      setFormdata(explodedArray);
    }
  }, [props.typesOfServices]);

  // console.log("All Type of Services Data :=> "+JSON.stringify(props.typesOfServices.users_category));
  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      {/* <EmployementGapForm /> */}
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <h5>Are you able to work with the following types of service users?</h5>
        <form>
          <div className="row m-b20">
            {typeOfServicesData.map((service, index) => (
              <div key={index} className="col-lg-12 col-md-12 m-b0">
                <div className="form-group mb-0">
                  <Form.Check
                    inline
                    label={service}
                    name="cnd_types_of_services"
                    defaultChecked={checkIsServiceSelect(service)}
                    type="checkbox"
                    onClick={(e) => selectValue(e, index, service)}
                    id={`inline-radio-1-${index}`}
                  />
                </div>
              </div>
            ))}
            <Error errors={errors} fieldName={"cnd_types_of_services"} />
          </div>
          <Button
            disabled={loader}
            className="site-button m-b30 pull-right"
            onClick={(e) => updateServices(e)}
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader ? "Save Services" : "Loading..."}
          </Button>
          {/* <button className="site-button m-b30" onClick={(e)=>updateUserProfilePersonalInfo(e)}>Save Setting</button> */}
        </form>
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

export default TypesOfServicesInfo(TypesOfServices);
