import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_job_title",
    type: "sign",
    label: "Sign Terms & Condition",
    md: "8",
    sm: "12",
  },
  {
    name: "clt_job_title",
    type: "checkbox",
    options: [{ id: "1", name: "Accept Terms & Conditions" }],
    md: "12",
    sm: "12",
  },
];

export const validationSchema = Yup.object().shape({
  clt_job_title: Yup.string().required("Job Title Field is Required"),
  clt_job_sector: Yup.string().required("Job Sector is required"),
  clt_job_description: Yup.string()
    .required("Description is required")
    .min(6, "Description must be at least 6 characters")
    .max(40, "Description must not exceed 40 characters"),
  clt_job_pay_rate: Yup.number()
    .typeError("Job Pay Rate must be a valid number")
    .required("Job Pay Rate is required"),
  clt_job_address: Yup.string()
    .required("Job Address is required")
    .min(6, "Job Address must be at least 3 characters")
    .max(40, "Job Address must not exceed 40 characters"),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
