import React from 'react';
import PermanentDashboard from './PermanentDashboard';
import PermanentDashboardSidebar from './PermanentDashboardSidebar';
import PermanentProfilesidebar from './PermanentProfilesidebar';


const PermanentDashboardContainer = (props) => {
	return (
		<React.Fragment>
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								{/* <PermanentProfilesidebar page="job"/> */}
								<PermanentDashboardSidebar page="job"/>
									<div className="col-xl-9 col-lg-8 m-b30 ">
									<PermanentDashboard />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default PermanentDashboardContainer;