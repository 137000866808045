import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadloginInfo } from "./../redux/actions/AuthActions";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import { useHistory } from "react-router-dom";
import { updateDataOnRequest } from "../redux/actions/CommonActions";
import Input from "../components/FormElements/Input";

var bnr = require("./../images/banner/bnr2.jpg");

const NewPassword = (props) => {
  // Add title
  document.getElementById("titleText").innerText = "Staffing Connect - Set New Password";

  let history = useHistory();
  let usersInfo, usersEmail, userOtp;
  usersInfo = JSON.parse(localStorage.getItem("users_email"))
    ? JSON.parse(localStorage.getItem("users_email"))
    : "";
  usersEmail = usersInfo.users_email;

  userOtp = usersInfo.user_otp;

  if (!usersEmail && !userOtp) {
    window.location.href = "/login";
  }
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let errorsObj = { password: "", confirmPassword: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [formData, setFormData] = useState({});
  let users_password, users_confirm_password, users_password_msg, users_confirm_password_msg;
  let applyErrors = false;

  //   const [userType, setUserType] = useState("candidate");
  const dispatch = useDispatch();

  // To Disable The Paste Feature on Screen

  const handleChange = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // formData = {"users_email" :e.target.value};
    //console.log("This Is OTP :=>"+JSON.stringify(formData));
    // Fields Start
    users_password = document.getElementById("users_password");
    users_confirm_password = document.getElementById("users_confirm_password");
    // Fields End

    // Msg Start
    users_password_msg = document.getElementById("users_password_msg");
    users_confirm_password_msg = document.getElementById("users_confirm_password_msg");
    // Msg End

    // Current Password Start
    if(!users_password.value){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Please enter the current password!";
    }else if(users_password.value.trim() == ""){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Please enter the valid password!";
    }else if(users_password.value.length < 6){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Password must have 6 or more characters!";
    }else{
        users_password.classList.remove("border-danger");
        users_password_msg.innerText = "";
    }
    // Current Password End  
    

    // Confirm Password Start
    if(!users_confirm_password.value){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Please enter the confirm password!";
    }else if(users_confirm_password.value.trim() == ""){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Please enter the valid password!";
    }else if(users_confirm_password.value.length < 6){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Password must have 6 or more characters!";
    }else if(users_confirm_password.value != users_password.value){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Confirm password doesn't match with new password!";
    }else{
        users_confirm_password.classList.remove("border-danger");
        users_confirm_password_msg.innerText = "";
    }
    // Confirm Password End
  };

  const onLogin = (e) => {
    formData["users_email"] = usersEmail;
    formData["user_otp"] = userOtp;
    e.preventDefault();

    // Fields Start
    users_password = document.getElementById("users_password");
    users_confirm_password = document.getElementById("users_confirm_password");
    // Fields End

    // Msg Start
    users_password_msg = document.getElementById("users_password_msg");
    users_confirm_password_msg = document.getElementById("users_confirm_password_msg");
    // Msg End

    // Current Password Start
    if(!users_password.value){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Please enter the current password!";
    }else if(users_password.value.trim() == ""){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Please enter the valid password!";
    }else if(users_password.value.length < 6){
        applyErrors = true;
        users_password.classList.add("border-danger");
        users_password_msg.innerText = "Password must have 6 or more characters!";
    }else{
        users_password.classList.remove("border-danger");
        users_password_msg.innerText = "";
    }
    // Current Password End  
    

    // Confirm Password Start
    if(!users_confirm_password.value){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Please enter the confirm password!";
    }else if(users_confirm_password.value.trim() == ""){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Please enter the valid password!";
    }else if(users_confirm_password.value.length < 6){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Password must have 6 or more characters!";
    }else if(users_confirm_password.value != users_password.value){
        applyErrors = true;
        users_confirm_password.classList.add("border-danger");
        users_confirm_password_msg.innerText = "Confirm password doesn't match with new password!";
    }else{
        users_confirm_password.classList.remove("border-danger");
        users_confirm_password_msg.innerText = "";
    }
   

    //   console.log("Final Data :=>"+JSON.stringify(formData));
    if(applyErrors == false){
      document.getElementById("loader").style.display = "block";

      dispatch(
        updateDataOnRequest(JSON.stringify(formData, null, 2), "NewPassword", "")
      );
    }
  };

  // const changeTab = (activeTab) => {
  //   setUserType(activeTab);
  // };
  // console.log(props.errorMessage);

  return (
    <React.Fragment>
      <div className="page-content ">
        <div className="section-full content-inner-2 shop-account">
          <div className="container">
            <div className="max-w500 m-auto bg-white m-b30">
              <div className="p-a30 job-bx browse-job radius-sm seth loginSignup">
                <div className="tab-content nav ">
                  <form
                    id="login"
                    onSubmit={onLogin}
                    onInput={handleChange}
                    className="tab-pane active col-12 p-a0 "
                    noValidate
                  >
                    <h1 style={{display:"none"}}>Staffing Connect -  Set New Password Details</h1>
                    <h4 className="font-weight-700 text-center">
                      Set New Password
                    </h4>

                    <div className="row mt-4">
                      {/* <label className="font-weight-700">New Password *</label> */}
                      <Input
                        className="form-control"
                        label="New Password"
                        value={password}
                        type="password"
                        name="users_password"
                        id={"users_password"}
                        placeholder="Enter new password"
                        // title="Enter New Password"
                        onChange={(e) => handleChange()}
                        lg={12}
                      />
                      
                      <Input
                        className="form-control"
                        value={confirmPassword}
                        label="Confirm Password"
                        type="password"
                        name="users_confirm_password"
                        placeholder="Enter confirm password"
                        id={"users_confirm_password"}
                        // title="Enter Confirm Password"
                        onChange={(e) => handleChange()}
                        lg={12}
                      />
                      
                    </div>

                    <div className="text-center mt-5">
                      <button className="site-button green pull-right m-r5 button-lg">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: isAuthenticated(state),
    errorMessage: state.error.error,
  };
};

export default connect(mapStateToProps)(NewPassword);
