import React, { useEffect, useState } from "react";
import Menusidebar from "../components/Element/Menusidebar";
//import Profilesidebar from '../components/Element/Profilesidebar';
import AppliedJobs from "../components/Element/AppliedJobs";
import PermanentProfilesidebar from "./PermanentCandidate/PermanentProfilesidebar";
import CandidateProfileSidebar from "./My Profile/CandidateProfileSidebar";
import CandidateJobProfileWorkQualification from "./../containers/CandidateJobProfileWorkQualification";
import MenuSideBarMobile from "../components/Element/MenuSideBarMobile";
import { isMobileOrLaptop } from "../services/CheckDevice";
const AppliedJob = (props) => {
  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(350);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-blocks">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {/* <PermanentProfilesidebar page="appliedJobs" /> */}
                  {window.location.pathname == "/jobs-applied" ? (
                    <>
                    {checkDevice == "Mobile" ? (
                  <MenuSideBarMobile page="timesheet" />
                ) : (
                  <Menusidebar page="timesheet" />
                )}
                    </>
                  ) : (
                    <div className={"col-xl-4 col-lg-12 m-b30"}>
                    <CandidateProfileSidebar />
                    </div>
                  )}
                <div className={(window.location.pathname == "/jobs-applied" ? "col-xl-9" : "col-xl-8")+" col-xl-8 col-lg-12 m-b30"}>
                  <AppliedJobs />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateJobProfileWorkQualification(AppliedJob);
