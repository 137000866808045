import React from 'react';
import ClientSidebar from '../components/Element/ClientSidebar';
import CompanyDetails from '../components/Element/CompanyDetails';
const ClientProfile = (props) => {
	return (
		<React.Fragment>
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								<ClientSidebar page="company-details"/>
								<div className="col-xl-9 col-lg-8 m-b30">
									<CompanyDetails />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default ClientProfile;