import request from '../services/Request';
import {urls} from "../services/Urls";

export const postRequestData=(urlIdentifier,postData,params)=>{
  const url=urls[urlIdentifier] + params
  const options={url:url,method:'POST',data:postData};
  return request(options)
}

export const getRequestData=(urlIdentifier,id="",urlParameters="")=>{
  id = id ? '/'+id+'/':'';
  const url=urls[urlIdentifier]+id+urlParameters
  const options={url:url,method:'GET'};
  return request(options)
}
export const deleteRequestData=(urlIdentifier,postData,params)=>{
  const url=urls[urlIdentifier]+params
  const options={url:url,method:'POST',data:postData};
  return request(options)
}