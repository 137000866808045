import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Form,
  Button,
  Spinner,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Error from "../../Layout/Error";
import { format } from "date-fns";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Label from "../../Layout/Label";
import Textarea from "../../FormElements/Textarea";
import Input from "../../FormElements/Input";
import Dropdown from "../../FormElements/Dropdown";
const DriverLicenseForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [usersCategory, setUsersCategory] = useState("home care");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({});
  const [moreFormId, setMoreFormId] = useState({});

  const [driverCPC, setDriverCPC] = useState(null);
  const [driverAccident, setDriverAccident] = useState(false);
  const [driverBanFiveYear, setDriverBanFiveYear] = useState(false);
  const [driverBanMultiForm, setDriverBanMultiForm] = useState(false);
  const [medicalDrugs, setMedicalDrugs] = useState(false);
  const [changeConsent, setChangeConsent] = useState(false);

  const { loader } = props;

  // Add More Form - Start
  const [inputFields, setInputFields] = useState([
    {
      motor_convictions_date: "",
      motor_convictions_offense: "",
      motor_convictions_endorsement_code: "",
      motor_convictions_fine_or_points_or_suspension: "",
    },
  ]);
  const formIdsRef = useRef(null);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      motor_convictions_date: "",
      motor_convictions_offense: "",
      motor_convictions_endorsement_code: "",
      motor_convictions_fine_or_points_or_suspension: "",
    });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  const renderTooltip = (buttonName) => (
    <Tooltip id="button-tooltip">{buttonName}</Tooltip>
  );
  // Add More Form - End

  const showDescription = (currentId) => {
    let fieldYes = document.getElementById(currentId + "_yes");
    let fieldNo = document.getElementById(currentId + "_no");

    // Driver Qualification (CPC) (if applicable) - Start
    if (currentId == "driver_qualification_do_you_hold_valid_cpc") {
      if (fieldYes.checked == true) {
        setDriverCPC(true);
      } else if (fieldNo.checked == true) {
        setDriverCPC(false);
      }
    }
    // Driver Qualification (CPC) (if applicable) - End

    // Accidents - Start
    if (
      currentId ==
      "accidents_details_have_you_any_road_accidents_in_last_five_years"
    ) {
      if (fieldYes.checked == true) {
        setDriverAccident(true);
      } else {
        setDriverAccident(false);
      }
    }
    // Accidents - End

    // Driver Ban Five Years - Start
    if (
      currentId ==
      "driving_bans_details_have_you_ban_from_driving_for_last_5_years"
    ) {
      if (fieldYes.checked == true) {
        setDriverBanFiveYear(true);
      } else {
        setDriverBanFiveYear(false);
      }
    }
    // Driver Ban Five Years - End

    // Driver Ban Multi Form -Start
    if (currentId == "driving_bans_details_do_you_have_any_motor_conviction") {
      if (fieldYes.checked == true) {
        setDriverBanMultiForm(true);
      } else {
        setDriverBanMultiForm(false);
      }
    }
    // Driver Ban Multi Form - End

    // Medical Drugs - Start
    if (
      currentId ==
      "medical_details_prescribed_or_non_prescribed_drugs_or_medication"
    ) {
      if (fieldYes.checked == true) {
        setMedicalDrugs(true);
      } else {
        setMedicalDrugs(false);
      }
    }
    // Medical Drugs - End
  };

  // Consent Op -Start
  const agreeDisagreeCondition = (e) => {
    // let fieldId = document.getElementById(field);

    if (changeConsent == true) {
      setChangeConsent(false);
    } else {
      setChangeConsent(true);
    }
  };
  // Consent Op - End
  const validForm = () => {
    let applyError = false;
    let todayDate = new Date(String(new Date().toISOString().split("T")[0])); //  Today Date
    let currentDate = "";
    let fromDate = "";
    // Driver License Details - Start
    // License Number - Start
    let driver_license_details_license_no = document.getElementById(
      "driver_license_details_license_no"
    );
    let driver_license_details_license_no_msg = document.getElementById(
      "driver_license_details_license_no_msg"
    );

    if (
      !driver_license_details_license_no.value ||
      driver_license_details_license_no.value.trim() == ""
    ) {
      driver_license_details_license_no.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_license_no.focus();
      }
      driver_license_details_license_no_msg.innerText =
        "Please enter the license number!";
      applyError = true;
    } else {
      driver_license_details_license_no.classList.remove("border-danger");
      driver_license_details_license_no_msg.innerText = "";
    }
    // License Number - End

    // Groups or Categories - Start
    let driver_license_details_groups_or_categories = document.getElementById(
      "driver_license_details_groups_or_categories"
    );
    let driver_license_details_groups_or_categories_msg =
      document.getElementById(
        "driver_license_details_groups_or_categories_msg"
      );

    if (
      !driver_license_details_groups_or_categories.value ||
      driver_license_details_groups_or_categories.value.trim() == ""
    ) {
      driver_license_details_groups_or_categories.classList.add(
        "border-danger"
      );
      if (applyError == false) {
        driver_license_details_groups_or_categories.focus();
      }
      driver_license_details_groups_or_categories_msg.innerText =
        "Please enter the groups or categories details!";
      applyError = true;
    } else {
      driver_license_details_groups_or_categories.classList.remove(
        "border-danger"
      );
      driver_license_details_groups_or_categories_msg.innerText = "";
    }
    // Groups or Categories - End

    // Valid From Date  - Start
    let driver_license_details_valid_from = document.getElementById(
      "driver_license_details_valid_from"
    );
    let driver_license_details_valid_from_msg = document.getElementById(
      "driver_license_details_valid_from_msg"
    );
    currentDate = new Date(String(driver_license_details_valid_from.value));
    if (
      !driver_license_details_valid_from.value ||
      driver_license_details_valid_from.value.trim() == ""
    ) {
      driver_license_details_valid_from.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_valid_from.focus();
      }
      driver_license_details_valid_from_msg.innerText =
        "Please enter the date!";
      applyError = true;
    } else if (
      driver_license_details_valid_from.value &&
      !String(driver_license_details_valid_from.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      driver_license_details_valid_from.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_valid_from.focus();
      }
      driver_license_details_valid_from_msg.innerText =
        "Please select the date in dd-mm-yyyy format!";
      applyError = true;
    } else if (
      driver_license_details_valid_from.value &&
      currentDate > todayDate
    ) {
      driver_license_details_valid_from.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_valid_from.focus();
      }
      driver_license_details_valid_from_msg.innerText =
        "Please select the " +
        format(todayDate, "dd-MM-yyyy") +
        " or before date";
      applyError = true;
    } else {
      driver_license_details_valid_from.classList.remove("border-danger");
      driver_license_details_valid_from_msg.innerText = "";
    }
    // Valid From Date - End

    // Valid To Date - Start
    let driver_license_details_valid_to = document.getElementById(
      "driver_license_details_valid_to"
    );
    let driver_license_details_valid_to_msg = document.getElementById(
      "driver_license_details_valid_to_msg"
    );
    currentDate = new Date(String(driver_license_details_valid_to.value));
    fromDate = new Date(String(driver_license_details_valid_from.value));
    if (
      !driver_license_details_valid_to.value ||
      driver_license_details_valid_to.value.trim() == ""
    ) {
      driver_license_details_valid_to.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_valid_to.focus();
      }
      driver_license_details_valid_to_msg.innerText = "Please enter the date!";
      applyError = true;
    } else if (
      driver_license_details_valid_to.value &&
      !String(driver_license_details_valid_to.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      driver_license_details_valid_to.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_valid_to.focus();
      }
      driver_license_details_valid_to_msg.innerText =
        "Please select the date in dd-mm-yyyy format!";
      applyError = true;
    } else if (
      driver_license_details_valid_to.value &&
      todayDate > currentDate
    ) {
      driver_license_details_valid_to.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_valid_to.focus();
      }
      driver_license_details_valid_to_msg.innerText =
        "Please select the " +
        format(todayDate, "dd-MM-yyyy") +
        " or after date";
      applyError = true;
    } else if (
      driver_license_details_valid_to.value &&
      fromDate >= currentDate
    ) {
      driver_license_details_valid_to.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_valid_to.focus();
      }
      driver_license_details_valid_to_msg.innerText =
        "Valid To date Should be greater than Valid From date!";
      applyError = true;
    } else {
      driver_license_details_valid_to.classList.remove("border-danger");
      driver_license_details_valid_to_msg.innerText = "";
    }
    // Valid To Date - End

    // How many years driving - start
    let driver_license_details_how_many_years_driving = document.getElementById(
      "driver_license_details_how_many_years_driving"
    );
    let driver_license_details_how_many_years_driving_msg =
      document.getElementById(
        "driver_license_details_how_many_years_driving_msg"
      );

    if (
      !driver_license_details_how_many_years_driving.value ||
      driver_license_details_how_many_years_driving.value.trim() == ""
    ) {
      driver_license_details_how_many_years_driving.classList.add(
        "border-danger"
      );
      if (applyError == false) {
        driver_license_details_how_many_years_driving.focus();
      }
      driver_license_details_how_many_years_driving_msg.innerText =
        "Please enter the years in digit!";
      applyError = true;
    } else {
      driver_license_details_how_many_years_driving.classList.remove(
        "border-danger"
      );
      driver_license_details_how_many_years_driving_msg.innerText = "";
    }
    // How many years driving - end

    // How regularly do you drive - Start
    let driver_license_details_how_regularly_drive = document.getElementById(
      "driver_license_details_how_regularly_drive"
    );
    let driver_license_details_how_regularly_drive_msg =
      document.getElementById("driver_license_details_how_regularly_drive_msg");

    if (
      !formData.driver_license_details_how_regularly_drive ||
      formData.driver_license_details_how_regularly_drive.trim() == ""
    ) {
      driver_license_details_how_regularly_drive.classList.add("border-danger");
      if (applyError == false) {
        driver_license_details_how_regularly_drive.focus();
      }
      driver_license_details_how_regularly_drive_msg.innerText =
        "Please choose the option from the above list!";
      applyError = true;
    } else {
      driver_license_details_how_regularly_drive.classList.remove(
        "border-danger"
      );
      driver_license_details_how_regularly_drive_msg.innerText = "";
    }
    // How regularly do you drive - End
    // Driver License Details - End

    // Digital Tachograph Driver Card (If Applicable) - Start
    // Serial Number - Start
    let digital_tachograph_serial_no = document.getElementById(
      "digital_tachograph_serial_no"
    );
    let digital_tachograph_serial_no_msg = document.getElementById(
      "digital_tachograph_serial_no_msg"
    );

    if (
      digital_tachograph_serial_no.value &&
      digital_tachograph_serial_no.value.trim() == ""
    ) {
      digital_tachograph_serial_no.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_serial_no.focus();
      }
      digital_tachograph_serial_no_msg.innerText =
        "Please enter the serial number!";
      applyError = true;
    } else {
      digital_tachograph_serial_no.classList.remove("border-danger");
      digital_tachograph_serial_no_msg.innerText = "";
    }
    // Serial Number - End

    // Medical Due - Start
    let digital_tachograph_medical_due = document.getElementById(
      "digital_tachograph_medical_due"
    );
    let digital_tachograph_medical_due_msg = document.getElementById(
      "digital_tachograph_medical_due_msg"
    );
    currentDate = new Date(String(digital_tachograph_medical_due.value));

    if (
      digital_tachograph_medical_due.value &&
      digital_tachograph_medical_due.value.trim() == ""
    ) {
      digital_tachograph_medical_due.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_medical_due.focus();
      }
      digital_tachograph_medical_due_msg.innerText = "Please select the date!";
      applyError = true;
    } else if (
      digital_tachograph_medical_due.value &&
      !String(digital_tachograph_medical_due.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      digital_tachograph_medical_due.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_medical_due.focus();
      }
      digital_tachograph_medical_due_msg.innerText =
        "Please select the date in dd-mm-yyyy format!";
      applyError = true;
    } else if (
      digital_tachograph_medical_due.value &&
      todayDate > currentDate
    ) {
      digital_tachograph_medical_due.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_medical_due.focus();
      }
      digital_tachograph_medical_due_msg.innerText =
        "Please select the " +
        format(todayDate, "dd-MM-yyyy") +
        " or after date";
      applyError = true;
    } else {
      digital_tachograph_medical_due.classList.remove("border-danger");
      digital_tachograph_medical_due_msg.innerText = "";
    }
    // Medical Due - End

    // Valid From Date  - Start
    let digital_tachograph_valid_from = document.getElementById(
      "digital_tachograph_valid_from"
    );
    let digital_tachograph_valid_from_msg = document.getElementById(
      "digital_tachograph_valid_from_msg"
    );
    currentDate = new Date(String(digital_tachograph_valid_from.value));
    if (
      digital_tachograph_valid_from.value &&
      digital_tachograph_valid_from.value.trim() == ""
    ) {
      digital_tachograph_valid_from.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_valid_from.focus();
      }
      digital_tachograph_valid_from_msg.innerText = "Please enter the date!";
      applyError = true;
    } else if (
      digital_tachograph_valid_from.value &&
      !String(digital_tachograph_valid_from.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      digital_tachograph_valid_from.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_valid_from.focus();
      }
      digital_tachograph_valid_from_msg.innerText =
        "Please select the date in dd-mm-yyyy format!";
      applyError = true;
    } else if (digital_tachograph_valid_from.value && currentDate > todayDate) {
      digital_tachograph_valid_from.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_valid_from.focus();
      }
      digital_tachograph_valid_from_msg.innerText =
        "Please select the " +
        format(todayDate, "dd-MM-yyyy") +
        " or before date";
      applyError = true;
    } else {
      digital_tachograph_valid_from.classList.remove("border-danger");
      digital_tachograph_valid_from_msg.innerText = "";
    }
    // Valid From Date - End

    // Valid To Date - Start
    let digital_tachograph_valid_to = document.getElementById(
      "digital_tachograph_valid_to"
    );
    let digital_tachograph_valid_to_msg = document.getElementById(
      "digital_tachograph_valid_to_msg"
    );
    currentDate = new Date(String(digital_tachograph_valid_to.value));
    fromDate = new Date(String(digital_tachograph_valid_from.value));
    if (
      digital_tachograph_valid_to.value &&
      digital_tachograph_valid_to.value.trim() == ""
    ) {
      digital_tachograph_valid_to.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_valid_to.focus();
      }
      digital_tachograph_valid_to_msg.innerText = "Please enter the date!";
      applyError = true;
    } else if (
      digital_tachograph_valid_to.value &&
      !String(digital_tachograph_valid_to.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      digital_tachograph_valid_to.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_valid_to.focus();
      }
      digital_tachograph_valid_to_msg.innerText =
        "Please select the date in dd-mm-yyyy format!";
      applyError = true;
    } else if (digital_tachograph_valid_to.value && todayDate > currentDate) {
      digital_tachograph_valid_to.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_valid_to.focus();
      }
      digital_tachograph_valid_to_msg.innerText =
        "Please select the " +
        format(todayDate, "dd-MM-yyyy") +
        " or after date";
      applyError = true;
    } else if (digital_tachograph_valid_to.value && fromDate >= currentDate) {
      digital_tachograph_valid_to.classList.add("border-danger");
      if (applyError == false) {
        digital_tachograph_valid_to.focus();
      }
      digital_tachograph_valid_to_msg.innerText =
        "Valid To date Should be greater than Valid From date!";
      applyError = true;
    } else {
      digital_tachograph_valid_to.classList.remove("border-danger");
      digital_tachograph_valid_to_msg.innerText = "";
    }
    // Valid To Date - End

    // How many years driving - start
    let digital_tachograph_how_many_year_driving_lgv_or_hgv =
      document.getElementById(
        "digital_tachograph_how_many_year_driving_lgv_or_hgv"
      );
    let digital_tachograph_how_many_year_driving_lgv_or_hgv_msg =
      document.getElementById(
        "digital_tachograph_how_many_year_driving_lgv_or_hgv_msg"
      );

    if (
      digital_tachograph_how_many_year_driving_lgv_or_hgv.value &&
      digital_tachograph_how_many_year_driving_lgv_or_hgv.value.trim() == ""
    ) {
      digital_tachograph_how_many_year_driving_lgv_or_hgv.classList.add(
        "border-danger"
      );
      if (applyError == false) {
        digital_tachograph_how_many_year_driving_lgv_or_hgv.focus();
      }
      digital_tachograph_how_many_year_driving_lgv_or_hgv_msg.innerText =
        "Please enter the years in digit!";
      applyError = true;
    } else {
      digital_tachograph_how_many_year_driving_lgv_or_hgv.classList.remove(
        "border-danger"
      );
      digital_tachograph_how_many_year_driving_lgv_or_hgv_msg.innerText = "";
    }
    // How many years driving - end
    // Digital Tachograph Driver Card (If Applicable) - End

    // Driver Qualification (CPC) (if applicable) - Start
    // Do you hold a valid CPC? - Start
    let driver_qualification_do_you_hold_valid_cpc_yes =
      document.getElementById("driver_qualification_do_you_hold_valid_cpc_yes");
    let driver_qualification_do_you_hold_valid_cpc_no = document.getElementById(
      "driver_qualification_do_you_hold_valid_cpc_no"
    );
    let driver_qualification_do_you_hold_valid_cpc_msg =
      document.getElementById("driver_qualification_do_you_hold_valid_cpc_msg");

    // Do you hold a valid CPC? - End

    if (driver_qualification_do_you_hold_valid_cpc_yes.checked == true) {
      // Serial Number - Start

      let driver_qualification_serial_no = document.getElementById(
        "driver_qualification_serial_no"
      );
      let driver_qualification_serial_no_msg = document.getElementById(
        "driver_qualification_serial_no_msg"
      );

      if (
        !driver_qualification_serial_no.value ||
        driver_qualification_serial_no.value.trim() == ""
      ) {
        driver_qualification_serial_no.classList.add("border-danger");
        if (applyError == false) {
          driver_qualification_serial_no.focus();
        }
        driver_qualification_serial_no_msg.innerText =
          "Please enter the serial number!";
        applyError = true;
      } else {
        driver_qualification_serial_no.classList.remove("border-danger");
        driver_qualification_serial_no_msg.innerText = "";
      }

      // Serial Number - End

      // Valid From Date  - Start
      let driver_qualification_valid_from = document.getElementById(
        "driver_qualification_valid_from"
      );
      let driver_qualification_valid_from_msg = document.getElementById(
        "driver_qualification_valid_from_msg"
      );
      currentDate = new Date(String(driver_qualification_valid_from.value));
      if (
        !driver_qualification_valid_from.value ||
        driver_qualification_valid_from.value.trim() == ""
      ) {
        driver_qualification_valid_from.classList.add("border-danger");
        if (applyError == false) {
          driver_qualification_valid_from.focus();
        }
        driver_qualification_valid_from_msg.innerText =
          "Please enter the date!";
        applyError = true;
      } else if (
        driver_qualification_valid_from.value &&
        !String(driver_qualification_valid_from.value).match(
          /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
        )
      ) {
        driver_qualification_valid_from.classList.add("border-danger");
        if (applyError == false) {
          driver_qualification_valid_from.focus();
        }
        driver_qualification_valid_from_msg.innerText =
          "Please select the date in dd-mm-yyyy format!";
        applyError = true;
      } else if (
        driver_qualification_valid_from.value &&
        currentDate > todayDate
      ) {
        driver_qualification_valid_from.classList.add("border-danger");
        if (applyError == false) {
          driver_qualification_valid_from.focus();
        }
        driver_qualification_valid_from_msg.innerText =
          "Please select the " +
          format(todayDate, "dd-MM-yyyy") +
          " or before date";
        applyError = true;
      } else {
        driver_qualification_valid_from.classList.remove("border-danger");
        driver_qualification_valid_from_msg.innerText = "";
      }
      // Valid From Date - End

      // Valid To Date - Start
      let driver_qualification_valid_to = document.getElementById(
        "driver_qualification_valid_to"
      );
      let driver_qualification_valid_to_msg = document.getElementById(
        "driver_qualification_valid_to_msg"
      );
      currentDate = new Date(String(driver_qualification_valid_to.value));
      fromDate = new Date(String(driver_qualification_valid_from.value));
      if (
        !driver_qualification_valid_to.value ||
        driver_qualification_valid_to.value.trim() == ""
      ) {
        driver_qualification_valid_to.classList.add("border-danger");
        if (applyError == false) {
          driver_qualification_valid_to.focus();
        }
        driver_qualification_valid_to_msg.innerText = "Please enter the date!";
        applyError = true;
      } else if (
        driver_qualification_valid_to.value &&
        !String(driver_qualification_valid_to.value).match(
          /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
        )
      ) {
        driver_qualification_valid_to.classList.add("border-danger");
        if (applyError == false) {
          driver_qualification_valid_to.focus();
        }
        driver_qualification_valid_to_msg.innerText =
          "Please select the date in dd-mm-yyyy format!";
        applyError = true;
      } else if (
        driver_qualification_valid_to.value &&
        todayDate > currentDate
      ) {
        driver_qualification_valid_to.classList.add("border-danger");
        if (applyError == false) {
          driver_qualification_valid_to.focus();
        }
        driver_qualification_valid_to_msg.innerText =
          "Please select the " +
          format(todayDate, "dd-MM-yyyy") +
          " or after date";
        applyError = true;
      } else if (
        driver_qualification_valid_to.value &&
        fromDate >= currentDate
      ) {
        driver_qualification_valid_to.classList.add("border-danger");
        if (applyError == false) {
          driver_qualification_valid_to.focus();
        }
        driver_qualification_valid_to_msg.innerText =
          "Valid To date Should be greater than Valid From date!";
        applyError = true;
      } else {
        driver_qualification_valid_to.classList.remove("border-danger");
        driver_qualification_valid_to_msg.innerText = "";
      }
      // Valid To Date - End
    } else if (driver_qualification_do_you_hold_valid_cpc_no.checked == true) {
      // How many hours training have you completed? - Start
      let driver_qualification_how_many_hrs_training_completed =
        document.getElementById(
          "driver_qualification_how_many_hrs_training_completed"
        );
      let driver_qualification_how_many_hrs_training_completed_msg =
        document.getElementById(
          "driver_qualification_how_many_hrs_training_completed_msg"
        );

      if (
        !driver_qualification_how_many_hrs_training_completed.value ||
        driver_qualification_how_many_hrs_training_completed.value.trim() == ""
      ) {
        driver_qualification_how_many_hrs_training_completed.classList.add(
          "border-danger"
        );
        if (applyError == false) {
          driver_qualification_how_many_hrs_training_completed.focus();
        }
        driver_qualification_how_many_hrs_training_completed_msg.innerText =
          "Please enter the hours in digit!";
        applyError = true;
      } else {
        driver_qualification_how_many_hrs_training_completed.classList.remove(
          "border-danger"
        );
        driver_qualification_how_many_hrs_training_completed_msg.innerText = "";
      }
      // How many hours training have you completed? - End
    }
    // Driver Qualification (CPC) (if applicable) - End

    // Accidents - Start
    let accidents_details_have_you_any_road_accidents_in_last_five_years_yes =
      document.getElementById(
        "accidents_details_have_you_any_road_accidents_in_last_five_years_yes"
      );
    let accidents_details_have_you_any_road_accidents_in_last_five_years_no =
      document.getElementById(
        "accidents_details_have_you_any_road_accidents_in_last_five_years_no"
      );
    let accidents_details_have_you_any_road_accidents_in_last_five_years_msg =
      document.getElementById(
        "accidents_details_have_you_any_road_accidents_in_last_five_years_msg"
      );

    if (
      accidents_details_have_you_any_road_accidents_in_last_five_years_yes.checked ==
        false &&
      accidents_details_have_you_any_road_accidents_in_last_five_years_no.checked ==
        false
    ) {
      accidents_details_have_you_any_road_accidents_in_last_five_years_yes.classList.add(
        "border-danger"
      );
      accidents_details_have_you_any_road_accidents_in_last_five_years_no.classList.add(
        "border-danger"
      );
      if (applyError == false) {
        accidents_details_have_you_any_road_accidents_in_last_five_years_yes.focus();
      }
      accidents_details_have_you_any_road_accidents_in_last_five_years_msg.innerText =
        "Please select one of the above options!";
      applyError = true;
    } else {
      accidents_details_have_you_any_road_accidents_in_last_five_years_yes.classList.remove(
        "border-danger"
      );
      accidents_details_have_you_any_road_accidents_in_last_five_years_no.classList.remove(
        "border-danger"
      );
      accidents_details_have_you_any_road_accidents_in_last_five_years_msg.innerText =
        "";

      if (
        accidents_details_have_you_any_road_accidents_in_last_five_years_yes.checked ==
        true
      ) {
        let accidents_details_road_accidents_in_last_five_years_description =
          document.getElementById(
            "accidents_details_road_accidents_in_last_five_years_description"
          );
        let accidents_details_road_accidents_in_last_five_years_description_msg =
          document.getElementById(
            "accidents_details_road_accidents_in_last_five_years_description_msg"
          );

        if (
          !accidents_details_road_accidents_in_last_five_years_description.value ||
          accidents_details_road_accidents_in_last_five_years_description.value.trim() ==
            ""
        ) {
          accidents_details_road_accidents_in_last_five_years_description.classList.add(
            "border-danger"
          );
          if (applyError == false) {
            accidents_details_road_accidents_in_last_five_years_description.focus();
          }
          accidents_details_road_accidents_in_last_five_years_description_msg.innerText =
            "Please enter the description!";
          applyError = true;
        } else {
          accidents_details_road_accidents_in_last_five_years_description.classList.remove(
            "border-danger"
          );
          accidents_details_road_accidents_in_last_five_years_description_msg.innerText =
            "";
        }
      }
    }
    // Accidents - End

    // Driving Bans/Convictions/Points - Start
    // Have you been banned from driving in the last five years? - Start
    let driving_bans_details_have_you_ban_from_driving_for_last_5_years_yes =
      document.getElementById(
        "driving_bans_details_have_you_ban_from_driving_for_last_5_years_yes"
      );
    let driving_bans_details_have_you_ban_from_driving_for_last_5_years_no =
      document.getElementById(
        "driving_bans_details_have_you_ban_from_driving_for_last_5_years_no"
      );
    let driving_bans_details_have_you_ban_from_driving_for_last_5_years_msg =
      document.getElementById(
        "driving_bans_details_have_you_ban_from_driving_for_last_5_years_msg"
      );

    if (
      driving_bans_details_have_you_ban_from_driving_for_last_5_years_yes.checked ==
        false &&
      driving_bans_details_have_you_ban_from_driving_for_last_5_years_no.checked ==
        false
    ) {
      driving_bans_details_have_you_ban_from_driving_for_last_5_years_yes.classList.add(
        "border-danger"
      );
      driving_bans_details_have_you_ban_from_driving_for_last_5_years_no.classList.add(
        "border-danger"
      );
      if (applyError == false) {
        driving_bans_details_have_you_ban_from_driving_for_last_5_years_yes.focus();
      }
      driving_bans_details_have_you_ban_from_driving_for_last_5_years_msg.innerText =
        "Please select one of the above options!";
      applyError = true;
    } else {
      driving_bans_details_have_you_ban_from_driving_for_last_5_years_yes.classList.remove(
        "border-danger"
      );
      driving_bans_details_have_you_ban_from_driving_for_last_5_years_no.classList.remove(
        "border-danger"
      );
      driving_bans_details_have_you_ban_from_driving_for_last_5_years_msg.innerText =
        "";

      // Description Start
      if (
        driving_bans_details_have_you_ban_from_driving_for_last_5_years_yes.checked ==
        true
      ) {
        let driving_bans_details_ban_from_driving_for_last_5_yrs_description =
          document.getElementById(
            "driving_bans_details_ban_from_driving_for_last_5_yrs_description"
          );
        let driving_bans_details_ban_from_driving_for_last_5_yrs_description_msg =
          document.getElementById(
            "driving_bans_details_ban_from_driving_for_last_5_yrs_description_msg"
          );
        if (
          !driving_bans_details_ban_from_driving_for_last_5_yrs_description.value ||
          driving_bans_details_ban_from_driving_for_last_5_yrs_description.value.trim() ==
            ""
        ) {
          driving_bans_details_ban_from_driving_for_last_5_yrs_description.classList.add(
            "border-danger"
          );
          driving_bans_details_ban_from_driving_for_last_5_yrs_description_msg.innerText =
            "Please enter the description!";
          if (applyError == false) {
            driving_bans_details_ban_from_driving_for_last_5_yrs_description.focus();
          }
          applyError = true;
        } else {
          driving_bans_details_ban_from_driving_for_last_5_yrs_description.classList.remove(
            "border-danger"
          );
          driving_bans_details_ban_from_driving_for_last_5_yrs_description_msg.innerText =
            "";
        }
      }
      // Description End
    }
    // Have you been banned from driving in the last five years? - End

    // Do you have any motoring convictions/points on your license? - Start
    let driving_bans_details_do_you_have_any_motor_conviction_yes =
      document.getElementById(
        "driving_bans_details_do_you_have_any_motor_conviction_yes"
      );
    let driving_bans_details_do_you_have_any_motor_conviction_no =
      document.getElementById(
        "driving_bans_details_do_you_have_any_motor_conviction_no"
      );
    let driving_bans_details_do_you_have_any_motor_conviction_msg =
      document.getElementById(
        "driving_bans_details_do_you_have_any_motor_conviction_msg"
      );

    if (
      driving_bans_details_do_you_have_any_motor_conviction_yes.checked ==
        false &&
      driving_bans_details_do_you_have_any_motor_conviction_no.checked == false
    ) {
      driving_bans_details_do_you_have_any_motor_conviction_yes.classList.add(
        "border-danger"
      );
      driving_bans_details_do_you_have_any_motor_conviction_no.classList.add(
        "border-danger"
      );
      driving_bans_details_do_you_have_any_motor_conviction_msg.innerText =
        "Please select one of the above options!";
      if (applyError == false) {
        driving_bans_details_do_you_have_any_motor_conviction_yes.focus();
      }
      applyError = true;
    } else {
      driving_bans_details_do_you_have_any_motor_conviction_yes.classList.remove(
        "border-danger"
      );
      driving_bans_details_do_you_have_any_motor_conviction_no.classList.remove(
        "border-danger"
      );
      driving_bans_details_do_you_have_any_motor_conviction_msg.innerText = "";

      // Add More Form -Start
      if (
        driving_bans_details_do_you_have_any_motor_conviction_yes.checked ==
        true
      ) {
        // Multiple Forms - Start
        const formIds = formIdsRef.current.querySelectorAll("[id]");
        const invalidFields = [];

        formIds.forEach((element) => {
          const elementType = element.type;

          switch (elementType) {
            case "text":
              // Your validation logic for text fields
              let textTypeId = document.getElementById(element.id);
              let textTypeIdMsg = document.getElementById(element.id + "_msg");
              if (textTypeId.value.trim() === "") {
                textTypeId.classList.add("border-danger");
                textTypeIdMsg.innerText =
                  "Please enter the " +
                  (element.placeholder
                    ? String(element.placeholder).toLowerCase()
                    : "");
                if (applyError == false) {
                  textTypeId.focus();
                }
                applyError = true;
                // invalidFields.push(element.id);
              } else {
                textTypeId.classList.remove("border-danger");
                textTypeIdMsg.innerText = "";
                // invalidFields.pop(element.id);
              }
              break;
            case "date":
              // Your validation logic for text fields
              let dateTypeId = document.getElementById(element.id);
              let dateTypeIdMsg = document.getElementById(element.id + "_msg");
              let todayDate = new Date();
              let currentDate = new Date(String(dateTypeId.value));

              if (dateTypeId.value.trim() === "") {
                dateTypeId.classList.add("border-danger");
                dateTypeIdMsg.innerText =
                  "Please enter the " +
                  (element.placeholder
                    ? String(element.placeholder).toLowerCase()
                    : "");
                if (applyError == false) {
                  dateTypeId.focus();
                }
                applyError = true;
              } else if (currentDate > todayDate) {
                dateTypeId.classList.add("border-danger");
                dateTypeIdMsg.innerText =
                  "Date should be curent or before current date!";
                if (applyError == false) {
                  dateTypeId.focus();
                }
                applyError = true;
              } else {
                dateTypeId.classList.remove("border-danger");
                dateTypeIdMsg.innerText = "";
              }
              break;

            default:
              break;
          }
        });
        // Multiple Form - End
      }
      // Add More Form - End
    }
    // Do you have any motoring convictions/points on your license? - End
    // Driving Bans/Convictions/Points - End

    // Medical (employees only) - Start
    // Are you currently taking any prescribed or non-prescribed drugs or medication? - Start
    let medical_details_prescribed_or_non_prescribed_drugs_or_medication_yes =
      document.getElementById(
        "medical_details_prescribed_or_non_prescribed_drugs_or_medication_yes"
      );
    let medical_details_prescribed_or_non_prescribed_drugs_or_medication_no =
      document.getElementById(
        "medical_details_prescribed_or_non_prescribed_drugs_or_medication_no"
      );
    let medical_details_prescribed_or_non_prescribed_drugs_or_medication_msg =
      document.getElementById(
        "medical_details_prescribed_or_non_prescribed_drugs_or_medication_msg"
      );

    if (
      medical_details_prescribed_or_non_prescribed_drugs_or_medication_yes.checked ==
        false &&
      medical_details_prescribed_or_non_prescribed_drugs_or_medication_no.checked ==
        false
    ) {
      medical_details_prescribed_or_non_prescribed_drugs_or_medication_yes.classList.add(
        "border-danger"
      );
      if (applyError == false) {
        medical_details_prescribed_or_non_prescribed_drugs_or_medication_yes.focus();
      }
      medical_details_prescribed_or_non_prescribed_drugs_or_medication_no.classList.add(
        "border-danger"
      );
      medical_details_prescribed_or_non_prescribed_drugs_or_medication_msg.innerText =
        "Please select one of the above options!";
      applyError = true;
    } else {
      medical_details_prescribed_or_non_prescribed_drugs_or_medication_yes.classList.remove(
        "border-danger"
      );
      medical_details_prescribed_or_non_prescribed_drugs_or_medication_no.classList.remove(
        "border-danger"
      );
      medical_details_prescribed_or_non_prescribed_drugs_or_medication_msg.innerText =
        "";

      // Description - Start
      if (
        medical_details_prescribed_or_non_prescribed_drugs_or_medication_yes.checked ==
        true
      ) {
        let medical_details_prescribed_non_prescribed_drugs_medication_desc =
          document.getElementById(
            "medical_details_prescribed_non_prescribed_drugs_medication_desc"
          );
        let medical_details_prescribed_non_prescribed_drugs_medication_desc_msg =
          document.getElementById(
            "medical_details_prescribed_non_prescribed_drugs_medication_desc_msg"
          );

        if (
          !medical_details_prescribed_non_prescribed_drugs_medication_desc.value ||
          medical_details_prescribed_non_prescribed_drugs_medication_desc.value.trim() ==
            ""
        ) {
          medical_details_prescribed_non_prescribed_drugs_medication_desc.classList.add(
            "border-danger"
          );
          if (applyError == false) {
            medical_details_prescribed_non_prescribed_drugs_medication_desc.focus();
          }
          medical_details_prescribed_non_prescribed_drugs_medication_desc_msg.innerText =
            "Please enter the description!";
          applyError = true;
        } else {
          medical_details_prescribed_non_prescribed_drugs_medication_desc.classList.remove(
            "border-danger"
          );
          medical_details_prescribed_non_prescribed_drugs_medication_desc_msg.innerText =
            "";
        }
      }
      // Description - End
    }
    // Are you currently taking any prescribed or non-prescribed drugs or medication? - End

    // Do you have a DVLA notifiable condition? - Start
    let medical_details_dvla_notifiable_condition_yes = document.getElementById(
      "medical_details_dvla_notifiable_condition_yes"
    );
    let medical_details_dvla_notifiable_condition_no = document.getElementById(
      "medical_details_dvla_notifiable_condition_no"
    );
    let medical_details_dvla_notifiable_condition_msg = document.getElementById(
      "medical_details_dvla_notifiable_condition_msg"
    );

    if (
      medical_details_dvla_notifiable_condition_yes.checked == false &&
      medical_details_dvla_notifiable_condition_no.checked == false
    ) {
      medical_details_dvla_notifiable_condition_yes.classList.add(
        "border-danger"
      );
      medical_details_dvla_notifiable_condition_no.classList.add(
        "border-danger"
      );
      medical_details_dvla_notifiable_condition_msg.innerText =
        "Please select one of the above options!";
      if (applyError == false) {
        medical_details_dvla_notifiable_condition_yes.focus();
      }
      applyError = true;
    } else {
      medical_details_dvla_notifiable_condition_yes.classList.remove(
        "border-danger"
      );
      medical_details_dvla_notifiable_condition_no.classList.remove(
        "border-danger"
      );
      medical_details_dvla_notifiable_condition_msg.innerText = "";
    }
    // Do you have a DVLA notifiable condition? - End

    // Does the code for wearing glasses (01) show on your license? - start
    let medical_details_glasses_or_lenses_for_driving_yes =
      document.getElementById(
        "medical_details_glasses_or_lenses_for_driving_yes"
      );
    let medical_details_glasses_or_lenses_for_driving_no =
      document.getElementById(
        "medical_details_glasses_or_lenses_for_driving_no"
      );
    let medical_details_glasses_or_lenses_for_driving_msg =
      document.getElementById(
        "medical_details_glasses_or_lenses_for_driving_msg"
      );

    if (
      medical_details_glasses_or_lenses_for_driving_yes.checked == false &&
      medical_details_glasses_or_lenses_for_driving_no.checked == false
    ) {
      medical_details_glasses_or_lenses_for_driving_yes.classList.add(
        "border-danger"
      );
      medical_details_glasses_or_lenses_for_driving_no.classList.add(
        "border-danger"
      );
      medical_details_glasses_or_lenses_for_driving_msg.innerText =
        "Please select one of the above options!";
      if (applyError == false) {
        medical_details_glasses_or_lenses_for_driving_yes.focus();
      }
      applyError = true;
    } else {
      medical_details_glasses_or_lenses_for_driving_yes.classList.remove(
        "border-danger"
      );
      medical_details_glasses_or_lenses_for_driving_no.classList.remove(
        "border-danger"
      );
      medical_details_glasses_or_lenses_for_driving_msg.innerText = "";
    }
    // Does the code for wearing glasses (01) show on your license? - End

    // Does the code for wearing glasses (01) show on your license? - Start
    let medical_details_wearing_glasses_01_show_on_your_license_yes =
      document.getElementById(
        "medical_details_wearing_glasses_01_show_on_your_license_yes"
      );
    let medical_details_wearing_glasses_01_show_on_your_license_no =
      document.getElementById(
        "medical_details_wearing_glasses_01_show_on_your_license_no"
      );
    let medical_details_wearing_glasses_01_show_on_your_license_msg =
      document.getElementById(
        "medical_details_wearing_glasses_01_show_on_your_license_msg"
      );

    if (
      medical_details_wearing_glasses_01_show_on_your_license_yes.checked ==
        false &&
      medical_details_wearing_glasses_01_show_on_your_license_no.checked ==
        false
    ) {
      medical_details_wearing_glasses_01_show_on_your_license_yes.classList.add(
        "border-danger"
      );
      medical_details_wearing_glasses_01_show_on_your_license_no.classList.add(
        "border-danger"
      );
      medical_details_wearing_glasses_01_show_on_your_license_msg.innerText =
        "Please select one of the above options!";
      if (applyError == false) {
        medical_details_wearing_glasses_01_show_on_your_license_yes.focus();
      }
      applyError = true;
    } else {
      medical_details_wearing_glasses_01_show_on_your_license_yes.classList.remove(
        "border-danger"
      );
      medical_details_wearing_glasses_01_show_on_your_license_no.classList.remove(
        "border-danger"
      );
      medical_details_wearing_glasses_01_show_on_your_license_msg.innerText =
        "";
    }
    // Does the code for wearing glasses (01) show on your license? - End

    // Do you wear a hearing aid when you drive - Start
    let medical_details_wear_hearing_aid_when_you_drive_yes =
      document.getElementById(
        "medical_details_wear_hearing_aid_when_you_drive_yes"
      );
    let medical_details_wear_hearing_aid_when_you_drive_no =
      document.getElementById(
        "medical_details_wear_hearing_aid_when_you_drive_no"
      );
    let medical_details_wear_hearing_aid_when_you_drive_msg =
      document.getElementById(
        "medical_details_wear_hearing_aid_when_you_drive_msg"
      );

    if (
      medical_details_wear_hearing_aid_when_you_drive_yes.checked == false &&
      medical_details_wear_hearing_aid_when_you_drive_no.checked == false
    ) {
      medical_details_wear_hearing_aid_when_you_drive_yes.classList.add(
        "border-danger"
      );
      medical_details_wear_hearing_aid_when_you_drive_no.classList.add(
        "border-danger"
      );
      medical_details_wear_hearing_aid_when_you_drive_msg.innerText =
        "Please select one of the above options!";
      if (applyError == false) {
        medical_details_wear_hearing_aid_when_you_drive_yes.focus();
      }
      applyError = true;
    } else {
      medical_details_wear_hearing_aid_when_you_drive_yes.classList.remove(
        "border-danger"
      );
      medical_details_wear_hearing_aid_when_you_drive_no.classList.remove(
        "border-danger"
      );
      medical_details_wear_hearing_aid_when_you_drive_msg.innerText = "";
    }
    // Do you wear a hearing aid when you drive - End

    // If yes, have you informed the DVLA? - Start
    let medical_details_have_you_informed_the_dvla_yes =
      document.getElementById("medical_details_have_you_informed_the_dvla_yes");
    let medical_details_have_you_informed_the_dvla_no = document.getElementById(
      "medical_details_have_you_informed_the_dvla_no"
    );
    let medical_details_have_you_informed_the_dvla_msg =
      document.getElementById("medical_details_have_you_informed_the_dvla_msg");

    if (
      medical_details_have_you_informed_the_dvla_yes.checked == false &&
      medical_details_have_you_informed_the_dvla_no.checked == false
    ) {
      medical_details_have_you_informed_the_dvla_yes.classList.add(
        "border-danger"
      );
      medical_details_have_you_informed_the_dvla_no.classList.add(
        "border-danger"
      );
      medical_details_have_you_informed_the_dvla_msg.innerText =
        "Please select one of the above options!";
      if (applyError == false) {
        medical_details_have_you_informed_the_dvla_yes.focus();
      }
      applyError = true;
    } else {
      medical_details_have_you_informed_the_dvla_yes.classList.remove(
        "border-danger"
      );
      medical_details_have_you_informed_the_dvla_no.classList.remove(
        "border-danger"
      );
      medical_details_have_you_informed_the_dvla_msg.innerText = "";
    }
    // If yes, have you informed the DVLA? - End

    // Does the code for wearing a hearing aid (02) show on your license? - Start
    let medical_details_code_for_wearing_hearing_aid_02_show_on_license_yes =
      document.getElementById(
        "medical_details_code_for_wearing_hearing_aid_02_show_on_license_yes"
      );
    let medical_details_code_for_wearing_hearing_aid_02_show_on_license_no =
      document.getElementById(
        "medical_details_code_for_wearing_hearing_aid_02_show_on_license_no"
      );
    let medical_details_code_for_wearing_hearing_aid_02_show_on_license_msg =
      document.getElementById(
        "medical_details_code_for_wearing_hearing_aid_02_show_on_license_msg"
      );

    if (
      medical_details_code_for_wearing_hearing_aid_02_show_on_license_yes.checked ==
        false &&
      medical_details_code_for_wearing_hearing_aid_02_show_on_license_no.checked ==
        false
    ) {
      medical_details_code_for_wearing_hearing_aid_02_show_on_license_yes.classList.add(
        "border-danger"
      );
      medical_details_code_for_wearing_hearing_aid_02_show_on_license_no.classList.add(
        "border-danger"
      );
      medical_details_code_for_wearing_hearing_aid_02_show_on_license_msg.innerText =
        "Please select one of the above options!";
      if (applyError == false) {
        medical_details_code_for_wearing_hearing_aid_02_show_on_license_yes.focus();
      }
      applyError = true;
    } else {
      medical_details_code_for_wearing_hearing_aid_02_show_on_license_yes.classList.remove(
        "border-danger"
      );
      medical_details_code_for_wearing_hearing_aid_02_show_on_license_no.classList.remove(
        "border-danger"
      );
      medical_details_code_for_wearing_hearing_aid_02_show_on_license_msg.innerText =
        "";
    }
    // Does the code for wearing a hearing aid (02) show on your license? - End

    // Are you aware of any reason(s) which may impact on your ability to drive distances? - Start
    let medical_details_aware_of_any_reason_to_impact_on_ability_driving_yes =
      document.getElementById(
        "medical_details_aware_of_any_reason_to_impact_on_ability_driving_yes"
      );
    let medical_details_aware_of_any_reason_to_impact_on_ability_driving_no =
      document.getElementById(
        "medical_details_aware_of_any_reason_to_impact_on_ability_driving_no"
      );
    let medical_details_aware_of_any_reason_to_impact_on_ability_driving_msg =
      document.getElementById(
        "medical_details_aware_of_any_reason_to_impact_on_ability_driving_msg"
      );

    if (
      medical_details_aware_of_any_reason_to_impact_on_ability_driving_yes.checked ==
        false &&
      medical_details_aware_of_any_reason_to_impact_on_ability_driving_no.checked ==
        false
    ) {
      medical_details_aware_of_any_reason_to_impact_on_ability_driving_yes.classList.add(
        "border-danger"
      );
      medical_details_aware_of_any_reason_to_impact_on_ability_driving_no.classList.add(
        "border-danger"
      );
      medical_details_aware_of_any_reason_to_impact_on_ability_driving_msg.innerText =
        "Please select one of the above options!";
      if (applyError == false) {
        medical_details_aware_of_any_reason_to_impact_on_ability_driving_yes.focus();
      }
      applyError = true;
    } else {
      medical_details_aware_of_any_reason_to_impact_on_ability_driving_yes.classList.remove(
        "border-danger"
      );
      medical_details_aware_of_any_reason_to_impact_on_ability_driving_no.classList.remove(
        "border-danger"
      );
      medical_details_aware_of_any_reason_to_impact_on_ability_driving_msg.innerText =
        "";
    }
    // Are you aware of any reason(s) which may impact on your ability to drive distances? - End
    // Medical (employees only) - End

    // Final Consent - Start
    let cnd_driver_details_consent = document.getElementById(
      "cnd_driver_details_consent"
    );
    let cnd_driver_details_consent_msg = document.getElementById(
      "cnd_driver_details_consent_msg"
    );

    if (cnd_driver_details_consent.checked == false) {
      cnd_driver_details_consent.classList.add("border-danger");
      cnd_driver_details_consent_msg.innerText =
        "Please check the consent to proceed!";
      if (applyError == false) {
        cnd_driver_details_consent.focus();
      }
      applyError = true;
    } else {
      cnd_driver_details_consent.classList.remove("border-danger");
      cnd_driver_details_consent_msg.innerText = "";
    }
    // Final Consent - End

    return applyError;
  };

  const onInputValues = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // console.log("Form Data Is :=> "+JSON.stringify(formData));
  const updateDriverDetails = (e) => {
    e.preventDefault();
    let checkValid = validForm();
    if (checkValid == false) {
      document.getElementById("loader").style.display = "block";
      formData["users_access_token"] = props.userAuth.users_access_token;
      // Motor Conviction  - Start
      if (driverBanMultiForm == true) {
        formData["motorConvictionDetails"] = inputFields;
      }
      // Motor Conviction End
      formData["cnd_driver_details_progress_bar"] = "1";

      // Consent Start
      if (changeConsent == true) {
        formData["cnd_driver_details_consent"] = "1";
      } else {
        formData["cnd_driver_details_consent"] = "0";
      }
      // Consent End

      // Driver Qualification (CPC) (if applicable) - Start
      if (driverCPC == true) {
        formData["driver_qualification_how_many_hrs_training_completed"] = "";
      } else if (driverCPC == false) {
        formData["driver_qualification_serial_no"] = "";
        formData["driver_qualification_valid_from"] = "";
        formData["driver_qualification_valid_to"] = "";
      }
      // Driver Qualification (CPC) (if applicable) - End

      // Accidents - Start
      if (driverAccident == false) {
        formData[
          "accidents_details_road_accidents_in_last_five_years_description"
        ] = "";
      }
      // Accidents - End

      // Driving Bans/Convictions/Points - Start
      if (driverBanFiveYear == false) {
        formData[
          "driving_bans_details_ban_from_driving_for_last_5_yrs_description"
        ] = "";
      }
      // Driving Bans/Convictions/Points - End

      // Medical (employees only) - Start
      if (medicalDrugs == false) {
        formData[
          "medical_details_prescribed_non_prescribed_drugs_medication_desc"
        ] = "";
      }
      // Medical (employees only) - End

      dispatch(
        updateDataOnRequest(
          JSON.stringify(formData),
          "UpdateDriverDetails",
          "UPDATE_DRIVER_DETAILS",
          getDataOnRequest(
            props.userAuth.users_access_token,
            "ProgressBar",
            "SET_PROGRESS"
          )
        )
      );
    }
  };

  useEffect(() => {
    let form_data = {};
    let multiForms = {};
    if (Object.keys(props.driverDetails).length > 0) {
      form_data = props.driverDetails;
      if (Object.keys(props.driverDetails.motorConvictionDetails).length > 0) {
        multiForms = props.driverDetails.motorConvictionDetails;
        setInputFields(multiForms);
      }
      setFormData(form_data);
    }

    // Driver Cpc
    if (form_data.driver_qualification_do_you_hold_valid_cpc == "1") {
      setDriverCPC(true);
    } else if (form_data.driver_qualification_do_you_hold_valid_cpc == "0") {
      setDriverCPC(false);
    } else {
      setDriverCPC(null);
    }

    // Driver Accident
    if (
      form_data.accidents_details_have_you_any_road_accidents_in_last_five_years ==
      "1"
    ) {
      setDriverAccident(true);
    } else {
      setDriverAccident(false);
    }

    // Driver Ban 5 Years
    if (
      form_data.driving_bans_details_have_you_ban_from_driving_for_last_5_years ==
      "1"
    ) {
      setDriverBanFiveYear(true);
    } else {
      setDriverBanFiveYear(false);
    }

    // Driver Ban Multiple Forms
    if (
      form_data.driving_bans_details_do_you_have_any_motor_conviction == "1"
    ) {
      setDriverBanMultiForm(true);
    } else {
      setDriverBanMultiForm(false);
    }

    // Medical Drugs
    if (
      form_data.medical_details_prescribed_or_non_prescribed_drugs_or_medication ==
      "1"
    ) {
      setMedicalDrugs(true);
    } else {
      setMedicalDrugs(false);
    }

    // Consent Start
    if (form_data.cnd_driver_details_consent == "1") {
      setChangeConsent(true);
    } else {
      setChangeConsent(false);
    }
    // Consent End

    // console.log("Data Is Testing :=> " + JSON.stringify(props.driverDetails.motorConvictionDetails));
  }, [props.driverDetails, props.driverDetails.motorConvictionDetails]);

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  // console.log("Data Is :=> " + JSON.stringify(formData));

  return (
    <React.Fragment>
      {/* <EmployementGapForm /> */}
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Driver Details
          </h5>
        </div>
        <form noValidate className="pl-3 pr-3">
          <React.Fragment>
            <div className="row m-b20 border border-grey p-3">
              <div className="job-bx-title clearfix mb-3">
                <h6 className="text-sm pl-3 mb-0">
                  Driver License Details
                  <sup className="text-danger">
                    <b>*</b>
                  </sup>
                </h6>
              </div>
              <Input
                type="text"
                label="License Number"
                placeholder={"Eg., 849339TS8AD"}
                changeHandler={(e) => onInputValues(e)}
                name="driver_license_details_license_no"
                id={"driver_license_details_license_no"}
                value={formData.driver_license_details_license_no}
                lg={6}
              />
              <Input
                type="text"
                label="Groups or Categories"
                changeHandler={(e) => onInputValues(e)}
                name="driver_license_details_groups_or_categories"
                id={"driver_license_details_groups_or_categories"}
                value={formData.driver_license_details_groups_or_categories}
                lg={6}
              />
              <Input
                type="date"
                label="Valid From"
                changeHandler={(e) => onInputValues(e)}
                name="driver_license_details_valid_from"
                id={"driver_license_details_valid_from"}
                max={new Date().toISOString().split("T")[0]}
                value={formData.driver_license_details_valid_from}
                lg={6}
              />
              <Input
                type="date"
                label="Valid To"
                changeHandler={(e) => onInputValues(e)}
                name="driver_license_details_valid_to"
                min={new Date().toISOString().split("T")[0]}
                id={"driver_license_details_valid_to"}
                value={formData.driver_license_details_valid_to}
                lg={6}
              />
              <Input
                type="number"
                label="How many years have you been driving for?"
                changeHandler={(e) => onInputValues(e)}
                placeholder={"eg. 2"}
                min={"1"}
                name="driver_license_details_how_many_years_driving"
                id={"driver_license_details_how_many_years_driving"}
                value={formData.driver_license_details_how_many_years_driving}
                lg={6}
              />
              <Dropdown
                label="How regularly do you drive?"
                name="driver_license_details_how_regularly_drive"
                value={
                  formData &&
                  formData.driver_license_details_how_regularly_drive
                }
                options={[
                  {
                    name: "Choose Option",
                    value: "",
                  },
                  {
                    name: "Daily",
                    value: "daily",
                  },
                  {
                    name: "Once a Week",
                    value: "once_a_week",
                  },
                  {
                    name: "Occasional",
                    value: "occasional",
                  },
                ]}
                id={"driver_license_details_how_regularly_drive"}
                changeHandler={(e) => onInputValues(e)}
                lg={6}
              />
            </div>

            <div className="row m-b20 border border-grey p-3">
              <div className="job-bx-title clearfix mb-3">
                <h6 className="text-sm pl-3 mb-0">
                  Digital Tachograph Driver Card (if applicable)
                </h6>
              </div>
              <Input
                type="text"
                label="Serial Number"
                changeHandler={(e) => onInputValues(e)}
                name="digital_tachograph_serial_no"
                id={"digital_tachograph_serial_no"}
                value={formData.digital_tachograph_serial_no}
                req={false}
                lg={6}
              />
              <Input
                type="date"
                label="Medical Due"
                changeHandler={(e) => onInputValues(e)}
                name="digital_tachograph_medical_due"
                min={new Date().toISOString().split("T")[0]}
                id={"digital_tachograph_medical_due"}
                req={false}
                value={formData.digital_tachograph_medical_due}
                lg={6}
              />
              <Input
                type="date"
                label="Valid From"
                changeHandler={(e) => onInputValues(e)}
                max={new Date().toISOString().split("T")[0]}
                name="digital_tachograph_valid_from"
                id={"digital_tachograph_valid_from"}
                req={false}
                value={formData.digital_tachograph_valid_from}
                lg={6}
              />
              <Input
                type="date"
                label="Valid To"
                changeHandler={(e) => onInputValues(e)}
                name="digital_tachograph_valid_to"
                min={new Date().toISOString().split("T")[0]}
                id={"digital_tachograph_valid_to"}
                req={false}
                value={formData.digital_tachograph_valid_to}
                lg={6}
              />
              <Input
                type="number"
                label="How many years have you been driving LGV/HGV for?"
                changeHandler={(e) => onInputValues(e)}
                placeholder={"eg. 2"}
                min={"1"}
                name="digital_tachograph_how_many_year_driving_lgv_or_hgv"
                id={"digital_tachograph_how_many_year_driving_lgv_or_hgv"}
                value={
                  formData.digital_tachograph_how_many_year_driving_lgv_or_hgv
                }
                req={false}
                lg={6}
              />
            </div>

            <div className="row m-b20 border border-grey p-3">
              <div className="job-bx-title clearfix mb-3">
                <h6 className="text-sm pl-3 mb-0">
                  Driver Qualification (CPC) (if applicable)
                </h6>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Do you hold a valid CPC?" req={false} />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="driver_qualification_do_you_hold_valid_cpc"
                    checked={
                      formData.driver_qualification_do_you_hold_valid_cpc == "1"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "driver_qualification_do_you_hold_valid_cpc"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    type="radio"
                    id={"driver_qualification_do_you_hold_valid_cpc_yes"}
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.driver_qualification_do_you_hold_valid_cpc == "0"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "driver_qualification_do_you_hold_valid_cpc"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    name="driver_qualification_do_you_hold_valid_cpc"
                    type="radio"
                    id={"driver_qualification_do_you_hold_valid_cpc_no"}
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="driver_qualification_do_you_hold_valid_cpc_msg"
                  ></div>
                </div>
              </div>

              {/* Valid CPC Yes Content - Start  */}
              {driverCPC == true ? (
                <>
                  <Input
                    type="text"
                    label="Serial Number"
                    changeHandler={(e) => onInputValues(e)}
                    name="driver_qualification_serial_no"
                    id={"driver_qualification_serial_no"}
                    value={formData.driver_qualification_serial_no}
                    lg={4}
                  />

                  <Input
                    type="date"
                    label="Valid From"
                    changeHandler={(e) => onInputValues(e)}
                    name="driver_qualification_valid_from"
                    id={"driver_qualification_valid_from"}
                    max={new Date().toISOString().split("T")[0]}
                    value={formData.driver_qualification_valid_from}
                    lg={4}
                  />
                  <Input
                    type="date"
                    label="Valid To"
                    changeHandler={(e) => onInputValues(e)}
                    name="driver_qualification_valid_to"
                    id={"driver_qualification_valid_to"}
                    min={new Date().toISOString().split("T")[0]}
                    value={formData.driver_qualification_valid_to}
                    lg={4}
                  />
                </>
              ) : (
                ""
              )}
              {/* Valid CPC Yes Content - End  */}

              {/* Valid CPC No Content - Start  */}
              {driverCPC == false ? (
                <>
                  <Input
                    type="number"
                    label="How many hours training have you completed?"
                    changeHandler={(e) => onInputValues(e)}
                    placeholder={"eg. 2"}
                    min={"1"}
                    name="driver_qualification_how_many_hrs_training_completed"
                    id={"driver_qualification_how_many_hrs_training_completed"}
                    value={
                      formData.driver_qualification_how_many_hrs_training_completed
                    }
                    lg={6}
                  />
                </>
              ) : (
                ""
              )}
              {/* Valid CPC No Content - End  */}
            </div>

            <div className="row m-b20 border border-grey p-3">
              <div className="job-bx-title clearfix mb-3">
                <h6 className="text-sm pl-3 mb-0">
                  Accidents
                  <sup className="text-danger">
                    <b>*</b>
                  </sup>
                </h6>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Have you had any ‘at fault’ road traffic accidents in the past five years?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="accidents_details_have_you_any_road_accidents_in_last_five_years"
                    checked={
                      formData.accidents_details_have_you_any_road_accidents_in_last_five_years ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "accidents_details_have_you_any_road_accidents_in_last_five_years"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    type="radio"
                    id={
                      "accidents_details_have_you_any_road_accidents_in_last_five_years_yes"
                    }
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.accidents_details_have_you_any_road_accidents_in_last_five_years ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "accidents_details_have_you_any_road_accidents_in_last_five_years"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    name="accidents_details_have_you_any_road_accidents_in_last_five_years"
                    type="radio"
                    id={
                      "accidents_details_have_you_any_road_accidents_in_last_five_years_no"
                    }
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="accidents_details_have_you_any_road_accidents_in_last_five_years_msg"
                  ></div>
                </div>
              </div>

              {driverAccident == true ? (
                <>
                  <Textarea
                    name="accidents_details_road_accidents_in_last_five_years_description"
                    id={
                      "accidents_details_road_accidents_in_last_five_years_description"
                    }
                    changeHandler={(e) => onInputValues(e)}
                    value={
                      formData.accidents_details_road_accidents_in_last_five_years_description
                    }
                    label="Description"
                    lg={12}
                  />
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row m-b20 border border-grey p-3">
              <div className="job-bx-title clearfix mb-3">
                <h6 className="text-sm pl-3 mb-0">
                  Driving Bans/Convictions/Points
                  <sup className="text-danger">
                    <b>*</b>
                  </sup>
                </h6>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Have you been banned from driving in the last five years?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="driving_bans_details_have_you_ban_from_driving_for_last_5_years"
                    checked={
                      formData.driving_bans_details_have_you_ban_from_driving_for_last_5_years ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "driving_bans_details_have_you_ban_from_driving_for_last_5_years"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    type="radio"
                    id={
                      "driving_bans_details_have_you_ban_from_driving_for_last_5_years_yes"
                    }
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.driving_bans_details_have_you_ban_from_driving_for_last_5_years ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "driving_bans_details_have_you_ban_from_driving_for_last_5_years"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    name="driving_bans_details_have_you_ban_from_driving_for_last_5_years"
                    type="radio"
                    id={
                      "driving_bans_details_have_you_ban_from_driving_for_last_5_years_no"
                    }
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="driving_bans_details_have_you_ban_from_driving_for_last_5_years_msg"
                  ></div>
                </div>
              </div>

              {driverBanFiveYear == true ? (
                <>
                  <Textarea
                    name="driving_bans_details_ban_from_driving_for_last_5_yrs_description"
                    id={
                      "driving_bans_details_ban_from_driving_for_last_5_yrs_description"
                    }
                    changeHandler={(e) => onInputValues(e)}
                    value={
                      formData.driving_bans_details_ban_from_driving_for_last_5_yrs_description
                    }
                    label="Description"
                    lg={12}
                  />
                </>
              ) : (
                ""
              )}

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Do you have any motoring convictions/points on your license?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="driving_bans_details_do_you_have_any_motor_conviction"
                    checked={
                      formData.driving_bans_details_do_you_have_any_motor_conviction ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "driving_bans_details_do_you_have_any_motor_conviction"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    type="radio"
                    id={
                      "driving_bans_details_do_you_have_any_motor_conviction_yes"
                    }
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.driving_bans_details_do_you_have_any_motor_conviction ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "driving_bans_details_do_you_have_any_motor_conviction"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    name="driving_bans_details_do_you_have_any_motor_conviction"
                    type="radio"
                    id={
                      "driving_bans_details_do_you_have_any_motor_conviction_no"
                    }
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="driving_bans_details_do_you_have_any_motor_conviction_msg"
                  ></div>
                </div>
              </div>

              {/* Add More Form - Start */}

              {/* <Label labelName="Motoring Convictions/Points Details" req={false} />      {JSON.stringify(inputFields)} */}

              {driverBanMultiForm == true ? (
                <>
                  <div className="container">
                    <div className="row" ref={formIdsRef}>
                      {inputFields.map((inputField, index) => (
                        <React.Fragment key={index}>
                          <div
                            className="job-bx-title clearfix mb-3 text-dark p-3 text-sm"
                            style={{ fontSize: "100%" }}
                          >
                            <b>
                              Motoring Convictions/Points Details{" "}
                              {inputFields &&
                                inputFields.length > 1 &&
                                (index >= 0 ? " - " + (index + 1) : "")}
                              {index > 0 ? (
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip("Remove")}
                                  trigger="hover"
                                >
                                  <a
                                    className="btn btn-danger text-white btn-sm pull-right"
                                    onClick={() => handleRemoveFields(index)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}
                            </b>
                          </div>

                          <Input
                            type="date"
                            label="Date"
                            changeHandler={(event) =>
                              handleChangeInput(index, event)
                            }
                            max={new Date().toISOString().split("T")[0]}
                            name="motor_convictions_date"
                            id={"motor_convictions_date_" + index}
                            value={inputField.motor_convictions_date}
                            lg={6}
                          />

                          <Input
                            type="text"
                            label="Offence"
                            changeHandler={(event) =>
                              handleChangeInput(index, event)
                            }
                            name="motor_convictions_offense"
                            id={"motor_convictions_offense_" + index}
                            value={inputField.motor_convictions_offense}
                            lg={6}
                          />

                          <Input
                            type="text"
                            label="Endorsement Code"
                            changeHandler={(event) =>
                              handleChangeInput(index, event)
                            }
                            name="motor_convictions_endorsement_code"
                            id={"motor_convictions_endorsement_code_" + index}
                            value={
                              inputField.motor_convictions_endorsement_code
                            }
                            lg={6}
                          />

                          <Input
                            type="text"
                            label="Fine/Points/Suspension etc"
                            changeHandler={(event) =>
                              handleChangeInput(index, event)
                            }
                            name="motor_convictions_fine_or_points_or_suspension"
                            id={
                              "motor_convictions_fine_or_points_or_suspension_" +
                              index
                            }
                            value={
                              inputField.motor_convictions_fine_or_points_or_suspension
                            }
                            lg={6}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip("Add More")}
                    trigger="hover"
                  >
                    <p>
                      <a
                        className="btn btn-primary text-white pull-right"
                        onClick={() => handleAddFields()}
                      >
                        <i className="fa fa-plus-square"></i>
                      </a>
                    </p>
                  </OverlayTrigger>
                </>
              ) : (
                ""
              )}

              {/* Addd More Form - End */}
            </div>

            <div className="row m-b20 border border-grey p-3">
              <div className="job-bx-title clearfix mb-3">
                <h6 className="text-sm pl-3 mb-0">
                  Medical (employees only)
                  <sup className="text-danger">
                    <b>*</b>
                  </sup>
                </h6>
                <p className="text-sm pl-3 mb-0" style={{ fontSize: "70%" }}>
                  Please note that you may be required to undergo a medical
                  examination, which may include a drug and alcohol test before
                  any offer of employment can be confirmed. A disability or
                  health problem does not preclude full consideration for the
                  job, and applications are welcome from people with
                  disabilities.
                </p>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Are you currently taking any prescribed or non-prescribed drugs or medication?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="medical_details_prescribed_or_non_prescribed_drugs_or_medication"
                    checked={
                      formData.medical_details_prescribed_or_non_prescribed_drugs_or_medication ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "medical_details_prescribed_or_non_prescribed_drugs_or_medication"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    type="radio"
                    id={
                      "medical_details_prescribed_or_non_prescribed_drugs_or_medication_yes"
                    }
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.medical_details_prescribed_or_non_prescribed_drugs_or_medication ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={() =>
                      showDescription(
                        "medical_details_prescribed_or_non_prescribed_drugs_or_medication"
                      )
                    }
                    onChangeCapture={(e) => onInputValues(e)}
                    name="medical_details_prescribed_or_non_prescribed_drugs_or_medication"
                    type="radio"
                    id={
                      "medical_details_prescribed_or_non_prescribed_drugs_or_medication_no"
                    }
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="medical_details_prescribed_or_non_prescribed_drugs_or_medication_msg"
                  ></div>
                </div>
              </div>

              {medicalDrugs == true ? (
                <>
                  <Textarea
                    name="medical_details_prescribed_non_prescribed_drugs_medication_desc"
                    id={
                      "medical_details_prescribed_non_prescribed_drugs_medication_desc"
                    }
                    changeHandler={(e) => onInputValues(e)}
                    value={
                      formData.medical_details_prescribed_non_prescribed_drugs_medication_desc
                    }
                    label="Description"
                    lg={12}
                  />
                </>
              ) : (
                ""
              )}
              <div
                className={"text-danger mb-0 ml-3"}
                id="medical_details_prescribed_non_prescribed_drugs_medication_desc_msg"
              ></div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Do you have a DVLA notifiable condition?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="medical_details_dvla_notifiable_condition"
                    checked={
                      formData.medical_details_dvla_notifiable_condition == "1"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    type="radio"
                    id={"medical_details_dvla_notifiable_condition_yes"}
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.medical_details_dvla_notifiable_condition == "0"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    name="medical_details_dvla_notifiable_condition"
                    type="radio"
                    id={"medical_details_dvla_notifiable_condition_no"}
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="medical_details_dvla_notifiable_condition_msg"
                  ></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Do you require glasses/corrective lenses for driving?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="medical_details_glasses_or_lenses_for_driving"
                    checked={
                      formData.medical_details_glasses_or_lenses_for_driving ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    type="radio"
                    id={"medical_details_glasses_or_lenses_for_driving_yes"}
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.medical_details_glasses_or_lenses_for_driving ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    name="medical_details_glasses_or_lenses_for_driving"
                    type="radio"
                    id={"medical_details_glasses_or_lenses_for_driving_no"}
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="medical_details_glasses_or_lenses_for_driving_msg"
                  ></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Does the code for wearing glasses (01) show on your license?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="medical_details_wearing_glasses_01_show_on_your_license"
                    checked={
                      formData.medical_details_wearing_glasses_01_show_on_your_license ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    type="radio"
                    id={
                      "medical_details_wearing_glasses_01_show_on_your_license_yes"
                    }
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.medical_details_wearing_glasses_01_show_on_your_license ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    name="medical_details_wearing_glasses_01_show_on_your_license"
                    type="radio"
                    id={
                      "medical_details_wearing_glasses_01_show_on_your_license_no"
                    }
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="medical_details_wearing_glasses_01_show_on_your_license_msg"
                  ></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Do you wear a hearing aid when you drive?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="medical_details_wear_hearing_aid_when_you_drive"
                    checked={
                      formData.medical_details_wear_hearing_aid_when_you_drive ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    type="radio"
                    id={"medical_details_wear_hearing_aid_when_you_drive_yes"}
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.medical_details_wear_hearing_aid_when_you_drive ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    name="medical_details_wear_hearing_aid_when_you_drive"
                    type="radio"
                    id={"medical_details_wear_hearing_aid_when_you_drive_no"}
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="medical_details_wear_hearing_aid_when_you_drive_msg"
                  ></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="If yes, have you informed the DVLA?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="medical_details_have_you_informed_the_dvla"
                    checked={
                      formData.medical_details_have_you_informed_the_dvla == "1"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    type="radio"
                    id={"medical_details_have_you_informed_the_dvla_yes"}
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.medical_details_have_you_informed_the_dvla == "0"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    name="medical_details_have_you_informed_the_dvla"
                    type="radio"
                    id={"medical_details_have_you_informed_the_dvla_no"}
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="medical_details_have_you_informed_the_dvla_msg"
                  ></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Does the code for wearing a hearing aid (02) show on your license?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="medical_details_code_for_wearing_hearing_aid_02_show_on_license"
                    checked={
                      formData.medical_details_code_for_wearing_hearing_aid_02_show_on_license ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    type="radio"
                    id={
                      "medical_details_code_for_wearing_hearing_aid_02_show_on_license_yes"
                    }
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.medical_details_code_for_wearing_hearing_aid_02_show_on_license ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    name="medical_details_code_for_wearing_hearing_aid_02_show_on_license"
                    type="radio"
                    id={
                      "medical_details_code_for_wearing_hearing_aid_02_show_on_license_no"
                    }
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="medical_details_code_for_wearing_hearing_aid_02_show_on_license_msg"
                  ></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 m-b0">
                <Label labelName="Are you aware of any reason(s) which may impact on your ability to drive distances?" />
                <div className="form-group ml-3">
                  <Form.Check
                    outline
                    label={"Yes"}
                    value={"1"}
                    name="medical_details_aware_of_any_reason_to_impact_on_ability_driving"
                    checked={
                      formData.medical_details_aware_of_any_reason_to_impact_on_ability_driving ==
                      "1"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    type="radio"
                    id={
                      "medical_details_aware_of_any_reason_to_impact_on_ability_driving_yes"
                    }
                  />

                  <Form.Check
                    outline
                    label={"No"}
                    value={"0"}
                    checked={
                      formData.medical_details_aware_of_any_reason_to_impact_on_ability_driving ==
                      "0"
                        ? true
                        : false
                    }
                    onChange={(e) => onInputValues(e)}
                    name="medical_details_aware_of_any_reason_to_impact_on_ability_driving"
                    type="radio"
                    id={
                      "medical_details_aware_of_any_reason_to_impact_on_ability_driving_no"
                    }
                  />
                  <div
                    className={"text-danger mb-0"}
                    id="medical_details_aware_of_any_reason_to_impact_on_ability_driving_msg"
                  ></div>
                </div>
              </div>
            </div>

            <div className="row m-b20 ">
              <div className="job-bx-title clearfix mb-3">
                <h6 className="text-sm pl-3 mb-0">
                  Declaration
                  <sup className="text-danger">
                    <b>*</b>
                  </sup>
                </h6>
              </div>

              <p className="ml-4 mb-2" style={{ fontSize: "100%" }}>
                <ul>
                  <li>
                    I confirm that to the best of my knowledge all information I
                    have given in my application is correct.
                  </li>
                  <li>
                    I confirm that I have produced my most recent license.
                  </li>
                  <li>
                    I confirm I do not have any pending convictions,
                    endorsements or disqualifications.
                  </li>
                  <li>
                    I confirm I will inform Countrystyle of any road traffic
                    accidents, convictions, endorsements or disqualifications I
                    receive whilst employed by Countrystyle and/or using a
                    Countrystyle vehicle.
                  </li>
                  <li>
                    I have had no change in my health, which could affect my
                    entitlement to drive ANY vehicle and will notify
                    Countrystyle/DVLA of any future change.
                  </li>
                  <li>
                    I give Countrystyle my consent to using my personal data for
                    personnel, management and monitoring purposes.
                  </li>
                </ul>
              </p>

              {/* Consent Start */}
              <Form.Group as={Col} md={12} className="ml-2">
                {changeConsent == true ? (
                  <>
                    <Form.Check
                      outline
                      label={
                        "I hereby declare that the information provided is true and correct."
                      }
                      checked={true}
                      onChange={(e) => agreeDisagreeCondition(e)}
                      value={"1"}
                      type="checkbox"
                      name="cnd_driver_details_consent"
                      id="cnd_driver_details_consent"
                    />
                  </>
                ) : (
                  <>
                    <Form.Check
                      outline
                      label={
                        "I hereby declare that the information provided is true and correct."
                      }
                      onChange={(e) => agreeDisagreeCondition(e)}
                      value={"1"}
                      type="checkbox"
                      name="cnd_driver_details_consent"
                      id="cnd_driver_details_consent"
                    />
                  </>
                )}

                <div
                  className={"text-danger mb-0 ml-3"}
                  id="cnd_driver_details_consent_msg"
                ></div>
              </Form.Group>
              {/* Consent End */}
            </div>
          </React.Fragment>
          <Button
            disabled={loader}
            className="site-button m-b30 pull-right mr-3"
            onClick={(e) => updateDriverDetails(e)}
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader
              ? props.driverDetails.driver_license_details_license_no
                ? "Update & Next"
                : "Save & Next"
              : "Loading..."}
          </Button>
          {/* <button className="site-button m-b30" onClick={(e)=>updateUserProfilePersonalInfo(e)}>Save Setting</button> */}
        </form>
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    driverDetails: state.driverDetails.driverDetails,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(DriverLicenseForm);
