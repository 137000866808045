import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AppoinmentContainer from "../../../containers/AppoinmentContainer";
import AppoinmentForm from "./AppoinmentForm";
const Appoinments = (props) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Assesment Schedule
          </h5>
        </div>
        <AppoinmentForm />
      </div>
    </React.Fragment>
  );
};

export default AppoinmentContainer(Appoinments);
