const initialState = {identityDocument: []}

export const identityDocument = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_IDENTITY_DOCUMENT':
      return {identityDocument: action.payload}; 
  }
  return state
}


