import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch,useSelector } from "react-redux";
import { loadloginInfo} from "./../redux/actions/AuthActions";
import {isAuthenticated} from "../redux/selectors/AuthSelectors"
import { useHistory } from "react-router-dom";
import Input from "../components/FormElements/Input";
import { Helmet, HelmetProvider } from "react-helmet-async";

var bnr = require("./../images/banner/bnr2.jpg");

const Loginpage = (props) => {
    // Add title
    document.getElementById("titleText").innerText = "Staffing Connect - Login Page";

    let history  = useHistory();
    const [formData, setFormData] = useState({});
    let users_password, users_password_msg, users_email, users_email_msg;
    let applyErrors = false;
    const [userType, setUserType] = useState("candidate");
    const dispatch = useDispatch();

    const handleChange = (e) => {
      e.preventDefault();
  
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
     });

      // Fields Start
      users_password = document.getElementById("users_password");
      users_email = document.getElementById("users_email");
      // Fields End

      // Msg Start
      users_password_msg = document.getElementById("users_password_msg");
      users_email_msg = document.getElementById("users_email_msg");
      // Msg End

      // Current Password Start
      if(!users_password.value){
          applyErrors = true;
          users_password.classList.add("border-danger");
          users_password_msg.innerText = "Please enter the password!";
      }else if(users_password.value.trim() == ""){
          applyErrors = true;
          users_password.classList.add("border-danger");
          users_password_msg.innerText = "Please enter the valid password!";
      }else if(users_password.value.length < 6){
          applyErrors = true;
          users_password.classList.add("border-danger");
          users_password_msg.innerText = "Password must have 6 or more characters!";
      }else{
          users_password.classList.remove("border-danger");
          users_password_msg.innerText = "";
      }
      // Current Password End
      
      // Users Email Start
      if(!users_email.value){
          applyErrors = true;
          users_email.classList.add("border-danger");
          users_email_msg.innerText = "Please enter the email address!";
      }else if(users_email.value.trim() == "" || !users_email.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
          applyErrors = true;
          users_email.classList.add("border-danger");
          users_email_msg.innerText = "Please enter the valid email address!";
      }else{
          users_email.classList.remove("border-danger");
          users_email_msg.innerText = "";
      }
      // Users Email End
    }

    const onLogin = (e) => {
      let formData;
      e.preventDefault();
      // Fields Start
      users_password = document.getElementById("users_password");
      users_email = document.getElementById("users_email");
      // Fields End

      // Msg Start
      users_password_msg = document.getElementById("users_password_msg");
      users_email_msg = document.getElementById("users_email_msg");
      // Msg End

      // Current Password Start
      if(!users_password.value){
          applyErrors = true;
          users_password.classList.add("border-danger");
          users_password_msg.innerText = "Please enter the password!";
      }else if(users_password.value.trim() == ""){
          applyErrors = true;
          users_password.classList.add("border-danger");
          users_password_msg.innerText = "Please enter the valid password!";
      }else if(users_password.value.length < 6){
          applyErrors = true;
          users_password.classList.add("border-danger");
          users_password_msg.innerText = "Password must have 6 or more characters!";
      }else{
          users_password.classList.remove("border-danger");
          users_password_msg.innerText = "";
      }
      // Current Password End
      
      // Users Email Start
      if(!users_email.value){
          applyErrors = true;
          users_email.classList.add("border-danger");
          users_email_msg.innerText = "Please enter the email address!";
      }else if(users_email.value.trim() == "" || !users_email.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
          applyErrors = true;
          users_email.classList.add("border-danger");
          users_email_msg.innerText = "Please enter the valid email address!";
      }else{
          users_email.classList.remove("border-danger");
          users_email_msg.innerText = "";
      }
      // Users Email End   
      formData = {
        users_email: users_email.value,
        users_role_id: userType,
      }
      let email, password;
      email = users_email.value;
      password = users_password.value;
      // console.log("All Data :=> "+JSON.stringify(formData));
      

      if(applyErrors == false){
        localStorage.setItem("users_email", JSON.stringify(formData));
        document.getElementById("loader").style.display = "block";
        dispatch(loadloginInfo({email,password,history}))
      }
    }
    
    const changeTab = (activeTab) => {
      setUserType(activeTab)
    }
    console.log(props.errorMessage)
  return (
    <>
    <React.Fragment>
      <div className="page-content">
        <div className="section-full content-inner-2 shop-account">
          <div className="container">
            <div className="max-w500 m-auto bg-white m-b30">
              <div className="p-a30 job-bx browse-job radius-sm seth loginSignup">
                <div className="tab-content nav ">
                  <form
                    id="login"
                    onSubmit={onLogin}
                    onInput={handleChange}
                    className="tab-pane active col-12 p-a0"
                    noValidate
                  >
                    <h1 style={{display:"none"}}>Staffing Connect - Login Page Details</h1>
                    <h4 className="font-weight-700">LOGIN</h4>
                    <p className="font-weight-600">
                      If you have an account with us, please log in.
                    </p>
                    {(props.errorMessage.length > 0) && (
                      <div className="bg-red-300 font-weight-bold text-red text-danger border border-danger p-1 my-2">
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                        {props.successMessage}
                      </div>
                    )}
                    {/* <div className="careerfy-user-options">                     
                        <ul className="nav nav-tabs custom-tabs">
                          <li className={`${(userType == "candidate") && "active"}`} id="login-candidate">
                            <Link to="#" onClick={()=>changeTab('candidate')} data-toggle="tab">
                              <i className="careerfy-icon careerfy-user"></i>
                              <h5 className="mb-0">Candidate</h5>
                              <p>
                                I want to discover awesome companies.
                              </p>
                            </Link>
                          </li>
                          <li className={`${(userType == "client") && "active"}`} id="login-employer" >
                            <Link to="#" data-toggle="tab" onClick={()=>changeTab('client')}>
                              <i className="careerfy-icon careerfy-building"></i>
                              <h5 className="mb-0">Client</h5>
                              <p>I want to attract the best talent.</p>
                            </Link>
                          </li>
                        </ul>
                    </div> */}
                    <div className="row mb-3">
                      {/* <label className="font-weight-700"> *</label> */}
                      <Input
                        label="E-MAIL"
                        name="users_email"
                        id={"users_email"}
                        className="form-control"
                        placeholder="Your Email Address"
                        type="email"
                        onChange={(e) => handleChange()}
                        lg={12}
                      />
                     
                      {/* <label className="font-weight-700"> *</label> */}
                      <Input
                        name="users_password"
                        label="PASSWORD"
                        className="form-control"
                        placeholder="Type Password"
                        id={"users_password"}
                        type="password"
                        onChange={(e) => handleChange()}
                        lg={12}
                      />
                    </div>

                    <div className="text-left">
                      <button className="site-button m-r5 button-lg">
                        login
                      </button>
                      <a
                        href="/forgot-password"
                        className="text-primary m-l5 m-t15 forget-pass float-right"
                      >
                        <i className="fa fa-unlock-alt" /> Forgot Password?
                      </a> 
                    </div>
                    <div className="text-left mt-3">
                      If you are not registered with us, <a href="/register" className="text-primary">Click here for Signup.</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    authData:isAuthenticated(state),
    errorMessage:state.error.error
  };
};

export default connect(mapStateToProps)(Loginpage)
 
