import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import Jobfindbox from "../components/Element/Jobfindbox";
import { allJobs } from "../services/Data";
import { redirectUrl } from "../services/GlobalUrls";
import Background from "../images/new-updated-images/job-board/permanent-jobs/job-board-permanent-job.jpg";
import {
  jobContractType,
  jobTypeList,
  jobsEmploymentHours,
  jobsSalarytype,
} from "../services/JobTypeAndSectorGlobalList";
import logo2 from "../images/logo.png";
import { isMobileOrLaptop } from "../services/CheckDevice";
function includesIgnoringSpaces(str1, str2) {
  const formattedStr1 = str1.replace(/\s/g, "");
  const formattedStr2 = str2.replace(/\s/g, "");
  return formattedStr1.includes(formattedStr2);
}

const userType = localStorage.getItem("user_job_type");
const cndStatus = localStorage.getItem("permanentUserStatus");

const JobBoardMobile = ({ callArray }) => {
  const [recordsPerPage] = useState(6);
  const [allJobsRecords, setAllJobsRecords] = useState([]);
  const [filterJobsRecords, setAllFilterJobsRecords] = useState([]);
  const [area, setArea] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const location = useLocation();
  const data = (location && location.state) || {};
  const [maxHeight, setMaxHeight] = useState("auto");
  const [currentPage, setCurrentPage] = useState(1);
  const [descriptionTextSize, setDescriptiongTextSize] = useState(0);
  const [jobsNotFound, setJobssNotFound] = useState(false);
  const [jsonArray, setJsonArray] = useState([]);

  // Sanitize Html Code - Start
  const sanitizeHtmlContent = (htmlContentCall) => {
    const htmlContent = `${
      '<text style={{color:"red"}}>' + htmlContentCall + "</text>"
    }`;

    const sanitizedHtmlContent = htmlContent
      .replace(/\s+/g, " ")
      .replace(/;/g, "")
      .replace(/\s+/g, "   ");

    return sanitizedHtmlContent;
  };
  // Sanitize Html Code - End

  // Remove Html Content - Start
  const removeHtmlTagsAndAttributes = (html) => {
    // Remove HTML tags
    const cleanHtml = html.replace(/<[^>]*>/g, "");
    // Remove HTML attributes
    const cleanText = cleanHtml.replace(
      /\s*([\w-]+)\s*=\s*("[^"]*"|'[^']*'|\w+)\s*/g,
      ""
    );
    return cleanText;
  };
  // Remove Html Content - End

  const hideReadContent = () => {
    const multiIdsDiv = document.getElementById("mainJobContent");

    const elementsInsideDiv = multiIdsDiv.querySelectorAll("[id]");

    elementsInsideDiv.forEach((element) => {
      if (element.id && element.id.substr(0, 11) == "description") {
        let descriptionContent = document.getElementById(element.id);
        if (descriptionContent) {
          descriptionContent.style.display = "none";
        }
      }

      if (element.id && element.id.substr(0, 8) == "see_more") {
        let see_more = document.getElementById(element.id);
        if (see_more) {
          see_more.style.display = "block";
        }
      }
      if (element.id && element.id.substr(0, 8) == "see_less") {
        let see_less = document.getElementById(element.id);
        if (see_less) {
          see_less.style.display = "none";
        }
      }
    });
    // Multiple Form - End
  };

  // Read Less And More - Start
  const readLessAndMore = (callParam, callId, see_more, see_less) => {
    let callIdExecute = document.getElementById(callId);
    let seeMore = document.getElementById(see_more);
    let seeLess = document.getElementById(see_less);
    if (callParam == "see_more") {
      setMaxHeight("auto");
      if (callIdExecute) {
        callIdExecute.style.display = "block";
      }
      if (seeMore) {
        seeMore.style.display = "none";
      }
      if (seeLess) {
        seeLess.style.display = "block";
      }
      setDescriptiongTextSize(130);
    } else {
      setMaxHeight("250px");
      if (callIdExecute) {
        callIdExecute.style.display = "none";
      }
      if (seeMore) {
        seeMore.style.display = "block";
      }
      if (seeLess) {
        seeLess.style.display = "none";
      }
      setDescriptiongTextSize(0);
    }
  };
  // Read Less and More - End

  useEffect(() => {
    setAllJobsRecords(callArray);
    if (!data.isHomePage) {
      setAllFilterJobsRecords(callArray);
    }
  }, [callArray]);
  // Slice the data array to display only the data for the current page
  useEffect(() => {
    if (data.isHomePage) {
      handleSubmit(data.title, data.area, data.category);
      window.scrollTo(0, 400);
    }
  }, [area, title, category]);

  useEffect(() => {
    const listItems = document.querySelectorAll(".post-bx");
    let max = 0;

    listItems.forEach((item) => {
      const height = item.getBoundingClientRect().height;
      if (height > max) {
        max = height;
      }
    });

    setMaxHeight(max > 0 ? max + "px" : "250px");
  }, [allJobsRecords]);

  // Pagination logic
  const totalJobs = filterJobsRecords.length;
  const totalPages = Math.ceil(totalJobs / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = Math.min(startIndex + recordsPerPage, totalJobs);

  function handlePaginationClick(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleSubmit(title1 = "", area1 = "", category1 = "") {
    const filteredJobs = allJobsRecords.filter((job) => {
      const { jobs_location_town, jobs_title, job_sector_title } = job;
      const lowerCaseArea = jobs_location_town
        ? jobs_location_town.toLowerCase()
        : "";
      const lowerCaseTitle = jobs_title ? jobs_title.toLowerCase() : "";
      const lowerCaseCategory = job_sector_title
        ? job_sector_title.toLowerCase()
        : "";

      if (
        (title1 &&
          !includesIgnoringSpaces(lowerCaseTitle, title1.toLowerCase())) ||
        (area1 &&
          !includesIgnoringSpaces(lowerCaseArea, area1.toLowerCase())) ||
        (category1 &&
          !includesIgnoringSpaces(lowerCaseCategory, category1.toLowerCase()))
      ) {
        setJobssNotFound(true);
        return false;
      }
      setJobssNotFound(false);
      return true;
    });

    setArea(area1);
    setTitle(title1);
    setCategory(category1);
    setAllFilterJobsRecords(filteredJobs);
    // setAllJobsRecords(filteredJobs);
    setCurrentPage(1); // Reset to first page when filters change
  }

  // Generate Json Code - Google Jobs
  useEffect(() => {
    if (allJobsRecords) {
      let jobPosting = []; // Declare as mutable variable
      jobPosting = JSON.stringify(
        allJobsRecords.map((job) => ({
          "@context": "https://schema.org/",
          "@type": "JobPosting",
          url: "https://www.staffingconnect.co.uk/job-details/" + job.jobs_id,
          site: "staffingconnect.co.uk",
          title: job.jobs_title,
          description:
            "<p>" + removeHtmlTagsAndAttributes(job.jobs_description) + "</p>",
          identifier: {
            "@type": "PropertyValue",
            name: job.jobs_title + " at Staffing Connect",
            value: "1234567" + job.jobs_id,
          },
          datePosted: job.datePosted,
          validThrough: job.jobs_end_date + "T00:00",
          employmentType:
            job.jobs_job_type_contract_type == "permanentrequirement"
              ? "FULL_TIME"
              : job.jobs_job_type_contract_type == "temporaryrequirement"
              ? "TEMPORARY"
              : job.jobs_job_type_contract_type == "temproryandpermanent"
              ? "OTHER"
              : "OTHER",
          hiringOrganization: {
            "@type": "Organization",
            name: "Staffing Connect",
            sameAs: "https://www.staffingconnect.co.uk/job-board",
            logo: "https://admin.itstaffingsolutions.co.uk/assets/img/brand/staffing-new.png",
          },
          jobLocation: {
            "@type": "Place",
            address: {
              "@type": "PostalAddress",
              streetAddress: job.jobs_location_address_1,
              addressLocality: job.jobs_location_town,
              addressRegion: job.jobs_location_region
                ? job.jobs_location_region
                : job.jobs_location_town,
              postalCode: job.jobs_location_post_code,
              addressCountry: "UK",
            },
          },
          baseSalary: {
            "@type": "MonetaryAmount",
            currency: "GBP",
            value: {
              "@type": "QuantitativeValue",
              value: job.jobs_salary_details_min_salary,
              // "maxValue": job.jobs_salary_details_max_salary,
              unitText:
                job.jobs_salary_details_salary_type == "per_hour"
                  ? "HOUR"
                  : job.jobs_salary_details_salary_type == "per_day"
                  ? "DAY"
                  : job.jobs_salary_details_salary_type == "per_annum"
                  ? "YEAR"
                  : "MONTH",
            },
            application:
              "https://www.staffingconnect.co.uk/job-details/" + job.jobs_id,
          },
          // Add other fields for each job posting
        }))
      );

      // console.log("Job Posting Array :=> " + jobPosting);
      setJsonArray(jobPosting);
      // let jsonJobs = document.getElementById('jsonJobs');

      // if(jsonJobs){
      //   jsonJobs.innerHTML = jobPosting;
      // }
    }
  }, [allJobsRecords]);
  // Generate Json Code - Google Jobs

  // Render pagination buttons
  function renderPaginationButtons() {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <li key={i} className={currentPage === i ? "active" : ""}>
          <Link
            to="#"
            onClick={(event, newInputValue) => {
              window.scrollTo(0, 300);
              handlePaginationClick(i);
              hideReadContent();
            }}
          >
            {i}
          </Link>
        </li>
      );
    }
    return buttons;
  }

  // Add title
  if (window.location.pathname == "/job-board") {
    document.getElementById("titleText").innerText =
      "Staffing Connect - Job Board Page";
  } else {
    document.getElementById("titleText").innerText =
      "Staffing Connect - Job Board(Permanent Jobs)";
  }

  let jobUrl = "";
  if (userType) {
    if (userType == "permanentrequirement") {
      if (cndStatus) {
        jobUrl = "/applied-jobs";
      } else {
        jobUrl = "/permanent-job-profile";
      }
    } else {
      jobUrl = "/dashboard";
    }
  } else {
    jobUrl = "/login";
  }

  // useEffect(() => {
  //   // Check if jsonArray is not empty
  //   if (jsonArray && jsonArray.length > 0) {
  //     // Create a new script element
  //     const script = document.createElement("script");
  //     script.type = "application/ld+json";
  //     script.innerHTML = jsonArray;

  //     // Find the head element and insert the script after the title element
  //     const head = document.querySelector("head");
  //     const title = head.querySelector("title");
  //     if (title) {
  //       head.insertBefore(script, title.nextSibling);
  //     } else {
  //       head.appendChild(script); // If title tag not found, append at the end of head
  //     }
  //   }
  // }, [jsonArray]);

  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  return (
    <>
      {/* <div>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonArray }} />      
      </div> */}
      <div
        className="dez-bnr-inr content-inner-2 dez-bnr-inr-md overlay-black-middle mt-10"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="container">
          <div className="dez-bnr-inr-entry">
            <h1 className="text-white">
              {area || title || category ? "Find Jobs" : "Job Board"}
            </h1>
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>{area || title || category ? "Find Jobs" : "Job Board"}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full browse-job-find">
        {/* {JSON.stringify(callArray)} */}
        <Jobfindbox
          allJobs={allJobsRecords}
          filteredJobs={filterJobsRecords}
          area={area}
          setArea={setArea}
          category={category}
          title={title}
          setTitle={setTitle}
          handleSubmit={handleSubmit}
        />
      </div>
      {/* <div
                className="dez-bnr-inr content-inner-2 dez-bnr-inr-md overlay-black-middle mt-10"
                style={{backgroundImage: `url(${Background})`}}
            >
             <div className="container ">
                    <div className="dez-bnr-inr-entry align-m ">
                        <h1 className="text-white">
                            <b style={{display: "none"}}>
                                Staffing Connect -{" "}
                                {window.location.pathname == "/job-board" &&
                                    "Explore information jobs here"}{" "}
                                {window.location.pathname != "/job-board" &&
                                    "Explore information perm job here"}{" "}
                            </b>
                            Job Board
                        </h1>
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li>
                                    <Link to={"/"}>Home</Link>
                                </li>
                                <li>Job Board</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Jobfindbox
                    allJobs={allJobsRecords}
                    area={area}
                    setArea={setArea}
                    category={category}
                    title={title}
                    setTitle={setTitle}
                    handleSubmit={handleSubmit}
                />
            </div> */}

      <div className="page-content bg-white">
        {/* <Backgroundimage
          backgroundImage={require("../images/new-updated-images/job-board/permanent-jobs/job-board-permanent-job.jpg")}
          pageTitle="Job Board"
        /> */}

        <div className="content-block ">
          {/* <div className="container text-left mb-0">
            <h6 className="mb-0">
              Here you will find a selection of ongoing vacancies we have
              available.
            </h6>
            <p className="mb-0 mt-2">
              <b>Please note:</b> all of our candidates are required to undergo
              our registration process, which, in addition to a CV screening,
              telephone screening and face-to-face interview, entails a thorough
              qualification and identification check. A Criminal Records Bureau
              check, organised by us, is also required of all candidates.
            </p>
            <p className="mb-0 mt-2">
              If you are interested in applying for any of these roles or would
              like to enquire about other vacancies in Social Care, Education
              and Homecare, please email your CV to{" "}
              <a
                href="mailto:info@staffingconnect.co.uk"
                className="text-primary"
              >
                info@staffingconnect.co.uk
              </a>{" "}
              outlining which vacancy or type of work you would like to be
              considered for.
            </p>
          </div> */}

          {filterJobsRecords && filterJobsRecords.length > 0 ? (
            <>
              <div
                className="section-full bg-white browse-job p-b50 "
                style={{ marginTop: "0%" }}
              >
                <div className="container">
                  <ul
                    className="post-job-bx browse-job-grid row"
                    style={{ display: "flex", flexWrap: "wrap" }}
                    id="mainJobContent"
                  >
                    {filterJobsRecords
                      .slice(startIndex, endIndex)
                      .map((item, index) => (
                        <>
                          {/* <div > */}
                          {/* <li className="col-lg-4 col-md-12"></li> */}
                          <li
                            className="col-lg-12 col-md-12"
                            key={index}
                            style={{
                              height: "auto",
                              // display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="post-bx p-4"
                              style={{
                                height: "100%",
                                // display: "flex",
                                borderRadius: "20px",
                                // flexDirection: "column",
                                // justifyContent: "space-between",
                                // position: "relative",
                              }}
                            >
                              {/* <div className="d-flex m-b30"> */}
                              <div className="row">
                                <div className="col-sm-12">
                                    <h3
                                      className="font-size-info mb-0 text-primary"
                                      style={{ fontWeight: "500" }}
                                    >
                                      <Link
                                        className="text-primary"
                                        to={{
                                          pathname: "/job-details",
                                          state: {
                                            jobDetails: item,
                                            searchBarDetails: {
                                              title,
                                              area,
                                              category,
                                            },
                                            allJobsRecordsData: allJobsRecords,
                                          },
                                        }}
                                        style={{fontSize:"80%"}}                                        
                                      >
                                        <text dangerouslySetInnerHTML={{
                                          __html:
                                            sanitizeHtmlContent(
                                              item.jobs_title.slice(
                                                0,
                                                String(item.jobs_title).length != 0
                                                  ? (String(item.jobs_title).length <= 30 ? item.jobs_title.length : 28)
                                                  : 28
                                              )
                                            )+ (item.jobs_title && item.jobs_title.length > 30 ? "..." : ""),
                                        }}></text>
                                        {/* {item.jobs_title+"Dkmhkhkhkjakskahkkjhkjhjkahskjhkhkjhkj"} */}
                                        {item.cnd_permanent_job_status == "Applied" ? (<button class="btn btn-success btn-sm p-1 ml-1" aria-hidden="true" style={{borderRadius:"10px", fontSize:"40%"}}>Applied</button>) : (item.cnd_permanent_job_status == "Draft" ? (<button class="btn btn-dark btn-sm p-1 ml-1" aria-hidden="true" style={{borderRadius:"10px", fontSize:"40%"}}>Draft</button>) : (''))}
                                      </Link>
                                    </h3>
                                    <text
                                      className="mb-1"
                                      style={{ fontSize: "90%" }}
                                    >
                                      {item.jobs_post_date}{" "}
                                      <a href="#" className="text-primary">
                                        Staffing Connect
                                      </a>
                                    </text>
                                    <ul className="nav navbar-nav">
                                      <li>
                                        <i
                                          className="fa fa-map-marker text-primary mr-2"
                                          style={{ fontSize: "18px" }}
                                        ></i>
                                        {item.jobs_location_town}
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-money text-primary mr-2"
                                          style={{ fontSize: "18px" }}
                                        ></i>
                                        {item.jobs_salary_details_display_salary_range_check ==
                                        1 ? (
                                          <>
                                            {"  £" +
                                              item.jobs_salary_details_min_salary +
                                              " - £" +
                                              item.jobs_salary_details_max_salary +
                                              " " +
                                              jobsSalarytype[
                                                item
                                                  .jobs_salary_details_salary_type
                                              ]}
                                          </>
                                        ) : (
                                          <>
                                            {item.jobs_salary_details_display_options
                                              ? item.jobs_salary_details_display_options +
                                                " Salary"
                                              : ""}
                                          </>
                                        )}
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa fa-clock-o text-primary mr-2"
                                          style={{ fontSize: "18px" }}
                                        ></i>
                                        {
                                          jobsEmploymentHours[
                                            item.jobs_job_type_employment_hours
                                          ]
                                        }
                                      </li>

                                      <li>
                                        <i
                                          className="fa fa-suitcase text-primary mr-2"
                                          style={{ fontSize: "18px" }}
                                        ></i>
                                        {
                                          (String(jobContractType[
                                            item.jobs_job_type_contract_type
                                          ]) && jobContractType[
                                            item.jobs_job_type_contract_type
                                          ].length <= 30 ? jobContractType[
                                            item.jobs_job_type_contract_type
                                          ] : jobContractType[
                                            item.jobs_job_type_contract_type
                                          ].slice(0,30)+"...")
                                        }
                                      </li>

                                      {item.jobs_location_remote_location_check ==
                                        1 && (
                                        <li>
                                          <i
                                            className="fa fa-home text-primary mr-2"
                                            style={{ fontSize: "18px" }}
                                          ></i>
                                          Work from home
                                        </li>
                                      )}

                                      <div className="col-sm-12 mt-2 mb-0">
                                        <text style={{ fontStyle: "bold" }}>
                                          <div
                                            id={"description" + index}
                                            style={{ display: "none" }}
                                            className="mt-2"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                sanitizeHtmlContent(
                                                  item.jobs_description.slice(
                                                    0,
                                                    item.jobs_description
                                                      .length != 0
                                                      ? item.jobs_description
                                                          .length / 10
                                                      : 150
                                                  )
                                                ) + "...",
                                            }}
                                          />

                                          <b className="text-dark">
                                            <a
                                              className="cursor-pointer pull-right"
                                              id={"see_more" + index}
                                              onClick={() =>
                                                readLessAndMore(
                                                  "see_more",
                                                  "description" + index,
                                                  "see_more" + index,
                                                  "see_less" + index
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-caret-down text-lg"
                                                style={{
                                                  bottom: "0",
                                                  fontSize: "18px",
                                                }}
                                              ></i>{" "}
                                              See more
                                            </a>
                                            <a
                                              className="cursor-pointer pull-right"
                                              style={{ display: "none" }}
                                              id={"see_less" + index}
                                              onClick={() =>
                                                readLessAndMore(
                                                  "see_less",
                                                  "description" + index,
                                                  "see_more" + index,
                                                  "see_less" + index
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-caret-up text-lg"
                                                style={{
                                                  bottom: "0",
                                                  fontSize: "18px",
                                                }}
                                              ></i>{" "}
                                              See less
                                            </a>
                                          </b>
                                        </text>
                                      </div>
                                    </ul>
                                </div>
                                {/* <div className="col-sm-3">
                                  <div
                                    className="job-post-info border border-dark p-4"
                                    style={{ borderRadius: "20px" }}
                                  >
                                    <center>
                                      <img
                                        src={logo2}
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      />
                                    </center>
                                  </div>
                                </div> */}
                              </div>
                              {/* </div> */}
                              {/* <div
                            className="flex-row-reverse mb-2"
                            style={{ alignSelf: "flex-end" }}
                          >
                            <div className="job-time-info">
                              <a
                                className="site-button green float-right mt-2 mb-0 text-white"
                                onClick={() => redirectUrl(jobUrl)}
                              >
                                Apply Now
                              </a>
                            </div>
                          </div> */}
                            </div>
                          </li>
                          {/* </div> */}
                        </>
                      ))}
                  </ul>

                  <div className="pagination-bx m-t30 responsive" style={{overflow:"scroll"}}>
                    <ul className="pagination">
                      <li className="previous">
                        <Link
                          to="#"
                          onClick={(event, newInputValue) => {
                            window.scrollTo(0, 200);
                            handlePaginationClick(Math.max(1, currentPage - 1));
                            hideReadContent();
                          }}
                        >
                          <i className="ti-arrow-left"></i>
                        </Link>
                      </li>
                      {renderPaginationButtons()}
                      <li className="next">
                        <Link
                          to="#"
                          onClick={(event, newInputValue) => {
                            handlePaginationClick(
                              Math.min(totalPages, currentPage + 1)
                            );
                            window.scrollTo(0, 200);
                            hideReadContent();
                          }}
                        >
                          <i className="ti-arrow-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {jobsNotFound == false && (
                <>
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <center>
                          <p>
                            <h1
                              style={{ fontSize: "100px" }}
                              className="text-center text-primary"
                            >
                              <i class="fa fa-spinner fa-pulse"></i>
                            </h1>
                            <h3 className="text-center">Please wait</h3>
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {jobsNotFound == true && (
                <>
                  <div
                    className="container text-center mt-4  mb-4"
                    style={{
                      backgroundColor: "#f8d7da",
                      color: "#721c24",
                      padding: "5px",
                      borderRadius: "25px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      fontWeight: "bold",
                    }}
                  >
                    <p className="mt-4">No Jobs found!</p>
                  </div>
                </>
              )}
              <br />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default JobBoardMobile;
