import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
/* eslint react/prop-types: 0 */
import styles from "./nav.css";
import personal from "../../../images/form-img/personal.png";
import service from "../../../images/form-img/service.png";
import agreement from "../../../images/form-img/agreement.png";
import declaration from "../../../images/form-img/declaration.png";
import cv from "../../../images/form-img/cv.png";
import manager from "../../../images/form-img/manager.png";
import physical from "../../../images/form-img/physical.png";
import social from "../../../images/form-img/social.png";
import nurse from "../../../images/form-img/nurse.png";
import aim from "../../../images/form-img/aim.png";
import { toast } from "react-toastify";
const Nav = (props) => {
  const steps = [
    service,
    declaration,
    personal,
    physical,
    manager,
    agreement,
    social,
    declaration,
    cv,
    manager,
    nurse,
    aim,
  ];
  const [currentStep, SetCurrentState] = useState(0);
  const { switchStep } = props;

  const { careplan } = props;
  const dots = [];

  const nextStep = (i) => {
    toast.dismiss();
    SetCurrentState(i);
    props.goToStep(i);
  };

  useEffect(() => {
    SetCurrentState(currentStep + 1);
    nextStep(currentStep + 1);
  }, [switchStep]);
  //((i <= nextForm && props.userData['form_'+props.currentStep] == 1) || props.currentStep >= i)&&
  for (let i = 1; i <= props.totalSteps; i += 1) {
    //let nextStep = i-1;
    //const isActive =  ((i <= props.currentStep || i == props.currentStep) || careplan['form_'+i] == 1);
    const isActive = careplan["form_" + i] == 1;
    let nextForm = props.currentStep + 1;
    dots.push(
      <span
        key={`step-${i}`}
        onClick={() =>
          (careplan["form_" + (i - 1)] == 1 || i == 1 || isActive) &&
          nextStep(i)
        }
      >
        <img
          src={steps[i - 1]}
          className={`step-images-care-plan ${
            isActive
              ? "active"
              : careplan["form_" + (i - 1)] == 1 || i == 1
              ? "on-process"
              : "in-active"
          }`}
        />
      </span>
    );
  }

  return <div className={styles.nav}>{dots}</div>;
};

const mapStateToProps = (state) => {
  return {
    userData: state.userProfile.userData,
    careplan: state.careplan.careplan,
    switchStep: state.switchStep.switchStep,
  };
};

export default connect(mapStateToProps)(Nav);
