import React from "react";
import PropTypes from "prop-types";
import Label from "../Layout/Label";
import { Form, Col } from "react-bootstrap";
const Dropdown = ({
  name,
  value,
  options,
  changeHandler,
  label,
  id,
  errors,
  width,
  disabled,
  lg,
  req,
  addWidth,
}) => {
  if (id) {
    let idVal = document.getElementById(id);
  }
  return (
    <>
      <Form.Group
        as={Col}
        lg={lg ? lg : "6"}
        controlId={id ? "" : name + value}
      >
        {label && <Label labelName={label} req={req} />}
        <Form.Control
          required
          as="select"
          id={id}
          name={name}
          custom
          onChange={(e) => changeHandler(e)}
          isInvalid={errors && errors[name] ? true : false}
          className={errors && errors[name] ? "form-control invalid" : "form-control valid"}
          disabled={disabled}
          width={addWidth}
        >
          {options.map((option, k) => (
            <option
              key={name + k}
              selected={value === option.value && "selected"}
              value={option.value}
            >
              {option.name}
            </option>
          ))}
        </Form.Control>
        {name &&
          (window.location.pathname == "/create-identity-document" ||
            window.location.pathname.includes("/create-identity-document/") ||
            window.location.pathname == "/create-right-to-work" ||
            window.location.pathname.includes("/create-right-to-work/") || window.location.pathname == "/apply-more-jobs") && (
            <p
              id={name + "_msg"}
              style={{ fontSize: "80%" }}
              className="text-danger text-sm mb-0"
            ></p>
          )}

        {name &&
          (window.location.pathname == "/delete-account" ||
            window.location.pathname == "/cancel-assignments") && (
            <p
              id={name + "_msg"}
              style={{ fontSize: "80%", display: "none" }}
              className="text-danger text-sm mb-0"
            ></p>
          )}

        {window.location.pathname == "/driver-details" && id && (
          <div className={"text-danger mb-0"} id={id + "_msg"}></div>
        )}
        {window.location.pathname == "/candidate-profile" && id && (
          <div className={"text-danger mb-0"} style={{fontSize:"80%"}} id={id + "_msg"}></div>
        )}
        <Form.Control.Feedback type="invalid">
          {errors && errors[name]}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

Dropdown.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default Dropdown;
