import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import * as Icons from "@mui/icons-material";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TaskAlt from "@mui/icons-material/TaskAlt";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { redirectUrl } from "./GlobalUrls";
import { convertToSnakeCase } from "./StringOperation";
export default function IconMenu({
  menuList,
  menuListState,
  marginXValue,
  marginYValue,
  ...props
}) {
  return (
    <Paper
      sx={{
        width: 320,
        maxWidth: "100%",
        position: "absolute",
        marginX: marginXValue ? marginXValue : "250px",
        marginY: marginYValue ? marginYValue : "-350px",
        zIndex: "10",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
      style={{ display: menuListState == true ? "block" : "none" }}
      className="text-white"
    >
      <MenuList>
        <>
          {menuList.map((item, index) => (
            <>
              {item.divider == true && <Divider />}
              <MenuItem
                key={index}
                id={
                  "menu_item_" +
                  (item.name ? convertToSnakeCase(item.name) : "")
                }
                onClick={() => redirectUrl(item.url)}
                onMouseEnter={(event) => {
                  event.currentTarget.classList.add("bg-primary", "text-white");
                }}
                onMouseLeave={(event) => {
                  event.currentTarget.classList.remove(
                    "bg-primary",
                    "text-white"
                  );
                }}
              >
                {/* <ListItemIcon> */}
                {/* {React.createElement(Icons[item.icon], { fontSize: "small" })} */}
                <i
                  className={item.icon + " mr-2 text-xl"}
                  style={{ fontSize: "18px" }}
                ></i>
                {/* </ListItemIcon> */}
                <ListItemText>
                  {item.name && item.name.length <= 25
                    ? item.name
                    : item.name
                      ? item.name.slice(0, 25) + "..."
                      : ""}
                </ListItemText>
              </MenuItem>
            </>
          ))}
        </>
      </MenuList>
    </Paper>
  );
}
