import * as Yup from "yup";
export const formInputFields = (job) => [
  {
    name: "clt_job_title",
    type: "text",
    label: "Job Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_sku_code",
    type: "text",
    label: "SKU Code",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_sector",
    type: "text",
    label: "Sector",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_description",
    type: "textarea",
    label: "Description",
    md: "12",
    sm: "12",
  },
  {
    name: "clt_job_employment_type",
    type: "dropdown",
    label: "Employement Type",
    md: "6",
    sm: "12",
    options: [
      { id: "", name: "Select Employemrnt Type" },
      { id: "full time", name: "Full-Time" },
      { id: "part time", name: "Part-Time" },
    ],
  },
  {
    name: "clt_job_contract_type",
    type: "dropdown",
    label: "Contract Type",
    md: "6",
    sm: "12",
    options: [
      { id: "", name: "Select Contract Type" },
      { id: "contract", name: " Contract" },
    ],
  },
  {
    name: "clt_job_contract_duration",
    type: "text",
    label: "Contract Duration",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_country",
    type: "text",
    label: " Country",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_postcode",
    type: "text",
    label: " Postcode",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_address",
    type: "textarea",
    label: " Address",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_salary_min",
    type: "text",
    label: " Minimum Salary",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_salary_max",
    type: "text",
    label: "Maximum Salary",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_job_salary_type",
    type: "dropdown",
    label: "Salary Type",
    md: "6",
    sm: "12",
    options: [
      { id: "", name: "Select Salary Type" },
      { id: "per day", name: "Per Day" },
      { id: "per month", name: "Per Month" },
    ],
  },
  {
    name: "clt_job_addtion_ote",
    type: "radio",
    label: "Addition OTE",
    md: "6",
    sm: "12",
    options: [
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
  },
  {
    name: "clt_job_addtion_prorata",
    type: "radio",
    label: "Addition Prorata",
    md: "6",
    sm: "12",
    options: [
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
  },
  {
    name: "clt_job_addtion_benifits",
    type: "radio",
    label: "Additional Benefits",
    md: "6",
    sm: "12",
    options: [
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
  },
  {
    name: "clt_job_addtion_negotiable",
    type: "radio",
    label: " Negotiable",
    md: "3",
    sm: "6",
    options: [
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
  },
  {
    name: "clt_job_skill",
    type: "text",
    label:
      "Adding key skills to your job advert will allow you to sort any applications received by",
    md: "12",
    sm: "12",
  },

  {
    name: "clt_job_send_application",
    type: "text",
    label: "Send Application",
    md: "3",
    sm: "6",
  },
  {
    name: "clt_job_coverd_preference",
    type: "text",
    label: "Covered Preference ",
    md: "3",
    sm: "6",
  },
  {
    name: "clt_job_end_date",
    type: "date",
    label: "End Date ",
    md: "3",
    sm: "6",
  },
];

export const formArrayInputFields = (key) => [
  {
    name: `job_questions.${key}.clt_job_que_name`,
    field: "clt_job_que_name",
    type: "text",
    label: "Question",
    md: "6",
    sm: "12",
  },
  {
    name: `job_questions.${key}.clt_job_que_answer`,
    field: "clt_job_que_answer",
    type: "dropdown",
    label: "Answer",
    options: [
      { id: "", name: "Select Answer" },
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
    md: "6",
    sm: "12",
  },
];

export const validationSchema = Yup.object().shape({
  clt_job_title: Yup.string().required("Job Title Field is Required"),
  clt_job_sku_code: Yup.string().required("SKU Code Field is Required"),
  clt_job_sector: Yup.string().required("Job Sector is required"),
  clt_job_description: Yup.string()
    .required("Description is required")
    .min(6, "Description must be at least 6 characters")
    .max(40, "Description must not exceed 40 characters"),
  clt_job_address: Yup.string()
    .required("Job Address is required")
    .min(6, "Job Address must be at least 3 characters")
    .max(40, "Job Address must not exceed 40 characters"),
  clt_job_employment_type: Yup.string().required(
    "Employement Type is required"
  ),
  clt_job_contract_type: Yup.string().required("Contract Type is required"),
  clt_job_contract_duration: Yup.string().required(
    "Contract Duration is required"
  ),
  clt_job_country: Yup.string().required("Country is required"),
  clt_job_postcode: Yup.number()
    .typeError("Postcode must be a valid number")
    .required("Postcode is required"),
  clt_job_address: Yup.string().required("Address is required"),
  clt_job_salary_min: Yup.number()
    .typeError("Minimum Salary must be a valid number")
    .required("Minimum Salary is required"),
  clt_job_salary_max: Yup.number()
    .typeError("Maximum Salary must be a valid number")
    .required("Maximum Salary is required"),
  clt_job_salary_type: Yup.string().required("Salary Type is required"),
  clt_job_addtion_ote: Yup.string().oneOf(
    ["yes", "no"],
    "Please select valid Value"
  ),
  clt_job_addtion_prorata: Yup.string().oneOf(
    ["yes", "no"],
    "Please select valid Value"
  ),
  clt_job_addtion_benifits: Yup.string().oneOf(
    ["yes", "no"],
    "Please select valid Value"
  ),
  clt_job_addtion_negotiable: Yup.string().oneOf(
    ["yes", "no"],
    "Please select valid Value"
  ),
  clt_job_skill: Yup.string().required("Job Skills is required"),
  clt_job_send_application: Yup.string().required(
    "Send Application is required"
  ),
  clt_job_coverd_preference: Yup.string().required(
    "Covered Preference is required"
  ),
  clt_job_end_date: Yup.string().required("End dtae is required"),
  job_questions: Yup.array().of(
    Yup.object().shape({
      clt_job_que_name: Yup.string().required("Question Field required"),
      clt_job_que_answer: Yup.string().required("Please select answer"),
    })
  ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
