import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Live-carebanner.jpg";
// import HomecareImg from "./../images/ourservices/Livecare.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/home-care/live-in-care/our-services-home-care-live-in-care.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/home-care/live-in-care/our-services-home-care-live-in-care-01.jpg";
import bnr3 from "./../images/lines.png";

export default function Home_LiveCare() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Our Services - Live In Care";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/live-in-care"
            ? "0rem"
            : "" }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Live in Care</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/live-in-care" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Home Care</li>
                  <li>Live in Care</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Live in Care </h2>
                  {/* <p>Why, hello there! Want to see more of our work? Check out just some of what we've been up to below.</p> */}
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div
                  className="col-md-12 col-lg-6 m-b10"
                  style={{ marginBottom: "-2%" }}
                >
                  <p className="spacebar mt-3"></p>
                  <h6>
                    Experience the Freedom of Live-In Care with Staffing Connect
                  </h6>

                  <p className="text-left">
                    What is live-in care, you ask? It is freedom in its most
                    personal form. It is the key to remaining comfortable,
                    secure, and fiercely independent within the inviting walls
                    of your own home. It is a viable, cost-effective substitute
                    for residential care. It can also serve as short-term
                    assistance following a hospital stay, an enabler for your
                    well-deserved vacation, or a supportive interim for your
                    dedicated carers.
                  </p>

                  <h6>Localized Care For You</h6>

                  <p className="text-left">
                    Choosing the appropriate care is a decision of profound
                    importance. Our management teams are always ready to provide
                    continuous support, and our dedicated members are at your
                    service all 365 days of the year.
                  </p>
                </div>
              </div>

              <h6>Caring Tailored to Your Needs</h6>

              <p className="text-left">
                Our services are as diverse as your requirements. From
                supporting your independent lifestyle with housekeeping and
                social outings to assisting with personal care, meal
                preparations, and managing medications - we’ve got you covered.
                Our live-in care professionals are fully trained to surpass
                regulatory standards and are wholly committed to your
                well-being.
              </p>

              <p className="text-left">
                For those grappling with a health condition or injury, we offer
                specialised care tailored to your unique needs. Our caregivers,
                trained explicitly for your situation, will focus on your health
                and well-being while coordinating with other healthcare
                professionals as needed.
              </p>

              <h6>Who Are Our Caregivers?</h6>

              <p className="text-left">
                Our live-in caregivers are chosen through a stringent
                recruitment process to identify those with the right blend of
                skills, experiences, and attitudes. They bring a compassionate
                heart and a caring spirit, making them invaluable assets to our
                team and your life. Rest assured; you are in capable and caring
                hands with our caregivers.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
