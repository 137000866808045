const initialState = {userData: []}

export const userExperience = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_USER_EXPERIENCE':
      return {userData: action.payload}; 
  }
  return state
}


