import React, { useEffect } from "react";
import PermanentProfilesidebar from "./PermanentProfilesidebar";
import Permanentform from "./Permanentform";
import composedJobsContainer from "../../containers/JobsContainer";

const PermanentFormContainer = (props) => {
  
  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-blocks">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {/* <Profilesidebar page="profile" /> */}
                <PermanentProfilesidebar page="appliedJobs" />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <Permanentform />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    jobsdetails: state.jobsdetails.jobsdetails,
  };
};
export default composedJobsContainer(PermanentFormContainer);
