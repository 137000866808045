export const documentsList = {
  ukpassport: "UK Passport",
  eupassport: "EU Passport",
  eunatid: "EU National ID Card",
  biorespermit: "Biometric Residence Permit",
  hoissued: "Other HO-issued Document",
  birthcert: "britcitizen",
  birthcertificate: "Birth/adoption certificate plus NINO",
  britcitizen:
    "Certificate of registration/ naturalization as a British Citizen plus NINO",
  noneea: "Non-EEA Passport with Endorsement",
  pvn: "HO Positive Verifiation Notice (PVN)",
  pvnarc: "PVN plus ARC",
  pvncoa: "PVN plus COA",
  euother: "EU Other",
  other: "Other",
};
export const documentComplianceList = {
  nationalinsurance: "Proof of National Insurance",
  updateaddress: "Updated Address Proof",
  address1: "Address Proof 1",
  address2: "Address Proof 2",
  training: "Training Certificates",
  additional: "Additional Documents",
};

export const documentComplianceShortList = {
  address2: "Address Proof 2",
  training: "Training Certificates",
  additional: "Additional Documents",
};

export const dbsCertificates = {
  dbscertificate: "DBS Certificate",
  newdbscertificate: "New DBS Certificate",
  updateserviceconfirmation: "Update Service Confirmation",
};
export const dbsDocumentName = {
  doesnotcontain: "Does not contain adverse information",
  DBSrenewal: "Additional adverse information added",
  contain: "Contain adverse information",
};
export const dbsStatus = {
  doesnotcontain: "Does not contain adverse information",
  DBSrenewal: "Additional adverse information added",
  contain: "Contain adverse information",
};
export const IdentityDocumentListView = {
  passport: "Passport",
  provisionaldrivinglicence: "Provisional driving licence",
  drivinglicence: "Driving licence",
  biometricresidencecard: "Biometric residence card",
  nationalidentitycard: "National identity card",
  travedocument: "Other HO-issued Document",
  birthcertificate:
    "Birth or adoption certificate or certificate of registry of birth",
  other: "Other",
};

export const IdentityDocumentList = [
  { name: "Select Document Name", id: "" },
  { name: "Provisional driving licence", id: "provisionaldrivinglicence" },
  { name: "Passport", id: "passport" },
  { name: "Driving licence", id: "drivinglicence" },
  { name: "Biometric residence card", id: "biometricresidencecard" },
  { name: "National identity card", id: "nationalidentitycard" },
  { name: "Travel document", id: "travedocument" },
  {
    name: "Birth or adoption certificate or certificate of registry of birth",
    id: "birthcertificate",
  },
  { name: "Other", id: "other" },
];

export const IdentityDocumentNewList = [
  { name: "Select Document Name", value: "" },
  { name: "Provisional driving licence", value: "provisionaldrivinglicence" },
  { name: "Passport", value: "passport" },
  { name: "Driving licence", value: "drivinglicence" },
  { name: "Biometric residence card", value: "biometricresidencecard" },
  { name: "National identity card", value: "nationalidentitycard" },
  { name: "Travel document", value: "travedocument" },
  {
    name: "Birth or adoption certificate or certificate of registry of birth",
    value: "birthcertificate",
  },
  { name: "Other", value: "other" },
];
export const RightToworkdocumentsList = [
  { name: "Select Document Name", id: "" },
  { name: "UK Passport", id: "ukpassport" },
  { name: "EU Passport", id: "eupassport" },
  { name: "EU National ID Card", id: "eunatid" },
  { name: "Biometric Residence Permit", id: "biorespermit" },
  { name: "Birth/adoption certificate plus NINO", id: "birthcertificate" },
  // {name:'EEA residence permit',id:"eearesidence"},
  {
    name: "Certificate of registration/ naturalization as a British Citizen plus NINO",
    id: "britcitizen",
  },
  { name: "Non-EEA Passport with Endorsement", id: "noneea" },
  { name: "HO Positive Verifiation Notice (PVN)", id: "pvn" },
  { name: "PVN plus ARC", id: "pvnarc" },
  { name: "PVN plus COA", id: "pvncoa" },
  { name: "EU Other", id: "euother" },
  { name: "Other", id: "other" },
];

// New Right To Work List Start
export const RightToworkDocumentsNewList = [
  { name: "Select Document Name", value: "" },
  { name: "UK Passport", value: "ukpassport" },
  { name: "EU Passport", value: "eupassport" },
  { name: "EU National ID Card", value: "eunatid" },
  { name: "Biometric Residence Permit", value: "biorespermit" },
  { name: "Birth/adoption certificate plus NINO", value: "birthcertificate" },
  // {name:'EEA residence permit',value:"eearesidence"},
  {
    name: "Certificate of registration/ naturalization as a British Citizen plus NINO",
    value: "britcitizen",
  },
  { name: "Non-EEA Passport with Endorsement", value: "noneea" },
  { name: "HO Positive Verifiation Notice (PVN)", value: "pvn" },
  { name: "PVN plus ARC", value: "pvnarc" },
  { name: "PVN plus COA", value: "pvncoa" },
  { name: "EU Other", value: "euother" },
  { name: "Other", value: "other" },
];
// New Right to Work List End
