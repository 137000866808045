import {
  getRequestData,
  postRequestData,
} from "../../middleware/GetRequestData";
import swal from "sweetalert";
import { toast } from "react-toastify";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_EXPERIENCE = "SET_USER_EXPERIENCE";
export const SET_ERROR = "SET_ERROR";

const profileInfo = (content) => {
  return {
    type: SET_USER_DATA,
    payload: content.data.data,
  };
};
const expirenceInfo = (content) => {
  return {
    type: SET_USER_EXPERIENCE,
    payload: content.data.data,
  };
};

const failure = (content) => {
  return {
    type: SET_ERROR,
    payload: content,
  };
};

export const loadUserProfileData = (userToken) => {
  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const userInfo = await getRequestData("PersonInfo", userToken);
      if (userInfo) {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(profileInfo(userInfo));
      } else {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(failure(userInfo));
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failure({ msg: "Invalid Request" }));
    }
  };
};

export const updateUserProfile = (formData, actionName) => {
  toast.dismiss();
  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const userInfoUpdate = await postRequestData(actionName, formData, "");
      if (userInfoUpdate.data.status == "success") {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch({ type: "SWITCH_TO_NEXT", payload: true });
        const userInfo = await getRequestData(
          "PersonInfo",
          formData.users_access_token
        );
        
          dispatch(profileInfo(userInfo));
          if(actionName == "UpdateJobCandidateCV"){
            successToast(userInfoUpdate.data.msg);
          }else if(actionName != "UpdateJobPreferenceDetails"){
            successToast("Information updated successfully");
          }

          // Swal Msgs
          if(actionName == "UpdateJobPreferenceDetails"){
            swal({
              icon: userInfoUpdate.data.status,
              title: userInfoUpdate.data.title,
              text: userInfoUpdate.data.msg,
              // timer:"2000",
              dangerMode: true,
              buttons: {
                Ok: {
                  checkProf: true,
                },
              },
              //defeat:true,
              //buttons:false,
              closeOnClickOutside: true,
            }).then((checkProf) => {
              window.location.href = "";
            });
          }
          // Swal Msgs
        
          document.getElementById("loader").style.display = "none";
        //Redirection Code
        
          let interval = setInterval(myUrl, 2000);
          function myUrl(){
            if(window.location.pathname != "/job-details"){
              if(actionName == "UpdatePersonalInfo" && window.location.pathname != "/create-document-update-address"){
                window.location = "/work-prefrences";
              }else if(actionName == "UpdatePersonalInfo" && window.location.pathname == "/create-document-update-address"){
                window.location = "/document-required";
              }else if(actionName == "updateFinalAgreement"){
                window.location = "/sign-contract";
              }              
            }else{
              document.getElementById("loader").style.display = "none";
            }
            clearInterval(interval);
          }
          

      } else {
        document.getElementById("loader").style.display = "none";
        dispatch({ type: "SET_LOADER", payload: false });
        //errorToast(userInfoUpdate.data.msg);
        swal({
          icon:'error',
          title:userInfoUpdate.data.title,
          text:userInfoUpdate.data.msg
        })
        dispatch(failure(userInfoUpdate));

        //Redirection Code
        /*if(actionName == "UpdatePersonalInfo"){
          let interval = setInterval(myUrl, 2000);
          function myUrl(){
            window.location = "job-profile";
            clearInterval(interval);
          }
        }*/
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failure({ data: { msg: "Invalid Request" } }));
    }
  };
};

// Permanent Candidate Start
export const updatePermanentCandidateProfile = (formData, actionName) => {
  toast.dismiss();
  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const userInfoUpdate = await postRequestData(actionName, formData, "");
      if (userInfoUpdate.data.status == "success") {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch({ type: "SWITCH_TO_NEXT", payload: true });
        const userInfo = await getRequestData(
          "PersonalPermanentInfo",
          formData.users_access_token
        );
        
          dispatch(profileInfo(userInfo));
          // successToast("Information updated successfully");
        
        
        //Redirection Code
        
          
            if(actionName == "UpdatePersonalPermanentInfo"){
              await swal({
                icon: userInfoUpdate.data.status,
                title: userInfoUpdate.data.title,
                text:userInfoUpdate.data.msg,
                buttons:{
                  Ok:{
                    checkProf:true
                  }
                },
                //defeat:true,
                //buttons:false,
                closeOnClickOutside:true
      
              }).then((checkProf) =>{
                window.location.href = "/applied-jobs";
              })
            }
            

      } else {
        document.getElementById("loader").style.display = "none";
        // // Users Middle Name Disabled In Peranent Candidate
        // let users_middlename = document.getElementById("users_middlename");
        // if(users_middlename.value){
        //   users_middlename.disabled = true;
        // }
        dispatch({ type: "SET_LOADER", payload: false });
        //errorToast(userInfoUpdate.data.msg);
        swal({
          icon:userInfoUpdate.data.status ? userInfoUpdate.data.status : 'error',
          title:userInfoUpdate.data.title,
          text:userInfoUpdate.data.msg
        })
        dispatch(failure(userInfoUpdate));

        //Redirection Code
        /*if(actionName == "UpdatePersonalInfo"){
          let interval = setInterval(myUrl, 2000);
          function myUrl(){
            window.location = "job-profile";
            clearInterval(interval);
          }
        }*/
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failure({ data: { msg: "Invalid Request" } }));
    }
  };
};
// Permanent Candidate Detais Stop
// Update user experience data and get experience data action
export const loadUserExperienceData = (userToken) => {
  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const userInfo = await getRequestData("UserExperience", userToken);

      if (userInfo) {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(expirenceInfo(userInfo));
      } else {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(failure(userInfo));
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failure({ msg: "Invalid Request" }));
    }
  };
};

export const updateRefrenceInfo = (formData, actionName) => {
  toast.hideAll();
  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const userInfoUpdate = await postRequestData(actionName, formData, "");
      if (userInfoUpdate.data.status == "success") {
        const userInfo = await getRequestData(
          "UserExperience",
          formData.users_access_token
        );
        dispatch(expirenceInfo(userInfo));
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch({ type: "SWITCH_TO_NEXT", payload: true });
        successToast("Experience updated successfully");
      } else {
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(failure(userInfoUpdate));
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failure({ data: { msg: "Invalid Request" } }));
    }
  };
};

const successToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const errorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
