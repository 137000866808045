import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Form, Col, Spinner, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Label from "../../Layout/Label";
import { formInputFields, validationSchema } from "./FormHelper";
import { updateDataOnRequest } from "../../../redux/actions/CommonActions";
const CompanyDetails = (props) => {
  const [company, setFormdata] = useState({});
  const { loader, userDetails, companyDetails } = props;
  const dispatch = useDispatch();
  let contactInfo;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFormdata(companyDetails);
    if (Object.keys(companyDetails).length > 0)
      Object.keys(companyDetails).map((key) => {
        setValue(key, companyDetails[key]);
      });
  }, [companyDetails]);

  const onSubmit = (data) => {
    let formData = data;
    formData["users_access_token"] = userDetails.users_access_token;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "UpdateCompanyDetails",
        "UPDATE_COMPANY_DETAILS"
      )
    );
  };

  const onChangeInputvalue = (e) => {
    const { name, value } = e.target;
    company[name] = value;
    setFormdata(company);

    // Getting Register Address Start
    let clt_registered_address_1 = document.getElementById(
      "clt_registered_address_1"
    ).value;
    let clt_registered_address_2 = document.getElementById(
      "clt_registered_address_2"
    ).value;
    let clt_registered_town = document.getElementById(
      "clt_registered_town"
    ).value;
    let clt_registered_region = document.getElementById(
      "clt_registered_region"
    ).value;
    let clt_registered_postalcode = document.getElementById(
      "clt_registered_postalcode"
    ).value;
    let clt_registered_country = document.getElementById(
      "clt_registered_country"
    ).value;
    // Getting Register Address Stop

    // Getting Billing Address Start
    let clt_billing_address_1 = document.getElementById(
      "clt_billing_address_1"
    );
    let clt_billing_address_2 = document.getElementById(
      "clt_billing_address_2"
    );
    let clt_billing_town = document.getElementById("clt_billing_town");
    let clt_billing_region = document.getElementById("clt_billing_region");
    let clt_billing_postalcode = document.getElementById(
      "clt_billing_postalcode"
    );
    let clt_billing_country = document.getElementById("clt_billing_country");
    // Getting Billing Address Stop

    // Getting Service Address Start
    let clt_service_address_1 = document.getElementById(
      "clt_service_address_1"
    );
    let clt_service_address_2 = document.getElementById(
      "clt_service_address_2"
    );
    let clt_service_town = document.getElementById("clt_service_town");
    let clt_service_region = document.getElementById("clt_service_region");
    let clt_service_postalcode = document.getElementById(
      "clt_service_postalcode"
    );
    let clt_service_country = document.getElementById("clt_service_country");
    // Getting Service Address Stop

    //Set Billing Address Fetching Start
    if (e.target.name == "clt_billing_as_registered_check") {
      // console.log("Value Is :=>"+clt_registered_address_1);
      if (e.target.checked) {
        clt_billing_address_1.value = clt_registered_address_1;
        clt_billing_address_2.value = clt_registered_address_2;
        clt_billing_town.value = clt_registered_town;
        clt_billing_region.value = clt_registered_region;
        clt_billing_postalcode.value = clt_registered_postalcode;
        clt_billing_country.value = clt_registered_country;
      } else {
        clt_billing_address_1.value = "";
        clt_billing_address_2.value = "";
        clt_billing_town.value = "";
        clt_billing_region.value = "";
        clt_billing_postalcode.value = "";
        clt_billing_country.value = "";
      }
    }
    //Set Billing Address Fetching Stop

    // Set Service Address Fetching Start
    if (e.target.name == "clt_service_address_as_billing_check") {
      document.getElementById("service_as_register").checked = false;
      // console.log("Value Is :=>"+clt_billing_address_1);
      if (e.target.checked) {
        clt_service_address_1.value = clt_billing_address_1.value;
        clt_service_address_2.value = clt_billing_address_2.value;
        clt_service_town.value = clt_billing_town.value;
        clt_service_region.value = clt_billing_region.value;
        clt_service_postalcode.value = clt_billing_postalcode.value;
        clt_service_country.value = clt_billing_country.value;
      } else {
        clt_service_address_1.value = "";
        clt_service_address_2.value = "";
        clt_service_town.value = "";
        clt_service_region.value = "";
        clt_service_postalcode.value = "";
        clt_service_country.value = "";
      }
    }

    if (e.target.name == "clt_service_address_as_registered_check") {
      document.getElementById("service_as_billing").checked = false;
      // console.log("Value Is :=>"+clt_registered_address_1);
      if (e.target.checked) {
        clt_service_address_1.value = clt_registered_address_1;
        clt_service_address_2.value = clt_registered_address_2;
        clt_service_town.value = clt_registered_town;
        clt_service_region.value = clt_registered_region;
        clt_service_postalcode.value = clt_registered_postalcode;
        clt_service_country.value = clt_registered_country;
      } else {
        clt_service_address_1.value = "";
        clt_service_address_2.value = "";
        clt_service_town.value = "";
        clt_service_region.value = "";
        clt_service_postalcode.value = "";
        clt_service_country.value = "";
      }
    }
    // Set Service Address Fetching Stop
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-b30">
          {formInputFields(company).map((form, formKey) => (
            <>
              {form.name == "clt_contact_name" && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 sm-col-12">
                      <div className="job-bx-title clearfix">
                        <h6 className="font-weight-500 pull-left text-uppercase">
                          Contact Information
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {form.name == "clt_twitter" && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 sm-col-12">
                      <div className="job-bx-title clearfix">
                        <h6 className="font-weight-500 pull-left text-uppercase">
                          Social Media
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {form.name == "clt_email_1" && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 sm-col-12">
                      <div className="job-bx-title clearfix">
                        <h6 className="font-weight-500 pull-left text-uppercase">
                          Email setting & addresses
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Form.Group
                as={Col}
                controlId={formKey}
                key={formKey}
                md={form.md}
                sm={form.sm}
              >
                {form.label && <Label labelName={form.label} req={form.req} />}
                {form.name == "clt_billing_address_1" && (
                  <>
                    <b className="float-right">
                      <Label
                        labelName={"Same as Registered Address?"}
                        req={false}
                      />
                    </b>
                    <b className="form-check form-switch float-right">
                      <input
                        type="checkbox"
                        name="clt_billing_as_registered_check"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        value={company.clt_billing_as_registered_check}
                        onChange={(e) => onChangeInputvalue(e)}
                        className="form-check-input"
                        errors={errors}
                      />
                    </b>
                  </>
                )}

                {form.name == "clt_service_address_1" && (
                  <>
                    <b className="float-right">
                      <Label
                        labelName={"Same as Registered Address?"}
                        req={false}
                      />
                    </b>
                    <b className="form-check form-switch float-right">
                      <input
                        type="checkbox"
                        name="clt_service_address_as_registered_check"
                        role="switch"
                        id="service_as_register"
                        value={company.clt_service_address_as_registered_check}
                        onChange={(e) => onChangeInputvalue(e)}
                        className="form-check-input"
                        errors={errors}
                      />
                    </b>
                    <b className="float-right mr-5">
                      <Label
                        labelName={"Same as Billing Address?"}
                        req={false}
                      />
                    </b>
                    <b className="form-check form-switch float-right">
                      <input
                        type="checkbox"
                        name="clt_service_address_as_billing_check"
                        role="switch"
                        id="service_as_billing"
                        value={company.clt_service_address_as_billing_check}
                        onChange={(e) => onChangeInputvalue(e)}
                        className="form-check-input"
                        errors={errors}
                      />
                    </b>
                  </>
                )}

                <Form.Control
                  label={form.label}
                  name={form.name}
                  type={form.type}
                  id={form.id}
                  className={form.className}
                  placeholder={form.placeholder}
                  {...register(form.name)}
                  onKeyUp={(e) => onChangeInputvalue(e)}
                  isInvalid={errors[form.name] ? true : ""}
                  // isValid={company[form.name] ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[form.name]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          ))}

          <div className="conntainer">
            <div className="row">
              <div className="col-md-12 sm-col-12 mt-4 ml-4">
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    name="clt_email_concent"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    value={company.clt_email_concent}
                    onChange={(e) => onChangeInputvalue(e)}
                    className="form-check-input"
                    style={{ width: "5%", height: "18px" }}
                    errors={errors}
                  />

                  <b className="ml-2">
                    <Label
                      labelName={
                        "Staffing Connect can send letters, statement, etc.  via these emails."
                      }
                      req={false}
                    />
                  </b>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="form-group form-check">
          <input
            name="acceptTerms"
            type="checkbox"
            {...register("acceptTerms")}
            className={`form-check-input ${
              errors.acceptTerms ? "is-invalid" : ""
            }`}
          />
          <label htmlFor="acceptTerms" className="form-check-label">
            I have read and agree to the Terms
          </label>
          <div className="invalid-feedback">
            {errors.acceptTerms?.message}
          </div>
        </div> */}
          <Form.Group as={Col} className="mt-4">
            <Button
              disabled={loader ? "disabled" : ""}
              className="site-button m-b30 float-right"
              type="submit"
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Save"}
            </Button>
            <Button
              disabled={loader ? "disabled" : ""}
              className="m-b30 m-r5 site-button orange float-right"
              onClick={() => reset()}
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Reset"}
            </Button>
          </Form.Group>
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    companyDetails: state.companyDetails.companyDetails,
  };
};
export default connect(mapStateToProps)(CompanyDetails);
