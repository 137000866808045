const initialState = { driverDetails: {} };

export const driverDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DRIVER_DETAILS":
      return { driverDetails: action.payload };
    case "UPDATE_DRIVER_DETAILS":
      return { driverDetails: action.payload };
  }
  return state;
};
