export function calculateTimeDifference(startTime, endTime) {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    let [endHours, endMinutes] = endTime.split(':').map(Number);

    // If end time is "24:00", consider it as "00:00" of the next day
    if (endHours === 24) {
        endHours = 0;
        endMinutes = 0;
    }

    const startDate = new Date(0, 0, 0, startHours, startMinutes);
    const endDate = new Date(0, 0, 0, endHours, endMinutes);

    // If endDate is less than startDate, add a day to endDate
    if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1);
    }

    const diff = endDate - startDate;

    const hours = Math.floor(diff / 3600000);
    const minutes = Math.floor((diff % 3600000) / 60000);

    // return `${diffHours.toString().padStart(2, '0')}:${diffMinutes.toString().padStart(2, '0')}`;
    return {
        hours,
        minutes
    }
}