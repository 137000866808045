import {
  validateStringWithoutSpecialCharacter,
  validateEmail,
  validateUkNumber,
} from "./ValidationHelpers";
import { format } from "date-fns";  
let errorsObj = {};
export const CandidatePersonalInfoValidation = (userdata, fieldName) => {
  let error = false;
  const errorObj = { ...errorsObj };

  if (fieldName == "users_firstname" || fieldName == "submit") {
    if (userdata.users_firstname === "") {
      errorObj.users_firstname = "First Name is Required";
      error = true;
    } else if (
      !validateStringWithoutSpecialCharacter(userdata.users_firstname)
    ) {
      errorObj.users_firstname = "Please enter valid firstname";
      error = true;
    }
  }
  if (fieldName == "users_lastname" || fieldName == "submit") {
    if (!userdata.users_lastname) {
      errorObj.users_lastname = "Last Name is Required";
      error = true;
    } else if (
      !validateStringWithoutSpecialCharacter(userdata.users_lastname)
    ) {
      errorObj.users_lastname = "Please enter valid lastname";
      error = true;
    }
  }
  if (fieldName == "users_email" || fieldName == "submit") {
    if (!userdata.users_email) {
      errorObj.users_email = " The Email is Required";
      error = true;
    }
  }

  if (fieldName == "cnd_gender" || fieldName == "submit") {
    if (!userdata.cnd_gender) {
      errorObj.cnd_gender = "Gender Field is Required";
      error = true;
    }
  }

  if (fieldName == "users_email" || fieldName == "submit") {
    if (!validateEmail(userdata.users_email)) {
      errorObj.users_email = "Please enter valid email";
      error = true;
    }
  }

  if (fieldName == "users_phonenumber" || fieldName == "submit") {
    if (!userdata.users_phonenumber) {
      errorObj.users_phonenumber = "Please enter phone number";
      error = true;
    } else if (!validateUkNumber(userdata.users_phonenumber)) {
      errorObj.users_phonenumber = "Please enter valid phone number";
      error = true;
    }
  }

  if (fieldName == "cnd_home_mobile_number" || fieldName == "submit") {
    if (!userdata.cnd_home_mobile_number) {
      error = false;
    } else if (!validateUkNumber(userdata.cnd_home_mobile_number)) {
      errorObj.cnd_home_mobile_number = "Please enter valid phone number";
      error = true;
    }
  }
  if (fieldName == "cnd_kin_phonenumber" || fieldName == "submit") {
    if (!userdata.cnd_kin_phonenumber) {
      errorObj.cnd_kin_phonenumber = "Please enter phone number";
      error = true;
    } else if (!validateUkNumber(userdata.cnd_kin_phonenumber)) {
      errorObj.cnd_kin_phonenumber = "Please enter valid phone number";
      error = true;
    }
  }

  if (fieldName == "cnd_drivinglicense" || fieldName == "submit") {
    if (!userdata.cnd_drivinglicense) {
      errorObj.cnd_drivinglicense = "Please select corrent value";
      error = true;
    }
  }

  if (fieldName == "cnd_have_a_car" || fieldName == "submit") {
    if (!userdata.cnd_have_a_car) {
      errorObj.cnd_have_a_car = "Please select corrent value";
      error = true;
    }
  }

  if (!userdata.cnd_upload_cv && window.location.pathname == "/job-profile") {
    errorObj.cnd_upload_cv = "Please select the CV";
    error = true;
  }

  // if (fieldName == "cnd_birth_date" || fieldName == "submit") {
  //   if (!userdata.cnd_birth_date) {
  //     errorObj.cnd_birth_date = "Please select date of birth";
  //     error = true;
  //   }
  // }

  if (fieldName == "cnd_birth_date" || fieldName == "submit") {
    if (!userdata.cnd_birth_date) {
      errorObj.cnd_birth_date = "Please select date of birth";
      error = true;
    }else{
      // let birthDate = new Date(String(userdata.cnd_birth_date));
      // let currentDate = new Date();
      // if(birthDate > currentDate || birthDate.getFullYear() > 2011 || birthDate.getFullYear() < 1950 || (birthDate.getFullYear() >= 2011 && birthDate.getMonth() > 5)){
      //   // console.log("Birth Date is :=>"+ birthDate);
      //   errorObj.cnd_birth_date = "Please select the valid date";
      //   error = true;
      // }

      let birthDate = new Date(String(userdata.cnd_birth_date));
        let currentDate = new Date();
        let minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 73));
        let maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 15));
        // console.warn("Full Year of Current Date :=>"+birthDate);
        if(birthDate > currentDate || birthDate > maxDate || birthDate < minDate){
          // console.log("Birth Date is :=>"+ birthDate);
          errorObj.cnd_birth_date = "Date must be before or "+format(maxDate,"dd-MM-yyyy")+" and "+format(minDate,"dd-MM-yyyy")+" or later";
          error = true;
        }
    }
  }

  if (fieldName == "cnd_minibuslicense" || fieldName == "submit") {
    if (!userdata.cnd_minibuslicense) {
      errorObj.cnd_minibuslicense = "Please select corrent value";
      error = true;
    }
  }

  if(fieldName == 'cnd_swimmer' || fieldName == 'submit'){
    if(!userdata.cnd_swimmer){
      errorObj.cnd_swimmer = "Please select swimmer";
      error = true;
    }
  }

  if(fieldName == 'cnd_drivinglicense' || fieldName == 'submit'){
    if(!userdata.cnd_drivinglicense){
      errorObj.cnd_drivinglicense = "Please select driving license";
      error = true;
    }
  }

  if(fieldName == 'cnd_have_a_car' || fieldName == 'submit'){
    if(!userdata.cnd_have_a_car){
      errorObj.cnd_have_a_car = "Please select the option";
      error = true;
    }
  }

  if(fieldName == 'cnd_minibuslicense' || fieldName == 'submit'){
    if(!userdata.cnd_minibuslicense){
      errorObj.cnd_minibuslicense = " Please select mini bus";
      error = true;
    }
  }

  if (fieldName == "cnd_kin_lastname" || fieldName == "submit") {
    if (!userdata.cnd_kin_lastname) {
      errorObj.cnd_kin_lastname = "Kin Last Name Required";
      error = true;
    }else if(userdata.cnd_kin_lastname && userdata.cnd_kin_lastname.trim() == ""){
      errorObj.cnd_kin_lastname = "Please provide correct value";
      error = true;
    } else if (
      !validateStringWithoutSpecialCharacter(userdata.cnd_kin_lastname)
    ) {
      errorObj.cnd_kin_lastname = "Please provide correct value";
      error = true;
    }
  }

  if (fieldName == "cnd_kin_firstname" || fieldName == "submit") {
    if (!userdata.cnd_kin_firstname) {
      errorObj.cnd_kin_firstname = "Kin First Name Required";
      error = true;
    }else if(userdata.cnd_kin_firstname && userdata.cnd_kin_firstname.trim() == ""){
      errorObj.cnd_kin_firstname = "Please provide correct value";
      error = true;
    }else if (
      !validateStringWithoutSpecialCharacter(userdata.cnd_kin_firstname)
    ) {
      errorObj.cnd_kin_firstname = "Please provide correct value";
      error = true;
    }
  }

  if(window.location.pathname == "/create-document-update-address"){
    if (fieldName == "cnd_compliance_upload_file" || fieldName == "submit") {
      if (!userdata.cnd_compliance_upload_file) {
        errorObj.cnd_compliance_upload_file = "Address Proof Required";
        error = true;
     }
    }

    if (fieldName == "cnd_compliance_description" || fieldName == "submit") {
      if (!userdata.cnd_compliance_description) {
        errorObj.cnd_compliance_description = "Document Description Is Required";
        error = true;
     }else if (userdata.cnd_compliance_description && userdata.cnd_compliance_description.trim() == "") {
      errorObj.cnd_compliance_description = "Document Description Is Required";
      error = true;
      }
    }

    if (fieldName == "cnd_compliance_reference" || fieldName == "submit") {
      if (userdata.cnd_compliance_reference && (!validateStringForSpecialCharacter(userdata.cnd_compliance_reference) || userdata.cnd_compliance_reference.trim() == "")) {
        errorObj.cnd_compliance_reference = "Please enter the valid reference number";
        error = true;
     }
    }
  }

  // Address Field Start
  let cnd_street_address = document.getElementById("cnd_street_address");
  let cnd_town =  document.getElementById("cnd_town");
  let cnd_postcode =  document.getElementById("cnd_postcode");
  let cnd_county =  document.getElementById("cnd_county");

  if (fieldName == "cnd_street_address" || fieldName == "submit") {
    if (!cnd_street_address.value) {
      errorObj.cnd_street_address = "Street address required";
      error = true;
    }else if (cnd_street_address.value.trim() == "") {
      errorObj.cnd_street_address = "Please enter the valid Street Address";
      error = true;
    }
  }

  if (fieldName == "cnd_street_address_2" || fieldName == "submit") {
    if(userdata.cnd_street_address_2 && userdata.cnd_street_address_2.trim() == "") {
      errorObj.cnd_street_address_2 = "Please enter the valid Street Address";
      error = true;
    }
  }

  if (fieldName == "cnd_region" || fieldName == "submit") {
    if(userdata.cnd_region && userdata.cnd_region.trim() == "") {
      errorObj.cnd_region = "Please enter the valid Region";
      error = true;
    }
  }

  if (fieldName == "cnd_town" || fieldName == "submit") {
    if (!cnd_town.value) {
      errorObj.cnd_town = "Town required";
      error = true;
    }else if (cnd_town.value.trim() == "") {
      errorObj.cnd_town = "Please enter the valid Town";
      error = true;
    }
  }

  if (fieldName == "cnd_postcode" || fieldName == "submit") {
    if (!cnd_postcode.value) {
      errorObj.cnd_postcode = "Postcode required";
      error = true;
    } else if(cnd_postcode.value.trim() == ""){
      errorObj.cnd_postcode = "Please enter the valid postal code";
      error = true;
    }else if (
      !String(cnd_postcode.value).match(
         /* /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/*/
         
         /*/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/*/

         /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
        )
    ) {
      errorObj.cnd_postcode = "Please Enter Valid Postcode";
      error = true;
    }
  }
  // if (fieldName == "cnd_county" || fieldName == "submit") {
  //   if (!userdata.cnd_county) {
  //     errorObj.cnd_county = "Country required";
  //     error = true;
  //   }
  // }
  // if (fieldName == "cnd_training_details" || fieldName == "submit") {
  //   if (!userdata.cnd_training_details) {
  //     errorObj.cnd_training_details = "Training Detail Field Required";
  //     error = true;
  //   }
  // }
  

  return { stateData: errorObj, error: error };
};


export const validateStringForSpecialCharacter = (string) => {
  return String(string).match(
    /^[0-9a-zA-Z \b]+$/
  );
};