import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_shift_branch",
    type: "text",
    label: "Branch",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_shift_business_unit",
    type: "text",
    label: "Business Unit",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_shift_title",
    type: "text",
    label: "Assignment Title",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_shift_fromDate",
    type: "date",
    label: "Week From Date",
    md: "6",
    sm: "12",
    daysOfWeekDisabled: [0, 2, 3, 4, 5, 6],
  },
  {
    name: "clt_shift_toDate",
    disabled: true,
    type: "date",
    label: "Week To Date",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_shift_address",
    type: "text",
    label: "Location/Address",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_shift_description",
    type: "textarea",
    label: "Description",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_shift_compliance",
    type: "dropdown",
    label: "Required Compliance",
    md: "6",
    sm: "12",
    options: [
      { name: "Select Required Compliance", id: "" },
      { name: "Bhaskar", id: "bhaskar" },
      { name: "Sandeep", id: "sandeep" },
    ],
  },
  {
    name: "clt_shift_no_of_booking",
    type: "dropdown",
    label: "Number of Booking",
    md: "6",
    sm: "12",
    options: [
      { name: "Select Number of Bookings", id: "" },
      { name: "1", id: "1" },
      { name: "2", id: "2" },
      { name: "3", id: "3" },
      { name: "4", id: "4" },
      { name: "5", id: "5" },
    ],
  },
  {
    name: "clt_shift_right_to_wrk",
    type: "radio",
    label: "Right To Work",
    md: "6",
    sm: "12",
    showLabel: true,
    options: [
      { id: "yes", name: "Yes" },
      { id: "no", name: "No" },
    ],
  },
  {
    name: "clt_shift_dbs",
    type: "radio",
    label: "DBS",
    md: "6",
    sm: "12",
    showLabel: true,
    options: [
      { id: "1", name: "Yes" },
      { id: "0", name: "No" },
    ],
  },
];

export const validationSchema = Yup.object().shape({
  clt_shift_branch: Yup.string().required("Job Title Field is Required"),
  clt_shift_business_unit: Yup.string()
    .required("Business Unit Field is Required")
    .min(2, "Business Unit must be at least 11 characters")
    .max(40, "Business Unit must not exceed 11 characters"),
  clt_shift_right_to_wrk: Yup.string().oneOf(
    ["yes", "no"],
    "Please select valid Value"
  ),
  clt_shift_dbs: Yup.string().oneOf(["0", "1"], "Please select valid Value"),
  clt_shift_no_of_booking: Yup.string().required("No of Booking is required"),
  clt_shift_compliance: Yup.string().required(
    "Required Compliance is required"
  ),
  clt_shift_description: Yup.string()
    .required("Description is required")
    .min(6, "Description must be at least 3 characters")
    .max(1000, "Description must not exceed 1000 characters"),
  clt_shift_address: Yup.string().required("Location is required"),
  clt_shift_toDate: Yup.string()
    .required("End Date is required")
    .test("OnSelectSunday", "Only Sunday Dates are allowed.", (value) => {
      var today = new Date(value);
      if (today.getDay() == 0) return true;
      else return false;
    }),
  clt_shift_fromDate: Yup.string()
    .required("Start Date is required")
    .test("OnSelectMonday", "Only Monday Dates are allowed.", (value) => {
      var today = new Date(value);
      if (today.getDay() == 1) return true;
      else return false;
    }),
  clt_shift_title: Yup.string()
    .required("Assignment Title is required")
    .min(4, "Assignment Title must be at least 3 characters")
    .max(40, "Assignment Title must not exceed 40 characters"),
  // client: Yup.array().of(
  //   Yup.object().shape({
  //     client_week_day: Yup.string().required("Week Day is required"),
  //     client_week_in_time: Yup.string().required("Week In Time is required"),
  //     client_week_out_time: Yup.string().required("Week Out Time is required"),
  //     client_week_waking_night: Yup.string().required(
  //       "Waking Night is required"
  //     ),
  //     client_week_slip_in: Yup.string().required("Waking Night is required"),
  //   })
  // ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
