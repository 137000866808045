import React from "react";
import { Link } from "react-router-dom";
import icon from "../images/logo/icon1.png";
import { connect } from "react-redux";
// import Background from "../images/banner/bnr1.jpg";
// Newly Added Image
import composedJobsContainer from "../containers/JobsContainer";
import Background from "../images/new-updated-images/job-board/permanent-jobs/job-board-permanent-job.jpg";
import { deviceType } from "react-device-detect";
import { isMobileOrLaptop } from "../services/CheckDevice";
import JobBoardDesktop from "./JobBoardDesktop";
import JobBoardMobile from "./JobBoardMobile";
import { useState } from "react";
import { useEffect } from "react";

const JobBoard = (props) => {
  
  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener('resize', function() {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    if(!localStorage.getItem('checkRefreshPage')){
      localStorage.setItem('checkRefreshPage', 'startRefresh');
    }
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  useEffect(() => {
    const lenOfData = props.jobsdetails;
    // console.log("Jobs Details With Questions :=>"+JSON.stringify(props.jobsdetails));
  }, [props.jobsdetails]);
  return (
    <>
      {checkDevice == "Mobile" ? (
        <JobBoardMobile callArray={props.jobsdetails} />
      ) : (
        <JobBoardDesktop callArray={props.jobsdetails} />
      )}
    </>
    
     
  );
};

const mapStateToProps = (state) => {
  return {
    jobsdetails: state.jobsdetails.jobsdetails,
  };
};
export default composedJobsContainer(JobBoard);
