const initialState = {jobsdetails: []}
const initialState1 = {jobsScreeningQuestions: []}
const initialState2 = {individualJobsDetails: []}

export const jobsdetails = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_JOBS_DETAILS':
      return {jobsdetails: action.payload}; 
  }
  return state
}

export const jobsScreeningQuestions = (state = initialState1, action) => {
	switch (action.type) {
  	case 'SET_JOBS_SCREENING_QUESTIONS':
      return {jobsScreeningQuestions: action.payload}; 
  }
  return state
}

export const individualJobsDetails = (state = initialState1, action) => {
	switch (action.type) {
  	case 'SET_INDIVIDUAL_JOB_DETAILS':
      return {individualJobsDetails: action.payload}; 
  }
  return state
}


