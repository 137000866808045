import * as Yup from "yup";
import {
  jobSectorList,
  jobTypeList,
} from "../../../services/JobTypeAndSectorGlobalList";
export const candidateFormFields = () => [
  {
    name: "right_to_work",
    id: "right_to_work",
    type: "dropdown",
    label: "Do you have a right to work in the UK?",
    md: "12",
    sm: "12",
    options: [
      { name: "Select Right To Work", id: "" },
      { name: "Yes", id: "yes" },
      { name: "No", id: "no" },
    ],
  },

  {
    name: "users_firstname",
    id: "users_firstname",
    type: "text",
    label: "First Name",
    md: "12",
    sm: "12",
  },
  {
    name: "users_lastname",
    id: "users_lastname",
    type: "text",
    label: "Last Name",
    md: "12",
    sm: "12",
  },
  {
    name: "job_type",
    id: "job_type",
    type: "dropdown",
    label: "Job Type Interested in",
    md: "12",
    sm: "12",
    options: jobTypeList,
  },
  {
    name: "users_category",
    id: "users_category",
    type: "dropdown",
    label: "Job Sector Applying for?",
    md: "12",
    sm: "12",
    options: jobSectorList,
  },
  {
    name: "users_email",
    id: "users_email",
    type: "text",
    label: "Email",
    md: "12",
    sm: "12",
  },
  {
    name: "users_phonenumber",
    id: "users_phonenumber",
    type: "text",
    label: "Phone Number",
    md: "12",
    sm: "12",
  },
  {
    name: "users_password",
    id: "users_password",
    type: "password",
    label: "Password",
    md: "12",
    sm: "12",
  },
];

export const clientFormFields = () => [
  {
    name: "users_category",
    id: "users_category",
    type: "dropdown",
    label: "Job Sector Applying for?",
    md: "12",
    sm: "12",
    options: [
      { name: "Select Job Sector", id: "" },
      { name: "Education", id: "education" },
      { name: "Social Care", id: "social care" },
      { name: "Home Care", id: "home care" },
    ],
  },
  {
    name: "users_firstname",
    id: "users_firstname",
    type: "text",
    label: "First Name",
    md: "12",
    sm: "12",
  },
  {
    name: "users_lastname",
    id: "users_lastname",
    type: "text",
    label: "Last Name",
    md: "12",
    sm: "12",
  },
  {
    name: "users_email",
    id: "users_email",
    type: "text",
    label: "Email",
    md: "12",
    sm: "12",
  },
  {
    name: "users_phonenumber",
    id: "users_phonenumber",
    type: "text",
    label: "Phone Number",
    md: "12",
    sm: "12",
  },
  {
    name: "users_password",
    id: "users_password",
    type: "password",
    label: "Password",
    md: "12",
    sm: "12",
  },
];

export const clientFormSpecificFields = () => [
  {
    name: "users_category",
    id: "users_category",
    type: "dropdown",
    label: "Categories",
    md: "12",
    sm: "12",
    options: [
      { name: "Job Sector Applying for?", id: "" },
      { name: "Education", id: "education" },
      { name: "Social Care", id: "social care" },
      { name: "Home Care", id: "home care" },
    ],
  },
  {
    name: "job_type",
    id: "job_type",
    type: "dropdown",
    label: "Contract Type",
    md: "12",
    sm: "12",
    options: [
      { name: "Select Contract Type", id: "" },
      { name: "Temporary Positions", id: "temproryrequirement" },
      { name: "Permanent Positions", id: "permanentrequirement" },
      {
        name: "Temporary Positions & Permanent Positions",
        id: "temproryandpermanent",
      },
    ],
  },
  {
    name: "users_firstname",
    id: "users_firstname",
    type: "text",
    label: "First Name",
    md: "12",
    sm: "12",
  },
  {
    name: "users_lastname",
    id: "users_lastname",
    type: "text",
    label: "Last Name",
    md: "12",
    sm: "12",
  },
  {
    name: "users_email",
    id: "users_email",
    type: "text",
    label: "Email",
    md: "12",
    sm: "12",
  },
  {
    name: "users_phonenumber",
    id: "users_phonenumber",
    type: "text",
    label: "Phone Number",
    md: "12",
    sm: "12",
  },
  {
    name: "users_password",
    id: "users_password",
    type: "password",
    label: "Password",
    md: "12",
    sm: "12",
  },
];

export const validationSchemaClient = Yup.object().shape({
  users_firstname: Yup.string()
    .required("First Name Field Required")
    .matches(/^[a-zA-Z]+$/, "Please Enter Valid First Name"),
  users_lastname: Yup.string()
    .required("Last Name Field Required")
    .matches(/^[a-zA-Z]+$/, "Please Enter Valid Last Name"),
  users_category: Yup.string().required(
    "Job Sector Applying for Field Required"
  ),
  users_email: Yup.string()
    .required("Email Field Required")
    .email("Enter Valid Email"),
  users_phonenumber: Yup.string()
    .required("Phone Number Field Required")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Telephone Number"
    ),
  users_password: Yup.string()
    .required("Password field is required")
    .min(6, "Password field must be at least 6 characters"),
});

export const validationSchemaSpecificClient = Yup.object().shape({
  users_firstname: Yup.string()
    .required("First Name Field Required")
    .matches(/^[a-zA-Z]+$/, "Please Enter Valid First Name"),
  users_lastname: Yup.string()
    .required("Last Name Field Required")
    .matches(/^[a-zA-Z]+$/, "Please Enter Valid Last Name"),
  users_category: Yup.string().required(
    "Job Sector Applying for Field Required"
  ),
  job_type: Yup.string().required("Contract Type Field Required"),
  users_email: Yup.string()
    .required("Email Field Required")
    .email("Enter Valid Email"),
  users_phonenumber: Yup.string()
    .required("Phone Number Field Required")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Telephone Number"
    ),
  users_password: Yup.string()
    .required("Password field is required")
    .min(6, "Password field must be at least 6 characters"),
});

export const validationSchemaCandidate = Yup.object().shape({
  users_category: Yup.string().required("Job Sector Applying for Required"),
  job_type: Yup.string().required("Job Type Field Required"),
  right_to_work: Yup.string()
    .required("Right To Work Field Required")
    .oneOf(
      ["yes"],
      "Right to work in the UK required to register with Staffing Connect."
    ),
  users_firstname: Yup.string()
    .required("First Name Field Required").trim('').min(2, "First name should be more than 1 letter"),
  users_lastname: Yup.string()
    .required("Last Name Field Required").trim('').min(2, "Last name should be more than 1 letter"),
  users_email: Yup.string()
    .required("Email Field Required")
    .matches(/^\s*\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+\s*$/, "Enter Valid Email"),
  users_phonenumber: Yup.string()
    .required("Phone Number Field Required").matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Telephone Number"
    ),
  users_password: Yup.string()
    .required("Password field is required")
    .min(6, "Password field must be at least 6 characters"),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
