import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Error from "../../Layout/Error";
import { format } from "date-fns";
import { santetizeData } from "../../../helpers/FormFieldsSanetizations";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { WorkPrefrenceValidations } from "../../../helpers/WorkPrefrenceValidations";
import {
  jobTitle,
  qualifications,
  modeOfTravel,
} from "../../../helpers/WorkRefrencesHelper";
import { setError } from "../../../redux/actions/ErrorActions";
import Label from "../../Layout/Label";
import { Input } from "../../FormElements/Forms";
import { useHistory } from "react-router-dom";
import { getBase64 } from "../../../helpers/FileToBase64";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import constants from "../../../constants";
import { redirectUrl } from "../../../services/GlobalUrls";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import {
  capitalizeFirstLetter,
  convertToOriginalForm,
  convertToSnakeCase,
} from "../../../services/StringOperation";

const WorkPrefrenceForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [workPreferencesData, setFormdata] = useState({});
  const [jobtitle, setJobTitle] = useState("");
  const [selectedJobTitles, setSelectedJobTitle] = useState("");
  const [selectedQualification, setSelectedQualification] = useState("");
  const [notApplicable, setNotApplicable] = useState("");
  const [unQualified, setUnqualified] = useState(false);
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [selectJobTitle, setSelectJobTitle] = useState(false);
  const [removeJobTitle, setRemoveJobTitle] = useState(false);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [workPrefrences, setWorkPrefrences] = useState([]);
  const [fileName, setFileName] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);
  // New Code Added - Start
  const [allJobTitles, setAllJobTitles] = useState([]);
  let jobTitleNew = [];
  const newObject = Object.values(props.workPrefrencesJobTitles);

  useEffect(() => {
    newObject.map((item, index) => {
      jobTitleNew = [
        ...jobTitleNew,
        {
          id: item.cnd_jobs_and_assignment_title_key,
          name: item.cnd_jobs_and_assignment_title_label
            ? convertToOriginalForm(
                convertToSnakeCase(item.cnd_jobs_and_assignment_title_label)
              )
            : "",
        },
      ];
      // setAllJobTitles(jobTitleNew);
    });

    setAllJobTitles(jobTitleNew);
  }, [props.workPrefrencesJobTitles]);
  // New Code Added - End

  useEffect(() => {
    const lenOfData = props.workPreferenceQualificationDocuments.length;
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);

    let qualificationDocs = Object.values(
      props.workPreferenceQualificationDocuments
    );

    setWorkPrefrences(qualificationDocs);
  }, [props.workPreferenceQualificationDocuments]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // let notApplicable, unQualified;
  let qualificationTitles = Object.values(selectedQualification);
  let jobTitlesSelected = Object.values(selectedJobTitles);
  const { errors, loader } = props;

  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };
  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  const onChangeInputvalue = async (e) => {
    if (e.target.name === "cnd_qualification_documents_uploaded_file") {
      setFormdata({
        ...workPreferencesData,
        [e.target.name]: await getBase64(e.target.files[0]),
      });
    } else {
      setFormdata({
        ...workPreferencesData,
        [e.target.name]: e.target.value,
      });
    }
    // workPreferencesData[e.target.name] = e.target.value;
    // console.log("Qualifications Details  Are :=> " + e.target.name);

    // setFormdata(workPreferencesData);
    validateData();
  };

  const validateData = () => {
    let validations = WorkPrefrenceValidations(workPreferencesData);
    dispatch(setError(validations.stateData));
    return validations.error;
  };

  const updateOnSubmit = (e) => {
    e.preventDefault();
    if (!validateData()) {
      document.getElementById("loader").style.display = "block";
      dispatch(setError([]));
      workPreferencesData["users_access_token"] =
        props.userAuth.users_access_token;
      let cnd_qualification_documents_uploaded_file = document.getElementById(
        "cnd_qualification_documents_uploaded_file"
      );
      if (cnd_qualification_documents_uploaded_file) {
        workPreferencesData["acceptFileType"] =
          cnd_qualification_documents_uploaded_file.alt;
      }

      if (selectJobTitle == false && removeJobTitle == false) {
        if (workPreferencesData.cnd_work_job_title) {
          // console.log("Demo title List :=>"+selectedJobTitles);
          setSessionForRedirection(selectedJobTitles);
        }
      }
      dispatch(
        updateDataOnRequest(
          santetizeData(workPreferencesData),
          "UpdateWorkPreferences",
          "UPDATE_WORK_PReferenceS"
        )
      );
      dispatch(
        getDataOnRequest(
          props.userAuth.users_access_token,
          "ProgressBar",
          "SET_PROGRESS"
        )
      );
      //history.push('/document-compliance');
    }
  };
  // Delete Documents Code Start
  const deleteDocuments = (docId) => {
    let formdata = {
      cnd_qualification_documents_id: docId,
    };
    // formdata['cnd_qualification_documents_id'] = docId;
    // console.log("Form Data For Deletion :=>"+formdata);
    // DeleteWorkPreferenceQualificationDocument
    Swal.fire({
      title: "Confirm Delete Document",
      text: "Proceed with document deletion?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      // confirmButtonColor: 'green',
      // cancelButtonColor:  'red',
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        document.getElementById("loader").style.display = "block";
        dispatch(
          updateDataOnRequest(
            JSON.stringify(formdata, null, 2),
            "DeleteWorkPreferenceQualificationDocument",
            ""
          )
        );
      } else {
        // Cancel delete account logic
        Swal.fire(
          "Cancelled",
          "Document deletion has been cancelled.",
          "info"
        ).then((result) => {
          // window.location
        });
      }
    });
  };
  // Delete Document Code End
  // Qualification Condition Start
  const conditionQualification = (qualificationsSelected) => {
    if (!selectedQualification) {
      setNotApplicable("");
      setUnqualified(false);
    }
    if (qualificationsSelected) {
      setUnqualified(true);
    } else if (!qualificationsSelected) {
      setNotApplicable("");
      setUnqualified(false);
    }
    // console.log("Length of Selected Qual :=>"+qualificationsSelected.length);
    {
      qualificationsSelected.map((qualItem, qualIndex) => {
        if (qualificationsSelected.length != 0) {
          if (qualificationsSelected.length == 1) {
            setNotApplicable(qualItem.id);
          } else if (qualificationsSelected.length == 2) {
            setNotApplicable(notApplicable + qualItem.id);
          } else if (qualificationsSelected.length > 2) {
            setNotApplicable("");
          }
        } else if (qualificationsSelected.length == 0) {
          setNotApplicable("");
        }
      });
    }
  };
  // Qualification Condition End

  const setSessionForRedirection = (addList) => {
    addList.map((listItem, listNum) => {
      // FLT License
      if (listItem.id == "forklift_truck_drivers_(all_categories)") {
        localStorage.setItem("fltMenu", true);
      }
      // Driver License
      if (
        listItem.id ==
          convertToSnakeCase("HGV / 7.5 Tonne / Multi-Drop Drivers") ||
        listItem.id ==
          convertToSnakeCase("HGV & Commercial Vehicle Technicians")
      ) {
        localStorage.setItem("driverMenu", true);
      }
    });
  };

  const onSelect = (selectedList, column) => {
    const add = selectedList.map((x) => x.id).join(",");
    workPreferencesData[column] = add;
    // Show File Upload Start
    if (column == "cnd_work_job_title") {
      localStorage.setItem("fltMenu", false);
      localStorage.setItem("driverMenu", false);
      setSessionForRedirection(selectedList);
      setSelectJobTitle(true);
    }
    if (column == "cnd_work_qualification") {
      conditionQualification(selectedList);
    }
    // Show File Upload End
    setFormdata(workPreferencesData);
  };

  const onRemove = (selectedList, column) => {
    const rem = selectedList.map((x) => x.id).join(",");
    workPreferencesData[column] = rem;
    if (column == "cnd_work_job_title") {
      localStorage.setItem("fltMenu", false);
      localStorage.setItem("driverMenu", false);
      setSessionForRedirection(selectedList);
      setRemoveJobTitle(true);
    }
    // Show File Upload Start
    if (column == "cnd_work_qualification") {
      conditionQualification(selectedList);
    }
    // Show File Upload End
    setFormdata(workPreferencesData);
  };
  let mainObj = [];
  let arrayValues = [];
  const convertJobTitleValuesInArray = (valuesInArray) => {
    allJobTitles.map((val, i) => {
      arrayValues[val.id] = val.name;
    });
    valuesInArray.map((value, key) => {
      let capWord = convertToOriginalForm(value);
      mainObj[key] = { id: value, name: capWord };
    });
    // console.log("Demo test :=>"+JSON.stringify(valuesInArray));
    setSelectedJobTitle(mainObj);
  };

  const convertQualificationsInArray = (valuesInArray) => {
    let mainObj = [];
    let arrayValues = {};
    qualifications.map((val, i) => {
      arrayValues[val.id] = val.name;
    });
    valuesInArray.map((value, key) => {
      mainObj[key] = { name: arrayValues[value], id: value };
    });
    setSelectedQualification(mainObj);
    conditionQualification(mainObj);
  };

  useEffect(() => {
    let formdata = {};
    if (Object.keys(props.workPrefrences).length > 0) {
      formdata = props.workPrefrences;
      var valuesInArrayJobTitle = formdata.cnd_work_job_title.split(",");
      convertJobTitleValuesInArray(valuesInArrayJobTitle, "jobTitle");
      var valuesQualification = formdata.cnd_work_qualification.split(",");
      convertQualificationsInArray(valuesQualification, "qualifications");
    } else {
      formdata = {
        users_access_token: props.userAuth.users_access_token,
        cnd_work_job_title: "",
        cnd_work_qualification: "",
        cnd_work_mode_of_travel: "",
        cnd_work_travel_range: "",
        // cnd_work_minpay: "",
      };
    }
    setFormdata(formdata);
  }, [props.workPrefrences]);

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  // Filter Start
  const matchesSearchText = (docs) => {
    const searchText = inputValue.toLowerCase();
    return docs.cnd_qualification_documents_created_date
      .toLowerCase()
      .includes(searchText);
  };

  // Filter applied jobs based on the search text
  const filteredDocs = workPrefrences
    ? workPrefrences.filter(matchesSearchText)
    : [];
  // Filter End

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Work Preference
          </h5>
        </div>
        <form>
          <div className="row m-b30">
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>
                  Job Title<sup style={{ color: "red" }}>*</sup>
                </label>
                <Multiselect
                  // options={jobTitle}
                  // selectedValues={selectedJobTitles}
                  options={allJobTitles && allJobTitles}
                  selectedValues={selectedJobTitles && selectedJobTitles}
                  onSelect={(e) => onSelect(e, "cnd_work_job_title")}
                  onRemove={(e) => onRemove(e, "cnd_work_job_title")}
                  displayValue="name"
                  className={
                    errors && errors["cnd_work_job_title"] ? "invalid" : "valid"
                  }
                />
                <Error errors={errors} fieldName={"cnd_work_job_title"} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>
                  Qualification and Skills - “UK Qualifications valid only“{" "}
                  <sup style={{ color: "red" }}>*</sup>
                </label>
                {/* {notApplicable} */}
                <Multiselect
                  options={qualifications}
                  selectedValues={selectedQualification}
                  onSelect={(e) => onSelect(e, "cnd_work_qualification")}
                  onRemove={(e) => onRemove(e, "cnd_work_qualification")}
                  onChange={(e) => onChangeInputvalue(e)}
                  displayValue="name"
                  className={
                    errors && errors["cnd_work_qualification"]
                      ? "invalid"
                      : "valid"
                  }
                />
                <Error errors={errors} fieldName={"cnd_work_qualification"} />
              </div>
            </div>

            {/* Upload File Start */}
            {unQualified == true &&
              notApplicable != "healthandsocialcarelevel7" &&
              notApplicable != "healthandsocialcarelevel8" &&
              notApplicable !=
                "healthandsocialcarelevel7healthandsocialcarelevel8" &&
              notApplicable !=
                "healthandsocialcarelevel8healthandsocialcarelevel7" && (
                <>
                  <Input
                    label="Upload Qualification Document"
                    name="cnd_qualification_documents_uploaded_file"
                    id="cnd_qualification_documents_uploaded_file"
                    type="file"
                    fileUrl={""}
                    accept={".pdf, .jpeg, .jpg, .png"}
                    alt={"png, pdf, jpg, jpeg"}
                    changeHandler={(e) => onChangeInputvalue(e)}
                    className="form-control"
                    errors={errors}
                    lg={12}
                  />
                </>
              )}
            {/* Upload File End */}

            <Form.Group as={Col} md="6" controlId={"cnd_work_mode_of_travel"}>
              <Label labelName="Preferred Mode of Travel" />
              <Form.Control
                required
                as="select"
                name="cnd_work_mode_of_travel"
                custom
                defaultValue={workPreferencesData.cnd_work_mode_of_travel}
                value={workPreferencesData.cnd_work_mode_of_travel}
                onChange={(e) => onChangeInputvalue(e)}
                isInvalid={
                  errors && errors["cnd_work_mode_of_travel"] ? true : false
                }
                className={
                  errors && errors["cnd_work_mode_of_travel"]
                    ? "invalid"
                    : "valid"
                }
              >
                <option value="">Select Travel Mode</option>
                {Object.keys(modeOfTravel).map((i) => (
                  <option value={i} key={i}>
                    {modeOfTravel[i]}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors && errors["cnd_work_mode_of_travel"]}
              </Form.Control.Feedback>
            </Form.Group>
            {/* <div className="col-lg-6 col-md-6"></div> */}
            <Input
              type="text"
              label="Travel Range (in minutes)"
              placeholder={"Eg., 30"}
              changeHandler={(e) => onChangeInputvalue(e)}
              name="cnd_work_travel_range"
              value={workPreferencesData.cnd_work_travel_range}
              lg={6}
              md={6}
              maxLength={3}
              errors={errors}
            />
            {/* <Input
              type="text"
              label="Min pay per hour"
              placeholder={"Eg., 15"}
              changeHandler={(e) => onChangeInputvalue(e)}
              name="cnd_work_minpay"
              value={workPreferencesData.cnd_work_minpay}
              lg={6}
              md={6}
              errors={errors}
            /> */}
            <div className="col-lg-12 col-md-12">
              {/* <div className="form-group"></div> */}
              <Button
                disabled={loader ? "disabled" : ""}
                className="site-button m-b30 pull-right"
                onClick={(e) => updateOnSubmit(e)}
              >
                {loader && (
                  <Spinner
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                  />
                )}
                {!loader ? "Save & Next" : "Loading..."}
              </Button>
            </div>
          </div>
        </form>

        {/* Documents Table Start */}
        <div className="row m-b30" id="scrollFocusElement">
          <div className="job-bx-title clearfix pl-3 pr-3 mb-1">
            <h5 className="font-weight-700 pull-left text-uppercase">
              Qualification Documents
            </h5>
          </div>

          {checkDevice != "Mobile" && <div class="col-sm-6"></div>}
          <div
            class={
              "col-sm-" +
              (checkDevice == "Mobile" ? "12 justify-content-center" : "6")
            }
          >
            <label class="form-control-label text-right" for="search">
              Search
            </label>
            <div class="form-group form-group-default">
              <input
                type="text"
                name="search"
                class="form-control "
                placeholder="Enter key to search"
                onInput={(event, value) => {
                  setInputValue(event.target.value);
                }}
              />
            </div>
          </div>

          {/* Qualification Documents */}
          {checkDevice && checkDevice == "Mobile" ? (
            <>
              <div className="m-b30 wrapper">
                {filteredDocs &&
                  filteredDocs.length > 0 &&
                  filteredDocs
                    .slice(startIndex, endIndex)
                    .map((workQualDocs, i) => (
                      <ul
                        className="nav navbar-nav border border-grey p-4 mb-2"
                        style={{ borderRadius: "20px" }}
                        key={i}
                      >
                        <li className="text-dark">
                          <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                          {
                            workQualDocs.cnd_qualification_documents_created_date
                          }
                          <a
                            onClick={() =>
                              viewFileModal(
                                constants.IMAGE_URL +
                                  workQualDocs.cnd_qualification_documents_uploaded_file_path +
                                  workQualDocs.cnd_qualification_documents_uploaded_file
                              )
                            }
                            className={
                              "btn btn-" +
                              (workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                ".png"
                              ) ||
                              workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                ".jpg"
                              ) ||
                              workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                ".jpeg"
                              ) ||
                              workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                ".pdf"
                              )
                                ? "dark"
                                : "primary") +
                              " btn-sm text-white pull-right"
                            }
                          >
                            <i
                              className={
                                "fa fa-" +
                                (workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                  ".png"
                                ) ||
                                workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                  ".jpg"
                                ) ||
                                workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                  ".jpeg"
                                ) ||
                                workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                  ".pdf"
                                )
                                  ? "eye" //"eye"
                                  : "download")
                              }
                            ></i>
                          </a>
                        </li>
                      </ul>
                    ))}

                {workPrefrences.length === 0 ? (
                  <ul
                    className="nav navbar-nav border border-grey p-4 mb-2 text-center"
                    style={{ borderRadius: "20px" }}
                  >
                    No Result Found
                  </ul>
                ) : filteredDocs.length === 0 && inputValue ? (
                  <ul
                    className="nav navbar-nav border border-grey p-4 mb-2 text-center"
                    style={{ borderRadius: "20px" }}
                  >
                    No Result Found
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-12 col-lg-12 m-b0 wrapper">
                <table
                  className="table table-striped table-hover table-sm"
                  style={{ width: "100%" }}
                >
                  <thead className="thead-dark">
                    <tr>
                      {/*<th className="col-1 text-left" scope="col">Sr. No</th>*/}
                      <th className="col-4 text-center" scope="col">
                        Date & Time
                      </th>
                      <th className="col-4 text-center" scope="col">
                        Qualification Document
                      </th>
                      {props.userAuth.cnd_status == "In-Progress" ||
                      props.userAuth.cnd_status == "Pending" ? (
                        <th className="col-4 text-center" scope="col">
                          Action
                        </th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDocs &&
                      filteredDocs
                        .slice(startIndex, endIndex)
                        .map((workQualDocs, i) => (
                          <tr key={i}>
                            {/*<th className="col-1 text-center" scope="row">{i + 1}</th>*/}
                            <td className="col-4 text-center">
                              {
                                workQualDocs.cnd_qualification_documents_created_date
                              }
                            </td>
                            <td className="col-4 text-center">
                              <a
                                onClick={() =>
                                  viewFileModal(
                                    constants.IMAGE_URL +
                                      workQualDocs.cnd_qualification_documents_uploaded_file_path +
                                      workQualDocs.cnd_qualification_documents_uploaded_file
                                  )
                                }
                                className={
                                  "btn btn-" +
                                  (workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                    ".png"
                                  ) ||
                                  workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                    ".jpg"
                                  ) ||
                                  workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                    ".jpeg"
                                  ) ||
                                  workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                    ".pdf"
                                  )
                                    ? "dark"
                                    : "primary") +
                                  " btn-sm text-white"
                                }
                              >
                                <i
                                  className={
                                    "fa fa-" +
                                    (workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                      ".png"
                                    ) ||
                                    workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                      ".jpg"
                                    ) ||
                                    workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                      ".jpeg"
                                    ) ||
                                    workQualDocs.cnd_qualification_documents_uploaded_file.endsWith(
                                      ".pdf"
                                    )
                                      ? "eye" //"eye"
                                      : "download")
                                  }
                                ></i>
                              </a>
                            </td>
                            {props.userAuth.cnd_status == "In-Progress" ||
                            props.userAuth.cnd_status == "Pending" ? (
                              <td className="col-4 text-center">
                                <a
                                  onClick={() =>
                                    deleteDocuments(
                                      workQualDocs.cnd_qualification_documents_id
                                    )
                                  }
                                  className="btn btn-danger btn-sm text-white"
                                >
                                  <i className="fa fa-trash "></i>
                                </a>
                                {/* {JSON.stringify(props.userAuth.cnd_status)} */}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}

                    {filteredDocs.length == 0 ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No document found!
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
                {filteredDocs && filteredDocs.length > 10 && (
                  <TableDropdownAndPagination
                    handleDropDown={handleDropDown}
                    recordLen={recordLen}
                    handleLeft={handleLeft}
                    handleRight={handleRight}
                    renderPageNumbers={renderPageNumbers}
                    isDropdown={true}
                    isPaggination={true}
                  />
                )}
                {/* <a href="#" onClick={""} className="site-button listingnextbtn">
              Next
            </a> */}
              </div>
            </>
          )}
          {/* Qualification Documents */}
        </div>
        {/* Documents Table End */}
      </div>

      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={checkDevice == "Mobile" ? "Qualification Document" : "Work Prefrences - Qualification Document"}
        // fileName={fileName}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    workPrefrences: state.workPrefrences.workPrefrences,
    workPreferenceQualificationDocuments:
      state.workPreferenceQualificationDocuments
        .workPreferenceQualificationDocuments,
    workPrefrencesJobTitles:
      state.workPrefrencesJobTitles.workPrefrencesJobTitles,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(WorkPrefrenceForm);
