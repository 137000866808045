import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInputFields, validationSchema } from "./FormHelper";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import LoadFormFields from "../../Layout/LoadFormFields";
import AssesmentCategoryProfileContainer from "../../../containers/AssesmentCategoryProfileContainer";
const AssesmentCategoryProfile = (props) => {
  const [riskMaster, setRiskMaster] = useState([]);
  const { loader, userDetails, assesmentCategory, riskProfile, careplan } =
    props;
  const dispatch = useDispatch();
  const [assesmentCategories, setFormdata] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    removeValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "assesmentCategory",
  });

  useEffect(() => {
    if (formInputFields(assesmentCategory).length > 0)
      formInputFields().map((field) => {
        if (assesmentCategory[field.name])
          setValue(field.name, assesmentCategory[field.name]);
      });
    let riskMaster = [{ name: "Select Risk Profile", id: "" }];
    if (riskProfile) {
      riskProfile.map((risk) => {
        riskMaster.push({ name: risk.clt_risk_title, id: risk.clt_risk_id });
      });
      setRiskMaster(riskMaster);
    }
    if (assesmentCategory.length <= 0) {
      let defaultFormData = [
        {
          users_access_token: userDetails && userDetails.users_access_token,
          clt_ass_develop_careplan_id: careplan && careplan.clt_care_id,
        },
      ];
      setValue("assesmentCategory", defaultFormData);
      setFormdata(defaultFormData);
    } else {
      setValue("assesmentCategory", assesmentCategory);
      setFormdata(assesmentCategory);
    }
  }, [assesmentCategory, riskProfile, careplan]);

  const onSubmit = (data) => {
    let formData = data["assesmentCategory"];
    formData[0]["users_access_token"] = userDetails.users_access_token;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "SaveAssesmentProfile",
        "",
        getDataOnRequest(
          userDetails.users_access_token,
          "GetSaveAssesmentProfile",
          "SET_ASSESMENT_CATEGORY_RISK"
        )
      )
    );
  };

  const onChangeInputvalue = (e, key) => {
    // const { name, value } = e.target;
    // assesmentCategories[key][name] = value;
    // setFormdata(assesmentCategories);
  };

  const addMore = () => {
    let loadMoredata = [
      ...assesmentCategories,
      {
        users_access_token: userDetails.users_access_token,
        clt_ass_develop_careplan_id: careplan && careplan.clt_care_id,
      },
    ];
    setFormdata(loadMoredata);
  };

  const handleRemoveClick = (index) => {
    const list = [...assesmentCategories];
    list.splice(index, 1);
    setFormdata(list);
    remove(index);
  };
  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Assesment Category Profile
          </h5>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {assesmentCategories.map((individualData, i) => (
            <React.Fragment key={i}>
              {assesmentCategories.length > 1 && (
                <>
                  <h6>
                    Assesment Category - {i + 1}
                    <i
                      className="pull-right fa fa-trash red-icon"
                      onClick={(e) => handleRemoveClick(i)}
                    ></i>
                  </h6>
                  <hr />
                </>
              )}

              <LoadFormFields
                key={i}
                register={register}
                control={control}
                errors={errors.assesmentCategory && errors.assesmentCategory[i]}
                formInputFields={formInputFields(riskMaster, i)}
                onChangeInputvalue={(e) => onChangeInputvalue(e)}
                reset={reset}
                addMoreButton={true}
                arrayForm={true}
                addMore={addMore}
                data={individualData}
                loadExternalButtons={true}
              />
              {assesmentCategories.length === 1 && i > 0 && <hr />}
            </React.Fragment>
          ))}
          <Form.Group as={Col} md="12" sm="12">
            <Button
              disabled={loader ? "disabled" : ""}
              className="site-button m-b30 float-right"
              type="submit"
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Save"}
            </Button>
            <Button
              className="m-r5 m-b30 site-button yellow float-right"
              onClick={addMore}
            >
              Add More
            </Button>
            <Button
              disabled={loader ? "disabled" : ""}
              className="m-b30 m-r5 site-button orange float-right"
              onClick={() => reset()}
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Reset"}
            </Button>
          </Form.Group>
        </form>
        <br />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    assesmentCategory: state.assesmentCategory.assesmentCategory,
    careplan: state.careplan.careplan,
    riskProfile: state.individual.riskProfile,
  };
};
export default connect(mapStateToProps)(
  AssesmentCategoryProfileContainer(AssesmentCategoryProfile)
);
