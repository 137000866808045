import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
const UserProfile = (OriginalComponent) => {
  const NewComponent = (props) => {
    toast.dismiss();
    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userData:state.userProfile.userData,
    userAuth:state.auth.authUserData,
    loader:state.loader.loader,
    jobsdetails: state.jobsdetails.jobsdetails,
  };
};

const composedUserProfile = compose(connect(mapStateToProps), UserProfile)
export default composedUserProfile;

