import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadloginInfo } from "./../redux/actions/AuthActions";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import { useHistory } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import PageTitle from "../components/Layout/PageTitle";
import GoogleMaps from "simple-react-google-maps";

// import ContactBanner from './../images/banner/new_contact_banner.jpg';
// Newly Added Image
import ContactBanner from "./../images/new-updated-images/contact-us/contact-us.jpg";
import { Button } from "react-bootstrap";
import { validateEmail, validateUkNumber } from "../helpers/ValidationHelpers";
import { updateDataOnRequest } from "../redux/actions/CommonActions";
import Label from "../components/Layout/Label";

// var bnr = require('./../images/banner/new_contact_banner.jpg');

function Contact() {
  // Add title
  if(window.location.pathname == "/contact-us"){
    document.getElementById("titleText").innerText = "Staffing Connect - Contact Us";
  }else{
    document.getElementById("titleText").innerText = "Staffing Connect - Get in touch";
  }

  const formRef = useRef(null);
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  // Set Random Numbers Start
  const [ranNumFirst, setRanNumFirst] = useState((Math.floor(Math.random() * 10) == 0 ? 1 : Math.floor(Math.random() * 10)));
  const [ranNumSec, setRanNumSec] = useState((Math.floor(Math.random() * 10) == 0 ? 1 : Math.floor(Math.random() * 10)));
  const [ranNumResult, setRanNumResult] = useState(ranNumFirst + ranNumSec);
  
  const getRandomNumbers = () => {
    let firstVal = Math.floor(Math.random() * 10);
    let secondVal = Math.floor(Math.random() * 10);

    setRanNumFirst((firstVal == 0 ? 1 : firstVal));
    setRanNumSec((secondVal == 0 ? 1 : secondVal));

    setRanNumResult((firstVal == 0 ? 1 : firstVal) + (secondVal == 0 ? 1 : secondVal));
  };
  // Set Random Numbers End
  
  const onChangeData = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // console.log("Console Values :=>"+e.target.value);
    // console.log("Form Data  Are :=>" + JSON.stringify(formData));
    // Validation Start
    // let formDataStore = JSON.parse(formData);
    let finalValid = false;
    // Fields Value Start
    let contact_name = document.getElementById("contact_name");
    let contact_email = document.getElementById("contact_email");
    let contact_phone_number = document.getElementById("contact_phone_number");
    let contact_subject = document.getElementById("contact_subject");
    let contact_message = document.getElementById("contact_message");
    // Fields value Stop
    
    // console.log("OnChange Data Are :=>" + ranNumResult);
    // Msg Start
    let contact_name_msg = document.getElementById("contact_name_msg");
    let contact_email_msg = document.getElementById("contact_email_msg");
    let contact_phone_number_msg = document.getElementById(
      "contact_phone_number_msg"
    );
    let contact_subject_msg = document.getElementById("contact_subject_msg");
    let contact_message_msg = document.getElementById("contact_message_msg");
    // Msg Stop
    if (!contact_name.value || contact_name.value.trim() == "") {
      contact_name.classList.add("border-danger");
      contact_name_msg.innerText = "Please enter your name";
      finalValid = true;
    } else {
      contact_name.classList.remove("border-danger");
      contact_name_msg.innerText = "";
      // finalValid = false;
    }

    if (!contact_email.value || contact_email.value.trim() == "") {
      contact_email.classList.add("border-danger");
      contact_email_msg.innerText = "Please enter your email address";
      finalValid = true;
    } else if (!validateEmail(contact_email.value)) {
      contact_email.classList.add("border-danger");
      contact_email_msg.innerText = "Please enter valid email address";
      finalValid = true;
    } else {
      contact_email.classList.remove("border-danger");
      contact_email_msg.innerText = "";
      // finalValid = false;
    }

    if (
      !contact_phone_number.value ||
      contact_phone_number.value.trim() == ""
    ) {
      contact_phone_number.classList.add("border-danger");
      contact_phone_number_msg.innerText = "Please enter your phone number";
      finalValid = true;
    } else if (!validateUkNumber(contact_phone_number.value)) {
      contact_phone_number.classList.add("border-danger");
      contact_phone_number_msg.innerText = "Please enter valid UK phone number";
      finalValid = true;
    } else {
      contact_phone_number.classList.remove("border-danger");
      contact_phone_number_msg.innerText = "";
      // finalValid = false;
    }

    if (!contact_subject.value || contact_subject.value.trim() == "") {
      contact_subject.classList.add("border-danger");
      contact_subject_msg.innerText = "Please enter subject";
      finalValid = true;
    } else {
      contact_subject.classList.remove("border-danger");
      contact_subject_msg.innerText = "";
      // finalValid = false;
    }

    if (!contact_message.value || contact_message.value.trim() == "") {
      contact_message.classList.add("border-danger");
      contact_message_msg.innerText = "Please enter message";
      finalValid = true;
    } else {
      contact_message.classList.remove("border-danger");
      contact_message_msg.innerText = "";
      // finalValid = false;
    }

    if(finalValid == false){
      document.getElementById("showCaptcha").style.visibility = "visible";
    }else{
      document.getElementById("showCaptcha").style.visibility = "hidden";
    }

    // Validation End
  };

  const onSubmitData = (e) => {
    e.preventDefault();
    // let formDataStore = JSON.parse(formData);
    let finalValid = false;
    // Fields Value Start
    let contact_name = document.getElementById("contact_name");
    let contact_email = document.getElementById("contact_email");
    let contact_phone_number = document.getElementById("contact_phone_number");
    let contact_subject = document.getElementById("contact_subject");
    let contact_message = document.getElementById("contact_message");

    let captcha_output = document.getElementById("captcha_output"); // For Captcha
    // Fields value Stop

    // Msg Start
    let contact_name_msg = document.getElementById("contact_name_msg");
    let contact_email_msg = document.getElementById("contact_email_msg");
    let contact_phone_number_msg = document.getElementById(
      "contact_phone_number_msg"
    );
    let contact_subject_msg = document.getElementById("contact_subject_msg");
    let contact_message_msg = document.getElementById("contact_message_msg");

    let captcha_output_msg = document.getElementById("captcha_output_msg"); // For Captcha
    // Msg Stop
    if (!contact_name.value || contact_name.value.trim() == "") {
      contact_name.classList.add("border-danger");
      contact_name_msg.innerText = "Please enter your name";
      finalValid = true;
    } else {
      contact_name.classList.remove("border-danger");
      contact_name_msg.innerText = "";
      // finalValid = false;
    }

    if (!contact_email.value || contact_email.value.trim() == "") {
      contact_email.classList.add("border-danger");
      contact_email_msg.innerText = "Please enter your email address";
      finalValid = true;
    } else if (!validateEmail(contact_email.value)) {
      contact_email.classList.add("border-danger");
      contact_email_msg.innerText = "Please enter valid email address";
      finalValid = true;
    } else {
      contact_email.classList.remove("border-danger");
      contact_email_msg.innerText = "";
      // finalValid = false;
    }

    if (
      !contact_phone_number.value ||
      contact_phone_number.value.trim() == ""
    ) {
      contact_phone_number.classList.add("border-danger");
      contact_phone_number_msg.innerText = "Please enter your phone number";
      finalValid = true;
    } else if (!validateUkNumber(contact_phone_number.value)) {
      contact_phone_number.classList.add("border-danger");
      contact_phone_number_msg.innerText = "Please enter valid UK phone number";
      finalValid = true;
    } else {
      contact_phone_number.classList.remove("border-danger");
      contact_phone_number_msg.innerText = "";
      // finalValid = false;
    }

    if (!contact_subject.value || contact_subject.value.trim() == "") {
      contact_subject.classList.add("border-danger");
      contact_subject_msg.innerText = "Please enter subject";
      finalValid = true;
    } else {
      contact_subject.classList.remove("border-danger");
      contact_subject_msg.innerText = "";
      // finalValid = false;
    }

    if (!contact_message.value || contact_message.value.trim() == "") {
      contact_message.classList.add("border-danger");
      contact_message_msg.innerText = "Please enter message";
      finalValid = true;
    } else {
      contact_message.classList.remove("border-danger");
      contact_message_msg.innerText = "";
      // finalValid = false;
    }

    if(!captcha_output.value){
      captcha_output.classList.add("border-danger");
      captcha_output_msg.innerText = "Please enter the captcha";
      finalValid = true;
    }else if(captcha_output.value && (captcha_output.value != ranNumResult)){
      captcha_output.classList.add("border-danger");
      getRandomNumbers();
      captcha_output_msg.innerText = "Please enter the valid captcha";
      finalValid = true;
    }else{
      captcha_output.classList.remove("border-danger");
      captcha_output_msg.innerText = "";
      // finalValid = false;      
    }

    if (finalValid == false) {
      document.getElementById("loader").style.display = "block";
      dispatch(
        updateDataOnRequest(JSON.stringify(formData, null, 2), "ContactUs", "")
      );
      // console.log("Console Data Are :=>" + JSON.stringify(formData));
    }
  };

  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle mb-0"
          style={{ backgroundImage: `url(${ContactBanner})`, height: 500, marginTop: (window.location.pathname == "/mobile/contact-us" ? "0rem" : "") }}
        >
          <PageTitle motherName="Home" activeName="Contact Us" />
        </div>

        <div
          className="section-head text-black text-center col-lg-12 mt-4"
          style={{ marginBottom: "-6%" }}
        >
          <h2 className="text-uppercase m-b0">Contact Us</h2>
        </div>

        <div className="section-full content-inner bg-white contact-style-1">
          <div className="container ">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-lg-flex d-md-flex ">
                <div className="p-a30 border m-b30 contact-area border-1 align-self-stretch radius-sm ">
                  <h4 className="m-b10">Staffing Connect - Hounslow</h4>
                  <p>
                    If you have any questions simply use the following contact
                    details.
                  </p>
                  <ul className="no-margin">
                    <li className="icon-bx-wraper left m-b30">
                      <div className="icon-bx-xs border-1">
                        {" "}
                        <Link to={"#"} className="icon-cell">
                          <i className="ti-location-pin"></i>
                        </Link>{" "}
                      </div>
                      <div className="icon-content">
                        <h6 className="text-uppercase m-tb0 dez-tilte">
                          Address:{" "}
                        </h6>
                        <p>
                          Suite 14, Neals Corner, 2 Bath Road, Hounslow,
                          Middlesex, TW3 3HJ.
                        </p>
                      </div>
                    </li>
                    <li className="icon-bx-wraper left  m-b30">
                      <div className="icon-bx-xs border-1">
                        {" "}
                        <Link to={"#"} className="icon-cell">
                          <i className="ti-email"></i>
                        </Link>{" "}
                      </div>
                      <div className="icon-content">
                        <h6 className="text-uppercase m-tb0 dez-tilte">
                          Email:{" "}
                        </h6>
                        <p>
                          <a href="mailto:info@staffingconnect.co.uk">
                            info@staffingconnect.co.uk
                          </a>
                        </p>
                      </div>
                    </li>
                    <li className="icon-bx-wraper left">
                      <div className="icon-bx-xs border-1">
                        {" "}
                        <Link to={"#"} className="icon-cell">
                          <i className="ti-mobile"></i>
                        </Link>{" "}
                      </div>
                      <div className="icon-content">
                        <h6 className="text-uppercase m-tb0 dez-tilte">
                          Tel:{" "}
                        </h6>
                        <p>
                          <a href="tel:0203 841 6440">0203 841 6440</a>
                        </p>
                      </div>
                    </li>
                    {/* <li className="icon-bx-wraper left">
											<div className="icon-bx-xs border-1"> <Link to={"#"} className="icon-cell"><i className="ti-printer"></i></Link> </div>
											<div className="icon-content">
												<h6 className="text-uppercase m-tb0 dez-tilte">FAX</h6>
												<p>0203 841 6441</p>
											</div>
										</li> */}
                  </ul>
                  {/* <div className="m-t20">
										<ul className="dez-social-icon dez-social-icon-lg">
											<li><Link to={"#"} className="fa fa-facebook bg-primary mr-1"></Link></li>
											<li><Link to={"#"} className="fa fa-twitter bg-primary mr-1"></Link></li>
											<li><Link to={"#"} className="fa fa-linkedin bg-primary mr-1"></Link></li>
											<li><Link to={"#"} className="fa fa-pinterest-p bg-primary mr-1"></Link></li>
											<li><Link to={"#"} className="fa fa-google-plus bg-primary"></Link></li>
										</ul>
									</div> */}
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="p-a30 m-b30 radius-sm bg-gray clearfix">
                  <h4>Send Message</h4>
                  <div className="dzFormMsg"></div>
                  <form ref={formRef} noValidate onSubmit={onSubmitData}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="contact_name"
                              id="contact_name"
                              type="text"
                              className="form-control"
                              placeholder="Your Name"
                              onInput={(e) => onChangeData(e)}
                            />
                          </div>
                          <p
                            id="contact_name_msg"
                            className="text-danger"
                            style={{ fontSize: "90%" }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="contact_email"
                              id="contact_email"
                              type="email"
                              className="form-control"
                              placeholder="Your Email Address"
                              onInput={(e) => onChangeData(e)}
                            />
                          </div>
                          <p
                            id="contact_email_msg"
                            className="text-danger"
                            style={{ fontSize: "90%" }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="contact_phone_number"
                              id="contact_phone_number"
                              type="tel"
                              className="form-control"
                              placeholder="Your Phone Number"
                              onInput={(e) => onChangeData(e)}
                            />
                          </div>
                          <p
                            id="contact_phone_number_msg"
                            className="text-danger"
                            style={{ fontSize: "90%" }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="contact_subject"
                              id="contact_subject"
                              type="text"
                              className="form-control"
                              placeholder="Your Subject"
                              onInput={(e) => onChangeData(e)}
                            />
                          </div>
                          <p
                            id="contact_subject_msg"
                            className="text-danger"
                            style={{ fontSize: "90%" }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <textarea
                              name="contact_message"
                              id="contact_message"
                              rows="4"
                              className="form-control"
                              placeholder="Your Message..."
                              onInput={(e) => onChangeData(e)}
                            ></textarea>
                          </div>
                          <p
                            id="contact_message_msg"
                            className="text-danger"
                            style={{ fontSize: "90%" }}
                          ></p>
                        </div>
                            
                      </div>
                    </div>
                    <div className="row" id="showCaptcha" style={{visibility:"hidden"}} >
                     <h6 className="ml-2">Are you human?</h6>
                      <div className="col-lg-4">
                        <h2 className="text-center m-1"><d className="bg-light p-2">{ranNumFirst + " + " + ranNumSec}</d> <b className="pull-right">=</b></h2>
                      </div>
                      <div className="col-lg-5">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="captcha_output"
                              id="captcha_output"
                              type="text"
                              className="form-control"
                              placeholder="Enter Captcha"
                              onInput={(e) => onChangeData(e)}
                            />
                          </div>
                          <p
                            id="captcha_output_msg"
                            className="text-danger"
                            style={{ fontSize: "80%" }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="recaptcha-bx">
                          <div className="input-group">
                            <b style={{cursor:"pointer"}} className="ml-2">
                            <a
                              onClick={getRandomNumbers}
                              className="text-primary"
                            >
                              Refresh Captcha?
                            </a>
                            </b>
                            <div
                              className="g-recaptcha"
                              data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                              data-callback="verifyRecaptchaCallback"
                              data-expired-callback="expiredRecaptchaCallback"
                            ></div>
                            <input
                              className="form-control d-none"
                              style={{ display: "none" }}
                              data-recaptcha="true"
                              data-error="Please complete the Captcha"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">                    
                      <div className="col-lg-12">
                        <Button
                          className="site-button m-b30 float-right"
                          type="submit"
                        >
                          {"Submit"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 ">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.4592561026884!2d-0.36865438466174477!3d51.46808392139866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760cd90669c1ad%3A0x147d55f4e8c89fb5!2sStaffing+Connect!5e0!3m2!1sen!2sin!4v1555677958691!5m2!1sen!2sin" frameborder="0" style={{ border: "0", height: '100%', width: '100%' }} allowfullscreen></iframe> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.4593607672823!2d-0.36865169999999675!3d51.468082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x147d55f4e8c89fb5!2sStaffing%20Connect!5e0!3m2!1sen!2sin!4v1650374447479!5m2!1sen!2sin"
                  frameborder="0"
                  style={{ border: "0", height: "400px", width: "100%" }}
                  allowfullscreen
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
