import React from "react";
import { Link } from "react-router-dom";
import icon1 from "./../../images/home_icon/1_Nursery assistant-01.png";
import icon2 from "./../../images/home_icon/Care_worker-01.png";
import icon3 from "./../../images/home_icon/Teacher-01.png";
import icon4 from "./../../images/home_icon/Learning_support_assistant-01.png";
import icon5 from "./../../images/home_icon/warehouse-operative.png";
import icon6 from "./../../images/home_icon/assembly-operative.png";
import icon7 from "./../../images/home_icon/AdminManager-01.png";
import icon8 from "./../../images/home_icon/Residentialcareworker-01.png";
function Jobcategories() {
  let userType = localStorage.getItem("user_job_type");
  let jobUrl = "";
  if (userType) {
    if (userType == "permanentrequirement") {
      jobUrl = "/applied-jobs";
    } else {
      jobUrl = "/dashboard";
    }
  } else {
    jobUrl = "/login";
  }
  return (
    <div className="row sp20">
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="icon-bx-wraper navyblue">
          <div className="icon-content">
            <div className="icon-md text-primary m-b20">
              <img alt="Nursery Assistant Icon" src={icon1} />
            </div>
            <a href={jobUrl} className="dez-tilte">
              Nursery Assistant{" "}
            </a>
            <p className="m-a0">248 Jobs</p>
            {/* <div className="rotate-icon"><i className="ti-location-pin"></i></div>  */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="icon-bx-wraper green">
          <div className="icon-content">
            <div className="icon-md text-primary m-b20">
              {/* <i className="ti-wand"></i> */}
              <img alt="Care Workers Icon" src={icon2} />
            </div>
            <a href={jobUrl} className="dez-tilte">
              Care Workers{" "}
            </a>
            <p className="m-a0">369 Jobs</p>
            {/* <div className="rotate-icon"><i className="ti-wand"></i></div>  */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="icon-bx-wraper orange">
          <div className="icon-content ">
            <div className="icon-md text-primary m-b20">
              {/* <i className="ti-wallet"></i> */}
              <img alt="Teachers Icon" src={icon3} />
            </div>
            <a href={jobUrl} className="dez-tilte">
              Teachers{" "}
            </a>
            <p className="m-a0">571 Jobs</p>
            {/* <div className="rotate-icon"><i className="ti-wallet"></i></div>  */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="icon-bx-wraper yellow">
          <div className="icon-content ">
            <div className="icon-md text-primary m-b20">
              {/* <i className="ti-cloud-up"></i> */}
              <img alt="Learning Support Assistant Icon" src={icon4} />
            </div>
            <a href={jobUrl} className="dez-tilte">
              Learning Support Assistant{" "}
            </a>
            <p className="m-a0">234 Jobs</p>
            {/*<div className="rotate-icon"><i className="ti-cloud-up"></i></div> */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="icon-bx-wraper red">
          <div className="icon-content ">
            <div className="icon-md text-primary m-b20">
              {/* <i class="fa fa-archive" style={{fontSize: "30px"}} aria-hidden="true"></i> */}

              <img alt="Warehouse Operative Icon" src={icon5} />
            </div>
            <a href={jobUrl} className="dez-tilte">
              Warehouse Operative{" "}
            </a>
            <p className="m-a0">188 Jobs</p>
            {/* <div className="rotate-icon"><i className="ti-bar-chart"></i></div>  */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="icon-bx-wraper pink">
          <div className="icon-content ">
            <div className="icon-md text-primary m-b20 ">
              {/* <i className="ti-tablet"></i> */}
              <img alt="Assembly Operative Icon" src={icon6} />
            </div>
            <a href={jobUrl} className="dez-tilte">
              Assembly Operative
            </a>
            <p className="m-a0">408 Jobs</p>
            {/* <div className="rotate-icon"><i className="ti-tablet"></i></div>  */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="icon-bx-wraper black">
          <div className="icon-content ">
            <div className="icon-md text-primary m-b20">
              {/* <i className="ti-camera"></i> */}
              <img alt="Exam Invigilator Icon" src={icon7} />
            </div>
            <a href={jobUrl} className="dez-tilte">
              Exam Invigilator{" "}
            </a>
            <p className="m-a0">97 Jobs</p>
            {/* <div className="rotate-icon"><i className="ti-camera"></i></div>  */}
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <div className="icon-bx-wraper navyblue">
          <div className="icon-content">
            <div className="icon-md text-primary m-b20">
              {/* <i className="ti-panel"></i> */}
              <img alt="Residential Support Worker Icon" src={icon8} />
            </div>
            <a href={jobUrl} className="dez-tilte">
              Residential Support Worker{" "}
            </a>
            <p className="m-a0">688 Jobs</p>
            {/* <div className="rotate-icon"><i className="ti-panel"></i></div>  */}
          </div>
        </div>
      </div>
      {/* <div className="col-lg-12 text-center m-t30">
				<button className="site-button radius-xl">All Categories</button>
			</div> */}
    </div>
  );
}

export default Jobcategories;
