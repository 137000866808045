const initialState = {workPrefrencesJobTitles: {}}

export const workPrefrencesJobTitles = (state = initialState, action) => {
	switch (action.type) {
  	case 'GET_WORK_PREFRENCES_JOB_TITLES':
      return {workPrefrencesJobTitles: action.payload}; 
  }
  return state
}


