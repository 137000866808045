import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Form, Spinner, Button, Dropdown } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useHistory } from "react-router-dom";
import LoadFormFields from "../../Layout/LoadFormFields";
import UserExperience from "../../../containers/UserExperience";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  formInputFields,
  validationSchema,
  formWorkExperience,
} from "./EducationRefrenceHelper";
import {
  formInputEmployementRefrence,
  validationSchemaEmployment,
  formHaveExperience,
  formHaveMoreThenExperience,
} from "./EmployementRefrenceHelper";
import { formInputProfessionalRefrence } from "./ProfessionalRefrenceHelper";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
const EducationRefrences = (props) => {
  const { loader, userDetails, userExperience } = props;
  const dispatch = useDispatch();
  // Length Start
  const [educationLength, setEducationLength] = useState(null);
  const [employmentLength, setEmploymentLength] = useState(null);
  const [professionalLength, setProfessionalLength] = useState(null);
  // Length Stop
  const [educationReferencedata, setEductionArray] = useState([]);
  const [employementReferencedata, setEmployementArray] = useState([]);
  const [professionalReferencedata, setProfessionalArray] = useState([]);
  const [fiveYearEmployerExperience, setIsFiveYearEmployerExp] = useState("");
  const [isExperienced, setIsExperienced] = useState("");
  const [isSameEmployerExpExperienced, setIsSameEmployerExp] = useState("");
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isExperienced === "yes"
        ? validationSchemaEmployment(fiveYearEmployerExperience)
        : validationSchema
    ),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "educationReference",
  });

  const employementRef = useFieldArray({
    control,
    name: "employementReference",
  });

  const professionalRef = useFieldArray({
    control,
    name: "professionalReference",
  });

  useEffect(() => {
    //console.log("Testing Data");
    let defaultFormData = [
      {
        users_access_token: userDetails && userDetails.users_access_token,
      },
      {
        users_access_token: userDetails && userDetails.users_access_token,
      },
    ];
    if (isExperienced === "no") {
      if (
        formInputFields(userExperience).length > 0 &&
        userExperience.cnd_refer_work_experience
      )
        formInputFields().map((field) => {
          if (userExperience[field.name])
            setValue(field.name, userExperience[field.name]);
        });
      if (
        userExperience.educationReference &&
        userExperience.educationReference.length <= 0
      ) {
        setValue("educationReference", defaultFormData);
        setEductionArray(defaultFormData);
      } else {
        let educationData = userExperience.educationReference
          ? userExperience.educationReference
          : defaultFormData;
        let workRefrence = userExperience.cnd_refer_work_experience
          ? userExperience.cnd_refer_work_experience
          : "";
        setValue("educationReference", educationData);
        if (workRefrence) setValue("cnd_refer_work_experience", workRefrence);
        if (userExperience.educationReference) {
          // setEductionArray(userExperience.educationReference);
          setEductionArray(
            userExperience.educationReference.length > 1
              ? userExperience.educationReference
              : [
                  ...userExperience.educationReference,
                  {
                    users_access_token:
                      userDetails && userDetails.users_access_token,
                  },
                ]
          );
        } else {
          setEductionArray(defaultFormData);
        }
      }
      //Professional Start
      let defForm = [
        /*{
        users_access_token: userDetails && userDetails.users_access_token,
      }*/
      ];
      /*if (userExperience.professionalReference && userExperience.professionalReference.length <= 0) {
        //setValue("professionalReference", defForm);
        //setProfessionalArray(defForm);
      } else {
        let professionalData = userExperience.professionalReference ? userExperience.professionalReference : defaultFormData;
        setValue("professionalReference", professionalData);
        if (userExperience.professionalReference)
          setProfessionalArray(userExperience.professionalReference);
        else
          setProfessionalArray(defForm);
      }*/
      //Professional End
      //console.log(professionalReferencedata);
    } else {
      setEductionArray([]);
    }
  }, [
    userExperience,
    isExperienced,
    isSameEmployerExpExperienced,
    fiveYearEmployerExperience,
  ]);

  useEffect(() => {
    setEmployementArray([]);
    if (isExperienced === "yes") {
      setProfessionalArray([]);
      let defForm = [
        {
          users_access_token: userDetails && userDetails.users_access_token,
        },
      ];

      if (formInputEmployementRefrence(userExperience).length > 0)
        formInputEmployementRefrence().map((field) => {
          if (userExperience[field.name])
            setValue(field.name, userExperience[field.name]);
        });

      if (userExperience.length <= 0) {
        if (fiveYearEmployerExperience == "yes") {
          if (isSameEmployerExpExperienced == "yes") {
            let defaultFormData = [
              {
                users_access_token:
                  userDetails && userDetails.users_access_token,
              },
            ];
            setValue("employementReference", defaultFormData);
            setValue("professionalReference", defaultFormData);
            setEmployementArray(defaultFormData);
            setProfessionalArray(defaultFormData);
          } else if (isSameEmployerExpExperienced == "no") {
            let defaultFormData = [
              {
                users_access_token:
                  userDetails && userDetails.users_access_token,
              },
              {
                users_access_token:
                  userDetails && userDetails.users_access_token,
              },
            ];
            setValue("employementReference", defaultFormData);
            //setValue("professionalReference", defForm);
            setEmployementArray(defaultFormData);
            //setProfessionalArray(defForm);
          } else {
            setValue("employementReference", []);
            setValue("professionalReference", []);
            setEmployementArray([]);
            setProfessionalArray([]);
          }
        } else {
          if (fiveYearEmployerExperience === "no") {
            let defaultFormData = [
              {
                users_access_token:
                  userDetails && userDetails.users_access_token,
              },
              {
                users_access_token:
                  userDetails && userDetails.users_access_token,
              },
            ];
            setValue("employementReference", defaultFormData);
            //setValue("professionalReference", defaultFormData);
            setEmployementArray(defaultFormData);
            //setProfessionalArray(defaultFormData);
          }
        }
      } else {
        if (isSameEmployerExpExperienced == "yes") {
          let defaultFormData = [
            {
              users_access_token: userDetails && userDetails.users_access_token,
            },
          ];
          if (userExperience.employementReference.length <= 0)
            setEmployementArray(defaultFormData);
          else setEmployementArray(userExperience.employementReference);
          if (userExperience.professionalReference.length <= 0)
            setProfessionalArray(defaultFormData);
          else setProfessionalArray(userExperience.professionalReference);
        } else {
          let defaultYesForm = [
            {
              users_access_token: userDetails && userDetails.users_access_token,
            },
          ];
          if (userExperience.employementReference.length <= 0) {
            setEmployementArray(defaultYesForm);
          } else {
            setEmployementArray(
              userExperience.employementReference.length > 1
                ? userExperience.employementReference
                : [
                    ...userExperience.employementReference,
                    {
                      users_access_token: userDetails?.users_access_token, // Add the token if userDetails is available
                    },
                  ]
            );
          }

          /*if (userExperience.professionalReference.length <= 0)
            //setProfessionalArray(defForm);
          else*/
          setProfessionalArray(userExperience.professionalReference);
        }
      }
    } else {
      setEmployementArray([]);
    }
  }, [
    userExperience,
    isExperienced,
    isSameEmployerExpExperienced,
    fiveYearEmployerExperience,
  ]);

  useEffect(() => {
    setValue("employementReference", userExperience.employementReference);
    if (userExperience.professionalReference) {
      setValue("professionalReference", userExperience.professionalReference);
      setProfessionalArray(userExperience.professionalReference);
    }
    // setValue("professionalReference", userExperience.professionalReference);
    setValue(
      "cnd_five_year_of_experience",
      userExperience.cnd_five_year_of_experience
    );
    setValue(
      "cnd_refer_work_experience",
      userExperience.cnd_refer_work_experience
    );
    setValue("refYearsSelect", userExperience.refYearsSelect);
    setIsExperienced(userExperience.cnd_refer_work_experience);
    setEmployementArray(userExperience.employementReference);
    //setProfessionalArray(userExperience.professionalReference);

    setIsFiveYearEmployerExp(userExperience.cnd_five_year_of_experience);
    setIsSameEmployerExp(userExperience.refYearsSelect);
  }, [userExperience]);

  const addMore = () => {
    let loadMoredata = [
      ...educationReferencedata,
      {
        users_access_token: userDetails.users_access_token,
      },
    ];
    setEductionArray(loadMoredata);
  };

  const addMoreEmployement = () => {
    let loadMoredata = [
      ...employementReferencedata,
      {
        users_access_token: userDetails.users_access_token,
      },
    ];
    setEmployementArray(loadMoredata);
  };

  const addMoreProfessional = () => {
    let loadMoredata = [
      ...professionalReferencedata,
      {
        users_access_token: userDetails.users_access_token,
      },
    ];
    setProfessionalArray(loadMoredata);
  };

  const handleRemoveClick = (index) => {
    const list = [...educationReferencedata];
    list.splice(index, 1);
    setEductionArray(list);
    remove(index);
  };

  const handleRemoveEmployementClick = (index) => {
    const list = [...employementReferencedata];
    list.splice(index, 1);
    setEmployementArray(list);
    employementRef.remove(index);
  };

  const handleRemoveProfessionalClick = (index) => {
    const list = [...professionalReferencedata];
    list.splice(index, 1);
    setProfessionalArray(list);
    professionalRef.remove(index);
  };

  const onChangeInputvalue = (e, key) => {
    const { name, value } = e.target;
    if (name === "cnd_refer_work_experience") {
      setValue("cnd_refer_work_experience", value);
      setValue("refYearsSelect", userExperience.refYearsSelect);
      setValue("refYearsSelect", userExperience.refYearsSelect);
      setIsSameEmployerExp(userExperience.refYearsSelect);
      setValue("cnd_five_year_of_experience", "");
      setIsFiveYearEmployerExp("");
      setIsExperienced(value);
    }
    if (name === "cnd_five_year_of_experience") {
      setValue("cnd_five_year_of_experience", value);
      setValue("refYearsSelect", userExperience.refYearsSelect);
      setIsSameEmployerExp(userExperience.refYearsSelect);
      setIsFiveYearEmployerExp(value);
    }
    if (name === "refYearsSelect") {
      setIsSameEmployerExp(value);
      setValue("refYearsSelect", value);
    }
  };

  const onSubmit = (data) => {
    // console.log(data);
    document.getElementById("loader").style.display = "block";
    //Condition Added Start
    if (isExperienced == "yes") {
      data.employementReference.map((x, i) => {
        data.employementReference[i].users_access_token =
          userDetails.users_access_token;
        data.employementReference[i].react_employement_details =
          "react_employement_details";
      });
    }
    //Condition Added Stop

    data.educationReference.map((x, i) => {
      data.educationReference[i].users_access_token =
        userDetails.users_access_token;
      data.educationReference[i].react_education_details =
        "react_education_details";
    });
    data.professionalReference.map((x, i) => {
      data.professionalReference[i].users_access_token =
        userDetails.users_access_token;
      data.professionalReference[i].react_professional_details =
        "react_professional_details";
    });
    let formData = data;
    //console.warn("Check Condition :=>"+ userExperience.cnd_employ_declaration);
    if (userExperience.cnd_reference_id)
      formData["cnd_reference_id"] = userExperience.cnd_reference_id;
    formData["cnd_employ_declaration"] = "Checked";
    formData["users_category"] = userDetails.users_category;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "UpdateEmployementData",
        "UPDATE_EMPLOYEMENT_DATA",
        getDataOnRequest(
          userDetails.users_access_token,
          "UserExperience",
          "SET_USER_EXPERIENCE"
        )
      )
    );
    //window.location.href = "/agreements"
    //history.push("/agreements");
  };

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="job-bx submit-resume">
          <div className="job-bx-title clearfix">
            <h5 className="font-weight-700 pull-left text-uppercase">
              Employment and Professional History (References)
            </h5>
            {/* <Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link> */}
          </div>
          <div className="container ">
            <div className="row text-left">
              <div
                class="alert alert-warning alert-dismissible fade show clearfix"
                role="alert"
                id="scrollFocusElement"
              >
                <i
                  className="fa fa-warning text-warning"
                  aria-hidden="true"
                ></i>{" "}
                <strong>
                  We require at least <b>2 references </b>–
                  <ul>
                    <li>
                      For the candidates with work experience -one from your
                      last employer and another professional reference within
                      the last <b>5 years</b>.
                    </li>
                    <li>
                      For the candidates with no work experience – both
                      references from your tutors from last school/college you
                      attended.
                    </li>
                  </ul>
                  <p>
                    We may also ask to provide other referees if the above given
                    are not adequate. Please start with your most recent
                    employer/institution. Any gaps in employment including
                    education must be explained in the space provided.
                    Information you provide us is subject to verification and
                    checking.
                  </p>
                </strong>
              </div>
            </div>
          </div>
          <LoadFormFields
            register={register}
            control={control}
            errors={errors && errors}
            formInputFields={formWorkExperience()}
            onChangeInputvalue={(e) => onChangeInputvalue(e)}
            addMoreButton={true}
            data={educationReferencedata}
            loadExternalButtons={true}
          />
          {isExperienced === "yes" && (
            <LoadFormFields
              register={register}
              control={control}
              errors={errors && errors}
              formInputFields={formHaveExperience()}
              onChangeInputvalue={(e) => onChangeInputvalue(e)}
              addMoreButton={true}
              data={employementReferencedata}
              loadExternalButtons={true}
            />
          )}
          {fiveYearEmployerExperience === "yes" && (
            <LoadFormFields
              register={register}
              control={control}
              errors={errors && errors}
              formInputFields={formHaveMoreThenExperience()}
              onChangeInputvalue={(e) => onChangeInputvalue(e)}
              addMoreButton={true}
              data={employementReferencedata}
              loadExternalButtons={true}
            />
          )}
          {isExperienced && educationReferencedata.length > 0 && (
            <div className="job-bx-title clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                Education References
              </h5>
            </div>
          )}
          {
            <>
              {educationReferencedata.map((eductionData, i) => (
                <React.Fragment key={i}>
                  {educationReferencedata.length > 1 &&
                    i > 0 &&
                    professionalReferencedata.length > 0 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveClick(i)}
                      ></i>
                    )}
                  {educationReferencedata.length > 2 &&
                    i > 0 &&
                    professionalReferencedata.length == 0 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveClick(i)}
                      ></i>
                    )}
                  <h6>
                    {educationReferencedata.length > 1
                      ? "Education Reference - " + (i + 1)
                      : "Education Reference"}
                  </h6>
                  <input
                    name={`educationReference.${i}.users_access_token`}
                    value={userDetails.users_access_token}
                    type="hidden"
                  />
                  <LoadFormFields
                    key={i}
                    register={register}
                    control={control}
                    errors={
                      errors.educationReference && errors.educationReference[i]
                    }
                    formInputFields={formInputFields(i)}
                    onChangeInputvalue={(e) => onChangeInputvalue(e)}
                    reset={reset}
                    addMoreButton={true}
                    arrayForm={true}
                    addMore={addMore}
                    data={eductionData}
                    loadExternalButtons={true}
                  />
                  <hr />
                </React.Fragment>
              ))}
              {educationReferencedata.length > 0 && (
                <Button className="m-l5 btn-info m-b30" onClick={addMore}>
                  Add More Education
                </Button>
              )}
            </>
          }
          {fiveYearEmployerExperience &&
            employementReferencedata.length > 0 && (
              <div className="job-bx-title clearfix">
                <h5 className="font-weight-700 pull-left text-uppercase">
                  Employment References
                </h5>
              </div>
            )}
          {fiveYearEmployerExperience && (
            <>
              {employementReferencedata.map((employement, i) => (
                <React.Fragment key={i}>
                  {employementReferencedata.length > 1 &&
                    i >
                      0 /*&& ((isSameEmployerExpExperienced == 'yes') ? i > 1 : i > 1) */ &&
                    professionalReferencedata.length > 0 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveEmployementClick(i)}
                      ></i>
                    )}

                  {employementReferencedata.length > 2 &&
                    i >
                      0 /*&& ((isSameEmployerExpExperienced == 'yes') ? i > 1 : i > 1) */ &&
                    professionalReferencedata.length == 0 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveEmployementClick(i)}
                      ></i>
                    )}
                  <h6>
                    {employementReferencedata.length > 1
                      ? "Employment Reference - " + (i + 1)
                      : "Employment Reference"}
                  </h6>
                  <input
                    name={`employementReference.${i}.users_access_token`}
                    value={userDetails.users_access_token}
                    type="hidden"
                  />
                  <LoadFormFields
                    key={i}
                    register={register}
                    control={control}
                    errors={
                      errors.employementReference &&
                      errors.employementReference[i]
                    }
                    formInputFields={formInputEmployementRefrence(i)}
                    onChangeInputvalue={(e) => onChangeInputvalue(e)}
                    reset={reset}
                    addMoreButton={true}
                    arrayForm={true}
                    addMore={addMoreEmployement}
                    data={employement}
                    loadExternalButtons={true}
                  />
                  <hr />
                </React.Fragment>
              ))}
              {employementReferencedata.length > 0 && (
                <Button
                  className="m-l5 btn-info m-b30"
                  onClick={addMoreEmployement}
                >
                  Add More Employment
                </Button>
              )}
            </>
          )}
          {isExperienced && professionalReferencedata.length > 0 && (
            <div className="job-bx-title clearfix">
              <h5 className="font-weight-700 pull-left text-uppercase">
                Professional References
              </h5>
            </div>
          )}
          {isExperienced && (
            <>
              {professionalReferencedata.map((professional, i) => (
                <React.Fragment key={i}>
                  {/* {professionalReferencedata.length > 1 && (isSameEmployerExpExperienced =="yes" && i > 0) && (
                    <i className="pull-right fa fa-trash red-icon" onClick={(e) => handleRemoveProfessionalClick(i)}></i>
                  )} */}

                  {professionalReferencedata.length > 0 &&
                    isSameEmployerExpExperienced != "yes" &&
                    educationReferencedata.length > 1 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveProfessionalClick(i)}
                      ></i>
                    )}

                  {professionalReferencedata.length > 1 &&
                    isSameEmployerExpExperienced != "yes" &&
                    educationReferencedata.length == 1 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveProfessionalClick(i)}
                      ></i>
                    )}

                  {professionalReferencedata.length > 0 &&
                    isSameEmployerExpExperienced != "yes" &&
                    employementReferencedata.length > 1 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveProfessionalClick(i)}
                      ></i>
                    )}

                  {professionalReferencedata.length > 1 &&
                    isSameEmployerExpExperienced != "yes" &&
                    employementReferencedata.length == 1 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveProfessionalClick(i)}
                      ></i>
                    )}

                  {professionalReferencedata.length > 0 &&
                    isSameEmployerExpExperienced == "yes" &&
                    employementReferencedata.length > 1 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveProfessionalClick(i)}
                      ></i>
                    )}

                  {professionalReferencedata.length > 1 &&
                    isSameEmployerExpExperienced == "yes" &&
                    employementReferencedata.length == 1 && (
                      <i
                        className="pull-right fa fa-trash red-icon"
                        onClick={(e) => handleRemoveProfessionalClick(i)}
                      ></i>
                    )}
                  <input
                    name={`professionalReference.${i}.users_access_token`}
                    value={userDetails.users_access_token}
                    type="hidden"
                  />
                  <h6>
                    {professionalReferencedata.length > 1
                      ? "Professional Reference - " + (i + 1)
                      : "Professional Reference"}
                  </h6>
                  <LoadFormFields
                    key={i}
                    register={register}
                    control={control}
                    errors={
                      errors.professionalReference &&
                      errors.professionalReference[i]
                    }
                    formInputFields={formInputProfessionalRefrence(i)}
                    onChangeInputvalue={(e) => onChangeInputvalue(e)}
                    reset={reset}
                    addMoreButton={true}
                    arrayForm={true}
                    addMore={addMoreProfessional}
                    data={professional}
                    loadExternalButtons={true}
                  />

                  <hr />
                </React.Fragment>
              ))}

              {professionalReferencedata.length > 0 && (
                <Button
                  className="m-l5 btn-info m-b30"
                  onClick={addMoreProfessional}
                >
                  Add More Professional
                </Button>
              )}
              {
                /*For No Condition & Edu Ref */
                isExperienced == "no" &&
                  professionalReferencedata.length == 0 && (
                    <Button
                      className="m-l5 btn-info m-b30"
                      onClick={addMoreProfessional}
                    >
                      {professionalReferencedata.length == 0 &&
                        "Add Professional Reference"}
                      {professionalReferencedata.length > 1 &&
                        "Add More Professional"}
                    </Button>
                  )
              }
              {
                /*For YES->YES->NO Condition */
                isExperienced == "yes" &&
                  fiveYearEmployerExperience == "yes" &&
                  professionalReferencedata.length == 0 && (
                    <Button
                      className="m-l5 btn-info m-b30"
                      onClick={addMoreProfessional}
                    >
                      {professionalReferencedata.length == 0 &&
                        "Add Professional Reference"}
                      {professionalReferencedata.length > 1 &&
                        "Add More Professional"}
                    </Button>
                  )
              }
              {
                /*For YES->NO Condition */
                isExperienced == "yes" &&
                  fiveYearEmployerExperience == "no" &&
                  professionalReferencedata.length == 0 && (
                    <Button
                      className="m-l5 btn-info m-b30"
                      onClick={addMoreProfessional}
                    >
                      {professionalReferencedata.length == 0 &&
                        "Add Professional Reference"}
                      {professionalReferencedata.length > 1 &&
                        "Add More Professional"}
                    </Button>
                  )
              }
            </>
          )}
          <div className="container text-primary">
            <div className="row">
              <div className="col-sm-12">
                <p className="text-left">
                  I authorise Staffing Connect (Care Education Ltd) of Suite 14,
                  Neals Corner, 2 Bath Road, Hounslow, TW3 3HJ to approach the
                  individuals and organisations listed on the application form
                  for verification of my education, employment and unemployment
                  record and to obtain any other information which may be
                  pertinent to my application for employment with:
                </p>

                <p>
                  <h6>
                    Name of Organisation: Staffing Connect (Care Education Ltd)
                  </h6>
                </p>

                <p className="text-left">
                  I further authorise Staffing Connect (Care Education Ltd) to
                  transfer its findings together with other information obtained
                  to the organisation listed on application form and understand
                  that it will only be used for the purposes of this application
                  for employment: Personal Referees, Character Referees,
                  Employers, Professional advisors, Educational Establishments,
                  The department of works and pensions/job centres, The Inland
                  Revenue, The Criminal Records Bureau, Disclosure Barring
                  Service, Professional Bodies, Credit Reference Agencies,
                  Companies House.
                </p>

                <p className="text-left">
                  I also authorise Staffing Connect (Care Education Ltd) to
                  approach my current employer for verification of my most
                  recent employment.
                </p>

                <p className="ull-right">
                  <h6>
                    {userExperience.cnd_employ_declaration && (
                      <input
                        name="cnd_employ_declaration"
                        type={"checkbox"}
                        className=" mr-2"
                        checked
                        style={{ height: "20px", width: "20px" }}
                        required
                      />
                    )}

                    {!userExperience.cnd_employ_declaration && (
                      <input
                        name="cnd_employ_declaration"
                        type={"checkbox"}
                        className=" mr-2"
                        //checked
                        style={{ height: "20px", width: "20px" }}
                        required
                      />
                    )}
                    <b style={{ marginTop: "-1%" }} className="mb--5">
                      I Agree
                    </b>
                  </h6>
                </p>
              </div>
            </div>
          </div>
          <Form.Group as={Col} md="12" sm="12">
            <Button
              disabled={loader ? "disabled" : ""}
              className="site-button m-b30 pull-right"
              type="submit"
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Save"}
            </Button>
            <button
              disabled={loader ? "disabled" : ""}
              className="site-button yellow m-b30 m-l5 mr-2 pull-right"
              onClick={() => reset()}
            >
              {loader && (
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              )}
              {!loader && "Reset"}
            </button>
          </Form.Group>
          <br /> <br />
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userExperience: state.userExperience.userData,
    userAuth: state.auth.authUserData,
    userDetails: state.auth.authUserData,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps, null)(EducationRefrences);
