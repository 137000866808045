import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_care_kin_2_fname",
    type: "text",
    label: "Kin First Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_kin_2_lname",
    type: "text",
    label: "Kin Last Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_kin_2_relationship",
    type: "text",
    label: "Kin Relationship",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_kin_2_postcode",
    type: "text",
    label: "Kin Postal Code",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_kin_2_phone_no",
    type: "text",
    label: "Kin Phone Number",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_kin_2_mobile_no",
    type: "text",
    label: "Kin Mobile Number",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_kin_2_email",
    type: "text",
    label: "Kin Email",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_care_kin_2_work",
    type: "text",
    label: "Kin Work",
    md: "6",
    sm: "12",
  },
];

function formatDate() {
  return new Date().toLocaleDateString();
}

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  clt_care_kin_2_fname: Yup.string().required("Kin First Name Field Required"),
  clt_care_kin_2_lname: Yup.string().required("Kin Last Name Field Required"),
  clt_care_kin_2_relationship: Yup.string().required(
    "Kin Relationship Field Required"
  ),
  clt_care_kin_2_postcode: Yup.string().required(
    "Kin Postalcode Field Required"
  ),
  clt_care_kin_2_email: Yup.string()
    .required("Kin Email Field Required")
    .email("Please Enter Valid Email"),
  clt_care_kin_2_work: Yup.string().required("Kin Work Field Required"),
  clt_care_kin_2_phone_no: Yup.string()
    .required("Kin Phone Number Field Required")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Telephone Number"
    ),
  clt_care_kin_2_mobile_no: Yup.string()
    .required("Kin Mobile Number Field Required")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Mobile Number"
    ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
