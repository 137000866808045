import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
const IndividualRiskContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const { userAuth, individual } = props;
    const dispatch = useDispatch();
    useEffect(()=>{
      if(userAuth.users_access_token && Object.keys(individual).length == 0){
        dispatch(getDataOnRequest('','GetIndividualRiskProfileMaster','SET_INDIVIDUAL_RISK_MASTER'))
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetGetCarePlans','SET_CARE_PLAN'))
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetIndividualRisk','SET_INDIVIDUAL_RISK'))
      }
    },[userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    individual:state.individual.individual
  };
};

const composedIndividualRiskContainer = compose(connect(mapStateToProps), IndividualRiskContainer)
export default composedIndividualRiskContainer;

