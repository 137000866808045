const initialState = {holidays: []}

export const holidays = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_HOLIDAYS':
      return {holidays: action.payload}; 
  }
  return state
}


