import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import swal from "sweetalert";
let checkRed = "0",
  urlRed;
const Profilesidebar = (props) => {
  const { progress, userData, dbsDocuments } = props;

  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    users_phonenumber,
    users_email,
    users_category,
    users_access_token,
    users_profile_pic,
    job_type,
    users_profile_img_path,
    user_sign_signature,
    cnd_status,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "140px";
      imageRef.current.style.height = "145px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };

  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
  }, [props.userData]);

  // if(job_type == "permanentrequirement"){ //This Condition for Permanent Job Requirement Candidates
  //   window.location.href = "/applied-jobs";

  // }else if(user_sign_signature == ""){
  //     swal({
  //       //checkProf:"true",
  //       title:"Please, complete your profile",
  //       icon:'warning',
  //       text:"\n",
  //       text:"Hey "+ users_firstname + ", you have to complete 100% of your profile and sign the contract to proceed further!",
  //       timer:"2000",
  //       dangerMode: true,
  //       buttons:{
  //         Ok:{
  //           checkProf:true
  //         }
  //       },
  //       //defeat:true,
  //       //buttons:false,
  //       closeOnClickOutside:true
  //     }).then((checkProf) => {
  //       if(progress.progress_bar_profile_1 != "1"){
  //           checkRed = "1";
  //           urlRed = "/job-profile";
  //       }else if(progress.progress_bar_reference_2 != "1"){
  //           checkRed = "1";
  //           urlRed = "/emplyement-references";
  //       }else if(users_category == "home care" && userData.cnd_types_of_services == "" ){
  //           checkRed = "1";
  //           urlRed = "/type-of-services";
  //       }else if(progress.progress_bar_bank_4 != "1"){
  //           checkRed = "1";
  //           urlRed = "/bank-details";
  //       }else if(progress.progress_bar_work_preference_6 != "1"){
  //         checkRed = "1";
  //         urlRed = "/work-prefrences";
  //       }else if(progress.progress_bar_right_to_work_5 != "1"){
  //           checkRed = "1";
  //           urlRed = "/create-right-to-work";
  //       }else if(progress.progress_bar_compliance_7 != "1"){
  //           checkRed = "1";
  //           urlRed = "/document-required";
  //       }else if(progress.progress_bar_dbs_8 != "1"){
  //           checkRed = "1";
  //           urlRed = "/create-dbs-document";
  //       }else if(progress.progress_bar_identity_9 != "1" ){
  //           checkRed = "1";
  //           urlRed = "/create-identity-document";
  //       }else if(progress.progress_bar_agreement_3 != "1"){
  //           checkRed = "1";
  //           urlRed = "/agreements";
  //       }else{
  //           checkRed = "1";
  //           urlRed = "/sign-contract";
  //       }
  //         if(checkRed == "1"){
  //           window.location.href = urlRed;
  //         }

  //     });

  //   }else if(users_firstname && cnd_status != "Approved"){
  //     swal({
  //       //checkProf:"true",
  //       title:"Please, wait for Admin approval !",
  //       icon:'warning',
  //       text:"\n",
  //       text:"Hey "+ users_firstname + ", Your application is pending with Admin for final approval.... Please stay tuned !",
  //       timer:"2000",
  //       dangerMode: true,
  //       buttons:{
  //         Ok:{
  //           checkProf:true
  //         }
  //       },
  //       //defeat:true,
  //       //buttons:false,
  //       closeOnClickOutside:true
  //     }).then((checkProf) => {
  //       window.location.href = "/job-profile";
  //     })

  //   }else{
  // sticky-top
  return (
    <div className="col-xl-3 col-lg-4 m-b30">
      <div className="">
        <div className="candidate-info">
          <div className="candidate-detail text-center">
            <div className="canditate-des">
              <Link to={""}>
                <img
                  width={"136px"}
                  height={"136px"}
                  ref={imageRef}
                  src={require("./../../images/team/pic1.jpg")}
                />
              </Link>
              <div
                className="upload-link border"
                title="update"
                data-toggle="tooltip"
                data-placement="right"
                style={{ marginTop: "50%" }}
              >
                <input
                  type="file"
                  className="update-flie"
                  onChange={(e) => onChangeInputvalue(e)}
                  accept=".png,.jpeg,.jpg"
                />
                <i className="fa fa-camera"></i>
              </div>
            </div>
            <div className="candidate-title">
              <div className="">
                <h4 className="m-b5">
                  <Link to={"#"}>
                    {users_firstname} {users_lastname}
                    <br />
                  </Link>
                </h4>
                <p className="m-b0">
                  <Link to={"#"}>{props.userAuth.users_category}</Link>
                </p>
                <p className="m-b0">
                  <Link to={"#"}>{users_phonenumber}</Link>
                </p>
                <p className="m-b0">
                  <Link to={"#"}>{users_email}</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// }

/*const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData
  };
};*/

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(Profilesidebar);
