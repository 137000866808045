import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Form, Col, Spinner, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Document, Page, pdfjs } from "react-pdf";
import { yupResolver } from "@hookform/resolvers/yup";
import Label from "../../Layout/Label";
import { formInputFields, validationSchema } from "./FormHelper";
import { updateDataOnRequest } from "../../../redux/actions/CommonActions";
import LoadFormFields from "../../Layout/LoadFormFields";
import pdfTemp from "../../../docs/TOB-temp.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const TobForm = (props) => {
  const [company, setFormdata] = useState({});
  const { loader, jobs, userDetails } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFormdata(jobs);
    if (Object.keys(jobs).length > 0)
      Object.keys(jobs).map((key) => {
        setValue(key, jobs[key]);
      });
  }, [jobs]);

  const onSubmit = (data) => {
    let formData = data;
    formData["users_access_token"] = userDetails.users_access_token;
    dispatch(
      updateDataOnRequest(JSON.stringify(formData, null, 2), "UpdateJobs", "")
    );
  };

  const onChangeInputvalue = (e) => {
    const { name, value } = e.target;
    company[name] = value;
    setFormdata(company);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Document
          height="300"
          className="custom-canvas"
          file={pdfTemp}
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page height="300" width="800" pageNumber={pageNumber} />
        </Document>
        {pageNumber > 1 && (
          <Button
            className="m-l5 btn-info m-b30 m-t30"
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            Previous Page
          </Button>
        )}
        {pageNumber < numPages && (
          <Button
            className="m-l5 btn-info m-b30 float-right m-t30"
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Next Page >>
          </Button>
        )}
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <hr />
        <LoadFormFields
          register={register}
          errors={errors}
          formInputFields={formInputFields()}
          onChangeInputvalue={(e) => onChangeInputvalue(e)}
          reset={reset}
          control={control}
          data={company}
          buttonDisabled={numPages == pageNumber ? false : true}
        />
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    jobs: state.jobs.jobs,
  };
};
export default connect(mapStateToProps)(TobForm);
