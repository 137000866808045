import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
const AssesmentContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const { userAuth, assesmentShifts } = props;
    const dispatch = useDispatch();
    useEffect(()=>{
      if(userAuth.users_access_token && Object.keys(assesmentShifts).length == 0)
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetShiftData','SET_ASSESMENT'))
    },[userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    assesmentShifts:state.assesmentShifts.assesmentShifts
  };
};

const composedAssesmentContainer = compose(connect(mapStateToProps), AssesmentContainer)
export default composedAssesmentContainer;

