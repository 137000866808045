import constants from "../constants";
/**
 * Axios Request Wrapper
 */

import axios from "axios";
import { useDispatch } from "react-redux";
import {getDataOnRequest} from '../redux/actions/CommonActions'
/**
 * Create an Axios Client
 */
const client = axios.create({
  baseURL: constants.API_BASE_URL,
  mode: 'no-cors',
  //headers: {'accept': '*'},
  headers: {
    'accept': 'application/x-www-form-urlencoded',
    'content-type': 'multipart/form-data'
  }
});

client.interceptors.request.use((config) => {
  return config;
});

const request = (options) => {
  const onSuccess = (response) => {
    //debugger
    return response;
  };
  const onError = (error) => {
    console.error(error);

    Promise.reject(error.message);
  };
  return client(options).then(onSuccess).catch(onError);
};

export default request;
