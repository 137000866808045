import React, { useState, useEffect, useRef } from "react";
import Skeleton from "@mui/material/Skeleton";
export const printSkeleton = (
  column = "",
  countTime = "",
  styleBorderRadius = "",
  styleBackgroundColor = "",
  variant = "",
  width = "",
  height = "",
  className = ""
) => {
  return (
    <>
      <>
        {Array.from({ length: countTime ? countTime : 1 }).map((_, index) => (
          <div className={"col-sm-" + (column ? column : "12")} key={index}>
            <Skeleton
              style={{
                borderRadius: styleBorderRadius ? styleBorderRadius : "20px",
                backgroundColor: styleBackgroundColor
                  ? styleBackgroundColor
                  : "#e6d5ff",
              }}
              variant={variant ? variant : "rounded"}
              className={className ? className : "bg-default mb-2"}
              width={width ? width : "100%"}
              height={height ? height : 40}
            />
          </div>
        ))}
      </>
    </>
  );
};
