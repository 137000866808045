import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { getBase64 } from "../../../helpers/FileToBase64";
import { formInputFields, validationSchema } from "./IdentityDocumentHelper";
import constants from "../../../constants";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import LoadFormFields from "../../Layout/LoadFormFields";
import { IdentityDocumentNewList } from "../../../helpers/DocumentsList";
import Label from "../../Layout/Label";
import Dropdown from "../../FormElements/Dropdown";
import Input from "../../FormElements/Input";
import Textarea from "../../FormElements/Textarea";
import { Button } from "react-bootstrap";

const IdentityDocument = (props) => {
  const [identityData, setFormdata] = useState({});
  // const [identityData, setFormdata] = useState({});
  const { loader, userDetails, identityDocument } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    validateOnBlur,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    // window.scrollTo(0, 0);
    setFormdata(identityDocument);
    if (formInputFields(identityDocument).length > 0)
      formInputFields().map((field, key) => {
        if (identityDocument[field.name])
          setValue(field.name, identityDocument[field.name]);
      });
  }, [params.id, identityDocument]);

  const onSubmit = (e) => {
    e.preventDefault();
    let finalValid = false;
    let cnd_identity_doc_master_id = document.getElementById(
      "cnd_identity_doc_master_id"
    );
    let cnd_identity_doc_master_id_msg = document.getElementById(
      "cnd_identity_doc_master_id_msg"
    );
    let cnd_identity_doc_upload_name = document.getElementById(
      "cnd_identity_doc_upload_name"
    );
    let cnd_identity_doc_upload_name_msg = document.getElementById(
      "cnd_identity_doc_upload_name_msg"
    );
    let cnd_identity_doc_exp_date = document.getElementById(
      "cnd_identity_doc_exp_date"
    );
    let cnd_identity_doc_exp_date_msg = document.getElementById(
      "cnd_identity_doc_exp_date_msg"
    );
    let cnd_identity_doc_reference = document.getElementById(
      "cnd_identity_doc_reference"
    );
    let cnd_identity_doc_reference_msg = document.getElementById(
      "cnd_identity_doc_reference_msg"
    );
    let cnd_identity_doc_description = document.getElementById(
      "cnd_identity_doc_description"
    );
    let cnd_identity_doc_description_msg = document.getElementById(
      "cnd_identity_doc_description_msg"
    );

    if (!cnd_identity_doc_master_id.value) {
      cnd_identity_doc_master_id.classList.add("border-danger");
      cnd_identity_doc_master_id_msg.innerText =
        "Please select the document type";
      finalValid = true;
    } else {
      cnd_identity_doc_master_id.classList.remove("border-danger");
      cnd_identity_doc_master_id_msg.innerText = "";
    }

    if (
      !cnd_identity_doc_upload_name.value &&
      !identityData.cnd_identity_doc_upload_name
    ) {
      cnd_identity_doc_upload_name.classList.add("border-danger");
      cnd_identity_doc_upload_name_msg.innerText = "Please select the document";
      finalValid = true;
    } else {
      cnd_identity_doc_upload_name.classList.remove("border-danger");
      cnd_identity_doc_upload_name_msg.innerText = "";
    }

    let expDate = new Date(String(cnd_identity_doc_exp_date.value));
    let currentDate = new Date(new Date().toISOString().split("T")[0]);
    if (!cnd_identity_doc_exp_date.value) {
      cnd_identity_doc_exp_date.classList.add("border-danger");
      cnd_identity_doc_exp_date_msg.innerText = "Please select the expiry date";
      finalValid = true;
    } else if (
      cnd_identity_doc_exp_date.value &&
      !String(cnd_identity_doc_exp_date.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      cnd_identity_doc_exp_date.classList.add("border-danger");
      cnd_identity_doc_exp_date_msg.innerText =
        "Please select the date in dd-mm-yyyy format";
      finalValid = true;
    } else if (cnd_identity_doc_exp_date.value && expDate < currentDate) {
      cnd_identity_doc_exp_date.classList.add("border-danger");
      cnd_identity_doc_exp_date_msg.innerText =
        "Please select the current or after date";
      finalValid = true;
    } else {
      cnd_identity_doc_exp_date.classList.remove("border-danger");
      cnd_identity_doc_exp_date_msg.innerText = "";
    }

    if (
      !cnd_identity_doc_reference.value ||
      cnd_identity_doc_reference.value.trim() == ""
    ) {
      cnd_identity_doc_reference.classList.add("border-danger");
      cnd_identity_doc_reference_msg.innerText =
        "Please enter the document reference number";
      finalValid = true;
    } else if (
      cnd_identity_doc_reference.value &&
      !cnd_identity_doc_reference.value.match(/^[0-9a-zA-Z \b]+$/)
    ) {
      cnd_identity_doc_reference.classList.add("border-danger");
      cnd_identity_doc_reference_msg.innerText =
        "Special character not allowed";
      finalValid = true;
    } else {
      cnd_identity_doc_reference.classList.remove("border-danger");
      cnd_identity_doc_reference_msg.innerText = "";
    }

    if (
      cnd_identity_doc_description.value &&
      cnd_identity_doc_description.value.trim() == ""
    ) {
      cnd_identity_doc_description.classList.add("border-danger");
      cnd_identity_doc_description_msg.innerText =
        "Please enter the valid description";
      finalValid = true;
    } else {
      cnd_identity_doc_description.classList.remove("border-danger");
      cnd_identity_doc_description_msg.innerText = "";
    }
    let allData = identityData;
    // console.log("All Data Are :=>"+JSON.stringify(identityData));

    // console.log("File Is :=>"+allData['cnd_identity_doc_upload_name']);
    allData["users_access_token"] = userDetails.users_access_token;
    if (identityDocument.cnd_identity_doc_id)
      allData["cnd_identity_doc_id"] = identityDocument.cnd_identity_doc_id;

    if (finalValid == false) {
      document.getElementById("loader").style.display = "block";
      dispatch(
        updateDataOnRequest(
          JSON.stringify(allData, null, 2),
          "updateIdentityDoc",
          "",
          getDataOnRequest(
            userDetails.users_access_token,
            "SetIdentityDocuments",
            "SET_IDENTITY_DOCUMENTS"
          )
        )
      );
    }

    dispatch(
      getDataOnRequest(
        props.userDetails.users_access_token,
        "ProgressBar",
        "SET_PROGRESS"
      )
    );
    // history.goBack();
    ///history.push('/identity-document');
  };

  const onChangeInputvalue = async (e) => {
    // e.preventDefault();

    if (e.target.name === "cnd_identity_doc_upload_name") {
      if (e.target.files[0])
        // identityData["cnd_identity_doc_upload_name"] = await getBase64(e.target.files[0]);
        setFormdata({
          ...identityData,
          [e.target.name]: await getBase64(e.target.files[0]),
        });
    } else {
      setFormdata({
        ...identityData,
        [e.target.name]: e.target.value,
      });
    }

    let finalValid = false;
    let cnd_identity_doc_master_id = document.getElementById(
      "cnd_identity_doc_master_id"
    );
    let cnd_identity_doc_master_id_msg = document.getElementById(
      "cnd_identity_doc_master_id_msg"
    );
    let cnd_identity_doc_upload_name = document.getElementById(
      "cnd_identity_doc_upload_name"
    );
    let cnd_identity_doc_upload_name_msg = document.getElementById(
      "cnd_identity_doc_upload_name_msg"
    );
    let cnd_identity_doc_exp_date = document.getElementById(
      "cnd_identity_doc_exp_date"
    );
    let cnd_identity_doc_exp_date_msg = document.getElementById(
      "cnd_identity_doc_exp_date_msg"
    );
    let cnd_identity_doc_reference = document.getElementById(
      "cnd_identity_doc_reference"
    );
    let cnd_identity_doc_reference_msg = document.getElementById(
      "cnd_identity_doc_reference_msg"
    );
    let cnd_identity_doc_description = document.getElementById(
      "cnd_identity_doc_description"
    );
    let cnd_identity_doc_description_msg = document.getElementById(
      "cnd_identity_doc_description_msg"
    );

    if (!cnd_identity_doc_master_id.value) {
      cnd_identity_doc_master_id.classList.add("border-danger");
      cnd_identity_doc_master_id_msg.innerText =
        "Please select the document type";
      // finalValid = true;
    } else {
      cnd_identity_doc_master_id.classList.remove("border-danger");
      cnd_identity_doc_master_id_msg.innerText = "";
    }

    if (
      !cnd_identity_doc_upload_name.value &&
      !identityData.cnd_identity_doc_upload_name
    ) {
      cnd_identity_doc_upload_name.classList.add("border-danger");
      cnd_identity_doc_upload_name_msg.innerText = "Please select the document";
      // finalValid = true;
    } else {
      cnd_identity_doc_upload_name.classList.remove("border-danger");
      cnd_identity_doc_upload_name_msg.innerText = "";
    }

    let expDate = new Date(String(cnd_identity_doc_exp_date.value));
    let currentDate = new Date(new Date().toISOString().split("T")[0]);
    if (!cnd_identity_doc_exp_date.value) {
      cnd_identity_doc_exp_date.classList.add("border-danger");
      cnd_identity_doc_exp_date_msg.innerText = "Please select the expiry date";
      // finalValid = true;
    } else if (
      cnd_identity_doc_exp_date.value &&
      !String(cnd_identity_doc_exp_date.value).match(
        /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
      )
    ) {
      cnd_identity_doc_exp_date.classList.add("border-danger");
      cnd_identity_doc_exp_date_msg.innerText =
        "Please select the date in dd-mm-yyyy format";
      // finalValid = true;
    } else if (cnd_identity_doc_exp_date.value && expDate < currentDate) {
      cnd_identity_doc_exp_date.classList.add("border-danger");
      cnd_identity_doc_exp_date_msg.innerText =
        "Please select the current or after date";
      // finalValid = true;
    } else {
      cnd_identity_doc_exp_date.classList.remove("border-danger");
      cnd_identity_doc_exp_date_msg.innerText = "";
    }

    if (
      !cnd_identity_doc_reference.value ||
      cnd_identity_doc_reference.value.trim() == ""
    ) {
      cnd_identity_doc_reference.classList.add("border-danger");
      cnd_identity_doc_reference_msg.innerText =
        "Please enter the document reference number";
      // finalValid = true;
    } else if (
      cnd_identity_doc_reference.value &&
      !cnd_identity_doc_reference.value.match(/^[0-9a-zA-Z \b]+$/)
    ) {
      cnd_identity_doc_reference.classList.add("border-danger");
      cnd_identity_doc_reference_msg.innerText =
        "Special character not allowed";
      // finalValid = true;
    } else {
      cnd_identity_doc_reference.classList.remove("border-danger");
      cnd_identity_doc_reference_msg.innerText = "";
    }

    if (
      cnd_identity_doc_description.value &&
      cnd_identity_doc_description.value.trim() == ""
    ) {
      cnd_identity_doc_description.classList.add("border-danger");
      cnd_identity_doc_description_msg.innerText =
        "Please enter the valid description";
      // finalValid = true;
    } else {
      cnd_identity_doc_description.classList.remove("border-danger");
      cnd_identity_doc_description_msg.innerText = "";
    }

    // console.log("Set Format Data :=> "+JSON.stringify(identityData));

    // console.log("Data Are  :=>" + JSON.stringify(identityData));
  };

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Identity Document
          </h5>
        </div>
        <form noValidate onSubmit={onSubmit}>
          {/* <LoadFormFields
            register={register}
            errors={errors}
            formInputFields={formInputFields()}
            onChangeInputvalue={(e) => onChangeInputvalue(e)}
            reset={reset}
            data={identityData}
            documentUrl={constants.IMAGE_URL +
              identityDocument.cnd_identity_doc_folder_path +
              identityDocument.cnd_identity_doc_upload_name}
            backButton={true}
            backUrl={"/identity-document"}
          /> */}
          {/* <div className="container"> */}
          <div className="row">
            <div className="col-sm-6">
              <Dropdown
                label="Select Documents"
                value={identityData.cnd_identity_doc_master_id}
                // disabled={identityData.cnd_identity_doc_master_id ? true : false}
                options={IdentityDocumentNewList}
                name="cnd_identity_doc_master_id"
                id="cnd_identity_doc_master_id"
                changeHandler={(e) => onChangeInputvalue(e)}
                lg={12}
              />
            </div>
            <div className="col-sm-6">
              <Input
                label="Upload File"
                name="cnd_identity_doc_upload_name"
                id="cnd_identity_doc_upload_name"
                type="file"
                fileUrl={
                  identityData.cnd_identity_doc_folder_path &&
                  identityData.cnd_identity_doc_upload_name
                    ? constants.IMAGE_URL +
                      identityData.cnd_identity_doc_folder_path +
                      identityData.cnd_identity_doc_upload_name
                    : ""
                }
                changeHandler={(e) => onChangeInputvalue(e)}
                className="form-control"
                lg={12}
              />
            </div>
            <div className="col-sm-6">
              <Input
                label="Expiry Date"
                name="cnd_identity_doc_exp_date"
                id="cnd_identity_doc_exp_date"
                type="date"
                min={new Date().toISOString().split("T")[0]}
                className="form-control"
                value={identityData.cnd_identity_doc_exp_date}
                changeHandler={(e) => onChangeInputvalue(e)}
                placeholder="Your Name"
                lg={12}
              />
            </div>
            <div className="col-sm-6">
              <Input
                label="Document Reference"
                name="cnd_identity_doc_reference"
                id="cnd_identity_doc_reference"
                type="text"
                className="form-control"
                value={identityData.cnd_identity_doc_reference}
                changeHandler={(e) => onChangeInputvalue(e)}
                placeholder="Enter document reference"
                lg={12}
              />
            </div>
            <div className="col-sm-12">
              <Textarea
                label="Document Description"
                name="cnd_identity_doc_description"
                id="cnd_identity_doc_description"
                type="text"
                className="form-control"
                placeholder="Enter Document Description"
                lg={12}
                value={identityData.cnd_identity_doc_description}
                changeHandler={(e) => onChangeInputvalue(e)}
                req={false}
              />
              <Button
                className="site-button m-b30 float-right mr-3"
                type="submit"
              >
                Save
              </Button>
              <a
                href="/identity-document"
                className="site-button text-white yellow m-b30 float-right mr-3"
                type="button"
              >
                Back
              </a>
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    userProfileData: state.userProfile.userData,
    identityDocument: state.identityDocument.identityDocument,
  };
};
export default connect(mapStateToProps)(IdentityDocument);
