import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Spinner, Form, Col } from "react-bootstrap";
import BankDetailInfo from "../../../containers/BankDetailInfo";
import Error from "../../Layout/Error";
import { santetizeData } from "../../../helpers/FormFieldsSanetizations";
import { connect } from "react-redux";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { BankDataValidations } from "../../../helpers/BankDataValidations";
import Label from "../../Layout/Label";
import { toast } from "react-toastify";
import {
  nationalInsurance,
  sortCode,
  accountNumber,
  rollNumber,
} from "../../../helpers/ValidationHelpers";
import { Input } from "../../FormElements/Forms";
import { useHistory } from "react-router-dom";
import composedBankDetailInfo from "../../../containers/BankDetailInfo";
const BankDetailForm = (props) => {
  const dispatch = useDispatch();
  const { progress, userData } = props;
  const [bankData, setFormdata] = useState({ users_access_token: "" });
  const [formDisabled, setformDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  let setNIN = useRef("");
  let setSortCode = useRef("");
  const { loader } = props;
  const onChangeInputvalue = (e) => {
    bankData[e.target.name] = e.target.value;
    if (e.target.name == "cnd_bank_national_insurance") {
      if (e.target.value.length == 2)
        bankData[e.target.name] = e.target.value + "-";
      else if (e.target.value.length == 9)
        bankData[e.target.name] = e.target.value + "-";
      else bankData[e.target.name] = e.target.value;
      if (!nationalInsurance(bankData.cnd_bank_national_insurance)) {
        setErrors({
          cnd_bank_national_insurance: "Invalid National Insurance Number",
        });
      } else {
        setErrors({});
      }
    }
    if (e.target.name == "cnd_bank_sort_code") {
      if (setSortCode.current.value && e.target.value.length == 2)
        bankData[e.target.name] = e.target.value + "-";
      else if (setSortCode.current.value && e.target.value.length == 4)
        bankData[e.target.name] = e.target.value + "-";
      else bankData[e.target.name] = e.target.value;
      if (!sortCode(bankData.cnd_bank_sort_code)) {
        setErrors({ cnd_bank_sort_code: "Invalid Sort Code" });
      } else {
        setErrors({});
      }
    }
    if (e.target.name == "cnd_bank_account_number") {
      if (!bankData.cnd_bank_account_number) {
        setErrors({ cnd_bank_account_number: "Enter Confirm Account Number" });
      } else if (!accountNumber(bankData.cnd_bank_account_number)) {
        setErrors({ cnd_bank_account_number: "Invalid Account Number" });
      } else {
        setErrors({});
      }
    }
    if (e.target.name == "cnd_confirm_bank_account_number") {
      if (!bankData.cnd_confirm_bank_account_number) {
        setErrors({
          cnd_confirm_bank_account_number: "Enter Confirm Account Number",
        });
      } else if (!accountNumber(bankData.cnd_confirm_bank_account_number)) {
        setErrors({
          cnd_confirm_bank_account_number: "Invalid Account Number",
        });
      } else if (
        bankData.cnd_confirm_bank_account_number !==
        bankData.cnd_bank_account_number
      ) {
        setErrors({
          cnd_confirm_bank_account_number:
            "Confirm Account Number Should Match Account Number",
        });
      } else {
        setErrors({});
      }
    }
    validateBankData();
    setFormdata(bankData);
  };

  const validateBankData = () => {
    let validations = BankDataValidations(bankData);
    // console.log(validations);
    setErrors(validations.stateData);
    return validations.error;
  };

  const updateBankDetails = (e) => {
    e.preventDefault();
    validateBankData();
    let cnd_bank_name = document.getElementById("cnd_bank_name");
    let cnd_bank_sort_code = document.getElementById("cnd_bank_sort_code");
    let cnd_bank_account_number = document.getElementById(
      "cnd_bank_account_number"
    );
    let cnd_confirm_bank_account_number = document.getElementById(
      "cnd_confirm_bank_account_number"
    );
    let cnd_bank_roll_number = document.getElementById("cnd_bank_roll_number");
    let cnd_bank_national_insurance = document.getElementById(
      "cnd_bank_national_insurance"
    );
    let cnd_bank_holder_name = document.getElementById("cnd_bank_holder_name");

    if (
      cnd_bank_name.value &&
      !cnd_bank_name.value.trim() == "" &&
      cnd_bank_sort_code.value &&
      !cnd_bank_sort_code.value.trim() == "" &&
      sortCode(cnd_bank_sort_code.value) &&
      cnd_bank_account_number.value &&
      !cnd_bank_account_number.value.trim() == "" &&
      accountNumber(cnd_bank_account_number.value) &&
      cnd_confirm_bank_account_number.value &&
      !cnd_confirm_bank_account_number.value.trim() == "" &&
      accountNumber(cnd_confirm_bank_account_number.value) &&
      cnd_confirm_bank_account_number.value == cnd_bank_account_number.value &&
      (!cnd_bank_roll_number.value ||
        (cnd_bank_roll_number.value &&
          !cnd_bank_roll_number.value.trim() == "" &&
          rollNumber(cnd_bank_roll_number.value))) &&
      cnd_bank_national_insurance.value &&
      !cnd_bank_national_insurance.value.trim() == "" &&
      nationalInsurance(cnd_bank_national_insurance.value) &&
      cnd_bank_holder_name.value &&
      !cnd_bank_holder_name.value.trim() == ""
    ) {
      document.getElementById("loader").style.display = "block";
      delete bankData.cnd_confirm_bank_account_number;
      bankData["users_access_token"] = props.userAuth.users_access_token;
      bankData["cnd_bank_holder_name"] = cnd_bank_holder_name.value;
      dispatch(
        updateDataOnRequest(
          santetizeData(bankData),
          "UpdateBankInfo",
          "UPDATE_BANK_DATA"
        )
      );
      dispatch(
        getDataOnRequest(
          props.userAuth.users_access_token,
          "ProgressBar",
          "SET_PROGRESS"
        )
      );
      //history.push('/right-to-work');
      toast.success("Bank Details Updated Successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      let interval = setInterval(myUrl, 2000);
      function myUrl() {
        window.location.href = progress.progress_bar_right_to_work_5
          ? "/right-to-work"
          : "/create-right-to-work";
        clearInterval(interval);
      }
    }
  };

  // console.log("Progress Data Is :=>"+progress.progress_bar_right_to_work_5);
  useEffect(() => {
    if (props.bankInfo != "") {
      let formdata = { ...props.bankInfo };
      formdata.cnd_confirm_bank_account_number =
        props.bankInfo.cnd_bank_account_number;
      setFormdata(formdata);
      setformDisabled(true);
    }
  }, [props.bankInfo]);

  const handleKeyDown = (e) => {
    if (e.keyCode != 8 && e.target.value.length == 2)
      setNIN.current.value = e.target.value + "-";
    else if (e.keyCode != 8 && e.target.value.length == 9)
      setNIN.current.value = e.target.value + "-";
  };

  const handleKeyDownSortCode = (e) => {
    if (e.keyCode != 8 && e.target.value.length == 2)
      setSortCode.current.value = e.target.value + "-";
    else if (e.keyCode != 8 && e.target.value.length == 5)
      setSortCode.current.value = e.target.value + "-";
  };

  useEffect(() => {
    setNIN.current.addEventListener("keydown", handleKeyDown);
    setSortCode.current.addEventListener("keydown", handleKeyDownSortCode);
  }, [setNIN, setSortCode]);

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Bank Details
          </h5>
        </div>
        <form onKeyUp={(e) => onChangeInputvalue(e)}>
          <div className="row m-b30">
            <Input
              type="text"
              label="Bank Name"
              disabled={
                bankData.user_sign_signature != null &&
                bankData.user_sign_signature != ""
                  ? true
                  : false
              }
              changeHandler={(e) => onChangeInputvalue(e)}
              name="cnd_bank_name"
              id={"cnd_bank_name"}
              lg={6}
              md={6}
              value={bankData.cnd_bank_name && bankData.cnd_bank_name}
              placeholder="Eg HSBC Bank"
              errors={errors}
            />
            <Form.Group as={Col}>
              <Label labelName="Sort Code" />
              <Form.Control
                defaultValue={
                  bankData.cnd_bank_sort_code && bankData.cnd_bank_sort_code
                }
                disabled={
                  bankData.user_sign_signature != null &&
                  bankData.user_sign_signature != ""
                    ? true
                    : false
                }
                name="cnd_bank_sort_code"
                id="cnd_bank_sort_code"
                ref={setSortCode}
                maxLength="8"
                onChange={(e) => onChangeInputvalue(e)}
                type="text"
                placeholder="Eg 12-34-56"
                className={
                  errors && errors["cnd_bank_sort_code"]
                    ? "invalid form-control"
                    : "valid form-control"
                }
                isInvalid={
                  errors && errors["cnd_bank_sort_code"] ? true : false
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors["cnd_bank_sort_code"]}
              </Form.Control.Feedback>
            </Form.Group>
            <Input
              type="text"
              label="Account Number"
              changeHandler={(e) => onChangeInputvalue(e)}
              disabled={
                bankData.user_sign_signature != null &&
                bankData.user_sign_signature != ""
                  ? true
                  : false
              }
              name="cnd_bank_account_number"
              id={"cnd_bank_account_number"}
              lg={6}
              md={6}
              maxLength={8}
              value={
                bankData.cnd_bank_account_number &&
                bankData.cnd_bank_account_number
              }
              placeholder="Eg 12345678"
              errors={errors}
            />
            <Input
              type="text"
              label="Confirm Account Number"
              disabled={
                bankData.user_sign_signature != null &&
                bankData.user_sign_signature != ""
                  ? true
                  : false
              }
              changeHandler={(e) => onChangeInputvalue(e)}
              name="cnd_confirm_bank_account_number"
              id={"cnd_confirm_bank_account_number"}
              value={
                bankData.cnd_confirm_bank_account_number &&
                bankData.cnd_confirm_bank_account_number
              }
              lg={6}
              md={6}
              maxLength={8}
              placeholder="Eg 12345678"
              errors={errors}
            />
            <Input
              type="text"
              disabled={
                bankData.user_sign_signature != null &&
                bankData.user_sign_signature != ""
                  ? true
                  : false
              }
              label="Roll No(Optional– Only required if bank provides roll no)"
              changeHandler={(e) => onChangeInputvalue(e)}
              name="cnd_bank_roll_number"
              id={"cnd_bank_roll_number"}
              lg={6}
              md={6}
              //minLength={2}
              maxLength={6}
              value={
                bankData.cnd_bank_roll_number == 0
                  ? ""
                  : bankData.cnd_bank_roll_number &&
                    bankData.cnd_bank_roll_number
              }
              placeholder="Eg 1234"
              pattern="[0-9]{4}"
              errors={""}
              req={false}
            />
            <Form.Group as={Col}>
              <Label labelName="National Insurance Number" />
              <Form.Control
                defaultValue={
                  bankData.cnd_bank_national_insurance &&
                  bankData.cnd_bank_national_insurance
                }
                name="cnd_bank_national_insurance"
                id="cnd_bank_national_insurance"
                ref={setNIN}
                disabled={
                  bankData.user_sign_signature != null &&
                  bankData.user_sign_signature != ""
                    ? true
                    : false
                }
                onChange={(e) => onChangeInputvalue(e)}
                type="text"
                maxLength={11}
                placeholder="XX-123456-X"
                style={{ textTransform: "uppercase" }}
                className={
                  errors && errors["cnd_bank_national_insurance"]
                    ? "invalid form-control"
                    : "valid form-control"
                }
                isInvalid={
                  errors && errors["cnd_bank_national_insurance"] ? true : false
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors["cnd_bank_national_insurance"]}
              </Form.Control.Feedback>
            </Form.Group>
            <Input
              type="text"
              disabled={
                bankData.user_sign_signature != null &&
                bankData.user_sign_signature != ""
                  ? true
                  : false
              }
              label="Account Holder Name"
              changeHandler={(e) => onChangeInputvalue(e)}
              name="cnd_bank_holder_name"
              id={"cnd_bank_holder_name"}
              lg={6}
              md={6}
              value={
                bankData.cnd_bank_holder_name
                  ? bankData.cnd_bank_holder_name
                  : bankData.users_firstname &&
                    bankData.users_firstname + " " + bankData.users_lastname
              }
              placeholder="Eg Jade Wisternoff"
              errors={errors}
            />
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <Button
                  disabled={loader ? "disabled" : ""}
                  className="site-button m-b30 pull-right"
                  onClick={(e) => updateBankDetails(e)}
                >
                  {loader && (
                    <Spinner
                      as="span"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    />
                  )}
                  {!loader ? "Save & Next" : "Loading..."}
                </Button>
                <br />
                <br />
                <br />
                {bankData.user_sign_signature != null &&
                  bankData.user_sign_signature != "" && (
                    <p className="text-justify mt-2">
                      If you want to change the bank account details, please
                      email it to{" "}
                      <a
                        href="mailto:finance@staffingconnect.co.uk"
                        target="_blank"
                        className="text-primary"
                      >
                        finance@staffingconnect.co.uk
                      </a>
                    </p>
                  )}
              </div>
            </div>
          </div>
          {/* <button className="site-button m-b30" onClick={(e)=>updateUserProfilePersonalInfo(e)}>Save Setting</button> */}
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    progress: state.progress.progress,
    userData: state.userProfile.userData,
    userAuth: state.auth.authUserData,
  };
};

// export default BankDetailInfo(BankDetailForm);
export default connect(mapStateToProps)(composedBankDetailInfo(BankDetailForm));
