import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  updateDataOnRequest,
  deleteDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import composedDocumentRequireds from "../../../containers/DocumentRequiredsContainer";
import { setError } from "../../../redux/actions/ErrorActions";
import {
  DocumentComplianceValidations,
  validateStringWithoutSpecialCharacter,
} from "../../../helpers/DocumentComplianceValidations";
import DocFormNational from "./DocFormNational";
import { getBase64 } from "../../../helpers/FileToBase64";
import DocFormAddres1 from "./DocFormAddres1";
import DocFormAddres2 from "./DocFormAddres2";
import DocFormAdditional from "./DocFormAdditional";
import DocFormTraining from "./DocFormTraining";
import { toast } from "react-toastify";
import DocFormUpdateAddress from "./DocFormUpdateAddress";
//import DocumentRequiredList from "./DocumentRequiredList";
const DocumentCompliance = (props) => {
  const param = useParams();
  //const [userdata, setFormdata] = useState(props.userData);
  const { form, loader, userProfile, documentCompliance } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputList, setInputList] = useState([]);
  const [radioOne, setAddressOne] = useState("No");
  // const [radioTwo, setAddressTwo] = useState('No');
  const handleInputChange = async (e, index) => {
    const { name, value, files } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (name == "cnd_compliance_upload_file") {
      list[index][name] = await getBase64(e.target.files[0]);
    }
    validationErrors();
    setInputList(list);
    let cnd_compliance_upload_file = document.getElementById(
      "cnd_compliance_upload_file"
    );
    let cnd_compliance_upload_file_error = document.getElementById(
      "cnd_compliance_upload_file_error"
    );
    if (cnd_compliance_upload_file.value) {
      cnd_compliance_upload_file.classList.remove("text-danger");
      cnd_compliance_upload_file_error.style.display = "none";
    }

    if (window.location.pathname == "/create-document-additional") {
      let cnd_doc_name = document.getElementById("cnd_doc_name");
      let cnd_doc_name_error = document.getElementById("cnd_doc_name_error");
      if (cnd_doc_name.value && !cnd_doc_name.value.trim() == "") {
        cnd_doc_name.classList.remove("text-danger", "border-danger");
        cnd_doc_name_error.style.display = "none";
      } else if (!cnd_doc_name.value || cnd_doc_name.value.trim() == "") {
        cnd_doc_name.classList.add("text-danger", "border-danger");
        cnd_doc_name_error.style.display = "block";
      }
    }

    // Document Reference Start
    let cnd_compliance_reference = document.getElementById(
      "cnd_compliance_reference"
    );
    let cnd_compliance_reference_error = document.getElementById(
      "cnd_compliance_reference_error"
    );
    if (
      cnd_compliance_reference.value &&
      (cnd_compliance_reference.value.trim() == "" ||
        !validateStringWithoutSpecialCharacter(cnd_compliance_reference.value))
    ) {
      cnd_compliance_reference.classList.add("text-danger", "border-danger");
      cnd_compliance_reference_error.style.display = "block";
    } else {
      cnd_compliance_reference.classList.remove("text-danger", "border-danger");
      cnd_compliance_reference_error.style.display = "none";
    }
    // Document Reference Stop

    // Document Expiry Data Start
    if (window.location.pathname == "/create-document-training-certificate") {
      let cnd_compliance_exp_date = document.getElementById(
        "cnd_compliance_exp_date"
      );
      let cnd_compliance_exp_date_error = document.getElementById(
        "cnd_compliance_exp_date_error"
      );
      let minDate = new Date(String(cnd_compliance_exp_date.min));
      let finalDate = new Date(String(cnd_compliance_exp_date.value));
      if (
        cnd_compliance_exp_date.value &&
        (cnd_compliance_exp_date.value.match(
          /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
        ) ||
          finalDate < minDate)
      ) {
        cnd_compliance_exp_date.classList.remove(
          "text-danger",
          "border-danger"
        );
        cnd_compliance_exp_date_error.style.display = "none";
      }
    }
    // Document Expiry Date Stop
  };

  // const handleChangeAddress2One = (e, i) => {
  //   let formData = [...inputList];
  //   if(e == 'No'){
  //     // formData[i].cnd_street_address_2 = userProfile.cnd_street_address_2;
  //   }
  //   setInputList(formData);
  //   setAddressTwo(e);
  // }

  const handleChangeAddressOne = (e, i) => {
    let formData = [...inputList];
    if (e == "No") {
      formData[i].cnd_street_address = userProfile.cnd_street_address;
      formData[i].cnd_town = userProfile.cnd_town;
      formData[i].cnd_postcode = userProfile.cnd_postcode;
      formData[i].cnd_region = userProfile.cnd_region;
    }
    setInputList(formData);
    setAddressOne(e);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index, id) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    if (id) {
      dispatch(
        deleteDataOnRequest(
          {
            cnd_compliance_id: id,
            users_access_token: props.userAuth.users_access_token,
          },
          "DeleteDocumentCompliance",
          "",
          getDataOnRequest(
            props.userAuth.users_access_token,
            "SetDocumentCompliance",
            "SET_DOCUMENT_COMPLIANCE"
          ),
          id
        )
      );
    }
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        users_access_token: props.userAuth.users_access_token,
        cnd_compliance_doc_master_id: "additional",
        cnd_compliance_upload_file: "",
        cnd_compliance_description: "",
        cnd_compliance_exp_date: "",
        cnd_compliance_reference: "",
      },
    ]);
  };

  let err;
  const validationErrors = () => {
    let validations = DocumentComplianceValidations(inputList, form);
    if (validations.error == true) {
      dispatch(setError(validations.stateData));
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("All Submitted Data Is :=>"+JSON.stringify(inputList[0].cnd_compliance_doc_master_id));
    if (!validationErrors()) {
      document.getElementById("loader").style.display = "block";
      dispatch(setError([]));
      dispatch(
        updateDataOnRequest(
          inputList,
          "updateDocumentCompliance",
          "",
          getDataOnRequest(
            props.userAuth.users_access_token,
            "SetDocumentCompliance",
            "SET_DOCUMENT_COMPLIANCE"
          )
        )
      );

      dispatch(
        getDataOnRequest(
          props.userAuth.users_access_token,
          "ProgressBar",
          "SET_PROGRESS"
        )
      );
      let docMasterName;
      inputList.map((id) => {
        docMasterName = id.cnd_compliance_doc_master_id;
      });
      //console.log("Document Name :=> " + docMasterName);

      // if(docMasterName == "address1"){
      //   toast.success("Proof of Address 1 Saved Successfully!", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   })
      //   let interval = setInterval(myUrl, 2000);
      //   function myUrl(){
      //     //window.location.href = "/create-document-address2";
      //     clearInterval(interval);
      //   }
      // }else if(docMasterName == "address2"){
      //   toast.success("Proof of Address 2 Saved Successfully!", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   })
      //   let interval = setInterval(myUrl, 2000);
      //   function myUrl(){
      //     //window.location.href = "/create-document-national-insurance";
      //     clearInterval(interval);
      //   }
      // }else if(docMasterName == "nationalinsurance"){
      //   toast.success("Proof of National Insurance Saved Successfully!", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   })
      //   let interval = setInterval(myUrl, 2000);
      //   function myUrl(){
      //     //window.location.href = "/create-document-training-certificate";
      //     clearInterval(interval);
      //   }
      // }else if(docMasterName == "training"){
      //   toast.success("Training Certificate Saved Successfully!", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   })
      //   let interval = setInterval(myUrl, 2000);
      //   function myUrl(){
      //     //window.location.href = "/create-document-additional";
      //     clearInterval(interval);
      //   }
      // }else if(docMasterName == "additional"){
      //   toast.success("Additional Document Saved Successfully!", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   })
      //   let interval = setInterval(myUrl, 2000);
      //   function myUrl(){
      //     //window.location.href = "/document-required";
      //     clearInterval(interval);
      //   }
      // }

      ////console.log("This Is The Master Id :=>" + documentCompliance);
      //history.push('/dbs-document');
    }
  };

  useEffect(() => {
    let dataArray = [];
    ////console.log(documentCompliance);
    if (documentCompliance.length > 0 && param.id != null) {
      let formData = [];
      documentCompliance.map((compliance, i) => {
        if (window.location.pathname == "/create-document-national-insurance") {
          if (compliance.cnd_compliance_doc_master_id == "nationalinsurance")
            dataArray = [
              {
                users_access_token:
                  props.userAuth && props.userAuth.users_access_token,
                cnd_compliance_doc_master_id: "nationalinsurance",
                cnd_compliance_upload_file: "",
                cnd_compliance_description:
                  compliance.cnd_compliance_description,
                cnd_compliance_exp_date: "",
                cnd_compliance_reference: compliance.cnd_compliance_reference,
              },
            ];
        } else if (window.location.pathname == "/create-document-address1") {
          dataArray = [
            {
              users_access_token:
                props.userAuth && props.userAuth.users_access_token,
              cnd_compliance_doc_master_id: "address1",
              cnd_compliance_upload_file: "",
              /*cnd_street_address: "Demo",//userProfile.cnd_street_address,
            cnd_postcode: userProfile.cnd_postcode,
            cnd_town: userProfile.cnd_town,
            cnd_region: userProfile.cnd_region,*/
              cnd_compliance_description: "",
              cnd_compliance_exp_date: "",
              cnd_compliance_reference: "",
            },
          ];
        } else if (window.location.pathname == "/create-document-address2") {
          dataArray = [
            {
              users_access_token:
                props.userAuth && props.userAuth.users_access_token,
              cnd_compliance_doc_master_id: "address2",
              cnd_compliance_upload_file: "",
              // cnd_street_address_2: userProfile.cnd_street_address_2,
              cnd_compliance_description: "",
              cnd_compliance_exp_date: "",
              cnd_compliance_reference: "",
            },
          ];
        } else if (
          window.location.pathname == "/create-document-training-certificate"
        ) {
          dataArray = [
            {
              users_access_token:
                props.userAuth && props.userAuth.users_access_token,
              cnd_compliance_doc_master_id: "training",
              cnd_compliance_upload_file: "",
              // cnd_street_address_2: userProfile.cnd_street_address_2,
              cnd_compliance_description: "",
              cnd_compliance_exp_date: "",
              cnd_compliance_reference: "",
            },
          ];
        } else if (window.location.pathname == "/create-document-additional") {
          dataArray = [
            {
              users_access_token:
                props.userAuth && props.userAuth.users_access_token,
              cnd_compliance_doc_master_id: "additional",
              cnd_compliance_upload_file: "",
              // cnd_street_address_2: userProfile.cnd_street_address_2,
              cnd_compliance_description: "",
              cnd_compliance_exp_date: "",
              cnd_compliance_reference: "",
            },
            /*{
              users_access_token:
              props.userAuth && props.userAuth.users_access_token,
              cnd_compliance_doc_master_id: "additional",
              cnd_compliance_upload_file: "",
             // cnd_street_address_2: userProfile.cnd_street_address_2,
               cnd_compliance_description: "",
               cnd_compliance_exp_date: "",
               cnd_compliance_reference: "",
           },*/
          ];
        }
      });

      // if (props.documentCompliance.length > 2) {
      //   props.documentCompliance.map((compliance, i)=>{
      //     formData[i] = compliance;
      //   });
      // }

      // if(!formData[1]){
      //   formData[1] = {
      //     users_access_token:
      //     props.userAuth && props.userAuth.users_access_token,
      //     cnd_compliance_doc_master_id: "address1",
      //     cnd_street_address: userProfile.cnd_street_address,
      //     cnd_postcode: userProfile.cnd_postcode,
      //     cnd_town: userProfile.cnd_town,
      //     cnd_region: userProfile.cnd_region,
      //     cnd_compliance_upload_file: "",
      //     cnd_compliance_description: "",
      //     cnd_compliance_exp_date: "",
      //     cnd_compliance_reference: "",
      //   }
      // }

      //dataArray = [...formData];
      // //console.log(dataArray);
    } else {
      if (window.location.pathname == "/create-document-address1") {
        dataArray = [
          {
            users_access_token:
              props.userAuth && props.userAuth.users_access_token,
            cnd_compliance_doc_master_id: "address1",
            cnd_compliance_upload_file: "",
            /*cnd_street_address: "Demo",//userProfile.cnd_street_address,
          cnd_postcode: userProfile.cnd_postcode,
          cnd_town: userProfile.cnd_town,
          cnd_region: userProfile.cnd_region,*/
            cnd_compliance_description: "",
            cnd_compliance_exp_date: "",
            cnd_compliance_reference: "",
          },
        ];
      } else if (window.location.pathname == "/create-document-address2") {
        dataArray = [
          {
            users_access_token:
              props.userAuth && props.userAuth.users_access_token,
            cnd_compliance_doc_master_id: "address2",
            cnd_compliance_upload_file: "",
            // cnd_street_address_2: userProfile.cnd_street_address_2,
            cnd_compliance_description: "",
            cnd_compliance_exp_date: "",
            cnd_compliance_reference: "",
          },
        ];
      } else if (
        window.location.pathname == "/create-document-national-insurance"
      ) {
        dataArray = [
          {
            users_access_token:
              props.userAuth && props.userAuth.users_access_token,
            cnd_compliance_doc_master_id: "nationalinsurance",
            cnd_compliance_upload_file: "",
            cnd_compliance_description: "",
            cnd_compliance_exp_date: "",
            cnd_compliance_reference: "",
          },
        ];
      } else if (
        window.location.pathname == "/create-document-training-certificate"
      ) {
        dataArray = [
          {
            users_access_token:
              props.userAuth && props.userAuth.users_access_token,
            cnd_compliance_doc_master_id: "training",
            cnd_compliance_upload_file: "",
            // cnd_street_address_2: userProfile.cnd_street_address_2,
            cnd_compliance_description: "",
            cnd_compliance_exp_date: "",
            cnd_compliance_reference: "",
          },
        ];
      } else if (window.location.pathname == "/create-document-additional") {
        dataArray = [
          {
            users_access_token:
              props.userAuth && props.userAuth.users_access_token,
            cnd_compliance_doc_master_id: "additional",
            cnd_compliance_upload_file: "",
            // cnd_street_address_2: userProfile.cnd_street_address_2,
            cnd_compliance_description: "",
            cnd_compliance_exp_date: "",
            cnd_compliance_reference: "",
          },
          /*{
            users_access_token:
            props.userAuth && props.userAuth.users_access_token,
            cnd_compliance_doc_master_id: "additional",
            cnd_compliance_upload_file: "",
           // cnd_street_address_2: userProfile.cnd_street_address_2,
             cnd_compliance_description: "",
             cnd_compliance_exp_date: "",
             cnd_compliance_reference: "",
         },*/
        ];
      }
    }
    setInputList(dataArray);
  }, [props.userAuth, documentCompliance]);

  let docMasterName;
  inputList.map((id) => {
    docMasterName = id.cnd_compliance_doc_master_id;
  });

  //Data Map Calling
  let masterIdNational,
    masterIdAddress1,
    masterIdAddress2,
    masterIdTraining,
    masterIdAdditional;
  documentCompliance.map((compliance, i) => {
    if (compliance.cnd_compliance_doc_master_id == "address1") {
      masterIdAddress1 = compliance.cnd_compliance_doc_master_id;
    } else if (compliance.cnd_compliance_doc_master_id == "address2") {
      masterIdAddress2 = compliance.cnd_compliance_doc_master_id;
    } else if (compliance.cnd_compliance_doc_master_id == "nationalinsurance") {
      masterIdNational = compliance.cnd_compliance_doc_master_id;
    } else if (compliance.cnd_compliance_doc_master_id == "training") {
      masterIdTraining = compliance.cnd_compliance_doc_master_id;
    } else if (compliance.cnd_compliance_doc_master_id == "additional") {
      masterIdAdditional = compliance.cnd_compliance_doc_master_id;
    }
  });

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        {docMasterName == "address1" && (
          <DocFormAddres1
            inputList={inputList}
            loader={loader}
            onSubmit={(e) => onSubmit(e)}
            handleAddClick={(index) => handleAddClick(index)}
            handleRemoveClick={(index, id) => handleRemoveClick(index, id)}
            handleInputChange={(e, i) => handleInputChange(e, i)}
            errors={props.errors}
            userProfile={userProfile}
            radioOne={radioOne}
            hideNextButton={masterIdAddress1}
            // radioTwo={radioTwo}
            // handleChangeAddress2One={(e,i)=>handleChangeAddress2One(e,i)}
            handleChangeAddressOne={(e, i) => handleChangeAddressOne(e, i)}
          />
        )}

        {docMasterName == "address2" && (
          <DocFormAddres2
            inputList={inputList}
            loader={loader}
            onSubmit={(e) => onSubmit(e)}
            handleAddClick={(index) => handleAddClick(index)}
            handleRemoveClick={(index, id) => handleRemoveClick(index, id)}
            handleInputChange={(e, i) => handleInputChange(e, i)}
            errors={props.errors}
            userProfile={userProfile}
            radioOne={radioOne}
            hideNextButton={masterIdAddress2}
            // radioTwo={radioTwo}
            // handleChangeAddress2One={(e,i)=>handleChangeAddress2One(e,i)}
            handleChangeAddressOne={(e, i) => handleChangeAddressOne(e, i)}
          />
        )}

        {docMasterName == "nationalinsurance" && (
          <DocFormNational
            inputList={inputList}
            loader={loader}
            onSubmit={(e) => onSubmit(e)}
            handleAddClick={(index) => handleAddClick(index)}
            handleRemoveClick={(index, id) => handleRemoveClick(index, id)}
            handleInputChange={(e, i) => handleInputChange(e, i)}
            errors={props.errors}
            userProfile={userProfile}
            radioOne={radioOne}
            hideNextButton={masterIdNational}
            // radioTwo={radioTwo}
            // handleChangeAddress2One={(e,i)=>handleChangeAddress2One(e,i)}
            handleChangeAddressOne={(e, i) => handleChangeAddressOne(e, i)}
          />
        )}

        {docMasterName == "training" && (
          <DocFormTraining
            inputList={inputList}
            loader={loader}
            onSubmit={(e) => onSubmit(e)}
            handleAddClick={(index) => handleAddClick(index)}
            handleRemoveClick={(index, id) => handleRemoveClick(index, id)}
            handleInputChange={(e, i) => handleInputChange(e, i)}
            errors={props.errors}
            userProfile={userProfile}
            radioOne={radioOne}
            hideNextButton={masterIdTraining}
            // radioTwo={radioTwo}
            // handleChangeAddress2One={(e,i)=>handleChangeAddress2One(e,i)}
            handleChangeAddressOne={(e, i) => handleChangeAddressOne(e, i)}
          />
        )}

        {window.location.pathname == "/create-document-additional" && (
          <DocFormAdditional
            inputList={inputList}
            loader={loader}
            onSubmit={(e) => onSubmit(e)}
            handleAddClick={(index) => handleAddClick(index)}
            handleRemoveClick={(index, id) => handleRemoveClick(index, id)}
            handleInputChange={(e, i) => handleInputChange(e, i)}
            errors={props.errors}
            userProfile={userProfile}
            radioOne={radioOne}
            hideNextButton={masterIdAdditional}
            // radioTwo={radioTwo}
            // handleChangeAddress2One={(e,i)=>handleChangeAddress2One(e,i)}
            handleChangeAddressOne={(e, i) => handleChangeAddressOne(e, i)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  ////console.warn("Userprofile Info :=> "+state.userProfile.userData);
  return {
    userData: state.userExperience.userData,
    userAuth: state.auth.authUserData,
    userProfile: state.userProfile.userData,
    documentCompliance: state.documentCompliance.documentCompliance,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};

export default composedDocumentRequireds(DocumentCompliance);
