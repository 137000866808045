import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const JobsContainer = (OriginalComponent) => {
  let usersAccessToken = localStorage.getItem("loggedInUserAccessToken");
  // console.log("User Access Token:=>", usersAccessToken);
  const NewComponent = (props) => {
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      // if(window.location.pathname == "/job-board"){
        if(props.jobsdetails && window.location.pathname != "/job-details"){
          dispatch(getDataOnRequest((usersAccessToken ? usersAccessToken : ''),'GetJobsCandidateAppliedDetails','SET_JOBS_DETAILS'))
        }

        // dispatch(getDataOnRequest((usersAccessToken ? usersAccessToken : ''),'GetJobsDetails','SET_JOBS_DETAILS'))
        // if(props.jobsScreeningQuestions){
        //   dispatch(getDataOnRequest('2','GetJobsScreeningQuestions','SET_JOBS_SCREENING_QUESTIONS'))
        // }
      // }else{
      //   if(window.location.pathname && window.location.pathname.substring(12)){
      //     dispatch(getDataOnRequest(window.location.pathname.substring(12),'GetJobsDetails','SET_JOBS_DETAILS'))

      //   }
      // }
      
    },[])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData,
    jobsdetails:state.jobsdetails.jobsdetails,
    workAndQualificationDetails:
      state.workAndQualificationDetails.workAndQualificationDetails,
    individualJobsDetails:state.individualJobsDetails.individualJobsDetails,
    jobsScreeningQuestions:state.jobsScreeningQuestions.jobsScreeningQuestions    
  };
};

const composedJobsContainer = compose(connect(mapStateToProps), JobsContainer)
export default composedJobsContainer;

