import * as Yup from "yup";
export const formInputFields = () => [
  {
    name: "clt_details_organisation_name",
    type: "text",
    label: "Organization Full Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_details_full_name",
    type: "text",
    label: "Full Name",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_details_position",
    type: "text",
    label: "Position",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_details_email",
    type: "text",
    label: "Email",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_details_phone_no",
    type: "text",
    label: "Phone Number",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_details_date",
    type: "date",
    label: "Date",
    md: "6",
    sm: "12",
  },
  {
    name: "clt_details_signed",
    type: "sign",
    label: "Signed Image",
    md: "6",
    sm: "12",
  },
];
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
export const validationSchema = Yup.object().shape({
  clt_details_organisation_name: Yup.string().required(
    "Organization Field Required"
  ),
  clt_details_full_name: Yup.string().required("Full Name Field Required"),
  clt_details_position: Yup.string().required("Position Field Required"),
  clt_details_date: Yup.string().required("Date Field Required"),
  clt_details_signed: Yup.string()
    .required("Signature Required")
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature must be png"
    ),
  clt_details_email: Yup.string()
    .required("Email Field Required")
    .email("Pease Enter Vlaid Email"),
  clt_details_phone_no: Yup.string()
    .required("Phone Number Field Required")
    .matches(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      "Please provide valid Telephone Number"
    ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
