import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, Route } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { redirectUrl } from "../../services/GlobalUrls";
import {
  capitalizeFirstLetter,
  convertToSnakeCase,
} from "../../services/StringOperation";
import { jobTitle } from "../../helpers/WorkRefrencesHelper";
import GlobalViewFileModal from "../../services/GlobalViewFileModal";
import { checkIfFileIsaDocument } from "../../services/FileOperationGlobalFunctions";
import Swal from "sweetalert2";
import { getInitialsLettersOfString } from "../../services/GlobalStringOp";
// Navbar Bottom - Start
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import SwipeableTemporaryDrawer from "../../services/DrawerMenus";
// Navbar Bottom - End
const Profilesidebar = (props) => {
  let cndStatus = localStorage.getItem("tempUserStatus");
  // Drawer Menu - Start
  const [myProfileMenu, setMyProfileMenu] = useState(false);
  const[menuList, setMenuList] = useState([
    {
      name: "Submit Timesheet",
      icon: "fa fa-calendar",
      url: "/timesheet",
      divider: false
    },
    {
      name: "My Availability",
      icon: "fa fa-calendar-check-o",
      url: "/availability",
      divider: false
    },
    {
      name: "Daily Logs",
      icon: "fa fa-comment-o",
      url: "/daily-logs",
      divider: false
    },
    {
      name: "My Assignments",
      icon: "fa fa-sticky-note-o",
      url: "/my-assignments",
      divider: false
    },
    {
      name: "Jobs Applied",
      icon: "fa fa-briefcase",
      url: "/jobs-applied",
      divider: false
    }
  ]);
  // Handle Drawer State Change - Start
  const handleDrawerStateChange = (state) => {
    setMyProfileMenu(state);
  };
  // Handle Drawer State Change - End
  // Drawer Menu - End
  // Modal States - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // Modal State - End
  //   Navbar Bottoms - Start
  const [isScrolling, setIsScrolling] = useState(false);
  const [value, setValue] = useState("");
  const ref = useRef(null);
  const [touchMove, setTouchMove] = useState("false");
  // Navbar Bottoms - End

  const history = useHistory();
  const { progress, userData, dbsDocuments } = props;
  const [formStates, setFormStates] = useState({
    userProfile: false,
    employmentReferences: false,
    typesOfServices: false,
    agreements: false,
    bankDetails: false,
    rightToWork: false,
    workPreferences: false,
    documentsCompliance: false,
    dbsCertificate: false,
    identityDocument: false,
  });
  const [progresStatus, setProgress] = useState(0);
  const [fltMenu, setFltMenu] = useState(false);
  const [driverMenu, setDriverMenu] = useState(false);
  const [selectedJobTitles, setSelectedJobTitle] = useState("");

  const convertJobTitleValuesInArray = (valuesInArray) => {
    let mainObj = [];
    let arrayValues = {};
    jobTitle.map((val, i) => {
      arrayValues[val.id] = val.name;
    });
    valuesInArray.map((value, key) => {
      mainObj[key] = { name: arrayValues[value], id: value };
    });
    setSelectedJobTitle(mainObj);
  };

  useEffect(() => {
    if (progress.cnd_work_job_title) {
      var valuesInArrayJobTitle = progress.cnd_work_job_title.split(",");
      convertJobTitleValuesInArray(valuesInArrayJobTitle, "jobTitle");
    }

    let jobTitleSortObject = Object.values(selectedJobTitles);
    let flt_menu, driver_menu;
    if (jobTitleSortObject) {
      jobTitleSortObject.map((item, index) => {
        // FLT License
        if (item.id == "forklift_truck_drivers_(all_categories)") {
          flt_menu = true;
        }
        // Driver License
        if (
          item.id ==
            convertToSnakeCase("HGV / 7.5 Tonne / Multi-Drop Drivers") ||
          item.id == convertToSnakeCase("HGV & Commercial Vehicle Technicians")
        ) {
          driver_menu = true;
        }
      });
    }
    setFltMenu(flt_menu);
    setDriverMenu(driver_menu);
    if (!localStorage.getItem("fltMenu") && flt_menu == true) {
      localStorage.setItem("fltMenu", true);
    }
    if (!localStorage.getItem("driverMenu") && driver_menu == true) {
      localStorage.setItem("driverMenu", true);
    }

    setFormStates({
      userProfile: progress.progress_bar_profile_1 ? true : false,
      employmentReferences: progress.progress_bar_reference_2 ? true : false,
      typesOfServices: userData.cnd_types_of_services !== "" ? true : false,
      agreements: progress.progress_bar_agreement_3 ? true : false,
      bankDetails: progress.progress_bar_bank_4 ? true : false,
      rightToWork: progress.progress_bar_right_to_work_5 ? true : false,
      workPreferences: progress.progress_bar_work_preference_6 ? true : false,
      documentsCompliance: progress.progress_bar_compliance_7 ? true : false,
      dbsCertificate: progress.progress_bar_dbs_8 ? true : false,
      identityDocument: progress.progress_bar_identity_9 ? true : false,
    });
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 10;
    }
    // Flt Menu
    if (fltMenu == true) {
      if (progress.cnd_flt_licenses_progress_bar == "1") {
        totalProgress = totalProgress + 5;
      }
    }
    // Driver Menu
    if (driverMenu == true) {
      if (progress.cnd_driver_details_progress_bar == "1") {
        totalProgress = totalProgress + 5;
      }
    }
    if (progress.progress_bar_reference_2 == "1") {
      if (fltMenu == true) {
        totalProgress = totalProgress + 5;
      } else {
        totalProgress = totalProgress + 10;
      }
    }
    if (users_category == "home care") {
      if (
        progress.cnd_types_of_services != null &&
        progress.cnd_types_of_services != ""
      ) {
        totalProgress = totalProgress + 10;
      }
    }
    if (progress.progress_bar_agreement_3 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_bank_4 == "1") {
      if (driverMenu == true) {
        totalProgress = totalProgress + 5;
      } else {
        totalProgress = totalProgress + 10;
      }
    }
    if (progress.progress_bar_right_to_work_5 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_work_preference_6 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_compliance_7 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_dbs_8 == "1") {
      if (
        users_category == "home care" &&
        userData.cnd_types_of_services !== ""
      ) {
        totalProgress = totalProgress + 10;
      } else {
        totalProgress = totalProgress + 15;
      }
    }
    if (progress.progress_bar_identity_9 == "1") {
      if (
        users_category == "home care" &&
        userData.cnd_types_of_services !== ""
      ) {
        totalProgress = totalProgress + 10;
      } else {
        totalProgress = totalProgress + 15;
      }
    }

    //Check Condition For Profile Completion
    // if (job_type == "permanentrequirement") {
    //   //This Condition for Permanent Job Requirement Candidates
    //   window.location.href = "/permanent-job-profile";
    // }
    /*else if(totalProgress != 100 && user_sign_signature == "" || totalProgress == 100 && user_sign_signature == "" || totalProgress != 100 && !user_sign_signature || totalProgress == 100 && !user_sign_signature){
      // alert(progress.progress_bar_profile_1);
      if(progress.progress_bar_profile_1 != "1" && progress.progress_bar_profile_1 != null){
        if(window.location.pathname != "/job-profile"){
          window.location.href = "/job-profile";
        }         
      }else if(progress.progress_bar_reference_2 != "1" && progress.progress_bar_reference_2 != null){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references"){
          history.push('/employment-and-professional-references')   
        }        
      }else if(users_category == "home care" && userData.cnd_types_of_services == "" ){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services"){
          history.push('/type-of-services')   
        }        
      }else if(progress.progress_bar_bank_4 != "1" && progress.progress_bar_bank_4 != null){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services" &&  window.location.pathname != "/bank-details"){
          history.push('/bank-details') 
        }
      }else if(progress.progress_bar_work_preference_6 != "1" && progress.progress_bar_work_preference_6 != null){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services" && window.location.pathname != "/bank-details" && window.location.pathname != "/work-prefrences"){
          history.push('/work-prefrences') 
        }        
      }else if(progress.progress_bar_right_to_work_5 != "1" && progress.progress_bar_right_to_work_5 != null){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services" && window.location.pathname != "/bank-details" && window.location.pathname != "/work-prefrences" && window.location.pathname != "/right-to-work" && !window.location.pathname.includes('create-right-to-work')){
          history.push('/create-right-to-work')
        }
      }else if(progress.progress_bar_compliance_7 != "1" && progress.progress_bar_compliance_7 != null){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services" && window.location.pathname != "/bank-details" && window.location.pathname != "/right-to-work" && !window.location.pathname.includes('create-right-to-work') && window.location.pathname != "/work-prefrences"&& window.location.pathname != "/document-required" && window.location.pathname != "/create-document-national-insurance" && window.location.pathname != "/create-document-address1" && window.location.pathname != "/create-document-address2" && window.location.pathname != "/create-document-training-certificate" && window.location.pathname != "/create-document-additional" && window.location.pathname != "/create-document-update-address"){
          history.push('/document-required') 
        }        
      }else if(progress.progress_bar_dbs_8 != "1" && progress.progress_bar_dbs_8 != null){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services" && window.location.pathname != "/bank-details" && window.location.pathname != "/right-to-work" && !window.location.pathname.includes('create-right-to-work') && window.location.pathname != "/work-prefrences"&& window.location.pathname != "/document-required" && window.location.pathname != "/create-document-national-insurance" && window.location.pathname != "/create-document-address1" && window.location.pathname != "/create-document-address2" && window.location.pathname != "/create-document-training-certificate" && window.location.pathname != "/create-document-additional" && window.location.pathname != "/create-document-update-address" && window.location.pathname != "/dbs-document" && window.location.pathname != "/create-dbs-document"){
          history.push('/create-dbs-document' ) 
        }
      }else if(progress.progress_bar_identity_9 != "1" && progress.progress_bar_identity_9 != null){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services" && window.location.pathname != "/bank-details" && window.location.pathname != "/right-to-work" && !window.location.pathname.includes('create-right-to-work') && window.location.pathname != "/work-prefrences"&& window.location.pathname != "/document-required" && window.location.pathname != "/create-document-national-insurance" && window.location.pathname != "/create-document-address1" && window.location.pathname != "/create-document-address2" && window.location.pathname != "/create-document-training-certificate" && window.location.pathname != "/create-document-additional" && window.location.pathname != "/create-document-update-address" && window.location.pathname != "/dbs-document" && window.location.pathname != "/create-dbs-document" && window.location.pathname != "/identity-document" && !window.location.pathname.includes('create-identity-document')){
          history.push('/create-identity-document') 
        }
      }else if(progress.progress_bar_agreement_3 != "1" && progress.progress_bar_agreement_3 != null){
        if(window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services" && window.location.pathname != "/bank-details" && window.location.pathname != "/right-to-work" && !window.location.pathname.includes('create-right-to-work') && window.location.pathname != "/work-prefrences"&& window.location.pathname != "/document-required" && window.location.pathname != "/create-document-national-insurance" && window.location.pathname != "/create-document-address1" && window.location.pathname != "/create-document-address2" && window.location.pathname != "/create-document-training-certificate" && window.location.pathname != "/create-document-additional" && window.location.pathname != "/create-document-update-address" && window.location.pathname != "/dbs-document" && window.location.pathname != "/create-dbs-document" && window.location.pathname != "/identity-document" && !window.location.pathname.includes('create-identity-document') && window.location.pathname != "/agreements"){
          history.push('/agreements') 
        }        
      }else*/ if (
      totalProgress == "100" &&
      !user_sign_signature &&
      (cndStatus == "In-Progress" || cndStatus == "Pending")
    ) {
      // if(progress.progress_bar_identity_9 == "1" && window.location.pathname != "/job-profile" && window.location.pathname != "/employment-and-professional-references" && window.location.pathname != "/type-of-services" && window.location.pathname != "/bank-details" && window.location.pathname != "/right-to-work" && !window.location.pathname.includes('create-right-to-work') && window.location.pathname != "/work-prefrences"&& window.location.pathname != "/document-required" && window.location.pathname != "/create-document-national-insurance" && window.location.pathname != "/create-document-address1" && window.location.pathname != "/create-document-address2" && window.location.pathname != "/create-document-training-certificate" && window.location.pathname != "/create-document-additional" && window.location.pathname != "/create-document-update-address" && window.location.pathname != "/dbs-document" && window.location.pathname != "/create-dbs-document" && window.location.pathname != "/identity-document" && !window.location.pathname.includes('create-identity-document') && window.location.pathname != "/agreements"){
      if (window.location.pathname == "/sign-contract") {
        swal({
          //checkProf:"true",
          title: "Candidate Signature Required - Please Sign the Contract.",
          icon: "info",
          text: "Please read the contract carefully and sign it digitally to complete the application.",
          //timer:"7000",
          dangerMode: true,
          buttons: {
            Ok: {
              checkProf: true,
            },
          },
          //buttons:false,
          closeOnClickOutside: true,
        }); /*.then((checkProf) => {
               history.push('/identity-document')
          });*/
      }
    }
    /*}else{
      let path = window.location.pathname;
      history.push(path)
    }*/
    setProgress(totalProgress);
  }, [progress, userData]);
  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    users_category,
    users_access_token,
    users_profile_pic,
    users_profile_img_path,
    user_sign_signature,
    job_type,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "135px";
      imageRef.current.style.height = "135px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };

  const scrollNavTab = (page) => {
    const element = document.getElementById(page);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
    if (page) {
      scrollNavTab(page);
    }
  }, [props.userData, page]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };

  const documentsRequiredCall = () => {
    let urlRed;
    if (
      progress.progress_bar_compliance_7 == null ||
      progress.progress_bar_compliance_7 == 0 ||
      progress.progress_bar_compliance_7 == "0"
    ) {
      if (!progress.cnd_compliance_doc_master_id) {
        urlRed = "/create-document-address1";
      } else if (
        progress.cnd_compliance_doc_master_id &&
        progress.cnd_compliance_doc_master_id == "address1"
      ) {
        urlRed = "/create-document-address2";
      } else if (
        progress.cnd_compliance_doc_master_id &&
        progress.cnd_compliance_doc_master_id == "address2"
      ) {
        urlRed = "/create-document-national-insurance";
      } else {
        urlRed = "/document-required";
      }
    }
    redirectUrl(urlRed ? urlRed : "/document-required");
  };

  // Modal Function - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // Modal Function - End
  // sticky-top
  //if(user_sign_signature == ""){
  //window.location.href = "/identity-document";
  //}
  //else{
  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      //   if (isScrolling == false) {
      scrollNavTab(page);
      // Clear the previous timeout if it's still running
      if (timeoutId) clearTimeout(timeoutId);

      // Set isScrolling to true when scroll event is detected
      setIsScrolling(true);

      // Set a timeout to change isScrolling to false after scrolling stops
      timeoutId = setTimeout(() => {
        // Check if the page is at the top or bottom
        const atTop = window.scrollY === 0;
        const atBottom =
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight;

        // if (!atTop && !atBottom) {
        //   setIsScrolling(false);
        // }
        setIsScrolling(touchMove == "true" ? true : false);
        // console.log("Touch Move:=> " + touchMove);
      }, 6000); // Adjust the timeout as needed
      //   }
    };

    window.addEventListener("scroll", handleScroll);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [touchMove]);
  return (
    <div className="col-xl-3 col-lg-4 m-b0">
      <div className="wrapper">
        <div className="candidate-info">
          <div className="candidate-detail text-center">
            <div
              className={
                "canditate-des " +
                (users_profile_img_path && users_profile_pic ? "" : "bg-dark")
              }
            >
              <CircularProgressbarWithChildren
                className="CircularProgressbar-background"
                value={progresStatus}
              >
                {users_profile_img_path && users_profile_pic ? (
                  <img
                    className="size-thumbnail1 rounded-circle cursor-pointer"
                    width={"135px"}
                    height={"135px"}
                    ref={imageRef}
                    onClick={() => {
                      if (users_profile_img_path && users_profile_pic) {
                        viewFileModal(
                          constants.IMAGE_URL +
                            users_profile_img_path +
                            users_profile_pic
                        );
                      } else {
                        Swal.fire({
                          title: "Profile Picture Not Available",
                          text: "",
                          icon: "warning",
                          showCancelButton: false,
                          confirmButtonText: "Ok",
                          // confirmButtonColor: 'blue'
                        });
                      }
                    }}
                    src={require("./../../images/team/pic1.jpg")}
                  />
                ) : (
                  <div className="mt-4 text-white">
                    <h1 style={{ fontSize: "60px" }}>
                      {getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) &&
                      getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) != "UU" ? (
                        getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        )
                      ) : (
                        <i
                          class="fa fa-spinner text-center fa-pulse text-white"
                          style={{ fontSize: "80px" }}
                        ></i>
                      )}
                    </h1>
                  </div>
                )}
              </CircularProgressbarWithChildren>
              <div style={{ fontSize: 18, marginTop: 5 }}>
                <strong>{progresStatus}%</strong> Completed
              </div>
              <div
                className="upload-link border"
                title="Upload Image"
                data-toggle="tooltip"
                data-placement="right"
                style={{ marginTop: "65%" }}
              >
                <input
                  type="file"
                  className="update-flie"
                  id="users_profile_pic"
                  // onChange={(e) => onChangeInputvalue(e)}
                  accept=".png,.jpeg,.jpg"
                />
                <i className="fa fa-camera"></i>
              </div>
            </div>

            <div className="candidate-title">
              <div className="">
                <h4 className="m-b0">
                  <b>{users_firstname}</b>
                </h4>
                <p className="m-b0">
                  <b>
                    {props.userAuth.users_category
                      ? capitalizeFirstLetter(
                          String(props.userAuth.users_category)
                        )
                      : ""}
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div
            className=""
            onTouchStart={() => setTouchMove("true")}
            onTouchEnd={() => setTouchMove("false")}
          >
            {isScrolling == true && (
              <Box ref={ref}>
                <Paper
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    overflowX: "auto",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    opacity: 1,
                    zIndex: 10,
                  }}
                  elevation={3}
                >
                  <Box
                    sx={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      overflowX: "auto",
                    }}
                  >
                    <BottomNavigation
                      showLabels
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      onMouseEnter={() => setIsScrolling(true)}
                    >
                      <BottomNavigationAction
                        label={
                          String("Dashboard").length <= 10
                            ? String("Dashboard")
                            : String("Dashboard").slice(0, 10) + "..."
                        }
                        icon={
                          <>
                          {progresStatus == 100 && user_sign_signature && cndStatus == "Approved" ? (
                            <>
                            {myProfileMenu == true ? (
                              <i
                                className="fa fa-chevron-down"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-chevron-up"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                }}
                              ></i>
                            )}
                            </>
                          ) : (
                            <i
                              className="fa fa-bar-chart"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                          )}
                          </>
                        }
                        // className={page == "profile" ? "bg-dark text-white" : ""}
                        // onClick={() => redirectUrl("/dashboard")}
                        onClick={() => {
                          if(progresStatus == 100 && user_sign_signature && cndStatus == "Approved"){                    
                            setMyProfileMenu(true);
                          }else{
                            redirectUrl('/dashboard');
                          }
                        }}
                      />

                      <BottomNavigationAction
                        label={
                          String("My Profile").length <= 10
                            ? String("My Profile")
                            : String("My Profile").slice(0, 10) + "..."
                        }
                        id="job-profile"
                        icon={
                          <>
                            <i
                              className="fa fa-user-circle"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                progress.progress_bar_profile_1 == 1 ||
                                progress.progress_bar_profile_1
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      backgroundColor: "10px",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "profile" ? "bg-dark text-white" : ""
                        }
                        onClick={() => redirectUrl("/job-profile")}
                      />

                      <BottomNavigationAction
                        label={
                          String("Work Preferences").length <= 10
                            ? String("Work Preferences")
                            : String("Work Preferences").slice(0, 10) + "..."
                        }
                        id="work-prefrences"
                        icon={
                          <>
                            <i
                              className="fa fa-object-ungroup"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                formStates.workPreferences
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      backgroundColor: "10px",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "work-prefrences"
                            ? "bg-dark text-white"
                            : ""
                        }
                        onClick={() => redirectUrl("/work-prefrences")}
                      />

                      {/* FLT License - Start */}
                      {fltMenu == true && (
                        <BottomNavigationAction
                          label={
                            String("FLT License").length <= 10
                              ? String("FLT License")
                              : String("FLT License").slice(0, 10) + "..."
                          }
                          id="flt-license"
                          icon={
                            <>
                              <i
                                className="fa fa-cubes"
                                style={{ fontSize: "15px", fontWeight:"500" }}
                              ></i>
                              <i
                                className="fa fa-check-square text-success"
                                style={
                                  progress.cnd_flt_licenses_progress_bar == "1"
                                    ? {
                                        display: "inline-block",
                                        borderRadius: "50%",
                                        backgroundColor: "10px",
                                        fontSize: "10px",
                                      }
                                    : { display: "none" }
                                }
                                aria-hidden="true"
                              ></i>
                            </>
                          }
                          className={
                            page == "flt-license" ? "bg-dark text-white" : ""
                          }
                          onClick={() => redirectUrl("/flt-license")}
                        />
                      )}
                      {/* FLT License - End */}

                      {/* Driver Details - Start */}
                      {driverMenu == true && (
                        <BottomNavigationAction
                          label={
                            String("Driver Details").length <= 10
                              ? String("Driver Details")
                              : String("Driver Details").slice(0, 10) + "..."
                          }
                          id="driver-details"
                          icon={
                            <>
                              <i
                                className="fa fa-car"
                                style={{ fontSize: "15px", fontWeight:"500" }}
                              ></i>
                              <i
                                className="fa fa-check-square text-success"
                                style={
                                  progress.cnd_driver_details_progress_bar ==
                                  "1"
                                    ? {
                                        display: "inline-block",
                                        borderRadius: "50%",
                                        backgroundColor: "10px",
                                        fontSize: "10px",
                                      }
                                    : { display: "none" }
                                }
                                aria-hidden="true"
                              ></i>
                            </>
                          }
                          className={
                            page == "driver-details"
                              ? "bg-dark text-white"
                              : ""
                          }
                          onClick={() => redirectUrl("/driver-details")}
                        />
                      )}
                      {/* Driver Details - End */}

                      <BottomNavigationAction
                        label={
                          String("Employment and Professional Reference")
                            .length <= 10
                            ? String("Employment and Professional Reference")
                            : String(
                                "Employment and Professional Reference"
                              ).slice(0, 10) + "..."
                        }
                        id="emplyement-references"
                        icon={
                          <>
                            <i
                              className="fa fa-briefcase"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                formStates.employmentReferences
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "emplyement-references"
                            ? "bg-dark text-white"
                            : ""
                        }
                        onClick={() =>
                          redirectUrl("/employment-and-professional-references")
                        }
                      />
                      {users_category === "home care" && (
                        <BottomNavigationAction
                          label={
                            String("Type of Services").length <= 10
                              ? String("Type of Services")
                              : String("Type of Services").slice(0, 10) + "..."
                          }
                          id="type-of-services"
                          icon={
                            <>
                              <i
                                className="fa fa-plus-square"
                                style={{ fontSize: "15px", fontWeight:"500" }}
                              ></i>
                              <i
                                className="fa fa-check-square text-success"
                                style={
                                  formStates.typesOfServices &&
                                  progress.cnd_types_of_services != null
                                    ? {
                                        display: "inline-block",
                                        borderRadius: "50%",
                                        fontSize: "10px",
                                      }
                                    : { display: "none" }
                                }
                                aria-hidden="true"
                              ></i>
                            </>
                          }
                          className={
                            page == "type-of-services"
                              ? "bg-dark text-white"
                              : ""
                          }
                          onClick={() => redirectUrl("/type-of-services")}
                        />
                      )}

                      <BottomNavigationAction
                        label={
                          String("Bank Details").length <= 10
                            ? String("Bank Details")
                            : String("Bank Details").slice(0, 10) + "..."
                        }
                        id="bank-details"
                        icon={
                          <>
                            <i
                              className="fa fa-file-text"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                formStates.bankDetails
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "bank-details" ? "bg-dark text-white" : ""
                        }
                        onClick={() => redirectUrl("/bank-details")}
                      />

                      <BottomNavigationAction
                        label={
                          String("Right To Work").length <= 10
                            ? String("Right To Work")
                            : String("Right To Work").slice(0, 10) + "..."
                        }
                        id="right-to-work"
                        icon={
                          <>
                            <i
                              className="fa fa-check-circle"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                formStates.rightToWork
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "right-to-work" ? "bg-dark text-white" : ""
                        }
                        onClick={() =>
                          redirectUrl(
                            progress.progress_bar_right_to_work_5
                              ? "/right-to-work"
                              : "/create-right-to-work"
                          )
                        }
                      />

                      <BottomNavigationAction
                        label={
                          String("Documents Required").length <= 10
                            ? String("Documents Required")
                            : String("Documents Required").slice(0, 10) + "..."
                        }
                        id="document-required"
                        icon={
                          <>
                            <i
                              className="fa fa-id-card"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                progress.progress_bar_compliance_7 == "1"
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "document-required"
                            ? "bg-dark text-white"
                            : ""
                        }
                        onClick={documentsRequiredCall}
                      />

                      <BottomNavigationAction
                        label={
                          String("DBS Certificate").length <= 10
                            ? String("DBS Certificate")
                            : String("DBS Certificate").slice(0, 10) + "..."
                        }
                        id="dbs-document"
                        icon={
                          <>
                            <i
                              className="fa fa-list-alt"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                progress.progress_bar_dbs_8 == "1"
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "dbs-document" ? "bg-dark text-white" : ""
                        }
                        onClick={() =>
                          redirectUrl(
                            progress.progress_bar_dbs_8 == "1"
                              ? "/dbs-document"
                              : "/create-dbs-document"
                          )
                        }
                      />

                      <BottomNavigationAction
                        label={
                          String("Identity Documents").length <= 10
                            ? String("Identity Documents")
                            : String("Identity Documents").slice(0, 10) + "..."
                        }
                        id="identity-document"
                        icon={
                          <>
                            <i
                              className="fa fa-id-badge"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                formStates.identityDocument
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "identity-document"
                            ? "bg-dark text-white"
                            : ""
                        }
                        onClick={() =>
                          redirectUrl(
                            progress.progress_bar_identity_9 == "1"
                              ? "/identity-document"
                              : "/create-identity-document"
                          )
                        }
                      />

                      <BottomNavigationAction
                        label={
                          String("Agreements").length <= 10
                            ? String("Agreements")
                            : String("Agreements").slice(0, 10) + "..."
                        }
                        id="agreements"
                        icon={
                          <>
                            <i
                              className="fa fa-print"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                progress.progress_bar_agreement_3 == "1"
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "agreements" ? "bg-dark text-white" : ""
                        }
                        onClick={() => redirectUrl("/agreements")}
                      />

                      <BottomNavigationAction
                        label={
                          String("Sign Contract").length <= 10
                            ? String("Sign Contract")
                            : String("Sign Contract").slice(0, 10) + "..."
                        }
                        id="sign-contract"
                        icon={
                          <>
                            <i
                              className="fa fa-pencil-square-o"
                              style={{ fontSize: "15px", fontWeight:"500" }}
                            ></i>
                            <i
                              className="fa fa-check-square text-success"
                              style={
                                user_sign_signature
                                  ? {
                                      display: "inline-block",
                                      borderRadius: "50%",
                                      fontSize: "10px",
                                    }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                            ></i>
                          </>
                        }
                        className={
                          page == "sign-contract" ? "bg-dark text-white" : ""
                        }
                        onClick={() => redirectUrl("/sign-contract")}
                      />
                    </BottomNavigation>
                  </Box>
                </Paper>
              </Box>
            )}
          </div>
        </div>
      </div>
      <SwipeableTemporaryDrawer
          drawerMenuList={menuList}
          drawerDirection={"top"}
          drawerState={myProfileMenu}
          onDrawerStateChange={handleDrawerStateChange}
        />
      {fileUrl && (
        <>
          <GlobalViewFileModal
            modalOption={modalOption}
            closeFileModal={closeFileModal}
            fileUrl={fileUrl}
            loaderEnd={loaderEnd}
            modalHeight={modalHeight}
            callModal={callModal}
            title={"Profile Picture"}
            // fileName={fileName}
          />
        </>
      )}
    </div>
  );
};
//}

const successToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(Profilesidebar);
