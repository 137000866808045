import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import UserProfile from "../../../containers/UserProfile";
import { updateUserProfile } from "../../../redux/actions/UserProfileActions";
import AddressFields from "./AddressFields";

import { santetizeData } from "../../../helpers/FormFieldsSanetizations";
import { getBase64 } from "../../../helpers/FileToBase64";
import {
  CandidatePersonalInfoValidation,
  validateStringForSpecialCharacter,
} from "../../../helpers/PersonalInfoCandidateValidation";
// import TrainingQualifications from "../TrainingQualifications";
import { toast } from "react-toastify";
import { redirectUrl } from "../../../services/GlobalUrls";
const DocFormUpdateAddress = (props) => {
  const dispatch = useDispatch();
  const [userdata, setFormdata] = useState(props.userData);
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const history = useHistory();
  const { loader } = props;
  const onChangeInputvalue = async (e) => {
    if (e.target.name === "cnd_compliance_upload_file") {
      if (e.target.files[0])
        userdata["cnd_compliance_upload_file"] = await getBase64(
          e.target.files[0]
        );
    } else {
      if (e.target.value) userdata[e.target.name] = e.target.value;
      else userdata[e.target.name] = "";
    }
    //validateDataBeforeSubmit(e.target.name);
    validateDataBeforeSubmit("submit");
    setFormdata(userdata);
  };

  const validateDataBeforeSubmit = (type) => {
    let validations = CandidatePersonalInfoValidation(userdata, type, errors);
    setErrors(validations.stateData);
    return validations.error;
  };
  const updateUserProfilePersonalInfo = (e) => {
    e.preventDefault();
    userdata["cnd_street_address"] = e.target[3].value;
    userdata["cnd_street_address_2"] = e.target[4].value;
    userdata["cnd_town"] = e.target[5].value;
    userdata["cnd_region"] = e.target[6].value;
    userdata["cnd_postcode"] = e.target[7].value;

    let cnd_compliance_upload_file = document.getElementById(
      "cnd_compliance_upload_file"
    );
    let cnd_compliance_reference = document.getElementById(
      "cnd_compliance_reference"
    );
    let cnd_compliance_description = document.getElementById(
      "cnd_compliance_description"
    );
    let cnd_street_address = document.getElementById("cnd_street_address");
    let cnd_town = document.getElementById("cnd_town");
    let cnd_postcode = document.getElementById("cnd_postcode");

    if (!cnd_compliance_upload_file.value) {
      cnd_compliance_upload_file.focus();
    } else if (
      cnd_compliance_reference.value &&
      (!validateStringForSpecialCharacter(cnd_compliance_reference.value) ||
        cnd_compliance_reference.value.trim() == "")
    ) {
      cnd_compliance_reference.focus();
    } else if (
      !cnd_compliance_description.value ||
      (cnd_compliance_description.value &&
        cnd_compliance_description.value.trim() == "")
    ) {
      cnd_compliance_upload_file.scrollIntoView({ behavior: "smooth" }, true);
      cnd_compliance_description.focus();
    } else if (!cnd_street_address.value) {
      cnd_street_address.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    } else if (!cnd_town.value) {
      cnd_town.focus();
    } else if (!cnd_postcode.value) {
      cnd_postcode.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    } else if (
      !String(cnd_postcode.value).match(
        /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
      )
    ) {
      cnd_postcode.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    }

    if (!validateDataBeforeSubmit("submit")) {
      document.getElementById("loader").style.display = "block";
      userdata["users_access_token"] = props.userAuth.users_access_token;
      userdata["cnd_compliance_doc_master_id"] = "updateaddress";
      dispatch(
        updateUserProfile(santetizeData(userdata), "UpdatePersonalInfo")
      );
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    setFormdata(props.userData);
  }, [props.userData]);

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    // if (deviceType == "Mobile" || deviceType == "Tablet") {
    //   window.scrollTo(0, 900);
    // }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Update Address
          </h5>
          {/* <Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link> */}
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => updateUserProfilePersonalInfo(e)}
          noValidate
          encType="multipart/form-data"
        >
          <div className="row m-b30">
            <AddressFields
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            />
          </div>
          <Button
            disabled={loader}
            className="site-button m-b30 pull-right btn btn-primary"
            type="submit"
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader ? "Update" : ""}
          </Button>
          <Button
            disabled={loader}
            className="site-button yellow m-b30 float-right mr-2 "
            onClick={() => redirectUrl("/document-required")}
            // href={"/document-required"}
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                //animation="border"
              />
            )}
            {"Back"}
          </Button>
          <br />
          <br />
        </form>
      </div>
      {/* <TrainingQualifications /> */}
    </React.Fragment>
  );
};

export default UserProfile(DocFormUpdateAddress);
