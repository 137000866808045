import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function InstallButton() {
    const [open, setOpen] = useState(false);  // Control dialog visibility
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      // Check if the user has already been prompted
      const hasVisited = localStorage.getItem('hasVisited');
      if (!hasVisited) {
        window.addEventListener('beforeinstallprompt', (e) => {
          // Prevent the mini-infobar from appearing on mobile
          e.preventDefault();
          // Store the event for later use
          setDeferredPrompt(e);
          setIsVisible(true);
          setOpen(true);  // Open dialog on first visit
          localStorage.setItem('hasVisited', 'true'); // Mark as visited
        });
      }
  
      return () => {
        window.removeEventListener('beforeinstallprompt', () => {}); // Clean up event listener
      };
    }, []);
  
    const handleInstallClick = () => {
      setOpen(false);
      if (deferredPrompt) {
        // Show the install prompt
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          setDeferredPrompt(null); // Reset the deferred prompt
          setIsVisible(false); // Hide the dialog
        });
      }
    };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleInstallClick}>
        Install
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        message="Staffing Connect Web App"
        action={action}
      />
    </div>
  );
}