import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import constants from "../../../constants";
import Skeleton from "@mui/material/Skeleton";
import Swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import UserProfile from "../../../containers/UserProfile";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import { redirectUrl } from "../../../services/GlobalUrls";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import {
  Autocomplete,
  Avatar,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import { getBase64 } from "../../../helpers/FileToBase64";
import { updateUserProfile } from "../../../redux/actions/UserProfileActions";
import { santetizeData } from "../../../helpers/FormFieldsSanetizations";
import { Button, Modal, Spinner } from "react-bootstrap";
import Input from "../../../components/FormElements/Input";
import CloseIcon from "@mui/icons-material/Close";
import {
  jobWorkPostionTitles,
  jobWorkSkillsDropList,
} from "../../../services/JobPositionTitles";
import Label from "../../../components/Layout/Label";
import Dropdown from "../../../components/FormElements/Dropdown";
import {
  jobEmploymentStatusDropList,
  jobNoticePeriodDropList,
  jobsEmploymentHoursDropList,
  jobsSalaryTypeDropList,
} from "../../../services/JobTypeAndSectorGlobalList";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Countries from "../../../components/Element/Countries";
import { globalCountriesDropList } from "../../../services/GlobalCountriesList";
import Multiselect from "multiselect-react-dropdown";
import { convertToOriginalForm } from "../../../services/StringOperation";
import Textarea from "../../../components/FormElements/Textarea";
import { getDataOnRequest } from "../../../redux/actions/CommonActions";
import { printSkeleton } from "../../../services/GlobalSkeleton";

const CandidateFullDetailsForm = ({ callParam, ...props }) => {
  const [userdata, setFormdata] = useState(props.userData);
  const [completeFormData, setCompleteFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [jobWorkPositionList, setJobWorkPositionList] = useState(false);
  const [jobSkillsList, setJobSkillsList] = useState(false);
  const { loader } = props;
  const [selectedValues, setSelectedValues] = useState("");
  const [removeValues, setRemoveValues] = useState("");

  const [selectedValuesCheck, setSelectedValuesCheck] = useState(false);
  const [selectedDesiredJobtitle, setSelectedDesiredJobtitle] = useState("");
  const [selectedDesiredJobtitleCheck, setSelectedDesiredJobtitleCheck] =
    useState(false);
  const [selectedSkillsArray, setSelectedSkillsArray] = useState([]);
  const [updateCallType, setUpdateCallType] = useState("update_all");

  const dispatch = useDispatch();

  // Work Experience Multiple - Start
  const formIdsRefWorkExperience = useRef(null);
  const formIdsRefQualification = useRef(null);
  const [inputFieldsWorkExperience, setInputFieldsWorkExperience] = useState([
    {
      work_experience_job_title: "",
      work_experience_company_name: "",
      work_experience_from_date: "",
      work_experience_to_date: "",
      work_experience_description: "",
    },
  ]);

  const [
    inputFieldsQualificationExperience,
    setInputFieldsQualificationExperience,
  ] = useState([
    {
      qualification_type: "",
      qualification_institute_name: "",
      qualification_from_date: "",
      qualification_to_date: "",
      qualification_description: "",
    },
  ]);

  const handleAddFields = (addFormType) => {
    if (addFormType == "educationReference") {
      const values = [...inputFieldsQualificationExperience];
      values.push({
        qualification_type: "",
        qualification_institute_name: "",
        qualification_from_date: "",
        qualification_to_date: "",
        qualification_description: "",
      });
      setInputFieldsQualificationExperience(values);
    } else if (addFormType == "employementReference") {
      const values = [...inputFieldsWorkExperience];
      values.push({
        work_experience_job_title: "",
        work_experience_company_name: "",
        work_experience_from_date: "",
        work_experience_to_date: "",
        work_experience_description: "",
      });
      setInputFieldsWorkExperience(values);
    }
  };

  const handleRemoveFields = (index, removeType) => {
    if (removeType == "educationReference") {
      const values = [...inputFieldsQualificationExperience];
      values.splice(index, 1);
      setInputFieldsQualificationExperience(values);
    } else if (removeType == "employementReference") {
      const values = [...inputFieldsWorkExperience];
      values.splice(index, 1);
      setInputFieldsWorkExperience(values);
    }
  };

  const handleChangeInput = (index, event, inputDataType) => {
    validCompleteForm(event.target.id);
    if (inputDataType == "educationReference") {
      const values = [...inputFieldsQualificationExperience];
      values[index][event.target.name] = event.target.value;
      setInputFieldsQualificationExperience(values);
    } else if (inputDataType == "employementReference") {
      const values = [...inputFieldsWorkExperience];
      values[index][event.target.name] = event.target.value;
      setInputFieldsWorkExperience(values);
    }
  };
  // Work Experience Multiple - End

  useEffect(() => {
    // Loader - Start
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, []);

  const validCompleteForm = (callType) => {
    let applyError = false;

    if (
      callType == "submit" ||
      callType == "cnd_street_address" ||
      callType == "residential_details"
    ) {
      let cnd_street_address = document.getElementById("cnd_street_address");
      let cnd_street_address_msg = document.getElementById(
        "cnd_street_address_msg"
      );

      //Set Data - Start
      if (cnd_street_address) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_street_address.name]: cnd_street_address.value,
        });
      }
      // Set Data - End

      if (cnd_street_address.value && cnd_street_address.value.trim() == "") {
        cnd_street_address.classList.add("border-danger");
        cnd_street_address_msg.innerText =
          "Please enter the valid address line 1!";
        if (applyError == false && callType == "submit") {
          cnd_street_address.focus();
        }
        applyError = true;
      } else {
        cnd_street_address.classList.remove("border-danger");
        cnd_street_address_msg.innerText = "";
      }
    }
    // Address Line 2
    if (
      callType == "submit" ||
      callType == "cnd_street_address_2" ||
      callType == "residential_details"
    ) {
      let cnd_street_address_2 = document.getElementById(
        "cnd_street_address_2"
      );
      let cnd_street_address_2_msg = document.getElementById(
        "cnd_street_address_2_msg"
      );

      //Set Data - Start
      console.log("Address 2 Value :=> " + cnd_street_address_2.value);
      if (cnd_street_address_2) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_street_address_2.name]: cnd_street_address_2.value,
        });
      }
      // Set Data - End

      if (
        cnd_street_address_2.value &&
        cnd_street_address_2.value.trim() == ""
      ) {
        cnd_street_address_2.classList.add("border-danger");
        cnd_street_address_2_msg.innerText =
          "Please enter the valid address line 2!";
        if (applyError == false) {
          cnd_street_address_2.focus();
        }
        applyError = true;
      } else {
        cnd_street_address_2.classList.remove("border-danger");
        cnd_street_address_2_msg.innerText = "";
      }
    }
    // Town
    if (
      callType == "submit" ||
      callType == "cnd_town" ||
      callType == "residential_details"
    ) {
      let cnd_town = document.getElementById("cnd_town");
      let cnd_town_msg = document.getElementById("cnd_town_msg");

      //Set Data - Start
      if (cnd_town) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_town.name]: cnd_town.value,
        });
      }
      // Set Data - End

      if (cnd_town.value && cnd_town.value.trim() == "") {
        cnd_town.classList.add("border-danger");
        cnd_town_msg.innerText = "Please enter the valid town!";
        if (applyError == false) {
          cnd_town.focus();
        }
        applyError = true;
      } else {
        cnd_town.classList.remove("border-danger");
        cnd_town_msg.innerText = "";
      }
    }
    // Region
    if (
      callType == "submit" ||
      callType == "cnd_region" ||
      callType == "residential_details"
    ) {
      let cnd_region = document.getElementById("cnd_region");
      let cnd_region_msg = document.getElementById("cnd_region_msg");
      //Set Data - Start
      if (cnd_region) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_region.name]: cnd_region.value,
        });
      }
      // Set Data - End
      if (cnd_region.value && cnd_region.value.trim() == "") {
        cnd_region.classList.add("border-danger");
        cnd_region_msg.innerText = "Please enter the valid region!";
        if (applyError == false) {
          cnd_region.focus();
        }
        applyError = true;
      } else {
        cnd_region.classList.remove("border-danger");
        cnd_region_msg.innerText = "";
      }
    }
    // Postal Code
    if (
      callType == "submit" ||
      callType == "cnd_postcode" ||
      callType == "residential_details"
    ) {
      let cnd_postcode = document.getElementById("cnd_postcode");
      let cnd_postcode_msg = document.getElementById("cnd_postcode_msg");
      //Set Data - Start
      if (cnd_postcode) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_postcode.name]: cnd_postcode.value,
        });
      }
      // Set Data - End
      if (
        cnd_postcode.value &&
        (cnd_postcode.value.trim() == "" ||
          !String(cnd_postcode.value).match(
            /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
          ))
      ) {
        cnd_postcode.classList.add("border-danger");
        cnd_postcode_msg.innerText = "Please enter the valid UK post code!";
        if (applyError == false) {
          cnd_postcode.focus();
        }
        applyError = true;
      } else {
        cnd_postcode.classList.remove("border-danger");
        cnd_postcode_msg.innerText = "";
      }
    }
    // Residential Address - End

    // Looking for - Start
    // Desired Job title
    if (
      callType == "submit" ||
      callType == "cnd_desired_job_title" ||
      callType == "looking_for_details"
    ) {
      let cnd_desired_job_title = document.getElementById(
        "cnd_desired_job_title"
      );
      let cnd_desired_job_title_msg = document.getElementById(
        "cnd_desired_job_title_msg"
      );
      //Set Data - Start
      if (cnd_desired_job_title) {
        setCompleteFormData({
          ...completeFormData,
          ["cnd_desired_job_title"]: cnd_desired_job_title.value
            ? cnd_desired_job_title.value
            : "",
        });
      }
      // Set Data - End
      if (
        !cnd_desired_job_title.value ||
        cnd_desired_job_title.value.trim() == ""
      ) {
        // setSelectedDesiredJobtitleCheck(true);
        cnd_desired_job_title.classList.add("border-danger");
        cnd_desired_job_title_msg.innerText =
          "Please enter the valid desired job title!";
        if (
          applyError == false &&
          (callType == "submit" || callType == "looking_for_details")
        ) {
          cnd_desired_job_title.focus();
        }
        applyError = true;
      } else {
        // setSelectedDesiredJobtitleCheck(false);
        cnd_desired_job_title.classList.remove("border-danger");
        cnd_desired_job_title_msg.innerText = "";
      }
    }

    // Salary Amount
    if (
      callType == "submit" ||
      callType == "cnd_job_salary_amount" ||
      callType == "looking_for_details"
    ) {
      let cnd_job_salary_amount = document.getElementById(
        "cnd_job_salary_amount"
      );
      let cnd_job_salary_amount_msg = document.getElementById(
        "cnd_job_salary_amount_msg"
      );

      //Set Data - Start
      if (cnd_job_salary_amount) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_job_salary_amount.name]: cnd_job_salary_amount.value,
        });
      }
      // Set Data - End

      if (
        !cnd_job_salary_amount.value ||
        cnd_job_salary_amount.value.trim() == ""
      ) {
        cnd_job_salary_amount.classList.add("border-danger");
        cnd_job_salary_amount_msg.innerText =
          "Please enter the expected salary amount!";
        if (
          applyError == false &&
          (callType == "submit" || callType == "looking_for_details")
        ) {
          cnd_job_salary_amount.focus();
        }
        applyError = true;
      } else if (
        cnd_job_salary_amount.value &&
        !String(cnd_job_salary_amount.value).match(/^\d+(?:\.\d{1,2})?$/)
      ) {
        cnd_job_salary_amount.classList.add("border-danger");
        cnd_job_salary_amount_msg.innerText =
          "Please enter the valid salary amount in number!";
        if (
          applyError == false &&
          (callType == "submit" || callType == "looking_for_details")
        ) {
          cnd_job_salary_amount.focus();
        }
        applyError = true;
      } else {
        cnd_job_salary_amount.classList.remove("border-danger");
        cnd_job_salary_amount_msg.innerText = "";
      }
    }

    // Salary Type
    if (
      callType == "submit" ||
      callType == "cnd_job_salary_type" ||
      callType == "looking_for_details"
    ) {
      let cnd_job_salary_type = document.getElementById("cnd_job_salary_type");
      let cnd_job_salary_type_msg = document.getElementById(
        "cnd_job_salary_type_msg"
      );

      //Set Data - Start
      if (cnd_job_salary_type) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_job_salary_type.name]: cnd_job_salary_type.value,
        });
      }
      // Set Data - End

      if (
        !cnd_job_salary_type.value ||
        cnd_job_salary_type.value.trim() == ""
      ) {
        cnd_job_salary_type.classList.add("border-danger");
        cnd_job_salary_type_msg.innerText = "Please select the salary type!";
        if (
          applyError == false &&
          (callType == "submit" || callType == "looking_for_details")
        ) {
          cnd_job_salary_type.focus();
        }
        applyError = true;
      } else {
        cnd_job_salary_type.classList.remove("border-danger");
        cnd_job_salary_type_msg.innerText = "";
      }
    }

    // Job prefered location
    if (
      callType == "submit" ||
      callType == "cnd_job_prefered_location" ||
      callType == "looking_for_details"
    ) {
      let cnd_job_prefered_location = document.getElementById(
        "cnd_job_prefered_location"
      );
      let cnd_job_prefered_location_msg = document.getElementById(
        "cnd_job_prefered_location_msg"
      );

      //Set Data - Start
      if (cnd_job_prefered_location) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_job_prefered_location.name]: cnd_job_prefered_location.value,
        });
      }
      // Set Data - End

      if (
        cnd_job_prefered_location.value &&
        cnd_job_prefered_location.value.trim() == ""
      ) {
        cnd_job_prefered_location.classList.add("border-danger");
        cnd_job_prefered_location_msg.innerText =
          "Please enter the valid job prefered location!";
        if (
          applyError == false &&
          (callType == "submit" || callType == "looking_for_details")
        ) {
          cnd_job_prefered_location.focus();
        }
        applyError = true;
      } else {
        cnd_job_prefered_location.classList.remove("border-danger");
        cnd_job_prefered_location_msg.innerText = "";
      }
    }
    // Looking for - End

    // Status and Availability - Start
    // Employment Status
    if (
      callType == "submit" ||
      callType == "cnd_job_employment_status" ||
      callType == "status_and_availability"
    ) {
      let cnd_job_employment_status = document.getElementById(
        "cnd_job_employment_status"
      );
      let cnd_job_employment_status_msg = document.getElementById(
        "cnd_job_employment_status_msg"
      );

      //Set Data - Start
      if (cnd_job_employment_status) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_job_employment_status.name]: cnd_job_employment_status.value,
        });
      }
      // Set Data - End

      if (
        cnd_job_employment_status.value &&
        cnd_job_employment_status.value.trim() == ""
      ) {
        cnd_job_employment_status.classList.add("border-danger");
        cnd_job_employment_status_msg.innerText =
          "Please enter the valid job prefered location!";
        if (
          applyError == false &&
          (callType == "submit" || callType == "status_and_availability")
        ) {
          cnd_job_employment_status.focus();
        }
        applyError = true;
      } else {
        cnd_job_employment_status.classList.remove("border-danger");
        cnd_job_employment_status_msg.innerText = "";
      }
    }
    // Notice Period
    if (
      callType == "submit" ||
      callType == "cnd_job_notice_period" ||
      callType == "status_and_availability"
    ) {
      let cnd_job_notice_period = document.getElementById(
        "cnd_job_notice_period"
      );
      let cnd_job_notice_period_msg = document.getElementById(
        "cnd_job_notice_period_msg"
      );

      //Set Data - Start
      if (cnd_job_notice_period) {
        setCompleteFormData({
          ...completeFormData,
          [cnd_job_notice_period.name]: cnd_job_notice_period.value,
        });
      }
      // Set Data - End

      if (
        cnd_job_notice_period.value &&
        cnd_job_notice_period.value.trim() == ""
      ) {
        cnd_job_notice_period.classList.add("border-danger");
        cnd_job_notice_period_msg.innerText =
          "Please enter the valid job prefered location!";
        if (
          applyError == false &&
          (callType == "submit" || callType == "status_and_availability")
        ) {
          cnd_job_notice_period.focus();
        }
        applyError = true;
      } else {
        cnd_job_notice_period.classList.remove("border-danger");
        cnd_job_notice_period_msg.innerText = "";
      }
    }
    // Status and Availability - End

    // Work Experience - Start
    const workFormIds =
      formIdsRefWorkExperience.current.querySelectorAll("[id]");
    const invalidFields = [];
    if (workFormIds) {
      workFormIds.forEach((element) => {
        const elementType = element.type;
        // console.log("Test Type => " + elementType);

        if (
          callType == "submit" ||
          callType == element.id ||
          callType == "work_experience"
        ) {
          switch (elementType) {
            case "text":
              // Your validation logic for text fields
              let textTypeId = document.getElementById(element.id);
              let textTypeIdMsg = document.getElementById(element.id + "_msg");
              if (textTypeId.value.trim() === "") {
                textTypeId.classList.add("border-danger");
                textTypeIdMsg.innerText =
                  "Please enter the " +
                  (element.placeholder
                    ? String(element.placeholder).toLowerCase() + "!"
                    : "");
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "work_experience")
                ) {
                  textTypeId.focus();
                }
                applyError = true;
                // invalidFields.push(element.id);
              } else {
                if (
                  element.name.endsWith("cnd_refer_record_phone_no") == true
                ) {
                  if (
                    textTypeId.value &&
                    !String(textTypeId.value).match(/^0\d{10}$/)
                  ) {
                    textTypeId.classList.add("border-danger");
                    textTypeIdMsg.innerText =
                      "Please enter the valid Uk " +
                      (element.placeholder
                        ? String(element.placeholder).toLowerCase() + "!"
                        : "");
                    if (
                      applyError == false &&
                      (callType == "submit" || callType == "work_experience")
                    ) {
                      textTypeId.focus();
                    }
                    applyError = true;
                  } else {
                    textTypeId.classList.remove("border-danger");
                    textTypeIdMsg.innerText = "";
                  }
                } else {
                  textTypeId.classList.remove("border-danger");
                  textTypeIdMsg.innerText = "";
                  // invalidFields.pop(element.id);
                }
              }
              break;
            case "select-one":
              // Your validation logic for text fields
              let dropTypeId = document.getElementById(element.id);
              let dropTypeIdMsg = document.getElementById(element.id + "_msg");
              if (dropTypeId.value.trim() === "") {
                dropTypeId.classList.add("border-danger");
                dropTypeIdMsg.innerText = "Please select the country!";
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "work_experience")
                ) {
                  dropTypeId.focus();
                }
                applyError = true;
                // invalidFields.push(element.id);
              } else {
                dropTypeId.classList.remove("border-danger");
                dropTypeIdMsg.innerText = "";
                // invalidFields.pop(element.id);
              }
              break;
            case "email":
              // Your validation logic for text fields
              let emaiTypeId = document.getElementById(element.id);
              let emaiTypeIdMsg = document.getElementById(element.id + "_msg");
              if (emaiTypeId.value.trim() === "") {
                emaiTypeId.classList.add("border-danger");
                emaiTypeIdMsg.innerText =
                  "Please enter the " +
                  (element.placeholder
                    ? String(element.placeholder).toLowerCase()
                    : "");
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "work_experience")
                ) {
                  emaiTypeId.focus();
                }
                applyError = true;
                // invalidFields.push(element.id);
              } else if (
                emaiTypeId.value &&
                !String(emaiTypeId.value).match(
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                )
              ) {
                emaiTypeId.classList.add("border-danger");
                emaiTypeIdMsg.innerText =
                  "Please enter the valid email address!";
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "work_experience")
                ) {
                  emaiTypeId.focus();
                }
                applyError = true;
                // invalidFields.push(element.id);
              } else {
                emaiTypeId.classList.remove("border-danger");
                emaiTypeIdMsg.innerText = "";
                // invalidFields.pop(element.id);
              }
              break;
            // Assume "date" is the type of both "from date" and "to date" fields
            case "date":
              let dateTypeId = document.getElementById(element.id);
              let dateTypeIdMsg = document.getElementById(element.id + "_msg");
              let todayDate = new Date();
              let currentDate = new Date(dateTypeId.value);

              if (dateTypeId.value.trim() === "") {
                dateTypeId.classList.add("border-danger");
                dateTypeIdMsg.innerText =
                  "Please enter the " +
                  (element.placeholder
                    ? String(element.placeholder).toLowerCase()
                    : "");
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "work_experience")
                ) {
                  dateTypeId.focus();
                }
                applyError = true;
              } else if (currentDate > todayDate) {
                dateTypeId.classList.add("border-danger");
                dateTypeIdMsg.innerText =
                  "Date should be current or before the current date!";
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "work_experience")
                ) {
                  dateTypeId.focus();
                }
                applyError = true;
              } else {
                dateTypeId.classList.remove("border-danger");
                dateTypeIdMsg.innerText = "";
              }

              // Additional validation for "to date" being greater than "from date"
              const fromFieldId = element.id.replace("_to", "_from"); // Assuming IDs follow a pattern like "from_date_id" and "to_date_id"
              const fromDateValue = document.getElementById(fromFieldId).value;
              if (fromDateValue) {
                const fromDate = new Date(fromDateValue);
                if (currentDate < fromDate) {
                  dateTypeId.classList.add("border-danger");
                  dateTypeIdMsg.innerText =
                    "To date should be greater than from date!";
                  if (
                    applyError == false &&
                    (callType == "submit" || callType == "work_experience")
                  ) {
                    dateTypeId.focus();
                  }
                  applyError = true;
                }
              }
              break;

            default:
              break;
          }
        }
      });
    }
    // Work Experience - End

    // Qualification Details - Start
    const qualificationsFormIds =
      formIdsRefQualification.current.querySelectorAll("[id]");
    const invalidFieldsQualification = [];
    if (qualificationsFormIds) {
      qualificationsFormIds.forEach((element) => {
        const elementType = element.type;
        // console.log("Test Type => " + elementType);

        if (
          callType == "submit" ||
          callType == element.id ||
          callType == "qualification_details"
        ) {
          switch (elementType) {
            case "text":
              // Your validation logic for text fields
              let textTypeId = document.getElementById(element.id);
              let textTypeIdMsg = document.getElementById(element.id + "_msg");
              if (textTypeId.value.trim() === "") {
                textTypeId.classList.add("border-danger");
                textTypeIdMsg.innerText =
                  "Please enter the " +
                  (element.placeholder
                    ? String(element.placeholder).toLowerCase() + "!"
                    : "");
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "qualification_details")
                ) {
                  textTypeId.focus();
                }
                applyError = true;
                // invalidFieldsQualification.push(element.id);
              } else {
                if (
                  element.name.endsWith("cnd_refer_record_phone_no") == true
                ) {
                  if (
                    textTypeId.value &&
                    !String(textTypeId.value).match(/^0\d{10}$/)
                  ) {
                    textTypeId.classList.add("border-danger");
                    textTypeIdMsg.innerText =
                      "Please enter the valid Uk " +
                      (element.placeholder
                        ? String(element.placeholder).toLowerCase() + "!"
                        : "");
                    if (
                      applyError == false &&
                      (callType == "submit" ||
                        callType == "qualification_details")
                    ) {
                      textTypeId.focus();
                    }
                    applyError = true;
                  } else {
                    textTypeId.classList.remove("border-danger");
                    textTypeIdMsg.innerText = "";
                  }
                } else {
                  textTypeId.classList.remove("border-danger");
                  textTypeIdMsg.innerText = "";
                  // invalidFieldsQualification.pop(element.id);
                }
              }
              break;
            case "select-one":
              // Your validation logic for text fields
              let dropTypeId = document.getElementById(element.id);
              let dropTypeIdMsg = document.getElementById(element.id + "_msg");
              if (dropTypeId.value.trim() === "") {
                dropTypeId.classList.add("border-danger");
                dropTypeIdMsg.innerText = "Please select the country!";
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "qualification_details")
                ) {
                  dropTypeId.focus();
                }
                applyError = true;
                // invalidFieldsQualification.push(element.id);
              } else {
                dropTypeId.classList.remove("border-danger");
                dropTypeIdMsg.innerText = "";
                // invalidFieldsQualification.pop(element.id);
              }
              break;
            case "email":
              // Your validation logic for text fields
              let emaiTypeId = document.getElementById(element.id);
              let emaiTypeIdMsg = document.getElementById(element.id + "_msg");
              if (emaiTypeId.value.trim() === "") {
                emaiTypeId.classList.add("border-danger");
                emaiTypeIdMsg.innerText =
                  "Please enter the " +
                  (element.placeholder
                    ? String(element.placeholder).toLowerCase()
                    : "");
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "qualification_details")
                ) {
                  emaiTypeId.focus();
                }
                applyError = true;
                // invalidFieldsQualification.push(element.id);
              } else if (
                emaiTypeId.value &&
                !String(emaiTypeId.value).match(
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                )
              ) {
                emaiTypeId.classList.add("border-danger");
                emaiTypeIdMsg.innerText =
                  "Please enter the valid email address!";
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "qualification_details")
                ) {
                  emaiTypeId.focus();
                }
                applyError = true;
                // invalidFieldsQualification.push(element.id);
              } else {
                emaiTypeId.classList.remove("border-danger");
                emaiTypeIdMsg.innerText = "";
                // invalidFieldsQualification.pop(element.id);
              }
              break;
            // Assume "date" is the type of both "from date" and "to date" fields
            case "date":
              let dateTypeId = document.getElementById(element.id);
              let dateTypeIdMsg = document.getElementById(element.id + "_msg");
              let todayDate = new Date();
              let currentDate = new Date(dateTypeId.value);

              if (dateTypeId.value.trim() === "") {
                dateTypeId.classList.add("border-danger");
                dateTypeIdMsg.innerText =
                  "Please enter the " +
                  (element.placeholder
                    ? String(element.placeholder).toLowerCase()
                    : "");
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "qualification_details")
                ) {
                  dateTypeId.focus();
                }
                applyError = true;
              } else if (currentDate > todayDate) {
                dateTypeId.classList.add("border-danger");
                dateTypeIdMsg.innerText =
                  "Date should be current or before the current date!";
                if (
                  applyError == false &&
                  (callType == "submit" || callType == "qualification_details")
                ) {
                  dateTypeId.focus();
                }
                applyError = true;
              } else {
                dateTypeId.classList.remove("border-danger");
                dateTypeIdMsg.innerText = "";
              }

              // Additional validation for "to date" being greater than "from date"
              const fromFieldId = element.id.replace("_to", "_from"); // Assuming IDs follow a pattern like "from_date_id" and "to_date_id"
              const fromDateValue = document.getElementById(fromFieldId).value;
              if (fromDateValue) {
                const fromDate = new Date(fromDateValue);
                if (currentDate < fromDate) {
                  dateTypeId.classList.add("border-danger");
                  dateTypeIdMsg.innerText =
                    "To date should be greater than from date!";
                  if (
                    applyError == false &&
                    (callType == "submit" ||
                      callType == "qualification_details")
                  ) {
                    dateTypeId.focus();
                  }
                  applyError = true;
                }
              }
              break;

            default:
              break;
          }
        }
      });
    }
    // Qualification Details - End

    // Skills - Start
    // if (
    //   callType == "submit" ||
    //   callType == "cnd_jobs_skills" ||
    //   callType == "skills_and_expertise"
    // ) {
    //   let cnd_jobs_skills = document.getElementById("cnd_jobs_skills");
    //   let cnd_jobs_skills_msg = document.getElementById("cnd_jobs_skills_msg");
    //   //Set Data - Start
    //   if (cnd_jobs_skills) {
    //     let collectData = "";
    //     let count = 0;
    //     selectedSkillsArray.map((item, index) => {
    //       count++;
    //       if (count == 1) {
    //         collectData += item;
    //       } else {
    //         collectData += "," + item;
    //       }
    //     });
    //     setSelectedValues(collectData);
    //     // setCompleteFormData({
    //     //   ...completeFormData,
    //     //   ["cnd_jobs_skills"]: collectData ? collectData : "",
    //     // });
    //   }
    //   // Set Data - End
    //   if (selectedValues != "" && selectedValues.trim() == "") {
    //     setSelectedValuesCheck(true);
    //     cnd_jobs_skills_msg.innerText =
    //       "Please select the skills and expertise!";
    //     if (applyError == false && callType == "submit") {
    //       cnd_jobs_skills.focus();
    //     }
    //     applyError = true;
    //   } else {
    //     setSelectedValuesCheck(false);
    //     cnd_jobs_skills_msg.innerText = "";
    //   }
    // }
    // Skills - End

    // Car and Driver License Details - Start
    // License
    // if (callType == "submit" || callType == "cnd_drivinglicense") {
    //   let cnd_drivinglicense = document.getElementsByName("cnd_drivinglicense");
    //   let cnd_drivinglicense_msg = document.getElementById(
    //     "cnd_drivinglicense_msg"
    //   );
    //   let isChecked = false;
    //   if(cnd_drivinglicense){
    //     cnd_drivinglicense.forEach((radio) => {
    //       if (radio.checked) {
    //           isChecked = true;
    //       }
    //     });
    //   }
    //   if (isChecked == false) {
    //     cnd_drivinglicense_msg.innerText = "Please select one option!";
    //     if (applyError == false) {
    //       cnd_drivinglicense_msg.focus();
    //     }
    //     applyError = true;
    //   } else {
    //     cnd_drivinglicense_msg.innerText = "";
    //   }
    // }

    // Car
    // if (callType == "submit" || callType == "cnd_have_a_car") {
    //   let cnd_have_a_car = document.getElementsByName("cnd_have_a_car");
    //   let cnd_have_a_car_msg = document.getElementById(
    //     "cnd_have_a_car_msg"
    //   );
    //   let isChecked = false;

    //   if(cnd_have_a_car){
    //     cnd_have_a_car.forEach((radio) => {
    //       if (radio.checked) {
    //           isChecked = true;
    //       }
    //     });
    //   }
    //   if (isChecked == false) {
    //     cnd_have_a_car_msg.innerText = "Please select one option!";
    //     if (applyError == false) {
    //       cnd_have_a_car_msg.focus();
    //     }
    //     applyError = true;
    //   } else {
    //     cnd_have_a_car_msg.innerText = "";
    //   }
    // }
    // Car and Driver License Details - End

    return applyError;
  };

  const changeUpdateType = (callType) => {
    if (callType) {
      setUpdateCallType(callType);
    } else {
      setUpdateCallType("");
    }
  };

  const updateJobPreferencesDetails = (callType = "") => {
    // console.log("Demo test :=> "+JSON.stringify(callType));
    if (validCompleteForm(callType ? callType : "submit") == false) {
      document.getElementById("loader").style.display = "block";
      completeFormData["users_access_token"] =
        props.userAuth.users_access_token;
      if (callType == "residential_details" || callType == "submit") {
        let cnd_street_address = document.getElementById("cnd_street_address");
        if (cnd_street_address) {
          completeFormData["cnd_street_address"] = cnd_street_address.value;
        }

        let cnd_street_address_2 = document.getElementById(
          "cnd_street_address_2"
        );
        if (cnd_street_address_2) {
          completeFormData["cnd_street_address_2"] = cnd_street_address_2.value;
        }

        let cnd_town = document.getElementById("cnd_town");
        if (cnd_town) {
          completeFormData["cnd_town"] = cnd_town.value;
        }

        let cnd_region = document.getElementById("cnd_region");
        if (cnd_region) {
          completeFormData["cnd_region"] = cnd_region.value;
        }

        let cnd_postcode = document.getElementById("cnd_postcode");
        if (cnd_postcode) {
          completeFormData["cnd_postcode"] = cnd_postcode.value;
        }
      }
      if (callType == "work_experience" || callType == "submit") {
        completeFormData["employementReference"] = inputFieldsWorkExperience;
      }
      if (callType == "qualification_details" || callType == "submit") {
        completeFormData["educationReference"] =
          inputFieldsQualificationExperience;
      }
      if (callType == "skills_and_expertise" || callType == "submit") {
        completeFormData["cnd_jobs_skills"] = setValuesSkills();
      }

      completeFormData["update_call_type"] = updateCallType;
      dispatch(
        updateUserProfile(
          santetizeData(completeFormData),
          "UpdateJobPreferenceDetails"
        )
      );
    }
  };

  useEffect(() => {
    setFormdata(props.userData);
    const updatedFormData = {
      ...completeFormData,
      cnd_street_address: props.userData.cnd_street_address,
      cnd_street_address_2: props.userData.cnd_street_address_2,
      cnd_town: props.userData.cnd_town,
      cnd_region: props.userData.cnd_region,
      cnd_postcode: props.userData.cnd_postcode,
      cnd_desired_job_title: props.userData.cnd_desired_job_title,
      cnd_job_salary_amount: props.userData.cnd_job_salary_amount,
      cnd_job_salary_type: props.userData.cnd_job_salary_type,
      cnd_job_prefered_location: props.userData.cnd_job_prefered_location,
      cnd_job_employment_status: props.userData.cnd_job_employment_status,
      cnd_job_notice_period: props.userData.cnd_job_notice_period,
      cnd_jobs_skills: props.userData.cnd_jobs_skills,
      cnd_drivinglicense: props.userData.cnd_drivinglicense,
      cnd_have_a_car: props.userData.cnd_have_a_car,
    };
    setSelectedDesiredJobtitle(props.userData.cnd_desired_job_title);
    setSelectedValues(props.userData.cnd_jobs_skills);

    if (props.userData.cnd_jobs_skills) {
      let getSkillsArray = String(props.userData.cnd_jobs_skills).split(",");
      setSelectedSkillsArray(getSkillsArray);
    }

    // Work Experience - Start
    let workExperienceFormData = {};
    let workExperienceMultiForm = {};
    if (props.workAndQualificationDetails) {
      workExperienceFormData = props.workAndQualificationDetails;
      if (props.workAndQualificationDetails.employementReference) {
        workExperienceMultiForm =
          props.workAndQualificationDetails.employementReference;
        setInputFieldsWorkExperience(workExperienceMultiForm);
      }
      updatedFormData.employementReference = workExperienceFormData;
    }
    // Work Experience - End

    // Qualification Details - Start
    let qualificationFormData = {};
    let qualificationMultiForm = {};
    if (props.workAndQualificationDetails) {
      qualificationFormData = props.workAndQualificationDetails;
      if (props.workAndQualificationDetails.educationReference) {
        qualificationMultiForm =
          props.workAndQualificationDetails.educationReference;
        setInputFieldsQualificationExperience(qualificationMultiForm);
      }

      updatedFormData.educationReference = qualificationFormData;
    }
    // Qualification Details - End

    setCompleteFormData(updatedFormData);
  }, [props.userData, props.workAndQualificationDetails]);

  const joinSkills = (newValue) => {
    setSelectedSkillsArray([...selectedSkillsArray, newValue]);
    setJobSkillsList(false);
  };

  const removeValueFromArray = (valueToDelete) => {
    if (valueToDelete == "all") {
      setSelectedSkillsArray([]);
    } else {
      setSelectedSkillsArray(
        selectedSkillsArray.filter((item) => item !== valueToDelete)
      );
    }
  };

  const setValuesSkills = () => {
    let collectData = "";
    let count = 0;
    selectedSkillsArray.map((item, index) => {
      count++;
      if (count == 1) {
        collectData += item;
      } else {
        collectData += "," + item;
      }
    });
    return collectData;
    // setSelectedValues(collectData);
  };

  const printUpdateButton = (displayCondition = "") => {
    return (
      <>
        <div className="container" id={displayCondition + "_row"}>
          <div className="row">
            <div className="col-sm-12">
              {updateCallType == displayCondition && (
                <>
                  <Button
                    disabled={loader}
                    className="site-button m-b30 pull-right mr-3"
                    onClick={() =>
                      updateJobPreferencesDetails(displayCondition)
                    }
                  >
                    Update
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const printTooltip = (
    displayCondition = "",
    cancelTitle = "",
    updateTitle = "",
    callId = ""
  ) => {
    return (
      <>
        {updateCallType == displayCondition ? (
          <>
            <Tooltip className="pull-right" title={cancelTitle}>
              <i
                className="fa fa-times-circle text-danger pull-right mt-1 cursor-pointer"
                style={{ fontSize: "125%" }}
                onClick={() => setUpdateCallType("")}
              ></i>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip className="pull-right" title={updateTitle}>
              <i
                className="fa fa-pencil-square-o text-primary pull-right mt-1 cursor-pointer"
                style={{ fontSize: "125%" }}
                onClick={() => {
                  setUpdateCallType(displayCondition);
                  let update_button = document.getElementById(callId);
                  if (update_button) {
                    update_button.scrollIntoView({ behavior: "smooth" }, true);
                  }
                }}
              ></i>
            </Tooltip>
          </>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            <b>Candidate Profile & CV Details</b>
          </h5>
          {updateCallType == "update_all" ? (
            <>
              <Tooltip className="pull-right" title={"Cancel"}>
                <i
                  className="fa fa-times-circle text-danger pull-right mt-1 cursor-pointer"
                  style={{ fontSize: "125%" }}
                  onClick={() => setUpdateCallType("")}
                ></i>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip className="pull-right" title={"Update All Details"}>
                <i
                  className="fa fa-pencil-square-o text-primary pull-right mt-1 cursor-pointer"
                  style={{ fontSize: "125%" }}
                  onClick={() => {
                    let update_all_button =
                      document.getElementById("update_all_button");
                    if (update_all_button) {
                      update_all_button.scrollIntoView(
                        { behavior: "smooth" },
                        true
                      );
                    }
                    setUpdateCallType("update_all");
                  }}
                ></i>
              </Tooltip>
            </>
          )}
        </div>
        <div
          className="border border-grey mb-4"
          style={{ borderRadius: "20px" }}
        >
          {/* <div className="row p-3"> */}
          <h5 className="mb-0 p-3">
            <b className="" style={{ fontWeight: "700" }}>
              Residential Details {completeFormData.cnd_street_address || completeFormData.cnd_street_address_2 || completeFormData.cnd_region || completeFormData.cnd_town || completeFormData.cnd_postcode ? <i className="fa fa-check-circle m-l5 text-success"></i> : ""}
            </b>
            {/* {JSON.stringify(completeFormData)} */}
            {printTooltip(
              "residential_details",
              "Cancel",
              "Update Residential Details",
              "cnd_street_address_2"
            )}
          </h5>
          <div
            style={{
              width: "100%",
              height: "1px",
              right: "0",
              left: "20",
              backgroundColor: "#ccc",
            }}
          ></div>

          {/* Main Code Start*/}
          <div
            className="container"
            style={{ display: loading == false ? "block" : "none" }}
          >
            {/* {JSON.stringify(completeFormData)} */}
            <div className="p-3 row">
              {/* Address - Start */}
              <Input
                type="text"
                label="Address - (optional)"
                placeholder={"Eg., 123 Main St"}
                lg={12}
                md={12}
                changeHandler={(e) => validCompleteForm(e.target.name)}
                name="cnd_street_address"
                value={completeFormData.cnd_street_address}
                id={"cnd_street_address"}
                req={false}
              />
              <Input
                type="text"
                lg={12}
                md={12}
                changeHandler={(e) => validCompleteForm(e.target.name)}
                name="cnd_street_address_2"
                id={"cnd_street_address_2"}
                value={completeFormData.cnd_street_address_2}
                placeholder="Eg., Apartment, suite, unit etc. (optional)"
                req={false}
              />
              <Input
                type="text"
                changeHandler={(e) => validCompleteForm(e.target.name)}
                name="cnd_town"
                value={completeFormData.cnd_town}
                id={"cnd_town"}
                lg={4}
                md={4}
                placeholder="Eg., London"
                req={false}
              />
              <Input
                type="text"
                changeHandler={(e) => validCompleteForm(e.target.name)}
                name="cnd_region"
                id={"cnd_region"}
                value={completeFormData.cnd_region}
                lg={4}
                md={4}
                placeholder="Eg., London"
                req={false}
              />
              <Input
                type="text"
                changeHandler={(e) => validCompleteForm(e.target.name)}
                name="cnd_postcode"
                id={"cnd_postcode"}
                value={completeFormData.cnd_postcode}
                lg={4}
                md={4}
                //maxLength={8}
                placeholder="Eg., AB12CD"
                req={false}
              />
              {/* Address - End */}
            </div>
          </div>
          {/* Main Code End */}
          {loading == true && (
            <p className="p-3 mb-0">
              <div className="row mb-0">
                {printSkeleton("", 2)}
                {printSkeleton("4", 3)}
              </div>
            </p>
          )}

          {printUpdateButton("residential_details")}
        </div>

        <div
          className="border border-grey mb-4"
          style={{ borderRadius: "20px" }}
        >
          {/* Looking for Start */}

          <h5 className="pt-3 pb-2 pl-3 pr-3">
            <b className="" style={{ fontWeight: "700" }}>
              Looking For {completeFormData.cnd_desired_job_title ? <i className="fa fa-check-circle m-l5 text-success"></i> : <b className="text-danger">*</b>}
            </b>
            {printTooltip(
              "looking_for_details",
              "Cancel",
              "Update Loking for Details",
              "cnd_desired_job_title"
            )}
          </h5>
          <div
            style={{
              width: "100%",
              height: "1px",
              right: "0",
              left: "20",
              backgroundColor: "#ccc",
            }}
          ></div>

          {/* Main Code Start*/}
          <div
            className="container"
            style={{ display: loading == false ? "block" : "none" }}
          >
            <div className="p-3 row">
              <Input
                type="text"
                label="Desired Job Title"
                changeHandler={(e) => validCompleteForm(e.target.name)}
                name="cnd_desired_job_title"
                id={"cnd_desired_job_title"}
                value={completeFormData.cnd_desired_job_title}
                lg={6}
                md={6}
                //maxLength={8}
                placeholder="Eg., Nursery Asisatant "
              />
              {/* <Label labelName="Desired Job Title" />
                    <Autocomplete
                      freeSolo
                      options={[
                        ...new Set(
                          jobWorkPostionTitles.map((jobTitle) => jobTitle.name)
                        ),
                      ]}
                      inputValue={selectedDesiredJobtitle || ""}
                      id="cnd_desired_job_title"
                      className={
                        selectedDesiredJobtitleCheck == true
                          ? "border border-danger"
                          : ""
                      }
                      onChange={(event, newValue) => {
                        setSelectedDesiredJobtitle(newValue);

                        validCompleteForm("cnd_desired_job_title");
                        setJobWorkPositionList(false);
                      }}
                      onInputChange={(event, newInputValue) => {
                        if (newInputValue.length >= 2) {
                          setJobWorkPositionList(true);
                        } else {
                          setJobWorkPositionList(false);
                        }
                        validCompleteForm("cnd_desired_job_title");
                      }}
                      onBlur={(event, newInputValue) => {
                        validCompleteForm("cnd_desired_job_title");
                        setJobWorkPositionList(false);
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            placeholder="Desired Job Title"
                            //   variant="standard"

                            value={selectedDesiredJobtitle} // Bind the value
                            onChange={(e) => {
                              setSelectedDesiredJobtitle(e.target.value);
                            }}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        </>
                      )}
                      style={{ width: "100%" }}
                      // Set open to show/hide options based on showTitleList state
                      open={jobWorkPositionList}
                      clearIcon={
                        <CloseIcon
                          onClick={(e) => {
                            setSelectedDesiredJobtitle("");
                          }}
                          className="text-danger"
                        />
                      }
                    /> */}

              <Input
                type="text"
                label="Salary Amount(£ GBP)"
                placeholder={"Eg., 6000"}
                lg={6}
                md={6}
                value={completeFormData.cnd_job_salary_amount}
                changeHandler={(e) => validCompleteForm(e.target.name)}
                name="cnd_job_salary_amount"
                id={"cnd_job_salary_amount"}
              />

              <Dropdown
                name="cnd_job_salary_type"
                value={completeFormData.cnd_job_salary_type}
                id={"cnd_job_salary_type"}
                label="Salary Type"
                options={jobsSalaryTypeDropList}
                changeHandler={(e) => validCompleteForm(e.target.name)}
                lg={6}
              />
              <Input
                type="text"
                label="Prefered Job Location"
                placeholder={"Eg., Hounslow"}
                lg={6}
                md={6}
                changeHandler={(e) => validCompleteForm(e.target.name)}
                name="cnd_job_prefered_location"
                id={"cnd_job_prefered_location"}
                value={completeFormData.cnd_job_prefered_location}
                req={false}
              />
            </div>
          </div>
          {/* Main Code End */}

          {loading == true && (
            <p className="p-3 mb-0">
              <div className="row mb-0">{printSkeleton("6", 4)}</div>
            </p>
          )}

          {printUpdateButton("looking_for_details")}
          {/* Looking for End */}
        </div>

        <div
          className="border border-grey mb-4"
          style={{ borderRadius: "20px" }}
        >
          {/* Status and Availability Start */}
          <h5 className="pt-3 pb-2 pl-3 pr-3">
            <b className="" style={{ fontWeight: "700" }}>
              Status and Availability {completeFormData.cnd_job_employment_status || completeFormData.cnd_job_notice_period ? <i className="fa fa-check-circle m-l5 text-success"></i> : ""}
            </b>
            {printTooltip(
              "status_and_availability",
              "Cancel",
              "Update Status & Availability"
            )}
          </h5>
          <div
            style={{
              width: "100%",
              height: "1px",
              right: "0",
              left: "20",
              backgroundColor: "#ccc",
            }}
          ></div>

          {/* Main Code Start*/}
          <div
            className="container"
            style={{ display: loading == false ? "block" : "none" }}
          >
            <div className="p-3 row">
              <Dropdown
                name="cnd_job_employment_status"
                value={completeFormData.cnd_job_employment_status}
                id={"cnd_job_employment_status"}
                label="Employment Status"
                options={jobEmploymentStatusDropList}
                changeHandler={(e) => validCompleteForm(e.target.name)}
                lg={6}
                req={false}
              />

              <Dropdown
                name="cnd_job_notice_period"
                value={completeFormData.cnd_job_notice_period}
                id={"cnd_job_notice_period"}
                label="Notice Period"
                options={jobNoticePeriodDropList}
                changeHandler={(e) => validCompleteForm(e.target.name)}
                lg={6}
                req={false}
              />
            </div>
          </div>
          {/* Main Code End */}

          {loading == true && (
            <p className="p-3 mb-0">
              <div className="row mb-0">{printSkeleton("6", 2)}</div>
            </p>
          )}

          {printUpdateButton("status_and_availability")}
          {/* Status and Availability End */}
        </div>

        {/* Work Experience - Start */}
        <div
          className="border border-grey mb-4"
          style={{ borderRadius: "20px" }}
        >
          <h5 className="pt-3 pb-2 pl-3 pr-3">
            <b className="" style={{ fontWeight: "700" }}>
              Work Experience {inputFieldsWorkExperience && inputFieldsWorkExperience[0].work_experience_company_name ? <i className="fa fa-check-circle m-l5 text-success"></i> : <b className="text-danger">*</b>}
            </b>
            {printTooltip(
              "work_experience",
              "Cancel",
              "Update Work Experience",
              inputFieldsWorkExperience
                ? "employementReference_" +
                    (inputFieldsWorkExperience.length - 1) +
                    "_work_experience_to_date"
                : ""
            )}
          </h5>
          <div
            style={{
              width: "100%",
              height: "1px",
              right: "0",
              left: "20",
              backgroundColor: "#ccc",
            }}
          ></div>

          {/* Main Code Start*/}
          <div
            className="container"
            style={{ display: loading == false ? "block" : "none" }}
          >
            <div className="p-3 row">
              <div className="container">
                <div className="row" ref={formIdsRefWorkExperience}>
                  {inputFieldsWorkExperience.map((inputField, index) => (
                    <React.Fragment key={index}>
                      <div
                        className={
                          (inputFieldsWorkExperience.length > 1
                            ? "job-bx-title mb-3"
                            : "") + "clearfix text-dark p-3 text-sm"
                        }
                        style={{ fontSize: "100%" }}
                      >
                        <b>
                          {inputFieldsWorkExperience &&
                            inputFieldsWorkExperience.length > 1 &&
                            (index >= 0
                              ? "Work Experience - " + (index + 1)
                              : "")}
                          {index > 0 ? (
                            <Tooltip
                              className="pull-right"
                              title={"Remove Work Experience"}
                              // overlay={renderTooltip("Remove")}
                            >
                              <a
                                className="btn btn-danger text-white btn-sm pull-right"
                                onClick={() =>
                                  handleRemoveFields(
                                    index,
                                    "employementReference"
                                  )
                                }
                              >
                                <i className="fa fa-trash"></i>
                              </a>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </b>
                      </div>

                      <Input
                        type="text"
                        label="Organisation Name"
                        changeHandler={(event) =>
                          handleChangeInput(
                            index,
                            event,
                            "employementReference"
                          )
                        }
                        name="work_experience_company_name"
                        id={
                          "employementReference_" +
                          index +
                          "_work_experience_company_name"
                        }
                        value={inputField.work_experience_company_name}
                        lg={6}
                      />

                      <Input
                        type="text"
                        label="Position Held"
                        changeHandler={(event) =>
                          handleChangeInput(
                            index,
                            event,
                            "employementReference"
                          )
                        }
                        name="work_experience_job_title"
                        id={
                          "employementReference_" +
                          index +
                          "_work_experience_job_title"
                        }
                        value={inputField.work_experience_job_title}
                        lg={6}
                      />

                      <Input
                        type="date"
                        label="From Date"
                        changeHandler={(event) =>
                          handleChangeInput(
                            index,
                            event,
                            "employementReference"
                          )
                        }
                        max={new Date().toISOString().split("T")[0]}
                        min={
                          new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() - 63
                            )
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        name="work_experience_from_date"
                        id={
                          "employementReference_" +
                          index +
                          "_work_experience_from_date"
                        }
                        value={inputField.work_experience_from_date}
                        lg={6}
                      />

                      <Input
                        type="date"
                        label="To Date"
                        max={new Date().toISOString().split("T")[0]}
                        min={
                          new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() - 63
                            )
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        changeHandler={(event) =>
                          handleChangeInput(
                            index,
                            event,
                            "employementReference"
                          )
                        }
                        name="work_experience_to_date"
                        id={
                          "employementReference_" +
                          index +
                          "_work_experience_to_date"
                        }
                        value={inputField.work_experience_to_date}
                        lg={6}
                      />
                      <Textarea
                        label="What did you do there?"
                        type="text"
                        name="work_experience_description"
                        id={
                          "employementReference_" +
                          index +
                          "work_experience_description"
                        }
                        value={inputField.work_experience_description}
                        placeholder={"Description(optional)"}
                        className="form-control"
                        lg={12}
                        changeHandler={(event) =>
                          handleChangeInput(
                            index,
                            event,
                            "employementReference"
                          )
                        }
                        req={false}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <p>
                <Tooltip
                  className="pull-right"
                  title={"Add More Work Experince"}
                >
                  <a
                    className="btn btn-primary text-white pull-right"
                    onClick={() => handleAddFields("employementReference")}
                  >
                    <i className="fa fa-plus-square"></i>
                  </a>
                </Tooltip>
              </p>
            </div>
          </div>
          {/* Main Code End */}
          {loading == true && (
            <p className="p-3 mb-0">
              <div className="row mb-0">
                {printSkeleton("6", 4)}
                {printSkeleton("", "", "", "", "", "", 80)}
              </div>
            </p>
          )}

          {printUpdateButton("work_experience")}
        </div>
        {/* Wrok Experience - End */}

        {/* Qualification Start */}
        <div
          className="border border-grey mb-4"
          style={{ borderRadius: "20px" }}
        >
          <h5 className="pt-3 pb-2 pl-3 pr-3">
            <b className="" style={{ fontWeight: "700" }}>
              Qualifications {inputFieldsQualificationExperience && inputFieldsQualificationExperience[0].qualification_institute_name ? <i className="fa fa-check-circle m-l5 text-success"></i> : <b className="text-danger">*</b>}
            </b>
            {printTooltip(
              "qualification_details",
              "Cancel",
              "Update Qualification Details",
              inputFieldsQualificationExperience
                ? "educationReference_" +
                    (inputFieldsQualificationExperience.length - 1) +
                    "_qualification_to_date"
                : ""
            )}
          </h5>
          <div
            style={{
              width: "100%",
              height: "1px",
              right: "0",
              left: "20",
              backgroundColor: "#ccc",
            }}
          ></div>

          {/* Main Code Start*/}
          <div
            className="container"
            style={{ display: loading == false ? "block" : "none" }}
          >
            <div className="p-3 row">
              <div className="container">
                <div className="row" ref={formIdsRefQualification}>
                  {inputFieldsQualificationExperience.map(
                    (inputField, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={
                            (inputFieldsQualificationExperience.length > 1
                              ? "job-bx-title mb-3"
                              : "") + "clearfix text-dark p-3 text-sm"
                          }
                          style={{ fontSize: "100%" }}
                        >
                          <b>
                            {inputFieldsQualificationExperience &&
                              inputFieldsQualificationExperience.length > 1 &&
                              (index >= 0
                                ? "Qualification - " + (index + 1)
                                : "")}
                            {index > 0 ? (
                              <Tooltip
                                className="pull-right"
                                title={"Remove Qualification Details"}
                              >
                                <a
                                  className="btn btn-danger text-white btn-sm pull-right"
                                  onClick={() =>
                                    handleRemoveFields(
                                      index,
                                      "educationReference"
                                    )
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </b>
                        </div>

                        <Input
                          type="text"
                          label="Institute Name"
                          changeHandler={(event) =>
                            handleChangeInput(
                              index,
                              event,
                              "educationReference"
                            )
                          }
                          name="qualification_institute_name"
                          id={
                            "educationReference_" +
                            index +
                            "_qualification_institute_name"
                          }
                          value={inputField.qualification_institute_name}
                          lg={6}
                        />

                        <Input
                          type="text"
                          label="Qualification"
                          changeHandler={(event) =>
                            handleChangeInput(
                              index,
                              event,
                              "educationReference"
                            )
                          }
                          name="qualification_type"
                          id={
                            "educationReference_" +
                            index +
                            "_qualification_type"
                          }
                          value={inputField.qualification_type}
                          lg={6}
                        />

                        <Input
                          type="date"
                          label="From Date"
                          max={new Date().toISOString().split("T")[0]}
                          min={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 63
                              )
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                          changeHandler={(event) =>
                            handleChangeInput(
                              index,
                              event,
                              "educationReference"
                            )
                          }
                          name="qualification_from_date"
                          id={
                            "educationReference_" +
                            index +
                            "_qualification_from_date"
                          }
                          value={inputField.qualification_from_date}
                          lg={6}
                        />

                        <Input
                          type="date"
                          label="To Date"
                          max={new Date().toISOString().split("T")[0]}
                          min={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 63
                              )
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                          changeHandler={(event) =>
                            handleChangeInput(
                              index,
                              event,
                              "educationReference"
                            )
                          }
                          name="qualification_to_date"
                          id={
                            "educationReference_" +
                            index +
                            "_qualification_to_date"
                          }
                          value={inputField.qualification_to_date}
                          lg={6}
                        />

                        <Textarea
                          label="Describe your achievements"
                          type="text"
                          name="qualification_description"
                          id={
                            "educationReference_" +
                            index +
                            "qualification_description"
                          }
                          value={inputField.qualification_description}
                          placeholder={"Description(optional)"}
                          className="form-control"
                          lg={12}
                          changeHandler={(event) =>
                            handleChangeInput(
                              index,
                              event,
                              "educationReference"
                            )
                          }
                          req={false}
                        />
                      </React.Fragment>
                    )
                  )}
                </div>
              </div>

              <p>
                <Tooltip
                  className="pull-right"
                  title={"Add More Qualification Details"}
                >
                  <a
                    className="btn btn-primary text-white pull-right"
                    onClick={() => handleAddFields("educationReference")}
                  >
                    <i className="fa fa-plus-square"></i>
                  </a>
                </Tooltip>
              </p>
            </div>
          </div>
          {/* Main Code End */}

          {loading == true && (
            <p className="p-3 mb-0">
              <div className="row mb-0">
                {printSkeleton("6", 4)}
                {printSkeleton("", "", "", "", "", "", 80)}
              </div>
            </p>
          )}

          {printUpdateButton("qualification_details")}
        </div>
        {/* Qualification End */}

        {/* Skills Start */}
        <div
          className="border border-grey mb-4"
          style={{ borderRadius: "20px" }}
        >
          <h5 className="pt-3 pb-2 pl-3 pr-3">
            <b className="" style={{ fontWeight: "700" }}>
              Skills {completeFormData.cnd_jobs_skills ? <i className="fa fa-check-circle m-l5 text-success"></i> : ""}
            </b>
            {printTooltip(
              "skills_and_expertise",
              "Cancel",
              "Update Skills & Expertise"
            )}
          </h5>
          <div
            style={{
              width: "100%",
              height: "1px",
              right: "0",
              left: "20",
              backgroundColor: "#ccc",
            }}
          ></div>

          {/* Main Code Start*/}
          <div
            className="container"
            style={{ display: loading == false ? "block" : "none" }}
          >
            <div className="p-3 row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <div className="input-group">
                    {/* {JSON.stringify(completeFormData.cnd_jobs_skills.slice(','))} */}
                    {/* <Label labelName="Skills & Expertise" req={false} /> */}
                    <Autocomplete
                      freeSolo
                      // multiple
                      id="cnd_jobs_skills"
                      options={[
                        ...new Set(
                          jobWorkSkillsDropList
                            .filter(
                              (jobSkills) =>
                                !selectedSkillsArray.includes(jobSkills.name)
                            )
                            .map((jobSkills) => jobSkills.name)
                        ),
                      ]}
                      inputValue={removeValues || ""}
                      className={
                        selectedValuesCheck == true
                          ? "border border-danger"
                          : ""
                      }
                      // onChange={(event, newInputValue) => {
                      //   if (newInputValue && newInputValue.trim() != "") {
                      //     joinSkills(newInputValue);
                      //     setRemoveValues("");
                      //   }
                      //   setJobSkillsList(false);
                      //   // validCompleteForm("cnd_jobs_skills");
                      // }}

                      onChange={(event, newInputValue) => {
                        // Check if newInputValue is present in jobWorkSkillsDropList
                        const isValidInput = jobWorkSkillsDropList.some(
                          (jobSkills) => jobSkills.name === newInputValue
                        );

                        // If newInputValue is valid, add it to joinSkills
                        if (isValidInput) {
                          if (newInputValue && newInputValue.trim() !== "") {
                            joinSkills(newInputValue);
                            setRemoveValues("");
                          }
                        } else {
                          setRemoveValues("");
                        }

                        setJobSkillsList(false);
                      }}
                      onInputChange={(event, newInputValue) => {
                        if (newInputValue.length >= 2) {
                          setJobSkillsList(true);
                        } else {
                          setJobSkillsList(false);
                        }
                        // validCompleteForm("cnd_jobs_skills");
                      }}
                      onBlur={(event, newInputValue) => {
                        setJobSkillsList(false);

                        // validCompleteForm("cnd_jobs_skills");
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            placeholder="Type Here to Add Your Skills"
                            // name="cnd_jobs_skills"
                            // variant="standard"
                            value={removeValues}
                            onChange={(e) => {
                              setRemoveValues(e.target.value);
                            }}
                            onBlur={(e) => {
                              const isValidInput = jobWorkSkillsDropList.some(
                                (jobSkills) => jobSkills.name === e.target.value
                              );

                              // If newInputValue is valid, add it to joinSkills
                              if (isValidInput) {
                                if (
                                  e.target.value &&
                                  e.target.value.trim() !== ""
                                ) {
                                  joinSkills(e.target.value);
                                  setRemoveValues("");
                                }
                              } else {
                                if (removeValues) {
                                  Swal.fire({
                                    title: "Invalid Input",
                                    text: "You have entered a wrong input, please choose from the list only",
                                    icon: "warning",
                                    showCancelButton: false,
                                    confirmButtonText: "Ok",
                                    // confirmButtonColor: 'green',
                                    cancelButtonColor: "red",
                                    cancelButtonText: "Cancel",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      setRemoveValues("");
                                    }
                                  });
                                }
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        </>
                      )}
                      style={{ width: "100%" }}
                      open={jobSkillsList}
                      clearIcon={
                        <CloseIcon
                          onClick={() => setRemoveValues("")}
                          className="text-danger"
                        />
                      }
                    />
                    {/* <br /> */}
                    {/* Chips Start */}
                    {selectedSkillsArray && selectedSkillsArray.length > 0 && (
                      <>
                        <div className="container">
                          <div
                            className="row p-3 border border-grey mt-2"
                            style={{ borderRadius: "20px" }}
                          >
                            <p className="clearfix mb-1">
                              <Tooltip
                                className="pull-right"
                                title={"Remove All Skills"}
                                // overlay={renderTooltip("Remove")}
                              >
                                <a
                                  className="text-white pull-right cursor-pointer pl-1 pr-1 border border-danger bg-danger text-white"
                                  style={{
                                    borderRadius: "10px",
                                    borderWidth: "10%",
                                  }}
                                  onClick={() => removeValueFromArray("all")}
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              </Tooltip>
                            </p>
                            {selectedSkillsArray.map((chip, chipIndex) => (
                              <>
                                <p
                                  className="bg-grey border border-grey mb-2 p-2 ml-1 mr-1 "
                                  style={{
                                    borderRadius: "20px",
                                    width: "auto",
                                  }}
                                  key={chipIndex}
                                >
                                  <b>{chip}</b>{" "}
                                  <Tooltip
                                    className="pull-right"
                                    title={"Remove " + chip}
                                  >
                                    <i
                                      className="fa fa-close pull-right text-danger ml-2 mt-1 cursor-pointer"
                                      onClick={() => {
                                        removeValueFromArray(chip);
                                      }}
                                    ></i>
                                  </Tooltip>
                                </p>
                              </>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    {/* Chips End */}

                    <div
                      className="text-danger"
                      id="cnd_jobs_skills_msg"
                      style={{ fontSize: "80%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Main Code End */}

          {loading == true && (
            <p className="p-3 mb-0">
              <div className="row mb-0">
                {printSkeleton("", "", "", "", "", "", 45)}
                {printSkeleton("", "", "", "", "", "", 80)}
              </div>
            </p>
          )}

          {printUpdateButton("skills_and_expertise")}
        </div>
        {/* Skills End */}

        {/* Car and Driving License - Start */}
        <div
          className="border border-grey mb-4"
          style={{ borderRadius: "20px" }}
        >
          <h5 className="pt-3 pb-2 pl-3 pr-3">
            <b className="" style={{ fontWeight: "700" }}>
              Car and Driving License Details {completeFormData.cnd_drivinglicense || completeFormData.cnd_have_a_car ? <i className="fa fa-check-circle m-l5 text-success"></i> : ""}
            </b>
            {printTooltip(
              "car_and_driving_license_details",
              "Cancel",
              "Update Car & Driving License Details"
            )}
          </h5>
          <div
            style={{
              width: "100%",
              height: "1px",
              right: "0",
              left: "20",
              backgroundColor: "#ccc",
            }}
          ></div>

          {/* Main Code Start*/}
          <div
            className="container"
            style={{ display: loading == false ? "block" : "none" }}
          >
            <div className="p-3 row">
              <div className="col-lg-12 col-md-12" id="update_all_button">
                <FormControl className="mb-0">
                  <Label
                    labelName="I have a full licence and am eligible to drive in the UK"
                    req={false}
                  />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="cnd_drivinglicense"
                    className="mb-0"
                    onChange={(event, currentValue) => {
                      validCompleteForm(event.target.name);
                      //Set Data - Start
                      if (currentValue) {
                        setCompleteFormData({
                          ...completeFormData,
                          ["cnd_drivinglicense"]: currentValue
                            ? currentValue
                            : "",
                        });
                      }
                      // Set Data - End
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      checked={
                        completeFormData.cnd_drivinglicense == "1"
                          ? true
                          : false
                      }
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      checked={
                        completeFormData.cnd_drivinglicense == "0"
                          ? true
                          : false
                      }
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                <p
                  className="text-danger"
                  id="cnd_drivinglicense_msg"
                  style={{ fontSize: "80%" }}
                ></p>
              </div>

              <div className="col-lg-12 col-md-12">
                <FormControl className="mb-0">
                  <Label labelName="I have a car" req={false} />
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="cnd_have_a_car"
                    onChange={(event, currentValue) => {
                      validCompleteForm(event.target.name);
                      //Set Data - Start
                      if (currentValue) {
                        setCompleteFormData({
                          ...completeFormData,
                          ["cnd_have_a_car"]: currentValue ? currentValue : "",
                        });
                      }
                      // Set Data - End
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      checked={
                        completeFormData.cnd_have_a_car == "1" ? true : false
                      }
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      checked={
                        completeFormData.cnd_have_a_car == "0" ? true : false
                      }
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                <p
                  className="text-danger"
                  id="cnd_have_a_car_msg"
                  style={{ fontSize: "80%" }}
                ></p>
              </div>
            </div>
          </div>

          {loading == true && (
            <p className="p-3 mb-0">
              <div className="row mb-0">
                {printSkeleton("", "", "", "", "", "", 30)}
                {printSkeleton("2", 2, "", "", "", "", 20)}
                {printSkeleton("", "", "", "", "", "", 30)}
                {printSkeleton("2", 2, "", "", "", "", 20)}
              </div>
            </p>
          )}
          {printUpdateButton("car_and_driving_license_details")}
          {/* Main Code End */}
        </div>
        {/* Car and Driving License - End */}

        {/* Button Start */}
        <div className="row">
          <div className="col-sm-12">
            {updateCallType == "update_all" && (
              <>
                <Button
                  disabled={loader}
                  className="site-button m-b30 pull-right mr-3"
                  onClick={() => updateJobPreferencesDetails("submit")}
                >
                  Update
                </Button>
              </>
            )}
          </div>
        </div>
        {/* Button End */}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    workAndQualificationDetails:
      state.workAndQualificationDetails.workAndQualificationDetails,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(CandidateFullDetailsForm);
