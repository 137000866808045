import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { loadloginInfo } from "./../redux/actions/AuthActions";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import { useHistory } from "react-router-dom";
import { updateDataOnRequest } from "../redux/actions/CommonActions";

var bnr = require("./../images/banner/bnr2.jpg");

const ForgotPassword = (props) => {
  // Add title
  document.getElementById("titleText").innerText = "Staffing Connect - Forgot Password";
  let history = useHistory();
  const [email, setEmail] = useState("");
  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [formData, setFormData] = useState({});

  //   const [userType, setUserType] = useState("candidate");
  const dispatch = useDispatch();

  // To Disable The Paste Feature on Screen

  const handleChange = (e) => {
    e.preventDefault();
    
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // formData = {"users_email" :e.target.value};
    //console.log("This Is OTP :=>"+JSON.stringify(formData));
  };

  const onLogin = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    // console.warn(JSON.stringify(formData));
    localStorage.setItem("users_email", JSON.stringify(formData));
    document.getElementById("loader").style.display = "block";   
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "ForgotPassword",
        ""
      )
    );
  };

  // const changeTab = (activeTab) => {
  //   setUserType(activeTab);
  // };
  // console.log(props.errorMessage);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="section-full content-inner-2 shop-account">
          <div className="container">
            <div className="max-w500 m-auto bg-white m-b30">
              <div className="p-a30 job-bx browse-job radius-sm seth loginSignup">
                <div className="tab-content nav">
                  <form
                    id="login"
                    onSubmit={onLogin}
                    onInput={handleChange}
                    className="tab-pane active col-12 p-a0 "
                  >
                    <h1 style={{display:"none"}}>Staffing Connect - Forgot Password Details</h1>
                    <h4 className="font-weight-700 text-center">
                      Forgot Password?
                    </h4>

                    <div className="form-group mt-5 otp-form">
                      <label className="font-weight-700">Email *</label>
                      <input
                        className="form-control"
                        value={email}
                        type="email"
                        name="users_email"
                        placeholder="Enter your registered email"
                        title="Enter Email Address"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && (
                        <div className="text-danger fs-12">{errors.email}</div>
                      )}

                      {/* <input
                          
                          name="otp-value"
                          // required=""
                          className="form-control otp-value"
                          placeholder="Your OTP Is"
                          type="hidden"
                          id="otp-value"
                          maxLength={6}
                          onBeforeInput={handleChange}                        
                        /> */}

                      {/* {errors.otp && (
                          <div className="text-danger fs-12">{errors.otp}</div>
                        )} */}
                    </div>

                    <div className="text-center mt-5">
                      <button className="site-button green pull-right m-r5 button-lg">
                        Send otp
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: isAuthenticated(state),
    errorMessage: state.error.error,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
