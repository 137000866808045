export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateStringWithoutSpecialCharacter = (string) => {
  return String(string)
  .toLowerCase()
  .match(
    /^[a-zA-Z ]{2,30}$/
  );
}

export const validateUkNumber = (string) => {
  return String(string)
  .toLowerCase()
  .match(
    /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
  );
}
export const sortCode = (string) => {
  return String(string)
  .toLowerCase()
  .match(
    /^(?!(?:0{6}[-]|00-00-00))(?:\d{6}[-]|\d\d-\d\d-\d\d)$/
  );
}
export const nationalInsurance = (string) => {
  return String(string)
  .toLowerCase()
  .match(
    /^[A-Z]{2}[-][0-9]{6}[-][A-Z]{1}$/i
    // /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[-][0-9]{6}[-][A-D]{1,2}$/i
  );
}

export const accountNumber = (string) => {
  return String(string)
  .toLowerCase()
  .match(
    /^(\d){8}$/
  );
}

export const rollNumber = (string) => {
  
  /*if(string.length < 2){
    return false;
  }else if(string.length > 6){
    return false;
  }else{
    return true;
  }*/
  return String(string).match(/^[0-9]{2,6}$/);
}

