import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { redirectUrl } from "../../../services/GlobalUrls";
const DailyLogsListMobile = (props) => {
  const [dailylogs, SetDailyLogs] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.dailylogs.length;
    // console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetDailyLogs(props.dailylogs);
  }, [props.dailylogs]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };

  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  //   Filter - Start
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (dailyLog) => {
    const searchText = inputValue.toLowerCase();
    return (
      dailyLog.cnd_daily_logs_created_date.toLowerCase().includes(searchText) ||
      dailyLog.cnd_daily_logs_firstname.toLowerCase().includes(searchText) ||
      dailyLog.cnd_daily_logs_lastname.toLowerCase().includes(searchText) ||
      dailyLog.cnd_daily_logs_date.toLowerCase().includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filterRows = dailylogs ? dailylogs.filter(matchesSearchText) : [];
  //   Filter - End

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        {/* Search Start */}
        <div className="container">
          <div className="row">
            <div class="col-sm-12">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  onInput={(event, value) => {
                    setInputValue(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          {filterRows &&
            filterRows.length > 0 &&
            filterRows.slice(startIndex, endIndex).map((dailylog, i) => (
              <ul
                className="nav navbar-nav border border-grey p-4 mb-2"
                style={{ borderRadius: "20px" }}
                key={i}
              >
                <li className="text-dark">
                  <i className="fa fa-calendar-o mr-1 text-primary"></i>{" "}
                  {dailylog.cnd_daily_logs_created_date}
                </li>
                <li className="text-dark">
                  <i className="fa fa-user-circle mr-1 text-primary"></i>{" "}
                  {dailylog.cnd_daily_logs_firstname +
                    " " +
                    dailylog.cnd_daily_logs_lastname}
                  {dailylog.cnd_daily_logs_upload_file && (
                    <a
                      onClick={() =>
                        viewFileModal(
                          constants.IMAGE_URL +
                            dailylog.cnd_daily_logs_upload_file_folder_path +
                            dailylog.cnd_daily_logs_upload_file
                        )
                      }
                      className={
                        "text-" +
                        (dailylog.cnd_daily_logs_upload_file.endsWith(".png") ||
                        dailylog.cnd_daily_logs_upload_file.endsWith(".jpg") ||
                        dailylog.cnd_daily_logs_upload_file.endsWith(".jpeg") ||
                        dailylog.cnd_daily_logs_upload_file.endsWith(".pdf")
                          ? "primary"
                          : "primary") +
                        " text-sm pull-right mt--2"
                      }
                    >
                      <u>View File</u>
                    </a>
                  )}
                </li>
                <li className="text-dark">
                  <i className="fa fa-calendar-check-o mr-1 text-primary"></i>{" "}
                  {dailylog.cnd_daily_logs_date}
                </li>
              </ul>
            ))}

          {dailylogs.length === 0 ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : filterRows.length === 0 && inputValue ? (
            <ul
              className="nav navbar-nav border border-grey p-4 mb-2 text-center"
              style={{ borderRadius: "20px" }}
            >
              No Result Found
            </ul>
          ) : (
            ""
          )}
          {filterRows && filterRows.length > 10 && (
            <TableDropdownAndPagination
                handleDropDown={handleDropDown}
                recordLen={recordLen}
                handleLeft={handleLeft}
                handleRight={handleRight}
                renderPageNumbers={renderPageNumbers}
                isDropdown={true}
                isPaggination={true}
            />
          )}
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        // fileName={fileName}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"Uploaded Documents"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dailylogs: state.dailylogs.dailylogs,
  };
};

export default connect(mapStateToProps)(DailyLogsListMobile);
