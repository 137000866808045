import { main } from "react-devtools";
import * as Yup from "yup";
export const formInputFields = (riskProfile, key) => [
  {
    name: `individualRisk.${key}.clt_risk_profile_master_id`,
    field: "clt_risk_profile_master_id",
    type: "dropdown",
    options: riskProfile,
    label: "Risk Profile Master Name",
    md: "6",
    sm: "12",
  },
  {
    name: `individualRisk.${key}.clt_risk_profile_careplan_id`,
    field: "clt_risk_profile_careplan_id",
    type: "hidden",
    md: "6",
    sm: "12",
  },
  {
    name: `individualRisk.${key}.clt_risk_profile_high`,
    type: "radio",
    label: "High",
    field: "clt_risk_profile_high",
    options: [
      { id: "1", name: "Yes" },
      { id: "0", name: "No" },
    ],
    md: "2",
    sm: "12",
  },
  {
    name: `individualRisk.${key}.clt_risk_profile_medium`,
    type: "radio",
    label: "Medium",
    field: "clt_risk_profile_medium",
    options: [
      { id: "1", name: "Yes" },
      { id: "0", name: "No" },
    ],
    md: "2",
    sm: "12",
  },
  {
    name: `individualRisk.${key}.clt_risk_profile_low`,
    type: "radio",
    label: "Low",
    field: "clt_risk_profile_low",
    options: [
      { id: "1", name: "Yes" },
      { id: "0", name: "No" },
    ],
    md: "2",
    sm: "12",
  },
];

export const validationSchema = Yup.object().shape({
  individualRisk: Yup.array().of(
    Yup.object().shape({
      clt_risk_profile_master_id: Yup.string()
        .required("Please Select Profile Name")
        .typeError("Please Select Profile Name"),
      clt_risk_profile_high: Yup.string()
        .required("Please Select Option")
        .typeError("Please Select Option"),
      clt_risk_profile_medium: Yup.string()
        .required("Please Select Option")
        .typeError("Please Select Option"),
      clt_risk_profile_low: Yup.string()
        .required("Please Select Option")
        .typeError("Please Select Option"),
    })
  ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
