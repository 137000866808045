const initialState = {myassignments: []}

export const myassignments = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_MYASSIGNMENTS':
      return {myassignments: action.payload}; 
  }
  return state
}


