import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Care-your-home-banner.jpg";
// import HomecareImg from "./../images/ourservices/Care-you-home.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/home-care/care-in-home/our-services-home-care-care-in-your-home.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/home-care/care-in-home/our-services-home-care-care-in-your-home-01.jpg";
// import Footer from '../components/Layout/Footer';
import bnr3 from "./../images/lines.png";

export default function Carecome() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Our Services - Care In Your Home";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/care-in-your-home"
            ? "0rem"
            : ""}}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Care In Your Home</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/care-in-your-home" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Home Care</li>
                  <li>Care In Your Home</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Care In Your Home </h2>
                  {/* <p>Why, hello there! Want to see more of our work? Check out just some of what we've been up to below.</p> */}
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                  <br />
                </div>
                <div
                  className="col-md-12 col-lg-6 m-b20"
                  style={{ marginBottom: "-2%" }}
                >
                  {/* <h2 className="m-b5"> Home Care</h2> */}
                  <p className="spacebar mt-2"></p>
                  <h5>
                    Experience Unmatched Personalized Home Care and Support with
                    Staffing Connect
                  </h5>

                  <p className="text-left">
                    Providing top-tier, personalized home care and support,
                    Staffing Connect is committed to ensuring your safety,
                    comfort, and independence in the familiarity of your own
                    home. We understand and value the uniqueness of each
                    individual, their preferences, and their distinct needs.
                    Hence, we place you firmly in the driver's seat, delivering
                    the specific care and assistance you require precisely when
                    and where you need it. After all, it's your life and care,
                    and it should unquestionably be your way.
                  </p>

                  <p className="text-left">
                    Our suite of care services is flexible and wide-ranging. We
                    offer everything from a single 60-minute visit to
                    comprehensive live-in care. Some clients seek short-term
                    assistance following an illness or when their regular
                    caregiver is unavailable. Others require extended home care
                    and support over a long period.
                  </p>
                </div>
              </div>
              <div className="row ">
                <ul className="col-md-12 ">
                  <h6 className="">
                    Our dedicated team of home care professionals are
                    comprehensively trained to provide an array of services,
                    including:
                  </h6>
                  <p className="m-l20">
                    <li className="m-l20">
                      {" "}
                      Starting your day on a positive note, assisting with
                      getting up, personal hygiene, dressing, and preparing
                      breakfast.
                    </li>
                    <li className="m-l20">
                      {" "}
                      Reminding or helping with medication, including collecting
                      prescriptions from your pharmacy or GP.
                    </li>
                    <li className="m-l20">
                      {" "}
                      Preparing meals with or for you, offering assistance
                      during mealtimes.
                    </li>
                    <li className="m-l20">
                      {" "}
                      Providing support with grocery shopping, list-making, and
                      tidying up afterwards.
                    </li>
                    <li className="m-l20">
                      {" "}
                      Helping keep your living space clean and organized,
                      assisting with laundry and ironing.
                    </li>
                    <li className="m-l20">
                      {" "}
                      Facilitating engagement in social activities such as
                      walks, day Centre visits, meeting friends or family, or
                      attending church or club events.
                    </li>
                    <li className="m-l20">
                      {" "}
                      Offering to pamper when needed and organizing occasional
                      bathing or grooming visits.
                    </li>
                    <li className="m-l20">
                      {" "}
                      Assisting with bedtime routines, ensuring you're
                      comfortably settled for the night.
                    </li>
                  </p>
                </ul>
              </div>
              <p className="text-left" style={{ marginTop: "-2%" }}>
                We also extend our home care services to children and their
                families, available on a regular basis or for short-term respite
                care, reinforcing our dedication to delivering quality, tailored
                care solutions that enhance lives.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
