import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Input from "../../FormElements/Input";
import Dropdown from "../../FormElements/Dropdown";
import Label from "../../Layout/Label";
import Textarea from "../../FormElements/Textarea";
const AddressFields = (props) => {
  const [userdata, setFormdata] = useState({});
  const { errors } = props;
  useEffect(() => {
    setFormdata(props.data);
  }, [props.data]);
  return (
    <React.Fragment>
      <Input
        type="text"
        label="Address"
        placeholder={"Eg., 123 Main St"}
        lg={12}
        md={12}
        id={"cnd_street_address"}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_street_address"
        value={userdata.cnd_street_address}
        errors={errors}
      />
      <Input
        type="text"
        lg={12}
        md={12}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_street_address_2"
        id={"cnd_street_address_2"}
        value={userdata.cnd_street_address_2}
        placeholder="Eg., Apartment, suite, unit etc. (optional)"
        errors={errors}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        name="cnd_town"
        id={"cnd_town"}
        lg={4}
        md={4}
        value={userdata.cnd_town}
        placeholder="Eg., London"
        errors={errors}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        name="cnd_region"
        id={"cnd_region"}
        lg={4}
        md={4}
        value={userdata.cnd_region}
        placeholder="Eg., London"
        errors={errors}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        name="cnd_postcode"
        lg={4}
        md={4}
        //maxLength={8}
        id={"cnd_postcode"}
        value={userdata.cnd_postcode}
        placeholder="Eg., AB12CD"
        errors={errors}
      />
      <Input
        type="text"
        name="cnd_county"
        id={"cnd_county"}
        value={userdata.cnd_county == "GB" || userdata.cnd_county == "UK" ? "UK" : "UK"}
        label="Country"
        disabled={true}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
        lg={6}
      />

      {/* Right To Work Dropdown Start */}
      {/* <Dropdown
        name="cnd_right_to_work"
        id={"cnd_right_to_work"}
        value={userdata.cnd_right_to_work}
        label="Do you have right to work in UK?"
        options={[
          {
            name: "Select the option",
            value: "",
          },
          {
            name: "Yes",
            value: "yes",
          },
          {
            name: "No",
            value: "no",
          }
        ]}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
        lg={6}
      /> */}
      {/* Right to Work Dropdown Stop */}

      <Dropdown
        name="cnd_drivinglicense"
        id={"cnd_drivinglicense"}
        value={userdata.cnd_drivinglicense}
        label="Do you have driving license?"
        options={[
          {
            name: "Select the option",
            value: "",
          },
          {
            name: "Yes",
            value: "1",
          },
          {
            name: "No",
            value: "0",
          },
        ]}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
        lg={6}
      />
      {/* Sensitive Information Part Start */}
      <Dropdown
        name="cnd_disability_condition"
        value={userdata.cnd_disability_condition}
        label="Disability/health condition relevant to the role"
        id={"cnd_disability_condition"}
        options={[
          {
            name: "Select Disability Condition",
            value: "",
          },
          {
            name: "Yes",
            value: "yes",
          },
          {
            name: "No",
            value: "no",
          },
        ]}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
        lg={12}
      />

      {userdata.cnd_disability_condition == "yes" && (
        <Textarea
          name="cnd_disability_description"
          value={userdata.cnd_disability_description}
          id={"cnd_disability_description"}
          label="Please write the description about Disability/health condition"
          changeHandler={(e) => props.onChange(e)}
          errors={errors}
          lg={12}
        />
      )}

      {/*Sensitive Part Stop  */}
    </React.Fragment>
  );
};

export default AddressFields;
