import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  updateDataOnRequest,
  deleteDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import { setError } from "../../../redux/actions/ErrorActions";
import { DocumentComplianceValidations } from "../../../helpers/DocumentComplianceValidations";
import DocForm from "./DocForm";
import { getBase64 } from "../../../helpers/FileToBase64";
import DocumentComplianceTable from "../Tables/DocumentComplianceTable";
const DocumentCompliance = (props) => {
  const { form, loader, userProfile } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputList, setInputList] = useState([]);
  const [radioOne, setAddressOne] = useState("No");
  // const [radioTwo, setAddressTwo] = useState('No');
  const handleInputChange = async (e, index) => {
    const { name, value, files } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (name == "cnd_compliance_upload_file") {
      list[index][name] = await getBase64(e.target.files[0]);
    }
    validationErrors();
    setInputList(list);
  };

  // const handleChangeAddress2One = (e, i) => {
  //   let formData = [...inputList];
  //   if(e == 'No'){
  //     // formData[i].cnd_street_address_2 = userProfile.cnd_street_address_2;
  //   }
  //   setInputList(formData);
  //   setAddressTwo(e);
  // }

  const handleChangeAddressOne = (e, i) => {
    let formData = [...inputList];
    if (e == "No") {
      formData[i].cnd_street_address = userProfile.cnd_street_address;
      formData[i].cnd_town = userProfile.cnd_town;
      formData[i].cnd_postcode = userProfile.cnd_postcode;
      formData[i].cnd_region = userProfile.cnd_region;
    }
    setInputList(formData);
    setAddressOne(e);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index, id) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    if (id) {
      dispatch(
        deleteDataOnRequest(
          {
            cnd_compliance_id: id,
            users_access_token: props.userAuth.users_access_token,
          },
          "DeleteDocumentCompliance",
          "",
          getDataOnRequest(
            props.userAuth.users_access_token,
            "SetDocumentCompliance",
            "SET_DOCUMENT_COMPLIANCE"
          ),
          id
        )
      );
    }
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        users_access_token: props.userAuth.users_access_token,
        cnd_compliance_doc_master_id: "",
        cnd_compliance_upload_file: "",
        cnd_compliance_description: "",
        cnd_compliance_exp_date: "",
        cnd_compliance_reference: "",
      },
    ]);
  };

  const validationErrors = () => {
    let validations = DocumentComplianceValidations(inputList, form);
    if (validations.error == true) {
      dispatch(setError(validations.stateData));
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validationErrors()) {
      dispatch(setError([]));
      dispatch(
        updateDataOnRequest(
          inputList,
          "updateDocumentCompliance",
          "",
          getDataOnRequest(
            props.userAuth.users_access_token,
            "SetDocumentCompliance",
            "SET_DOCUMENT_COMPLIANCE"
          )
        )
      );
      dispatch(
        getDataOnRequest(
          props.userAuth.users_access_token,
          "ProgressBar",
          "SET_PROGRESS"
        )
      );
      //history.push('/dbs-document');
    }
  };

  useEffect(() => {
    let dataArray = [];
    console.log(props.documentCompliance);
    if (props.documentCompliance.length > 0) {
      let formData = [];
      props.documentCompliance.map((compliance, i) => {
        formData[i] = compliance;

        // if(compliance.cnd_compliance_doc_master_id == 'nationalinsurance'){
        //   formData[0] = compliance;
        // }else if(compliance.cnd_compliance_doc_master_id == 'address1'){
        //   formData[1] = compliance;
        // }
        // else if(compliance.cnd_compliance_doc_master_id == 'address2'){
        //   formData[2] = compliance;
        // }

        if (!formData[i]) {
          formData[i] = {
            users_access_token:
              props.userAuth && props.userAuth.users_access_token,
            cnd_compliance_doc_master_id: "",
            cnd_compliance_upload_file: "",
            cnd_compliance_description: "",
            cnd_compliance_exp_date: "",
            cnd_compliance_reference: "",
          };
        }
      });

      // if (props.documentCompliance.length > 2) {
      //   props.documentCompliance.map((compliance, i)=>{
      //     formData[i] = compliance;
      //   });
      // }

      // if(!formData[1]){
      //   formData[1] = {
      //     users_access_token:
      //     props.userAuth && props.userAuth.users_access_token,
      //     cnd_compliance_doc_master_id: "address1",
      //     cnd_street_address: userProfile.cnd_street_address,
      //     cnd_postcode: userProfile.cnd_postcode,
      //     cnd_town: userProfile.cnd_town,
      //     cnd_region: userProfile.cnd_region,
      //     cnd_compliance_upload_file: "",
      //     cnd_compliance_description: "",
      //     cnd_compliance_exp_date: "",
      //     cnd_compliance_reference: "",
      //   }
      // }

      dataArray = [...formData];
      // console.log(dataArray);
    } else {
      dataArray = [
        {
          users_access_token:
            props.userAuth && props.userAuth.users_access_token,
          cnd_compliance_doc_master_id: "nationalinsurance",
          cnd_compliance_upload_file: "",
          cnd_compliance_description: "",
          cnd_compliance_exp_date: "",
          cnd_compliance_reference: "",
        },
        {
          users_access_token:
            props.userAuth && props.userAuth.users_access_token,
          cnd_compliance_doc_master_id: "address1",
          cnd_compliance_upload_file: "",
          cnd_street_address: userProfile.cnd_street_address,
          cnd_postcode: userProfile.cnd_postcode,
          cnd_town: userProfile.cnd_town,
          cnd_region: userProfile.cnd_region,
          cnd_compliance_description: "",
          cnd_compliance_exp_date: "",
          cnd_compliance_reference: "",
        },
        // {
        //   users_access_token:
        //     props.userAuth && props.userAuth.users_access_token,
        //   cnd_compliance_doc_master_id: "address2",
        //   cnd_compliance_upload_file: "",
        //   // cnd_street_address_2: userProfile.cnd_street_address_2,
        //   cnd_compliance_description: "",
        //   cnd_compliance_exp_date: "",
        //   cnd_compliance_reference: "",
        // },
      ];
    }
    setInputList(dataArray);
  }, [props.userAuth, props.documentCompliance]);

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <DocumentComplianceTable />
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Documents and Compliance
          </h5>
        </div>
        <DocForm
          inputList={inputList}
          loader={loader}
          onSubmit={(e) => onSubmit(e)}
          handleAddClick={(index) => handleAddClick(index)}
          handleRemoveClick={(index, id) => handleRemoveClick(index, id)}
          handleInputChange={(e, i) => handleInputChange(e, i)}
          errors={props.errors}
          userProfile={userProfile}
          radioOne={radioOne}
          // radioTwo={radioTwo}
          // handleChangeAddress2One={(e,i)=>handleChangeAddress2One(e,i)}
          handleChangeAddressOne={(e, i) => handleChangeAddressOne(e, i)}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userExperience.userData,
    userAuth: state.auth.authUserData,
    userProfile: state.userProfile.userData,
    documentCompliance: state.documentCompliance.documentCompliance,
    errors: state.error.error,
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(DocumentCompliance);
