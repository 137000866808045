import { getFileSizeInMbByID } from "../services/FileOperationGlobalFunctions";

let errorsObj = {
  cnd_work_job_title: "",
  cnd_work_qualification: "",
  cnd_work_mode_of_travel: "",
  cnd_work_travel_range: "",
  // cnd_work_minpay:''
};

export const WorkPrefrenceValidations = (data) => {
  let error = false;
  const errorObj = { ...errorsObj };
  if (data.cnd_work_job_title === "") {
    errorObj.cnd_work_job_title = "Job Title Field Required";
    error = true;
  }
  if (data.cnd_work_qualification === "") {
    errorObj.cnd_work_qualification = "Work Qualification Field is Required";
    error = true;
  } else {
    let cnd_qualification_documents_uploaded_file = document.getElementById(
      "cnd_qualification_documents_uploaded_file"
    );
    if (
      cnd_qualification_documents_uploaded_file &&
      cnd_qualification_documents_uploaded_file.value == ""
    ) {
      errorObj.cnd_qualification_documents_uploaded_file =
        "Please upload the qualification document!";
      error = true;
    } else if (
      cnd_qualification_documents_uploaded_file &&
      cnd_qualification_documents_uploaded_file.value != ""
    ) {
      let checkFileSize = getFileSizeInMbByID(
        "cnd_qualification_documents_uploaded_file"
      );
      if (checkFileSize > 2) {
        errorObj.cnd_qualification_documents_uploaded_file =
          "File size should not be greater than 2 MB!";
        error = true;
      }
    }
  }

  if (data.cnd_work_mode_of_travel === "") {
    errorObj.cnd_work_mode_of_travel = "Work Mode of Travel Field is Required";
    error = true;
  }
  if (data.cnd_work_travel_range === "") {
    errorObj.cnd_work_travel_range = "Work Travel Range Field is Required";
    error = true;
  } else if (
    data.cnd_work_travel_range &&
    data.cnd_work_travel_range.trim() == ""
  ) {
    errorObj.cnd_work_travel_range = "Enter the valid Work Travel Range";
    error = true;
  }
  // if (data.cnd_work_minpay === "") {
  //   errorObj.cnd_work_minpay = "Work Min Pay Field is Required";
  //   error = true;
  // }

  return { stateData: errorObj, error: error };
};
