import React from "react";
import { connect } from "react-redux";
import Error from "../../Layout/Error";
import Label from "../../Layout/Label";
import { Form, Col } from "react-bootstrap";
import Countries from "../Countries";
import { Input, Dropdown, Textarea } from "../../FormElements/Forms";
const DBSNoForm = (props) => {
  const { x, handleInputChange, i, errors, dbsType } = props;

  let errorMsgStartDate = false;
  let errorMsgEndDate = false;
  if (!x.cnd_address_start_date) {
    errorMsgStartDate = true;
  } else {
    let startDate = new Date(String(x.cnd_address_start_date));
    let currentDate = new Date();
    let cnd_address_start_date = document.getElementById(
      "cnd_address_start_date"
    );
    let minDate = new Date(String(cnd_address_start_date.min));
    if (startDate > currentDate) {
      errorMsgStartDate = true;
    } else if (minDate > startDate) {
      errorMsgStartDate = true;
    } else {
      errorMsgStartDate = false;
    }
  }

  // For End Date
  if (!x.cnd_address_end_date) {
    errorMsgEndDate = true;
  } else {
    let endDate = new Date(String(x.cnd_address_end_date));
    let currentDate = new Date();
    var d2 = new Date(x.cnd_address_end_date);
    var d1 = new Date(x.cnd_address_start_date);

    let cnd_address_end_date = document.getElementById("cnd_address_end_date");
    let minDate = new Date(String(cnd_address_end_date.min));

    if (endDate > currentDate) {
      errorMsgEndDate = true;
    } else if (minDate > endDate) {
      errorMsgEndDate = true;
    } else if (d1 >= d2) {
      errorMsgEndDate = true;
    } else {
      errorMsgEndDate = false;
    }
  }
  let addressCity = false;
  let addressCounty = false;
  let addressDesc = false;
  let addressPostcode = false;
  // For City
  if (
    !x.cnd_address_city ||
    (x.cnd_address_city && x.cnd_address_city.trim() == "")
  ) {
    addressCity = true;
  }

  // For County
  if (
    !x.cnd_address_county ||
    (x.cnd_address_county && x.cnd_address_county.trim() == "")
  ) {
    addressCounty = true;
  }

  // For PostCode
  if (
    !x.cnd_address_postcode ||
    x.cnd_address_postcode.trim() == "" ||
    !String(x.cnd_address_postcode).match(
      /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
    )
  ) {
    addressPostcode = true;
  }

  // For Address
  if (
    !x.cnd_address_address ||
    (x.cnd_address_address && x.cnd_address_address.trim() == "")
  ) {
    addressDesc = true;
  }

  return (
    <React.Fragment>
      <Input
        type="date"
        label="Start Date"
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_address_start_date"
        id={"cnd_address_start_date"}
        lg={6}
        md={6}
        min={
          new Date(new Date().setFullYear(new Date().getFullYear() - 63))
            .toISOString()
            .split("T")[0]
        }
        max={new Date().toISOString().split("T")[0]}
        value={x.cnd_address_start_date}
        placeholder="Eg., 10/10/2018"
        errors={errorMsgStartDate == true && errors[i]}
      />

      <Input
        type="date"
        label="End Date"
        placeholder={"Eg., 10/10/2019"}
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_address_end_date"
        id={"cnd_address_end_date"}
        lg={6}
        md={6}
        min={
          new Date(new Date().setFullYear(new Date().getFullYear() - 63))
            .toISOString()
            .split("T")[0]
        }
        max={new Date().toISOString().split("T")[0]}
        value={x.cnd_address_end_date}
        errors={errorMsgEndDate == true && errors[i]}
      />
      <Textarea
        label="Address"
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_address_address"
        lg={12}
        md={12}
        value={x.cnd_address_address}
        placeholder="Address"
        errors={
          addressDesc == true ? errors[i] : !x.cnd_address_address && errors[i]
        }
      />

      <Input
        type="text"
        label="City"
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_address_city"
        lg={6}
        md={6}
        value={x.cnd_dbs_birth_of_town}
        placeholder="Eg., London"
        errors={
          addressCity == true ? errors[i] : !x.cnd_address_city && errors[i]
        }
      />
      <Input
        type="text"
        label="County/State"
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_address_county"
        lg={6}
        md={6}
        value={x.cnd_address_county}
        placeholder="Eg., London"
        errors={
          addressCounty == true
            ? errors[i]
            : !x.cnd_address_county && errors[i]
        }
      />
      <Form.Group as={Col} md={6} sm={6}>
        <Label labelName="Country" />
        <Form.Control
          controlId={"cnd_address_country"}
          as="select"
          name="cnd_address_country"
          onChange={(e) => handleInputChange(e, i)}
          custom
          // defaultValue={x.cnd_address_country}
          value={x.cnd_address_country}
          style={{ width: "100%" }}
          isInvalid={
            !x.cnd_address_country &&
            errors[i] /* && errors[i].cnd_address_country?true:false*/
          }
        >
          {/*<option selected disabled value="">Select Country</option>*/}
          {x.cnd_dbs_country_of_birth && (
            <option selected value={x.cnd_dbs_country_of_birth}>
              {x.cnd_dbs_country_of_birth == "GB" ||
              x.cnd_dbs_country_of_birth == "uk"
                ? "United Kingdom"
                : x.cnd_dbs_country_of_birth}
            </option>
          )}
          <Countries />
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors[i] && errors[i].cnd_address_country}
        </Form.Control.Feedback>
      </Form.Group>

      <Input
        type="text"
        label="Post Code/Pin Code"
        changeHandler={(e) => handleInputChange(e, i)}
        name="cnd_address_postcode"
        lg={6}
        md={6}
        value={x.cnd_address_postcode}
        placeholder="Eg., AB12CD"
        errors={
          addressPostcode == true
            ? errors[i]
            : !x.cnd_address_postcode && errors[i]
        }
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
  };
};

export default connect(mapStateToProps)(DBSNoForm);
