import React, { useEffect, useState } from "react";
import Menusidebar from "../components/Element/Menusidebar";
//import Profilesidebar from '../components/Element/Profilesidebar';
import DailyLog from "../components/Element/DailyLogs";
import { isMobileOrLaptop } from "../services/CheckDevice";
import MenuSideBarMobile from "../components/Element/MenuSideBarMobile";
const DailyLogs = (props) => {
  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(350);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {checkDevice == "Mobile" ? (
                  <MenuSideBarMobile page="daily-logs" />
                ) : (
                  <Menusidebar page="daily-logs" />
                )}
                <div className="col-xl-9 col-lg-8 m-b30">
                  <DailyLog deviceInfo={checkDevice} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DailyLogs;
