import React, { useEffect, useState, Suspense } from "react";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {
  documentComplianceList,
  documentComplianceShortList,
} from "../../../helpers/DocumentsList";
import { Input, Textarea, Dropdown } from "../../FormElements/Forms";
import Label from "../../Layout/Label";
import { connect } from "react-redux";
const DocFormAddress1 = (props) => {
  const {
    inputList,
    handleInputChange,
    errors,
    onSubmit,
    handleAddClick,
    handleRemoveClick,
    loader,
    userProfile,
    hideNextButton,
    handleChangeAddressOne,
    handleChangeAddress2One,
    progress,
  } = props;

  const [dropdownValues, setDropdownValues] = useState(
    documentComplianceShortList
  );
  const [dropdown, setDropDown] = useState("");
  const [radioValue, setRadioValue] = useState("No");
  const [radioSecondValue, setRadioSecondValue] = useState("No");
  const [existing, setExisting] = useState([]);
  const history = useHistory();

  const handleInputDropDownChange = (e, i) => {
    handleInputChange(e, i);
    setDropDown(e.target.value);
  };

  const onChangeRadio = (e, i) => {
    console.log(e, i);
    setRadioValue(e);
    handleChangeAddressOne(e, i);
  };

  const onChangeSecondRadio = (e, i) => {
    setRadioSecondValue(e);
    handleChangeAddress2One(e, i);
  };

  const routeChange = () => {
    let path = `/document-required`;
    history.push(path);
  };

  useEffect(() => {
    var a = [];
    inputList.map((id) => {
      console.log(id.cnd_compliance_doc_master_id);
      a = [...a, id.cnd_compliance_doc_master_id];
      setExisting(a);
    });
  }, [inputList]);

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      // window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <form id="scrollFocusElement">
        <div className="row m-b30">
          <h6>Upload Additional Documents - (Optional)</h6>
          <br />
          <React.Fragment>
            {inputList.map((x, i) => (
              <React.Fragment key={i}>
                <div className="col-lg-12 col-md-12">
                  {inputList.length > 0 && <hr />}
                  {inputList.length !== 1 && i > 0 && (
                    <i
                      className="pull-right fa fa-trash red-icon"
                      onClick={(e) => handleRemoveClick(i)}
                    ></i>
                  )}

                  {inputList.length > 0 && (
                    <h6>
                      Additional document{" "}
                      {inputList.length > 1 ? "  - " + (i + 1) : ""}
                      {x.cnd_doc_name &&
                        (inputList.length == 1
                          ? " - (" + x.cnd_doc_name + ")"
                          : " (" + x.cnd_doc_name + ")")}
                    </h6>
                  )}
                </div>
                {/*<Form.Group
                  as={Col}
                  md="6"
                  controlId={"cnd_compliance_doc_master_id" + i}
                >
                  <Label labelName="Document Name" />
                  <Form.Control
                    required
                    as="select"
                    disabled={(i === 0 || i === 1) && true}
                    // disabled={(i === 0 || i === 1 || i === 2) && true}
                    name="cnd_compliance_doc_master_id"
                    custom
                    defaultValue={x.cnd_compliance_doc_master_id}
                    value={x.cnd_compliance_doc_master_id}
                    onChange={(e) => handleInputDropDownChange(e, i)}
                    isInvalid={
                      errors[i] && errors[i]["cnd_compliance_doc_master_id"]
                        ? true
                        : false
                    }
                    className={
                      errors[i] && errors[i]["cnd_compliance_doc_master_id"]
                        ? "invalid"
                        : "valid"
                    }
                  >
                    <option value="">Select Document Name</option>


                    {Object.keys((i > 1) ? dropdownValues : documentComplianceList).map((value) => {
                      return (
                        <>
                          {console.log("check", existing.includes(value), existing)}
                          {
                            (!(existing.includes(value))) ?
                              <option key={value} value={value}>{documentComplianceList[value]}</option>
                              : <option key={value} value={value} disabled style={{color:"red"}}>{documentComplianceList[value]}</option>
                          }
                        </>
                      )
                    }
                    )}

                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors[i] && errors[i]["cnd_compliance_doc_master_id"]}
                  </Form.Control.Feedback>
                  </Form.Group>*/}
                <Suspense fallback={<h1>Loading profile...</h1>}>
                  <Input
                    type="text"
                    label="Document Name"
                    changeHandler={(e) => handleInputChange(e, i)}
                    name="cnd_doc_name"
                    id={"cnd_doc_name"}
                    lg={6}
                    md={6}
                    errors={errors && errors[i]}
                  />
                  <Input
                    type="file"
                    label="Upload Document"
                    changeHandler={(e) => handleInputChange(e, i)}
                    name="cnd_compliance_upload_file"
                    id={"cnd_compliance_upload_file"}
                    lg={6}
                    md={6}
                    errors={errors && errors[i]}
                  />
                  <Input
                    type="text"
                    label="Document Reference Number"
                    changeHandler={(e) => handleInputChange(e, i)}
                    name="cnd_compliance_reference"
                    id={"cnd_compliance_reference"}
                    value={x.cnd_compliance_reference}
                    lg={12}
                    md={12}
                    placeholder="Reference"
                    errors={errors && errors[i]}
                    req={false}
                  />
                  {/*<Input
                  type="date"
                  label="Document Expiry Date"
                  changeHandler={(e) => handleInputChange(e, i)}
                  name="cnd_compliance_exp_date"
                  min={
                    new Date(Date.now() + 12096e5).toISOString().split("T")[0]
                  }
                  value={x.cnd_compliance_exp_date}
                  lg={6}
                  md={6}
                  placeholder="Eg., 10/10/2023"
                  // errors={errors && errors[i]}
                  req={false}
                />*/}
                </Suspense>
                <Textarea
                  label="Document Description"
                  changeHandler={(e) => handleInputChange(e, i)}
                  name="cnd_compliance_description"
                  value={x.cnd_compliance_description}
                  req={false}
                  lg={12}
                  md={12}
                  errors={errors && errors[i]}
                />

                {i === 1 && (
                  <>
                    {/*<label>Do you want to update address?</label>
                    {['Yes', 'No'].map((type) => (
                      <div key={`inline-${type}`} className="mb-6">
                        <Form.Check
                          inline
                          onChange={(e) => onChangeRadio(type, i)}
                          checked={radioValue == type}
                          label={type}
                          value={radioValue}
                          name="group"
                          type={'radio'}
                          id={`inline-${type}-address-1`}
                        />
                      </div>
                    ))}
                    <Input
                      type="text"
                      label="Address 1"
                      disabled={radioValue == 'No'}
                      changeHandler={(e) => handleInputChange(e, i)}
                      name="cnd_street_address"
                      value={x.cnd_street_address}
                      lg={12}
                      md={12}
                      placeholder="Address 1"
                      errors={errors && errors[i]}
                      req={false}
                    />
                    <Input
                      type="text"
                      label="Postal Code"
                      disabled={radioValue == 'No'}
                      changeHandler={(e) => handleInputChange(e, i)}
                      name="cnd_postcode"
                      value={x.cnd_postcode}
                      lg={4}
                      md={4}
                      placeholder="Postal Code"
                      errors={errors && errors[i]}
                      req={false}
                    />
                    <Input
                      type="text"
                      label="Town"
                      disabled={radioValue == 'No'}
                      changeHandler={(e) => handleInputChange(e, i)}
                      name="cnd_town"
                      value={x.cnd_town}
                      lg={4}
                      md={4}
                      placeholder="Town"
                      errors={errors && errors[i]}
                      req={false}
                    />
                    <Input
                      type="text"
                      label="Region"
                      disabled={radioValue == 'No'}
                      changeHandler={(e) => handleInputChange(e, i)}
                      name="cnd_region"
                      value={x.cnd_region}
                      lg={4}
                      md={4}
                      placeholder="Region"
                      errors={errors && errors[i]}
                      req={false}
                    />*/}
                  </>
                )}
                {/* {(i === 2) && 
                    <>
                      <label>Do you want to update address?</label>
                      {['Yes', 'No'].map((type) => (
                        <div key={`inline-${type}`} className="mb-6">
                          <Form.Check
                            inline
                            onChange={(e) => onChangeSecondRadio(type,1)}
                            label={type}
                            value={radioSecondValue}
                            checked={radioSecondValue == type}
                            name="group1"
                            type={'radio'}
                            id={`inline-${type}-1`}
                          />
                        </div>
                      ))}
                      <Input
                        type="text"
                        label="Address 2"
                        disabled={radioSecondValue == 'No'}
                        changeHandler={(e) => handleInputChange(e, i)}
                        name="cnd_street_address_2"
                        value={x.cnd_street_address_2}
                        lg={12}
                        md={12}
                        placeholder="Address 2"
                        errors={errors && errors[i]}
                        req={false}
                      />
                    </>  
                  
                  } */}

                <div className="col-lg-12 col-md-12"></div>
                <div className="col-lg-12 col-md-12">
                  {inputList.length - 1 === i && (
                    <Button
                      disabled={loader ? "disabled" : ""}
                      className="site-button m-b30 pull-right"
                      onClick={(e) => onSubmit(e)}
                    >
                      {loader && (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      )}
                      {!loader ? "Save" : "Loading..."}
                    </Button>
                  )}

                  {inputList.length - 1 === i && (
                    <a
                      className="site-button yellow pull-right m-b30 mr-2"
                      href="/document-required"
                    >
                      {loader && (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      )}
                      Skip
                    </a>
                  )}
                  {inputList.length - 1 === i && (
                    <button
                      className="site-button purple m-b30 pull-right mr-2"
                      onClick={(e) => handleAddClick(i)}
                    >
                      Add More
                    </button>
                  )}
                  {/* {inputList.length - 1 === i && (
                    <Link disabled={progress.progress_bar_compliance_7 == "" ? "disabled" : ""}   to={"/document-required"} className="site-button m-b30 m-l20" >Next</Link>
                  )} */}

                  {/* {(inputList.length - 1 === i) && (
            <Button
              onClick={routeChange}
              disabled={progress.progress_bar_compliance_7 == "1" ? "" : "disabled"} 
              className="site-button m-b30 m-l20"
            > Next
        </Button>
         )}  */}
                </div>
              </React.Fragment>
            ))}
          </React.Fragment>
        </div>
      </form>
    </React.Fragment>
  );
};

// export default DocFormAddress1;
const mapStateToProps = (state) => {
  return {
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(DocFormAddress1);
