import React, { useEffect, useState } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { isAuthenticated } from "../redux/selectors/AuthSelectors";
import PageHeader from "../components/Layout/PageHeader";
import Footer from "../components/Layout/Footer";
import { Link } from "react-router-dom";

// import { ProgressBar } from 'react-bootstrap';
const CommonCandidateRoutes = ({ cheader: CHeader, component: Component, ...rest }) => {
  const [loginType, setLoginType] = useState('candidate')
  let userType = localStorage.getItem("user_job_type");
  let userJobSector = localStorage.getItem("users_category");
  useEffect(() => {
    if (rest.userAuth.users_role_id)
      setLoginType(rest.userAuth.users_role_id)
  }, [rest.userAuth])
  return (
    <React.Fragment>
      {/* <ProgressBar now={60} variant={"success"} label={"60%"}
       style={{ marginTop: '8rem', width: '80%', marginLeft:'10%', marginRight:'10%' }} /> */}
        {/* <div className="sticky-header main-bar-wraper navbar-expand-lg"> */}
          {/* <div className="main-bar clearfix"> */}
            <div className="container clearfix" style={{ marginTop: window.location.pathname != "/delete-account-otp-verification" ? '6rem' : ""}}>
              {/* <div className="logo-header mostion"> */}
              {/*<Link to="/dashboard" 
                   style={{ marginTop: '10rem',marginBottom: '-2rem', marginRight:'0%', icon:"arrow-left-circle" , float:"right"}} 
                   className="btn btn-primary"><i className="fa fa-home" aria-hidden="true"></i>  Home {/*Back To Home}
      </Link>*/}
              {/* </div> */}
            {/* </div> */}
          {/* </div> */}
        </div>
      
      <Route {...rest} render={props => (
        (typeof rest.isAuthenticated != undefined && rest.isAuthenticated && loginType == 'candidate') ?
          (typeof rest.header != 'undefined' && rest.header == false) ?
            <>
              {rest.loader && <div className="loading"></div>}
              <Component {...props} />
            </> :
            <React.Fragment>
              {(CHeader) ? <CHeader {...props} /> : <PageHeader />}
              {rest.loader && <div className="loading"></div>}
              <Component {...props} />
              <Footer />
            </React.Fragment>
          : <Redirect to="/login" />
      )} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    loader: state.loader.loader,
    userAuth: state.auth.authUserData
  };
};

export default withRouter(connect(mapStateToProps)(CommonCandidateRoutes));
