import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, Route } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import { Modal } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../services/StringOperation";
import GlobalViewFileModal from "../../services/GlobalViewFileModal";
import { redirectUrl } from "../../services/GlobalUrls";
import { checkIfFileIsaDocument } from "../../services/FileOperationGlobalFunctions";
import Swal from "sweetalert2";
import { getInitialsLettersOfString } from "../../services/GlobalStringOp";
// Navbar Bottom - Start
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import SwipeableTemporaryDrawer from "../../services/DrawerMenus";
// Navbar Bottom - End
const Profilesidebar = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { progress, userData, dbsDocuments } = props;
  const [myProfileMenu, setMyProfileMenu] = useState(false);
  //   Navbar Bottoms - Start
  const [isScrolling, setIsScrolling] = useState(false);
  const [value, setValue] = useState("");
  const ref = useRef(null);
  const [touchMove, setTouchMove] = useState("false");
  // Navbar Bottoms - End
  // Modal States - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // Modal State - End
  let usersCategory = localStorage.getItem("users_category");
  const [formStates, setFormStates] = useState({
    userProfile: false,
    employmentReferences: false,
    typesOfServices: false,
    agreements: false,
    bankDetails: false,
    rightToWork: false,
    workPreferences: false,
    documentsCompliance: false,
    dbsCertificate: false,
    identityDocument: false,
  });
  const [progresStatus, setProgress] = useState(0);
  useEffect(() => {
    setFormStates({
      userProfile: progress.progress_bar_profile_1 ? true : false,
      employmentReferences: progress.progress_bar_reference_2 ? true : false,
      typesOfServices: userData.cnd_types_of_services !== "" ? true : false,
      agreements: progress.progress_bar_agreement_3 ? true : false,
      bankDetails: progress.progress_bar_bank_4 ? true : false,
      rightToWork: progress.progress_bar_right_to_work_5 ? true : false,
      workPreferences: progress.progress_bar_work_preference_6 ? true : false,
      documentsCompliance: progress.progress_bar_compliance_7 ? true : false,
      dbsCertificate: progress.progress_bar_dbs_8 ? true : false,
      identityDocument: progress.progress_bar_identity_9 ? true : false,
    });
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 10;
    }

    if (progress.progress_bar_reference_2 == "1") {
      totalProgress = totalProgress + 10;
    }
    // if(progress.cnd_types_of_services !== ""){
    //   totalProgress = totalProgress+10
    // }
    if (progress.progress_bar_agreement_3 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_bank_4 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_right_to_work_5 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_work_preference_6 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_compliance_7 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_dbs_8 == "1") {
      totalProgress = totalProgress + 15;
    }
    if (progress.progress_bar_identity_9 == "1") {
      totalProgress = totalProgress + 15;
    }

    setProgress(totalProgress);
  }, [progress, userData]);
  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    users_category,
    users_access_token,
    users_profile_pic,
    users_profile_img_path,
    user_sign_signature,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "135px";
      imageRef.current.style.height = "135px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };
  // Modal Function - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };
  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // Modal Function - End

  const scrollNavTab = (page) => {
    const element = document.getElementById(page);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };
  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
    if (page) {
      scrollNavTab(page);
    }
  }, [props.userData, page]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };

  const handleLink = (e, link) => {
    setShow(true);
  };
  const handleClose = () => setShow(false);

  //   Navbar Menus - Start
  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      //   if (isScrolling == false) {
      scrollNavTab(page);
      // Clear the previous timeout if it's still running
      if (timeoutId) clearTimeout(timeoutId);

      // Set isScrolling to true when scroll event is detected
      setIsScrolling(true);

      // Set a timeout to change isScrolling to false after scrolling stops
      timeoutId = setTimeout(() => {
        // Check if the page is at the top or bottom
        const atTop = window.scrollY === 0;
        const atBottom =
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight;

        // if (!atTop && !atBottom) {
        //   setIsScrolling(false);
        // }
        setIsScrolling(touchMove == "true" ? true : false);
        // console.log("Touch Move:=> " + touchMove);
      }, 6000); // Adjust the timeout as needed
      //   }
    };

    window.addEventListener("scroll", handleScroll);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [touchMove]);
  //   Navbar Menus - End
  // sticky-top

  const [menuList, setMenuList] = useState([
    {
      name: "My Profile",
      icon: "fa fa-user-circle-o",
      url: "/job-profile",
      divider: false,
    },
    {
      name: "Work Preference",
      icon: "fa fa-object-ungroup",
      url: "/work-prefrences",
      divider: false,
    },
    {
      name: "Employment and professional reference",
      icon: "fa fa-briefcase",
      url: "/employment-and-professional-references",
      divider: false,
    },
    {
      name: "Bank Details",
      icon: "fa fa-file-text-o",
      url: "/bank-details",
      divider: false,
    },
    {
      name: "Right To Work",
      icon: "fa fa-check-circle-o",
      url: "/right-to-work",
      divider: false,
    },
    {
      name: "Documents Required",
      icon: "fa fa-id-card-o",
      url: "/document-required",
      divider: false,
    },
    {
      name: "DBS Certificate",
      icon: "fa fa-list-alt",
      url: "/dbs-document",
      divider: false,
    },
    {
      name: "Identity Documents",
      icon: "fa fa-id-badge",
      url: "/identity-document",
      divider: false,
    },
    {
      name: "Agreements",
      icon: "fa fa-print",
      url: "/agreements",
      divider: false,
    },
    {
      name: "Sign Contract",
      icon: "fa fa-pencil-square-o",
      url: "/sign-contract",
      divider: false,
    },
  ]);

  // Handle Drawer State Change - Start
  const handleDrawerStateChange = (state) => {
    setMyProfileMenu(state);
  };
  // Handle Drawer State Change - End

  return (
    <>
      <div className="profile-modal">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Coming Soon!</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body></Modal.Body> */}
        </Modal>
      </div>
      <div className="col-xl-3 col-lg-4 m-b30">
        <div className="wrapper">
          <div className="candidate-info">
            <div className="candidate-detail text-center">
              <div
                className={
                  "canditate-des " +
                  (users_profile_img_path && users_profile_pic ? "" : "bg-dark")
                }
              >
                <CircularProgressbarWithChildren
                  className="CircularProgressbar-background"
                  value={progresStatus}
                >
                  {users_profile_img_path && users_profile_pic ? (
                    <img
                      className="size-thumbnail1 rounded-circle cursor-pointer"
                      width={"135px"}
                      height={"135px"}
                      ref={imageRef}
                      onClick={() => {
                        if (users_profile_img_path && users_profile_pic) {
                          viewFileModal(
                            constants.IMAGE_URL +
                              users_profile_img_path +
                              users_profile_pic
                          );
                        } else {
                          Swal.fire({
                            title: "Profile Picture Not Available",
                            text: "",
                            icon: "warning",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                            // confirmButtonColor: 'blue'
                          });
                        }
                      }}
                      src={require("./../../images/team/pic1.jpg")}
                    />
                  ) : (
                    <div className="mt-4 text-white">
                      <h1 style={{ fontSize: "60px" }}>
                        {getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        ) &&
                        getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        ) != "UU" ? (
                          getInitialsLettersOfString(
                            users_firstname + " " + users_lastname
                          )
                        ) : (
                          <i
                            class="fa fa-spinner text-center fa-pulse text-white"
                            style={{ fontSize: "80px" }}
                          ></i>
                        )}
                      </h1>
                    </div>
                  )}
                </CircularProgressbarWithChildren>
                <div style={{ fontSize: 18, marginTop: 5 }}>
                  <strong>{progresStatus}%</strong> Completed
                </div>
                <div
                  className="upload-link border"
                  title="Upload Image"
                  data-toggle="tooltip"
                  data-placement="right"
                  style={{ marginTop: "65%" }}
                >
                  <input
                    type="file"
                    className="update-flie"
                    id="users_profile_pic"
                    // onChange={(e) => onChangeInputvalue(e)}
                    accept=".png,.jpeg,.jpg"
                  />
                  <i className="fa fa-camera"></i>
                </div>
              </div>

              <div className="candidate-title">
                <div className="">
                  <h4 className="m-b5">
                    <b>{users_firstname}</b>
                  </h4>
                  <p className="m-b0">
                    <b>
                      {props.userAuth.users_category
                        ? capitalizeFirstLetter(
                            String(props.userAuth.users_category)
                          )
                        : ""}
                    </b>
                  </p>
                </div>
              </div>
            </div>
            <div
              className=""
              onTouchStart={() => setTouchMove("true")}
              onTouchEnd={() => setTouchMove("false")}
            >
              {isScrolling == true && (
                <Box ref={ref}>
                  <Paper
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      overflowX: "auto",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      opacity: 1,
                      zIndex: 10,
                    }}
                    elevation={3}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        overflowX: "auto",
                      }}
                    >
                      <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onMouseEnter={() => setIsScrolling(true)}
                      >
                        <BottomNavigationAction
                          label={
                            String("Dashboard").length <= 10
                              ? String("Dashboard")
                              : String("Dashboard").slice(0, 10) + "..."
                          }
                          icon={
                            <i
                              className="fa fa-bar-chart"
                              style={{ fontSize: "15px", fontWeight: "500" }}
                            ></i>
                          }
                          // className={page == "profile" ? "bg-dark text-white" : ""}
                          onClick={() => redirectUrl("/dashboard")}
                        />

                        <BottomNavigationAction
                          label={
                            String("My Profile").length <= 10
                              ? String("My Profile")
                              : String("My Profile").slice(0, 10) + "..."
                          }
                          id="job-profile"
                          icon={
                            <>
                              {myProfileMenu == true ? (
                                <i
                                  className="fa fa-chevron-down"
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-chevron-up"
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                  }}
                                ></i>
                              )}
                            </>
                          }
                          className={
                            page == "profile" ? "bg-dark text-white" : ""
                          }
                          onClick={() => setMyProfileMenu(true)}
                        />

                        <BottomNavigationAction
                          label={
                            String("Timesheet").length <= 10
                              ? String("Timesheet")
                              : String("Timesheet").slice(0, 10) + "..."
                          }
                          id="timesheet"
                          icon={
                            <>
                              <i
                                className="fa fa-calendar"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "timesheet" ? "bg-dark text-white" : ""
                          }
                          onClick={() => redirectUrl("/timesheet")}
                        />

                        <BottomNavigationAction
                          label={
                            String("My Availability").length <= 10
                              ? String("My Availability")
                              : String("My Availability").slice(0, 10) + "..."
                          }
                          id="availability"
                          icon={
                            <>
                              <i
                                className="fa fa-calendar-check-o"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "availability" ? "bg-dark text-white" : ""
                          }
                          onClick={() => redirectUrl("/availability")}
                        />

                        <BottomNavigationAction
                          label={
                            String("Daily Logs").length <= 10
                              ? String("Daily Logs")
                              : String("Daily Logs").slice(0, 10) + "..."
                          }
                          id="daily-logs"
                          icon={
                            <>
                              <i
                                className="fa fa-comment-o"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "daily-logs" ? "bg-dark text-white" : ""
                          }
                          onClick={() => redirectUrl("/daily-logs")}
                        />

                        <BottomNavigationAction
                          label={
                            String("My Payroll").length <= 10
                              ? String("My Payroll")
                              : String("My Payroll").slice(0, 10) + "..."
                          }
                          id="my-payroll"
                          icon={
                            <>
                              <i
                                className="fa fa-address-card-o"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "my-payroll" ? "bg-dark text-white" : ""
                          }
                          onClick={handleLink}
                        />

                        <BottomNavigationAction
                          label={
                            String("My Assignments").length <= 10
                              ? String("My Assignments")
                              : String("My Assignments").slice(0, 10) + "..."
                          }
                          id="my-assignments"
                          icon={
                            <>
                              <i
                                className="fa fa-sticky-note-o"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "my-assignments" ? "bg-dark text-white" : ""
                          }
                          onClick={() => redirectUrl("/my-assignments")}
                        />

                        <BottomNavigationAction
                          label={
                            String("Jobs Available").length <= 10
                              ? String("Jobs Available")
                              : String("Jobs Available").slice(0, 10) + "..."
                          }
                          id="jobs-available"
                          icon={
                            <>
                              <i
                                className="fa fa-bullhorn"
                                style={{ fontSize: "15px", fontWeight: "500" }}
                              ></i>
                            </>
                          }
                          className={
                            page == "jobs-available" ? "bg-dark text-white" : ""
                          }
                          onClick={handleLink}
                        />
                      </BottomNavigation>
                    </Box>
                  </Paper>
                </Box>
              )}
            </div>
          </div>
        </div>
        <SwipeableTemporaryDrawer
          drawerMenuList={menuList}
          drawerDirection={"top"}
          drawerState={myProfileMenu}
          onDrawerStateChange={handleDrawerStateChange}
        />
        <GlobalViewFileModal
          modalOption={modalOption}
          closeFileModal={closeFileModal}
          fileUrl={fileUrl}
          loaderEnd={loaderEnd}
          modalHeight={modalHeight}
          callModal={callModal}
          title={"Profile Picture"}
          // fileName={fileName}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(Profilesidebar);
