import { postRequestData } from "../../middleware/GetRequestData";
import swal from "sweetalert";
import { toast } from "react-toastify";
import axios from "axios";
export const SET_ERROR = "SET_ERROR";
export const SET_AUTH_DATA = "SET_AUTH_DATA";

const authInfoSuccess = (content) => {
  return {
    type: SET_AUTH_DATA,
    payload: content.data.data,
  };
};
const failureAuth = (data) => {
  return {
    type: SET_ERROR,
    payload: data.data.msg,
  };
};

export const loadSignUpInfo = (postData) => {
  return async (dispatch) => {
    dispatch(failureAuth({ data: { msg: "" } }));
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const authInfo = await postRequestData("Signup", postData, "");
      if (
        typeof authInfo.data.status != undefined &&
        authInfo.data.status == "success"
      ) {
        await swal({
          icon: "success",
          title: "Hi " + authInfo.data.data.users_firstname,
          // text:'Thank you for joining Staffing Connect \nPlease login using your Credentials!'
          text: "Please check your inbox and verify the OTP!",
        }).then((document.getElementById("loader").style.display = "none"));
        dispatch({ type: "SET_LOADER", payload: false });
        window.location.href = "/otp-verification";
      } else {
        if (authInfo.data.status == "warning") {
          await swal({
            icon: authInfo.data.status ? authInfo.data.status : "success",
            title: authInfo.data.title,
            text: authInfo.data.msg,
            buttons: {
              Ok: {
                checkProf: true,
              },
            },
            //defeat:true,
            //buttons:false,
            closeOnClickOutside: true,
          }).then((checkProf) => {
            document.getElementById("loader").style.display = "none";
            if (authInfo.data.data == "warning") {
              // window.location.href="/otp-verification"
            } else {
              window.location.href = "/otp-verification";
            }
          });
        } else {
          await swal({
            icon: authInfo.data.status,
            text: authInfo.data.msg,
            buttons: {
              Ok: {
                checkProf: true,
              },
            },
            //defeat:true,
            //buttons:false,
            closeOnClickOutside: true,
          }).then((checkProf) => {
            document.getElementById("loader").style.display = "none";
            localStorage.clear();
            window.location.href = "/login";
          });
        }

        dispatch({ type: "SET_LOADER", payload: false });
        dispatch(failureAuth(authInfo));
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failureAuth({ data: { msg: "Invalid Request" } }));
    }
  };
};

export const loadloginInfo = ({ email, password, userType, history }) => {
  const postData = {
    users_email: email,
    users_password: password,
    users_role_id: userType,
  };

  /*const postRequestAxiosData = (auth, data, config) =>{
    return axios.post("http://localhost/staffingconnect_api/login/verifyuser", {
      data,
      headers: { 'content-type': 'application/x-www-form-urlencoded'}
    }).then((response) =>{
      console.log(response);
      return response;
    }).catch((error) =>{
      console.log(error);
      return error;
    })
    
  }*/

  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    dispatch(failureAuth({ data: { msg: "" } }));
    try {
      const authInfo = await postRequestData("Auth", postData, "");
      // console.log("Authinfo",authInfo);
      if (
        typeof authInfo.data.status != undefined &&
        authInfo.data.status == "success"
      ) {
        dispatch(authInfoSuccess(authInfo));
        saveTokenInLocalStorage({
          access_token: authInfo.data.data.users_access_token,
          isLogin: true,
        });
        if (authInfo.data.data.users_role_id == "client") {
          window.location.href = "/client-dashboard";
        } else {
          localStorage.setItem("user_job_type", authInfo.data.data.job_type);
          localStorage.setItem(
            "users_category",
            authInfo.data.data.users_category
          );
          if (authInfo.data.data.job_type == "permanentrequirement") {
            const applyJobsValues = JSON.parse(
              localStorage.getItem("applyJobDetails")
            );
            if (applyJobsValues) {
              document.getElementById("loader").style.display = "none";
              history.push({
                pathname: applyJobsValues.pathname,
                state: applyJobsValues.state,
              });
            } else {
              if (
                authInfo.data.data.cnd_status == "" ||
                authInfo.data.data.cnd_status == "Pending"
              ) {
                localStorage.setItem("applyJobDetails", "");
                localStorage.setItem(
                  "permanentUserStatus",
                  authInfo.data.data.cnd_status
                );
                // window.location.href = "/permanent-job-profile";
                window.location.href = "/candidate-profile";
                // window.location.href = "/job-board";
              } else {
                localStorage.setItem("applyJobDetails", "");
                // window.location.href = "/applied-jobs";
                // window.location.href = "/permanent-candidate-dashboard";
                // window.location.href = "/job-board";
                window.location.href = "/candidate-profile";
              }
            }
          } else {
            const applyJobsValues = JSON.parse(
              localStorage.getItem("applyJobDetails")
            );
            if (applyJobsValues) {
              document.getElementById("loader").style.display = "none";
              history.push({
                pathname: applyJobsValues.pathname,
                state: applyJobsValues.state,
              });
            } else {
              localStorage.setItem("applyJobDetails", "");
              localStorage.setItem(
                "tempUserStatus",
                authInfo.data.data.cnd_status
              );
              // if (
              //   authInfo.data.data.cnd_profile_is_temping == "Allowed" &&
              //   (authInfo.data.data.cnd_status == "Pending" ||
              //     authInfo.data.data.cnd_status == "In-Progress")
              // ) {
              //   window.location.href = "/dashboard";
              // } else {
              //   // window.location.href = "/job-board";
              //   window.location.href = "/candidate-profile";
              // }
              window.location.href = "/dashboard";
            }
          }
        }
      } else if (authInfo.data.status == "otp_required") {
        await swal({
          icon: "warning",
          title: authInfo.data.title,
          text: authInfo.data.msg,
          buttons: {
            Ok: {
              checkProf: true,
            },
          },
          //defeat:true,
          //buttons:false,
          closeOnClickOutside: true,
        }).then((checkProf) => {
          window.location.href = "/otp-verification";
        });
      } else {
        await swal({
          icon: authInfo.data.status,
          title: authInfo.data.title,
          text: authInfo.data.msg,
        }).then((document.getElementById("loader").style.display = "none"));
        dispatch(failureAuth(authInfo));
        dispatch({ type: "SET_LOADER", payload: false });
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failureAuth({ data: { msg: "Invalid Request" } }));
    }
  };
};

export const setUserdataWhenUserLoggedIn = (accessToken, history) => {
  return async (dispatch) => {
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      dispatch({ type: "SET_LOADER", payload: false });
      const authInfo = await postRequestData(
        "AuthAPI",
        { users_access_token: accessToken },
        ""
      );
      dispatch(authInfoSuccess(authInfo));
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch(failureAuth({ data: { msg: "Invalid Request" } }));
      removeTokenInLocalStorage();
      history.push("/login");
    }
  };
};

const saveTokenInLocalStorage = (tokenDetails) => {
  localStorage.setItem("loginUserDetails", JSON.stringify(tokenDetails));
};

const removeTokenInLocalStorage = () => {
  localStorage.removeItem("loginUserDetails");
};

const successToast = (msg) => {
  toast.success(msg, { backgroundColor: "green", color: "white" });
};
const errorToast = (msg) => {
  toast.error(msg, { backgroundColor: "red", color: "white" });
};
