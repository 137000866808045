import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  dbsCertificates,
  dbsDocumentName,
} from "../../../helpers/DocumentsList";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";

const DBSTableNo = (props) => {
  const [dbsdocuments, SetDbsdocuments] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.dbsdocuments.length;
    // console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetDbsdocuments(props.dbsdocuments);
  }, [props.dbsdocuments]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        {/* Search Start */}
          <div className="container">
            <div className="row">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <label class="form-control-label text-right" for="search">
                  Search
                </label>
                <div class="form-group form-group-default">
                  <input
                    type="text"
                    name="search"
                    class="form-control "
                    placeholder="Enter key to search"
                    id="myInput"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Search End */}
        <table className="table table-striped table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              <>
                {/*<th className="col-1 text-left">Sr. No</th>*/}
                <th className="col-2 text-center">Date & Time</th>

                <th className="col-2 text-center">DBS Address</th>
                <th className="col-2 text-center">Status</th>
                {/* <th className="col-2 text-center">DBS Enhanced Certificate</th> */}
              </>
            </tr>
          </thead>
          <tbody id="myTable">
            {dbsdocuments &&
              dbsdocuments.slice(startIndex, endIndex).map((work, i) => (
                <tr key={i}>
                  {/*<td className="col-1 text-center" scope="row">
                  {i + 1}
              </td>*/}
                  <td className="col-3 text-center">
                    {work.cnd_address_created_date
                      ? work.cnd_address_created_date
                      : "-"}
                  </td>
                  <td className="col-4 text-center">
                    {work.cnd_address_address}
                  </td>
                  <td className="col-1 text-center">{work.cnd_dbs_status}</td>
                </tr>
              ))}
            {dbsdocuments.length == 0 && (
              <tr>
                <td colSpan="100">No DBS Record Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
        {dbsdocuments && dbsdocuments.length > 0 && (
          <TableDropdownAndPagination
            handleDropDown={handleDropDown}
            recordLen={recordLen}
            handleLeft={handleLeft}
            handleRight={handleRight}
            renderPageNumbers={renderPageNumbers}
            isDropdown={true}
            isPaggination={true}
          />
        )}
        <Link to={"/identity-document"} className="site-button listingnextbtn">
          Next
        </Link>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dbsdocuments: state.dbsdocuments.dbsdocuments,
  };
};

export default connect(mapStateToProps)(DBSTableNo);
