const initialState = {assesmentShifts: {}}

export const assesmentShifts = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_ASSESMENT':
      return {assesmentShifts: action.payload}; 
  }
  return state
}


