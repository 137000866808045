let host = window.location.host;
let url;
let imageUrl;
if(host == "www.staffingconnect.co.uk" || host == "staffingconnect.co.uk"){
    url = "https://api.staffingconnect.co.uk/";
    imageUrl = "https://api.staffingconnect.co.uk/";
}else{
    url = "https://api.itstaffingsolutions.co.uk/";
    imageUrl = "https://api.itstaffingsolutions.co.uk/";
}
export default Object.freeze({
    /* Server API URL AWS EC2*/
    
    API_BASE_URL: url,
    IMAGE_URL: imageUrl
    // Old Links
    // API_BASE_URL:"https://api.itstaffingsolutions.co.uk/",
    // IMAGE_URL:"https://api.itstaffingsolutions.co.uk/"

    /*Plesk Api Urls */
    //API_BASE_URL:"https://itstaffingsolutions.co.uk/",
    //IMAGE_URL:"https://itstaffingsolutions.co.uk/api/"    

    /* Local API URL */
    //API_BASE_URL:"http://localhost/staffingconnect_api/",
    //IMAGE_URL:"http://localhost/staffingconnect_api/api/"
})

