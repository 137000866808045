import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, Route } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useDispatch, connect } from "react-redux";
import constants from "../../constants";
import { updateDataOnRequest } from "../../redux/actions/CommonActions";
import { Modal } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { checkIfFileIsaDocument } from "../../services/FileOperationGlobalFunctions";
import { redirectUrl } from "../../services/GlobalUrls";
import GlobalViewFileModal from "../../services/GlobalViewFileModal";
import { getInitialsLettersOfString } from "../../services/GlobalStringOp";
import { capitalizeFirstLetter } from "../../services/StringOperation";
const AccountCentreSidebar = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  let userType = localStorage.getItem("user_job_type");
  const { progress, userData, dbsDocuments } = props;
  // Modal - Start
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // Modal - End
  const [formStates, setFormStates] = useState({
    userProfile: false,
    employmentReferences: false,
    typesOfServices: false,
    agreements: false,
    bankDetails: false,
    rightToWork: false,
    workPreferences: false,
    documentsCompliance: false,
    dbsCertificate: false,
    identityDocument: false,
  });
  const [progresStatus, setProgress] = useState(0);
  useEffect(() => {
    setFormStates({
      userProfile: progress.progress_bar_profile_1 ? true : false,
      employmentReferences: progress.progress_bar_reference_2 ? true : false,
      typesOfServices: userData.cnd_types_of_services !== "" ? true : false,
      agreements: progress.progress_bar_agreement_3 ? true : false,
      bankDetails: progress.progress_bar_bank_4 ? true : false,
      rightToWork: progress.progress_bar_right_to_work_5 ? true : false,
      workPreferences: progress.progress_bar_work_preference_6 ? true : false,
      documentsCompliance: progress.progress_bar_compliance_7 ? true : false,
      dbsCertificate: progress.progress_bar_dbs_8 ? true : false,
      identityDocument: progress.progress_bar_identity_9 ? true : false,
    });
    let totalProgress = 0;

    if (progress.progress_bar_profile_1 == "1") {
      totalProgress = totalProgress + 10;
    }

    if (progress.progress_bar_reference_2 == "1") {
      totalProgress = totalProgress + 10;
    }
    // if(progress.cnd_types_of_services !== ""){
    //   totalProgress = totalProgress+10
    // }
    if (progress.progress_bar_agreement_3 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_bank_4 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_right_to_work_5 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_work_preference_6 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_compliance_7 == "1") {
      totalProgress = totalProgress + 10;
    }
    if (progress.progress_bar_dbs_8 == "1") {
      totalProgress = totalProgress + 15;
    }
    if (progress.progress_bar_identity_9 == "1") {
      totalProgress = totalProgress + 15;
    }

    setProgress(totalProgress);
  }, [progress, userData]);
  const dispatch = useDispatch();
  const {
    users_firstname,
    users_lastname,
    users_category,
    users_access_token,
    users_profile_pic,
    users_profile_img_path,
    user_sign_signature,
  } = props.userData;
  const { page } = props;
  let imageRef = useRef(null);

  const onChangeInputvalue = (e) => {
    let formdata = {};
    const fd = new FormData();
    fd.append("users_profile_pic", e.target.files[0], e.target.files[0].name);
    fd.append("users_access_token", props.userAuth.users_access_token);
    let reader = new FileReader();
    reader.onload = (e) => {
      imageRef.current.src = e.target.result;
      imageRef.current.style.width = "135px";
      imageRef.current.style.height = "135px";
    };
    reader.readAsDataURL(e.target.files[0]);
    dispatch(updateDataOnRequest(fd, "CustomerImageUpdate", ""));
  };

  useEffect(() => {
    if (users_profile_pic) {
      //if (users_profile_img_path) {
      imageRef.current.src =
        constants.IMAGE_URL + users_profile_img_path + users_profile_pic;
    }
  }, [props.userData]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require("./../../images/team/pic1.jpg");
  };

  const handleLink = (e, link) => {
    setShow(true);
  };
  const handleClose = () => setShow(false);
  // sticky-top
  // Modal Code - Start
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };
  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };
  // Modal Code - End

  return (
    <>
      <div className="profile-modal">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Coming Soon!</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body></Modal.Body> */}
        </Modal>
      </div>
      <div className="col-xl-3 col-lg-4 m-b30">
        <div className="wrapper mb-0">
          <div className="candidate-info">
            <div className="candidate-detail text-center">
              <div
                className={
                  "canditate-des " +
                  (users_profile_img_path && users_profile_pic ? "" : "bg-dark")
                }
              >
                {users_profile_img_path && users_profile_pic ? (
                  <img
                    className="size-thumbnail border border-default rounded-circle cursor-pointer"
                    width={"136px"}
                    height={"136px"}
                    ref={imageRef}
                    onClick={() => {
                      if (users_profile_img_path && users_profile_pic) {
                        viewFileModal(
                          constants.IMAGE_URL +
                            users_profile_img_path +
                            users_profile_pic
                        );
                      } else {
                        Swal.fire({
                          title: "Profile Picture Not Available",
                          text: "",
                          icon: "warning",
                          showCancelButton: false,
                          confirmButtonText: "Ok",
                          // confirmButtonColor: 'blue'
                        });
                      }
                    }}
                    src={require("./../../images/team/pic1.jpg")}
                  />
                ) : (
                  <div className="text-white" style={{ marginTop: "35%" }}>
                    <h1 style={{ fontSize: "60px" }}>
                      {getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) &&
                      getInitialsLettersOfString(
                        users_firstname + " " + users_lastname
                      ) != "UU" ? (
                        getInitialsLettersOfString(
                          users_firstname + " " + users_lastname
                        )
                      ) : (
                        <i
                          class="fa fa-spinner text-center fa-pulse text-white"
                          style={{ fontSize: "80px", marginTop: "-10%" }}
                        ></i>
                      )}
                    </h1>
                  </div>
                )}
                <div
                  className="upload-link border"
                  title="update"
                  data-toggle="tooltip"
                  data-placement="right"
                  style={{
                    marginTop:
                      users_profile_img_path && users_profile_pic
                        ? "50%"
                        : "70%",
                  }}
                >
                  <input
                    type="file"
                    className="update-flie"
                    id="users_profile_pic"
                    // onChange={(e) => onChangeInputvalue(e)}
                    accept=".png,.jpeg,.jpg"
                  />
                  <i className="fa fa-camera"></i>
                </div>
              </div>

              <div className="candidate-title">
                <div className="">
                  <h4 className="m-b5">
                    <a href={"#"}>{users_firstname}</a>
                  </h4>
                  <p className="m-b0">
                    {/* <a href={"#"}>{props.userAuth.users_category}</a> */}
                    <a href={"#"}>
                      {props.userAuth.users_category
                        ? capitalizeFirstLetter(props.userAuth.users_category)
                        : ""}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <ul>
              <li>
                <a
                  href={"/candidate-profile"}
                  className={`${
                    window.location.pathname == "/candidate-profile" && "active"
                  }`}
                >
                  <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                  <span className="text-md">
                    <b>Profile & CV</b>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={"/change-password"}
                  className={`${
                    window.location.pathname == "/change-password" && "active"
                  }`}
                >
                  <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                  <span className="text-md">
                    <b>Change Password</b>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={"/account-faq"}
                  className={`${
                    window.location.pathname == "/account-faq" && "active"
                  }`}
                >
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                  <span>Account FAQ'S</span>
                </a>
              </li>
              {/* <li>
                <a
                  href={"#"}
                  className={`${window.location.pathname == "/switch-account" && "active"}`}
                >
                  <i class="fa fa-retweet" aria-hidden="true"></i>
                  <span>Switch Account</span>
                </a>
            </li>
            <li>
              <a
                href={"#"}
                className={`${window.location.pathname == "/write-an-feedback" && "active"}`}
              >
                <i class="fa fa-commenting" aria-hidden="true"></i>
                <span>Write Feedback</span>
              </a>
            </li>
            <li>
              <a
                href={"#"}
                className={`${window.location.pathname == "/block-account" && "active"}`}
              >
                <i class="fa fa-ban" aria-hidden="true"></i>
                <span>Suspend My Account</span>
              </a>
            </li> */}
              <li>
                <a
                  href={"/delete-account"}
                  className={`${
                    (window.location.pathname == "/delete-account" ||
                      window.location.pathname == "/cancel-assignments") &&
                    "active"
                  }`}
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  <span>Delete My Account</span>
                </a>
              </li>
              {/* <li>
              <a
                href={"#"}
                className={`${window.location.pathname == "/need-an-help" && "active"}`}
              >
                <i class="fa fa-question-circle" aria-hidden="true"></i>
                <span>Need an Help</span>
              </a>
            </li> */}
            </ul>
          </div>
          <GlobalViewFileModal
            modalOption={modalOption}
            closeFileModal={closeFileModal}
            fileUrl={fileUrl}
            loaderEnd={loaderEnd}
            modalHeight={modalHeight}
            callModal={callModal}
            title={"Profile Picture"}
            // fileName={fileName}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userData: state.userProfile.userData,
    userExperience: state.userExperience.userData,
    bankInfo: state.bankInfo.bankInfo,
    rightToWork: state.rightToWork.rightToWork,
    workPrefrences: state.workPrefrences.workPrefrences,
    documentCompliance: state.documentCompliance.documentCompliance,
    dbsDocuments: state.dbsdocuments.dbsdocuments,
    identityDocument: state.identityDocument.identityDocument,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(AccountCentreSidebar);
