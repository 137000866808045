import React, { useState } from "react";
import Profilesidebar from "../components/Element/Profilesidebar";
import StepWizard from "react-step-wizard";
import { useEffect } from "react";
import Nav from "../components/Element/Agreements/Nav";
import RehabitationDeclaration from "../components/Element/Agreements/RehabitationDeclaration";
import FinalDeclaration from "../components/Element/Agreements/FinalDeclaration";
import ApplicationDeclaration from "../components/Element/Agreements/ApplicationDeclaration";
import { isMobileOrLaptop } from "../services/CheckDevice";
import ProfileSideBarMobile from "../components/Element/ProfileSideBarMobile";

const Jobprofile = (props) => {
  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(350);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  return (
    <React.Fragment>
      <div className="page-content bg-white" id="scrollFocusElement">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {checkDevice && checkDevice == "Mobile" ? (
                  <ProfileSideBarMobile page="agreements" />
                ) : (
                  <Profilesidebar page="agreements" />
                )}
                <div className="col-xl-9 col-lg-8 m-b30">
                  <StepWizard nav={<Nav />} isLazyMount={true}>
                    <ApplicationDeclaration />
                    <RehabitationDeclaration />
                    <FinalDeclaration />
                  </StepWizard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Jobprofile;
