import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest} from '../redux/actions/CommonActions'
const CompanyDetails = (OriginalComponent) => {
  const NewComponent = (props) => {
    const { userAuth, companyDetails } = props;
    const dispatch = useDispatch();
    useEffect(()=>{
      if(userAuth.users_access_token)
        dispatch(getDataOnRequest(userAuth.users_access_token,'GetCompanyDetails','SET_COMPANY_DETAILS'))
    },[userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    bankInfo:state.bankInfo.bankInfo,
    companyDetails:state.companyDetails.companyDetails
  };
};

const composedCompanyDetails = compose(connect(mapStateToProps), CompanyDetails)
export default composedCompanyDetails;

