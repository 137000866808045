import React from 'react';
import { connect } from 'react-redux';
import ClientSidebar from '../components/Element/ClientSidebar';
import JobForm from '../components/Element/Jobs/JobsForm';
import ClientMenusidebar from '../components/Element/ClientMenusidebar';
const Job = (props) => {
	return (
		<React.Fragment>
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								<ClientMenusidebar page="job"/>
								<div className="col-xl-9 col-lg-8 m-b30 m-t30">
									<JobForm />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}


const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserdata
  }
}

export default connect(mapStateToProps)(Job);