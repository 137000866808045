import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12">
              <div className="widget">
                <img
                  src={require("./../../images/logo.png")}
                  width="120"
                  className="m-b15"
                  alt=""
                />
                <p className="text-capitalize m-b20">
                  Staffing Connect is an independent recruiting agency providing
                  staffing solutions in the Education, Home Care, and Social
                  Care sectors on temporary as well as permanent basis.
                </p>
                {/* <div className="subscribe-form m-b20">
									<form className="dzSubscribe" action="script/mailchamp.php" method="post">
										<div className="dzSubscribeMsg"></div>
										<div className="input-group">
											<input name="dzEmail" required="required" className="form-control" placeholder="Your Email Address" type="email" />
											<span className="input-group-btn">
												<button name="submit" value="Submit" type="submit" className="site-button radius-xl">Subscribe</button>
											</span>
										</div>
									</form>
								</div> */}
                {/* <ul className="list-inline m-a0">
									<li><Link to={''} className="site-button white facebook circle "><i className="fa fa-facebook"></i></Link></li>
									<li><Link to={''} className="site-button white google-plus circle "><i className="fa fa-google-plus"></i></Link></li>
									<li><Link to={''} className="site-button white linkedin circle "><i className="fa fa-linkedin"></i></Link></li>
									<li><Link to={''} className="site-button white instagram circle "><i className="fa fa-instagram"></i></Link></li>
									<li><Link to={''} className="site-button white twitter circle "><i className="fa fa-twitter"></i></Link></li>
								</ul> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="widget border-0">
                <h5 className="m-b20 text-white">Hounslow</h5>
                <p className="text-capitalize m-b20">
                  Suite 14, Neals Corner, 2 Bath Road, <br />
                  Hounslow, Middlesex, TW3 3HJ. <br />
                  Ph: 0203 841 6440 <br />
                  Email:{" "}
                  <a
                    href="mailto:info@staffingconnect.co.uk"
                    className="text-lowercase"
                    style={{ color: "#c6cffa" }}
                  >
                    info@staffingconnect.co.uk
                  </a>
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-8 col-sm-8 col-12">
              <div className="widget border-0">
                <h5 className="m-b15 text-white">Quick Links</h5>
                <ul className="list-2 list-line">
                  <li>
                    <a href={"/"}>Home</a>
                  </li>
                  <li>
                    <a href={"/privacy-policy"}>Privacy Policy</a>
                  </li>
                  <li>
                    <a href={"/about-us"}>About Us</a>
                  </li>
                  <li>
                    <a href={"/cookie-policy"}>Cookie Policy</a>
                  </li>
                  <li>
                    <a href={"/contact"}>Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center m-b20">
              <span>
                {" "}
                © Copyright by{" "}
                <img
                  src={require("./../../images/Staffing_Connect_White.png")}
                  width=""
                  className="m-b15"
                  alt=""
                />
                <Link to={"/"}> 2022. </Link> All rights reserved.
              </span>
            </div>
            <div
              className="col-lg-12 text-center m-b10"
              style={{ color: "#c6cffa", fontSize: "0.75rem" }}
            >
              Care Education Ltd (Trading as Staffing Connect) is a company
              registered in England and Wales. Registered number: 9797844.
            </div>
            <div
              className="col-lg-12 text-center"
              style={{ color: "#c6cffa", fontSize: "0.75em" }}
            >
              The Staffing Connect website uses cookies, tracking pixels and
              related technologies. Cookies are small data files that are served
              by our platform and stored on your device. Our site uses cookies
              dropped by us or third parties for a variety of purposes including
              to operate and personalize the website. Also, cookies may also be
              used to track how you use the site to target ads to you on other
              websites.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
