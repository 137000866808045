import { sortCode, nationalInsurance, accountNumber, rollNumber } from './ValidationHelpers'

let errorsObj = {
  cnd_bank_name: '', cnd_bank_sort_code: '',
  cnd_bank_account_number: '',
  cnd_bank_roll_number: '',
  cnd_bank_national_insurance: '',
  cnd_bank_holder_name: ''
};

export const BankDataValidations = (data) => {
  let error = false;
  const errorObj = { ...errorsObj };
  if (!data.cnd_bank_name || (data.cnd_bank_name && data.cnd_bank_name.trim() == "")) {
    errorObj.cnd_bank_name = "Bank Name Required";
    error = true;
  }
  if (!data.cnd_bank_sort_code || (data.cnd_bank_sort_code && data.cnd_bank_sort_code.trim() == "")) {
    errorObj.cnd_bank_sort_code = "Bank Sort Code Required";
    error = true;
  } else if (data.cnd_bank_sort_code && !sortCode(data.cnd_bank_sort_code)) {
    errorObj.cnd_bank_sort_code = "Please Provide Valid Sort Code  Eg, 12-45-78";
    error = true;
  }
  if (!data.cnd_bank_account_number) {
    errorObj.cnd_bank_account_number = "Bank Account Number Required";
    error = true;
  }
  else if (!accountNumber(data.cnd_bank_account_number)) {
    errorObj.cnd_bank_account_number = "Please Provide Valid Account Number Eg, 12345678";
    error = true;
  }

  if (!data.cnd_confirm_bank_account_number) {
    errorObj.cnd_confirm_bank_account_number = "Confirm Bank Account Number Required";
    error = true;
  }
  else if (!accountNumber(data.cnd_confirm_bank_account_number)) {
    errorObj.cnd_confirm_bank_account_number = "Please Provide Valid Confirm Account Number Eg, 12345678";
    error = true;
  }
  else if (data.cnd_confirm_bank_account_number !== data.cnd_bank_account_number) {
    errorObj.cnd_confirm_bank_account_number = "Confirm Account Number Should Match With Account Number";
    error = true;
  }
  // if (!data.cnd_bank_roll_number) {
  //   errorObj.cnd_bank_roll_number = "Bank Roll Number Required";
  //   error = true;
  // }
  let cnd_bank_roll_number = document.getElementById("cnd_bank_roll_number");
  if (data.cnd_bank_roll_number && !rollNumber(data.cnd_bank_roll_number) || cnd_bank_roll_number.value.trim() == "") {
    errorObj.cnd_bank_roll_number = "Enter valid roll no, it will accept only 2-6 length digit of numbers Eg, [12], [123], [1234], [12345], [123456]";
    error = true;
  }
  if (!data.cnd_bank_national_insurance) {
    errorObj.cnd_bank_national_insurance = "National Insurance Number";
    error = true;
  }else if (!nationalInsurance(data.cnd_bank_national_insurance) || data.cnd_bank_national_insurance.trim() == "") {
    errorObj.cnd_bank_national_insurance = "Please Provide Valid National Insurance Number Eg, AA-123456-A";
    error = true;
  }
  let cnd_bank_holder_name = document.getElementById("cnd_bank_holder_name");
  data.cnd_bank_holder_name = cnd_bank_holder_name.value;
  if (!data.cnd_bank_holder_name) {
    errorObj.cnd_bank_holder_name = "Bank Account Holder Name Required";
    error = true;
  }else if (data.cnd_bank_holder_name && data.cnd_bank_holder_name.trim() == "") {
    errorObj.cnd_bank_holder_name = "Bank Account Holder Name Required";
    error = true;
  }


  return { stateData: errorObj, error: error };
}