import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import UserProfile from "../../../containers/UserProfile";
import { updateUserProfile } from "../../../redux/actions/UserProfileActions";
import AddressFields from "./AddressFields";
import PersonalInfoFields from "./PersonalInfoForm";
import PersonalInfoRadioFields from "./PersonalInfoRadioFields";
import { santetizeData } from "../../../helpers/FormFieldsSanetizations";
import { getBase64 } from "../../../helpers/FileToBase64";
import { CandidatePersonalInfoValidation } from "../../../helpers/PersonalInfoCandidateValidation";
// import TrainingQualifications from "../TrainingQualifications";
import Resume from "../Resume";
import { validateUkNumber } from "../../../helpers/ValidationHelpers";
const PersonalInfoContainer = (props) => {
  const dispatch = useDispatch();
  const [userdata, setFormdata] = useState(props.userData);
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const history = useHistory();
  const { loader } = props;
  localStorage.setItem("tempUserStatus", userdata.cnd_status);
  localStorage.setItem(
    "usersName",
    userdata.users_firstname + " " + userdata.users_lastname
  );

  const onChangeInputvalue = async (e) => {
    if (e.target.name === "cnd_upload_cv") {
      if (e.target.files[0])
        userdata["cnd_upload_cv"] = await getBase64(e.target.files[0]);
    } else {
      if (e.target.value) userdata[e.target.name] = e.target.value;
      else userdata[e.target.name] = "";
    }
    //validateDataBeforeSubmit(e.target.name);
    validateDataBeforeSubmit("submit");
    setFormdata(userdata);
  };

  const validateDataBeforeSubmit = (type) => {
    let validations = CandidatePersonalInfoValidation(userdata, type, errors);
    setErrors(validations.stateData);
    return validations.error;
  };
  const updateUserProfilePersonalInfo = (e) => {
    e.preventDefault();
    //console.warn("Target value :=> "+e.target[3].value);
    userdata["cnd_birth_date"] = e.target[4].value; //Cnd Birth Date
    userdata["cnd_street_address"] = e.target[10].value;
    userdata["cnd_street_address_2"] = e.target[11].value;
    userdata["cnd_town"] = e.target[12].value;
    userdata["cnd_region"] = e.target[13].value;
    userdata["cnd_postcode"] = e.target[14].value;

    // On Focus Start
    let cnd_gender = document.getElementById("cnd_gender");
    let cnd_birth_date = document.getElementById("cnd_birth_date");
    let cnd_home_mobile_number = document.getElementById(
      "cnd_home_mobile_number"
    );
    let cnd_kin_firstname = document.getElementById("cnd_kin_firstname");
    let cnd_kin_lastname = document.getElementById("cnd_kin_lastname");
    let cnd_kin_phonenumber = document.getElementById("cnd_kin_phonenumber");
    let cnd_street_address = document.getElementById("cnd_street_address");
    let cnd_street_address_2 = document.getElementById("cnd_street_address_2");
    let cnd_town = document.getElementById("cnd_town");
    let cnd_region = document.getElementById("cnd_region");
    let cnd_postcode = document.getElementById("cnd_postcode");
    let cnd_county = document.getElementById("cnd_county");
    let drivingLicense = document.getElementById("drivingLicense");
    let cnd_drivinglicense_yes = document.getElementById(
      "cnd_drivinglicense_yes"
    );
    let cnd_drivinglicense_no = document.getElementById(
      "cnd_drivinglicense_no"
    );
    
    let cnd_have_a_car_yes = document.getElementById(
      "cnd_have_a_car_yes"
    );
    let cnd_minibuslicense_yes = document.getElementById(
      "cnd_minibuslicense_yes"
    );
    let cnd_minibuslicense_no = document.getElementById(
      "cnd_minibuslicense_no"
    );
    let cnd_swimmer_yes = document.getElementById("cnd_swimmer_yes");
    let cnd_swimmer_no = document.getElementById("cnd_swimmer_no");
    let cnd_upload_cv = document.getElementById("cnd_upload_cv");

    // Date Focus Start
    let birthDate = new Date(String(cnd_birth_date.value));
    let currentDate = new Date();
    let minDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 73)
    );
    let maxDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 15)
    );

    if (!cnd_gender.value) {
      cnd_gender.focus();
    } else if (!cnd_birth_date.value) {
      cnd_birth_date.focus();
    } /*if(birthDate > currentDate || birthDate.getFullYear() > 2011 || birthDate.getFullYear() < 1950 || (birthDate.getFullYear() >= 2011 && birthDate.getMonth() > 5)){*/ else if (
      birthDate > currentDate ||
      birthDate > maxDate ||
      birthDate < minDate
    ) {
      cnd_birth_date.focus();
    } else if (
      cnd_home_mobile_number.value &&
      (cnd_home_mobile_number.value.trim() == "" ||
        !validateUkNumber(cnd_home_mobile_number.value))
    ) {
      cnd_home_mobile_number.focus();
    } else if (
      !cnd_kin_firstname.value ||
      (cnd_kin_firstname.value && cnd_kin_firstname.value.trim() == "")
    ) {
      cnd_kin_firstname.focus();
    } else if (
      !cnd_kin_lastname.value ||
      (cnd_kin_lastname.value && cnd_kin_lastname.value.trim() == "")
    ) {
      cnd_kin_lastname.focus();
    } else if (
      !cnd_kin_phonenumber.value ||
      (cnd_kin_phonenumber.value &&
        !validateUkNumber(cnd_kin_phonenumber.value))
    ) {
      cnd_kin_phonenumber.focus();
    } else if (!validateUkNumber(cnd_kin_phonenumber.value)) {
      cnd_kin_phonenumber.focus();
    } else if (
      !cnd_street_address.value ||
      (cnd_street_address.value && cnd_street_address.value.trim() == "")
    ) {
      cnd_street_address.focus();
    } else if (
      cnd_street_address_2.value &&
      cnd_street_address_2.value.trim() == ""
    ) {
      cnd_street_address_2.focus();
    } else if (!cnd_town.value || cnd_town.value.trim() == "") {
      cnd_town.focus();
    } else if (cnd_region.value && cnd_region.value.trim() == "") {
      cnd_region.focus();
    } else if (!cnd_postcode.value) {
      cnd_postcode.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    } else if (
      !String(cnd_postcode.value).match(
        /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
      )
    ) {
      cnd_postcode.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    } else if (!cnd_county.value) {
      cnd_county.focus();
      cnd_street_address.scrollIntoView({ behavior: "smooth" }, true);
    } else if (!userdata.cnd_drivinglicense) {
      cnd_drivinglicense_yes.focus();
      // drivingLicense.scrollIntoView({behavior: 'smooth'}, true);
    }else if (!userdata.cnd_have_a_car) {
      cnd_have_a_car_yes.scrollIntoView({ behavior: "smooth" }, true);
      cnd_have_a_car_yes.focus();
      // drivingLicense.scrollIntoView({behavior: 'smooth'}, true);
    } else if (!userdata.cnd_minibuslicense) {
      cnd_minibuslicense_yes.focus();
    } else if (!userdata.cnd_swimmer) {
      cnd_swimmer_yes.focus();
    } else if (!cnd_upload_cv.value && !userdata.cnd_upload_cv) {
      cnd_upload_cv.focus();
    }
    // On Focus Stop
    // console.log("New Demo For File Accept Type :=> "+cnd_upload_cv.accept);
    if (!validateDataBeforeSubmit("submit")) {
      document.getElementById("loader").style.display = "block";
      userdata["users_access_token"] = props.userAuth.users_access_token;
      userdata["cnd_county"] = "GB";
      userdata["acceptFileType"] = cnd_upload_cv.accept;
      dispatch(
        updateUserProfile(santetizeData(userdata), "UpdatePersonalInfo")
      );
      //history.push('/emplyement-references');
    }
  };

  useEffect(() => {
    // window.scrollTo(0,0);
    setFormdata(props.userData);
  }, [props.userData]);

  // Useffect For Scroll Start

  const isMobileOrLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile";
    } else {
      const isTablet = /android|ipad|tablet/i.test(userAgent);
      if (isTablet) {
        return "Tablet";
      } else {
        return "Laptop/Desktop";
      }
    }
  };
  let scrollFocusElement;
  useEffect(() => {
    scrollFocusElement = document.getElementById("scrollFocusElement");
    const deviceType = isMobileOrLaptop();
    // console.log('Device Type:', deviceType);
    if (deviceType == "Mobile" || deviceType == "Tablet") {
      window.scrollTo(0, 900);
    }
  }, [scrollFocusElement]);
  // UseEffect For Scroll Stop

  return (
    <React.Fragment>
      <div className="job-bx submit-resume" id="scrollFocusElement">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Personal Information
          </h5>
          {/* <Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link> */}
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => updateUserProfilePersonalInfo(e)}
          noValidate
          encType="multipart/form-data"
          onKeyUp={(e) => onChangeInputvalue(e)}
          onClick={(e) => onChangeInputvalue(e)}
        >
          <div className="row m-b30">
            <PersonalInfoFields
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            />
            <AddressFields
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
              cndApproved={userdata.cnd_status == "Approved" ? true : false}
            />
            <PersonalInfoRadioFields
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            />
            {/* <TrainingQualifications
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            /> */}
            <Resume
              errors={errors}
              data={userdata}
              onChange={(e) => onChangeInputvalue(e)}
            />
          </div>
          <Button
            disabled={loader}
            className="site-button m-b30 float-right"
            type="submit"
          >
            {loader && (
              <Spinner
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            )}
            {!loader ? "Save & Next" : ""}
          </Button>
          <br />
          <br />
        </form>
      </div>
      {/* <TrainingQualifications /> */}
    </React.Fragment>
  );
};

export default UserProfile(PersonalInfoContainer);
