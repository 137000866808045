import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isMobileOrLaptop } from "../services/CheckDevice";
import { deviceType } from "react-device-detect";
import DesktopAboutus from "./DesktopAboutUs";
import MobileAboutus from "./MobileAboutUs";
function Aboutus() {
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener('resize', function() {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  }, [deviceType]);

  return(
    <>
    {checkDevice == "Laptop/Desktop" ? (
      <DesktopAboutus />
    ) : (
      <MobileAboutus />
    )}
    </>
  );
}
export default Aboutus;
