const initialState = {avaibility: {}}

export const avaibility = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_AVAIBILITY':
      return {avaibility: action.payload}; 
  }
  return state
}


