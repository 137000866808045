export const showPassword = (fieldId) => {
  // console.log("Function Calling"+fieldId);
  let fieldHolder = document.getElementById(fieldId);
  fieldHolder.type = "text";

  let hidePassword = document.getElementById("hidePassword" + fieldId);
  let showPassword = document.getElementById("showPassword" + fieldId);
  showPassword.style.display = "none";
  hidePassword.style.display = "block";
};

export const hidePassword = (fieldId) => {
  // console.log("Hide Function Calling"+fieldId);
  let fieldHolder = document.getElementById(fieldId);
  fieldHolder.type = "password";

  let hidePassword = document.getElementById("hidePassword" + fieldId);
  let showPassword = document.getElementById("showPassword" + fieldId);
  showPassword.style.display = "block";
  hidePassword.style.display = "none";
};
