import React from "react";
import PropTypes from "prop-types";
import Label from "../Layout/Label";

import { Form, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { hidePassword, showPassword } from "./ShowPassword";
import { isMobileOrLaptop } from "../../services/CheckDevice";
const Input = ({
  errors,
  name,
  value,
  type,
  accept,
  alt,
  changeHandler,
  label,
  placeholder,
  max,
  disabled,
  md,
  sm,
  lg,
  min,
  req,
  maxLength,
  id,
  fileUrl,
  defaultValue,
  step,
  toolTip,
}) => {
  //const Input = ({ errors, name, value, type, changeHandler, label, placeholder, max, disabled, md, sm, lg, min }) => {
  const renderTooltip = (props) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      className={window.location.pathname == "/delete-account" ? "ml-4" : ""}
      style={{ display: toolTip == false ? "none" : "block" }}
    >
      {placeholder ? placeholder : label}
    </Tooltip>
  );
  // console.log(disabled);
  return (
    <>
      <Form.Group
        as={Col}
        /*controlId={name + type}*/ md={md ? md : 6}
        sm={sm ? sm : 12}
        lg={lg ? lg : 6}
      >
        {label && <Label labelName={label} req={req} />}{" "}
        {name == "cnd_street_address" &&
          localStorage.getItem("tempUserStatus") == "Approved" &&
          window.location.pathname == "/job-profile" && (
            <a
              href="/create-document-update-address"
              className="float-right text-primary"
            >
              Do you want to update address?
            </a>
          )}
        {type == "file" && fileUrl && (
          <a
            rel="noopener noreferrer"
            download
            href={fileUrl}
            target="_blank"
            className="link-primary pull-right"
          >
            <i className="fa fa-download f-26"></i> Download Document
          </a>
        )}
        {isMobileOrLaptop() == "Mobile" ? (
          <>
            <Form.Control
              defaultValue={defaultValue ? defaultValue : value}
              name={name}
              onChange={changeHandler}
              type={type}
              required={req === false ? false : true}
              max={max}
              min={min}
              id={id}
              step={step}
              maxLength={maxLength}
              label={label}
              style={{
                padding: type == "file" ? "9px" : "",
                border: type == "file" ? "solid #e0dfe3 0.5px" : "",
                borderBlockColor: type == "file" ? "#e0dfe3" : "",
                borderRadius: type == "file" ? "5px" : "",
              }}
              disabled={disabled ? disabled : false}
              placeholder={placeholder ? placeholder : label}
              accept={
                type == "file" &&
                (accept
                  ? accept
                  : name == "cnd_upload_cover" || name == "cnd_upload_cv"
                  ? ".pdf,.jpg,.jpeg,.png,.doc,.docx,.docm,.dot,.dotx"
                  : ".pdf,.jpg,.jpeg,.png")
              }
              alt={alt ? alt : ""}
              isInvalid={errors && errors[name] ? true : false}
            />
          </>
        ) : (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip()}
            trigger="focus"
          >
            <Form.Control
              defaultValue={defaultValue ? defaultValue : value}
              name={name}
              onChange={changeHandler}
              type={type}
              required={req === false ? false : true}
              max={max}
              min={min}
              id={id}
              step={step}
              maxLength={maxLength}
              label={label}
              style={{
                padding: type == "file" ? "9px" : "",
                border: type == "file" ? "solid #e0dfe3 0.5px" : "",
                borderBlockColor: type == "file" ? "#e0dfe3" : "",
                borderRadius: type == "file" ? "5px" : "",
              }}
              disabled={disabled ? disabled : false}
              placeholder={placeholder ? placeholder : label}
              accept={
                type == "file" &&
                (accept
                  ? accept
                  : name == "cnd_upload_cover" || name == "cnd_upload_cv"
                  ? ".pdf,.jpg,.jpeg,.png,.doc,.docx,.docm,.dot,.dotx"
                  : ".pdf,.jpg,.jpeg,.png")
              }
              alt={alt ? alt : ""}
              isInvalid={errors && errors[name] ? true : false}
            />
          </OverlayTrigger>
        )}
        {type == "password" && id && (
          <>
            <a
              onClick={() => showPassword(id)}
              className="text-primary"
              id={"showPassword" + id}
            >
              <i
                className="fa fa-eye pull-right mr-2"
                style={{
                  marginTop:
                    window.location.pathname == "/delete-account"
                      ? "-5%"
                      : "-8%",
                      display:"flex",
                      justifyContent:"center"
                }}
              ></i>
            </a>
            <a
              onClick={() => hidePassword(id)}
              className="text-primary"
              id={"hidePassword" + id}
              style={{ display: "none" }}
            >
              <i
                className="fa fa-eye-slash pull-right mr-2"
                style={{
                  marginTop:
                    window.location.pathname == "/delete-account"
                      ? "-5%"
                      : "-8%",
                }}
              ></i>
            </a>
          </>
        )}
        {name &&
          (window.location.pathname == "/create-identity-document" ||
            window.location.pathname.includes("/create-identity-document/") ||
            window.location.pathname == "/create-right-to-work" ||
            window.location.pathname.includes("/create-right-to-work/") ||
            window.location.pathname == "/create-timesheet" ||
            window.location.pathname.includes("/create-timesheet/")) && (
            <p
              id={name + "_msg"}
              style={{ fontSize: "80%" }}
              className={"text-danger text-sm"}
            ></p>
          )}
        {name &&
          (window.location.pathname == "/change-password" ||
            window.location.pathname == "/new-password" ||
            window.location.pathname == "/login" ||
            window.location.pathname == "/delete-account") && (
            <p
              id={name + "_msg"}
              style={{ fontSize: "80%" }}
              className={
                type == "file"
                  ? "text-danger text-sm mt-3 mb-0"
                  : "text-danger text-sm mb-0"
              }
            ></p>
          )}
        {(window.location.pathname == "/driver-details" ||
          window.location.pathname == "/flt-license") &&
          id && <div className={"text-danger mb-0"} id={id + "_msg"}></div>}
        {name && window.location.pathname == "/create-daily-logs" && (
          <p
            id={name + "_msg"}
            style={{ fontSize: "80%", display: "none" }}
            className={
              type == "file"
                ? "text-danger text-sm mt-3 mb-0"
                : "text-danger text-sm mb-0"
            }
          ></p>
        )}
        {(window.location.pathname == "/candidate-profile" ||
          window.location.pathname == "/applied-jobs") &&
          id && (
            <div
              className={"text-danger mb-0"}
              style={{ fontSize: "80%" }}
              id={id + "_msg"}
            ></div>
          )}
        <Form.Control.Feedback type="invalid">
          {errors && errors[name]}
        </Form.Control.Feedback>
        {id == "cnd_doc_name" && (
          <p
            className="text-danger text-left"
            style={{ display: "none", fontSize: "100%" }}
            id="cnd_doc_name_error"
          >
            Please enter the document name
          </p>
        )}
        {id == "cnd_compliance_upload_file" && (
          <p
            className="text-danger text-left mt-3"
            style={{ display: "none", fontSize: "100%" }}
            id="cnd_compliance_upload_file_error"
          >
            Please select the file
          </p>
        )}
        {id == "cnd_compliance_reference" && (
          <p
            className="text-danger text-left"
            style={{ display: "none", fontSize: "100%" }}
            id="cnd_compliance_reference_error"
          >
            Please enter the valid reference number
          </p>
        )}
        {id == "cnd_compliance_exp_date" && (
          <p
            className="text-danger text-left"
            style={{ display: "none", fontSize: "100%" }}
            id="cnd_compliance_exp_date_error"
          >
            Please slect the date from calendar in DD-MM-YYYY format!
          </p>
        )}
      </Form.Group>
    </>
  );
};

Input.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Input;
