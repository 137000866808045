const initialState = {employementGapinfo: []}

export const employementGapReducers = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_EMPLOYEMENT_GAP_DATA':
      return {employementGapinfo: action.payload}; 
  }
  return state
}


