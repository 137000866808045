const initialState = {employementinfo: []}

export const employementinfo = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_EMPLOYEMENT_DATA':
      return {employementinfo: action.payload}; 
  	case 'UPDATE_EMPLOYEMENT_DATA':
      return {employementinfo: action.payload}; 
  }
  return state
}


