import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
const ChargeRateTable = (props) => {
  const { loader, userDetails, chargerate } = props;
  const [chargeRateTableData, setChargeRateTable] = useState({});
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h6 className="pull-left text-uppercase">Charge Rate Table</h6>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Support Worker/ Care Worker</th>
              <th scope="col">Charge</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Weekday</td>
              <td>&euro;{chargerate.clt_rate_weekday}</td>
            </tr>
            <tr>
              <td>Saturday</td>
              <td>&euro;{chargerate.clt_rate_saturday}</td>
            </tr>
            <tr>
              <td>Sunday</td>
              <td>&euro;{chargerate.clt_rate_sunday}</td>
            </tr>
            <tr>
              <td>Sleep in</td>
              <td>&euro;{chargerate.clt_rate_sleep_in}</td>
            </tr>
            <tr>
              <td>Weekday Waking Night</td>
              <td>&euro;{chargerate.clt_rate_weekday_wakingnight}</td>
            </tr>
            <tr>
              <td>Weekend Waking Night</td>
              <td>&euro;{chargerate.clt_rate_weekend_wakingnight}</td>
            </tr>
            <tr>
              <td>Bank Holiday</td>
              <td>&euro;{chargerate.clt_rate_bankholiday}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    chargerate: state.chargerate.chargeratetable,
  };
};
export default connect(mapStateToProps)(ChargeRateTable);
