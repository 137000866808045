import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";

import { format } from "date-fns";
const MyAssignmentsCancelledList = (props) => {
  const [myassignments, SetMyAssignments] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);
  let checkData = false;

  useEffect(() => {
    const lenOfData = props.myassignments.length;
    console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetMyAssignments(props.myassignments);
  }, [props.myassignments]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    SetMyAssignments(props.myassignments);
  }, [props.myassignments]);

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <table className="table table-bordered table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              {/*<th  className="col-1 text-center" scope="col">Sr. No</th>*/}
              <th className="col-2 text-center" scope="col">
                Date
              </th>
              <th className="col-2 text-center" scope="col">
                Timing
              </th>
              {/* <th  className="col-3 text-center" scope="col"> Name</th> */}
              {/*<th  className="col-2 text-center" scope="col">Email</th>*/}
              <th className="col-2 text-center" scope="col">
                Client Name
              </th>
              <th className="col-2 text-center" scope="col">
                Branch Name
              </th>
              <th className="col-2 text-center" scope="col">
                Reason
              </th>
              <th className="col-2 text-center" scope="col">
                Comment
              </th>
              {/* <th className="col-2 text-center" scope="col">
                Status
              </th> */}
              {/*<th  className="col-1 text-center" scope="col">Action</th>*/}
            </tr>
          </thead>
          <tbody id="myTable">
            {myassignments &&
              myassignments.length > 0 &&
              myassignments
                ?.slice(startIndex, endIndex)
                .map((myassignment, i) => {
                  if (myassignment.cnd_assignment_status == "Cancelled") {
                    if (i == 0) {
                      checkData = true;
                    }
                    return (
                      <tr key={i}>
                        {/*<td className="col-1 text-center" scope="row">
                    {i + 1}
              </td>*/}
                        <td className="col-2 text-center" scope="row">
                          {format(
                            new Date(
                              myassignment.cnd_assignment_availability_date
                            ),
                            "dd-MM-yyyy"
                          ) +
                            " (" +
                            myassignment.cnd_availability_week_day
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ") +
                            ")"}
                        </td>
                        <td className="col-2 text-center" scope="row">
                          {myassignment.cnd_assignment_start_time +
                            " - " +
                            myassignment.cnd_assignment_end_time}
                        </td>
                        <td className="col-2 text-center" scope="row">
                          {myassignment.clt_company_name}
                        </td>
                        {/* <td className="col-2 text-center" scope="row">
                    {myassignment.clt_branch_location
                      ? myassignment.clt_branch_name +
                        " - ( " +
                        myassignment.clt_branch_location +
                        " )"
                      : myassignment.clt_branch_name}
                  </td> */}
                        {myassignment.cnd_assignment_client_branch_id &&
                          myassignment.cnd_assignment_client_branch_id ==
                            myassignment.clt_branch_id && (
                            <td className="col-2 text-center" scope="row">
                              {myassignment.clt_branch_location
                                ? myassignment.clt_branch_name +
                                  " - ( " +
                                  myassignment.clt_branch_location +
                                  " )"
                                : myassignment.clt_branch_name}
                            </td>
                          )}
                        {!myassignment.cnd_assignment_client_branch_id && (
                          <td className="col-2 text-center" scope="row">
                            {myassignment.clt_service_town
                              ? myassignment.clt_company_name +
                                " - ( " +
                                myassignment.clt_service_town +
                                " )"
                              : myassignment.clt_company_name}
                          </td>
                        )}
                        <td className="col-2 text-center" scope="row">
                          {myassignment.cnd_assignment_cancel_reason
                            ? myassignment.cnd_assignment_cancel_reason
                            : " - "}
                        </td>
                        <td className="col-2 text-center" scope="row">
                          {myassignment.cnd_assignment_cancel_comment
                            ? myassignment.cnd_assignment_cancel_comment
                            : " - "}
                        </td>
                      </tr>
                    );
                  }
                })}
            {myassignments.length == 0 && (
              <tr>
                <td colSpan="100">No myassignments Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {checkData == true && (
        <TableDropdownAndPagination
          handleDropDown={handleDropDown}
          recordLen={recordLen}
          handleLeft={handleLeft}
          handleRight={handleRight}
          renderPageNumbers={renderPageNumbers}
          isDropdown={true}
          isPaggination={true}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    myassignments: state.myassignments.myassignments,
  };
};

export default connect(mapStateToProps)(MyAssignmentsCancelledList);
