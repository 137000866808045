const initialState = {loader: true}

export const loader = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_LOADER':
      return {loader: action.payload}; 
  }
  return state
}


