import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import constants from "../../../constants";
import Input from "../../FormElements/Input";

import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { redirectUrl } from "../../../services/GlobalUrls";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
const Resume = (props) => {
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");
  const { errors, userInfo, onChange } = props;
  const [formdata, setFormdata] = useState({
    cnd_upload_cv: "",
    users_access_token: "",
  });
  const [title, setTitle] = useState("");

  const viewFileModal = (url, st1) => {
    setTitle("Personal Information - " + st1);
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      // setFileName(fileName);
      setFileUrl(url);
    } else {
      redirectUrl(url);
    }
  };
  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };
  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  return (
    <React.Fragment>
      <div className="submit-resume container mt-3">
        {/* <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Upload Cover Letter & CV
          </h5>
          {/* <Link to={"./"} className="site-button right-arrow button-sm float-right">Back</Link> 
        </div> */}
        {window.location.pathname == "/permanent-job-profile" && (
          <div className="row m-b30 text-danger">
            {/* Cover Letter Start */}
            <Input
              type="file"
              label="Upload Cover Letter"
              placeholder={
                "Select Coverletter [ word | pdf | jpg | jpeg | png ] files only"
              }
              changeHandler={(e) => onChange(e)}
              name="cnd_upload_cover"
              value=""
              errors={errors}
              req={false}
              lg={6}
            />
            {userInfo.cnd_cover_forderpath &&
              userInfo.cnd_upload_cover.substr(0, 5) != "data:" && (
                <div className="col-lg-6 col-md-6">
                  <label>Download/View</label>
                  <div className="form-group hover">
                    <a
                      onClick={() =>
                        viewFileModal(
                          userInfo.cnd_cover_forderpath
                            ? constants.IMAGE_URL +
                                userInfo.cnd_cover_forderpath +
                                userInfo.cnd_upload_cover
                            : "#",
                          "Uploaded Cover Letter"
                        )
                      }
                      // target="_blank"
                      className="cursor-pointer"
                    >
                      {(userInfo &&
                        userInfo.cnd_upload_cover &&
                        userInfo.cnd_upload_cover.endsWith(".png")) ||
                      userInfo.cnd_upload_cover.endsWith(".jpg") ||
                      userInfo.cnd_upload_cover.endsWith(".jpeg") ||
                      userInfo.cnd_upload_cover.endsWith(".pdf") ? (
                        <h6 class="text-dark">
                          <i className="fa fa-eye f-26 btn-sm text-white bg-dark"></i>
                          <span>
                            {/*userInfo.cnd_upload_cover*/} View Cover Letter
                          </span>
                        </h6>
                      ) : (
                        <h6 class="text-primary">
                          <i className="fa fa-download f-26"></i>
                          <span>
                            {/*userInfo.cnd_upload_cover*/} Download Cover
                            Letter
                          </span>
                        </h6>
                      )}
                    </a>
                  </div>
                </div>
              )}
          </div>
        )}

        {/* Cover Letter End */}

        {/* CV Start */}

        <div className="row m-b30 text-danger">
          <Input
            type="file"
            label="Upload CV"
            placeholder={
              "Select CV [ word | pdf | jpg | jpeg | png ] files only"
            }
            changeHandler={(e) => onChange(e)}
            name="cnd_upload_cv"
            id={"cnd_upload_cv"}
            accept={".pdf, .png, .jpeg, .jpg, .doc, .docx, .docm, .dot, .dotm"}
            value=""
            errors={errors}
            req={
              true /*window.location.pathname == "/permanent-job-profile" ? true : false*/
            }
            lg={6}
          />
          {userInfo.cnd_cv_forderpath &&
            userInfo.cnd_upload_cv.substr(0, 5) != "data:" && (
              <div className="col-lg-6 col-md-6">
                <label>Download/View</label>
                <div className="form-group hover">
                  <a
                    onClick={() =>
                      viewFileModal(
                        userInfo.cnd_cv_forderpath && userInfo.cnd_upload_cv
                          ? constants.IMAGE_URL +
                              userInfo.cnd_cv_forderpath +
                              userInfo.cnd_upload_cv
                          : "#",
                        "Uploaded CV"
                      )
                    }
                    // target="_blank"
                    className="cursor-pointer"
                  >
                    {(userInfo &&
                      userInfo.cnd_upload_cv &&
                      userInfo.cnd_upload_cv.endsWith(".png")) ||
                    userInfo.cnd_upload_cv.endsWith(".jpg") ||
                    userInfo.cnd_upload_cv.endsWith(".jpeg") ||
                    userInfo.cnd_upload_cv.endsWith(".pdf") ? (
                      <h6 class="text-dark">
                        <i className="fa fa-eye f-26 btn-sm text-white bg-dark"></i>
                        <span>{/*userInfo.cnd_upload_cv*/} View CV</span>
                      </h6>
                    ) : (
                      <h6 class="text-primary">
                        <i className="fa fa-download f-26"></i>
                        <span>{/*userInfo.cnd_upload_cv*/} Download CV</span>
                      </h6>
                    )}
                  </a>
                </div>
              </div>
            )}
        </div>
        {/* <br /><br /><br /> */}
        {/* DBS Start */}

        {window.location.pathname == "/permanent-job-profile" && (
          <div className="row m-b30 text-danger">
            <Input
              type="file"
              label="Upload DBS Certificate"
              placeholder={
                "Select DBS Certificate [ word | pdf | jpg | jpeg | png ] files only"
              }
              changeHandler={(e) => onChange(e)}
              name="cnd_upload_dbs"
              value=""
              errors={errors}
              req={false}
              lg={6}
            />
            {userInfo.cnd_dbs_forderpath &&
              userInfo.cnd_upload_dbs.substr(0, 5) != "data:" && (
                <div className="col-lg-6 col-md-6">
                  <label>Download/View</label>

                  <div className="form-group hover">
                    <a
                      onClick={() =>
                        viewFileModal(
                          userInfo.cnd_dbs_forderpath
                            ? constants.IMAGE_URL +
                                userInfo.cnd_dbs_forderpath +
                                userInfo.cnd_upload_dbs
                            : "#",
                          "Uploaded DBS Certificate"
                        )
                      }
                      // target="_blank"
                      className="cursor-pointer"
                    >
                      {(userInfo &&
                        userInfo.cnd_upload_dbs &&
                        userInfo.cnd_upload_dbs.endsWith(".png")) ||
                      userInfo.cnd_upload_dbs.endsWith(".jpg") ||
                      userInfo.cnd_upload_dbs.endsWith(".jpeg") ||
                      userInfo.cnd_upload_dbs.endsWith(".pdf") ? (
                        <h6 class="text-dark">
                          <i className="fa fa-eye f-26  btn-sm text-white bg-dark "></i>
                          <span>{/*userInfo.cnd_upload_dbs*/} View DBS</span>
                        </h6>
                      ) : (
                        <h6 class="text-primary">
                          <i className="fa fa-download f-26"></i>
                          <span>
                            {/*userInfo.cnd_upload_dbs*/} Download DBS
                          </span>
                        </h6>
                      )}
                    </a>
                  </div>
                </div>
              )}
          </div>
        )}

        {/* DBS Stop */}
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={title}
        // fileName={fileName}
      />
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userAuth: state.auth.authUserData,
    userInfo: state.userProfile.userData,
    workPrefrences: state.workPrefrences.workPrefrences,
    loader: state.loader.loader,
  };
};

export default connect(mapStateToProps)(Resume);
