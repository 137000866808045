const initialState = {chargerate: {},chargeratetable:{}}

export const chargerate = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_CHANGE_RATE':
      return {...state,chargerate: action.payload}; 
  	case 'SET_CHANGE_RATE_TABLE':
      return {...state,chargeratetable: action.payload}; 
  }
  return state
}


