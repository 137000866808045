import React, { Component } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import Slider from "react-slick";

// var bnr1 = require('./../../images/main-slider/new_home_banner.jpg');
import HomeBanner from "./../../images/main-slider/new_home_banner.jpg";
import firstBanner from "./../../images/home_icon/Home 1.jpg";
import secondBanner from "./../../images/home_icon/Home 2.jpg";
import thirdBanner from "./../../images/home_icon/Home 3.jpg";
import fourthBanner from "./../../images/home_icon/Home 4.jpg";
import playStore from "./../../images/play-store.png";
import appStore from "./../../images/app_store.png";

// import finalImage from "./../../images/home_icon/home_2.jpg";
import finalImage from "./../../images/new-updated-images/home/home-banner.jpg";
import { redirectUrl } from "../../services/GlobalUrls";
import { jobSectorList } from "../../services/JobTypeAndSectorGlobalList";
import Jobfindbox from "./Jobfindbox";
import { allJobs } from "../../services/Data";

const postBlog = [
  {
    image: firstBanner,
    content:
      "Very well run and organised team. Easy to use web site. Louise always available to answer questions. I have regular work since last 4 months and happy to recommend.",
    owner: "Simone Butler",
  },
  {
    // image: require('./../../images/testimonials/pic2.jpg'),
    image: secondBanner,
    content:
      "Great to work for Staffing Connect. Jobs are easy to accept. Any queries they are answered swiftly. Once timesheets are completed and submitted, payroll makes weekly payments. ",
    owner: "Cathy Thomas",
  },
  {
    image: thirdBanner,
    content:
      "Really easy Sign-up process. DBS was done in 2 days. Booked onto shifts really quickly so started to earn straight away. Enjoyed the nursery assistant work. Very happy.",
    owner: "Laura Blundell",
  },
  {
    image: fourthBanner,
    content:
      "Fantastic flexible work options & get paid on weekly basis. Lots of variety of roles that you're matched to. No trawling through irrelevant jobs just the ones that meet your criteria.",
    owner: "Suzanne Brown",
  },
];

const IndexBannerComponent = ({ callArray }) => {
  // componentDidMount() {
  //   var i = 0;
  //   // Placeholder Animation Start
  //   var inputSelector = document.querySelectorAll("input, textarea");
  //   for (i = 0; i < inputSelector.length; i++) {
  //     inputSelector[i].addEventListener("focus", function (event) {
  //       return this.parentElement.parentElement.classList.add("focused");
  //     });
  //   }
  //   for (i = 0; i < inputSelector.length; i++) {
  //     inputSelector[i].addEventListener("blur", function (event) {
  //       var inputValue = this.value;
  //       if (inputValue === "") {
  //         this.parentElement.parentElement.classList.remove("filled");
  //         this.parentElement.parentElement.classList.remove("focused");
  //       } else {
  //         this.parentElement.parentElement.classList.add("filled");
  //       }
  //     });
  //   }
  //   // Placeholder Animation End
  // }

  // handleSearch(e) {
  //   e.preventDefault();
  // }
  // render() {
  //   var settings = {
  //     slidesToShow: 1,
  //     arrows: true,
  //     infinite: true,
  //     autoplay: true,
  //     responsive: [
  //       {
  //         breakpoint: 1200,
  //         settings: {
  //           slidesToShow: 1,
  //         },
  //       },
  //       {
  //         breakpoint: 991,
  //         settings: {
  //           slidesToShow: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 576,
  //         settings: {
  //           slidesToShow: 1,
  //         },
  //       },
  //     ],
  //   };

  // }

  return (
    <>
      <div
        className="dez-bnr-inr content-inner-2 dez-bnr-inr-md overlay-black-middle"
        style={{
          backgroundImage: `url(${finalImage})`,
        }}
      >
        <div className="container ">
          <div className="dez-bnr-inr-entry align-m">
            <div className="find-job-bx">
              {/* <form className="dezPlaceAni searchFormSize">
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>Job Title, Keywords, or Phrase</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="form-group">
                      <label>Select Location</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fa fa-map-marker" ></i>
                          
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="form-group">
                      <Form.Control
                        as="select"
                        custom
                        className="select-btn p-1"
                      >
                          {jobSectorList.map((item, index) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                      </Form.Control>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-12">
                    <Button
                      onClick={() => redirectUrl("/permanent-jobs")}
                      className="site-button bg-primary btn-block"
                    >
                      Find Job
                    </Button>
                  </div>
                </div>
              </form> */}
              <Jobfindbox
                allJobs={callArray}
                filteredJobs={callArray}
                isHomePage={true}
                // handleSubmit={handleSubmit}
              />
              <center>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ittrp.staffing_connect"
                  target="_blank"
                >
                  <img
                    src={playStore}
                    alt="Download on Google Play"
                    id="playStore"
                    className="img-fluid appImage"
                  />
                </a>
                <a
                  href="https://apps.apple.com/app/staffing-connect/id6449247669"
                  target="_blank"
                >
                  <img
                    src={appStore}
                    id="appStore"
                    alt="Download on the App Store"
                    className="img-fluid appImage"
                  />
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IndexBannerComponent;
