import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import IndexBanner from "./../components/Element/IndexBanner";
import Jobcategories from "./../components/Element/Jobcategories";
import Featureblog from "./../components/Element/Featureblog";
import Jobsection from "./../components/Element/Jobsection";
import Owltestimonial from "./../components/Element/Owlblog1";

//Images
// var bnr2 = require('./../images/background/bg4.jpg');
// var bnr3 = require('./../images/lines.png');
// import bnr2 from './../images/background/bg4.jpg';
import bnr2 from "./../images/new-updated-images/home/home-testimonial.jpg";
import bnr3 from "./../images/lines.png";
import playStore from "./../images/play-store.png";
import appStore from "./../images/app_store.png";
import IndexBannerComponent from "./../components/Element/IndexBanner";
import composedJobsContainer from "../containers/JobsContainer";
import { saveAs } from 'file-saver';


function Homepage(props) {
  const [allJobsRecords, setAllJobsRecords] = useState([]);
  const [jsonArray, setJsonArray] = useState([]);
  const handleDownload = (jsonData) => {
    const file = new Blob([jsonData], { type: 'text/json;charset=utf-8' });
    saveAs(file, 'hello_world.json');
  };
  useEffect(() => {
    const lenOfData = props.jobsdetails;
    if (lenOfData) {
      setAllJobsRecords(lenOfData);
    }
  }, [props.jobsdetails]);
  // Add title
  if (window.location.pathname == "/") {
    document.getElementById("titleText").innerText =
      "Staffing Connect - Home Page";
  } else {
    document.getElementById("titleText").innerText =
      "Staffing Connect - Home Page Alternate";
  }

  // Plain Html Code Print In Description - Start
  const getPlainHtml = (html) => {
    let tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  // Plain Html Code Print In Description - End
  // Get Random Numbers - Start
  const generateUniqueNumber = () => {
    // Generate a random number and append timestamp to ensure uniqueness
    const randomNumber = Math.floor(Math.random() * 1000000); // Adjust range as needed
    const timestamp = Date.now(); // Get current timestamp
    return `${randomNumber}${timestamp}`;
  }
  // Get Random Numbers - End

  useEffect(() => {
    if (allJobsRecords) {
      let jobPosting = []; // Declare as mutable variable
      jobPosting = JSON.stringify(
        allJobsRecords.map((job) => ({
          "@context": "https://schema.org/",
          "@type": "JobPosting",
          "title": job.jobs_title,
          "description":
            "<p>" + getPlainHtml(job.jobs_description) + "</p>",
          "identifier": {
            "@type": "PropertyValue",
            "name": job.jobs_title + " at Staffing Connect",
            "value": generateUniqueNumber(),//job.jobs_id,//"1234567" + job.jobs_id,
          },
          "datePosted": job.datePosted,
          "validThrough": job.jobs_end_date + "T00:00",
          "employmentType": job.jobs_job_type_contract_type == "permanentrequirement"
          ? "FULL_TIME"
          : job.jobs_job_type_contract_type == "temporaryrequirement"
          ? "TEMPORARY"
          : job.jobs_job_type_contract_type == "temproryandpermanent"
          ? "OTHER"
          : "OTHER",
          "hiringOrganization": {
            "@type": "Organization",
            "name": "Staffing Connect",
            "sameAs": "https://www.staffingconnect.co.uk",
            "logo": "https://admin.itstaffingsolutions.co.uk/assets/img/brand/staffing-new.png",
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": job.jobs_location_address_1,
              "addressLocality": job.jobs_location_town,
              "addressRegion": job.jobs_location_region
                ? job.jobs_location_region
                : job.jobs_location_town,
              "postalCode": job.jobs_location_post_code,
              "addressCountry": "UK",
            },
          },
          "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "GBP",
            "value": {
              "@type": "QuantitativeValue",
              "value": job.jobs_salary_details_min_salary,
              // "maxValue": job.jobs_salary_details_max_salary,
              "unitText":
                job.jobs_salary_details_salary_type == "per_hour"
                  ? "HOUR"
                  : job.jobs_salary_details_salary_type == "per_day"
                  ? "DAY"
                  : job.jobs_salary_details_salary_type == "per_annum"
                  ? "YEAR"
                  : "MONTH",
            },
          },
          // Add other fields for each job posting
        }))
      );

      // console.log("Job Posting Array :=> " + jobPosting);
      // setJsonArray(jobPosting);
      let jsonJobs = document.getElementById('jsonJobs');
      
      if(jsonJobs){
        jsonJobs.innerHTML = jobPosting;
      }
    }
  }, [allJobsRecords]);

  return (
    <>
      {/* Add Goggle Jobs - Start */}
      <div>
        {/* {jsonArray && jsonArray} */}
        {/* <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: jsonArray }}
        /> */}
      </div>
      {/* Google Jobs End */}
      <div className="page-wraper text-left">
        <div className="page-content">
          <IndexBannerComponent callArray={props.jobsdetails} />
          <div className="section-full job-categories content-inner-2 bg-white">
            <div className="container">
              <div className="section-head d-flex head-counter clearfix">
                <div className="mr-auto">
                  <h1 style={{ display: "none" }}>
                    Staffing Connect - Home Page - Explore Home Page Here
                  </h1>
                  <h2 className="m-b5 text-center">
                    Quality Staffing Solutions Tailored to Your Needs
                  </h2>
                  <h6 className="fw3 text-left">
                    At Staffing Connect, we are committed to providing
                    exceptional services in Education, Home Care, and Social
                    Care sectors, offering both temporary and permanent staffing
                    solutions. Our professional, reliable, and dedicated team
                    works tirelessly to ensure the highest standards are met,
                    helping you find the right people to support your specific
                    needs.
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="section-full job-categories bg-white">
            <div className="container">
              <div className="section-head d-flex head-counter clearfix">
                <div className="mr-auto">
                  <h2 className="m-b5">Popular Assignments</h2>
                  <h6 className="fw3">100+ Temporary Jobs available for you</h6>
                </div>
                <div className="head-counter-bx">
                  <h2 className="m-b5 counter">
                    <CountUp end={1800} duration={5} />
                  </h2>
                  <h6 className="fw3">Jobs Posted</h6>
                </div>
                <div className="head-counter-bx">
                  <h2 className="m-b5 counter">
                    <CountUp end={4500} duration={5} />
                  </h2>
                  <h6 className="fw3">Tasks Posted</h6>
                </div>
                <div className="head-counter-bx">
                  <h2 className="m-b5 counter">
                    <CountUp end={1500} duration={5} />
                  </h2>
                  <h6 className="fw3">Freelancers</h6>
                </div>
              </div>
              <Jobcategories />
            </div>
          </div>
          <Jobsection className="mt--5" />
          {/* Splited Content */}
          <Featureblog />

          <div
            className="section-full p-tb70 overlay-black-dark text-white text-center bg-img-fix "
            style={{ backgroundImage: "url(" + bnr2 + ")" }}
            role="img"
            aria-label="Testimonials Background"
          >
            <div className="container">
              <div className="section-head text-center text-white">
                <h2 className="m-b5">Testimonials</h2>
                <h5 className="fw4">Few words from our Candidates</h5>
              </div>
              <Owltestimonial />
            </div>
          </div>

          <div
            className=" content-inner-2 footerImage footerImageHome"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
            role="img"
            aria-label="Footer Background"
          >
            {/* <div className="section-full content-inner-2 overlay-white-middle" style={{backgroundImage:"url( " + bnr3 + ")", backgroundPosition:"bottom", backgroundRepeat:"no-repeat", backgroundSize: "100%" }}> */}
            {/* <div className="container"> */}
            {/* <div className="section-head text-black text-center">
							<h2 className="m-b0">Membership Plans</h2>
							<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
						</div> */}
            {/* <div className="section-content box-sort-in button-example m-t80">
							<div className="pricingtable-row">
								<div className="row max-w1000 m-auto">
									<div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
										<div className="pricingtable-wrapper style2 bg-white">
											<div className="pricingtable-inner">
												<div className="pricingtable-price"> 
													<h4 className="font-weight-300 m-t10 m-b0">Basic</h4>
													<div className="pricingtable-bx"><span>Free</span></div>
												</div>
												<p>Lorem ipsum dolor sit amet adipiscing elit sed do eiusmod tempors labore et dolore magna siad enim aliqua</p>
												<div className="m-t20"> 
													<Link to={"/register"} className="site-button radius-xl"><span className="p-lr30">Sign Up</span></Link> 
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
										<div className="pricingtable-wrapper style2 bg-primary text-white active">
											<div className="pricingtable-inner">
												<div className="pricingtable-price"> 
													<h4 className="font-weight-300 m-t10 m-b0">Professional</h4>
													<div className="pricingtable-bx"> $ <span>29</span> /  Per Installation </div>
												</div>
												<p>Lorem ipsum dolor sit amet adipiscing elit sed do eiusmod tempors labore et dolore magna siad enim aliqua</p>
												<div className="m-t20"> 
													<Link to={"/register"} className="site-button white radius-xl"><span className="text-primary p-lr30">Sign Up</span></Link> 
												</div>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
										<div className="pricingtable-wrapper style2 bg-white">
											<div className="pricingtable-inner">
												<div className="pricingtable-price"> 
													<h4 className="font-weight-300 m-t10 m-b0">Extended</h4>
													<div className="pricingtable-bx"> $  <span>29</span> /  Per Installation </div>
												</div>
												<p>Lorem ipsum dolor sit amet adipiscing elit sed do eiusmod tempors labore et dolore magna siad enim aliqua</p>
												<div className="m-t20"> 
													<Link to={"/register"} className="site-button radius-xl"><span className="p-lr30">Sign Up</span></Link> 
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    jobsdetails: state.jobsdetails.jobsdetails,
  };
};
export default composedJobsContainer(Homepage);
