import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
const EmailVerificationList = (props) => {
  const [timesheets, SetTimeSheets] = useState([]);
  useEffect(() => {
    SetTimeSheets(props.timesheets);
  }, [props.timesheets]);
  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <table className="table table-striped table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              {/*<th  className="col-1 text-center" scope="col">Sr. No</th>*/}
              <th className="col-1 text-center" scope="col">
                Date & Time
              </th>
              <th className="col-3 text-center" scope="col">
                {" "}
                Name
              </th>
              {/*<th  className="col-2 text-center" scope="col">Email</th>*/}
              <th className="col-2 text-center" scope="col">
                Client Name
              </th>
              <th className="col-2 text-center" scope="col">
                Week Commencing
              </th>
              <th className="col-3 text-center" scope="col">
                Uploaded Timesheet
              </th>
              <th className="col-1 text-center" scope="col">
                Status
              </th>
              {/*<th  className="col-1 text-center" scope="col">Action</th>*/}
            </tr>
          </thead>
          <tbody>
            {timesheets &&
              timesheets.length > 0 &&
              timesheets?.map((timesheet, i) => (
                <tr key={i}>
                  {/*<td className="col-1 text-center" scope="row">
                    {i + 1}
              </td>*/}
                  <td className="col-4 text-center" scope="row">
                    {timesheet.cnd_timesheet_created_date}
                  </td>
                  <td className="col-3 text-center" scope="row">
                    {timesheet.fullname}
                  </td>
                  {/*<td  className="col-2" scope="col">{timesheet.users_email}</td>*/}
                  <td className="col-3 text-center" scope="row">
                    {timesheet.cnd_timesheet_client_name}
                  </td>
                  <td className="col-3 text-center" scope="row">
                    {timesheet.cnd_timesheet_week_commencing}
                  </td>
                  <td className="col-3 text-center" scope="col">
                    <a
                      rel="noopener noreferrer"
                      download
                      href={
                        constants.IMAGE_URL +
                        timesheet.cnd_timesheet_folderpath +
                        timesheet.cnd_timesheet_upload_document
                      }
                      target="_blank"
                    >
                      <i className="fa fa-download f-26"></i>
                    </a>
                  </td>
                  <td className="col-1 text-center" scope="col">
                    {" "}
                    {timesheet.cnd_timesheet_status}
                  </td>
                  {/*<td  className="col-1 text-center" scope="col"> <Link to={"/create-timesheet/"+timesheet.cnd_timesheet_id}><i className="fa fa-pencil"></i></Link></td>*/}
                </tr>
              ))}
            {timesheets.length == 0 && (
              <tr>
                <td colSpan="100">No timesheets Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    timesheets: state.timesheets.timesheets,
  };
};

export default connect(mapStateToProps)(EmailVerificationList);
