import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import Countries from "../Countries";
import Input from "../../FormElements/Input";
import Label from "../../Layout/Label";
const AddressFields = (props) => {
  const [branch, setFormdata] = useState({});
  const { errors } = props;
  useEffect(() => {
    setFormdata(props.data);
  }, [props.data]);
  return (
    <React.Fragment>
      <div className="row m-b30">
        <Input
          type="text"
          name="clt_branch_name"
          value={branch.clt_branch_name}
          label="Branch Name"
          // disabled={true}
          changeHandler={(e) => props.onChange(e)}
          errors={errors}
          lg={6}
        />
        <Input
          type="text"
          name="clt_branch_location"
          value={branch.clt_branch_location}
          label="Branch Location"
          // disabled={true}
          changeHandler={(e) => props.onChange(e)}
          errors={errors}
          lg={6}
        />
        <Input
          type="hidden"
          name="cnd_county"
          value={branch.clt_branch_country}
          // label="Country"
          // disabled={true}
          changeHandler={(e) => props.onChange(e)}
          errors={errors}
          lg={4}
        />
        <Input
          type="text"
          label="Address"
          placeholder={"Eg., 123 Main St"}
          lg={12}
          md={12}
          changeHandler={(e) => props.onChange(e)}
          name="cnd_street_address"
          value={branch.clt_branch_address1}
          errors={errors}
        />
        <Input
          type="text"
          lg={12}
          md={12}
          changeHandler={(e) => props.onChange(e)}
          name="cnd_street_address_2"
          value={branch.clt_branch_address2}
          placeholder="Eg., Apartment, suite, unit etc. (optional)"
          errors={errors}
        />
        <Input
          type="text"
          changeHandler={(e) => props.onChange(e)}
          name="cnd_town"
          lg={4}
          md={4}
          value={branch.clt_branch_town}
          placeholder="Eg., London"
          errors={errors}
        />
        <Input
          type="text"
          changeHandler={(e) => props.onChange(e)}
          name="cnd_region"
          lg={4}
          md={4}
          value={branch.clt_branch_region}
          placeholder="Eg., London"
          errors={errors}
        />
        <Input
          type="text"
          changeHandler={(e) => props.onChange(e)}
          name="cnd_postcode"
          lg={4}
          md={4}
          //maxLength={8}
          value={branch.clt_branch_postal}
          placeholder="Eg., AB12CD"
          errors={errors}
        />
      </div>
    </React.Fragment>
  );
};

export default AddressFields;
