import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Further-education-banner.jpg";
// import HomecareImg from "./../images/ourservices/Further-education.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/education/edu-further/our-services-edu-further-education-banner.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/education/edu-further/our-services-edu-further-education.jpg";
import bnr3 from "./../images/lines.png";

export default function Further() {
  // Add title
  document.getElementById("titleText").innerText =
    "Staffing Connect - Our Services - Further Education";
  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{
            backgroundImage: `url(${Background})`,
            height: 580,
            marginTop:
              window.location.pathname == "/mobile/further-education"
                ? "0rem"
                : "",
          }}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white">
                <b style={{ display: "none" }}>
                  Staffing Connect - Our Services -{" "}
                </b>
                Further Education
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/further-education" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Education</li>
                  <li>Further Education</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">Further Education </h2>
                  {/* <p>Why, hello there! Want to see more of our work? Check out just some of what we've been up to below.</p> */}
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div
                  className="col-md-12 col-lg-6 m-b10"
                  style={{ marginTop: "-121 !important", marginBottom: "-3%" }}
                >
                  <p className="spacebar mt-2"></p>
                  <h5>
                    Elevate Your Further Education Journey with Staffing
                    connects Unparalleled Recruitment Solutions
                  </h5>

                  <p className="text-left">
                    As a vanguard in Further Education, Staffing Connect is the
                    premier recruitment agency for lecturers and assessors.
                    Harnessing our collective experience of two decades, we have
                    honed a precision-targeted approach to connecting the most
                    qualified candidates with the best Further Education
                    opportunities across the United Kingdom.
                  </p>

                  <p className="text-left">
                    Our services cover a broad spectrum of specialties. We're
                    adept at facilitating placements for various roles,
                    including temporary and permanent college lecturers,
                    Learning Support Assistants, Assessors, and non-academic
                    support staff. But our expertise doesn't stop there. We also
                    handle executive placements, including interim managerial
                    roles, offering our clients access to top-tier talent to
                    lead their organizations.
                  </p>
                </div>
              </div>
              <p className="text-left">
                We take immense pride in the bespoke nature of our services.
                Each solution we offer is meticulously tailored to dovetail with
                our client's unique workflows and operational strategies. Our
                goal is to augment efficiency within their organizations while
                providing substantial cost-effectiveness.
              </p>

              <p className="text-left">
                But at Staffing Connect, we don't just provide a service. We
                establish partnerships, working closely with our clients to
                fully understand their needs and aspirations. This collaborative
                approach enables us to align our recruitment strategies with
                their objectives, ensuring optimal outcomes for all parties
                involved.
              </p>

              <p className="text-left">
                Trust Staffing Connect to be your catalyst for growth and
                success in the ever-evolving landscape of Further Education.
                With our unwavering commitment to quality, comprehensive
                expertise, and client-centric approach, we're poised to elevate
                your organization to new heights of achievement.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
