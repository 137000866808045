import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  formInputFields,
  validationSchema,
} from "./FormGeneralPractitionerHelper";
import {
  updateDataOnRequest,
  getDataOnRequest,
} from "../../../redux/actions/CommonActions";
import LoadFormFields from "../../Layout/LoadFormFields";
const CareManager = (props) => {
  const [company, setFormdata] = useState({});
  const { loader, userDetails, careplan } = props;
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setFormdata(careplan);
    if (formInputFields(careplan).length > 0)
      formInputFields().map((field, key) => {
        if (careplan[field.name]) setValue(field.name, careplan[field.name]);
      });
  }, [careplan]);

  const onSubmit = (data) => {
    let formData = data;
    formData["users_access_token"] = userDetails.users_access_token;
    dispatch(
      updateDataOnRequest(
        JSON.stringify(formData, null, 2),
        "SaveGenralPractitioner",
        "",
        getDataOnRequest(
          userDetails.users_access_token,
          "GetCarePlan",
          "SET_CARE_PLAN"
        )
      )
    );
  };

  const onChangeInputvalue = (e) => {
    const { name, value } = e.target;
    company[name] = value;
    setFormdata(company);
  };

  return (
    <React.Fragment>
      <div className="job-bx submit-resume">
        <div className="job-bx-title clearfix">
          <h5 className="font-weight-700 pull-left text-uppercase">
            Genral Practitioner
          </h5>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LoadFormFields
            register={register}
            errors={errors}
            formInputFields={formInputFields()}
            onChangeInputvalue={(e) => onChangeInputvalue(e)}
            reset={reset}
            data={company}
          />
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.error.error,
    loader: state.loader.loader,
    userDetails: state.auth.authUserData,
    careplan: state.careplan.careplan,
  };
};
export default connect(mapStateToProps)(CareManager);
