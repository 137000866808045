import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import {getDataOnRequest} from '../redux/actions/CommonActions'
import { toast } from 'react-toastify';
const DBSDocument = (OriginalComponent) => {
  const NewComponent = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token){
        (params.id) && 
          dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetDBSDocument','SET_DBS',params.id))
      }
    },[props.userAuth.users_access_token])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    dbsdocument:state.dbsdocuments.dbsdocument,
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData
  };
};

const composedDBSDocument = compose(connect(mapStateToProps), DBSDocument)
export default composedDBSDocument;

