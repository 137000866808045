import React, { useEffect, useState } from "react";
import Profilesidebar from "./../components/Element/Profilesidebar";
import AvailabilityForm from "../components/Element/SideBarForms/AvailabilityForm";
import Menusidebar from "../components/Element/Menusidebar";
import UpdatedAvailability from "../components/Element/SideBarForms/Mobile-Availability";
import { isMobileOrLaptop } from "../services/CheckDevice";
import { deviceType } from "react-device-detect";
import MobileAvailability from "../components/Element/SideBarForms/Mobile-Availability";
import MenuSideBarMobile from "../components/Element/MenuSideBarMobile";

const Availability = (props) => {
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(350);
    setCheckDevice(deviceType);
  }, [deviceType]);

  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {checkDevice == "Mobile" ? (
                  <MenuSideBarMobile page="availability" />
                ) : (
                  <Menusidebar page="availability" />
                )}

                <div className="col-xl-9 col-lg-8 m-b30">
                  {/* <AvailabilityForm /> */}
                  {checkDevice == "Laptop/Desktop" ? (
                    <AvailabilityForm />
                  ) : (
                    <MobileAvailability />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Availability;
