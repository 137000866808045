export const allJobs = [
    {
        title: 'Residential Support Workers',
        price: '£18,000 to £24,000',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Residential Support worker? Residential Support workers are needed asap!  A residential support worker is a professional who provides centre assistance, guidance, and support to individuals who reside in residential care facilities. These facilities may include group homes, assisted living facilities, or residential treatment centres. Residential support workers typically work with individuals who have various needs, such as physical disabilities, mental health issues, developmental disabilities, or behavioural challenges.',
        responsibilities: [
            'Assisting residents with activities of daily living, such as bathing, dressing, grooming, and toileting.',
            'Ensuring residents take their prescribed medications correctly and monitor their health conditions.',
            'Providing emotional encouragement, active listening, and counselling to residents who may be experiencing distress or difficulties.',
            'Implementing behaviour support plans and strategies to manage challenging behaviours and promote positive behaviour among residents.',
            'Teaching and assisting residents with acquiring or improving skills related to cooking, cleaning, budgeting, and maintaining a clean-living environment.',
            'Organizing and facilitating recreational and social activities to promote socialization and engagement among residents.',
            'Maintaining accurate and up-to-date records of resident progress, incidents, and any changes in their health or behaviour.',
            'Responding to emergencies or crises that may arise within the residential setting and taking appropriate action to ensure the safety and well-being of residents.',
            'Serving as an advocate for residents, liaising with other healthcare professionals, families, and external agencies to ensure their needs are met and their rights are protected.',
            'Working closely with a multidisciplinary team, including other support workers, nurses, therapists, and supervisors, to develop and implement individualized care plans for residents.',
        ],
        othercontaint: [
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",
    },
    {
        title: 'Learning Support Assistant',
        price: '£18,000 to £26,000',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Learning support assistant? Learning support assistants are needed asap! A learning support assistant (LSA) is an individual who works alongside teachers in schools to provide additional support and assistance to students. Learning support assistant plays a crucial role in helping students with diverse learning needs to reach their full potential.',
        responsibilities: [
            'Assist students with their learning by providing individualized support, including one-on-one assistance and small group work.',
            'Collaborate with teachers to facilitate classroom activities, such as preparing materials, setting up equipment, and organizing learning resources.',
            'Help maintain a positive and inclusive classroom environment by managing student behaviour and implementing behaviour management strategies as directed by the teacher.',
            'Modify teaching techniques and learning materials to meet the specific needs of students with learning difficulties, disabilities, or special educational needs (SEN).',
            'Work to ensure that all students can fully participate in classroom activities by providing additional support, accommodations, or adaptations as necessary.',
            'Maintain accurate records of student progress, behaviour, and any additional support provided, contributing to individual education plans (IEPs) or other personalized learning programs.',
            'Work closely with teachers, SEN coordinators, and other professionals to develop strategies, exchange information, and ensure effective communication regarding student progress and needs.',
            'Assist in implementing individualized learning goals and targets, as outlined or personalized education plans, to support student development and achievement.',
            'Communicate with parents or guardians to provide updates on student progress, discuss concerns, and collaborate on strategies for supporting student learning and development.'
        ],
        othercontaint: [
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",

    },
    {
        title: 'Qualified Primary and Secondary School Teachers',
        price: '£25,714-£32,157',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Qualified Primary and Secondary School Teacher? Qualified Primary and Secondary School Teachers are needed asap! Qualified Primary and Secondary School Teachers have specific responsibilities and tasks that align with requirements.',
        responsibilities: [
            'Teachers are responsible for delivering lessons and implementing the Curriculum, which sets out the learning objectives, subject content, and assessment criteria for each key stage.',
            'Teachers adapt their teaching methods to meet the diverse needs of students, including those with special educational needs or disabilities and English as an Additional Language learners. They provide differentiated instruction and support to ensure every student can access the curriculum.',
            'Teachers regularly assess student progress, conduct formative and summative assessments, and provide feedback to students and parents/carers. They also prepare and submit reports on student attainment and progress in line with school policies.',
            'In Primary schools, teachers prepare students for the Key Stage 1 and Key Stage 2 SATs (Standard Assessment Tests). In Secondary schools, they prepare students for GCSE (General Certificate of Secondary Education) examinations, providing subject-specific instruction and exam preparation.',
            'Teachers work with the Special Educational Needs Coordinator (SENCo) to create and implement Individual Education Plans for students with special educational needs or disabilities. They provide appropriate accommodations, adaptations, and support to meet their specific learning needs.',
            'Teachers have a legal duty to safeguard and promote the welfare of their students. They must be vigilant for signs of abuse, follow safeguarding policies and procedures, and report any concerns to designated safeguarding leads.',
            'Teachers deliver PSHE lessons (Personal, Social, Health, and Economic Education), covering topics such as health education, relationships and sex education, emotional well-being, and financial literacy.',
            'Teachers plan and prepare lessons, schemes of work, and resources to meet curriculum requirements. They use formative and summative assessments to track student progress, identify areas for improvement, and inform future teaching strategies.',
            'Teachers engage in ongoing professional development, attending training sessions, workshops, and courses to enhance their subject knowledge, and teaching skills, and stay updated with educational policies and practices.'
        ],
        othercontaint: [
            'Its important to note that these responsibilities may be subject to changes in education policies and guidelines. Teachers should stay informed about current developments and adapt their practice accordingly.',
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",

    },
    {
        title: 'Special Education Needs Teacher',
        price: '£25,000 to £33,000',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as Special education needs teacher? Qualified Special education needs teachers are needed asap! A special education needs (SEN) teacher is a highly specialized educator who works with students who have various types of disabilities or learning difficulties. Their primary role is to provide individualized support and instruction to help these students overcome barriers to learning and achieve their educational goals.',
        responsibilities: [
            'SEN teachers work closely with students, parents, and other professionals to identify and assess individual learning needs and disabilities. They use a range of assessment tools and observations to gather information about students strengths and areas requiring support.',
            'Based on their assessments, SEN teachers create individual education plans for each student. These plans outline personalized goals, strategies, accommodations, and interventions to support the students learning and development.',
            'SEN teachers develop and deliver specialized teaching strategies, interventions, and resources tailored to students individual needs. They adapt curriculum materials, use assistive technologies, and employ various teaching methods to facilitate learning and engagement.',
            'SEN teachers employ a range of strategies to differentiate instruction, addressing diverse learning styles, abilities, and needs within their classroom. They may use visual aids, manipulatives, assistive technologies, and multisensory approaches to enhance student understanding and participation.',
            'SEN teachers offer support, advice, and professional development opportunities to other teachers and school staff. They share best practices, provide guidance on differentiation techniques, and promote inclusive teaching strategies.',
            'SEN teachers regularly monitor students progress, collect data, and evaluate the effectiveness of interventions and support strategies. They use this information to make informed decisions about adjustments to teaching approaches or to seek additional support when required.',
            'SEN teachers maintain regular communication with parents or guardians, updating them on their childs progress, sharing strategies for support at home, and involving them in the decision-making process regarding their childs education.',
            'SEN teachers handle administrative tasks related to students needs, including maintaining records, preparing reports, and managing resources such as specialized equipment, assistive technology, and learning materials.',
            'SEN teachers play a crucial role in promoting inclusive education within their schools and communities. They advocate for the rights of students with Special education needs & disabled, raise awareness about their needs, and contribute to the development of inclusive policies and practices.',
            'SEN teachers engage in ongoing professional development to enhance their knowledge and skills in special education. They stay abreast of the latest research, best practices, and legislative changes to ensure they provide the most effective and up-to-date support to their students.',

        ],
        othercontaint: [
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",

    },
    {
        title: 'ICT Teacher',
        price: '£25,000 to £33,000',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as ICT Teacher? ICT teachers are needed asap!An ICT (Information and Communication Technology) teacher is responsible for teaching students about various aspects of technology, computer science, and digital literacy. Their primary role is to facilitate students understanding and use of technology as a tool for learning, problem-solving, and communication.',
        responsibilities: [
            'ICT teachers develop lesson plans that align with the curriculum and educational standards. They prepare engaging and interactive lessons to teach students various ICT concepts, skills, and applications.',
            'ICT teachers instruct students on a wide range of ICT topics, including computer hardware and software, programming, web design, multimedia creation, data analysis, and digital literacy. They explain complex concepts in a clear and understandable manner.',
            'ICT teachers demonstrate the use of different software applications, tools, and technologies. They guide students in using word processors, spreadsheets, presentation software, graphic design tools, programming languages, and other relevant ICT tools.',
            'ICT teachers provide students with practical, hands-on experiences to apply their ICT knowledge and skills. They organize activities, projects, and assignments that allow students to use ICT tools and techniques to solve problems and complete tasks.',
            'ICT teachers assess and evaluate student understanding and progress in ICT through various methods such as quizzes, tests, projects, assignments, and practical demonstrations. They provide feedback to students to help them improve their ICT skills and knowledge.',
            'ICT teachers provide individualized support to students who may be struggling with ICT concepts or skills. They offer extra assistance, provide additional resources, and adapt teaching strategies to meet the needs of diverse learners.',
            'ICT teachers manage and maintain ICT resources, including computers, software, peripherals, and other technological tools. They ensure that equipment is in working order, update software as needed, and troubleshoot technical issues.',
            'ICT teachers educate students on digital literacy skills, including responsible and ethical use of technology, online safety, information literacy, and cybersecurity. They teach students how to critically evaluate online information and promote responsible digital citizenship.',
            'ICT teachers collaborate with other teachers and educators to integrate ICT into various subject areas. They work together to develop cross-curricular projects, share best practices, and support technology integration throughout the school.',
            'ICT teachers engage in continuous professional development to stay up-to-date with the latest ICT trends, tools, and educational practices. They attend workshops, conferences, and training sessions to enhance their own ICT skills and knowledge. Its important to note that the specific responsibilities and tasks of an ICT teacher can vary depending on the educational level (e.g., primary, secondary, or higher education), the specific curriculum, and the resources available in the school or institution.',
        ],
        othercontaint: [
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",

    },
    {
        title: 'Psychology/ Sociology Teacher',
        price: '£28,714 to £39,604',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Psychology/ Sociology teacher? Psychology/ Sociology teacher are needed asap!As a psychology/sociology teacher in a school, your role would involve educating students about the principles, theories, and methodologies of psychology and sociology. You will develop and deliver lessons, assess students progress, manage the classroom environment, and collaborate with colleagues and parents/guardians. Your role is to inspire a love for learning, foster critical thinking skills, and promote an understanding of human behaviour and social structures.',
        responsibilities: [
            'Develop a comprehensive and engaging curriculum for psychology and sociology that aligns with the national educational standards and examination board requirements (e.g., AQA, OCR).',
            'Select appropriate textbooks, resources, and materials to support teaching and learning in psychology and sociology.',
            'Design lesson plans that cover a wide range of topics, including social psychology, cognitive psychology, sociological theories, research methods, culture, identity, and social issues.',
            'Deliver well-structured and stimulating lessons using a variety of teaching strategies, such as lectures, discussions, group work, case studies, multimedia presentations, and practical experiments.',
            'Foster critical thinking, analytical skills, and a deeper understanding of psychological and sociological concepts.',
            'Adapt teaching methods to meet the diverse needs and abilities of students, ensuring an inclusive and supportive learning environment.',
            'Encourage active student participation and engagement through debates, projects, and real-world applications of psychological and sociological theories.',
            'Develop and administer assessments, including tests, quizzes, essays, projects, and presentations, to evaluate students understanding of key concepts and their ability to apply them.',
            'Provide timely and constructive feedback on students work to support their learning and development.',
            'Monitor and track individual student progress, identify areas of improvement, and provide additional support and guidance as required.',
            'Prepare students for internal and external examinations, offering guidance on exam techniques and revision strategies.',
            'Establish and maintain a positive, respectful, and inclusive classroom environment that fosters learning and student well-being.',
            'Set clear expectations for behaviour and conduct within the classroom, ensuring a safe and supportive learning atmosphere.',
            'Effectively manage classroom dynamics, address conflicts, and implement appropriate behaviour management strategies when necessary.',
            'Promote a culture of mutual respect, active listening, and open-mindedness among students.',
            'Identify students who may require additional support, including those with special educational needs or English as an additional language, and work collaboratively with support staff to develop personalized learning plans.',
            'Provide extra assistance, resources, and guidance to students who are struggling academically or emotionally.',
            'Establish effective communication channels with parents/guardians, regularly updating them on students progress, strengths, and areas for improvement.',
            'Stay updated with the latest developments, research, and best practices in the fields of psychology and sociology.',
            'Engage in continuous professional development activities, such as attending relevant workshops, conferences, and training sessions.',
            'Collaborate with colleagues within the psychology/sociology department, share knowledge, and contribute to the ongoing improvement of teaching and learning practices.',
            'Collaborate with other teachers within the psychology/sociology department and across disciplines to ensure a coherent and integrated curriculum.',
            'Attend departmental meetings, contribute to curriculum planning, and participate in school-wide initiatives.',
            'Maintain effective communication with colleagues, school administrators, and parents/guardians, providing regular updates on students progress, concerns, and achievements.',
            'Promote ethical conduct and sensitivity to diversity and cultural differences within the study and application of psychology and sociology.',
            'Create a supportive and inclusive learning environment where students feel safe to explore and discuss sensitive topics.',
            'Respect students privacy and maintain confidentiality regarding personal information and disclosures.'
        ],
        othercontaint: [
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",

    },
    {
        title: 'Food Technology Teacher',
        price: ' £25,000 to £32,000',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Food Technology Teacher? Food Technology Teachers are needed asap! As a Food Technology teacher, your role is to educate and guide students in various aspects of food science, nutrition, culinary skills, and food safety. You will play a crucial role in preparing students for careers in the food industry or equipping them with essential knowledge for personal well-being.',
        responsibilities: [
            'Develop and deliver engaging and comprehensive lesson plans in line with the national curriculum and exam board requirements. This includes preparing materials, resources, and activities to facilitate student learning.',
            'Contribute to the development and review of the schools Food Technology curriculum, ensuring it meets national standards and promotes students understanding of food science, nutrition, cooking skills, and food safety.',
            'Maintain a positive and inclusive classroom environment that fosters student engagement, participation, and mutual respect. Set clear expectations for behaviour and manage classroom activities effectively.',
            'Teach students essential culinary skills, including safe food handling, food preparation techniques, cooking methods, and recipe development. Supervise practical cooking sessions and provide guidance on presentation and taste.',
            'Deliver theoretical lessons on topics such as food science, nutrition principles, food safety regulations, food labelling, and food industry practices. Help students develop a deep understanding of the subject matter through explanations, discussions, and interactive activities.',
            'Design and administer formative and summative assessments to evaluate students’ progress and understanding. Prepare students for examinations, including providing guidance on exam techniques and reviewing past papers.',
            'Identify and address the individual learning needs of students, including those with special educational needs or disabilities. Provide additional support and accommodations as required to ensure inclusive learning for all students.',
            'Ensure that all practical cooking sessions and food-related activities adhere to health and safety regulations. Teach students about the importance of hygiene, safe working practices, and risk assessment in the food preparation environment.',
            'Regularly monitor and track students academic progress, attendance, and behaviour. Provide feedback to students and parents/guardians on their achievements and areas for improvement. Maintain accurate records of student performance.',
            'Engage in continuous professional development to stay updated with the latest developments in food technology, teaching methodologies, and assessment practices. Attend relevant training sessions, workshops, and conferences.',
            'Organize and supervise extracurricular activities related to food technology, such as cooking clubs, culinary competitions, and visits to food industry establishments. Encourage students passion for the subject and provide opportunities for them to showcase their skills.'

        ],
        othercontaint: [
            'Its important to note that specific responsibilities and tasks may vary depending on the school, grade level, and the needs of the students. Collaborate, communicate with parents/guardians, and actively participate in the school community to ensure a well-rounded educational experience for your students.',
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",

    },
    {
        title: 'English Teacher',
        price: '£24,373 to £36,253',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as an English teacher? English Teachers are needed asap! English teachers play a vital role in educating students in the English language and literature. They work at various levels of education, including primary schools, and secondary schools. English teachers have a significant impact on students language skills, critical thinking abilities, and their overall appreciation for literature. They inspire and guide students to become effective communicators, thoughtful readers, and skilled writers in the English language.',
        responsibilities: [
            'English teachers are responsible for designing and planning engaging and comprehensive lessons that align with the curriculum objectives and learning outcomes.',
            'They deliver lessons to students, covering various aspects of English language skills such as reading, writing, speaking, and listening. This includes teaching grammar, vocabulary, comprehension strategies, literary analysis, and writing techniques.',
            'English teachers design and administer assessments to evaluate students progress and understanding of the material taught. They grade assignments, tests, and essays, providing constructive feedback to support student growth.',
            'Maintaining a positive and inclusive learning environment is crucial. English teachers establish rules and expectations, manage student behaviour, and ensure a safe and respectful classroom atmosphere conducive to learning.',
            'They adapt teaching methods and materials to accommodate students with diverse learning styles, abilities, and needs. This involves providing additional support to struggling students and challenging advanced learners.',
            'For students with special educational needs, English teachers collaborate with special education teachers and support staff to develop and implement Individualized Education Programs that address specific learning goals and accommodations.',
            'English teachers may participate in curriculum development and review committees, contributing their expertise to shape the English language and literature curriculum offered by the school.',
            'English teachers are involved in organizing and supervising extracurricular activities such as literary clubs, writing competitions, drama productions, or poetry recitals.',
            'They communicate with parents or guardians about students progress, behaviour, and any concerns. Regular meetings, progress reports, and parent-teacher conferences facilitate this communication.',
            'Engaging in ongoing professional development activities, such as attending workshops, conferences, or taking courses, allows English teachers to stay updated with new teaching methodologies, technologies, and educational research.'
        ],
        othercontaint: [
            'These responsibilities collectively contribute to creating a supportive and enriching learning experience for students, fostering their language proficiency, critical thinking skills, and appreciation for literature.',
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.',
        ],
        jobType: "permanent",
        sector: "Education",

    },
    {
        title: 'Geography Teacher',
        price: '£34,500 to £39,400',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Geography teacher? Geography Teachers are needed asap!Geography teachers are responsible for teaching geography as a subject to students at various levels of education, including primary schools and secondary schools. They play a crucial role in helping students develop an understanding of the physical and human aspects of geography, such as landscapes, ecosystems, climate, population distribution, cultural diversity, and socio-economic issues.They may also have completed a teacher training program to obtain qualified teacher status (QTS) or have a Postgraduate Certificate in Education (PGCE) specializing in geography.',
        responsibilities: [
            'Geography teachers are responsible for designing and developing lesson plans that align with the national curriculum and meet the needs of their students. They select appropriate learning resources, such as textbooks, maps, digital tools, and supplementary materials, to support their teaching.',
            'Geography teachers deliver lessons in a way that captures students interest and promotes active learning. They use a variety of teaching methods, including lectures, discussions, group activities, multimedia presentations, and practical experiments, to engage students and enhance their understanding of geographical concepts.',
            'Geography teachers regularly assess and evaluate student progress to determine their understanding of the subject matter. They design and administer quizzes, tests, projects, and assignments to assess students knowledge, skills, and critical thinking abilities. They also provide constructive feedback to help students improve their performance.',
            'Geography teachers may provide individualized instruction to students who require additional support or have specific learning needs. They identify areas where students are struggling and develop strategies to address those challenges, including providing extra guidance, offering one-on-one support, or modifying instructional materials.',
            'Geography teachers establish a positive and inclusive classroom environment where students feel safe, respected, and engaged in their learning. They set and enforce classroom rules, manage student behaviour, and maintain discipline to create an optimal learning environment for all students.',
            'Geography teachers often organize field trips and outdoor activities to provide practical experiences that complement classroom learning. They plan and supervise these excursions, ensuring students understand safety guidelines and educational objectives associated with the fieldwork.',
            'Geography teachers engage in continuous professional development to stay updated with advancements in the field of geography and teaching methodologies. They attend workshops, conferences, and training programs to enhance their subject knowledge, teaching skills, and awareness of new resources and technologies.',
            'Geography teachers communicate with parents or guardians to discuss student progress, address concerns, and provide updates on curriculum topics or upcoming events. They may also engage with the local community by organizing geography-related events, inviting guest speakers, or participating in community outreach programs.',
            'In secondary schools, geography teachers help students prepare for external examinations, such as the General Certificate of Secondary Education (GCSE) or A-level exams. They familiarize students with exam formats, provide revision materials, and offer guidance on exam techniques and strategies.'
        ],
        othercontaint: [
            'Its important to note that specific responsibilities and tasks may vary depending on the school, grade level, and educational policies in place.',
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'

        ],
        jobType: "permanent",
        sector: "Education",

    },
    {
        title: 'Art Teacher',
        price: '£25,714 to £36,253',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as an Art teacher? Art Teachers are needed asap! Art teachers in England schools are responsible for teaching art as a subject to students at various levels of education, including primary schools and secondary schools. They play a crucial role in helping students develop creativity, artistic skills, visual literacy, and an appreciation for art. They may have completed a teacher training program to obtain qualified teacher status (QTS) or have a Postgraduate Certificate in Education (PGCE) specializing in geography',
        responsibilities: [
            'Art teachers design and develop lesson plans that align with the national curriculum and meet the needs and abilities of their students. They plan engaging and diverse art activities, including drawing, painting, sculpture, printmaking, digital art, and mixed media projects.',
            'Art teachers provide instruction and demonstrations on various artistic skills and techniques, such as colour theory, composition, perspective, shading, and different art mediums. They guide students in developing their technical skills and exploring different artistic approaches.',
            'Art teachers introduce students to art history, art movements, and the works of famous artists. They help students understand and appreciate the cultural and historical significance of artworks, encouraging critical thinking and interpretation.',
            'Art teachers assess and evaluate student progress through ongoing observations, critiques, and portfolio reviews. They provide constructive feedback to students to help them improve their artistic skills and develop their artistic voice.',
            'Art teachers may organize art exhibitions or displays to showcase students artwork within the school or in the wider community. They coordinate logistics, curate the exhibition, and involve students in the process to celebrate their artistic achievements.',
            'Art teachers integrate technology and digital media into their teaching, incorporating software, digital tools, and multimedia platforms to explore digital art, graphic design, or animation. They stay updated with advancements in digital art techniques and tools.',
            'Art teachers may supervise art clubs, after-school activities, or art-related trips to provide additional opportunities for students to engage with art outside the regular curriculum. They encourage creativity, artistic exploration, and personal expression beyond the classroom.',
            'Art teachers provide individualized instruction and support to students with diverse learning needs or specific artistic goals. They adapt teaching strategies, offer guidance, and provide resources to accommodate different learning styles and abilities.',
            'Art teachers engage in continuous professional development, attending art workshops, conferences, and training programs to enhance their own artistic skills, knowledge of contemporary art practices, and teaching methodologies. They stay updated with changes in the national curriculum and educational policies.'
        ],
        othercontaint: [
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",
    },
    {
        title: 'science Teacher',
        price: '£25,714 to £36,624',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Science Teacher? Science Teachers are needed asap! Science teachers in schools in England play a crucial role in educating students about various scientific disciplines and fostering curiosity and understanding of the natural world.',
        responsibilities: [
            'Science teachers are responsible for planning and preparing lessons that align with the national curriculum and the specific needs of their students. This includes designing engaging activities, selecting appropriate resources, and creating assessments.',
            'Science teachers should have a solid understanding of scientific concepts and principles across disciplines such as biology, chemistry, and physics. They need to stay up to date with advancements in scientific research and be able to communicate complex ideas in a way that students can understand.',
            'Science teachers design and plan lessons to engage and challenge students. They consider the learning objectives, appropriate teaching strategies, and the use of resources, practical experiments, and demonstrations to facilitate understanding and application of scientific principles.',
            'Science teachers conduct practical activities and experiments to enhance students hands-on experience and understanding of scientific concepts. They ensure that proper safety protocols are followed during practical work and provide guidance and support to students in conducting experiments.',
            'Science teachers assess students progress and understanding through a variety of methods, including written tests, practical assessments, and projects. They provide constructive feedback to help students improve their scientific skills and knowledge.',
            'Maintaining a positive and inclusive classroom environment is essential for effective teaching and learning. Science teachers manage student behaviour, establish classroom routines, and create a safe and respectful space for all students to participate and learn.',
            'Science teachers recognize the diverse learning needs of their students and adapt their teaching strategies to cater to different abilities and learning styles. They provide additional support to students who may require extra assistance or challenge for those who need a further extension.',
            'Science teachers often collaborate with colleagues within the science department and across other subjects. They participate in professional development activities, attend training sessions, and engage in continuous learning to enhance their teaching skills and keep up with developments in science education.',
            'Science teachers may organize and lead extracurricular activities, such as science clubs, field trips, or science fairs, to provide students with opportunities for further exploration and application of scientific concepts outside of the regular classroom setting.',
            'Science teachers communicate with parents or guardians to provide updates on students progress, discuss concerns, and address any questions. They may also participate in school events and engage with the local community to promote the importance of science education.'
        ],
        othercontaint: [
            'These responsibilities and tasks contribute to the overall goal of science teachers, which is to inspire and educate students in the field of science and equip them with the knowledge and skills necessary for further study and potential careers in scientific fields.',
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",
    },
    {
        title: 'Nursery Manager',
        price: '£32,000 to £40,000',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Nursery Manager? Nursery Managers are needed asap! As a nursery manager, your role is to oversee the day-to-day operations of a nursery or childcare centre. Your responsibilities may include managing staff, ensuring the well-being and development of children, maintaining a safe and stimulating environment, and communicating with parents or guardians. While specific duties may vary depending on the organisation.',
        responsibilities: [
            'Provide strong leadership and guidance to the nursery staff.',
            'Recruit, hire, train, and supervise nursery staff members.',
            'Conduct performance evaluations and provide feedback to staff.',
            'Develop and implement staff training and development programs.',
            'Foster a positive and collaborative work environment.',
            'Develop and implement an age-appropriate curriculum and learning activities.',
            'Monitor and assess childrens progress and development.',
            'Collaborate with staff to create individualized learning plans for children with special needs.',
            'Ensure compliance with legal and regulatory requirements, such as the Early Years Foundation Stage (EYFS) framework.',
            'Maintain a safe and healthy environment for children, staff, and visitors.',
            'Conduct risk assessments and implement appropriate safety measures.',
            'Develop and review policies and procedures related to safeguarding and child protection.',
            'Maintain accurate records and documentation, including health and safety records, incident reports, and child records.',
            'Build strong relationships with parents or guardians and promote open communication.',
            'Provide regular updates on childrens progress, development, and activities.',
            'Organize parent meetings, orientations, and workshops.',
            'Respond to parental inquiries, concerns, and feedback.',
            'Collaborate with external agencies, professionals, and community organizations.',
            'Develop and manage the nurserys budget, ensuring financial sustainability.',
            'Oversee enrolment, admissions, and fee collection processes.',
            'Maintain accurate records of attendance, registrations, and child records.',
            'Handle administrative tasks, including staff scheduling, payroll, and procurement.',
            'Participate in strategic planning and contribute to the overall growth and development of the nursery.',
            'Stay updated with current childcare practices, legislation, and industry trends.',
            'Attend relevant training, workshops, and conferences.',
            'Seek opportunities for professional development for yourself and your staff.',
            'Implement quality improvement initiatives and monitor the effectiveness of programs and services.',
            'Foster a culture of continuous learning and improvement within the nursery.'
        ],
        othercontaint: [
            'Its important to note that the specific responsibilities and tasks of a nursery manager can vary depending on the size and type of nursery, as well as local regulations and organizational requirements.',
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",
    },
    {
        title: 'Nursery Practitioners',
        price: ' £19,000 to £24,000',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Nursery Practitioner? Nursery Practitioners are needed asap! Nursery practitioners are professionals who work in early years settings, such as nurseries, preschools, and childcare centres, to provide care and education for young children. They play a crucial role in supporting childrens development and creating a safe and nurturing environment for them.',
        responsibilities: [
            'Nursery practitioners are responsible for providing high-quality care and supervision to children in their care. They ensure the safety and well-being of the children, offering support, comfort, and guidance as needed.',
            'Nursery practitioners plan and organize age-appropriate activities to promote the physical, social, emotional, and cognitive development of children. These activities may include arts and crafts, music and movement, storytelling, outdoor play, and sensory play.',
            'Nursery practitioners contribute to the implementation of the early years curriculum, such as the Early Years Foundation Stage (EYFS) framework. They plan and deliver activities that align with the curriculums goals and objectives, fostering childrens learning and development across various areas, including communication, language, literacy, mathematics, and personal, social, and emotional development.',
            'Nursery practitioners observe and assess childrens progress and development. They use various methods, such as observations, record keeping, and developmental assessments, to monitor childrens skills, interests, and needs. This information helps inform planning and supports individualized learning for each child.',
            'Nursery practitioners work closely with children who have additional needs, such as those with special educational needs or disabilities. They provide appropriate support, accommodations, and adaptations to ensure that all children can fully participate in activities and reach their potential.',
            'Nursery practitioners maintain regular communication with parents or guardians, sharing information about their childs progress, daily activities, and any concerns. They collaborate with parents to ensure consistency in care and support childrens learning and development both at home and in the nursery.',
            'Nursery practitioners adhere to health and safety policies and procedures to maintain a safe environment for children. They conduct risk assessments, ensure proper hygiene practices, administer basic first aid when necessary, and follow safeguarding protocols to protect children from harm.',
            'Nursery practitioners engage in ongoing professional development to enhance their knowledge and skills. They participate in training, workshops, and relevant courses to stay updated with best practices in early years education and childcare.'
        ],
        othercontaint: [
            'Nursery practitioners typically hold a relevant qualification in early childhood education or childcare. The most common qualification is the Level 3 Diploma in Childcare and Education, which is regulated by organizations like CACHE (Council for Awards in Care, Health and Education).',
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",
    },
    {
        title: 'Nursery nurse/Nursery assistant',
        price: ' £19,000 to £23,000',
        area: 'West London',
        jobdescription: 'Are you looking for a new career as a Nursery Practitioner? Nursery Practitioners are needed asap! Nursery practitioners are professionals who work in early years settings, such as nurseries, preschools, and childcare centres, to provide care and education for young children. They play a crucial role in supporting childrens development and creating a safe and nurturing environment for them.',
        responsibilities: [
            'Ensuring the safety, well-being, and development of children in the nursery. This includes supervising children during playtime, meals, and rest periods, as well as providing emotional support and encouragement.',
            'Designing and organizing engaging activities that promote learning and development in various areas, such as language and communication, motor skills, creativity, and social interaction.',
            'Assisting with personal hygiene tasks, including diaper changing, toilet training, and helping children with dressing and feeding, while promoting independence and self-care skills.',
            'Monitoring and observing childrens behaviour, progress, and development, and documenting their achievements and challenges. This helps to identify individual needs and provide appropriate support.',
            'Creating a stimulating and nurturing environment where children can explore, discover, and learn through play. Introducing educational concepts, such as numbers, letters, shapes, colours, and basic literacy and numeracy skills.',
            'Embracing and celebrating the diverse backgrounds and abilities of children and their families. Creating an inclusive environment where every child feels valued and respected.',
            'Ensuring a safe and clean environment by following health and safety guidelines, conducting risk assessments, and implementing appropriate safety measures. Administering first aid when necessary and responding to emergencies effectively.',
            'Engaging in training and professional development opportunities to enhance knowledge and skills. Staying up to date with relevant childcare practices, regulations, and educational frameworks.',
            'Embracing diversity and creating an inclusive environment where every child feels valued and respected. Adapting activities and strategies to meet the individual needs and abilities of children with diverse backgrounds and learning styles.',
            'Engaging in ongoing learning and professional development to enhance knowledge and skills in early childhood education. Staying updated with current practices, policies, and regulations in the field.'
        ],
        othercontaint: [
            'We do not offer visa sponsorship and only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy and Disclaimers which can be found atstaffingconnect.co.uk.'
        ],
        jobType: "permanent",
        sector: "Education",
    },
    {
        title: 'Night Shift Warehouse Operative',
        area: 'Harrow',
        price: '£12.67',
        jobdescription: 'Staffing Connect, a leading recruitment agency, is currently seeking Night Shift Warehouse Operatives for our client, a prominent provider of fresh prepared food in the UK. With a global presence and a focus on high-growth markets, our client operates over 30 sites and supplies a diverse portfolio of over 2,900 products to retailers in the UK and abroad.As a Night Shift Warehouse Operative, you will play a crucial role in the production team. Successful candidates have the opportunity to transition into permanent roles after completing a 12-week period.',
        responsibilities: [
            'Demonstrate drive, motivation, and ambition in a fast-paced environment.',
            'Learn and adhere to Food Safety and Good Manufacturing Practices.',
            'Comfortably wear Personal Protective Equipment (PPE) as required.',
            'Utilize food manufacturing experience to contribute to production processes.',
            'Exhibit a strong work ethic, attention to detail, and proficiency in English.',
            'Proven experience in food manufacturing.',
            'Eligibility to work in the UK (we do not offer visa sponsorship).',
            'Successful completion of the 12-week period may lead to permanent employment.'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Industrial",
    },
    {
        title: 'Forklift Driver',
        area: 'Heathrow',
        price: '£12.00',
        jobdescription: 'Join our client, an award-winning provider of handcrafted bread, pastries, and cakes. Renowned for supplying Londons top restaurants and prestigious hotels, our client is committed to using natural ingredients and traditional baking techniques. Be part of a team that takes pride in creating exceptional products and leads the way in shaping the future of baking in the UK.As a Forklift Driver, you will be a vital part of our clients logistics and warehouse team. Your role involves ensuring the efficient movement of goods within the warehouse and supporting various departments with accurate stock management.',
        responsibilities: [
            'Demonstrate a good command of written and spoken English.',
            'Uphold punctuality and attention to detail in all tasks.',
            'Utilize strong management skills in overseeing warehouse operations.',
            'Proficiency in MS Office for documentation and reporting purposes.',
            'Receive goods into the warehouse, trace them, and allocate to different departments.',
            'Implement effective stock control and rotation practices.',
            'Manage packaging, stock, and orders efficiently.',
            'Ensure traceability on all products entering the warehouse.',
            'Execute accurate daily transfers to each production department.',
            'Valid forklift driver certification and experience.',
            'Good knowledge of warehouse management practices.',
            'Eligibility to work in the UK (we do not offer visa sponsorship).'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Commercial",
    },
    {
        title: 'Picker/Packers and Warehouse Operatives',
        area: 'Acton',
        price: ' £11.00 to £11.50',
        jobdescription: 'Staffing Connect is currently hiring Picker/Packers and Warehouse Operatives for our client in Acton. Join our dynamic team and play a key role in ensuring the smooth flow of operations within a fast-paced warehouse environment. As a Picker/Packer, you will be responsible for checking deliveries, efficiently placing stock within the warehouse, and ensuring accurate packing of customer orders. This role requires a candidate with the ability to work autonomously and thrive in a collaborative team setting.',
        responsibilities: [
            'Working Hours: Monday to Friday, with start times between 5 am and 6 am.',
            'Utilize voice-activated picking machines and scanners for efficient order processing.',
            'Perform quality checks on products and select suitable replacements for sold-out items.',
            'Pack items with meticulous attention to detail, preparing them for delivery.',
            'Collaborate effectively with team members in a fast-paced environment.',
            'Demonstrate patience and composure in stressful situations.',
            'Exhibit initiative, flexibility, and openness to change.',
            'Maintain excellent command of the English language and communicate effectively.',
            'Previous experience in a warehouse or packing role is advantageous.',
            'Ability to work a 40-hour week with early morning start times.',
            'Proficiency in using voice-activated picking machines and scanners.',
            'Strong attention to detail and the ability to work well in a team.',
            'Excellent verbal communication skills. '
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'

        ],
        jobType: "temporary",
        sector: "Industrial",

    },
    {
        title: 'Post Room Staff & Mail Sorters',
        area: 'Slough',
        price: ' £12.75',
        jobdescription: 'Staffing Connect is currently recruiting Post Room Staff & Mail Sorters for a crucial role in maintaining the efficiency of post room services. Join our dynamic team in Slough and contribute to the smooth handling of incoming and outgoing mail, packages, and deliveries. As a Post Room Operative, you will play a vital role in ensuring the accurate and timely distribution of mail and packages within the university setting. This position requires a candidate with exceptional organizational skills, attention to detail, and a customer-centric approach.',
        responsibilities: [
            'Receive, sort, and distribute incoming mail, packages, and deliveries to the appropriate recipients or departments within the university.',
            'Process outgoing mail and packages, including packaging, labeling, and arranging courier services as necessary.',
            'Ensure accurate and timely recording of all incoming and outgoing items in the universitys tracking systems or databases.',
            'Operate and maintain mailroom equipment, such as postage meters, letter folding machines, and franking machines.',
            'Manage and maintain an organized inventory of office supplies and stationery, placing orders as needed to replenish stock.',
            'Assist in coordinating and scheduling collection and delivery services, ensuring efficient routing and adherence to deadlines.',
            'Handle mail-related queries, providing excellent customer service to students, faculty, and staff in person, via telephone, or email.',
            'Collaborate with other departments or external vendors to coordinate special mailings, such as bulk mailings or campus-wide distribution.',
            'Maintain the cleanliness and tidiness of the post room, ensuring a safe and organized working environment.',
            'Valid UK drivers license.',
            'Previous experience in a similar role is advantageous.',
            'Excellent organizational skills and attention to detail.',
            'Customer-centric approach and strong communication skills.',
            'Ability to operate mailroom equipment.',
            'Eligibility to work in the UK (we do not offer visa sponsorship).'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Commercial",

    },
    {
        title: 'Industrial Cleaners',
        area: 'Southall',
        price: '£11.50',
        jobdescription: 'Staffing Connect is currently recruiting Industrial Cleaners for a crucial role in maintaining the cleanliness of the maintenance department. Join our team in Southall and play a key part in ensuring a clean and safe working environment.As an Industrial Cleaner, you will be responsible for performing basic cleaning tasks on the shop floor and supporting the maintenance department in various projects. This role requires an individual with good physical fitness, attention to detail, and the ability to work independently.',
        responsibilities: [
            'Perform basic cleaning tasks on the shop floor, including sweeping, washing floors, and maintaining corridors and bays.',
            'Empty rubbish bins and ensure proper disposal of waste.',
            'Assist the maintenance department in project works as needed.',
            'Work independently to maintain the cleanliness of the assigned area to company standards.',
            'Ensure all equipment is clean and in good working order.',
            'Perform tasks that may involve lifting and require good physical fitness.',
            'Have a good understanding of general maintenance cleaning practices.',
            'Adhere to Health & Safety obligations and consider environmental impact.',
            'Possess basic computer skills.',
            'Maintain a good level of English, both verbal and written.',
            'Previous experience in industrial cleaning is advantageous.',
            'Good physical fitness for performing demanding tasks.',
            'Clear understanding of Health & Safety obligations and environmental impact.',
            'Basic computer skills.',
            'Proficient in English, both verbal and written.'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Industrial",

    },
    {
        title: 'Security Staff',
        area: 'Brentford',
        price: ' £14.00 to £15.00',
        jobdescription: 'Staffing Connect is currently seeking SIA Security Guards for a reputable company in Brentford. Join a team that values its members and work as a Door Supervisor at events in and around London this season. To be considered for this role, applicants MUST have an NVQ Level 2 in Spectator Safety or a Certificate in Level 2 Spectator Safety, and hold a current SIA Door Supervisor Licence.As a Security Staff member, you will play a crucial role in ensuring the safety and security of venues and events. This position involves controlling access to premises, managing incidents, liaising with emergency services, and protecting assets. We seek individuals who are punctual, assertive, and can remain calm under pressure.',
        responsibilities: [
            'Be the welcoming face of the venue, ensuring a positive and secure environment.',
            'Control access to the premises, verifying credentials and maintaining a vigilant presence.',
            'Manage incidents and conflicts, implementing appropriate resolutions when necessary.',
            'Liaise effectively with emergency services to ensure a coordinated response to any situation.',
            'Protect assets and property, enforcing security protocols and procedures.',
            'Work closely with clients to understand and meet their specific security needs.',
            'NVQ Level 2 in Spectator Safety or Certificate in Level 2 Spectator Safety.',
            'Valid SIA Door Supervisor Licence.',
            'Punctual, assertive, and confident in various security situations.',
            'Smart and professional appearance with a commitment to Look sharp, stay sharp.',
            'Ability to remain calm under pressure.'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Commercial",

    },
    {
        title: 'Forklift Truck  Drivers (All categories)',
        area: 'Enfield',
        price: '£12.50',
        jobdescription: 'Staffing Connect is currently seeking experienced Forklift Truck Drivers for a Full-Time position in Enfield. Join our team and play a vital role in loading and unloading HGV vehicles. The position offers a competitive salary of £12.50 per hour with opportunities for overtime.  As a Forklift Truck Driver, your primary responsibilities will include loading and unloading HGV lorries, utilizing your experience with counterbalance FLT, and assisting with general warehouse duties. This role requires a candidate with good communication skills, a clean and tidy work ethic, and a commitment to maintaining a well-organized work environment.',
        responsibilities: [
            'Load and unload HGV lorries efficiently and safely.',
            'Operate counterbalance FLT and contribute to site tidiness.',
            'Perform general warehouse duties as required.',
            'Maintain a high level of cleanliness and tidiness in the workplace.',
            'Demonstrate good English communication skills.',
            'Take pride in your work and contribute positively to the team environment.',
            'Valid Forklift Licence (all categories).',
            'Experience in loading and unloading HGV vehicles.',
            'Good level of English and communication skills.',
            'Clean and tidy work ethic.',
            'Respectful and courteous team player.',
            'Monday to Friday, 5 am start, 9-hour day.'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Commercial",
    },
    {
        title: 'Production Assembly Operatives',
        area: 'Kingston',
        price: ' £10.76',
        jobdescription: 'Staffing Connect is currently recruiting Production Assembly Operatives for our client, a leading worldwide brand in the furniture industry. With global locations and continuous growth, we are urgently seeking dedicated individuals to join the production team in Kingston. This is a temporary, full-time position offering a starting hourly rate of £10.76.As a Production Assembly Operative, you will play a vital role in the manufacturing process of high-quality furniture. Join a dynamic team and contribute to the efficient assembly of products based on specific measurements, calculations, drawings, or instructions.',
        responsibilities: [
            'Work on the assembly line to produce furniture based on specifications.',
            'Stand for long periods while performing assembly tasks.',
            'Pack boxes, load and unload materials and finished goods while adhering to safety protocols.',
            'Inspect assembled products for faults or defects.',
            'Report incidents, faulty operations, or product imperfections to management.',
            'Follow established safety and efficiency procedures to ensure smooth operations.',
            'Utilize previous experience in a similar role.',
            'Demonstrate the ability to work under pressure in a fast-paced environment.',
            'Previous experience in production or assembly roles.',
            'Ability to stand for extended periods and perform physically demanding tasks.',
            'Strong attention to detail in inspecting products for faults or defects.',
            'Ability to work under pressure in a fast-paced environment.',
            'Monday to Friday, 8 am start, 39 hours per week, with early finish on Fridays.'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Industrial",
    },
    {
        title: 'HGV Driver',
        area: 'Park Royal',
        price: ' £14.75',
        jobdescription: 'Staffing Connect is currently seeking experienced HGV Drivers for a thriving removal company in Park Royal. Join our expanding team and embark on a thrilling career in the removal industry. We are looking for skilled and dedicated HGV Drivers/Removal Porters with prior experience in the removals industry.As an HGV Driver with our removal company in Park Royal, you will play a crucial role in ensuring the safe and efficient transportation of goods and furniture for our clients. We are seeking individuals who thrive in a dynamic work environment, providing flawless removal services from project inception to completion.',
        responsibilities: [
            'Expertly handle and drive HGV vehicles, ensuring the safe and efficient transport of goods and furniture.',
            'Execute removal services meticulously, delivering a hassle-free experience for clients.',
            'Work collaboratively within a supportive team to manage every removal project with precision.',
            'Maintain a valid and clean drivers license for operating commercial vehicles.',
            'Apply prior experience in the removal industry, demonstrating a deep understanding of removal procedures and best practices.',
            'Possess a clean DBS (Disclosure and Barring Service) for high-end removals and customer-facing roles.',
            'Valid and clean drivers license for HGV vehicles.',
            'Prior experience in the removal industry.',
            'Clean DBS for high-end removals and customer-facing roles.'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Commercial",
    },
    {
        title: 'Refuse Workers',
        area: 'Wembley',
        price: '  £10.42',
        jobdescription: 'Staffing Connect is currently recruiting Refuse Loaders for our clients based in Wembley and Maidenhead. Join our dedicated team and play a crucial role in waste collection services. The position involves working from 6 am to 2 pm, Monday to Friday, with a competitive hourly rate of £10.42. As a Refuse Loader, you will work as part of the collections team, loading refuse and recycling waste from various locations, including domestic, business, and public areas. The role requires physical activity, including walking up to 10 miles per day, ensuring cleanliness in carriageways and pathways, and promptly addressing spillages.',
        responsibilities: [
            'Load refuse and recycling waste from domestic, business, and public areas.',
            'Undertake the removal of litter and waste as directed from various premises.',
            'Walk up to 10 miles per day to maintain cleanliness in designated areas.',
            'Experience in a similar busy manual role is desirable.',
            'Communicate effectively within a team and with the public.',
            'Work independently and collaboratively as part of a team.',
            'Demonstrate reliability and hardworking qualities.',
            'Previous experience in a busy manual role is desirable.',
            'Ability to walk up to 10 miles per day and perform physically demanding tasks.',
            'Effective communication skills within a team and with the public.',
            'Ability to work independently and collaboratively.',
            'Reliable and hardworking.',
            'Monday to Friday, 6 am to 2 pm.'
        ],
        othercontaint: [
            'Only applicants with a valid right to work in the UK will be considered.',
            'Staffing Connect is an equal opportunity employer.',
            'Staffing Connect acts as an employment agency for permanent recruitment and employment business for the supply of temporary workers. By applying for this job, you accept the T&Cs, Privacy Policy, and Disclaimers, which can be found at staffingconnect.co.uk.'
        ],
        jobType: "temporary",
        sector: "Industrial",
    }
]