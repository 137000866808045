import React from "react";
import DocumentCompliance from "../components/Element/SideBarForms/DocumentCompliance";
import Profilesidebar from "../components/Element/Profilesidebar";
import DocumentComplianceContainer from '../containers/DocumentComplianceContainer'
const DocumentCompliancePage = (props) => {
  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar page={"document-compliance"} />
                <div className="col-xl-9 col-lg-8 m-b30">
                <DocumentCompliance setDoc="SetDocumentCompliance" updateDoc="updateDocumentCompliance"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentComplianceContainer(DocumentCompliancePage);
