import React from "react";
import PropTypes from "prop-types";
import { Form, Col } from "react-bootstrap";
import Label from "../Layout/Label";
const Textarea = ({
  errors,
  label,
  value,
  name,
  changeHandler,
  placeholder,
  type,
  md,
  sm,
  lg,
  id,
  req,
}) => {
  return (
    <>
      <Form.Group as={Col} md={md ? md : 6} sm={sm ? sm : 12} lg={lg ? lg : 6}>
        {label && (
          <Label
            labelName={label}
            req={name == "cnd_dbs_certificate_description" ? false : req}
          />
        )}
        <Form.Control
          as="textarea"
          defaultValue={value}
          name={name}
          onChange={changeHandler}
          type={type}
          id={id ? id : name}
          required
          placeholder={placeholder ? placeholder : label}
          isInvalid={errors && errors[name] ? true : false}
          className={
            errors && errors[name]
              ? "form-control invalid"
              : "form-control valid"
          }
        />
        {/* <label>{value ? value.length : 0 }/ 500 characters</label> */}
        {name &&
          (window.location.pathname == "/create-identity-document" ||
            window.location.pathname.includes("/create-identity-document/") ||
            window.location.pathname == "/create-right-to-work" ||
            window.location.pathname.includes("/create-right-to-work/")) && (
            <p
              id={name + "_msg"}
              style={{ fontSize: "80%" }}
              className="text-danger text-sm"
            ></p>
          )}
        {window.location.pathname == "/driver-details" && id && (
          <div className={"text-danger mb-0"} id={id + "_msg"}></div>
        )}
        <Form.Control.Feedback type="invalid">
          {errors && errors[name]}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

Textarea.Textarea = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Textarea;
