import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { redirectUrl } from "../../../services/GlobalUrls";
const DailyLogsList = (props) => {
  const [dailylogs, SetDailyLogs] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.dailylogs.length;
    // console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetDailyLogs(props.dailylogs);
  }, [props.dailylogs]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName) => {
    if (checkIfFileIsaDocument(url)) {
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };

  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        {/* Search Start */}
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  id="myInput"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <table className="table table-striped table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              {/*<th  className="col-1 text-center" scope="col">Sr. No</th>*/}
              <th className="col-3 text-center" scope="col">
                Date & Time
              </th>
              {/*<th  className="col-2 text-center" scope="col">Email</th>*/}
              <th className="col-3 text-center" scope="col">
                Service User Name
              </th>
              <th className="col-3 text-center" scope="col">
                Date of Visit
              </th>
              <th className="col-3 text-center" scope="col">
                Uploaded Document
              </th>
              {/*<th  className="col-1 text-center" scope="col">Action</th>*/}
            </tr>
          </thead>
          <tbody id="myTable">
            {dailylogs &&
              dailylogs.length > 0 &&
              dailylogs?.slice(startIndex, endIndex).map((daily_log, i) => (
                <tr key={i}>
                  {/*<td className="col-1 text-center" scope="row">
                    {i + 1}
              </td>*/}
                  <td className="col-4 text-center" scope="row">
                    {daily_log.cnd_daily_logs_created_date}
                  </td>
                  <td className="col-3 text-center" scope="row">
                    {daily_log.cnd_daily_logs_firstname +
                      " " +
                      daily_log.cnd_daily_logs_lastname}
                  </td>
                  {/*<td  className="col-2" scope="col">{daily_log.users_email}</td>*/}

                  <td className="col-3 text-center" scope="row">
                    {daily_log.cnd_daily_logs_date}
                  </td>

                  <td className="col-3 text-center" scope="col">
                    {daily_log.cnd_daily_logs_upload_file ? (
                      <a
                        rel="noopener noreferrer"
                        download
                        onClick={() =>
                          viewFileModal(
                            constants.IMAGE_URL +
                              daily_log.cnd_daily_logs_upload_file_folder_path +
                              daily_log.cnd_daily_logs_upload_file
                          )
                        }
                        className={
                          "btn btn-" +
                          (daily_log.cnd_daily_logs_upload_file.endsWith(
                            ".png"
                          ) ||
                          daily_log.cnd_daily_logs_upload_file.endsWith(
                            ".jpg"
                          ) ||
                          daily_log.cnd_daily_logs_upload_file.endsWith(
                            ".jpeg"
                          ) ||
                          daily_log.cnd_daily_logs_upload_file.endsWith(".pdf")
                            ? "dark"
                            : "primary") +
                          " btn-sm text-white"
                        }
                      >
                        <i
                          className={
                            "fa fa-" +
                            (daily_log.cnd_daily_logs_upload_file.endsWith(
                              ".png"
                            ) ||
                            daily_log.cnd_daily_logs_upload_file.endsWith(
                              ".jpg"
                            ) ||
                            daily_log.cnd_daily_logs_upload_file.endsWith(
                              ".jpeg"
                            ) ||
                            daily_log.cnd_daily_logs_upload_file.endsWith(
                              ".pdf"
                            )
                              ? "eye" //"eye"
                              : "download")
                          }
                        ></i>
                      </a>
                    ) : (
                      " - "
                    )}
                  </td>
                  {/*<td  className="col-1 text-center" scope="col"> <Link to={"/create-timesheet/"+daily_log.cnd_timesheet_id}><i className="fa fa-pencil"></i></Link></td>*/}
                </tr>
              ))}
            {dailylogs.length == 0 && (
              <tr>
                <td colSpan="100">No dailylogs Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
        {dailylogs && dailylogs.length > 0 && (
          <TableDropdownAndPagination
            handleDropDown={handleDropDown}
            recordLen={recordLen}
            handleLeft={handleLeft}
            handleRight={handleRight}
            renderPageNumbers={renderPageNumbers}
            isDropdown={true}
            isPaggination={true}
          />
        )}
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        // fileName={fileName}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        title={"Daily Logs - Uploaded Documents"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dailylogs: state.dailylogs.dailylogs,
  };
};

export default connect(mapStateToProps)(DailyLogsList);
