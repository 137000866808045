export const capitalizeWords = (sentence) =>{
    return sentence.split(' ').map(word => {
        if (word.length > 0) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          } else {
            return word;
        }
    }).join(' ');
  
}

export const getInitialsLettersOfString = (name) => {
    // Split the name into words
    const words = name.split(" ");
    let finalWord;
    // Get the first letter of each word and concatenate them
    finalWord = words.map((word) => word[0]).join("");

    return finalWord ? finalWord.toUpperCase() : "";
  };