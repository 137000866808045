import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//import {logout} from '../../store/actions/AuthActions';
import { isAuthenticated } from "../../redux/selectors/AuthSelectors";

function Signup(props) {
  const dispatch = useDispatch();
  const { onClick } = props;

  return (
    <>
      <a
        style={{ display: props.isAuthenticated ? "none" : "inline-block" }}
        // onClick={()=>onClick("/register")}
        href="/register"
        // to="#"
        title="Signup"
        className="site-button"
      >
        <i className="fa fa-user" /> Signup
      </a>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Signup));
