const initialState = {holiday: {}}

export const holiday = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_HOLIDAY':
      return {holiday: action.payload}; 
  }
  return state
}


