import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import constants from "../../../constants";
import { format } from "date-fns";
import { jobsArray } from "../PermanentRequirements/JobArray";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import { convertToOriginalForm } from "../../../services/StringOperation";

const RejectedJobsList = (props) => {
  const [appliedjobs, SetRejectedJobs] = useState([]);
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [filter, setFilter] = useState("");
  let checkData = false;
  let countIndex = 0;
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.appliedjobs.length;
    console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetRejectedJobs(props.appliedjobs);
  }, [props.appliedjobs]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  useEffect(() => {
    SetRejectedJobs(props.appliedjobs);
  }, [props.appliedjobs]);

  return (
    <React.Fragment>
      <div className="m-b30 wrapper">
        <table className="table table-bordered table-hover table-sm search-table">
          <thead className="thead-dark">
            <tr>
              {/*<th  className="col-1 text-center" scope="col">Sr. No</th>*/}
              <th className="col-2 text-center" scope="col">
                Date
              </th>
              <th className="col-2 text-center" scope="col">
                Name
              </th>
              <th className="col-2 text-center" scope="col">
                Job Title
              </th>
              <th className="col-2 text-center" scope="col">
                Status
              </th>
            </tr>
          </thead>
          <tbody id="myTable">
            {appliedjobs &&
              appliedjobs.length > 0 &&
              appliedjobs?.slice(startIndex, endIndex).map((appliedjob, i) => {
                if (appliedjob.cnd_permanent_job_status == "Rejected") {
                  countIndex++;
                  if (countIndex == 1) {
                    checkData = true;
                  }
                  return (
                    <tr key={i}>
                      {/*<td className="col-1 text-center" scope="row">
                    {i + 1}
              </td>*/}
                      <td className="col-2 text-center" scope="row">
                        {format(
                          new Date(appliedjob.cnd_permanent_job_created_date),
                          "dd-MM-yyyy"
                        )}
                      </td>
                      <td className="col-2 text-center" scope="row">
                        {appliedjob.users_firstname +
                          " " +
                          appliedjob.users_lastname}
                      </td>
                      <td className="col-2 text-center" scope="row">
                        {/* {jobsArray.map((arrVal, i) => {
                          if (
                            arrVal.value == appliedjob.cnd_permanent_job_title
                          ) {
                            return arrVal.name;
                          }
                        })} */}
                        {convertToOriginalForm(appliedjob.cnd_permanent_job_title)}
                        {/* {jobsArray[appliedjob.cnd_permanent_job_title].name} */}
                      </td>
                      <td className="col-2 text-center" scope="row">
                        {appliedjob.cnd_permanent_job_status}
                      </td>
                    </tr>
                  );
                }
              })}
            {appliedjobs.length == 0 && (
              <tr>
                <td colSpan="100">No Applied Jobs Founds.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {checkData == true && (
        <TableDropdownAndPagination
          handleDropDown={handleDropDown}
          recordLen={recordLen}
          handleLeft={handleLeft}
          handleRight={handleRight}
          renderPageNumbers={renderPageNumbers}
          isDropdown={true}
          isPaggination={true}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appliedjobs: state.appliedjobs.appliedjobs,
  };
};

export default connect(mapStateToProps)(RejectedJobsList);
