import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Dropdown from "../../FormElements/Dropdown";
import Input from "../../FormElements/Input";
import Error from "../../Layout/Error";
const PersonalInfoFields = (props) => {
  const [userdata, setFormdata] = useState({});
  const { errors } = props;
  useEffect(() => {
    setFormdata(props.data);
  }, [props.data]);

  let users_middlename_id = document.getElementById("users_middlename");

  return (
    <React.Fragment>
      <Input
        type="text"
        label="First Name"
        placeholder={"Eg., John"}
        changeHandler={(e) => props.onChange(e)}
        disabled={userdata.users_firstname ? true : false}
        name="users_firstname"
        value={userdata.users_firstname}
        errors={errors}
        lg={4}
      />
      <Input
        type="text"
        label="Middle Name"
        placeholder={"Eg., Doe"}
        changeHandler={(e) => props.onChange(e)}
        name="users_middlename"
        id={"users_middlename"}
        value={userdata.users_middlename}
        errors={errors}
        req={false}
        lg={4}
      />
      <Input
        type="text"
        label="Last Name"
        placeholder={"Eg., Doe"}
        changeHandler={(e) => props.onChange(e)}
        name="users_lastname"
        disabled={userdata.users_lastname ? true : false}
        value={userdata.users_lastname}
        errors={errors}
        lg={4}
      />
      <Input
        type="email"
        label="Email"
        placeholder={"Eg. johndoe@example.com"}
        disabled={"disabled"}
        changeHandler={(e) => props.onChange(e)}
        name="users_email"
        value={userdata.users_email}
        errors={errors}
        lg={4}
      />
      <Dropdown
        name="cnd_gender"
        value={userdata.cnd_gender}
        // disabled={userdata.cnd_gender ? true : false}
        id={"cnd_gender"}
        label="Select Gender"
        options={[
          {
            name: "Select Gender",
            value: "",
          },
          {
            name: "Male",
            value: "male",
          },
          {
            name: "Female",
            value: "female",
          },
          {
            name: "Other",
            value: "other",
          },
        ]}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
        lg={4}
      />
      <Input
        type="date"
        label="Date of Birth"
        placeholder={"Eg., 01/01/1990"}
        id={"cnd_birth_date"}
        name="cnd_birth_date"
        max={
          new Date(new Date().setFullYear(new Date().getFullYear() - 15))
            .toISOString()
            .split("T")[0]
        }
        min={
          new Date(new Date().setFullYear(new Date().getFullYear() - 73))
            .toISOString()
            .split("T")[0]
        }
        defaultValue={userdata.cnd_birth_date}
        value={userdata.cnd_birth_date}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
        lg={4}
      />
      {/* <p id="cnd_birth_date_error" className="text-danger" style={{display:"none"}}>Please select the birth date</p> */}
      <Input
        type="text"
        label="Phone Number"
        disabled={userdata.users_phonenumber ? true : false}
        placeholder={"Eg., 01234567890"}
        changeHandler={(e) => props.onChange(e)}
        name="users_phonenumber"
        value={userdata.users_phonenumber}
        errors={errors}
        lg={6}
      />

      <Input
        type="text"
        label="Training & Qualifications"
        placeholder={"Eg., Doe"}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_traing_and_qualifications"
        value={userdata.cnd_traing_and_qualifications}
        id={"cnd_traing_and_qualifications"}
        errors={errors}
        lg={6}
      />
    </React.Fragment>
  );
};

export default PersonalInfoFields;
