import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Jobfindbox from "../components/Element/Jobfindbox";
import { allJobs } from "./Data";

import { checkUserStatus } from './checkUserStatus';
import Backgroundimage from './Backgroundimage';
export default function TempJobs() {
  const temporaryJobs = allJobs.filter((j) => j.jobType === "temporary");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);
  const [temporaryRecords] = useState(temporaryJobs);
  const [maxHeight, setMaxHeight] = useState("auto");

  const totalPages = Math.ceil(temporaryRecords.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = Math.min(
    startIndex + recordsPerPage,
    temporaryRecords.length
  );

  useEffect(() => {
    const listItems = document.querySelectorAll(".post-bx");
    let max = 0;

    listItems.forEach((item) => {
      const height = item.getBoundingClientRect().height;
      if (height > max) {
        max = height;
      }
    });

    setMaxHeight(max + "px");
  }, [temporaryRecords]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const applyNow = () => {
		const isLogin = checkUserStatus()
		if(isLogin) {
			window.location = "/dashboard"
		} else {
			window.location = "/login"
		}
	}
  let userType = localStorage.getItem("user_job_type");
  const isLogin = checkUserStatus();
    //const userType = "permanent requirement"; 

    const linkTo = isLogin
        ? userType === "permanentrequirement"
            ? "/applied-jobs"
            : "/dashboard"
        : "/login";

  return (
    <>
      <div className="text-left">
        <div className="page-content1 bg-white">
        <Backgroundimage backgroundImage={require('../images/new-updated-images/job-board/permanent-jobs/job-board-permanent-job.jpg')} pageTitle="Temporary Job Board" />
         </div>
         <Jobfindbox />
         <div className="section-full bg-white browse-job p-b50">
            <div className="container">
              {/* <div className="job-bx-title clearfix">
                <h5 className="font-weight-700 pull-left text-uppercase">Temporary Job Board</h5>
              </div> */}
              <ul className="post-job-bx browse-job-grid row">
                {temporaryRecords
                    .slice(startIndex, endIndex)
                    .map((item, index) => (
                      <li
                      className="col-lg-4 col-md-6"
                      key={index}
                      style={{
                        height: maxHeight,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="post-bx"
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          position: "relative",
                        }}
                      >
                        <div className="d-flex m-b30">
                          <div className="job-post-info">
                            <h6 className="font-size-info">
                              <Link
                                to={{
                                  pathname: "/jobDetails",
                                  state: { jobDetails: item },
                                }}
                              >
                                {item.title}
                              </Link>
                            </h6>
                            <ul>
                              <li>
                                <i className="fa fa-map-marker"></i>West
                                London
                              </li>
                              <li>
                                <i className="fa fa-bookmark-o"></i>{" "}
                                {item.jobType === "permanent"
                                  ? "Permanent"
                                  : "Temporary"}
                                , Full Time
                              </li>
                              <li>
                                <i className="fa fa-clock-o"></i>
                                {item.price}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          className="flex-row-reverse mb-2"
                          style={{ alignSelf: "flex-end" }}
                        >
                          <div className="job-time-info">
                          <Link className="site-button green  float-right mt-2 mb-0" to={linkTo}>Apply Now</Link>
                          </div>
                        </div>
                      </div>
                    </li>
                ))}
              </ul>
              <div className="pagination-bx m-t30 ">
                    <ul className="pagination d-flex justify-content-end">
                      <li className="previous">
                        <Link to="#" onClick={handleLeft}>
                          <i className="ti-arrow-left"></i> Prev
                        </Link>
                      </li>
                      {[...Array(totalPages)].map((_, i) => (
                        <li
                          key={i}
                          className={currentPage === i + 1 ? "active" : ""}
                        >
                          <Link to="#" onClick={() => setCurrentPage(i + 1)}>
                            {i + 1}
                          </Link>
                        </li>
                      ))}
                      <li className="next">
                        <Link to="#" onClick={handleRight}>
                          Next <i className="ti-arrow-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
            </div>
          </div>

        </div>
    
    </>
  );
}
