import * as Yup from "yup";
export const formInputFieldsSign = () => [
  {
    name: "user_sign_signature",
    type: "sign",
    label: "Sign Terms & Conditions",
    md: "8",
    sm: "12",
  },
  {
    name: "user_term_condition",
    type: "checkbox",
    id: "user_term_condition",
    options: [{ id: "1", name: "Accept Terms & Conditions" }],
    md: "12",
    sm: "12",
  },
];

export const validationSchemaSign = Yup.object().shape({
  user_term_condition: Yup.bool().oneOf(
    [true],
    "Please Accept Term & Condition"
  ),
  user_sign_signature: Yup.string()
    .required("Signature Required")
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature must be png"
    ),
});

// confirmPassword: Yup.string()
//     .required("Confirm Password is required")
//     .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
//     email: Yup.string().required("Email is required").email("Email is invalid"),
//acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
