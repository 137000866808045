export const jobTypeList = [
  { name: "Select Job Type", id: "" },
  { name: "Permanent Positions", id: "permanentrequirement" },
  { name: "Temporary Positions", id: "temporaryrequirement" },
  {
    name: "Permanent Positions & Temporary Positions",
    id: "temproryandpermanent",
  },
];

export const jobContractType = {
  permanentrequirement: "Permanent Positions",
  temporaryrequirement: "Temporary Positions",
  temproryandpermanent: "Permanent Positions & Temporary Positions",
};

export const jobsEmploymentHours = {
  full_time: "Full Time",
  part_time: "Part Time",
  full_time_or_part_time: "Full Time or Part Time",
};

export const jobsEmploymentHoursDropList = [
  {
    name: "Full Time",
    value: "full_time",
  },
  {
    name: "Part Time",
    value: "part_time",
  },
  {
    name: "Full Time or Part Time",
    value: "full_time_or_part_time",
  },
];

export const jobsSalarytype = {
  per_hour: "Per Hour",
  per_day: "Per Day",
  per_annum: "Per Annum",
};

export const jobsSalaryTypeDropList = [
  {
    name: "Select Salary Type",
    value: "",
  },
  {
    name: "Per Hour",
    value: "per_hour",
  },
  {
    name: "Per Day",
    value: "per_day",
  },
  {
    name: "Per Annum",
    value: "per_annum",
  },
];

export const jobSectorList = [
  {
    name: "Home Care",
    id: "home care",
  },
  {
    name: "Social Care",
    id: "social care",
  },
  {
    name: "Education",
    id: "education",
  },
  {
    name: "Industrial",
    id: "industrial",
  },
  {
    name: "Commercial",
    id: "commercial",
  },
];

export const jobEmploymentStatusDropList = [
  {
    name: "Select Employment Status",
    value: "",
  },
  {
    name: "Employed (full-time)",
    value: "employed_(full_time)",
  },
  {
    name: "Employed (part-time)",
    value: "employed_(part_time)",
  },
  {
    name: "Employed (temp / contract)",
    value: "employed_(temp_or_contract)",
  },
  {
    name: "Full-time education",
    value: "full_time_education",
  },
  {
    name: "Unemployed",
    value: "unemployed",
  },
];

export const jobNoticePeriodDropList = [
  {
    name: "Select Notice Period",
    value: "",
  },
  {
    name: "Not Specified",
    value: "not_specified",
  },
  {
    name: "1 Week",
    value: "one_week",
  },
  {
    name: "2 Weeks",
    value: "two_weeks",
  },
  {
    name: "3 Weeks",
    value: "three_weeks",
  },
  {
    name: "1 Month",
    value: "one_month",
  },
  {
    name: "2 Months",
    value: "two_months",
  },
  {
    name: "3 Months",
    value: "three_months",
  },
  {
    name: "6 Months",
    value: "six_months",
  },
];
