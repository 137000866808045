import React, { useState, useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';
import { compose } from 'redux';
import {getDataOnRequest, emptyAction} from '../redux/actions/CommonActions'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const DailyLogContainer = (OriginalComponent) => {
  const NewComponent = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    useEffect(()=>{
      toast.dismiss();
      if(props.userAuth.users_access_token){
        dispatch(emptyAction("SET_DAILY_LOG"));
        dispatch(getDataOnRequest(props.userAuth.users_access_token,'GetDailyLog','SET_DAILY_LOG',params.id))
      }
    },[props.userAuth.users_access_token, params.id])

    return (
      <OriginalComponent {...props}  />
    )
  }
  return NewComponent
}

const mapStateToProps = (state) => {
  return {
    userAuth:state.auth.authUserData,
    userInfo:state.userProfile.userData,
    dailylog:state.dailylog.dailylog
  };
};

const composedDailyLogContainer = compose(connect(mapStateToProps), DailyLogContainer)
export default composedDailyLogContainer;

