const initialState = {timesheet: {}}

export const timesheet = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_TIMESHEET':
      return {timesheet: action.payload}; 
  }
  return state
}


