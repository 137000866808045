import React from 'react';
import Profilesidebar from '../components/Element/ClientSidebar';
import StepWizard from 'react-step-wizard';
import Nav from '../components/Element/CarePlan/Nav';
import CarePlanForm from '../components/Element/CarePlan';
import NextOfKin from '../components/Element/CarePlan/NextOfKin';
import EmergencyContact from '../components/Element/CarePlan/EmergencyContact';
import CareManager from '../components/Element/CarePlan/CareManager';
import GeneralPractitioner from '../components/Element/CarePlan/GeneralPractitioner';
import CareCordinator from '../components/Element/CarePlan/CareCordinator';
import SocialService from '../components/Element/CarePlan/SocialService';
import Lpa from '../components/Element/CarePlan/Lpa';
import PhysicalInfo from '../components/Element/CarePlan/PhysicalInfo';
import Nurse from '../components/Element/CarePlan/Nurse';
import OtherManager from '../components/Element/CarePlan/OtherManager';


const CarePlan = (props) => {
	return (
		<React.Fragment>
			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full bg-white browse-job p-t50 p-b20">
						<div className="container">
							<div className="row">
								<Profilesidebar page="care-plan" />
								<div className="col-xl-9 col-lg-8 m-b30">
									<StepWizard nav={<Nav />} isLazyMount={true}>
										{/* 1th number form */}
										<CarePlanForm />
										{/* 2 number form */}
										<EmergencyContact />
										{/* 3 number form */}
										<NextOfKin />
										{/* 4 number form */}
										<PhysicalInfo/>
										{/* 5th number form */}
										<CareManager />  
										{/* 6 number form */}
										<GeneralPractitioner />
										{/* 7 number form */}
										<SocialService/>
										{/* 8 number form */}
										<CareCordinator />
										{/* 9th Fomr */}
										<Lpa/>
										{/* 10th Fomr */}
										<OtherManager/>
										{/* 11th Fomr */}
										<Nurse/>
										{/* 12th Fomr */}
									</StepWizard>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}


export default CarePlan;