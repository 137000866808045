import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { documentComplianceList } from "../../../helpers/DocumentsList";
import constants from "../../../constants";
import TableDropdownAndPagination from "../../../services/TableDropdownAndPagination";
import GlobalViewFileModal from "../../../services/GlobalViewFileModal";
import { checkIfFileIsaDocument } from "../../../services/FileOperationGlobalFunctions";
import { redirectUrl } from "../../../services/GlobalUrls";

const DocumentRequiredList = (props) => {
  const [documentCompliance, SetDocumentCompliance] = useState([]);
  const { progress, userData } = props;

  const [filter, setFilter] = useState("");
  const [recordLen, setRecordLen] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [dataLen, setDataLen] = useState(0);
  const [modalOption, setModalOption] = useState(false);
  const [modalHeight, setModalHeight] = useState("500px");
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  let totalPages = Math.ceil(dataLen / recordsPerPage);
  let startIndex = (currentPage - 1) * recordsPerPage;
  let endIndex = Math.min(startIndex + recordsPerPage, dataLen);

  useEffect(() => {
    const lenOfData = props.documentCompliance.length;
    console.log("lenOfData", lenOfData);
    setDataLen(lenOfData);
    totalPages = Math.ceil(lenOfData / recordsPerPage);
    startIndex = (currentPage - 1) * recordsPerPage;
    endIndex = Math.min(startIndex + recordsPerPage, lenOfData);
    SetDocumentCompliance(props.documentCompliance);
  }, [props.documentCompliance]);

  const handleLeft = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRight = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const visiblePages = 4;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = currentPage + Math.floor(visiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => {
            setCurrentPage(i);
            setFilter("");
          }}
        >
          <Link to="#">{i}</Link>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleDropDown = (value) => {
    setRecordLen(value);
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  const viewFileModal = (url, fileName, subFileName) => {
    if (checkIfFileIsaDocument(url)) {
      // document.getElementById("loader").style.display = "block";
      // setModalOption(true);
      setModalHeight("500px");
      setFileUrl(url);
      setFileName(fileName + " " + subFileName);
    } else {
      redirectUrl(url);
    }
  };

  const callModal = () => {
    document.getElementById("loader").style.display = "block";
    setModalOption(true);
  };
  const loaderEnd = () => {
    document.getElementById("loader").style.display = "none";
    setModalHeight("auto");
  };

  const closeFileModal = () => {
    setModalOption(false);
    setFileUrl("");
  };

  useEffect(() => {
    SetDocumentCompliance(props.documentCompliance);
  }, [props.documentCompliance]);

  const dateDiffrence = (date) => {
    const date1 = new Date();
    const date2 = new Date(splitDateToFormat(date));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const splitDateToFormat = (date) => {
    let splitDate = date.split("-");
    let formattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
    return formattedDate;
  };

  return (
    <React.Fragment>
      <div className="row m-b30">
        {/* Search Start */}
        <div className="container">
          <div className="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <label class="form-control-label text-right" for="search">
                Search
              </label>
              <div class="form-group form-group-default">
                <input
                  type="text"
                  name="search"
                  class="form-control "
                  placeholder="Enter key to search"
                  id="myInput"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Search End */}
        <div className="col-lg-12 col-md-12 m-b0 wrapper">
          <table className="table table-striped table-hover table-sm table-responsive">
            <thead className="thead-dark">
              <tr>
                {/*<th className="col-1 text-center" scope="col">
                  Sr. No
                </th>*/}
                <th className="col-1 text-center" scope="col">
                  Date & Time
                </th>
                <th className="col-3 text-center" scope="col">
                  Document Name
                </th>
                <th className="col-2 text-center" scope="col">
                  Document(s) Uploaded
                </th>
                {/* <th className="col-3" scope="col">Description</th> */}
                {/* <th className="col-2 text-center" scope="col">Reference</th> */}
                <th className="col-3 text-center" scope="col">
                  Expiry Date
                </th>
                {/*<th className="col-3 text-center" scope="col">
                  Latest Document Date
                </th>*/}
                <th className="col-2 text-center" scope="col">
                  Status
                </th>
                {/*<th className="col-2 text-center" scope="col">
                  Action
              </th>*/}
              </tr>
            </thead>
            <tbody id="myTable">
              {documentCompliance &&
                documentCompliance
                  .slice(startIndex, endIndex)
                  .map((compliance, i) => {
                    
                    if(compliance.cnd_compliance_exp_date_convert && compliance.cnd_compliance_exp_date){
                      return(
                        <tr
                      className={
                        compliance.cnd_compliance_exp_date_convert ==
                        "00-00-0000"
                          ? ""
                          : new Date() <=
                              new Date(
                                splitDateToFormat(
                                  compliance.cnd_compliance_exp_date_convert
                                )
                              ) &&
                            dateDiffrence(
                              compliance.cnd_compliance_exp_date_convert
                            ) > 30
                          ? " "
                          : new Date() <=
                              new Date(
                                splitDateToFormat(
                                  compliance.cnd_compliance_exp_date_convert
                                )
                              ) &&
                            dateDiffrence(
                              compliance.cnd_compliance_exp_date_convert
                            ) < 30
                          ? "table-warning"
                          : "table-danger"
                      }
                      key={i}
                    >
                      {/*<th className="col-1 text-center" scope="row">
                    {i + 1}
                  </th>*/}
                      <td className="col-4 text-center">
                        {compliance.cnd_compliance_created_date}
                      </td>
                      <td className="col-4 text-center">
                        {
                          documentComplianceList[
                            compliance.cnd_compliance_doc_master_id
                          ]
                        }
                        {compliance.cnd_compliance_doc_master_id == "additional"
                          ? "(" + compliance.cnd_doc_name + ")"
                          : ""}
                      </td>
                      <td className="col-1 text-center">
                        <a
                          onClick={() =>
                            viewFileModal(
                              constants.IMAGE_URL +
                                compliance.cnd_compliance_folder_path +
                                compliance.cnd_compliance_upload_file,
                              documentComplianceList[
                                compliance.cnd_compliance_doc_master_id
                              ],
                              compliance.cnd_compliance_doc_master_id ==
                                "additional"
                                ? "(" + compliance.cnd_doc_name + ")"
                                : ""
                            )
                          }
                          className={
                            "btn btn-" +
                            (compliance.cnd_compliance_upload_file.endsWith(
                              ".png"
                            ) ||
                            compliance.cnd_compliance_upload_file.endsWith(
                              ".jpg"
                            ) ||
                            compliance.cnd_compliance_upload_file.endsWith(
                              ".jpeg"
                            ) ||
                            compliance.cnd_compliance_upload_file.endsWith(
                              ".pdf"
                            )
                              ? "dark"
                              : "primary") +
                            " btn-sm text-white"
                          }
                        >
                          <i
                            className={
                              "fa fa-" +
                              (compliance.cnd_compliance_upload_file.endsWith(
                                ".png"
                              ) ||
                              compliance.cnd_compliance_upload_file.endsWith(
                                ".jpg"
                              ) ||
                              compliance.cnd_compliance_upload_file.endsWith(
                                ".jpeg"
                              ) ||
                              compliance.cnd_compliance_upload_file.endsWith(
                                ".pdf"
                              )
                                ? "eye" //"eye"
                                : "download")
                            }
                          ></i>
                        </a>
                      </td>
                      {/* <td className="col-3">{compliance.cnd_compliance_description}</td> */}
                      {/* <td className="col-2 text-center" >
                    {compliance.cnd_compliance_reference}
                  </td> */}
                      <td className="col-3 text-center">
                        {compliance.cnd_compliance_exp_date_convert ==
                        "00-00-0000"
                          ? ""
                          : compliance.cnd_compliance_exp_date_convert}
                      </td>
                      {/*<td className="col-2 text-center">
                    {compliance?.cnd_compliance_created_date}
                </td>*/}
                      <td className="col-2 text-center">
                        {compliance.cnd_compliance_exp_date_convert ==
                        "00-00-0000"
                          ? "Valid"
                          : new Date() <=
                              new Date(
                                splitDateToFormat(
                                  compliance.cnd_compliance_exp_date_convert
                                )
                              ) &&
                            dateDiffrence(
                              compliance.cnd_compliance_exp_date_convert
                            ) > 30
                          ? " "
                          : new Date() <=
                              new Date(
                                splitDateToFormat(
                                  compliance.cnd_compliance_exp_date_convert
                                )
                              ) &&
                            dateDiffrence(
                              compliance.cnd_compliance_exp_date_convert
                            ) < 30
                          ? "About to Expire"
                          : "Expire"}
                      </td>
                      {/*<td className="col-2 text-center" scope="col"> <Link to={"/create-document-national-insurance/"+compliance.cnd_compliance_id}><i className="fa fa-pencil"></i></Link></td>*/}
                    </tr>
                      );
                    }else{
                      return(
                        <tr key={i}>
                      {/*<th className="col-1 text-center" scope="row">
                    {i + 1}
                  </th>*/}
                      <td className="col-4 text-center">
                        {compliance.cnd_compliance_created_date}
                      </td>
                      <td className="col-4 text-center">
                        {
                          documentComplianceList[
                            compliance.cnd_compliance_doc_master_id
                          ]
                        }
                        {compliance.cnd_compliance_doc_master_id == "additional"
                          ? "(" + compliance.cnd_doc_name + ")"
                          : ""}
                      </td>
                      <td className="col-1 text-center">
                        <a
                          onClick={() =>
                            viewFileModal(
                              constants.IMAGE_URL +
                                compliance.cnd_compliance_folder_path +
                                compliance.cnd_compliance_upload_file,
                              documentComplianceList[
                                compliance.cnd_compliance_doc_master_id
                              ],
                              compliance.cnd_compliance_doc_master_id ==
                                "additional"
                                ? "(" + compliance.cnd_doc_name + ")"
                                : ""
                            )
                          }
                          className={
                            "btn btn-" +
                            (compliance.cnd_compliance_upload_file.endsWith(
                              ".png"
                            ) ||
                            compliance.cnd_compliance_upload_file.endsWith(
                              ".jpg"
                            ) ||
                            compliance.cnd_compliance_upload_file.endsWith(
                              ".jpeg"
                            ) ||
                            compliance.cnd_compliance_upload_file.endsWith(
                              ".pdf"
                            )
                              ? "dark"
                              : "primary") +
                            " btn-sm text-white"
                          }
                        >
                          <i
                            className={
                              "fa fa-" +
                              (compliance.cnd_compliance_upload_file.endsWith(
                                ".png"
                              ) ||
                              compliance.cnd_compliance_upload_file.endsWith(
                                ".jpg"
                              ) ||
                              compliance.cnd_compliance_upload_file.endsWith(
                                ".jpeg"
                              ) ||
                              compliance.cnd_compliance_upload_file.endsWith(
                                ".pdf"
                              )
                                ? "eye" //"eye"
                                : "download")
                            }
                          ></i>
                        </a>
                      </td>
                      {/* <td className="col-3">{compliance.cnd_compliance_description}</td> */}
                      {/* <td className="col-2 text-center" >
                    {compliance.cnd_compliance_reference}
                  </td> */}
                      <td className="col-3 text-center">
                        {compliance.cnd_compliance_exp_date_convert ==
                        "00-00-0000"
                          ? ""
                          : compliance.cnd_compliance_exp_date_convert}
                      </td>
                      {/*<td className="col-2 text-center">
                    {compliance?.cnd_compliance_created_date}
                </td>*/}
                      <td className="col-2 text-center">
                        -
                      </td>
                      {/*<td className="col-2 text-center" scope="col"> <Link to={"/create-document-national-insurance/"+compliance.cnd_compliance_id}><i className="fa fa-pencil"></i></Link></td>*/}
                    </tr>
                      );
                    }
                  })}
            </tbody>
          </table>
          {documentCompliance && documentCompliance.length > 0 && (
            <TableDropdownAndPagination
              handleDropDown={handleDropDown}
              recordLen={recordLen}
              handleLeft={handleLeft}
              handleRight={handleRight}
              renderPageNumbers={renderPageNumbers}
              isDropdown={true}
              isPaggination={true}
            />
          )}
          <a
            href={
              progress.progress_bar_dbs_8
                ? "/dbs-document"
                : "/create-dbs-document"
            }
            className="site-button listingnextbtn"
          >
            Next
          </a>
        </div>
      </div>
      <GlobalViewFileModal
        modalOption={modalOption}
        closeFileModal={closeFileModal}
        fileUrl={fileUrl}
        loaderEnd={loaderEnd}
        modalHeight={modalHeight}
        callModal={callModal}
        fileName={fileName}
        title={"Documents Required"}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    documentCompliance: state.documentCompliance.documentCompliance,
    progress: state.progress.progress,
  };
};

export default connect(mapStateToProps)(DocumentRequiredList);
