import React from "react";
import { Link } from "react-router-dom";
// import Background from "./../images/ourservices/Physical-support-banner.jpg";
// import HomecareImg from "./../images/ourservices/Physical.jpg";
// Newly Added Images
import Background from "./../images/new-updated-images/our-services/home-care/physical-disabilities-support/our-services-home-care-physical-disabilities-care.jpg";
import HomecareImg from "./../images/new-updated-images/our-services/home-care/physical-disabilities-support/our-services-home-care-physical-disabilities-care-01.jpg";

import bnr3 from "./../images/lines.png";

export default function Home_Physicalcare() {
  // Add title
	document.getElementById("titleText").innerText = "Staffing Connect - Our Services - Physical Disabilities Support";

  return (
    <div className="page-wraper">
      <div className="page-content1 bg-white">
        <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: `url(${Background})`, height: 580, marginTop:
          window.location.pathname == "/mobile/physical-disabilities-support"
            ? "0rem"
            : ""}}
        >
          <div className="container">
            <div className="dez-bnr-inr-entry">
              <h1 className="text-white"><b style={{display:"none"}}>Staffing Connect - Our Services - </b>Physical Disabilities Support</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    {window.location.pathname == "/mobile/physical-disabilities-support" ? (
                      "Home"
                    ) : (
                      <a href={"/"}>Home</a>
                    )}
                  </li>
                  <li>Our Services</li>
                  <li>Home Care</li>
                  <li>Physical Disabilities Support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full content-inner overlay-white-middle">
            <div className="container">
              <div className="row align-items-center m-b5">
                <div className="section-head text-black text-center col-lg-12">
                  <h2 className="text-uppercase m-b0">
                    Physical Disabilities Support{" "}
                  </h2>
                </div>
              </div>

              <div className="row m-b10">
                <div className="col-md-12 col-lg-6">
                  <img src={HomecareImg} alt="" />
                </div>
                <div className="col-md-12 col-lg-6 ">
                  <p className="spacebar mt-4"></p>

                  <h6>
                    Empowering Independence: Exceptional Physical Disabilities
                    Care at Staffing Connect
                  </h6>

                  <p className="text-left">
                    At Staffing Connect, we firmly believe that individuals with
                    physical disabilities deserve to lead fulfilling and active
                    lives. Our dedicated team is committed to making this a
                    reality by providing professional care services, promoting
                    independence and maximizing quality of life.
                  </p>

                  <p className="text-left">
                    We recognize that each of our customers has unique needs,
                    and we are equipped to address them with precision and
                    compassion. From assisting with essential personal care
                    tasks like getting up and daily activities such as cooking,
                    laundry, and bathing, we go above and beyond. Our goal is to
                    empower individuals to enjoy a life that is as full and
                    independent as possible.
                  </p>
                </div>
              </div>
              <p className="text-left">
                Living with a physical disability may require specialized
                assistance to access essential facilities within your home, such
                as the bathroom or kitchen. You may also benefit from support
                with personal care and community integration. Our team at
                Staffing Connect is here to offer tailored support and
                assistance delivered by compassionate professionals dedicated to
                helping you live life to its fullest potential.
              </p>

              <p className="text-left">
                Our personalized care approach strives to enhance your overall
                well-being and enable you to maintain a sense of independence
                and dignity. Your needs and preferences are at the forefront of
                our care planning, ensuring that our support aligns with your
                goals and aspirations.
              </p>

              <p className="text-left">
                Let Staffing Connect be your partner in enhancing your life with
                our comprehensive physical disabilities care services. We can
                overcome challenges and create a more inclusive and empowering
                future.
              </p>
            </div>
          </div>
          <div
            className=" content-inner-2 footerImage"
            style={{
              backgroundImage: "url( " + bnr3 + ")",
              backgroundPosition: "bottom",
              backgroundRepeat: "repeat-x",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
