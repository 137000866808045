import React, { useEffect, useState } from "react";
import Profilesidebar from "./../components/Element/Profilesidebar";
import StepWizard from "react-step-wizard";
import PersonalInfoContainer from "../components/Element/CandidateProfileForms";
import Nav from "../components/Element/CandidateProfileForms/Nav";
import Resume from "../components/Element/Resume";
import { ProgressBar } from "react-bootstrap";
import { isMobileOrLaptop } from "../services/CheckDevice";
import ProfileSideBarMobile from "../components/Element/ProfileSideBarMobile";

const Jobprofile = (props) => {
  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(350);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-blocks">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {checkDevice && checkDevice == "Mobile" ? (
                  <ProfileSideBarMobile page="profile" />
                ) : (
                  <Profilesidebar page="profile" />
                )}
                <div className="col-xl-9 col-lg-8 m-b30">
                  <PersonalInfoContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Jobprofile;
