import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { isAuthenticated } from "../../redux/selectors/AuthSelectors";

function Login(props) {
  const { onClick } = props;
  return (
    <>
      <a
        style={{ display: props.isAuthenticated ? "none" : "inline-block" }}
        // onClick={()=>onClick("/login")}
        // to="#"
        href="/login"
        title="Login"
        className="site-button"
      >
        <i className="fa fa-lock" /> Login
      </a>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Login));
