import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Countries from "../Countries";
import Input from "../../FormElements/Input";
import { Textarea } from "../../FormElements/Forms";
import Label from "../../Layout/Label";
const AddressFields = (props) => {
  const [userdata, setFormdata] = useState({});
  const { errors } = props;
  useEffect(() => {
    setFormdata(props.data);
  }, [props.data]);
  return (
    <React.Fragment>
      <Input
        type="file"
        label="Address Proof"
        placeholder={"Eg., 123 Main St"}
        lg={6}
        md={6}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_compliance_upload_file"
        id={"cnd_compliance_upload_file"}
        value={userdata.cnd_compliance_upload_file}
        errors={errors}
      />
      <Input
        type="text"
        label="Document Reference Number"
        //placeholder={""}
        lg={6}
        md={6}
        changeHandler={(e) => props.onChange(e)}
        name="cnd_compliance_reference"
        id={"cnd_compliance_reference"}
        value={userdata.cnd_compliance_reference}
        errors={errors}
        req={false}
      />
      <Textarea
        label="Document Description"
        changeHandler={(e) => props.onChange(e)}
        name="cnd_compliance_description"
        value={userdata.cnd_compliance_description}
        id={"cnd_compliance_description"}
        lg={12}
        md={12}
        errors={errors}
      />
      <Input
        type="text"
        label="Address 1"
        placeholder={"Eg., 123 Main St"}
        lg={12}
        md={12}
        name="cnd_street_address"
        id={"cnd_street_address"}
        value={userdata.cnd_street_address}
        changeHandler={(e) => props.onChange(e)}
        errors={errors}
      />
      <Input
        type="text"
        lg={12}
        md={12}
        changeHandler={(e) => props.onChange(e)}
        label="Address 2"
        name="cnd_street_address_2"
        id={"cnd_street_address_2"}
        value={userdata.cnd_street_address_2}
        placeholder="Eg., Apartment, suite, unit etc. (optional)"
        errors={errors}
        req={false}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        name="cnd_town"
        id={"cnd_town"}
        label="Town"
        lg={4}
        md={4}
        value={userdata.cnd_town}
        placeholder="Eg., London"
        errors={errors}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        name="cnd_region"
        id={"cnd_region"}
        label="Region"
        lg={4}
        md={4}
        value={userdata.cnd_region}
        placeholder="Eg., London"
        errors={errors}
        req={false}
      />
      <Input
        type="text"
        changeHandler={(e) => props.onChange(e)}
        name="cnd_postcode"
        id={"cnd_postcode"}
        label="Postal Code"
        lg={4}
        md={4}
        //maxLength={8}
        value={userdata.cnd_postcode}
        placeholder="Eg., AB12CD"
        errors={errors}
      />
    </React.Fragment>
  );
};

export default AddressFields;
