import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import composedAppliedJobsContainer from "../../../containers/AppliedJobsContainer";
import MyAssignementsList from "../MyAssignments/MyAssignementsList";
import AppliedJobsList from "./AppliedJobsList";
import InprogressList from "./InprogressList";
import HiredList from "./HiredList";
import RejectedList from "./RejectedList";
import { isMobileOrLaptop } from "../../../services/CheckDevice";
import AppliedJobsListMobile from "./AppliedJobsListMobile";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Speed Dial - Start
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TaskAlt from "@mui/icons-material/TaskAlt";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";

const actions = [
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
  { icon: <TaskAlt />, name: "Applied Jobs" },
];
// Speed Dial - End

const AppliedJobsMainComponent = (props) => {
  // Check Device - Start
  const [checkDevice, setCheckDevice] = useState("Mobile");

  window.addEventListener("resize", function () {
    // Call the function when the screen size changes
    const deviceType = isMobileOrLaptop();
    setCheckDevice(deviceType);
  });

  useEffect(() => {
    const deviceType = isMobileOrLaptop(700);
    setCheckDevice(deviceType);
  }, []);
  // Check Device - End

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [jobsTabName, setJobsTabName] = useState("appliedJobs");
  const jobsTab = (tab) => {
    // console.log("This Is :=> "+tab)
    // let element, content;
    // Applied Jobs
    if (tab == "tab1") {
      setJobsTabName("appliedJobs");
    } else if (tab == "tab2") {
      setJobsTabName("inProgressJobs");
    } else if (tab == "tab3") {
      setJobsTabName("selectedJobs");
    } else if (tab == "tab4") {
      setJobsTabName("rejectedJobs");
    }
  };

  const printContent = (jobType = "") => {
    if (checkDevice == "Mobile") {
      return (
        <AppliedJobsListMobile jobTypeParam={jobType ? jobType : "Applied"} />
      );
    } else {
      return <AppliedJobsList jobTypeParam={jobType ? jobType : "Applied"} />;
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="job-bx submit-resume">
              <div className="job-bx-title clearfix">
                <h5 className="font-weight-700 pull-left text-uppercase">
                  Jobs Applied
                </h5>
                {/* <a
                  href="/apply-more-jobs"
                  className="btn btn-success site-button float-right"
                >
                  Apply More Jobs
                </a> */}
              </div>

              {checkDevice && checkDevice == "Mobile" ? (
                <>
                  <Box
                    sx={{
                      flexGrow: 1,
                      // maxWidth: { xs: 320, sm: 480 },
                      bgcolor: "background.paper",
                    }}
                    className="mb-3"
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="visible arrows tabs example"
                      sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                          "&.Mui-disabled": { opacity: 0.3 },
                        },
                      }}
                      className=""
                    >
                      <Tab
                        label="Applied Jobs"
                        style={{ fontSize: "80%" }}
                        onClick={(e) => jobsTab("tab1")}
                      />
                      <Tab
                        label="In-Progress Jobs"
                        style={{ fontSize: "80%" }}
                        onClick={(e) => jobsTab("tab2")}
                      />
                      <Tab
                        label="Selected Jobs"
                        style={{ fontSize: "80%" }}
                        onClick={(e) => jobsTab("tab3")}
                      />
                      <Tab
                        label="Rejected Jobs"
                        style={{ fontSize: "80%" }}
                        onClick={(e) => jobsTab("tab4")}
                      />
                    </Tabs>
                  </Box>
                  {/* <Box
                    sx={{
                      position: "fixed", // Fixed position for the entire Box
                      bottom: 16,
                      right: 16,
                      height: "auto", // Allow height to adjust based on its content
                      transform: "translateZ(0px)",
                      zIndex: "10",
                      opacity: "1"
                    }}
                  >
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{ position: "relative" }} // Relative positioning within the fixed Box
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          tooltipOpen
                        />
                      ))}
                    </SpeedDial>
                  </Box> */}
                  {/* <ul
                  className="nav nav-tabs bg-dark p-2"
                  style={{
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "" +
                        (jobsTabName == "appliedJobs"
                          ? "bg-white text-primary active bold p-2"
                          : "text-white")
                      }
                      id="tab1"
                      style={{ borderRadius: "50px" }}
                      onClick={(e) => jobsTab("tab1")}
                    >
                      Applied Jobs
                    </a>
                  </li>
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "" +
                        (jobsTabName == "inProgressJobs"
                          ? "bg-white p-2 text-primary active"
                          : "text-white")
                      }
                      onClick={(e) => jobsTab("tab2")}
                      id="tab2"
                      style={{ borderRadius: "50px" }}
                    >
                      In-Progress Jobs
                    </a>
                  </li>
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "" +
                        (jobsTabName == "selectedJobs"
                          ? "bg-white p-2 text-primary active"
                          : "text-white")
                      }
                      id="tab3"
                      style={{ borderRadius: "50px" }}
                      onClick={(e) => jobsTab("tab3")}
                    >
                      Selected Jobs
                    </a>
                  </li>
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "" +
                        (jobsTabName == "rejectedJobs"
                          ? "bg-white p-2 text-primary active"
                          : "text-white")
                      }
                      id="tab4"
                      style={{ borderRadius: "50px" }}
                      onClick={(e) => jobsTab("tab4")}
                    >
                      Rejected Jobs
                    </a>
                  </li>
                </ul> */}
                </>
              ) : (
                <ul
                  className="nav nav-tabs bg-dark p-1"
                  style={{
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "nav-link " +
                        (jobsTabName == "appliedJobs"
                          ? "text-primary active bold"
                          : "text-white")
                      }
                      id="tab1"
                      style={{ borderRadius: "50px" }}
                      onClick={(e) => jobsTab("tab1")}
                    >
                      Applied Jobs
                    </a>
                  </li>
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "nav-link " +
                        (jobsTabName == "inProgressJobs"
                          ? "text-primary active"
                          : "text-white")
                      }
                      onClick={(e) => jobsTab("tab2")}
                      id="tab2"
                      style={{ borderRadius: "50px" }}
                    >
                      In-Progress Jobs
                    </a>
                  </li>
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "nav-link " +
                        (jobsTabName == "selectedJobs"
                          ? "text-primary active"
                          : "text-white")
                      }
                      id="tab3"
                      style={{ borderRadius: "50px" }}
                      onClick={(e) => jobsTab("tab3")}
                    >
                      Selected Jobs
                    </a>
                  </li>
                  <li className={"nav-item cursor-pointer p-2"}>
                    <a
                      className={
                        "nav-link " +
                        (jobsTabName == "rejectedJobs"
                          ? "text-primary active"
                          : "text-white")
                      }
                      id="tab4"
                      style={{ borderRadius: "50px" }}
                      onClick={(e) => jobsTab("tab4")}
                    >
                      Rejected Jobs
                    </a>
                  </li>
                </ul>
              )}

              <div className="tab-content mt-3">
                {/* Search Start */}

                {/* Search End */}
                {jobsTabName == "appliedJobs" && (
                  <>
                    <div id="tabFirst" className="tab-pane active">
                      {printContent("Applied")}
                    </div>
                  </>
                )}
                {jobsTabName == "inProgressJobs" && (
                  <>
                    <div id="tabSecond" className="tab-pane active">
                      {printContent("In-Progress")}
                    </div>
                  </>
                )}

                {jobsTabName == "selectedJobs" && (
                  <>
                    <div id="tabThird" className="tab-pane active">
                      {printContent("Hired")}
                    </div>
                  </>
                )}
                {jobsTabName == "rejectedJobs" && (
                  <>
                    <div id="tabFourth" className="tab-pane active">
                      {printContent("Rejected")}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default composedAppliedJobsContainer(AppliedJobsMainComponent);
