const initialState = {appoinments: {}}

export const appoinments = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_APPOINMENTS':
      return {appoinments: action.payload}; 
  }
  return state
}


