import React from "react";
import Profilesidebar from "./../components/Element/Profilesidebar";
import StepWizard from "react-step-wizard";
import PersonalInfoContainer from "../components/Element/PermanentRequirements";
import Nav from "../components/Element/CandidateProfileForms/Nav";
import Resume from "../components/Element/Resume";
import { ProgressBar } from "react-bootstrap";
import PermanentProfilesidebar from "./PermanentCandidate/PermanentProfilesidebar";

const PermanentJobprofile = (props) => {
  return (
    <React.Fragment>
      <div className="page-content bg-white">
        <div className="content-blocks">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                {/* <Profilesidebar page="profile" /> */}
                <PermanentProfilesidebar page="profile" />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <PersonalInfoContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PermanentJobprofile;
